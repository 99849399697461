import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";

export default function ChequeoOficinas(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    chequeoOficinas,
    statusAcceso,
    statusAcceso3,
    localidades,
    guardarChequeoOficinas,
    obtenerAcceso,
  } = serviciosContext;

  const handleChangePregunta1 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta1: event.target.checked,
    });
  };

  const handleChangePregunta2 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta2: event.target.checked,
    });
  };

  const handleChangePregunta3 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta3: event.target.checked,
    });
  };

  const handleChangePregunta4 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta4: event.target.checked,
    });
  };

  const handleChangePregunta5 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta5: event.target.checked,
    });
  };

  const handleChangePregunta6 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta6: event.target.checked,
    });
  };

  const handleChangePregunta7 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta7: event.target.checked,
    });
  };

  const handleChangePregunta8 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta8: event.target.checked,
    });
  };

  const handleChangePregunta9 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta9: event.target.checked,
    });
  };

  const handleChangePregunta10 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta10: event.target.checked,
    });
  };

  const handleChangePregunta11 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta11: event.target.checked,
    });
  };

  const handleChangePregunta12 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta12: event.target.checked,
    });
  };

  const handleChangePregunta13 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta13: event.target.checked,
    });
  };

  const handleChangePregunta14 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta14: event.target.checked,
    });
  };

  const handleChangePregunta15 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta15: event.target.checked,
    });
  };

  const handleChangePregunta16 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta16: event.target.checked,
    });
  };

  const handleChangePregunta17 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta17: event.target.checked,
    });
  };

  const handleChangePregunta18 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta18: event.target.checked,
    });
  };

  const handleChangePregunta19 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta19: event.target.checked,
    });
  };

  const handleChangePregunta20 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta20: event.target.checked,
    });
  };

  const handleChangePregunta21 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta21: event.target.checked,
    });
  };

  const handleChangePregunta22 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta22: event.target.checked,
    });
  };

  const handleChangePregunta23 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta23: event.target.checked,
    });
  };

  const handleChangePregunta24 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta24: event.target.checked,
    });
  };

  const handleChangePregunta25 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta25: event.target.checked,
    });
  };

  const handleChangePregunta26 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta26: event.target.checked,
    });
  };

  const handleChangePregunta27 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta27: event.target.checked,
    });
  };

  const handleChangePregunta28 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta28: event.target.checked,
    });
  };

  const handleChangePregunta29 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta29: event.target.checked,
    });
  };
  const handleChangePregunta30 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta30: event.target.checked,
    });
  };
  const handleChangePregunta31 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta31: event.target.checked,
    });
  };
  const handleChangePregunta32 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta32: event.target.checked,
    });
  };
  const handleChangePregunta33 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta33: event.target.checked,
    });
  };
  const handleChangePregunta34 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta34: event.target.checked,
    });
  };
  const handleChangePregunta35 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta35: event.target.checked,
    });
  };
  const handleChangePregunta36 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta36: event.target.checked,
    });
  };
  const handleChangePregunta37 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta37: event.target.checked,
    });
  };
  const handleChangePregunta38 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta38: event.target.checked,
    });
  };
  const handleChangePregunta39 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta39: event.target.checked,
    });
  };
  const handleChangePregunta40 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta40: event.target.checked,
    });
  };
  const handleChangePregunta41 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta41: event.target.checked,
    });
  };
  const handleChangePregunta42 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta42: event.target.checked,
    });
  };
  const handleChangePregunta43 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta43: event.target.checked,
    });
  };

  const handleChangePregunta44 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta44: event.target.checked,
    });
  };

  const handleChangePregunta45 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta45: event.target.checked,
    });
  };

  const handleChangePregunta46 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta46: event.target.checked,
    });
  };

  const handleChangePregunta47 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta47: event.target.checked,
    });
  };

  const handleChangePregunta48 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta48: event.target.checked,
    });
  };

  const handleChangePregunta49 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta49: event.target.checked,
    });
  };

  const handleChangePregunta50 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta50: event.target.checked,
    });
  };

  const handleChangePregunta51 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta51: event.target.checked,
    });
  };

  const handleChangePregunta52 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta52: event.target.checked,
    });
  };

  const handleChangePregunta53 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta53: event.target.checked,
    });
  };

  const handleChangePregunta54 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta54: event.target.checked,
    });
  };

  const handleChangePregunta55 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta55: event.target.checked,
    });
  };

  const handleChangePregunta56 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta56: event.target.checked,
    });
  };

  const handleChangePregunta57 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta57: event.target.checked,
    });
  };

  const handleChangePregunta58 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta58: event.target.checked,
    });
  };

  const handleChangePregunta59 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta59: event.target.checked,
    });
  };

  const handleChangePregunta60 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta60: event.target.checked,
    });
  };

  const handleChangePregunta61 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta61: event.target.checked,
    });
  };

  const handleChangePregunta62 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta62: event.target.checked,
    });
  };

  const handleChangePregunta63 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta63: event.target.checked,
    });
  };
  const handleChangePregunta64 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta64: event.target.checked,
    });
  };
  const handleChangePregunta65 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta65: event.target.checked,
    });
  };
  const handleChangePregunta66 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta66: event.target.checked,
    });
  };
  const handleChangePregunta67 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta67: event.target.checked,
    });
  };
  const handleChangePregunta68 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta68: event.target.checked,
    });
  };
  const handleChangePregunta69 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta69: event.target.checked,
    });
  };
  const handleChangePregunta70 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta70: event.target.checked,
    });
  };
  const handleChangePregunta71 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta71: event.target.checked,
    });
  };
  const handleChangePregunta72 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta72: event.target.checked,
    });
  };
  const handleChangePregunta73 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta73: event.target.checked,
    });
  };
  const handleChangePregunta74 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta74: event.target.checked,
    });
  };
  const handleChangePregunta75 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta75: event.target.checked,
    });
  };
  const handleChangePregunta76 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta76: event.target.checked,
    });
  };
  const handleChangePregunta77 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta77: event.target.checked,
    });
  };
  const handleChangePregunta78 = (event) => {
    guardarChequeoOficinas({
      ...chequeoOficinas,
      pregunta78: event.target.checked,
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  //console.log(chequeoOficinas);

  return (
    <Fragment>
      <Fragment>
        <Grid
          container
          spacing={3}
          style={{ marginTop: "0vw", marginLeft: "0vw" }}
        >
          <Grid item xs={12} md={7} style={{ marginTop: "1vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta1 : null}
                    onChange={handleChangePregunta1}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Mobiliario de uso diario, escritorio, computadoras, teléfonos funcionando."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "0vw" }}>
            <TextField
              id="comentario1"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario1 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario1: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta2 : null}
                    onChange={handleChangePregunta2}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cámaras y TV. Imágenes por teléfono, Wifi. Señal de internet."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario2"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario2 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario2: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta3 : null}
                    onChange={handleChangePregunta3}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Habilitación municipal."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario3"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario3 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario3: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta4 : null}
                    onChange={handleChangePregunta4}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Habilitación ministerio."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario4"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario4 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario4: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta5 : null}
                    onChange={handleChangePregunta5}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Libro de registro de inspecciones."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario5"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario5 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario5: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta6 : null}
                    onChange={handleChangePregunta6}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Plano de habilitación."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario6"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario6 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario6: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta7 : null}
                    onChange={handleChangePregunta7}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Certificado de limpieza de tanques de agua. Vencimiento."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario7"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario7 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario7: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta8 : null}
                    onChange={handleChangePregunta8}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Certificado bomberos. Vencimiento."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario8"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario8 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario8: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta9 : null}
                    onChange={handleChangePregunta9}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Planos de evacuación y simulacro de incendio."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario9"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario9 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario9: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta10 : null}
                    onChange={handleChangePregunta10}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Exhibe derechos de los residentes en lugar visible."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario10"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario10 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario10: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta11 : null}
                    onChange={handleChangePregunta11}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Inscripción ugcor."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario11"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario11 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario11: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta12 : null}
                    onChange={handleChangePregunta12}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Libretas sanitarias. Vencimiento."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario12"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario12 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario12: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta13 : null}
                    onChange={handleChangePregunta13}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Tarjetas de matafuegos. Vencimiento."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario13"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario13 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario13: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta14 : null}
                    onChange={handleChangePregunta14}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Libro de residentes actualizado última fecha."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario14"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario14 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario14: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta15 : null}
                    onChange={handleChangePregunta15}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Libro de visitas. Ultima fecha."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario15"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario15 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario15: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta16 : null}
                    onChange={handleChangePregunta16}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Certificado de desratización."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario16"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario16 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario16: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta17 : null}
                    onChange={handleChangePregunta17}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Chapa mural de frente. 30x40."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario17"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario17 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario17: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta18 : null}
                    onChange={handleChangePregunta18}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Inscripción en residuos patológicos."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario18"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario18 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario18: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta19 : null}
                    onChange={handleChangePregunta19}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Manifiesto de retiro residuos patológicos."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario19"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario19 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario19: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta20 : null}
                    onChange={handleChangePregunta20}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Certificado de incineración."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario20"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario20 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario20: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta21 : null}
                    onChange={handleChangePregunta21}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Teléfono de calle."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario21"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario21 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario21: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta22 : null}
                    onChange={handleChangePregunta22}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Teléfono celular."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario22"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario22 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario22: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta23 : null}
                    onChange={handleChangePregunta23}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Radios."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario23"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario23 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario23: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta24 : null}
                    onChange={handleChangePregunta24}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Impresora funcionando."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario24"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario24 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario24: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta25 : null}
                    onChange={handleChangePregunta25}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cartuchos de repuesto."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario25"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario25 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario25: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta26 : null}
                    onChange={handleChangePregunta26}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Hojas de impresión."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario26"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario26 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario26: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta27 : null}
                    onChange={handleChangePregunta27}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Computadora, teclado, mouse, monitor funcionando con internet."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario27"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario27 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario27: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta28 : null}
                    onChange={handleChangePregunta28}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Mail de la institución de lectura diaria."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario28"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario28 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario28: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta29 : null}
                    onChange={handleChangePregunta29}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Seguros de responsabilidad civil."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario29"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario29 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario29: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta30 : null}
                    onChange={handleChangePregunta30}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Incendio."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario30"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario30 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario30: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta31 : null}
                    onChange={handleChangePregunta31}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Ascensores."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario31"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario31 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario31: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta32 : null}
                    onChange={handleChangePregunta32}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Mala praxis."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario32"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario32 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario32: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta33 : null}
                    onChange={handleChangePregunta33}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Listado completo y actualizado de residentes con contactos telefónicos, mail , lugares de cápita y médicos de cabecera."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario33"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario33 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario33: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta34 : null}
                    onChange={handleChangePregunta34}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Libro de ascensores actualizado."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario34"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario34 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario34: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta35 : null}
                    onChange={handleChangePregunta35}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Calderas control vencimiento."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario35"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario35 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario35: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta36 : null}
                    onChange={handleChangePregunta36}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Contrato de alquiler vencimiento."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario36"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario36 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario36: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta37 : null}
                    onChange={handleChangePregunta37}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Teclas de luz con módulo completo y funcionan."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario37"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario37 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario37: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta38 : null}
                    onChange={handleChangePregunta38}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Iluminación funcionando, completa con lámpara y plafón."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario38"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario38 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario38: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta39 : null}
                    onChange={handleChangePregunta39}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Controlar la aparición de humedades."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario39"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario39 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario39: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta40 : null}
                    onChange={handleChangePregunta40}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Pintura en techos y paredes."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario40"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario40 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario40: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta41 : null}
                    onChange={handleChangePregunta41}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Limpieza y orden."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario41"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario41 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario41: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta42 : null}
                    onChange={handleChangePregunta42}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="perchero."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario42"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario42 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario42: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta43 : null}
                    onChange={handleChangePregunta43}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="reloj."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario43"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario43 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario43: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta44 : null}
                    onChange={handleChangePregunta44}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Pizarra actualizada con novedades."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario44"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario44 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario44: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta45 : null}
                    onChange={handleChangePregunta45}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Control de presentismo."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario45"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario45 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario45: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta46 : null}
                    onChange={handleChangePregunta46}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Legajos de residentes al dia. Contrato."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario46"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario46 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario46: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta47 : null}
                    onChange={handleChangePregunta47}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Consentimiento médico firmado."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario47"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario47 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario47: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta48 : null}
                    onChange={handleChangePregunta48}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Legajos de personal completo."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario48"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario48 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario48: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta49 : null}
                    onChange={handleChangePregunta49}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Dirección, tel, mail actualizado mensualmente."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario49"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario49 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario49: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta50 : null}
                    onChange={handleChangePregunta50}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Planillas de control de stock."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario50"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoOficinas ? chequeoOficinas.comentario50 : null}
              onChange={(e) => {
                guardarChequeoOficinas({
                  ...chequeoOficinas,
                  comentario50: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoOficinas ? chequeoOficinas.pregunta51 : null}
                    onChange={handleChangePregunta51}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Puerta con picaporte."
                labelPlacement="end"
                //disabled={chequeoOficinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12} />
        </Grid>
      </Fragment>
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
