import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../../context/AlertContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "../Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { jsPDF } from "jspdf";

export default function ListadosDietasPorDieta(props) {
  const exportColumns = [
    { title: "Medicación", dataKey: "label" },
    { title: "Inicio", dataKey: "inicio" },
    { title: "Final", dataKey: "final" },
    { title: "Dosis Mg.", dataKey: "dosis" },
    { title: "Vía", dataKey: "via" },
    { title: "Hora 1", dataKey: "headerHora1" },
    { title: "D", dataKey: "d" },
    { title: "A", dataKey: "a" },
    { title: "M", dataKey: "m" },
    { title: "C", dataKey: "c" },
    { title: "Hora 2", dataKey: "headerHora2" },
    { title: "Cantidad", dataKey: "cantidad" },
  ];

  const [listado, setListado] = useState({
    orientacion: "vertical",
    camposSeleccionados: [],
  });
  const [visible, setVisible] = useState(true);
  const toast = useRef(null);

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    pacientes,
    barthel,
    fragilidad,
    lawtonBrody,
    yesavage,
    tinetti,
    norton,
    dietas,
    medicacionesPacientes,
    diagnosticosPacientes,
    ingresosEgresos,
    evaluaciones,
    obrasSociales,
    obrasSocialesMedicas,
    obtenerAcceso,
    geriatricoElegido,
    obtenerObrasSociales,
    obtenerObrasSocialesMedicas,
    listadoMedicacionesActivas,
    obtenerMedicacionesPacientesIndividual,
    obtenerDiagnosticosPacientesIndividual,
    obtenerIngresosEgresos,
    obtenerInformesPreIngreso,
    setGeriatricoElegido,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  const exitClick = (e) => {
    navigate("/");
  };

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Listados",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    //obtenerObrasSociales({ todos: true });
    //obtenerObrasSocialesMedicas({ todos: false });
  }, []);

  const onHide = (e) => {
    setVisible(false);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  function getEdad(dateString) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  }

  function formatDate2(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  const imprimir = async (e) => {
    const doc = new jsPDF();
    let hoy = new Date();

    let row = 0;
    let dietaNom = null;
    let finalY = null;
    let index = null;

    dietas.sort(function (a, b) {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });

    const array = dietas.map((i) => ({ ...i }));

    array.sort(function (a, b) {
      if (a.pacientes.nombre > b.pacientes.nombre) {
        return 1;
      }
      if (a.pacientes.nombre < b.pacientes.nombre) {
        return -1;
      }
      return 0;
    });

    row = 20;

    doc.setFontSize(12);

    doc.setFont("Time-BoldItalic", "bold");
    doc.text(geriatricoElegido.nombre, 15, 8);

    doc.text(
      "Fecha: " + hoy.toLocaleDateString("en-GB"),
      150,
      8,
      "left",
      "middle"
    );

    doc.setFontSize(14);

    doc.setFont("Time-BoldItalic", "normal");
    doc.text("RESIDENTES POR DIETA", 80, 20);
    let fil = 10;
    let band = false
    for (var i = 0; i < dietas.length; i++) {
      if (dietas[i].label !== dietaNom) {
        dietaNom = dietas[i].label;
        band = true

        doc.setFont("Times-Roman", "normal");

        row = row + 10;

        index = array.findIndex((elem) => elem.label === dietaNom);

        if (index !== -1) {
          band = true
          doc.setFontSize(10);

          doc.text(
            "Dieta: " +
              (dietas[i].label !== undefined &&
              dietas[i].label !== null &&
              dietas[i].label !== undefined &&
              dietas[i].label !== null
                ? dietas[i].label
                : ""),
            fil,
            row,
            "left",
            "middle"
          );

          row = row + 5;

          index = dietas.findIndex((elem) => elem.label === dietaNom);

          if (index !== -1) {
            doc.text(
              array
                .filter((data) => data.label === dietaNom)
                .map((residente) => "•" + residente.pacientes.nombre + "  "),
              fil,
              row,
              "left",
              "middle"
            );

            let arrayC = array.filter((data) => data.label === dietaNom).length;

            row = row + 4 * arrayC - 5;
          } else {
            doc.text("", 10, row, "left", "middle");
          }
        } 

        /*
        doc.setFontSize(9);

        doc.autoTable(exportColumns, arrayPaciente, {
          margin: { left: 10 },
          startY: row,
          styles: {
            fontSize: 7,
          },
        });

        finalY = doc.previousAutoTable.finalY;

        row = finalY + 10;
        */

        if (row >= 220) {
          if (fil === 10) {
            fil = 100;
            row = 20;
          } else {
            fil = 10;
          }

          if (fil === 10) {
              doc.addPage();
              band = false

              row = 20;
              doc.setFontSize(12);

              doc.setFont("Time-BoldItalic", "bold");
              doc.text(geriatricoElegido.nombre, 15, 8);

              doc.text(
                "Fecha: " + hoy.toLocaleDateString("en-GB"),
                150,
                8,
                "left",
                "middle"
              );

              doc.setFontSize(14);

              doc.setFont("Time-BoldItalic", "normal");
              doc.text("RESIDENTES POR DIETA", 80, 20);
          }
        }
      }

      /*
      if (i < medicacionesPacientes.length - 1) {
        doc.addPage();
      }
      */
    }
    if (!band && fil ===10) {
      var pageCount = doc.internal.getNumberOfPages();
      doc.deletePage(pageCount)
    }

    window.open(doc.output("bloburl"));
  };

  const aceptarClick = async (exportType) => {
    //e.preventDefault();
    // Validar|

    var elem;

    await listadoMedicacionesActivas({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    }).then((data) => {
      if (exportType === "pdf") {
        elem = document.getElementById("expButtonPdf");
        elem.click();
      }
    });
    onHide();
    //exitClick();
  };

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Listado de Dietas"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <button
          id="expButtonPdf"
          type="button"
          onClick={(e) => {
            imprimir();
          }}
          style={{ display: "none" }}
        >
          Export Pdf
        </button>

        <ButtonMui
          onClick={(e) => {
            aceptarClick("pdf");
          }}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
          autoFocus
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  //console.log(medicacionesPacientes);
  //console.log(dietas);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeader()}
            visible={visible}
            closable={false}
            footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "75vw", paddingBottom: "0" }}
            position="center"
          ></Dialog>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}
/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
