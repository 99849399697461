import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";

export default function ChequeoCocinas(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    chequeoCocinas,
    statusAcceso,
    statusAcceso3,
    localidades,
    guardarChequeoCocinas,
    obtenerAcceso,
  } = serviciosContext;

  const handleChangePregunta1 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta1: event.target.checked,
    });
  };

  const handleChangePregunta2 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta2: event.target.checked,
    });
  };

  const handleChangePregunta3 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta3: event.target.checked,
    });
  };

  const handleChangePregunta4 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta4: event.target.checked,
    });
  };

  const handleChangePregunta5 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta5: event.target.checked,
    });
  };

  const handleChangePregunta6 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta6: event.target.checked,
    });
  };

  const handleChangePregunta7 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta7: event.target.checked,
    });
  };

  const handleChangePregunta8 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta8: event.target.checked,
    });
  };

  const handleChangePregunta9 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta9: event.target.checked,
    });
  };

  const handleChangePregunta10 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta10: event.target.checked,
    });
  };

  const handleChangePregunta11 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta11: event.target.checked,
    });
  };

  const handleChangePregunta12 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta12: event.target.checked,
    });
  };

  const handleChangePregunta13 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta13: event.target.checked,
    });
  };

  const handleChangePregunta14 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta14: event.target.checked,
    });
  };

  const handleChangePregunta15 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta15: event.target.checked,
    });
  };

  const handleChangePregunta16 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta16: event.target.checked,
    });
  };

  const handleChangePregunta17 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta17: event.target.checked,
    });
  };

  const handleChangePregunta18 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta18: event.target.checked,
    });
  };

  const handleChangePregunta19 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta19: event.target.checked,
    });
  };

  const handleChangePregunta20 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta20: event.target.checked,
    });
  };

  const handleChangePregunta21 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta21: event.target.checked,
    });
  };

  const handleChangePregunta22 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta22: event.target.checked,
    });
  };

  const handleChangePregunta23 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta23: event.target.checked,
    });
  };

  const handleChangePregunta24 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta24: event.target.checked,
    });
  };

  const handleChangePregunta25 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta25: event.target.checked,
    });
  };

  const handleChangePregunta26 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta26: event.target.checked,
    });
  };

  const handleChangePregunta27 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta27: event.target.checked,
    });
  };

  const handleChangePregunta28 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta28: event.target.checked,
    });
  };

  const handleChangePregunta29 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta29: event.target.checked,
    });
  };
  const handleChangePregunta30 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta30: event.target.checked,
    });
  };
  const handleChangePregunta31 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta31: event.target.checked,
    });
  };
  const handleChangePregunta32 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta32: event.target.checked,
    });
  };
  const handleChangePregunta33 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta33: event.target.checked,
    });
  };
  const handleChangePregunta34 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta34: event.target.checked,
    });
  };
  const handleChangePregunta35 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta35: event.target.checked,
    });
  };
  const handleChangePregunta36 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta36: event.target.checked,
    });
  };
  const handleChangePregunta37 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta37: event.target.checked,
    });
  };
  const handleChangePregunta38 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta38: event.target.checked,
    });
  };
  const handleChangePregunta39 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta39: event.target.checked,
    });
  };
  const handleChangePregunta40 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta40: event.target.checked,
    });
  };
  const handleChangePregunta41 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta41: event.target.checked,
    });
  };
  const handleChangePregunta42 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta42: event.target.checked,
    });
  };
  const handleChangePregunta43 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta43: event.target.checked,
    });
  };

  const handleChangePregunta44 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta44: event.target.checked,
    });
  };

  const handleChangePregunta45 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta45: event.target.checked,
    });
  };

  const handleChangePregunta46 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta46: event.target.checked,
    });
  };

  const handleChangePregunta47 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta47: event.target.checked,
    });
  };

  const handleChangePregunta48 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta48: event.target.checked,
    });
  };

  const handleChangePregunta49 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta49: event.target.checked,
    });
  };

  const handleChangePregunta50 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta50: event.target.checked,
    });
  };

  const handleChangePregunta51 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta51: event.target.checked,
    });
  };

  const handleChangePregunta52 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta52: event.target.checked,
    });
  };

  const handleChangePregunta53 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta53: event.target.checked,
    });
  };

  const handleChangePregunta54 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta54: event.target.checked,
    });
  };

  const handleChangePregunta55 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta55: event.target.checked,
    });
  };

  const handleChangePregunta56 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta56: event.target.checked,
    });
  };

  const handleChangePregunta57 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta57: event.target.checked,
    });
  };

  const handleChangePregunta58 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta58: event.target.checked,
    });
  };

  const handleChangePregunta59 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta59: event.target.checked,
    });
  };

  const handleChangePregunta60 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta60: event.target.checked,
    });
  };

  const handleChangePregunta61 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta61: event.target.checked,
    });
  };

  const handleChangePregunta62 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta62: event.target.checked,
    });
  };

  const handleChangePregunta63 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta63: event.target.checked,
    });
  };
  const handleChangePregunta64 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta64: event.target.checked,
    });
  };
  const handleChangePregunta65 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta65: event.target.checked,
    });
  };
  const handleChangePregunta66 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta66: event.target.checked,
    });
  };
  const handleChangePregunta67 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta67: event.target.checked,
    });
  };
  const handleChangePregunta68 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta68: event.target.checked,
    });
  };
  const handleChangePregunta69 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta69: event.target.checked,
    });
  };
  const handleChangePregunta70 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta70: event.target.checked,
    });
  };
  const handleChangePregunta71 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta71: event.target.checked,
    });
  };
  const handleChangePregunta72 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta72: event.target.checked,
    });
  };
  const handleChangePregunta73 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta73: event.target.checked,
    });
  };
  const handleChangePregunta74 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta74: event.target.checked,
    });
  };
  const handleChangePregunta75 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta75: event.target.checked,
    });
  };
  const handleChangePregunta76 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta76: event.target.checked,
    });
  };
  const handleChangePregunta77 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta77: event.target.checked,
    });
  };
  const handleChangePregunta78 = (event) => {
    guardarChequeoCocinas({
      ...chequeoCocinas,
      pregunta78: event.target.checked,
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  //console.log(chequeoCocinas);

  return (
    <Fragment>
      <Fragment>
        <Grid
          container
          spacing={3}
          style={{ marginTop: "0vw", marginLeft: "0vw" }}
        >
          <Grid item xs={12} md={7} style={{ marginTop: "1vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta1 : null}
                    onChange={handleChangePregunta1}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Controlar funcionamiento de las heladeras."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "0vw" }}>
            <TextField
              id="comentario1"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario1 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario1: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta2 : null}
                    onChange={handleChangePregunta2}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Heladera para cocidos."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario2"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario2 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario2: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta3 : null}
                    onChange={handleChangePregunta3}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Heladera para alimentos de la calle."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario3"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario3 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario3: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta4 : null}
                    onChange={handleChangePregunta4}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Heladera para postres."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario4"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario4 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario4: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta5 : null}
                    onChange={handleChangePregunta5}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Heladera para crudos."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario5"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario5 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario5: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta6 : null}
                    onChange={handleChangePregunta6}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cocina, hornallas."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario6"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario6 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario6: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta7 : null}
                    onChange={handleChangePregunta7}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Horno tiene válvula de seguridad."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario7"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario7 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario7: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta8 : null}
                    onChange={handleChangePregunta8}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Extractor de aire."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario8"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario8 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario8: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta9 : null}
                    onChange={handleChangePregunta9}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Campana de humo y olores con extractor forzado funcionando y limpio."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario9"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario9 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario9: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta10 : null}
                    onChange={handleChangePregunta10}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Detector de gas."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario10"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario10 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario10: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta11 : null}
                    onChange={handleChangePregunta11}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Detector de humo."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario11"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario11 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario11: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta12 : null}
                    onChange={handleChangePregunta12}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Corte Gral. de gas, automático manual."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario12"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario12 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario12: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta13 : null}
                    onChange={handleChangePregunta13}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Teclas de luz con módulo completo y funcionando."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario13"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario13 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario13: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta14 : null}
                    onChange={handleChangePregunta14}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Iluminación funcionando, completa con lámpara y plafón."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario14"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario14 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario14: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta15 : null}
                    onChange={handleChangePregunta15}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Atrapa moscas limpias y funcionando."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario15"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario15 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario15: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta16 : null}
                    onChange={handleChangePregunta16}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Control y desinfección de insectos. Última fecha."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario16"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario16 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario16: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta17 : null}
                    onChange={handleChangePregunta17}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Utiliza protocolo de limpieza."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario17"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario17 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario17: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta18 : null}
                    onChange={handleChangePregunta18}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Mesadas de trabajo."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario18"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario18 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario18: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta19 : null}
                    onChange={handleChangePregunta19}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Bacha profunda firmes con canilla funcionando."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario19"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario19 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario19: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta20 : null}
                    onChange={handleChangePregunta20}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Agua caliente y fría de suministro continuo."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario20"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario20 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario20: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta21 : null}
                    onChange={handleChangePregunta21}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Controlar la aparición de humedades."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario21"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario21 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario21: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta22 : null}
                    onChange={handleChangePregunta22}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Procesadora de alimentos."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario22"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario22 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario22: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta23 : null}
                    onChange={handleChangePregunta23}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Licuadora."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario23"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario23 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario23: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta24 : null}
                    onChange={handleChangePregunta24}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Batidora."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario24"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario24 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario24: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta25 : null}
                    onChange={handleChangePregunta25}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Balanza."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario25"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario25 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario25: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta26 : null}
                    onChange={handleChangePregunta26}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cortador de fiambre."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario26"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario26 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario26: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta61 : null}
                    onChange={handleChangePregunta61}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Picadora de carne."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario61"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario61 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario61: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta27 : null}
                    onChange={handleChangePregunta27}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Freezer en cantidad limpios funcionando."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario27"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario27 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario27: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta28 : null}
                    onChange={handleChangePregunta28}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Congelados fechados."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario28"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario28 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario28: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta29 : null}
                    onChange={handleChangePregunta29}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Congelados menú alternativo una población."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario29"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario29 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario29: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta30 : null}
                    onChange={handleChangePregunta30}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Stock carne 35 kg."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario30"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario30 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario30: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta31 : null}
                    onChange={handleChangePregunta31}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Stock pollo 35 kg."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario31"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario31 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario31: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta32 : null}
                    onChange={handleChangePregunta32}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Stock pescado 10 kg."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario32"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario32 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario32: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta33 : null}
                    onChange={handleChangePregunta33}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Stock congelados 35 kg."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario33"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario33 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario33: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta34 : null}
                    onChange={handleChangePregunta34}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Depósito alimentos secos completos, ordenados con estanterías. Sin alimentos apoyados en el piso."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario34"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario34 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario34: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta35 : null}
                    onChange={handleChangePregunta35}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Usa protocolo de ingreso de mercadería."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario35"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario35 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario35: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta36 : null}
                    onChange={handleChangePregunta36}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Depósito de verduras limpio ordenado con piso y pared lavable con puerta cerrada y ventilación con mosquitero."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario36"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario36 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario36: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta37 : null}
                    onChange={handleChangePregunta37}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Menú de dietas."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario37"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario37 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario37: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta38 : null}
                    onChange={handleChangePregunta38}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Menú del día en cartelera."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario38"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario38 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario38: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta39 : null}
                    onChange={handleChangePregunta39}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Carpeta de cocina."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario39"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario39 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario39: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta40 : null}
                    onChange={handleChangePregunta40}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Listado de residentes al día."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario40"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario40 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario40: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta41 : null}
                    onChange={handleChangePregunta41}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Menús de la semana."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario41"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario41 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario41: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta42 : null}
                    onChange={handleChangePregunta42}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Colaciones."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario42"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario42 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario42: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta43 : null}
                    onChange={handleChangePregunta43}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Vajilla, platos hondos, platos planos, platos de postre, tazas de 250 ml, vasos de 300 ml, cuchara, tenedor, cuchillo, cuchara de postre, paneras platos de café, jarras, bandejas."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario43"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario43 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario43: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta44 : null}
                    onChange={handleChangePregunta44}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Elementos de cocina, ollas con tapa, pizzeras, bandejas para horno, espumadera, cucharon, colador, cuchillas."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario44"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario44 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario44: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta45 : null}
                    onChange={handleChangePregunta45}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cuchilla grande, pelapapas, pisa papas."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario45"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario45 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario45: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta46 : null}
                    onChange={handleChangePregunta46}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Pisos lavables Paredes lavables."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario46"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario46 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario46: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta47 : null}
                    onChange={handleChangePregunta47}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Mosquiteros en ventanas y puertas."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario47"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario47 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario47: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta48 : null}
                    onChange={handleChangePregunta48}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="El personal controla el ingreso de mercadería, pesa y cuenta las unidades. Utiliza protocolo de ingreso mercadería."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario48"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario48 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario48: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta49 : null}
                    onChange={handleChangePregunta49}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Ventanas: con vidrio sano, con cierre en buen estado funcionando y mosquitero."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario49"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario49 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario49: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta50 : null}
                    onChange={handleChangePregunta50}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cocina en Gral. Ventilada, iluminada, Limpia y ordenado."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario50"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario50 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario50: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta51 : null}
                    onChange={handleChangePregunta51}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="El personal usa vestimenta adecuada, ambo, guantes, cofia, birrete, protectores. Empleados."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario51"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario51 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario51: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta52 : null}
                    onChange={handleChangePregunta52}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Bacha sin pérdida, desagüe sin pérdidas ni roturas."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario52"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario52 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario52: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta53 : null}
                    onChange={handleChangePregunta53}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Tablas para corte de varios colores."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario53"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario53 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario53: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta54 : null}
                    onChange={handleChangePregunta54}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Tiene porta planillas para pesos de mercadería."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario54"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario54 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario54: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta55 : null}
                    onChange={handleChangePregunta55}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Tachos de basura húmedos con tapa limpio."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario55"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario55 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario55: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta56 : null}
                    onChange={handleChangePregunta56}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Tacho de basura secos con tapa en condiciones."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario56"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario56 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario56: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta57 : null}
                    onChange={handleChangePregunta57}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Utiliza recipientes para pan."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario57"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario57 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario57: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta58 : null}
                    onChange={handleChangePregunta58}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Utiliza recipientes para huevos."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario58"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario58 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario58: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta59 : null}
                    onChange={handleChangePregunta59}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Dispenser de jabón."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario59"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario59 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario59: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta60 : null}
                    onChange={handleChangePregunta60}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Dispenser de papel seca manos."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario60"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario60 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario60: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta62 : null}
                    onChange={handleChangePregunta62}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Carros de transporte de alimentos limpios."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario62"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario62 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario62: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta63 : null}
                    onChange={handleChangePregunta63}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Carro de transporte en condiciones."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario63"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario63 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario63: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta64 : null}
                    onChange={handleChangePregunta64}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Trasladar la comida en bandeja con tapa."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario64"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario64 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario64: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta65 : null}
                    onChange={handleChangePregunta65}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Existen elementos en desuso o inservibles."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario65"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario65 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario65: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta66 : null}
                    onChange={handleChangePregunta66}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Tiene estanterías."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario66"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario66 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario66: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta67 : null}
                    onChange={handleChangePregunta67}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Alimentos depositados en el piso."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario67"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario67 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario67: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta68 : null}
                    onChange={handleChangePregunta68}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="La empresa capacita al personal."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario68"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario68 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario68: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta69 : null}
                    onChange={handleChangePregunta69}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Ayudante de cocina."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario69"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario69 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario69: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta70 : null}
                    onChange={handleChangePregunta70}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Hay listado de menú alternativo."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario70"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario70 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario70: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta71 : null}
                    onChange={handleChangePregunta71}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Hay manual del menú con fotos."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario71"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario71 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario71: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta72 : null}
                    onChange={handleChangePregunta72}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Hay stock para tres dias."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario72"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario72 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario72: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta73 : null}
                    onChange={handleChangePregunta73}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Existe mercadería en mal estado."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario73"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario73 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario73: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta74 : null}
                    onChange={handleChangePregunta74}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Tiene tuperware suficientes ?."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario74"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario74 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario74: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta75 : null}
                    onChange={handleChangePregunta75}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Paredes limpias, humedad, azulejada."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario75"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario75 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario75: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta76 : null}
                    onChange={handleChangePregunta76}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Hay mercadería apoyada en el piso."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario76"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario76 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario76: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta77 : null}
                    onChange={handleChangePregunta77}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Los estantes se encuentran limpios."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario77"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario77 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario77: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCocinas ? chequeoCocinas.pregunta78 : null}
                    onChange={handleChangePregunta78}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Realiza encuesta de pacientes."
                labelPlacement="end"
                //disabled={chequeoCocinas && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario78"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCocinas ? chequeoCocinas.comentario78 : null}
              onChange={(e) => {
                guardarChequeoCocinas({
                  ...chequeoCocinas,
                  comentario78: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} />
        </Grid>
      </Fragment>
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
