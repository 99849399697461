import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Sidebar } from "primereact/sidebar";
import { PanelMenu } from "primereact/panelmenu";
import "../../assets/css/menu.css";
import { Navigate } from "react-router";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import logo from "../../assets/images/logo.png";
import { Tooltip } from "primereact/tooltip";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";

function Menu(props) {
  const [visible, setVisible] = useState(false);

  const authContext = useContext(AuthContext);
  const { state, autenticado, usuario, usuarioAutenticado, cerrarSesion } =
    authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    establecimientosUsuarios,
    geriatricoElegido,
    setGeriatricoElegido,
    obtenerEstablecimientosUsuarios,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, [autenticado]);

  useEffect(() => {
    if (usuario) {
      obtenerEstablecimientosUsuarios({ usuario });
      setGeriatricoElegido({
        id: Number(localStorage.getItem("geriatricoElegidoId")),
        nombre: localStorage.getItem("geriatricoElegidoNombre"),
        domicilio: localStorage.getItem("geriatricoElegidoDomicilio"),
        telefono: localStorage.getItem("geriatricoElegidoTelefono"),
      });
    }
  }, [usuario]);

  const items = [
    {
      label: "Archivos",
      icon: "pi pi-fw pi-file",
      items: [
        {
          label: "Residencias",
          icon: "pi pi-flag",
          url: "/residencias",
        },
        {
          label: "Diagnósticos",
          icon: "pi pi-heart",
          url: "/diagnosticos",
        },
        {
          label: "Estudios",
          icon: "pi pi-map",
          url: "/estudios",
        },
        {
          label: "Obras Sociales Médicas",
          icon: "pi pi-sitemap",
          url: "/obrassocialesmedicas",
        },
        {
          label: "Obras Sociales Contrato",
          icon: "pi pi-sitemap",
          url: "/obrassociales",
        },
        {
          label: "Planes",
          icon: "pi pi-check-square",
          url: "/planes",
        },
        {
          label: "Valoración Geriátrica Integral",
          icon: "pi pi-ellipsis-v",
          items: [
            {
              label: "Médica",
              icon: "pi pi-briefcase",
              url: "/frasesvgihc/medica",
            },
            {
              label: "Social",
              icon: "pi pi-users",
              url: "/frasesvgihc/social",
            },
            {
              label: "Psicológica",
              icon: "pi pi-comments",
              url: "/frasesvgihc/psicologica",
            },
            {
              label: "Nutricional",
              icon: "pi pi-apple",
              url: "/frasesvgihc/nutricional",
            },
          ],
        },
        {
          label: "Clínicas",
          icon: "pi pi-building",
          url: "/clinicas",
        },
        {
          label: "Coseguros",
          icon: "pi pi-dollar",
          url: "/coseguros",
        },
        {
          label: "Pañales",
          icon: "pi pi-volume-off",
          url: "/panales",
        },
        {
          label: "Descartables",
          icon: "pi pi-trash",
          url: "/descartables",
        },
        {
          label: "Medicaciónes",
          icon: "pi pi-filter",
          items: [
            {
              label: "Medicamentos",
              icon: "pi pi-stop",
              url: "/medicaciones",
            },
            {
              label: "Unidades",
              icon: "pi pi-hashtag",
              url: "/unidades",
            },
            {
              label: "Vías",
              icon: "pi pi-slack",
              url: "/vias",
            },
            {
              label: "Velocidades",
              icon: "pi pi-fast-forward",
              url: "/velocidades",
            },
          ],
        },
        {
          label: "Vínculos",
          icon: "pi pi-heart-fill",
          url: "/vinculos",
        },
        {
          label: "Localidades",
          icon: "pi pi-map-marker",
          url: "/localidades",
        },
        {
          label: "Usuarios",
          icon: "pi pi-user-plus",
          url: "/account",
        },
        {
          label: "Accesos",
          icon: "pi pi-fw pi-external-link",
          items: [
            {
              label: "Menús del Sistema",
              icon: "pi pi-fw pi-calendar-plus",
              url: "/accesos",
            },
            {
              label: "Residencias",
              icon: "pi pi-fw pi-calendar-minus",
              url: "/accesosresidencias",
            },
          ],
        },
      ],
    },
    {
      label: "Residentes",
      icon: "pi pi-users",
      url: "/residentes",
    },
    {
      label: "Pre-Ingresos",
      icon: "pi pi-history",
      url: "/preingresos",
    },
    {
      label: "Historias Clínicas",
      icon: "pi pi-chart-line",
      url: "/historiasclinicas",
    },
    {
      label: "Evaluación Periódica",
      icon: "pi pi-clock",
      url: "/evaluacionperiodica",
    },
    {
      label: "Medicación",
      icon: "pi pi-box",
      items: [
        {
          label: "Residentes",
          icon: "pi pi-users",
          url: "/medicacion",
        },
        {
          label: "Recetas",
          icon: "pi pi-map",
          url: "/recetas",
        },
      ],
    },
    {
      label: "Eventos",
      icon: "pi pi-exclamation-circle",
      url: "/eventos",
    },
    {
      label: "Evoluciones",
      icon: "pi pi-arrow-up-right",
      url: "/evoluciones",
    },
    /*
    {
      label: "Protocolos",
      icon: "pi pi-exclamation-triangle",
      url: "/protocolos",
    },
    {
      label: "Controles",
      icon: "pi pi-fw pi-file",
      items: [
        {
          label: "Diabetes",
          icon: "pi pi-pencil",
          url: "/diabetes",
        },
        {
          label: "Escaras",
          icon: "pi pi-circle-fill",
          url: "/escaras",
        },
        {
          label: "Caídas",
          icon: "pi pi-thumbs-down",
          url: "/escaras",
        },
        {
          label: "Escabiosis",
          icon: "pi pi-circle-fill",
          url: "/escaras",
        },
      ],
    },
    */
    {
      label: "Planilla Mensual",
      icon: "pi pi-align-justify",
      url: "/planillamensual",
    },
    {
      label: "Notas de Difusión",
      icon: "pi pi-pencil",
      url: "/notas",
    },
        {
          label: "Certificado de Residencia",
          icon: "pi pi-pen-to-square",
          url: "certificadoresidencia",
        },
    {
      label: "Stock",
      icon: "pi pi-chart-line",
      items: [
        {
          label: "Artículos",
          icon: "pi pi-apple",
          url: "/articulos",
        },
        {
          label: "Movimientos",
          icon: "pi pi-arrows-v",
          url: "/movimientos",
        },
        {
          label: "Listado",
          icon: "pi pi-list",
          url: "/listadostock",
        },
      ],
    },
    {
      label: "Chequeos Períodicos",
      icon: "pi pi-check",
      url: "/chequeos",
    },
    {
      label: "Listados",
      icon: "pi pi-list",
      items: [
        {
          label: "Residentes",
          icon: "pi pi-users",
          url: "/listadospacientes",
        },
        {
          label: "Eventos",
          icon: "pi pi-exclamation-circle",
          items: [
            {
              label: "Diabetes",
              icon: "pi pi-pencil",
              url: "/listadosdiabetes",
            },
            {
              label: "Caídas",
              icon: "pi pi-thumbs-down",
              url: "/listadoscaidas",
            },
            {
              label: "Ulceras por Presión",
              icon: "pi pi-circle-fill",
              url: "/listadosulceras",
            },
            {
              label: "Enfermería",
              icon: "pi pi-bell",
              url: "/listadosenfermeria",
            },
            {
              label: "Anticoagulados",
              icon: "pi pi-sort",
              url: "/listadosanticoagulados",
            },
          ],
        },
        {
          label: "Evaluaciones Periódicas",
          icon: "pi pi-arrow-up-right",
          url: "/listadosevaluaciones",
        },
        {
          label: "Indicaciones Médicas",
          icon: "pi pi-box",
          url: "/listadosindicacionesmedicas",
        },
        {
          label: "Total Medicamentos",
          icon: "pi pi-calculator",
          url: "/listadostotalmedicamentos",
        },
        {
          label: "Dietas",
          icon: "pi pi-apple",
          items: [
            {
              label: "Por Residente",
              icon: "pi pi-user",
              url: "/listadosdietasporresidente",
            },
            {
              label: "Por Dieta",
              icon: "pi pi-list",
              url: "/listadosdietaspordieta",
            },
          ],
        },
        {
          label: "Recetas",
          icon: "pi pi-map",
          url: "/listadosrecetas",
        },
      ],
    },
  ];

  const leftContents = (
    <Fragment>
      {/*
      <Button label="New" icon="pi pi-plus" className="p-mr-2" />
      <Button label="Upload" icon="pi pi-upload" className="p-button-success" />
      <i className="pi pi-bars p-toolbar-separator p-mr-2" />
      <SplitButton
        label="Save"
        icon="pi pi-check"
        model={items}
        className="p-button-warning"
      ></SplitButton>
      */}

      {geriatricoElegido !== null &&
      geriatricoElegido.id !== null &&
      geriatricoElegido.id !== 0 ? (
        <Fragment>
          <Sidebar
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ backgroundColor: "#336CA6" }}
          >
            <PanelMenu model={items} style={{ width: "250px" }} />
          </Sidebar>

          <Button
            icon="pi pi-bars"
            onClick={(e) => setVisible(true)}
            tooltip="Menú"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
          />
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              height="50"
              style={{ marginLeft: "2em", marginTop: "-0.3em" }}
            />
          </Link>

          <Typography
            style={{
              marginLeft: "3vw",
              color: "black",
              textAlign: "center",
              fontWeight: "bold",
              fontStyle: "italic",
            }}
            variant="h4"
            gutterBottom
            component="div"
          >
            {geriatricoElegido.nombre}
          </Typography>
        </Fragment>
      ) : null}
    </Fragment>
  );

  const rightContents = (
    <Fragment>
      {usuario === undefined || usuario === null ? (
        <Button
          icon="pi pi-sign-in"
          className="p-mr-2"
          onClick={() => (window.location.href = "/login")}
          tooltip="Iniciar Sesión"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
        />
      ) : (
        <Fragment>
          {establecimientosUsuarios ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={establecimientosUsuarios}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} nombre="Geriátrico" />
              )}
              onChange={(event, value) => {
                if (value) {
                  setGeriatricoElegido({
                    id: Number(value.id),
                    nombre: value.label,
                  });
                  localStorage.setItem("geriatricoElegidoId", value.id);
                  localStorage.setItem("geriatricoElegidoNombre", value.label);
                  localStorage.setItem("geriatricoElegidoDomicilio", value.domicilio);
                  localStorage.setItem("geriatricoElegidoTelefono", value.telefono);
                  window.location.href = "/";
                }
              }}
            />
          ) : null}
          <Button
            style={{ marginLeft: "1vw" }}
            icon="pi pi-sign-out"
            className="p-mr-2"
            onClick={() => cerrarSesion()}
            tooltip="Cerrar Sesión"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
          />
        </Fragment>
      )}
      {/*
      <Button icon="pi pi-calendar" className="p-button-success p-mr-2" />
      <Button icon="pi pi-times" className="p-button-danger" />
      */}
    </Fragment>
  );

  const handleClick = useCallback((path) => {
    return () => {
      props.history.push(path);
    };
  }, []);

  //console.log(geriatricoElegido)

  return (
    <div>
      {usuario !== undefined && usuario !== null ? (
        <Toolbar left={leftContents} right={rightContents} />
      ) : (
        <Toolbar right={rightContents} />
      )}
    </div>
  );
}

export default Menu;
