import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";

export default function ChequeoHabitaciones(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    chequeoHabitaciones,
    statusAcceso,
    statusAcceso3,
    localidades,
    guardarChequeoHabitaciones,
    obtenerAcceso,
  } = serviciosContext;

  const handleChangePregunta1 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta1: event.target.checked,
    });
  };

  const handleChangePregunta2 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta2: event.target.checked,
    });
  };

  const handleChangePregunta3 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta3: event.target.checked,
    });
  };

  const handleChangePregunta4 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta4: event.target.checked,
    });
  };

  const handleChangePregunta5 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta5: event.target.checked,
    });
  };

  const handleChangePregunta6 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta6: event.target.checked,
    });
  };

  const handleChangePregunta7 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta7: event.target.checked,
    });
  };

  const handleChangePregunta8 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta8: event.target.checked,
    });
  };

  const handleChangePregunta9 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta9: event.target.checked,
    });
  };

  const handleChangePregunta10 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta10: event.target.checked,
    });
  };

  const handleChangePregunta11 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta11: event.target.checked,
    });
  };

  const handleChangePregunta12 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta12: event.target.checked,
    });
  };

  const handleChangePregunta13 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta13: event.target.checked,
    });
  };

  const handleChangePregunta14 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta14: event.target.checked,
    });
  };

  const handleChangePregunta15 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta15: event.target.checked,
    });
  };

  const handleChangePregunta16 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta16: event.target.checked,
    });
  };

  const handleChangePregunta17 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta17: event.target.checked,
    });
  };

  const handleChangePregunta18 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta18: event.target.checked,
    });
  };

  const handleChangePregunta19 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta19: event.target.checked,
    });
  };

  const handleChangePregunta20 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta20: event.target.checked,
    });
  };

  const handleChangePregunta21 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta21: event.target.checked,
    });
  };

  const handleChangePregunta22 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta22: event.target.checked,
    });
  };

  const handleChangePregunta23 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta23: event.target.checked,
    });
  };

  const handleChangePregunta24 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta24: event.target.checked,
    });
  };

  const handleChangePregunta25 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta25: event.target.checked,
    });
  };

  const handleChangePregunta26 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta26: event.target.checked,
    });
  };

  const handleChangePregunta27 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta27: event.target.checked,
    });
  };

  const handleChangePregunta28 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta28: event.target.checked,
    });
  };

  const handleChangePregunta29 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta29: event.target.checked,
    });
  };
  const handleChangePregunta30 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta30: event.target.checked,
    });
  };
  const handleChangePregunta31 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta31: event.target.checked,
    });
  };
  const handleChangePregunta32 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta32: event.target.checked,
    });
  };
  const handleChangePregunta33 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta33: event.target.checked,
    });
  };
  const handleChangePregunta34 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta34: event.target.checked,
    });
  };
  const handleChangePregunta35 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta35: event.target.checked,
    });
  };
  const handleChangePregunta36 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta36: event.target.checked,
    });
  };
  const handleChangePregunta37 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta37: event.target.checked,
    });
  };
  const handleChangePregunta38 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta38: event.target.checked,
    });
  };
  const handleChangePregunta39 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta39: event.target.checked,
    });
  };
  const handleChangePregunta40 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta40: event.target.checked,
    });
  };
  const handleChangePregunta41 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta41: event.target.checked,
    });
  };
  const handleChangePregunta42 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta42: event.target.checked,
    });
  };
  const handleChangePregunta43 = (event) => {
    guardarChequeoHabitaciones({
      ...chequeoHabitaciones,
      pregunta43: event.target.checked,
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  //console.log(chequeoHabitaciones);

  return (
    <Fragment>
      <Fragment>
        <Grid
          container
          spacing={3}
          style={{ marginTop: "1vw", marginLeft: "0vw" }}
        >
          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta1 : null}
                    onChange={handleChangePregunta1}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Presenta indicación de número correlativo y factor ocupacional."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta2 : null}
                    onChange={handleChangePregunta2}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Puerta en buen estado, que cierre correctamente, completa con 3 bisagras y picaporte de los 2 lados estilo sanatorial."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta3 : null}
                    onChange={handleChangePregunta3}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Mobiliario en buen estado, camas firmes, limpias, acordes al tamaño de la habitación."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta4 : null}
                    onChange={handleChangePregunta4}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Barandas para cama. Funcionando."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta5 : null}
                    onChange={handleChangePregunta5}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Teclas de luz con módulo completo y funcionando."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta6 : null}
                    onChange={handleChangePregunta6}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Iluminación funcionando, completa con lámpara y plafón."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta7 : null}
                    onChange={handleChangePregunta7}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Llamadores."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta8 : null}
                    onChange={handleChangePregunta8}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Luz cabecera."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta9 : null}
                    onChange={handleChangePregunta9}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Luz de emergencia."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta10 : null}
                    onChange={handleChangePregunta10}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Ventiladores funcionando. Limpios."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta11 : null}
                    onChange={handleChangePregunta11}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Pisos enteros lavables sin fisuras ni grietas."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta12 : null}
                    onChange={handleChangePregunta12}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Mesa de luz completa limpia con tiradores y en condiciones de uso."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta13 : null}
                    onChange={handleChangePregunta13}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Armarios completos en condiciones de uso. placares enteros. Tienen las puertas, con cajones y manijas."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta14 : null}
                    onChange={handleChangePregunta14}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Sillas de apoyo. Mesa."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta15 : null}
                    onChange={handleChangePregunta15}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Controlar aparición de humedades."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta16 : null}
                    onChange={handleChangePregunta16}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Pintura en techos y paredes."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta17 : null}
                    onChange={handleChangePregunta17}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Ventanas: con vidrio sano, protección al vacío, mosquiteros."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta18 : null}
                    onChange={handleChangePregunta18}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cortinas. Limpias, enteras, con buen funcionamiento."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta19 : null}
                    onChange={handleChangePregunta19}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Decoración, ambientación, cuadros, espejos."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta20 : null}
                    onChange={handleChangePregunta20}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Temperatura acorde a la estación."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta21 : null}
                    onChange={handleChangePregunta21}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Limpieza y orden."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta22 : null}
                    onChange={handleChangePregunta22}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Hay cables a la vista, sueltos."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta23 : null}
                    onChange={handleChangePregunta23}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Enchufes y teclas de luz funcionando."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta24 : null}
                    onChange={handleChangePregunta24}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Sensor de humo."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta25 : null}
                    onChange={handleChangePregunta25}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Las camas están armadas para la hora del día."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta26 : null}
                    onChange={handleChangePregunta26}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Tienen sábanas, colcha. En buen estado."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta27 : null}
                    onChange={handleChangePregunta27}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Almohada funda."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta28 : null}
                    onChange={handleChangePregunta28}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Zalea."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta29 : null}
                    onChange={handleChangePregunta29}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Colchón en buen estado, alta densidad, con forro."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta30 : null}
                    onChange={handleChangePregunta30}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Colchón anti escaras funcionando, con motor."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta31 : null}
                    onChange={handleChangePregunta31}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Hay sillas de ruedas sin uso. Apoya pies sin uso. Rotos."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoHabitaciones ? chequeoHabitaciones.pregunta32 : null}
                    onChange={handleChangePregunta32}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Placares en condiciones ordenados. Tienen la ropa colgada, ordenada, tienen cajones."
                labelPlacement="end"
                //disabled={chequeoHabitaciones && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>


          <Grid item xs={12} md={12} />

          <Grid item xs={12} md={2}>
            <TextField
              id="habitacionNum1"
              label="Habitación Nº"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionNum1 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionNum1: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="habitacionOcupacion1"
              label="Ocupación"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionOcupacion1 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionOcupacion1: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="habitacionComentario1"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionComentario1 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionComentario1: e.target.value,
                });
              }}
            />
          </Grid>


          <Grid item xs={12} md={2}>
            <TextField
              id="habitacionNum2"
              label="Habitación Nº"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionNum2 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionNum2: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="habitacionOcupacion2"
              label="Ocupación"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionOcupacion2 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionOcupacion2: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="habitacionComentario2"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionComentario2 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionComentario2: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="habitacionNum3"
              label="Habitación Nº"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionNum3 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionNum3: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="habitacionOcupacion3"
              label="Ocupación"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionOcupacion3 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionOcupacion3: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="habitacionComentario3"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionComentario3 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionComentario3: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="habitacionNum4"
              label="Habitación Nº"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionNum4 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionNum4: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="habitacionOcupacion4"
              label="Ocupación"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionOcupacion4 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionOcupacion4: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="habitacionComentario4"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionComentario4 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionComentario4: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="habitacionNum5"
              label="Habitación Nº"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionNum5 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionNum5: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="habitacionOcupacion5"
              label="Ocupación"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionOcupacion5 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionOcupacion5: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="habitacionComentario5"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionComentario5 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionComentario5: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="habitacionNum6"
              label="Habitación Nº"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionNum6 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionNum6: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="habitacionOcupacion6"
              label="Ocupación"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionOcupacion6 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionOcupacion6: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="habitacionComentario6"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionComentario6 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionComentario6: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="habitacionNum7"
              label="Habitación Nº"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionNum7 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionNum7: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="habitacionOcupacion7"
              label="Ocupación"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionOcupacion7 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionOcupacion7: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="habitacionComentario7"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionComentario7 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionComentario7: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="habitacionNum8"
              label="Habitación Nº"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionNum8 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionNum8: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="habitacionOcupacion8"
              label="Ocupación"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionOcupacion8 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionOcupacion8: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="habitacionComentario8"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionComentario8 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionComentario8: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="habitacionNum9"
              label="Habitación Nº"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionNum9 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionNum9: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="habitacionOcupacion9"
              label="Ocupación"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionOcupacion9 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionOcupacion9: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="habitacionComentario9"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionComentario9 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionComentario9: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="habitacionNum10"
              label="Habitación Nº"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionNum10 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionNum10: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="habitacionOcupacion10"
              label="Ocupación"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionOcupacion10 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionOcupacion10: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="habitacionComentario10"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoHabitaciones ? chequeoHabitaciones.habitacionComentario10 : null}
              onChange={(e) => {
                guardarChequeoHabitaciones({
                  ...chequeoHabitaciones,
                  habitacionComentario10: e.target.value,
                });
              }}
            />
          </Grid>


        </Grid>
      </Fragment>
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
