import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { InputTextarea } from "primereact/inputtextarea";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export default function Medicacion(props) {

  let navigate = useNavigate();
  const toast = useRef(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [globalFilter, setGlobalFilter] = useState(null);

  const [visible, setVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [programada, guardarProgramada] = useState(null);
  const [dialogPacientes, setDialogPacientes] = useState(false);
  const [dialogProgramada, setDialogProgramada] = useState(false);
  const [time, setTime] = useState(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    dietas,
    practicas,
    barthel,
    fragilidad,
    lawtonBrody,
    yesavage,
    tinetti,
    norton,
    diagnosticos,
    programadas,
    horarios,
    medicaciones,
    medicacionesPacientes,
    paciente,
    frasesVgi,
    frases,
    frasesVGI,
    evolucion,
    vgi,
    obrasSociales,
    obrasSocialesMedicas,
    ingresosEgresos,
    selectedRowPaciente,
    pacientes,
    geriatricoElegido,
    unidades,
    vias,
    velocidades,
    diagnosticosPacientes,
    statusAcceso3,
    localidades,
    guardarProgramadaPeriodica,
    obtenerAcceso,
    agregarFrasesVGI,
    agregarFrases,
    obtenerFrases,
    obtenerFrasesVGI,
    obtenerDiagnosticosPacientes,
    obtenerMedicacionesPacientes,
    obtenerMedicacionesPacientesIndividual,
    obtenerDiagnosticosPacientesIndividual,
    setSelectedRowPaciente,
    obtenerPacientes,
    setGeriatricoElegido,
    obtenerProgramadas,
    obtenerIngresosEgresos,
    obtenerObrasSocialesPacientes,
    obtenerObrasSocialesMedicasPacientes,
    obtenerInformesPreIngreso,
    guardarEvolucion,
    guardarVgi,
    agregarProgramada,
    actualizarProgramada,
    obtenerHorarios,
    actualizarHorario,
    agregarHorario,
    eliminarHorario,
    guardarHorarios,
    obtenerUnidades,
    obtenerVias,
    obtenerVelocidades,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Medicacion",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    obtenerPacientes({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    });
    obtenerObrasSocialesPacientes({ todos: false });
    obtenerObrasSocialesMedicasPacientes({ todos: false });
    obtenerVias();
    obtenerVelocidades()

    //obtenerDiagnosticosPacientes({ todos: false });
    //obtenerMedicacionesPacientes({ todos: false });
    //obtenerUnidades();
    //obtenerVias();
    //obtenerFrases();
    //obtenerFrasesVGI();
    //obtenerObrasSocialesPacientes({ todos: false });
    //obtenerObrasSocialesMedicasPacientes({ todos: false });
  }, []);

  const addClick = (e) => {
    setDialogPacientes(true);
    setSelectedRowPaciente(null);
  };

  const diasSemana = [
    { id: 1, nombre: "L" },
    { id: 2, nombre: "M" },
    { id: 3, nombre: "X" },
    { id: 4, nombre: "J" },
    { id: 5, nombre: "V" },
    { id: 6, nombre: "S" },
    { id: 7, nombre: "D" },
  ];


  const exportColumns = [
    { title: "Medicación", dataKey: "label" },
    { title: "Inicio", dataKey: "inicio" },
    { title: "Final", dataKey: "final" },
    { title: "Dosis Mg.", dataKey: "dosis" },
    { title: "Vía", dataKey: "via" },
    { title: "Velocidad", dataKey: "velocidad" },
    { title: "Necesidad", dataKey: "necesidad" },
    { title: "Días", dataKey: "dias" },
    { title: "Hora 1", dataKey: "headerHora1" },
    { title: "D", dataKey: "d" },
    { title: "A", dataKey: "a" },
    { title: "M", dataKey: "m" },
    { title: "C", dataKey: "c" },
    { title: "Hora 2", dataKey: "headerHora2" },
    { title: "Cantidad", dataKey: "cantidad" },
  ];

  const exportPdf = () => {
    let data = [];
    let hoy = new Date();

    for (var i = 0; i < medicacionesPacientes.length; i++) {
      if (medicacionesPacientes[i].activo) {
        data.push(medicacionesPacientes[i]);
      }
    }

    const array = data.map((i) => ({ ...i }));

    for (var i = 0; i < array.length; i++) {
      if (array[i].inicio) {
        array[i].inicio = formatDate2(array[i].inicio);
      }
      if (array[i].final) {
        array[i].final = formatDate2(array[i].final);
      }
    }

    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.setFontSize(12);

        doc.setFont("Time-BoldItalic", "bold");
        doc.text(geriatricoElegido.nombre, 15, 8);

        doc.rect(10, 12, 190, 30);

        doc.setFontSize(14);

        doc.setFont("Time-BoldItalic", "bold");
        doc.text("INDICACIONES MEDICAS", 65, 20);

        doc.setFont("Times-Roman", "normal");

        doc.setFontSize(10);

        let row = 25;

        doc.text(
          "Paciente: " +
            (selectedRowPaciente !== undefined &&
            selectedRowPaciente !== null &&
            selectedRowPaciente.nombre !== undefined &&
            selectedRowPaciente.nombre !== null
              ? selectedRowPaciente.nombre
              : ""),
          12,
          row,
          "left",
          "middle"
        );

        doc.text(
          "D.N.I.: " +
            (selectedRowPaciente !== undefined &&
            selectedRowPaciente !== null &&
            selectedRowPaciente.numeroDocumento !== undefined &&
            selectedRowPaciente.numeroDocumento !== null
              ? selectedRowPaciente.numeroDocumento
              : ""),

          130,
          row,
          "left",
          "middle"
        );

        row = row + 5;

        doc.text(
          "Fecha Nacimiento: " +
            (selectedRowPaciente !== undefined &&
            selectedRowPaciente !== null &&
            selectedRowPaciente.fechaNacimiento !== undefined &&
            selectedRowPaciente.fechaNacimiento !== null
              ? formatDate2(selectedRowPaciente.fechaNacimiento)
              : ""),
          12,
          row,
          "left",
          "middle"
        );

        doc.text(
          "Edad: " +
            (selectedRowPaciente !== undefined &&
            selectedRowPaciente !== null &&
            selectedRowPaciente.fechaNacimiento !== undefined &&
            selectedRowPaciente.fechaNacimiento !== null
              ? getEdad(selectedRowPaciente.fechaNacimiento)
              : ""),
          130,
          row,
          "left",
          "middle"
        );

        row = row + 5;

        doc.text(
          "Fecha de Ingreso a la Institución: " +
            (selectedRowPaciente !== undefined &&
            selectedRowPaciente !== null &&
            ingresosEgresos &&
            selectedRowPaciente.fechaNacimiento !== undefined &&
            selectedRowPaciente.fechaNacimiento !== null
              ? formatDate2(ingresosEgresos[0].fecha)
              : ""),
          12,
          row,
          "left",
          "middle"
        );

        doc.text(
          "Obra Social Contrato: " +
            (selectedRowPaciente !== undefined &&
            selectedRowPaciente !== null &&
            selectedRowPaciente.obraSocialContratoId !== undefined
              ? obrasSociales[
                  obrasSociales.findIndex(
                    (item) =>
                      item.id === selectedRowPaciente.obraSocialContratoId
                  )
                ].label
              : ""),
          130,
          row,
          "left",
          "middle"
        );

        row = row + 5;

        doc.text(
          "Obra Social Médica: " +
            (selectedRowPaciente !== undefined &&
            selectedRowPaciente !== null &&
            selectedRowPaciente.obraSocialId !== undefined
              ? obrasSocialesMedicas[
                  obrasSocialesMedicas.findIndex(
                    (item) => item.id === selectedRowPaciente.obraSocialId
                  )
                ].label
              : ""),
          12,
          row,
          "left",
          "middle"
        );

        doc.text(
          "Nº Obra Social: " +
            (selectedRowPaciente !== undefined &&
            selectedRowPaciente !== null &&
            selectedRowPaciente.numeroObraSocial !== undefined &&
            selectedRowPaciente.numeroObraSocial !== null
              ? selectedRowPaciente.numeroObraSocial
              : ""),

          130,
          row,
          "left",
          "middle"
        );

        row = row + 10;

        doc.text("Fecha: " + formatDate(hoy), 12, row, "left", "middle");

        row = row + 10;

        doc.setFontSize(9);

        doc.text(
          "DIETA GENERAL: " +
            (dietas ? dietas.map((dieta) => "•" + dieta.label + "  ") : ""),

          10,
          row,
          "left",
          "middle"
        );

        row = row + 5;

        doc.text(
          "PRACTICAS: " +
            (practicas ? practicas.map((practica) => "•" + practica.label + "  ") : ""),

          10,
          row,
          "left",
          "middle"
        );

        row = row + 5;

        doc.autoTable(exportColumns, array, {
          margin: { top: row, left: 10 },
          styles: {
            fontSize: 7,
          },
        });
        window.open(doc.output("bloburl"));
        //doc.save("localidades.pdf");
      });
    });
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHidePacientes = (e) => {
    setSelectedRowPaciente(null);
    setDialogPacientes(false);
  };

  const aceptarProgramada = (e) => {
    e.preventDefault();

    if (
      !programada ||
      programada.inicio === undefined ||
      programada.inicio === null ||
      programada.inicio === ""
    ) {
      mensajeAlerta("Medicaciones", "Fecha de inicio es necesaria !.", "error");
      return;
    }

    if (
      !programada ||
      programada.dosis === undefined ||
      programada.dosis === null ||
      programada.dosis === ""
    ) {
      mensajeAlerta("Medicaciones", "La dosis es necesaria !.", "error");
      return;
    }

    if (
      !programada ||
      programada.viaId === undefined ||
      programada.viaId === null ||
      programada.viaId === ""
    ) {
      mensajeAlerta("Medicaciones", "La vía es necesaria !.", "error");
      return;
    }

    if (
      programada &&
      programada.hora1 &&
      (programada.cantidadHora1 === null ||
        programada.cantidadHora1 === "" ||
        programada.cantidadHora1 === 0)
    ) {
      mensajeAlerta(
        "Medicaciones",
        "Cantidad en hora 1 es necesaria !.",
        "error"
      );
      return;
    }

    if (
      (!programada ||
        programada.cantidadHora1 === undefined ||
        programada.cantidadHora1 === null ||
        programada.cantidadHora1 === "" ||
        programada.cantidadHora1 === 0) &&
      (!programada ||
        programada.d === undefined ||
        programada.d === null ||
        programada.d === "" ||
        programada.d === 0) &&
      (!programada ||
        programada.a === undefined ||
        programada.a === null ||
        programada.a === "" ||
        programada.a === 0) &&
      (!programada ||
        programada.m === undefined ||
        programada.m === null ||
        programada.m === "" ||
        programada.m === 0) &&
      (!programada ||
        programada.c === undefined ||
        programada.c === null ||
        programada.c === "" ||
        programada.c === 0) &&
      (!programada ||
        programada.cantidadHora2 === undefined ||
        programada.cantidadHora2 === null ||
        programada.cantidadHora2 === "" ||
        programada.cantidadHora2 === 0)
    ) {
      mensajeAlerta(
        "Medicaciones",
        "Debe ingresar un horario o comida !.",
        "error"
      );
      return;
    }

    if (
      programada &&
      programada.hora2 &&
      (programada.cantidadHora2 === null ||
        programada.cantidadHora2 === "" ||
        programada.cantidadHora2 === 0)
    ) {
      mensajeAlerta(
        "Medicaciones",
        "Cantidad en hora 2 es necesaria !.",
        "error"
      );
      return;
    }

    if (selectedRow) {
      actualizarProgramada({
        programada,
        pacienteId: selectedRowPaciente.id,
        usuario: state.usuario,
      });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerMedicacionesPacientesIndividual({
        pacienteId: selectedRowPaciente.id,
        tipo: "M",
      });
    }, 1000);
  };

  const aceptarClickPacientes = (e) => {
    if (selectedRowPaciente) {
      //verificarHistoriaClinica({ selectedRowPaciente });
      //obtenerInformeFragilidad({ pacienteId: selectedRowPaciente.id });
      //obtenerInformesHistoriaClinica({ pacienteId: selectedRowPaciente.id });

      obtenerMedicacionesPacientesIndividual({
        pacienteId: selectedRowPaciente.id,
        tipo: "M",
      });
      /*
      obtenerDiagnosticosPacientesIndividual({
        pacienteId: selectedRowPaciente.id,
      });
      obtenerProgramadas({
        pacienteId: selectedRowPaciente.id,
      });
      /*
      obtenerIngresosEgresos({ pacienteId: selectedRowPaciente.id });
      obtenerInformesPreIngreso({ pacienteId: selectedRowPaciente.id });
      */
      setDialogPacientes(false);
      setDialogProgramada(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  const onHideProgramadas = (e) => {
    setDialogProgramada(false);
    //setDialogCaidas(false);
  };

  const editClickProgramada = (e) => {
    if (selectedRow) {
      let array = {
        ...selectedRow,
      };
      /*
      if (array.inicio) {
        array.inicio = fechaReves(array.inicio);
      }
      if (array.final) {
        array.final = fechaReves(array.final);
      }
      */
      guardarProgramada(array);
      /*
      obtenerHorarios({ id: selectedRow.id });
      setSelectedRowHorario(null);
      */
      setTimeout(() => {
        setVisible(true);
      }, 1000);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una medicación.",
        life: 3000,
      });
    }
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  function getEdad(dateString) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate2(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("-");
  }

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Tag value="Control de Medicación"></Tag>
      </div>
    </Fragment>
  );

  const renderFooterPacientes = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickPacientes}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHidePacientes}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeaderProgramadas = () => {
    return (
      <Fragment>
        <div>
          {selectedRowPaciente && programada ? (
            <Tag
              value={
                "Medicación " +
                programada.label +
                " de " +
                selectedRowPaciente.nombre
              }
            ></Tag>
          ) : null}
        </div>
      </Fragment>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e)=> aceptarProgramada(e)}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const headerProgramadas = (
    <Fragment>
      {/*
      <Button
        icon="pi pi-plus"
        tooltip="Agregar"
        className="p-button-rounded p-button-help"
        style={{ marginLeft: "0em" }}
        onClick={addClickProgramada}
      />
      */}
      <Button
        icon="pi pi-pencil"
        tooltip="Editar"
        className="p-button-rounded p-button-success"
        style={{ marginLeft: "1em" }}
        onClick={editClickProgramada}
      />
      <Button
        icon="pi pi-print"
        tooltip="Imprimir"
        className="p-button-rounded p-button-help"
        style={{ marginLeft: "1em" }}
        onClick={exportPdf}
      />
    </Fragment>
  );

  const headerPacientes = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            fullWidth
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Residentes"></Tag>
      </div>
    </Fragment>
  );

  /* INICIO */

  /*FIN*/

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const inicioTemplate = (rowData) => {
    if (rowData.inicio) {
      return (
        <Fragment>
          <span className="p-column-title">Fecha</span>
          {formatDate2(rowData.inicio)}
        </Fragment>
      );
    }
  };

  const finalTemplate = (rowData) => {
    if (rowData.final) {
      return (
        <Fragment>
          <span className="p-column-title">Fecha</span>
          {!rowData.final ? "" : formatDate2(rowData.final)}
        </Fragment>
      );
    }
  };

  const dTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">D</span>
        <Checkbox checked={rowData.d}></Checkbox>
      </Fragment>
    );
  };

  const aTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">A</span>
        <Checkbox checked={rowData.a}></Checkbox>
      </Fragment>
    );
  };

  const mTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">M</span>
        <Checkbox checked={rowData.m}></Checkbox>
      </Fragment>
    );
  };

  const cTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">C</span>
        <Checkbox checked={rowData.c}></Checkbox>
      </Fragment>
    );
  };

  const fecha2Template = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDate(rowData.fecha)}
      </Fragment>
    );
  };

  function fechaReves(fecha) {
    var initial = fecha.split(/\//);
    return [initial[2], initial[1], initial[0]].join("-"); //=> 'mm/dd/yyyy'
  }

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const documentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const ingresoEgresoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Ingreso/Egreso</span>
        {formatDate2(rowData.fechaIngresoEgreso)}
      </Fragment>
    );
  };

  const motivoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Motivo</span>
        {rowData.motivoIngresoEgreso}
      </Fragment>
    );
  };

  const activoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Activo</span>
        <Checkbox checked={rowData.activo}></Checkbox>
      </Fragment>
    );
  };

  //console.log(programada);
  //console.log(horarios);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {medicacionesPacientes ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  align: "center",
                  margin: "1em",
                  marginTop:
                    isMobile && !isPortrait
                      ? "22vw"
                      : isMobile && isPortrait
                      ? "55vw"
                      : "3vw",
                }}
              >
                <Button
                  icon="pi pi-search"
                  tooltip="Pacientes"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
              </Grid>

              <Dialog
                header={renderHeaderProgramadas()}
                visible={dialogProgramada}
                maximized={true}
                //maximizable={true}
                closable={true}
                onHide={onHideProgramadas}
                //footer={renderFooterEvoluciones()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "95vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    {medicacionesPacientes ? (
                      <div className="datatable-responsive-demo">
                        <div className="card">
                          <Tooltip
                            target=".export-buttons>button"
                            position="bottom"
                          />
                          <DataTable
                            value={medicacionesPacientes}
                            selection={selectedRow}
                            onSelectionChange={(e) => setSelectedRow(e.value)}
                            selectionMode="single"
                            dataKey="id"
                            header={headerProgramadas}
                            className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                            style={{ fontSize: "small", marginTop: "0vw" }}
                            //globalFilter={globalFilter}
                            emptyMessage="No hay datos."
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            responsiveLayout="scroll"

                            //paginatorLeft={paginatorLeft}
                            //paginatorRight={paginatorRight}
                          >
                            <Column
                              field="id"
                              header="ID"
                              body={idTemplate}
                              style={{ display: "none" }}
                              sortable
                            ></Column>
                            <Column
                              field="label"
                              header="Medicación"
                              //body={documentoTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="inicio"
                              header="Inicio"
                              body={inicioTemplate}
                            ></Column>
                            <Column
                              field="dosis"
                              header="Dosis Mg."
                              //body={inicioTemplate}
                            ></Column>
                            <Column
                              field="via"
                              header="Vía"
                              //body={inicioTemplate}
                            ></Column>
                            <Column
                              field="velocidad"
                              header="Velocidad"
                              //body={inicioTemplate}
                            ></Column>
                            <Column
                              field="necesidad"
                              header="Necesidad"
                              //body={inicioTemplate}
                            ></Column>
                            <Column
                              field="dias"
                              header="Días"
                              //body={inicioTemplate}
                            ></Column>
                            <Column
                              field="headerHora1"
                              header="Hora 1"
                              //body={inicioTemplate}
                            ></Column>
                            <Column
                              field="d"
                              header="D"
                              //body={dTemplate}
                            ></Column>
                            <Column
                              field="a"
                              header="A"
                              //body={aTemplate}
                            ></Column>
                            <Column
                              field="m"
                              header="M"
                              //body={mTemplate}
                            ></Column>
                            <Column
                              field="c"
                              header="C"
                              //body={cTemplate}
                            ></Column>
                            <Column
                              field="headerHora2"
                              header="Hora 2"
                              //body={inicioTemplate}
                            ></Column>
                            <Column
                              field="cantidad"
                              header="Cantidad"
                              //body={inicioTemplate}
                            ></Column>
                            <Column
                              field="final"
                              header="Final"
                              body={finalTemplate}
                            ></Column>
                            <Column
                              field="activo"
                              header="Activo"
                              body={activoTemplate}
                            ></Column>
                          </DataTable>
                        </div>
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                showHeader={false}
                visible={dialogPacientes}
                closable={false}
                footer={renderFooterPacientes()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <Tooltip
                          target=".export-buttons>button"
                          position="bottom"
                        />
                        <DataTable
                          value={pacientes}
                          selection={selectedRowPaciente}
                          onSelectionChange={(e) =>
                            setSelectedRowPaciente(e.value)
                          }
                          selectionMode="single"
                          dataKey="id"
                          header={headerPacientes}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "small", marginTop: "1vw" }}
                          globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          paginator
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          rows={10}
                          rowsPerPageOptions={[10, 20, 50]}
                          responsiveLayout="scroll"

                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                        >
                          <Column
                            field="id"
                            header="ID"
                            body={idTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="nombre"
                            header="Apellido y Nombre"
                            body={nombreTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="domicilio"
                            header="Domicilio"
                            body={domicilioTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="numeroDocumento"
                            header="Documento"
                            body={documentoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="fechaIngresoEgreso"
                            header="Ingreso/Egreso"
                            body={ingresoEgresoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="motivoIngresoEgreso"
                            header="Motivo"
                            body={motivoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="activo"
                            header="Activo"
                            body={activoTemplate}
                            sortable
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderProgramadas()}
                visible={visible}
                closable={true}
                //maximizable
                onHide={onHide}
                footer={renderFooter()}
                breakpoints={{ "960px": "100vw" }}
                style={{ width: "55vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0vw", marginLeft: "0vw" }}
                >
                  <Grid
                    item
                    xs={12}
                    md={3}
                    style={{ marginTop: "-1vw", marginRight: "1vw" }}
                  >
                    <TextField
                      id="fechaInicio"
                      label="Fecha Inicio"
                      type="date"
                      fullWidth
                      required
                      variant="standard"
                      //disabled={selectedRow ? true : false}
                      value={
                        programada && programada.inicio
                          ? programada.inicio
                          : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarProgramada({
                          ...programada,
                          inicio: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={3}
                    style={{ marginTop: "-1vw", marginRight: "1vw" }}
                  >
                    <TextField
                      id="fechaFin"
                      label="Fecha final"
                      type="date"
                      fullWidth
                      required
                      variant="standard"
                      //disabled={selectedRow ? true : false}
                      value={
                        programada && programada.final ? programada.final : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarProgramada({
                          ...programada,
                          final: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={programada ? programada.activo : null}
                          style={{ marginLeft: "1em" }}
                          onChange={(e) =>
                            guardarProgramada({
                              ...programada,
                              activo: e.target.checked,
                            })
                          }
                        />
                      }
                      disabled
                      label="Activo"
                      labelPlacement="start"
                    />
                  </Grid>

                  <Grid item xs={6} md={2} />
                  <Grid item xs={6} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      id="dosis"
                      label="Dosis Mg"
                      fullWidth
                      variant="standard"
                      value={programada ? programada.dosis : null}
                      //disabled={selectedRow ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarProgramada({
                          ...programada,
                          dosis: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="via"
                      fullWidth
                      options={vias}
                      disablePortal
                      required
                      //defaultValue={colores[idColorDefault]}

                      defaultValue={
                        programada && programada.viaId !== undefined
                          ? vias[
                              vias.findIndex(
                                (item) => item.id === programada.viaId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Vía"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarProgramada({
                            ...programada,
                            viaId: value.id,
                            via: value.nombre,
                          });
                        } else {
                          guardarProgramada({
                            ...programada,
                            viaId: null,
                            via: null,
                          });
                        }
                      }}
                    />
                  </Grid>


                  <Grid item xs={12} md={5}>
                    <Autocomplete
                      id="velocidad"
                      fullWidth
                      options={velocidades}
                      disablePortal
                      required
                      //defaultValue={colores[idColorDefault]}

                      defaultValue={
                        programada && programada.velocidadId !== undefined
                          ? velocidades[
                            velocidades.findIndex(
                                (item) => item.id === programada.velocidadId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Velocidad"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarProgramada({
                            ...programada,
                            velocidadId: value.id,
                            velocidad: value.nombre,
                          });
                        } else {
                          guardarProgramada({
                            ...programada,
                            velocidadId: null,
                            velocidad: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} md={6} style={{ marginTop: "-1vw" }}>
                    <TextField
                      id="necesidad"
                      label="Según necesidad"
                      fullWidth
                      variant="standard"
                      value={programada ? programada.necesidad : null}
                      //disabled={selectedRow ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarProgramada({
                          ...programada,
                          necesidad: e.target.value,
                        });
                      }}
                    />
                  </Grid>



                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="dias"
                      fullWidth
                      options={diasSemana}
                      disablePortal
                      required
                      multiple
                      value={programada && programada.diasId}
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Días"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarProgramada({
                            ...programada,
                            diasId: value,
                          });
                        } else {
                          guardarProgramada({
                            ...programada,
                            diasId: null,
                          });
                        }
                      }}
                    />
                  </Grid>


                  <Grid item xs={6} md={3} style={{ marginTop: "0vw" }}>
                    <TextField
                      id="primeraHora"
                      label="Primer Horario"
                      type="time"
                      fullWidth
                      variant="standard"
                      value={programada ? programada.hora1 : null}
                      //disabled={selectedRow ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarProgramada({
                          ...programada,
                          hora1: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} md={3} style={{ marginTop: "0vw" }}>
                    <TextField
                      id="cantidadHora1"
                      label="Cantidad"
                      fullWidth
                      variant="standard"
                      type="number"
                      value={programada ? programada.cantidadHora1 : null}
                      //disabled={selectedRow ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={programada && programada.hora1 === null}
                      onChange={(e) => {
                        guardarProgramada({
                          ...programada,
                          cantidadHora1: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} />

                  <Grid item xs={6} md={3} style={{ marginTop: "0vw" }}>
                    <TextField
                      id="desayuno"
                      label="Desayuno"
                      fullWidth
                      variant="standard"
                      type="number"
                      value={programada ? programada.d : null}
                      //disabled={selectedRow ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarProgramada({
                          ...programada,
                          d: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} md={3} style={{ marginTop: "0vw" }}>
                    <TextField
                      id="almuerzo"
                      label="Almuerzo"
                      fullWidth
                      variant="standard"
                      type="number"
                      value={programada ? programada.a : null}
                      //disabled={selectedRow ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarProgramada({
                          ...programada,
                          a: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} md={3} style={{ marginTop: "0vw" }}>
                    <TextField
                      id="merienda"
                      label="Merienda"
                      fullWidth
                      variant="standard"
                      type="number"
                      value={programada ? programada.m : null}
                      //disabled={selectedRow ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarProgramada({
                          ...programada,
                          m: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} md={3} style={{ marginTop: "0vw" }}>
                    <TextField
                      id="cena"
                      label="Cena"
                      fullWidth
                      variant="standard"
                      type="number"
                      value={programada ? programada.c : null}
                      //disabled={selectedRow ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarProgramada({
                          ...programada,
                          c: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} md={3} style={{ marginTop: "0vw" }}>
                    <TextField
                      id="ultimaHora"
                      label="Ultimo Horario"
                      type="time"
                      fullWidth
                      variant="standard"
                      value={programada ? programada.hora2 : null}
                      //disabled={selectedRow ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarProgramada({
                          ...programada,
                          hora2: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} md={3} style={{ marginTop: "0vw" }}>
                    <TextField
                      id="cantidadHora2"
                      label="Cantidad"
                      fullWidth
                      variant="standard"
                      type="number"
                      value={programada ? programada.cantidadHora2 : null}
                      //disabled={selectedRow ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={programada && programada.hora2 === null}
                      onChange={(e) => {
                        guardarProgramada({
                          ...programada,
                          cantidadHora2: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}
