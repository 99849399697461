import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

export default function Barthel(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const { barthel, statusAcceso3, localidades, guardarBarthel } =
    serviciosContext;

  const handleChangeComer = (event) => {
    guardarBarthel({
      ...barthel,
      comer: event.target.value,
    });
  };

  const handleChangeLavarse = (event) => {
    guardarBarthel({
      ...barthel,
      lavarse: event.target.value,
    });
  };

  const handleChangeVestirse = (event) => {
    guardarBarthel({
      ...barthel,
      vestirse: event.target.value,
    });
  };

  const handleChangeArreglarse = (event) => {
    guardarBarthel({
      ...barthel,
      arreglarse: event.target.value,
    });
  };

  const handleChangeDeposiciones = (event) => {
    guardarBarthel({
      ...barthel,
      deposiciones: event.target.value,
    });
  };

  const handleChangeMiccion = (event) => {
    guardarBarthel({
      ...barthel,
      miccion: event.target.value,
    });
  };

  const handleChangeRetrete = (event) => {
    guardarBarthel({
      ...barthel,
      retrete: event.target.value,
    });
  };

  const handleChangeTrasladarse = (event) => {
    guardarBarthel({
      ...barthel,
      trasladarse: event.target.value,
    });
  };

  const handleChangeDeambular = (event) => {
    guardarBarthel({
      ...barthel,
      deambular: event.target.value,
    });
  };

  const handleChangeEscalones = (event) => {
    guardarBarthel({
      ...barthel,
      escalones: event.target.value,
    });
  };

  //console.log(statusAcceso3);
  //console.log(barthel);

  return (
    <Fragment>
      {barthel ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "0vw", marginLeft: "0vw" }}
          >
            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="comer"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (1) - Comer
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="comer"
                  value={barthel ? barthel.comer : null}
                  onChange={handleChangeComer}
                >
                  <FormControlLabel
                    value="10"
                    control={<Radio />}
                    label="Totalmente independiente."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Necesita ayuda para cortar carne, el pan, etc."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Dependiente."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="lavarse"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (2) - Lavarse
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="lavarse"
                  value={barthel ? barthel.lavarse : null}
                  onChange={handleChangeLavarse}
                >
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Independiente: entra y sale solo del baño."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Dependiente."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="vestirse"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (3) - Vestirse
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="vestirse"
                  value={barthel ? barthel.vestirse : null}
                  onChange={handleChangeVestirse}
                >
                  <FormControlLabel
                    value="10"
                    control={<Radio />}
                    label="Independiente: capaz de ponerse y de quitarse la ropa, abotonarse, atarse los zapatos."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Necesita ayuda."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Dependiente."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="arreglarse"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (4) - Arreglarse
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="arreglarse"
                  value={barthel ? barthel.arreglarse : null}
                  onChange={handleChangeArreglarse}
                >
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Independiente para lavarse la cara, las manos, peinarse, afeitarse, maquillarse, etc."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Dependiente."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="deposiciones"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (5) - Deposiciones
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="deposiciones"
                  value={barthel ? barthel.deposiciones : null}
                  onChange={handleChangeDeposiciones}
                >
                  <FormControlLabel
                    value="10"
                    control={<Radio />}
                    label="Continencia normal."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Ocasionalmente algún episodio de incontinencia, o necesita ayuda para administrarse supositorios o lavativas."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Incontinencia."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="miccion"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (6) - Micción
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="miccion"
                  value={barthel ? barthel.miccion : null}
                  onChange={handleChangeMiccion}
                >
                  <FormControlLabel
                    value="10"
                    control={<Radio />}
                    label="Continencia normal, o es capaz de cuidarse de la sonda si tiene una puesta."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Un episodio diario como máximo de incontinencia, o necesita ayuda para cuidar de la sonda."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Incontinencia."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="retrete"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (7) - Usar el inodoro
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="retrete"
                  value={barthel ? barthel.retrete : null}
                  onChange={handleChangeRetrete}
                >
                  <FormControlLabel
                    value="10"
                    control={<Radio />}
                    label="Independiente para ir al cuarto de aseo, quitarse y ponerse la ropa…"
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Necesita ayuda para ir al inodoro, pero se limpia solo."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Dependiente."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="trasladarse"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (8) - Trasladarse
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="trasladarse"
                  value={barthel ? barthel.trasladarse : null}
                  onChange={handleChangeTrasladarse}
                >
                  <FormControlLabel
                    value="15"
                    control={<Radio />}
                    label="Independiente para ir del sillón a la cama."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="10"
                    control={<Radio />}
                    label="Mínima ayuda física o supervisión para hacerlo."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Necesita gran ayuda, pero es capaz de mantenerse sentado solo."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Dependiente."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="deambular"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (9) - Deambular
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="deambular"
                  value={barthel ? barthel.deambular : null}
                  onChange={handleChangeDeambular}
                >
                  <FormControlLabel
                    value="15"
                    control={<Radio />}
                    label="Independiente, camina solo 50 metros."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="10"
                    control={<Radio />}
                    label="Necesita ayuda física o supervisión para caminar 50 metros."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Independiente en silla de ruedas sin ayuda."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Dependiente."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="escalones"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (10) - Escalones
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="escalones"
                  value={barthel ? barthel.escalones : null}
                  onChange={handleChangeEscalones}
                >
                  <FormControlLabel
                    value="10"
                    control={<Radio />}
                    label="Independiente para bajar y subir escaleras."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Necesita ayuda física o supervisión para hacerlo."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Dependiente."
                    disabled={barthel && barthel.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="caption" display="block" gutterBottom>
                Máxima puntuación: 100 puntos (90 si va en silla de ruedas)
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                Resultado - Grado de dependencia
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                {`<20 Total`}
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                20-35 Grave
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                40-55 Moderado
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                ≥ 60 Leve
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                100 Independiente
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" gutterBottom component="div">
                Resultado
              </Typography>
              <Typography variant="h4" gutterBottom component="div">
                {Number(barthel.comer ? barthel.comer : 0) +
                  Number(barthel.lavarse ? barthel.lavarse : 0) +
                  Number(barthel.vestirse ? barthel.vestirse : 0) +
                  Number(barthel.arreglarse ? barthel.arreglarse : 0) +
                  Number(barthel.deposiciones ? barthel.deposiciones : 0) +
                  Number(barthel.miccion ? barthel.miccion : 0) +
                  Number(barthel.retrete ? barthel.retrete : 0) +
                  Number(barthel.trasladarse ? barthel.trasladarse : 0) +
                  Number(barthel.deambular ? barthel.deambular : 0) +
                  Number(barthel.escalones ? barthel.escalones : 0) 
                  }
              </Typography>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
