import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import { SelectButton } from "primereact/selectbutton";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";



export default function Protocolos(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowPagos, setSelectedRowPagos] = useState(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  
  const exportColumns = [
    { title: "Nombre", dataKey: "nombre" },
    { title: "Obra Social", dataKey: "obraSocial" },
    { title: "Mensual", dataKey: "mensual" },
    { title: "Coseguro", dataKey: "coseguro" },
    { title: "Pañales", dataKey: "panales" },
    { title: "Descartables", dataKey: "descartables" },
    { title: "Estudios", dataKey: "estudios" },
    { title: "Deuda", dataKey: "deuda" },
    { title: "Efectivo", dataKey: "efectivo" },
    { title: "Obra Social", dataKey: "importeObraSocial" },
    { title: "Pendiente", dataKey: "pendiente" },
    { title: "Ingreso", dataKey: "ingreso" },
  ];

  const [periodo, setPeriodo] = useState(null);
  const [paciente, guardarPaciente] = useState(null);
  const [visible, setVisible] = useState(false);
  const [escabiosisVisible, setEscabiosisVisible] = useState(false);
  const [caidasVisible, setCaidasVisible] = useState(false);
  const [ulcerasVisible, setUlcerasVisible] = useState(false);
  const [modalIngresosEgresos, setModalIngresosEgresos] = useState(false);
  const toast = useRef(null);
  const [exit, setExit] = useState(false);
  const [tipo, setTipo] = useState("Pendientes");
  const options = ["Pendientes", "Realizados"];
  const veces = ["Si", "No"];
  const [primera, setPrimera] = useState("Si");
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    statusAcceso2,
    pacientes,
    localidades,
    diagnosticos,
    datosPlanilla,
    datosPlanillaExport,
    datosPagos,
    ingresosEgresos,
    obrasSociales,
    coseguro,
    protocolos,
    protocolo,
    realizado,
    totalesPlanilla,
    geriatricoElegido,
    obtenerPacientes,
    obtenerAcceso,
    obtenerAcceso2,
    agregarPaciente,
    actualizarPaciente,
    setGeriatricoElegido,
    obtenerLocalidadesPacientes,
    obtenerObrasSocialesPacientes,
    obtenerDiagnosticosPacientes,
    agregarCoseguros,
    obtenerIngresosEgresos,
    obtenerDatosPlanilla,
    facturacionMensual,
    guardarDatosPlanilla,
    guardarCoseguro,
    obtenerPendientesPlanilla,
    generarPagoPendiente,
    cerrarPlanilla,
    obtenerProtocolos,
    guardarProtocolo,
    obtenerProtocolo,
    actualizarProtocolo,
    setRealizado,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Protocolos",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    obtenerProtocolos({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
      pendientes: true,
    });
  }, []);

  const showConfirm = () => {
    if (selectedRow) {
      toast.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div className="p-flex p-flex-column" style={{ flex: "1" }}>
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>Confirma actualización ?</h4>
            </div>
            <div className="p-grid p-fluid">
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) => {
                    opcionSi();
                  }}
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-secondary"
                  onClick={hide}
                />
              </div>
            </div>
          </div>
        ),
      });
    }
  };

  const opcionSi = () => {
    if (selectedRow.tipo === "caidas") {
      if (
        !protocolo ||
        protocolo.fecha === undefined ||
        protocolo.fecha === null ||
        protocolo.fecha.trim() === ""
      ) {
        mensajeAlerta(
          "Caídas",
          "La fecha de la caída es necesaria !.",
          "error"
        );
        return;
      }
    }

    actualizarProtocolo({
      protocoloId: selectedRow.id,
      protocolo,
      realizado,
      selectedRow,
      primera,
    });
    hide();
    onHideCaidas();
    onHideEscabiosis();
    onHideUlceras();

    setTimeout(() => {
      obtenerProtocolos({
        geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
        pendientes: tipo === "Pendientes" ? true : false,
      });
    }, 2000);
  };

  const hide = () => {
    toast.current.clear();
    //setSelectedRow(null);
  };
  const exitClick = (e) => {
    navigate("/");
  };

  const actClick = (e) => {
    if (selectedRow) {
      //guardarProtocolo(selectedRow);
      //obtenerPaciente({ pacienteId: selectedRow.pacienteId });
      if (selectedRow.tipo === "caidas") {
        setCaidasVisible(true);
      }
      if (selectedRow.tipo === "escabiosis") {
        setEscabiosisVisible(true);
      }
      if (selectedRow.tipo === "ulceras") {
        setUlcerasVisible(true);
      }
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un protocolo.",
        life: 3000,
      });
    }
  };

  const onHideCaidas = (e) => {
    setCaidasVisible(false);
    setSelectedRow(null);  };

  const onHideEscabiosis = (e) => {
    setEscabiosisVisible(false);
    setSelectedRow(null);  };

  const onHideUlceras = (e) => {
    setUlcerasVisible(false);
    setSelectedRow(null);  };

  function convertUTCDateToLocalDate(date) {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  }

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const fechaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDateTime(rowData.fecha)}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const documentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const tipoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Tipo</span>
        {rowData.tipo === "ulceras"
          ? "Ulceras por presión"
          : rowData.tipo === "caidas"
          ? "Caídas"
          : rowData.tipo === "escabiosis"
          ? "Escabiosis"
          : null}
      </Fragment>
    );
  };

  const descripcionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Descripción</span>
        {rowData.descripcion}
      </Fragment>
    );
  };

  const saldoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Pendiente</span>
        {rowData.pendiente}
      </Fragment>
    );
  };

  const emptyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title"></span>
        {""}
      </Fragment>
    );
  };

  const totalMensualTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Mensual</span>
        {rowData.mensual}
      </Fragment>
    );
  };

  const totalCoseguroTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Coseguro</span>
        {rowData.coseguro}
      </Fragment>
    );
  };

  const totalPanalesTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Pañales</span>
        {rowData.panales}
      </Fragment>
    );
  };

  const totalDescartablesTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Descartables</span>
        {rowData.descartables}
      </Fragment>
    );
  };

  const totalEstudiosTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Estudios</span>
        {rowData.estudios}
      </Fragment>
    );
  };

  const totalDeudaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Deuda</span>
        {rowData.deuda}
      </Fragment>
    );
  };

  const totalEfectivoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Efectivo</span>
        {rowData.efectivo}
      </Fragment>
    );
  };

  const totalObraSocialTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Obra Social</span>
        {rowData.obraSocial}
      </Fragment>
    );
  };

  const totalPendienteTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Pendiente</span>
        {rowData.pendiente}
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.setFontSize(12);
        doc.text("Listado Mensual Período " + periodo, 60, 8);
        doc.autoTable(exportColumns, datosPlanilla, {
          styles: {
            fontSize: 7,
          },
        });

        doc.save("planilla_" + periodo + ".pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(datosPlanilla);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Planilla");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName +
            "_" +
            periodo +
            "_" +
            new Date().getTime() +
            EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value={"Protocolos " + tipo}></Tag>
      </div>
    </Fragment>
  );

  const renderHeaderCaidas = () => {
    return (
      <div>
        {selectedRow ? (
          <Tag value={"Protocolo de Caída de " + selectedRow.nombre}></Tag>
        ) : null}
      </div>
    );
  };

  const renderFooterCaidas = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e) => {
            showConfirm();
          }}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideCaidas}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeaderEscabiosis = () => {
    return (
      <div>
        {selectedRow ? (
          <Tag value={"Protocolo de Escabiosis de " + selectedRow.nombre}></Tag>
        ) : null}
      </div>
    );
  };

  const renderFooterEscabiosis = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e) => {
            showConfirm();
          }}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideEscabiosis}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeaderUlceras = () => {
    return (
      <div>
        {selectedRow ? (
          <Tag
            value={"Protocolo de Ulceras por presión de " + selectedRow.nombre}
          ></Tag>
        ) : null}
      </div>
    );
  };

  const renderFooterUlceras = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e) => {
            showConfirm();
          }}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideUlceras}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  function formatDate(dateString) {
    var allDate = dateString.split("-");
    var newDate = [allDate[2], allDate[1], allDate[0]].join("/");
    return newDate;
  }

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  //console.log(protocolo);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {protocolos ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
               style={{
                  align: "center",
                  margin: "1em",
                  marginTop:
                    isMobile && !isPortrait
                      ? "22vw"
                      : isMobile && isPortrait
                      ? "55vw"
                      : "0vw",
                }}
              >
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "1em" }}
                  onClick={actClick}
                />

                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />

                <SelectButton
                  value={tipo}
                  options={options}
                  onChange={(e) => {
                    setTipo(e.value);
                    obtenerProtocolos({
                      geriatricoId: Number(
                        localStorage.getItem("geriatricoElegidoId")
                      ),
                      pendientes: e.value === "Pendientes" ? true : false,
                    });
                  }}
                  style={{ float: "right" }}
                />

                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={protocolos}
                      selection={selectedRow}
                      onSelectionChange={(e) => {
                        setSelectedRow(e.value);
                        obtenerProtocolo({
                          protocoloId: e.value.id,
                          selectedRow: e.value,
                        });
                      }}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50, 100, 200]}
                      responsiveLayout="scroll"

                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha"
                        body={fechaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Nombre"
                        body={nombreTemplate}
                        style={{ width: "40%" }}
                        sortable
                      ></Column>
                      <Column
                        field="numeroDocumento"
                        header="Documento"
                        body={documentoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="tipo"
                        header="Tipo"
                        body={tipoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeaderCaidas()}
                visible={caidasVisible}
                closable={false}
                footer={renderFooterCaidas()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "90vw", paddingBottom: "0" }}
                position="center"
                maximizable={true}
              >
                <Grid container spacing={1} style={{ marginTop: "1vw" }}>
                  <Grid item xs={6} md={2}>
                    <TextField
                      id="fecha"
                      label="Fecha"
                      type="date"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.fecha ? protocolo.fecha : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          fecha: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={6} md={2}>
                    <TextField
                      id="hora"
                      label="Hora"
                      type="time"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.hora ? protocolo.hora : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          hora: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <TextField
                      id="testigo"
                      label="Testigo"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.testigo
                          ? protocolo.testigo
                          : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          testigo: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="lugar"
                      label="Lugar del acontecimiento: (institución) (calle) casa) (otro)"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.lugar ? protocolo.lugar : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          lugar: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="lugar2"
                      label="Lugar de la institución:(habitación) (comedor) (baño) (pasillo) (otro)"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.lugar2 ? protocolo.lugar2 : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          lugar2: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="circunstancia"
                      label="Circunstancia"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.circunstancia
                          ? protocolo.circunstancia
                          : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          circunstancia: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="diagnostico"
                      label="Diagnóstico"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.diagnostico
                          ? protocolo.diagnostico
                          : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          diagnostico: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="intercurrencias"
                      label="Intercurrencias médicas que puedan influir"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.intercurrencias
                          ? protocolo.intercurrencias
                          : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          intercurrencias: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="medicamentos"
                      label="Medicamentos que ingiere"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.medicamentos
                          ? protocolo.medicamentos
                          : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          medicamentos: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="piso"
                      label="Piso"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.piso ? protocolo.piso : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          piso: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="iluminacion"
                      label="Iluminación"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.iluminacion
                          ? protocolo.iluminacion
                          : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          iluminacion: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="obstaculos"
                      label="Obstaculos"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.obstaculos
                          ? protocolo.obstaculos
                          : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          obstaculos: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="barandas"
                      label="Barandas"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.barandas
                          ? protocolo.barandas
                          : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          barandas: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="cama"
                      label="Cama"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.cama ? protocolo.cama : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          cama: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="escaleras"
                      label="Escaleras"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.escaleras
                          ? protocolo.escaleras
                          : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          escaleras: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="calzado"
                      label="Calzado"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.calzado
                          ? protocolo.calzado
                          : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          calzado: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="silla"
                      label="Silla"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.silla ? protocolo.silla : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          silla: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="acompanante"
                      label="Acompañante"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.acompanante
                          ? protocolo.acompanante
                          : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          acompanante: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3} style={{ marginTop: "1.5vw" }}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Primera Vez
                      <SelectButton
                        value={primera}
                        options={veces}
                        onChange={(e) => setPrimera(e.value)}
                        style={{ float: "right", marginTop: "-0.5vw" }}
                      />
                    </FormLabel>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="tinetti"
                      label="Escala de Tinetti"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.tinetti
                          ? protocolo.tinetti
                          : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          tinetti: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="consecuencias"
                      label="Consecuencias"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.consecuencias
                          ? protocolo.consecuencias
                          : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          consecuencias: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="tratamiento"
                      label="Tratamiento"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        protocolo && protocolo.tratamiento
                          ? protocolo.tratamiento
                          : null
                      }
                      onChange={(e) =>
                        guardarProtocolo({
                          ...protocolo,
                          tratamiento: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <hr />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Primeras acciones a tomar:
                    </FormLabel>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Evitar
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Suelos: resbaladizos.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Alfombra o felpudo gruesos o sueltos.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Trapos de piso en las entradas.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Irregularidades en el piso o pasamanos.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Objetos sueltos en pasillos y lugares de transito.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Iluminación: deslumbramiento, poca iluminación, sombras.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Escaleras: peldaños sueltos o altos, ausencia de
                      pasamanos.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Baño: resbaladizo, mojado, sin asiento, inodoro bajo,
                      difícil acceso a ducha.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Dormitorio: cama alta, poca iluminación.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Otras: obstáculos sueltos, desniveles, sillas flojas.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Falta de agarraderas en baños.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Pasamanos bien identificados a la altura
                      correspondiente.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Calzados sueltos no abrochados, tipo chancletas,
                      cordones sueltos.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - No utilizar las barandas de la cama.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Elementos con patas de apoyo grandes.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Cerraduras en mal estado.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Sillas de ruedas en mal estado.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Mesas flojas o muy bajas.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Sillas y sillones bajas.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Fuera del hogar: obras, aceras estrechas, obstáculos,
                      pavimento en mal estado.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      - Se estima que algún factor ambiental es el responsable
                      del 22 % de las caídas que ocurran en ambiente no
                      familiar.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Después de cada intercurrencia se deberá indicar en el
                      libro de eventos adversos y se confeccionará la planilla
                      de caídas a tal efecto, por el encargado de turno
                      entregándose posteriormente al encargado general sin
                      excepción,
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={3} style={{ marginTop: "1.5vw" }}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Realizado
                      <SelectButton
                        value={realizado}
                        options={veces}
                        onChange={(e) => setRealizado(e.value)}
                        style={{ float: "right", marginTop: "-0.5vw" }}
                      />
                    </FormLabel>
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderEscabiosis()}
                visible={escabiosisVisible}
                closable={false}
                footer={renderFooterEscabiosis()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "90vw", paddingBottom: "0" }}
                position="center"
                maximizable={true}
              >
                <Grid container spacing={1} style={{ marginTop: "1vw" }}>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      PROTOCOLO DE ENFERMERÍA ESCABIOSIS (última revisión 2021)
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      prevención/denuncia y tratamiento: (pasos a seguir)
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      I. -REVISIÓN GENERAL DE LOS INGRESOS Y LAS DERIVACIONES DE
                      II NIVEL.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      II. -IDENTIFICAR PERSONA-RESIDENTES-EMPLEADO QUE LO
                      PADECEN, PARA SU AISLAMIENTO.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      III. -AVISAR EN FORMA INMEDIATA A LOS RESPONSABLES DE
                      GUARDIA O TURNO:
                      ADMINISTRATIVO-MÉDICO-ENCARGADO-ENFERMERÍA. EN EL MOMENTO
                      DE LA DETECCIÓN.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      IV. -REALIZAR AL RESIDENTE EL TRATAMIENTO CORRESPONDIENTE
                      SEGÚN INDICACIÓN MÉDICA.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      V. -MANEJAR TODO SU ASEO Y MANIPULACIÓN CON GUANTES.
                      (PUEDE SER CONTAGIOSO)
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      VI. -CUMPLIR CON EL TRATAMIENTO EN TIEMPO Y FORMA
                      INDICADO.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      VII. -COMPLETAR LA PLANILLA DE ESCABIOSIS DE ENFERMERÍA Y
                      PASARLA POR GUARDIA.
                    </FormLabel>
                  </Grid>

                  <Grid item xs={12} md={12} />

                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      PROTOCOLO DE LAVADERO ESCABIOSIS
                    </FormLabel>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      1°-SE PROCEDERÁ A COLOCAR TODA SU ROPA PERSONAL Y ROPA DE
                      CAMA EN BOLSAS CERRADAS Y CORRECTAMENTE IDENTIFICADAS (CON
                      LA LEYENDA ROPA CONTAMINADA).
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      2°-POSTERIORMENTE COLOCARLAS EN EL LAVADERO EN EL LUGAR
                      ADECUADO; CUARTO DE ROPA CONTAMINADA. PERMANECIENDO ALLÍ
                      POR 3 DÍAS.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      3°-PERSONAL DE LAVADERO PROCEDERÁ A SU DESINFECCIÓN SEGÚN
                      INDICACIONES ESCRITAS PARA SU CORRECTA Y ADECUADA
                      DESINFECCIÓN.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      4°-POSTERIORMENTE, PROCEDER CON LAVADO HABITUAL.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      5°-TENDER PARA SU SECADO AL SOL.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      6°-SE PROCEDERÁ A PLANCHAR TODA LA ROPA PERSONAL Y DE CAMA
                    </FormLabel>
                  </Grid>

                  <Grid item xs={12} md={3} style={{ marginTop: "1.5vw" }}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Realizado
                      <SelectButton
                        value={realizado}
                        options={veces}
                        onChange={(e) => setRealizado(e.value)}
                        style={{ float: "right", marginTop: "-0.5vw" }}
                      />
                    </FormLabel>
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderUlceras()}
                visible={ulcerasVisible}
                closable={false}
                footer={renderFooterUlceras()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "90vw", paddingBottom: "0" }}
                position="center"
                maximizable={true}
              >
                <Grid container spacing={1} style={{ marginTop: "1vw" }}>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      ULCERAS POR PRESION
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      CLASIFICACIÓN (revisado 2021)
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      SEGÚN SEVERIDAD
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Grado I. Eritema en piel intacta.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Grado II. Úlcera superficial con aspecto de abrasión,
                      ampolla o cráter superficial. Pérdida parcial del grosor
                      de la piel que afecta a la epidermis, a la dermis o a
                      ambas.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Grado III. Úlcera marginal diferenciada, generalmente
                      con exudado y bordes definidos. Pérdida total del grosor
                      de la piel que implica lesión o necrosis del tejido
                      subcutáneo.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Grado IV. Úlcera que penetra hasta el músculo e incluso
                      hasta el hueso y articulaciones.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12} />
                  <Grid item xs={12} md={12} />
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      CLASIFICACION DE NORTON
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <table>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            padding: "8px",
                            backgroundColor: "#dddddd",
                          }}
                        >
                          ESTADO FÍSICO GENERAL
                        </th>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            padding: "8px",
                            backgroundColor: "#dddddd",
                          }}
                        >
                          ESTADO MENTAL
                        </th>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            padding: "8px",
                            backgroundColor: "#dddddd",
                          }}
                        >
                          ACTIVIDAD
                        </th>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            padding: "8px",
                            backgroundColor: "#dddddd",
                          }}
                        >
                          MOVILIDAD
                        </th>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            padding: "8px",
                            backgroundColor: "#dddddd",
                          }}
                        >
                          INCONTINENCIA
                        </th>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            padding: "8px",
                            backgroundColor: "#dddddd",
                          }}
                        >
                          PUNTOS
                        </th>
                      </tr>
                      <tr style={{ textAlign: "center" }}>
                        <td style={{ border: "1px solid #dddddd" }}>BUENO</td>
                        <td style={{ border: "1px solid #dddddd" }}>ALERTA </td>
                        <td style={{ border: "1px solid #dddddd" }}>
                          AMBULANTE
                        </td>
                        <td style={{ border: "1px solid #dddddd" }}>TOTAL</td>
                        <td style={{ border: "1px solid #dddddd" }}>NINGUNA</td>
                        <td style={{ border: "1px solid #dddddd" }}>4</td>
                      </tr>
                      <tr style={{ textAlign: "center" }}>
                        <td style={{ border: "1px solid #dddddd" }}>MEDIANO</td>
                        <td style={{ border: "1px solid #dddddd" }}>APATICO</td>
                        <td style={{ border: "1px solid #dddddd" }}>
                          DISMINUIDA
                        </td>
                        <td style={{ border: "1px solid #dddddd" }}>
                          CAMINA CON AYUDA
                        </td>
                        <td style={{ border: "1px solid #dddddd" }}>
                          OCASIONAL
                        </td>
                        <td style={{ border: "1px solid #dddddd" }}>3</td>
                      </tr>
                      <tr style={{ textAlign: "center" }}>
                        <td style={{ border: "1px solid #dddddd" }}>REGULAR</td>
                        <td style={{ border: "1px solid #dddddd" }}>CONFUSO</td>
                        <td style={{ border: "1px solid #dddddd" }}>
                          MUY LIMITADA
                        </td>
                        <td style={{ border: "1px solid #dddddd" }}>SENTADA</td>
                        <td style={{ border: "1px solid #dddddd" }}>
                          URINARIA FECAL
                        </td>
                        <td style={{ border: "1px solid #dddddd" }}>2</td>
                      </tr>
                      <tr style={{ textAlign: "center" }}>
                        <td style={{ border: "1px solid #dddddd" }}>MALO</td>
                        <td style={{ border: "1px solid #dddddd" }}>
                          ESTUPOROSO
                        </td>
                        <td style={{ border: "1px solid #dddddd" }}>INMOVIL</td>
                        <td style={{ border: "1px solid #dddddd" }}>
                          ESCAMADO
                        </td>
                        <td style={{ border: "1px solid #dddddd" }}>
                          URINARIA FECAL
                        </td>
                        <td style={{ border: "1px solid #dddddd" }}>1</td>
                      </tr>
                    </table>
                  </Grid>
                  <Grid item xs={12} md={12} />

                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      CLASIFICACIÓN DE RIESGO
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <table>
                      <tr style={{ textAlign: "left" }}>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            paddingRight: "30px",
                          }}
                        >
                          PUNTUACION DE 5 A 9
                        </td>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            paddingRight: "30px",
                          }}
                        >
                          RIESGO MUY ALTO
                        </td>
                      </tr>
                      <tr style={{ textAlign: "left" }}>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            paddingRight: "30px",
                          }}
                        >
                          PUNTUACION DE 10 A 12
                        </td>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            paddingRight: "30px",
                          }}
                        >
                          RIESGO ALTO
                        </td>
                      </tr>
                      <tr style={{ textAlign: "left" }}>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            paddingRight: "30px",
                          }}
                        >
                          PUNTUACION DE 13 A 14
                        </td>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            paddingRight: "30px",
                          }}
                        >
                          RIESGO MEDIO
                        </td>
                      </tr>
                      <tr style={{ textAlign: "left" }}>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            paddingRight: "30px",
                          }}
                        >
                          PUNTUACION MAYOR A 14
                        </td>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            paddingRight: "30px",
                          }}
                        >
                          RIESGO MÍNIMO/ NO RIESGO
                        </td>
                      </tr>
                    </table>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      ESCALA DE BRADEN: (otra escala similar)
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Alto riesgo: menor o igual a 12
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Riesgo moderado: 13 a 14
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Bajo riesgo: 15-16 menor de 75 años
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      15-18 mayor o igual a 75 años.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12} />
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline" }}
                    >
                      PROCEDIMIENTOS GENERALES
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Examinar el estado de la piel al menos una vez al día.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Mantener la piel limpia y seca.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Valorar y tratar la incontinencia.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • No elevar la cabecera de la cama más de 30º.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • No masajear prominencias óseas.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Fomentar la educación sanitaria.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Fomentar la movilidad y actividad del paciente.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Corregir el déficit nutricional
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      RIESGO MEDIANO
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Vigilar estrechamente cualquier zona enrojecida.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • PROTEGER LA ZONA ENROJECIDA.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • CAMBIOS POSTURALES CADA 6 HORAS
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • INSTAURAR COLCHÓN ANTI ESCARAS.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      RIESGO ALTO
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Cambios posturales cada 2/4 horas.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Instaurar medidas o dispositivos de alivio de presión.
                      Colchón anti escaras.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • No utilizar flotadores.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Vigilar zona de inserción de sondas, mascarilla y gafas
                      de oxígeno y sujeciones mecánicas.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Protección de prominencias óseas.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      TRATAMIENTO DE ÚLCERAS POR PRESIÓN:
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      El objetivo es unificar y estandarizar los cuidados
                      aplicados en el tratamiento de las úlceras por presión, en
                      función de las características de la lesión.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      ACTIVIDADES A REALIZAR:
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Examinar la superficie corporal para determinar la
                      presencia de úlceras en evolución.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Preparar todo el material necesario para la cura.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Informar al paciente del procedimiento a realizar y
                      aclarar todas sus dudas.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Lavar las manos.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Usar técnica estéril durante todo el procedimiento.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      ANOTAR EN LA HOJA DE EVOLUCIÓN LAS ACTUACIONES.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      En función del estadio en que se encuentre la lesión
                      actuar de la siguiente forma:
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Estadio 1: - Lavar la zona con agua jabonosa. - Aclarar
                      con solución salina. - Aplicar solución de ácidos grasos
                      (de 1 a 3 gotas o pulverizaciones).
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Estadio 2: - Lavar la zona afectada con suero fisiológico
                      sin tocar el lecho de la herida. Cura húmeda, secar bien
                      la piel circundante de la lesión (nunca el lecho de la
                      misma) y aplicar apósito hidrocoloide sobre la lesión,
                      procurando que alcance piel sana. - Si la lesión presenta
                      flictena (ampolla), intentar preservarla aplicando vendaje
                      protector o apósito hidrocoloide de protección (drenar si
                      no se ha reabsorbido al cabo de 4/6 días).
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Se desaconseja la limpieza rutinaria de la herida
                      utilizando limpiadores cutáneos o antisépticos locales
                      (povidona yodada, clorhexidina, agua oxigenada, ácido
                      acético, solución de hipoclorito sódico, etc.) ya que son
                      citotóxicos para el nuevo tejido, y su absorción sistémica
                      puede causar problemas.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Estadios 3 y 4: - Si existe presencia de tejido necrótico,
                      se debe eliminar bien por desbridamiento quirúrgico,
                      aplicación de colagenasa, o favoreciendo la autolisis con
                      hidrogel + hidrocoloide. Generalmente la mejor opción es
                      la cura combinada (quirúrgico + colagenasa o bien,
                      quirúrgico + hidrogel + hidrocoloide). - Si se opta por
                      desbridamiento quirúrgico, preparar el material y recortar
                      el tejido necrótico desde el centro hacia los bordes. Si
                      se corta algún vaso sanguíneo se hará hemostasia con un
                      mosquito y si no fuera suficiente se dará un punto con
                      sutura reabsorbible. Puede ser necesario abordar la
                      limpieza en varios días, hasta conseguir un lecho rosado y
                      libre de tejido necrótico.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Recoger y ordenar el material.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Registrar en la hoja correspondiente las características
                      de la úlcera, medidas (largo x ancho x profundidad),
                      aspecto, evolución e incidencias de la cura.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Aplicar las medidas y dispositivos oportunos para evitar
                      la presión en las lesiones y la aparición de otras nuevas
                      UPP.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Favorecer el autocuidado y participación de la familia.
                      Explicar la importancia de la prevención y las técnicas
                      empleadas en el tratamiento, comprobando que la
                      información ha sido comprendida.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      CONCLUSIONES
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • El método más eficaz para tratar las UP es prevenirlas,
                      minimizando en lo posible los factores de riesgo,
                      instaurando una serie de medidas posturales, extremando la
                      limpieza y eliminando las condiciones que favorecen su
                      aparición.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • El tratamiento de las UP ha de contemplar al paciente en
                      su totalidad y el entorno de cuidados en cada caso.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • En primer lugar habrá que determinar el grado de
                      evolución de las UP, el cual va a condicionar el tipo y
                      frecuencia de las curas a realizar.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Básicamente el cuidado local de la UP incluye: limpieza,
                      desbridamiento, prevención y tratamiento de infecciones, y
                      favorecer la aparición del tejido de granulación.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • No existe un apósito ideal, los ensayos clínicos para
                      determinar la eficacia de estos productos son muy escasos.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      • Entre los numerosos apósitos comercializados, muchos de
                      ellos con una composición compleja, la selección del más
                      adecuado (así como su forma farmacéutica) viene
                      determinada por las características de la UP a tratar.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12} />

                  <Grid item xs={12} md={12} style={{ border: "1px solid" }}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Dejar constancia escrita de las medidas preventivas y de
                      tratamiento para que todo el equipo siga las mismas
                      pautas. PEDIR INTERCONSULTA MÉDICA. Se harán cambios de
                      postura (rotación) cada 2-4 horas, según el riesgo de
                      padecer UPP, a mayor riesgo el intervalo entre los CP será
                      menor Si puede hacerlo de forma autónoma, instruir que lo
                      haga cada 15 min
                    </FormLabel>
                  </Grid>

                  <Grid item xs={12} md={3} style={{ marginTop: "1.5vw" }}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Realizado
                      <SelectButton
                        value={realizado}
                        options={veces}
                        onChange={(e) => setRealizado(e.value)}
                        style={{ float: "right", marginTop: "-0.5vw" }}
                      />
                    </FormLabel>
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
      {exit ? <Navigate to="/login" /> : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
