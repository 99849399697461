import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import { InputNumber } from "primereact/inputnumber";

export default function Reloj(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const { reloj, statusAcceso3, localidades, guardarReloj } = serviciosContext;

  const handleChangeEsfera = (event) => {
    guardarReloj({
      ...reloj,
      esfera: event.target.value,
    });
  };

  const handleChangeNumero = (event) => {
    guardarReloj({
      ...reloj,
      numero: event.target.value,
    });
  };

  const handleChangeAguja = (event) => {
    guardarReloj({
      ...reloj,
      aguja: event.target.value,
    });
  };

  //console.log(statusAcceso3);
  //console.log(reloj);

  return (
    <Fragment>
      {reloj ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "0vw", marginLeft: "0vw" }}
          >
            {/*1 Columna*/}
            <Grid container xs={12} md={5} style={{ margin: "1em" }}>
              <Grid item xs={12} md={12}>
                <FormLabel
                  id="escalones"
                  style={{
                    fontWeight: "bold",
                    fontSize: "medium",
                    marginRight: "1vw",
                    marginTop: ".5vw",
                  }}
                >
                  Solicite a la persona mayor que dibuje un reloj con la
                  siguiente secuencia:
                </FormLabel>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormLabel
                  id="escalones"
                  style={{
                    fontWeight: "bold",
                    fontSize: "medium",
                    marginRight: "1vw",
                    marginTop: ".5vw",
                  }}
                >
                  1- Dibujar primero una esfera redonda y grande.
                </FormLabel>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormLabel
                  id="escalones"
                  style={{
                    fontWeight: "bold",
                    fontSize: "medium",
                    marginRight: "1vw",
                    marginTop: ".5vw",
                  }}
                >
                  2- Que coloque dentro de ella los numeros correspondientes a
                  las horas del reloj en sus lugares respectivos.
                </FormLabel>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormLabel
                  id="escalones"
                  style={{
                    fontWeight: "bold",
                    fontSize: "medium",
                    marginRight: "1vw",
                    marginTop: ".5vw",
                  }}
                >
                  3- Dibuje las agujas del reloj marcando las once y diez.
                </FormLabel>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{
                  fontWeight: "bold",
                  fontSize: "medium",
                  marginRight: "1vw",
                  marginTop: ".5vw",
                }}
              >
                <Typography variant="caption" gutterBottom component="div">
                  Referencias de Puntuación : El punto de corte que muestra una
                  mayor eficacia es el 6. Por lo tanto, se considera el test
                  como positivo si el valor de la suma de las tres puntuaciones
                  (esfera, números, agujas) a la "orden" es menor o igual a 6, y
                  como negativo si la suma de las puntuaciones es mayor a seis.
                  Las puntuaciones altas sirven para descartar la enfermedad,
                  especialmente las puntuaciones próximas al 8 y al 9
                </Typography>
              </Grid>
            </Grid>

            {/*2 Columna*/}
            <Grid container xs={12} md={6} style={{ margin: "1em" }}>
              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel
                    id="esfera"
                    style={{ fontWeight: "bold", fontSize: "large" }}
                  >
                    1- Esfera del reloj ( máximo 2 puntos)
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="esfera"
                    value={reloj ? reloj.esfera : null}
                    onChange={handleChangeEsfera}
                  >
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Dibujo normal. Esfera circular u ovalada con pequeñas distorsiones por temblor."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Incompleto o con alguna distorsión significativa. Esfera muy asimétrica. "
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Ausencia de dibujo o totalmente distorsionado."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel
                    id="numero"
                    style={{ fontWeight: "bold", fontSize: "large" }}
                  >
                    2- Presencia o secuencia de los números ( máximo 4 puntos )
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="numero"
                    value={reloj ? reloj.numero : null}
                    onChange={handleChangeNumero}
                  >
                    <FormControlLabel
                      value="40"
                      control={<Radio />}
                      label="Todos los números presentes y en el orden correcto. Sólo pequeños errores de la localización espacial en menos de 4 números."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="35"
                      control={<Radio />}
                      label="Cuando los pequeños errores en la colocación espacial se dan en 4 o más números pequeños."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="30"
                      control={<Radio />}
                      label="Todos los presentes con error significativo en la localización espacial."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="31"
                      control={<Radio />}
                      label="Números con algún desorden en la secuencia (menos de 4 números)."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="20"
                      control={<Radio />}
                      label="Omisión o adicción de algún número  pero sin distorsiones en los restantes."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="21"
                      control={<Radio />}
                      label="Números con algún desorden en la secuencia (4 o más números)."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="22"
                      control={<Radio />}
                      label="Los 12 números colocados en sentido anti horario (rotación inversa)."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="23"
                      control={<Radio />}
                      label="Todos los números presentes, pero con gran distorsión espacial (números fuera del reloj o dibujados en la media esfera, etc.)."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="24"
                      control={<Radio />}
                      label="Presencia de los 12 números en una línea vertical, horizontal u oblicua (alienación numérica)."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="10"
                      control={<Radio />}
                      label="Ausencia o exceso de números con distorsión espacial."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="11"
                      control={<Radio />}
                      label="Alineación numérica con falta o exceso de números."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="12"
                      control={<Radio />}
                      label="Rotación inversa con falta o exceso de número."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="00"
                      control={<Radio />}
                      label="Ausencia o escasa representación de números (menos de 6 números dibujados)."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>


              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel
                    id="aguja"
                    style={{ fontWeight: "bold", fontSize: "large" }}
                  >
                    3- Presencia y localización de agujas  ( máximo 4 puntos) 
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="aguja"
                    value={reloj ? reloj.aguja : null}
                    onChange={handleChangeAguja}
                  >
                    <FormControlLabel
                      value="40"
                      control={<Radio />}
                      label="Las agujas están en posición correcta y con las proporciones adecuadas de tamaño."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="35"
                      control={<Radio />}
                      label="Las agujas están en posición correcta pero ambas de igual tamaño."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="30"
                      control={<Radio />}
                      label="Pequeños errores de  la localización (situar alguna de  las agujas en el espacio destinado al número anterior o posterior)."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="31"
                      control={<Radio />}
                      label="Aguja de los minutos  más corta que la de la hora, con pauta horaria correcta."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="20"
                      control={<Radio />}
                      label="Gran distorsión en la localización de las agujas (incluso si marcan las once y diez, cuando los números presentan errores significativos en la localización espacial)."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="21"
                      control={<Radio />}
                      label="Cuando las agujas no se juntan en el punto central y marcan la hora correcta."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="10"
                      control={<Radio />}
                      label="Cuando las agujas no se juntan en el punto central y marcan la hora incorrecta."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="11"
                      control={<Radio />}
                      label="Presencia de una sola aguja o un esbozo de las dos."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                    <FormControlLabel
                      value="00"
                      control={<Radio />}
                      label="Ausencia de una aguja o perseveración en el dibujo de las mismas. Efecto en forma de rueda de carro."
                      disabled={reloj && reloj.status && statusAcceso3 !== 200}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>


            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h5" gutterBottom component="div">
                Resultado
              </Typography>
              <Typography variant="h4" gutterBottom component="div">
                {Number(reloj.esfera ? reloj.esfera : 0) +
                  Number(
                    reloj.numero && reloj.numero / 10 === 3.5
                      ? reloj.numero / 10
                      : reloj.numero && reloj.numero / 10 !== 3.5
                      ? Math.trunc(reloj.numero / 10)
                      : 0
                  ) + 
                  Number(
                    reloj.aguja && reloj.aguja / 10 === 3.5
                      ? reloj.aguja / 10
                      : reloj.aguja && reloj.aguja / 10 !== 3.5
                      ? Math.trunc(reloj.aguja / 10)
                      : 0
                  )
                  }
              </Typography>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
