import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";

export default function Actual(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const { actual, statusAcceso3, localidades, guardarActual, obtenerAcceso } =
    serviciosContext;

  const handleChangePregunta1 = (event) => {
    guardarActual({
      ...actual,
      pregunta1: event.target.checked,
    });
  };

  const handleChangePregunta2 = (event) => {
    guardarActual({
      ...actual,
      pregunta2: event.target.checked,
    });
  };

  const handleChangePregunta3 = (event) => {
    guardarActual({
      ...actual,
      pregunta3: event.target.checked,
    });
  };

  const handleChangePregunta4 = (event) => {
    guardarActual({
      ...actual,
      pregunta4: event.target.checked,
    });
  };

  const handleChangePregunta5 = (event) => {
    guardarActual({
      ...actual,
      pregunta5: event.target.checked,
    });
  };

  const handleChangePregunta6 = (event) => {
    guardarActual({
      ...actual,
      pregunta6: event.target.checked,
    });
  };

  const handleChangePregunta7 = (event) => {
    guardarActual({
      ...actual,
      pregunta7: event.target.checked,
    });
  };

  const handleChangePregunta8 = (event) => {
    guardarActual({
      ...actual,
      pregunta8: event.target.checked,
    });
  };

  const handleChangePregunta9 = (event) => {
    guardarActual({
      ...actual,
      pregunta9: event.target.checked,
    });
  };

  const handleChangePregunta10 = (event) => {
    guardarActual({
      ...actual,
      pregunta10: event.target.checked,
    });
  };

  const handleChangePregunta11 = (event) => {
    guardarActual({
      ...actual,
      pregunta11: event.target.checked,
    });
  };

  const handleChangePregunta12 = (event) => {
    guardarActual({
      ...actual,
      pregunta12: event.target.checked,
    });
  };

  const handleChangePregunta13 = (event) => {
    guardarActual({
      ...actual,
      pregunta13: event.target.checked,
    });
  };

  const handleChangePregunta14 = (event) => {
    guardarActual({
      ...actual,
      pregunta14: event.target.checked,
    });
  };

  const handleChangePregunta15 = (event) => {
    guardarActual({
      ...actual,
      pregunta15: event.target.checked,
    });
  };

  const handleChangePregunta16 = (event) => {
    guardarActual({
      ...actual,
      pregunta16: event.target.checked,
    });
  };

  const handleChangePregunta17 = (event) => {
    guardarActual({
      ...actual,
      pregunta17: event.target.checked,
    });
  };

  const handleChangePregunta18 = (event) => {
    guardarActual({
      ...actual,
      pregunta18: event.target.checked,
    });
  };

  const handleChangePregunta19 = (event) => {
    guardarActual({
      ...actual,
      pregunta19: event.target.checked,
    });
  };

  const handleChangePregunta20 = (event) => {
    guardarActual({
      ...actual,
      pregunta20: event.target.checked,
    });
  };

  const handleChangePregunta21 = (event) => {
    guardarActual({
      ...actual,
      pregunta21: event.target.checked,
    });
  };

  const handleChangePregunta22 = (event) => {
    guardarActual({
      ...actual,
      pregunta22: event.target.checked,
    });
  };

  const handleChangePregunta23 = (event) => {
    guardarActual({
      ...actual,
      pregunta23: event.target.checked,
    });
  };

  const handleChangePregunta24 = (event) => {
    guardarActual({
      ...actual,
      pregunta24: event.target.checked,
    });
  };

  const handleChangePregunta25 = (event) => {
    guardarActual({
      ...actual,
      pregunta25: event.target.checked,
    });
  };

  const handleChangePregunta26 = (event) => {
    guardarActual({
      ...actual,
      pregunta26: event.target.checked,
    });
  };

  const handleChangePregunta27 = (event) => {
    guardarActual({
      ...actual,
      pregunta27: event.target.checked,
    });
  };

  const handleChangePregunta28 = (event) => {
    guardarActual({
      ...actual,
      pregunta28: event.target.checked,
    });
  };

  const handleChangePregunta29 = (event) => {
    guardarActual({
      ...actual,
      pregunta29: event.target.checked,
    });
  };

  const handleChangePregunta30 = (event) => {
    guardarActual({
      ...actual,
      pregunta30: event.target.checked,
    });
  };

  const handleChangePregunta31 = (event) => {
    guardarActual({
      ...actual,
      pregunta31: event.target.checked,
    });
  };

  const handleChangePregunta32 = (event) => {
    guardarActual({
      ...actual,
      pregunta32: event.target.checked,
    });
  };

  const handleChangePregunta33 = (event) => {
    guardarActual({
      ...actual,
      pregunta33: event.target.checked,
    });
  };

  const handleChangePregunta34 = (event) => {
    guardarActual({
      ...actual,
      pregunta34: event.target.checked,
    });
  };

  const handleChangePregunta35 = (event) => {
    guardarActual({
      ...actual,
      pregunta35: event.target.checked,
    });
  };

  const handleChangePregunta36 = (event) => {
    guardarActual({
      ...actual,
      pregunta36: event.target.checked,
    });
  };

  const handleChangePregunta37 = (event) => {
    guardarActual({
      ...actual,
      pregunta37: event.target.checked,
    });
  };

  const handleChangePregunta38 = (event) => {
    guardarActual({
      ...actual,
      pregunta38: event.target.checked,
    });
  };

  const handleChangePregunta39 = (event) => {
    guardarActual({
      ...actual,
      pregunta39: event.target.checked,
    });
  };

  const handleChangePregunta40 = (event) => {
    guardarActual({
      ...actual,
      pregunta40: event.target.checked,
    });
  };

  const handleChangePregunta41 = (event) => {
    guardarActual({
      ...actual,
      pregunta41: event.target.checked,
    });
  };

  const handleChangePregunta42 = (event) => {
    guardarActual({
      ...actual,
      pregunta42: event.target.checked,
    });
  };

  const handleChangePregunta43 = (event) => {
    guardarActual({
      ...actual,
      pregunta43: event.target.checked,
    });
  };

  const handleChangePregunta44 = (event) => {
    guardarActual({
      ...actual,
      pregunta44: event.target.checked,
    });
  };

  const handleChangePregunta45 = (event) => {
    guardarActual({
      ...actual,
      pregunta45: event.target.checked,
    });
  };

  const handleChangePregunta46 = (event) => {
    guardarActual({
      ...actual,
      pregunta46: event.target.checked,
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  //console.log(actual);

  return (
    <Fragment>
      {actual ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "1vw", marginLeft: "0vw" }}
          >
            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                GENERALES:
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta1 : null}
                      onChange={handleChangePregunta1}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Aspecto general deteriorado."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta2 : null}
                      onChange={handleChangePregunta2}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Anorexia."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta3 : null}
                      onChange={handleChangePregunta3}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Fatiga."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta4 : null}
                      onChange={handleChangePregunta4}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Deshidratación."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta5 : null}
                      onChange={handleChangePregunta5}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Pérdida de peso."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta6 : null}
                      onChange={handleChangePregunta6}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Prurito."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta7 : null}
                      onChange={handleChangePregunta7}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Ictericia."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1.5vw" }}>
              <TextField
                id="otrosGenerales"
                label="Otros"
                variant="standard"
                fullWidth
                value={actual ? actual.otrosGenerales : null}
                onChange={(e) => {
                  guardarActual({
                    ...actual,
                    otrosGenerales: e.target.value,
                  });
                }}
                disabled={actual && actual.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                GASTROINTESTINALES:
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta8 : null}
                      onChange={handleChangePregunta8}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Nauseas Vómitos."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta9 : null}
                      onChange={handleChangePregunta9}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Disfagia."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta10 : null}
                      onChange={handleChangePregunta10}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Pirosis."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta11 : null}
                      onChange={handleChangePregunta11}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Polifagia."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta12 : null}
                      onChange={handleChangePregunta12}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Dolor abdominal."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta13 : null}
                      onChange={handleChangePregunta13}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Diarrea."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta14 : null}
                      onChange={handleChangePregunta14}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Estreñimiento."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta15 : null}
                      onChange={handleChangePregunta15}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Hemorragias digestivas."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta16 : null}
                      onChange={handleChangePregunta16}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Polidipsia."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1.5vw" }}>
              <TextField
                id="otrosGastrointentinales"
                label="Otros"
                variant="standard"
                fullWidth
                value={actual ? actual.otrosGastrointestinales : null}
                onChange={(e) => {
                  guardarActual({
                    ...actual,
                    otrosGastrointestinales: e.target.value,
                  });
                }}
                disabled={actual && actual.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                GENITOURINARIOS:
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta17 : null}
                      onChange={handleChangePregunta17}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Disuria."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta18 : null}
                      onChange={handleChangePregunta18}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Nicturia."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta19 : null}
                      onChange={handleChangePregunta19}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Poliuria."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta20 : null}
                      onChange={handleChangePregunta20}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Polaquiurea."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta21 : null}
                      onChange={handleChangePregunta21}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Incontinencia."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta22 : null}
                      onChange={handleChangePregunta22}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Prurito genital."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta22 : null}
                      onChange={handleChangePregunta23}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Dolor genital."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1.5vw" }}>
              <TextField
                id="otrosGenitourinarios"
                label="Otros"
                variant="standard"
                fullWidth
                value={actual ? actual.otrosGenitourinarios : null}
                onChange={(e) => {
                  guardarActual({
                    ...actual,
                    otrosGenitourinarios: e.target.value,
                  });
                }}
                disabled={actual && actual.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                CARDIORESPIRATORIOS:
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta24 : null}
                      onChange={handleChangePregunta24}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Dolor torácico."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta25 : null}
                      onChange={handleChangePregunta25}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Tos."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta26 : null}
                      onChange={handleChangePregunta26}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Expectoración."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta27 : null}
                      onChange={handleChangePregunta27}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Edemas."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta28 : null}
                      onChange={handleChangePregunta28}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Disnea."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta29 : null}
                      onChange={handleChangePregunta29}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Hemoptisis."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta30 : null}
                      onChange={handleChangePregunta30}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Hipo."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta31 : null}
                      onChange={handleChangePregunta31}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="TBC."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta32 : null}
                      onChange={handleChangePregunta32}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Chagas."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1.5vw" }}>
              <TextField
                id="otrosCardiorespiratorios"
                label="Otros"
                variant="standard"
                fullWidth
                value={actual ? actual.otrosCardiorespiratorios : null}
                onChange={(e) => {
                  guardarActual({
                    ...actual,
                    otrosCardiorespiratorios: e.target.value,
                  });
                }}
                disabled={actual && actual.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                NEUROLOGICOS:
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta33 : null}
                      onChange={handleChangePregunta33}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Ansiedad."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta34 : null}
                      onChange={handleChangePregunta34}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Tristeza."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta35 : null}
                      onChange={handleChangePregunta35}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Alteraciones del lenguaje."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta36 : null}
                      onChange={handleChangePregunta36}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Inmovilidad."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta37 : null}
                      onChange={handleChangePregunta37}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Parálisis."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>


            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta45 : null}
                      onChange={handleChangePregunta45}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Pérdida de memoria."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1.5vw" }}>
              <TextField
                id="otrosNeurologicos"
                label="Otros"
                variant="standard"
                fullWidth
                value={actual ? actual.otrosNeurologicos : null}
                onChange={(e) => {
                  guardarActual({
                    ...actual,
                    otrosNeurologicos: e.target.value,
                  });
                }}
                disabled={actual && actual.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                LOCOMOTORES:
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta38 : null}
                      onChange={handleChangePregunta38}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Dolor articulaciones."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta39 : null}
                      onChange={handleChangePregunta39}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Dolor espalda."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1.5vw" }}>
              <TextField
                id="otrosLocomotores"
                label="Otros"
                variant="standard"
                fullWidth
                value={actual ? actual.otrosLocomotores : null}
                onChange={(e) => {
                  guardarActual({
                    ...actual,
                    otrosLocomotores: e.target.value,
                  });
                }}
                disabled={actual && actual.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                CEREBRALES:
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta40 : null}
                      onChange={handleChangePregunta40}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Convulsiones."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta41 : null}
                      onChange={handleChangePregunta41}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Cefaleas."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta42 : null}
                      onChange={handleChangePregunta42}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Caídas."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta43 : null}
                      onChange={handleChangePregunta43}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Insomnio."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta44 : null}
                      onChange={handleChangePregunta44}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Demencia."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actual ? actual.pregunta46 : null}
                      onChange={handleChangePregunta46}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="A.C.V."
                  labelPlacement="end"
                  disabled={actual && actual.status && statusAcceso3 !== 200}
                />
              </FormControl>
            </Grid>


            <Grid item xs={12} md={12} style={{ marginTop: "-1.5vw" }}>
              <TextField
                id="otrosCerebrales"
                label="Otros"
                variant="standard"
                fullWidth
                value={actual ? actual.otrosCerebrales : null}
                onChange={(e) => {
                  guardarActual({
                    ...actual,
                    otrosCerebrales: e.target.value,
                  });
                }}
                disabled={actual && actual.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "0vw" }} />
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
