import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const fechasPagos = [
  { label: "1 a 15", id: 1 },
  { label: "16 a 31", id: 2 },
];

export default function DatosPersonales(props) {
  const paciente = props.paciente;
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    pacientes,
    localidades,
    ingresosEgresos,
    obrasSociales,
    geriatricoElegido,
    obtenerPacientes,
    obtenerAcceso,
    agregarPaciente,
    actualizarPaciente,
    setGeriatricoElegido,
    obtenerLocalidadesPacientes,
    obtenerObrasSocialesPacientes,
    actualizarPacienteIngresoEgreso,
    obtenerIngresosEgresos,
  } = serviciosContext;

  useEffect(() => {
    obtenerLocalidadesPacientes({ todos: false });
    obtenerObrasSocialesPacientes({ todos: false });
  }, []);

  //console.log(paciente);
  //console.log(localidades);

  return (
    <Fragment>
      {paciente ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "0vw", marginLeft: "0vw" }}
          >
            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Datos Personales
              </Typography>
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                value={paciente ? paciente.id : null}
                id="id"
                label="ID"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                value={paciente ? paciente.nombre : null}
                id="nombre"
                label="Apellido y Nombre"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                value={paciente ? paciente.domicilio : null}
                id="domicilio"
                label="Domicilio"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Autocomplete
                id="localidad"
                disabled
                fullWidth
                options={localidades}
                disablePortal
                required
                //defaultValue={colores[idColorDefault]}

                defaultValue={
                  paciente && localidades && paciente.localidadId !== undefined
                    ? localidades[
                        localidades.findIndex(
                          (item) => item.id === paciente.localidadId
                        )
                      ]
                    : null
                }
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    style={{ marginTop: "-1vw" }}
                    label="Localidad"
                    size="small"
                  />
                )}
                //onKeyDown={handleEnter}
              />
            </Grid>
            <Grid item xs={12} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.cp : null}
                id="cp"
                label="Cod. Postal"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.numeroDocumento : null}
                id="numeroDocumento"
                label="Nº de Documento"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>
            <Grid item xs={6} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                id="fechaNacimiento"
                label="Fecha de Nacimiento"
                type="date"
                variant="standard"
                required
                fullWidth
                value={paciente ? paciente.fechaNacimiento : null}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.lugarNacimiento : null}
                id="lugarNacimiento"
                label="Lugar de Nacimiento"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.estadoCivil : null}
                id="estadoCivil"
                label="Estado Civil"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.telefono : null}
                id="telefono"
                label="Teléfono"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Autocomplete
                id="obraSocial"
                fullWidth
                disabled
                options={obrasSociales}
                disablePortal
                defaultValue={
                  paciente &&
                  obrasSociales &&
                  paciente.obraSocialId !== undefined
                    ? obrasSociales[
                        obrasSociales.findIndex(
                          (item) => item.id === paciente.obraSocialId
                        )
                      ]
                    : null
                }
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    style={{ marginTop: "-1vw" }}
                    label="Obra Social"
                    size="small"
                  />
                )}
                //onKeyDown={handleEnter}
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.numeroObraSocial : null}
                id="numeroObraSocial"
                label="Nº Obra Social"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.clinicaInternacion : null}
                id="clinicaInternacion"
                label="Clínica Internación"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.medicoCabecera : null}
                id="medicoCabecera"
                label="Médico de Cabecera"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.capita : null}
                id="capita"
                label="Cápita"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.patologiaBase : null}
                id="patologiaBase"
                label="Patología de Base"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={6} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                id="fechaIngresoEgreso"
                label="Fecha de Ingreso"
                type="date"
                fullWidth
                variant="standard"
                disabled
                value={paciente ? paciente.fechaIngresoEgreso : null}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={6} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                id="motivoIngresoEgreso"
                label={
                  paciente && paciente.activo
                    ? "Motivo de Ingreso"
                    : "Motivo de Egreso"
                }
                fullWidth
                variant="standard"
                disabled
                value={paciente ? paciente.motivoIngresoEgreso : null}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid
              item
              xs={6}
              md={6}
              style={{ paddingLeft: "1vw", marginTop: "0vw" }}
            >
              <FormControlLabel
                value="yes"
                control={
                  <Checkbox
                    color="primary"
                    checked={paciente ? paciente.activo : null}
                    style={{ marginLeft: "1vw" }}
                    disabled
                  />
                }
                label="Activo"
                labelPlacement="start"
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Datos del Responsable
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.nombreRepresentante : null}
                required
                id="nombreRepresentante"
                label="Apellido y Nombre el Representante"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.domicilioRepresentante : null}
                required
                id="domicilioRepresentante"
                label="Domicilio del Representante"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.parentezco : null}
                id="parentezco"
                label="Parentezco"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Autocomplete
                id="localidadRepresentante"
                fullWidth
                options={localidades}
                disablePortal
                required
                disabled
                //defaultValue={colores[idColorDefault]}

                defaultValue={
                  paciente &&
                  localidades &&
                  paciente.localidadRepresentanteId !== undefined
                    ? localidades[
                        localidades.findIndex(
                          (item) =>
                            item.id === paciente.localidadRepresentanteId
                        )
                      ]
                    : null
                }
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    style={{ marginTop: "-1vw" }}
                    label="Localidad del Representante"
                    size="small"
                  />
                )}
                //onKeyDown={handleEnter}
              />
            </Grid>

            <Grid item xs={12} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.cpRepresentante : null}
                id="cpRepresentante"
                label="C.P. Repres."
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.telefonoRepresentante : null}
                id="telefonoRepresentante"
                label="Teléfono Representante"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Nivel Socio-Económico
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "0vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={paciente ? paciente.escolaridad : null}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Escolaridad
                  </FormLabel>

                  <FormControlLabel
                    value="PRIM."
                    control={<Radio />}
                    label="PRIM."
                    disabled
                  />
                  <FormControlLabel
                    value="SECUND."
                    control={<Radio />}
                    label="SECUND."
                    disabled
                  />
                  <FormControlLabel
                    value="TERC."
                    control={<Radio />}
                    label="TERC."
                    disabled
                  />
                  <FormControlLabel
                    value="UNIV."
                    control={<Radio />}
                    label="UNIV."
                    disabled
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3} style={{ marginTop: "0vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={paciente ? paciente.completo : null}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Completo
                  </FormLabel>

                  <FormControlLabel
                    value="SI"
                    control={<Radio />}
                    label="SI"
                    disabled
                  />
                  <FormControlLabel
                    value="NO"
                    control={<Radio />}
                    label="NO"
                    disabled
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.situacionLaboral : null}
                id="situacionLaboral"
                label="Situación Laboral"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3} style={{ marginTop: "0vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={paciente ? paciente.desempenoLaboral : null}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Desempeño Laboral
                  </FormLabel>

                  <FormControlLabel
                    value="SI"
                    control={<Radio />}
                    label="SI"
                    disabled
                  />
                  <FormControlLabel
                    value="NO"
                    control={<Radio />}
                    label="NO"
                    disabled
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={9} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.profesion : null}
                id="profesion"
                label="En que profesión. oficio o actividad ?"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Aspecto Psicológico
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.lucidez : null}
                id="lucidez"
                label="Lucidez Mental"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.lenguaje : null}
                id="lenguaje"
                label="Lenguaje"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.memoria : null}
                id="memoria"
                label="Nivel de concentración/atención/memoria"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Dimensión Relacional y Convivencial
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "0vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={paciente ? paciente.contencion : null}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Cuenta con una red de contención social: familiar, vecinal,
                    etc.?
                  </FormLabel>

                  <FormControlLabel
                    value="SI"
                    control={<Radio />}
                    label="SI"
                    disabled
                  />
                  <FormControlLabel
                    value="NO"
                    control={<Radio />}
                    label="NO"
                    disabled
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.contencionCual : null}
                id="contencionCual"
                label="Cual?"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Datos de Grupo Familiar
              </Typography>
            </Grid>

            {/*Grupo 1*/}
            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.nombreGrupo1 : null}
                id="nombreGrupo1"
                label="Apellido y Nombre"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.vinculoGrupo1 : null}
                id="vinculoGrupo1"
                label="Vínculo"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.ocupacionGrupo1 : null}
                id="ocupacionGrupo1"
                label="Ocupación"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.domicilioGrupo1 : null}
                id="domicilioGrupo1"
                label="Domicilio"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.telefonoGrupo1 : null}
                id="telefonoGrupo1"
                label="Teléfono"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            {/*Grupo 2*/}
            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.nombreGrupo2 : null}
                id="nombreGrupo2"
                label="Apellido y Nombre"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.vinculoGrupo2 : null}
                id="vinculoGrupo2"
                label="Vínculo"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.ocupacionGrupo2 : null}
                id="ocupacionGrupo2"
                label="Ocupación"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.domicilioGrupo2 : null}
                id="domicilioGrupo2"
                label="Domicilio"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.telefonoGrupo2 : null}
                id="telefonoGrupo2"
                label="Teléfono"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            {/*Grupo 3*/}
            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.nombreGrupo3 : null}
                id="nombreGrupo3"
                label="Apellido y Nombre"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.vinculoGrupo3 : null}
                id="vinculoGrupo3"
                label="Vínculo"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.ocupacionGrupo3 : null}
                id="ocupacionGrupo3"
                label="Ocupación"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.domicilioGrupo3 : null}
                id="domicilioGrupo3"
                label="Domicilio"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.telefonoGrupo3 : null}
                id="telefonoGrupo3"
                label="Teléfono"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            {/*Grupo 4*/}
            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.nombreGrupo4 : null}
                id="nombreGrupo4"
                label="Apellido y Nombre"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.vinculoGrupo4 : null}
                id="vinculoGrupo4"
                label="Vínculo"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.ocupacionGrupo4 : null}
                id="ocupacionGrupo4"
                label="Ocupación"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.domicilioGrupo4 : null}
                id="domicilioGrupo4"
                label="Domicilio"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.telefonoGrupo4 : null}
                id="telefonoGrupo4"
                label="Teléfono"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            {/*Grupo 5*/}
            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.nombreGrupo5 : null}
                id="nombreGrupo5"
                label="Apellido y Nombre"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.vinculoGrupo5 : null}
                id="vinculoGrupo5"
                label="Vínculo"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.ocupacionGrupo5 : null}
                id="ocupacionGrupo5"
                label="Ocupación"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.domicilioGrupo5 : null}
                id="domicilioGrupo5"
                label="Domicilio"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.telefonoGrupo5 : null}
                id="telefonoGrupo5"
                label="Teléfono"
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Observaciones
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextareaAutosize
                value={paciente ? paciente.observaciones : null}
                id="observaciones"
                aria-label="minimum height"
                minRows={2}
                maxRows={6}
                fullWidth
                placeholder="Observaciones"
                style={{ width: "100%" }}
                disabled
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Diagnóstico Psico-Social
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextareaAutosize
                value={paciente ? paciente.diagnostico : null}
                id="diagnostico"
                aria-label="minimum height"
                minRows={2}
                maxRows={6}
                fullWidth
                placeholder="Diagnóstico Psico-Social"
                style={{ width: "100%" }}
                disabled
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Plan de Tratamiento Psico-Social
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextareaAutosize
                value={paciente ? paciente.planTratamiento : null}
                id="planTratamiento"
                aria-label="minimum height"
                minRows={2}
                maxRows={8}
                fullWidth
                placeholder="Plan de Tratamiento Psico-Social"
                style={{ width: "100%" }}
                disabled
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Datos de Contacto
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.email : null}
                id="email"
                label="Email"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.emailAlternativo : null}
                id="emailAlternativo"
                label="Email Alternativo"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.telefonoComunicaciones : null}
                id="telefonoComunicaciones"
                label="Teléfono Comunicaciones"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.whatsApp : null}
                id="whatsApp"
                label="WhatsApp"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.telefonoUrgencias : null}
                id="telefonoUrgencias"
                label="Teléfono Urgencias"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={6} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.arancel : null}
                id="arancel"
                label="Arancel"
                type="number"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Autocomplete
                id="obraSocialContrato"
                fullWidth
                options={obrasSociales}
                disablePortal
                disabled
                defaultValue={
                  paciente && obrasSociales && paciente.obraSocialContratoId !== undefined
                    ? obrasSociales[
                        obrasSociales.findIndex(
                          (item) => item.id === paciente.obraSocialContratoId
                        )
                      ]
                    : null
                }
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    style={{ marginTop: "-1vw" }}
                    label="Obra Social Contrato"
                  />
                )}
                //onKeyDown={handleEnter}
              />
            </Grid>


            <Grid item xs={12} md={3}>
              <Autocomplete
                id="fechaPago"
                fullWidth
                options={fechasPagos}
                disablePortal
                disabled
                defaultValue={
                  paciente && paciente.fechaPago !== undefined
                    ? fechasPagos[
                        fechasPagos.findIndex(
                          (item) => item.id === paciente.fechaPago
                        )
                      ]
                    : null
                }
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    style={{ marginTop: "-1vw" }}
                    label="Fecha de Pago"
                  />
                )}
                //onKeyDown={handleEnter}
              />
            </Grid>

            {/*         

            <Grid item xs={6} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={paciente ? paciente.coseguro : null}
                id="coseguro"
                label="Coseguro"
                type="number"
                fullWidth
                variant="standard"
                disabled
                size="small"
              />
            </Grid>
                    */}
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
