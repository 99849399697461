import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { jsPDF } from "jspdf";
import axios from "axios";

export default function FirmaCertificadoResidencia(props) {
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);

  let navigate = useNavigate();
  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const { statusAcceso, obtenerAcceso, setGeriatricoElegido, upload } =
    serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  const exitClick = (e) => {
    navigate("/");
  };

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "FirmaCertificado",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    //obtenerObrasSociales({ todos: true });
    //obtenerObrasSocialesMedicas({ todos: false });
  }, []);

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const onChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    upload(formData)

    //upload(formData)
    //setFile(null);
    //setFilename(null);
  };

  //console.log(medicacionesPacientes);
  //console.log(file);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ padding: "0", marginTop: "1vw" }}
          >
            <Grid item xs={12} md={12}>
              <div className="file-select" id="src-file1">
                <input
                  type="file"
                  name="src-file1"
                  aria-label="Archivo"
                  accept=".png"
                  onChange={onChange}
                />
              </div>

              <div style={{ marginLeft: "1vw", display: "inline-block" }}>
                <label htmlFor="src-file1">{filename}</label>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="button"
                icon="pi pi-upload"
                onClick={uploadFile}
                className="p-button-warning p-mr-2"
                style={{ marginRight: "1em" }}
                label="Upload"
              />
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}
/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
