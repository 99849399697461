import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../../context/AlertContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "../Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { jsPDF } from "jspdf";

export default function ListadosEvaluaciones(props) {
  const exportColumns = [
    { title: "Apellido Nombre", dataKey: "nombre" },
    { title: "Tipo", dataKey: "tipo" },
    { title: "Medicación", dataKey: "medicacion" },
    { title: "Valor", dataKey: "valor" },
    { title: "Control Estudio", dataKey: "control" },
  ];

  const [listado, setListado] = useState({
    orientacion: "vertical",
    camposSeleccionados: [],
  });
  const [visible, setVisible] = useState(true);
  const toast = useRef(null);

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    pacientes,
    barthel,
    fragilidad,
    lawtonBrody,
    yesavage,
    tinetti,
    norton,
    medicacionesPacientes,
    diagnosticosPacientes,
    ingresosEgresos,
    evaluaciones,
    obrasSociales,
    obrasSocialesMedicas,
    obtenerAcceso,
    geriatricoElegido,
    obtenerObrasSociales,
    obtenerObrasSocialesMedicas,
    listadoPacientesEvaluaciones,
    obtenerMedicacionesPacientesIndividual,
    obtenerDiagnosticosPacientesIndividual,
    obtenerIngresosEgresos,
    obtenerInformesPreIngreso,
    setGeriatricoElegido,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  const exitClick = (e) => {
    navigate("/");
  };

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Listados",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    obtenerObrasSociales({ todos: true });
    obtenerObrasSocialesMedicas({ todos: false });
  }, []);

  const onHide = (e) => {
    setVisible(false);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  function getEdad(dateString) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  }

  function formatDate2(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  const imprimir = async (e) => {
    const doc = new jsPDF();
    let hoy = new Date();

    for (var i = 0; i < evaluaciones.length; i++) {
      doc.setFontSize(12);

      doc.setFont("Time-BoldItalic", "bold");
      doc.text(geriatricoElegido.nombre, 15, 8);

      doc.rect(10, 12, 190, 30);

      doc.setFontSize(14);

      doc.setFont("Time-BoldItalic", "bold");
      doc.text("EVALUACION PERIODICA", 70, 20);

      doc.setFont("Times-Roman", "normal");

      doc.setFontSize(10);

      let row = 25;

      doc.text(
        "Paciente: " +
          (evaluaciones[i].pacientes !== undefined &&
          evaluaciones[i].pacientes !== null &&
          evaluaciones[i].pacientes.nombre !== undefined &&
          evaluaciones[i].pacientes.nombre !== null
            ? evaluaciones[i].pacientes.nombre
            : ""),
        12,
        row,
        "left",
        "middle"
      );

      doc.text(
        "D.N.I.: " +
          (evaluaciones[i].pacientes !== undefined &&
          evaluaciones[i].pacientes !== null &&
          evaluaciones[i].pacientes.numerodocumento !== undefined &&
          evaluaciones[i].pacientes.numerodocumento !== null
            ? evaluaciones[i].pacientes.numerodocumento
            : ""),

        130,
        row,
        "left",
        "middle"
      );

      row = row + 5;

      doc.text(
        "Fecha Nacimiento: " +
          (evaluaciones[i].pacientes !== undefined &&
          evaluaciones[i].pacientes !== null &&
          evaluaciones[i].pacientes.fechaNacimiento !== undefined &&
          evaluaciones[i].pacientes.fechaNacimiento !== null
            ? formatDate2(evaluaciones[i].pacientes.fechaNacimiento)
            : ""),
        12,
        row,
        "left",
        "middle"
      );

      doc.text(
        "Edad: " +
          (evaluaciones[i].pacientes !== undefined &&
          evaluaciones[i].pacientes !== null &&
          evaluaciones[i].pacientes.fechaNacimiento !== undefined &&
          evaluaciones[i].pacientes.fechaNacimiento !== null
            ? getEdad(evaluaciones[i].pacientes.fechaNacimiento)
            : ""),

        130,
        row,
        "left",
        "middle"
      );

      row = row + 5;

      doc.text(
        "Fecha de Ingreso a la Institución: " +
          (evaluaciones[i].pacientes !== undefined &&
          evaluaciones[i].pacientes !== null &&
          ingresosEgresos
            ? formatDate2(
                ingresosEgresos[
                  ingresosEgresos.findIndex(
                    (item) => item.pacienteId === evaluaciones[i].pacienteId
                  )
                ].fecha
              )
            : ""),
        12,
        row,
        "left",
        "middle"
      );

      doc.text(
        "Obra Social Contrato: " +
          (evaluaciones[i].pacientes !== null &&
          evaluaciones[i].pacientes.obraSocialContratoId !== undefined &&
          obrasSociales
            ? obrasSociales[
                obrasSociales.findIndex(
                  (item) =>
                    item.id === evaluaciones[i].pacientes.obraSocialContratoId
                )
              ].nombre
            : ""),
        130,
        row,
        "left",
        "middle"
      );

      row = row + 5;

      doc.text(
        "Obra Social Médica: " +
          (evaluaciones[i].pacientes !== null &&
          evaluaciones[i].pacientes.obraSocialId !== undefined &&
          obrasSocialesMedicas
            ? obrasSocialesMedicas[
                obrasSocialesMedicas.findIndex(
                  (item) => item.id === evaluaciones[i].pacientes.obraSocialId
                )
              ].nombre
            : ""),
        12,
        row,
        "left",
        "middle"
      );

      doc.text(
        "Nº Obra Social: " +
          (evaluaciones[i].pacientes !== undefined &&
          evaluaciones[i].pacientes !== null &&
          evaluaciones[i].pacientes.numeroObraSocial !== undefined &&
          evaluaciones[i].pacientes.numeroObraSocial !== null
            ? evaluaciones[i].pacientes.numeroObraSocial
            : ""),

        130,
        row,
        "left",
        "middle"
      );

      row = row + 10;

      doc.text(
        "Fecha: " +
          (evaluaciones[i] !== undefined &&
          evaluaciones[i] !== null &&
          evaluaciones[i].fecha !== undefined &&
          evaluaciones[i].fecha !== null
            ? formatDate2(evaluaciones[i].fecha)
            : ""),
        12,
        row,
        "left",
        "middle"
      );

      row = row + 10;

      doc.text("DIAGNOSTICOS DE INGRESO: ", 10, row, "left", "middle");

      row = row + 5;

      doc.setFontSize(9);

      for (var j = 0; j < diagnosticosPacientes.length; j++) {
        if (
          diagnosticosPacientes[j].pacienteId === evaluaciones[i].pacienteId
        ) {
          doc.text(
            "-" + diagnosticosPacientes[j].diagnosticos.nombre,
            10,
            row,
            {
              maxWidth: 180,
              align: "left",
            }
          );
          let w = doc.getTextDimensions(
            diagnosticosPacientes[j].diagnosticos.nombre
          );
          if (w.w > 180) {
            row = row + 10;
          } else {
            row = row + 5;
          }
        }
      }

      doc.setFontSize(10);

      row = row + 5;

      doc.text("EVALUACION DE INGRESO: ", 10, row, "left", "middle");

      row = row + 10;

      for (var j = 0; j < barthel.length; j++) {
        if (barthel[j].pacienteId === evaluaciones[i].pacienteId) {
          doc.text(
            "BARTHEL: " +
              (barthel[j]
                ? Number(barthel[j].comer ? barthel[j].comer : 0) +
                  Number(barthel[j].lavarse ? barthel[j].lavarse : 0) +
                  Number(barthel[j].vestirse ? barthel[j].vestirse : 0) +
                  Number(barthel[j].arreglarse ? barthel[j].arreglarse : 0) +
                  Number(
                    barthel[j].deposiciones ? barthel[j].deposiciones : 0
                  ) +
                  Number(barthel[j].miccion ? barthel[j].miccion : 0) +
                  Number(barthel[j].retrete ? barthel[j].retrete : 0) +
                  Number(barthel[j].trasladarse ? barthel[j].trasladarse : 0) +
                  Number(barthel[j].deambular ? barthel[j].deambular : 0) +
                  Number(barthel[j].escalones ? barthel[j].escalones : 0)
                : ""),
            10,
            row,
            "left",
            "middle"
          );
        }
      }

      for (var j = 0; j < lawtonBrody.length; j++) {
        if (lawtonBrody[j].pacienteId === evaluaciones[i].pacienteId) {
          doc.text(
            "LAWTON Y BRODY: " +
              (lawtonBrody[j]
                ? Number(
                    lawtonBrody[j].telefonoResultado
                      ? lawtonBrody[j].telefonoResultado
                      : 0
                  ) +
                  Number(
                    lawtonBrody[j].comprasResultado
                      ? lawtonBrody[j].comprasResultado
                      : 0
                  ) +
                  Number(
                    lawtonBrody[j].comidaResultado
                      ? lawtonBrody[j].comidaResultado
                      : 0
                  ) +
                  Number(
                    lawtonBrody[j].casaResultado
                      ? lawtonBrody[j].casaResultado
                      : 0
                  ) +
                  Number(
                    lawtonBrody[j].ropaResultado
                      ? lawtonBrody[j].ropaResultado
                      : 0
                  ) +
                  Number(
                    lawtonBrody[j].transporteResultado
                      ? lawtonBrody[j].transporteResultado
                      : 0
                  ) +
                  Number(
                    lawtonBrody[j].medicacionResultado
                      ? lawtonBrody[j].medicacionResultado
                      : 0
                  ) +
                  Number(
                    lawtonBrody[j].economicoResultado
                      ? lawtonBrody[j].economicoResultado
                      : 0
                  )
                : ""),
            60,
            row,
            "left",
            "middle"
          );
        }
      }

      for (var j = 0; j < tinetti.length; j++) {
        if (tinetti[j].pacienteId === evaluaciones[i].pacienteId) {
          doc.text(
            "TINETTI: " +
              (tinetti[j]
                ? Number(
                    tinetti[j].equilibrioSentado
                      ? tinetti[j].equilibrioSentado
                      : 0
                  ) +
                  Number(tinetti[j].levantarse ? tinetti[j].levantarse : 0) +
                  Number(
                    tinetti[j].intentoLevantarse
                      ? tinetti[j].intentoLevantarse
                      : 0
                  ) +
                  Number(
                    tinetti[j].equilibrioSegundos
                      ? tinetti[j].equilibrioSegundos
                      : 0
                  ) +
                  Number(
                    tinetti[j].equilibrioProlongado
                      ? tinetti[j].equilibrioProlongado
                      : 0
                  ) +
                  Number(
                    tinetti[j].rombergSensibilizado
                      ? tinetti[j].rombergSensibilizado
                      : 0
                  ) +
                  Number(tinetti[j].romberg ? tinetti[j].romberg : 0) +
                  Number(tinetti[j].girar ? tinetti[j].girar : 0) +
                  Number(tinetti[j].girar2 ? tinetti[j].girar2 : 0) +
                  Number(tinetti[j].sentarse ? tinetti[j].sentarse : 0) / 16
                : ""),
            110,
            row,
            "left",
            "middle"
          );
        }
      }

      for (var j = 0; j < norton.length; j++) {
        if (norton[j].pacienteId === evaluaciones[i].pacienteId) {
          doc.text(
            "NORTON: " +
              (norton
                ? Number(norton[j].estadoFisico ? norton[j].estadoFisico : 0) +
                  Number(norton[j].estadoMental ? norton[j].estadoMental : 0) +
                  Number(norton[j].actividad ? norton[j].actividad : 0) +
                  Number(norton[j].movilidad ? norton[j].movilidad : 0) +
                  Number(norton[j].incontinencia ? norton[j].incontinencia : 0)
                : ""),
            150,
            row,
            "left",
            "middle"
          );
        }
      }

      row = row + 5;

      for (var j = 0; j < fragilidad.length; j++) {
        if (fragilidad[j].pacienteId === evaluaciones[i].pacienteId) {
          doc.text(
            "MARCADORES DE FRAGILIDAD: " +
              (fragilidad
                ? Number(
                    fragilidad[j].pregunta1 ? fragilidad[j].pregunta1Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta2 ? fragilidad[j].pregunta2Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta3 ? fragilidad[j].pregunta3Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta4 ? fragilidad[j].pregunta4Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta5 ? fragilidad[j].pregunta5Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta6 ? fragilidad[j].pregunta6Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta7 ? fragilidad[j].pregunta7Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta8 ? fragilidad[j].pregunta8Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta9 ? fragilidad[j].pregunta9Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta10 ? fragilidad[j].pregunta10Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta11 ? fragilidad[j].pregunta11Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta12 ? fragilidad[j].pregunta12Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta13 ? fragilidad[j].pregunta13Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta14 ? fragilidad[j].pregunta14Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta15 ? fragilidad[j].pregunta15Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta16 ? fragilidad[j].pregunta16Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta17 ? fragilidad[j].pregunta17Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta18 ? fragilidad[j].pregunta18Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta19 ? fragilidad[j].pregunta19Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta20 ? fragilidad[j].pregunta20Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta21 ? fragilidad[j].pregunta21Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta22 ? fragilidad[j].pregunta22Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta23 ? fragilidad[j].pregunta23Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta24 ? fragilidad[j].pregunta24Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta25 ? fragilidad[j].pregunta25Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta26 ? fragilidad[j].pregunta26Value : 0
                  ) +
                  Number(
                    fragilidad[j].pregunta27 ? fragilidad[j].pregunta27Value : 0
                  ) +
                  " - " +
                  fragilidad[j].observaciones
                : ""),
            10,
            row,
            "left",
            "middle"
          );
        }
      }

      row = row + 10;

      doc.text("V.G.I.: ", 10, row, "left", "middle");
      row = row + 5;

      if (evaluaciones[i] && evaluaciones[i].vgi) {
        doc.text(
          evaluaciones[i] && evaluaciones[i].vgi ? evaluaciones[i].vgi : "",
          10,
          row,
          {
            maxWidth: 180,
            align: "left",
          }
        );
      }
      var lines = 0;
      var lMargin = 20; //left margin in mm
      var rMargin = 10; //right margin in mm
      var pdfInMM = 210; // width of A4 in mm
      lines = doc.splitTextToSize(
        evaluaciones[i].vgi,
        pdfInMM - lMargin - rMargin
      );

      row = row + lines.length * 5;
      doc.text(
        "_____________________________________________________________________________________________",
        10,
        row,
        "left",
        "middle"
      );

      row = row + 10;

      doc.text("MEDICACION: ", 10, row, "left", "middle");

      row = row + 5;

      doc.setFontSize(9);

      for (var j = 0; j < medicacionesPacientes.length; j++) {
        if (
          medicacionesPacientes[j].pacienteId === evaluaciones[i].pacienteId
        ) {
          doc.text(
            "-" + medicacionesPacientes[j].medicaciones.nombre,
            10,
            row,
            "left",
            "middle"
          );
          row = row + 5;
        }
      }

      doc.setFontSize(10);

      row = row + 5;

      doc.text("EVOLUCION: ", 10, row, "left", "middle");
      row = row + 5;

      if (evaluaciones[i] && evaluaciones[i].evolucion) {
        doc.text(
          evaluaciones[i] && evaluaciones[i].evolucion
            ? evaluaciones[i].evolucion
            : "",
          10,
          row,
          {
            maxWidth: 180,
            align: "left",
          }
        );
      }
      var lines = 0;
      var lMargin = 20; //left margin in mm
      var rMargin = 10; //right margin in mm
      var pdfInMM = 210; // width of A4 in mm
      lines = doc.splitTextToSize(
        evaluaciones[i].evolucion,
        pdfInMM - lMargin - rMargin
      );

      row = row + lines.length * 5;

      doc.text(
        "_____________________________________________________________________________________________",
        10,
        row,
        "left",
        "middle"
      );
      row = row + 10;

      row = 290;

      doc.text("FIRMA Y SELLO", 90, row, "left", "middle");

      if (i < evaluaciones.length - 1) {
        doc.addPage();
      }
    }

    window.open(doc.output("bloburl"));
  };

  const aceptarClick = async (exportType) => {
    //e.preventDefault();
    // Validar|

    if (
      !listado ||
      listado.fechaDesde === undefined ||
      listado.fechaDesde === null ||
      listado.fechaDesde.trim() === ""
    ) {
      mensajeAlerta("Evaluaciones", "Fecha desde es necesario !.", "error");
      return;
    }

    if (
      !listado ||
      listado.fechaHasta === undefined ||
      listado.fechaHasta === null ||
      listado.fechaHasta.trim() === ""
    ) {
      mensajeAlerta("Evaluaciones", "Fecha hasta es necesario !.", "error");
      return;
    }

    var elem;

    await listadoPacientesEvaluaciones({
      listado,
      desde: listado.fechaDesde,
      hasta: listado.fechaHasta,
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
      obraSocialId: listado.obraSocialId,
    }).then((data) => {
      if (exportType === "pdf") {
        elem = document.getElementById("expButtonPdf");
        elem.click();
      }
    });
    onHide();
    //exitClick();
  };

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Listado de Evaluaciones Periódicas"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <button
          id="expButtonPdf"
          type="button"
          onClick={(e) => {
            imprimir();
          }}
          style={{ display: "none" }}
        >
          Export Pdf
        </button>

        <ButtonMui
          onClick={(e) => {
            aceptarClick("pdf");
          }}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
          autoFocus
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  //console.log(medicacionesPacientes);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeader()}
            visible={visible}
            closable={false}
            footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "75vw", paddingBottom: "0" }}
            position="center"
          >
            <Grid container spacing={3} style={{ marginTop: "0vw" }}>
              <Grid item xs={12} md={3}>
                <TextField
                  id="fechaDesde"
                  label="Fecha Desde"
                  type="date"
                  variant="standard"
                  fullWidth
                  value={listado ? listado.fechaDesde : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setListado({
                      ...listado,
                      fechaDesde: e.target.value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id="fechaHasta"
                  label="Fecha Hasta"
                  type="date"
                  variant="standard"
                  fullWidth
                  value={listado ? listado.fechaHasta : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setListado({
                      ...listado,
                      fechaHasta: e.target.value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="tags-standard"
                  options={
                    obrasSociales
                      ? obrasSociales.filter((element) => element.habilitado)
                      : null
                  }
                  getOptionLabel={(option) => option.nombre}
                  //defaultValue={[top100Films[13]]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Obra Social Contrato *"
                      placeholder=""
                    />
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      setListado({
                        ...listado,
                        obraSocialId: value.id,
                        obraSocial: value.nombre,
                      });
                    } else {
                      setListado({
                        ...listado,
                        obraSocialId: null,
                        obraSocial: null,
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Dialog>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}
/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
