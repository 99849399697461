import React, { Fragment, useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { AlertProvider } from "./context/AlertContext";
import { ServiciosProvider } from "./context/ServiciosContext";
import RutaPrivada from "./components/rutas/RutaPrivada";
//import Account from "./components/auth/Account";
import Login from "./components/auth/Login";
import Account from "./components/auth/Account";
import Reset from "./components/auth/Reset";
import Change from "./components/auth/Change";
//import Prueba from "./components/layout/Prueba";
//import Menu from "./components/layout/Menu";
//import Navbar from "./components/layout/Navbar";
import Establecimientos from "./components/layout/Establecimientos";
import Pacientes from "./components/layout/Pacientes";
import Diagnosticos from "./components/layout/Diagnosticos";
import Estudios from "./components/layout/Estudios";
import Patologias from "./components/layout/Patologias";
import Clinicas from "./components/layout/Clinicas";
import Medicaciones from "./components/layout/Medicaciones";
import Vinculos from "./components/layout/Vinculos";
import Coseguros from "./components/layout/Coseguros";
import Panales from "./components/layout/Panales";
import Descartables from "./components/layout/Descartables";
import ObrasSociales from "./components/layout/ObrasSociales";
import ObrasSocialesMedicas from "./components/layout/ObrasSocialesMedicas";
import Localidades from "./components/layout/Localidades";
import Accesos from "./components/layout/Accesos";
import AccesosGeriatricos from "./components/layout/AccesosGeriatricos";
import Menu from "./components/layout/Menu";
import ListadosPacientes from "./components/layout/listados/ListadosPacientes";
import ListadosDiabetes from "./components/layout/listados/ListadosDiabetes";
import ListadosAnticoagulados from "./components/layout/listados/ListadosAnticoagulados";
import ListadosCaidas from "./components/layout/listados/ListadosCaidas";
import ListadosUlceras from "./components/layout/listados/ListadosUlceras";
import ListadosEnfermeria from "./components/layout/listados/ListadosEnfermeria";
import PreIngresos from "./components/layout/PreIngresos";
import HistoriasClinicas from "./components/layout/HistoriasClinicas";
import Planillas from "./components/layout/Planillas";
import Protocolos from "./components/layout/Protocolos";
import Diabeticos from "./components/layout/Diabeticos";
import Evoluciones from "./components/layout/Evoluciones";
import Eventos from "./components/layout/Eventos";
import Notas from "./components/layout/Notas";
import Articulos from "./components/layout/Articulos";
import Movimientos from "./components/layout/Movimientos";
import ListadoStock from "./components/layout/listados/ListadoStock";
import EvaluacionPeriodica from "./components/layout/EvaluacionPeriodica";
import FrasesVgiHc from "./components/layout/FrasesVgiHc";
import Chequeos from "./components/layout/Chequeos";
import Medicacion from "./components/layout/Medicacion";
import Unidades from "./components/layout/Unidades";
import Vias from "./components/layout/Vias";
import Velocidades from "./components/layout/Velocidades";
import Recetas from "./components/layout/Recetas";
import Planes from "./components/layout/Planes";
import ListadosEvaluaciones from "./components/layout/listados/ListadosEvaluaciones";
import ListadosIndicacionesMedicas from "./components/layout/listados/ListadosIndicacionesMedicas";
import ListadosTotalMedicamentos from "./components/layout/listados/ListadosTotalMedicamentos";
import ListadosDietasPorResidente from "./components/layout/listados/ListadosDietasPorResidente";
import ListadosDietasPorDieta from "./components/layout/listados/ListadosDietasPorDieta";
import ListadosRecetas from "./components/layout/listados/ListadosRecetas";
import FirmaCertificadoResidencia from "./components/layout/FirmaCertificadoResidencia";
import CertificadoResidencia from "./components/layout/CertificadoResidencia";

function App() {
  return (
    <AlertProvider>
      <AuthProvider>
        <ServiciosProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/change/:token" element={<Change />} />
              <Route path="/reset" element={<Reset />} />
              <Route path="/" element={<Menu />} />

              <Route
                path="/account"
                element={
                  <RutaPrivada>
                    <Account />
                  </RutaPrivada>
                }
              />

              <Route
                path="/residencias"
                element={
                  <RutaPrivada>
                    <Establecimientos />
                  </RutaPrivada>
                }
              />
              <Route
                path="/residentes"
                element={
                  <RutaPrivada>
                    <Pacientes />
                  </RutaPrivada>
                }
              />
              <Route
                path="/obrassociales"
                element={
                  <RutaPrivada>
                    <ObrasSociales />
                  </RutaPrivada>
                }
              />
              <Route
                path="/obrassocialesmedicas"
                element={
                  <RutaPrivada>
                    <ObrasSocialesMedicas />
                  </RutaPrivada>
                }
              />
              <Route
                path="/diagnosticos"
                element={
                  <RutaPrivada>
                    <Diagnosticos />
                  </RutaPrivada>
                }
              />
              <Route
                path="/estudios"
                element={
                  <RutaPrivada>
                    <Estudios />
                  </RutaPrivada>
                }
              />
              <Route
                path="/patologias"
                element={
                  <RutaPrivada>
                    <Patologias />
                  </RutaPrivada>
                }
              />
              <Route
                path="/clinicas"
                element={
                  <RutaPrivada>
                    <Clinicas />
                  </RutaPrivada>
                }
              />
              <Route
                path="/medicaciones"
                element={
                  <RutaPrivada>
                    <Medicaciones />
                  </RutaPrivada>
                }
              />
              <Route
                path="/recetas"
                element={
                  <RutaPrivada>
                    <Recetas />
                  </RutaPrivada>
                }
              />
              <Route
                path="/notas"
                element={
                  <RutaPrivada>
                    <Notas />
                  </RutaPrivada>
                }
              />
              <Route
                path="/vinculos"
                element={
                  <RutaPrivada>
                    <Vinculos />
                  </RutaPrivada>
                }
              />
              <Route
                path="/coseguros"
                element={
                  <RutaPrivada>
                    <Coseguros />
                  </RutaPrivada>
                }
              />
              <Route
                path="/panales"
                element={
                  <RutaPrivada>
                    <Panales />
                  </RutaPrivada>
                }
              />
              <Route
                path="/descartables"
                element={
                  <RutaPrivada>
                    <Descartables />
                  </RutaPrivada>
                }
              />
              <Route
                path="/localidades"
                element={
                  <RutaPrivada>
                    <Localidades />
                  </RutaPrivada>
                }
              />
              <Route
                path="/chequeos"
                element={
                  <RutaPrivada>
                    <Chequeos />
                  </RutaPrivada>
                }
              />
              <Route
                path="/accesos"
                element={
                  <RutaPrivada>
                    <Accesos />
                  </RutaPrivada>
                }
              />
              <Route
                path="/accesosresidencias"
                element={
                  <RutaPrivada>
                    <AccesosGeriatricos />
                  </RutaPrivada>
                }
              />
              <Route
                path="/historiasclinicas"
                element={
                  <RutaPrivada>
                    <HistoriasClinicas />
                  </RutaPrivada>
                }
              />
              <Route
                path="/evaluacionperiodica"
                element={
                  <RutaPrivada>
                    <EvaluacionPeriodica />
                  </RutaPrivada>
                }
              />
              <Route
                path="/medicacion"
                element={
                  <RutaPrivada>
                    <Medicacion />
                  </RutaPrivada>
                }
              />
              <Route
                path="/evoluciones"
                element={
                  <RutaPrivada>
                    <Evoluciones />
                  </RutaPrivada>
                }
              />
              <Route
                path="/eventos"
                element={
                  <RutaPrivada>
                    <Eventos />
                  </RutaPrivada>
                }
              />
              <Route
                path="/listadospacientes"
                element={
                  <RutaPrivada>
                    <ListadosPacientes />
                  </RutaPrivada>
                }
              />
              <Route
                path="/listadosdiabetes"
                element={
                  <RutaPrivada>
                    <ListadosDiabetes />
                  </RutaPrivada>
                }
              />
              <Route
                path="/listadosanticoagulados"
                element={
                  <RutaPrivada>
                    <ListadosAnticoagulados />
                  </RutaPrivada>
                }
              />
              <Route
                path="/listadosenfermeria"
                element={
                  <RutaPrivada>
                    <ListadosEnfermeria />
                  </RutaPrivada>
                }
              />
              <Route
                path="/listadosevaluaciones"
                element={
                  <RutaPrivada>
                    <ListadosEvaluaciones />
                  </RutaPrivada>
                }
              />
              <Route
                path="/listadosindicacionesmedicas"
                element={
                  <RutaPrivada>
                    <ListadosIndicacionesMedicas />
                  </RutaPrivada>
                }
              />
              <Route
                path="/listadosdietasporresidente"
                element={
                  <RutaPrivada>
                    <ListadosDietasPorResidente />
                  </RutaPrivada>
                }
              />
              <Route
                path="/listadosdietaspordieta"
                element={
                  <RutaPrivada>
                    <ListadosDietasPorDieta />
                  </RutaPrivada>
                }
              />
              <Route
                path="/listadosrecetas"
                element={
                  <RutaPrivada>
                    <ListadosRecetas />
                  </RutaPrivada>
                }
              />
              <Route
                path="/listadostotalmedicamentos"
                element={
                  <RutaPrivada>
                    <ListadosTotalMedicamentos />
                  </RutaPrivada>
                }
              />
              <Route
                path="/listadosdiabetes"
                element={
                  <RutaPrivada>
                    <ListadosDiabetes />
                  </RutaPrivada>
                }
              />
              <Route
                path="/listadoscaidas"
                element={
                  <RutaPrivada>
                    <ListadosCaidas />
                  </RutaPrivada>
                }
              />
              <Route
                path="/listadosulceras"
                element={
                  <RutaPrivada>
                    <ListadosUlceras />
                  </RutaPrivada>
                }
              />
              <Route
                path="/preingresos"
                element={
                  <RutaPrivada>
                    <PreIngresos />
                  </RutaPrivada>
                }
              />
              <Route
                path="/historiasclinicas"
                element={
                  <RutaPrivada>
                    <HistoriasClinicas />
                  </RutaPrivada>
                }
              />
              <Route
                path="/planillamensual"
                element={
                  <RutaPrivada>
                    <Planillas />
                  </RutaPrivada>
                }
              />
              <Route
                path="/protocolos"
                element={
                  <RutaPrivada>
                    <Protocolos />
                  </RutaPrivada>
                }
              />
              <Route
                path="/diabetes"
                element={
                  <RutaPrivada>
                    <Diabeticos />
                  </RutaPrivada>
                }
              />
              <Route
                path="/articulos"
                element={
                  <RutaPrivada>
                    <Articulos />
                  </RutaPrivada>
                }
              />
              <Route
                path="/unidades"
                element={
                  <RutaPrivada>
                    <Unidades />
                  </RutaPrivada>
                }
              />
              <Route
                path="/vias"
                element={
                  <RutaPrivada>
                    <Vias />
                  </RutaPrivada>
                }
              />
              <Route
                path="/velocidades"
                element={
                  <RutaPrivada>
                    <Velocidades />
                  </RutaPrivada>
                }
              />
              <Route
                path="/planes"
                element={
                  <RutaPrivada>
                    <Planes />
                  </RutaPrivada>
                }
              />
              <Route
                path="/movimientos"
                element={
                  <RutaPrivada>
                    <Movimientos />
                  </RutaPrivada>
                }
              />
              <Route
                path="/listadostock"
                element={
                  <RutaPrivada>
                    <ListadoStock />
                  </RutaPrivada>
                }
              />
              <Route
                path="/frasesvgihc/:tipo"
                element={
                  <RutaPrivada>
                    <FrasesVgiHc />
                  </RutaPrivada>
                }
              />
              <Route
                path="/firmacertificadoresidencia"
                element={
                  <RutaPrivada>
                    <FirmaCertificadoResidencia />
                  </RutaPrivada>
                }
              />
              <Route
                path="/certificadoresidencia"
                element={
                  <RutaPrivada>
                    <CertificadoResidencia />
                  </RutaPrivada>
                }
              />
            </Routes>
          </BrowserRouter>
        </ServiciosProvider>
      </AuthProvider>
    </AlertProvider>
  );
}

export default App;
