import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";

export default function ChequeoEnfermerias(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    chequeoEnfermerias,
    statusAcceso,
    statusAcceso3,
    localidades,
    guardarChequeoEnfermerias,
    obtenerAcceso,
  } = serviciosContext;

  const handleChangePregunta1 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta1: event.target.checked,
    });
  };

  const handleChangePregunta2 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta2: event.target.checked,
    });
  };

  const handleChangePregunta3 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta3: event.target.checked,
    });
  };

  const handleChangePregunta4 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta4: event.target.checked,
    });
  };

  const handleChangePregunta5 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta5: event.target.checked,
    });
  };

  const handleChangePregunta6 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta6: event.target.checked,
    });
  };

  const handleChangePregunta7 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta7: event.target.checked,
    });
  };

  const handleChangePregunta8 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta8: event.target.checked,
    });
  };

  const handleChangePregunta9 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta9: event.target.checked,
    });
  };

  const handleChangePregunta10 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta10: event.target.checked,
    });
  };

  const handleChangePregunta11 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta11: event.target.checked,
    });
  };

  const handleChangePregunta12 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta12: event.target.checked,
    });
  };

  const handleChangePregunta13 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta13: event.target.checked,
    });
  };

  const handleChangePregunta14 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta14: event.target.checked,
    });
  };

  const handleChangePregunta15 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta15: event.target.checked,
    });
  };

  const handleChangePregunta16 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta16: event.target.checked,
    });
  };

  const handleChangePregunta17 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta17: event.target.checked,
    });
  };

  const handleChangePregunta18 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta18: event.target.checked,
    });
  };

  const handleChangePregunta19 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta19: event.target.checked,
    });
  };

  const handleChangePregunta20 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta20: event.target.checked,
    });
  };

  const handleChangePregunta21 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta21: event.target.checked,
    });
  };

  const handleChangePregunta22 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta22: event.target.checked,
    });
  };

  const handleChangePregunta23 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta23: event.target.checked,
    });
  };

  const handleChangePregunta24 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta24: event.target.checked,
    });
  };

  const handleChangePregunta25 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta25: event.target.checked,
    });
  };

  const handleChangePregunta26 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta26: event.target.checked,
    });
  };

  const handleChangePregunta27 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta27: event.target.checked,
    });
  };

  const handleChangePregunta28 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta28: event.target.checked,
    });
  };

  const handleChangePregunta29 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta29: event.target.checked,
    });
  };
  const handleChangePregunta30 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta30: event.target.checked,
    });
  };
  const handleChangePregunta31 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta31: event.target.checked,
    });
  };
  const handleChangePregunta32 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta32: event.target.checked,
    });
  };
  const handleChangePregunta33 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta33: event.target.checked,
    });
  };
  const handleChangePregunta34 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta34: event.target.checked,
    });
  };
  const handleChangePregunta35 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta35: event.target.checked,
    });
  };
  const handleChangePregunta36 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta36: event.target.checked,
    });
  };
  const handleChangePregunta37 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta37: event.target.checked,
    });
  };
  const handleChangePregunta38 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta38: event.target.checked,
    });
  };
  const handleChangePregunta39 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta39: event.target.checked,
    });
  };
  const handleChangePregunta40 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta40: event.target.checked,
    });
  };
  const handleChangePregunta41 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta41: event.target.checked,
    });
  };
  const handleChangePregunta42 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta42: event.target.checked,
    });
  };
  const handleChangePregunta43 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta43: event.target.checked,
    });
  };

  const handleChangePregunta44 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta44: event.target.checked,
    });
  };

  const handleChangePregunta45 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta45: event.target.checked,
    });
  };

  const handleChangePregunta46 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta46: event.target.checked,
    });
  };

  const handleChangePregunta47 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta47: event.target.checked,
    });
  };

  const handleChangePregunta48 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta48: event.target.checked,
    });
  };

  const handleChangePregunta49 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta49: event.target.checked,
    });
  };

  const handleChangePregunta50 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta50: event.target.checked,
    });
  };

  const handleChangePregunta51 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta51: event.target.checked,
    });
  };

  const handleChangePregunta52 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta52: event.target.checked,
    });
  };

  const handleChangePregunta53 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta53: event.target.checked,
    });
  };

  const handleChangePregunta54 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta54: event.target.checked,
    });
  };

  const handleChangePregunta55 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta55: event.target.checked,
    });
  };

  const handleChangePregunta56 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta56: event.target.checked,
    });
  };

  const handleChangePregunta57 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta57: event.target.checked,
    });
  };

  const handleChangePregunta58 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta58: event.target.checked,
    });
  };

  const handleChangePregunta59 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta59: event.target.checked,
    });
  };

  const handleChangePregunta60 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta60: event.target.checked,
    });
  };

  const handleChangePregunta61 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta61: event.target.checked,
    });
  };

  const handleChangePregunta62 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta62: event.target.checked,
    });
  };

  const handleChangePregunta63 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta63: event.target.checked,
    });
  };
  const handleChangePregunta64 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta64: event.target.checked,
    });
  };
  const handleChangePregunta65 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta65: event.target.checked,
    });
  };
  const handleChangePregunta66 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta66: event.target.checked,
    });
  };
  const handleChangePregunta67 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta67: event.target.checked,
    });
  };
  const handleChangePregunta68 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta68: event.target.checked,
    });
  };
  const handleChangePregunta69 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta69: event.target.checked,
    });
  };
  const handleChangePregunta70 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta70: event.target.checked,
    });
  };
  const handleChangePregunta71 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta71: event.target.checked,
    });
  };
  const handleChangePregunta72 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta72: event.target.checked,
    });
  };
  const handleChangePregunta73 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta73: event.target.checked,
    });
  };
  const handleChangePregunta74 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta74: event.target.checked,
    });
  };
  const handleChangePregunta75 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta75: event.target.checked,
    });
  };
  const handleChangePregunta76 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta76: event.target.checked,
    });
  };
  const handleChangePregunta77 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta77: event.target.checked,
    });
  };
  const handleChangePregunta78 = (event) => {
    guardarChequeoEnfermerias({
      ...chequeoEnfermerias,
      pregunta78: event.target.checked,
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  //console.log(chequeoEnfermerias);

  return (
    <Fragment>
      <Fragment>
        <Grid
          container
          spacing={3}
          style={{ marginTop: "0vw", marginLeft: "0vw" }}
        >
          <Grid item xs={12} md={7} style={{ marginTop: "1vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta1 : null}
                    onChange={handleChangePregunta1}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Mobiliario en buen estado."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "0vw" }}>
            <TextField
              id="comentario1"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario1 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario1: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta2 : null}
                    onChange={handleChangePregunta2}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Mesadas lavables firmes con canilla funcionando con agua caliente y fría con volante completa con flexibles sin pérdida, desagüe sin pérdidas ni roturas."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario2"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario2 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario2: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta3 : null}
                    onChange={handleChangePregunta3}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Suministro de agua continuo."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario3"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario3 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario3: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta4 : null}
                    onChange={handleChangePregunta4}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Buen funcionamiento de sillas de ruedas completas con gomas, freno, apoya pie."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario4"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario4 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario4: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta5 : null}
                    onChange={handleChangePregunta5}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="La grúa para pacientes se completó con tela y funcionando."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario5"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario5 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario5: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta6 : null}
                    onChange={handleChangePregunta6}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Tubo de oxígeno con soporte firme, lleno con vías de administración y máscara."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario6"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario6 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario6: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta7 : null}
                    onChange={handleChangePregunta7}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Oxímetro."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario7"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario7 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario7: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta8 : null}
                    onChange={handleChangePregunta8}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Termómetro."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario8"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario8 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario8: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta9 : null}
                    onChange={handleChangePregunta9}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Tensiómetro bajalenguas."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario9"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario9 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario9: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta10 : null}
                    onChange={handleChangePregunta10}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Camilla."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario10"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario10 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario10: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta11 : null}
                    onChange={handleChangePregunta11}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Carro de curaciones."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario11"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario11 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario11: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta12 : null}
                    onChange={handleChangePregunta12}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Aspirador."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario12"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario12 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario12: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta13 : null}
                    onChange={handleChangePregunta13}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Nebulizador."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario13"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario13 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario13: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta14 : null}
                    onChange={handleChangePregunta14}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Bomba de infusión."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario14"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario14 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario14: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta15 : null}
                    onChange={handleChangePregunta15}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Lámpara."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario15"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario15 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario15: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta16 : null}
                    onChange={handleChangePregunta16}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Luz de emergencia."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario16"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario16 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario16: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta17 : null}
                    onChange={handleChangePregunta17}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Reloj."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario17"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario17 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario17: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta18 : null}
                    onChange={handleChangePregunta18}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Linterna."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario18"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario18 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario18: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta19 : null}
                    onChange={handleChangePregunta19}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Panel de visor funcionando."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario19"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario19 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario19: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta20 : null}
                    onChange={handleChangePregunta20}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Alarma de incendio."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario20"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario20 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario20: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta21 : null}
                    onChange={handleChangePregunta21}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cesto de basura con tapa."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario21"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario21 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario21: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta22 : null}
                    onChange={handleChangePregunta22}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Heladera."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario22"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario22 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario22: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta23 : null}
                    onChange={handleChangePregunta23}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Balanza."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario23"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario23 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario23: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta24 : null}
                    onChange={handleChangePregunta24}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Iluminación funcionando, completa con lámpara y plafón."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario24"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario24 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario24: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta25 : null}
                    onChange={handleChangePregunta25}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Atrapamoscas."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario25"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario25 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario25: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta26 : null}
                    onChange={handleChangePregunta26}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Controlar la aparición de humedades."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario26"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario26 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario26: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta27 : null}
                    onChange={handleChangePregunta27}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Pintura de techos y paredes."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario27"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario27 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario27: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta28 : null}
                    onChange={handleChangePregunta28}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Orden y limpieza."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario28"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario28 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario28: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta29 : null}
                    onChange={handleChangePregunta29}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="HC Completa (VIG)."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario29"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario29 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario29: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta30 : null}
                    onChange={handleChangePregunta30}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Evaluación medica quincenal."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario30"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario30 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario30: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta31 : null}
                    onChange={handleChangePregunta31}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Indicaciones médicas mensual."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario31"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario31 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario31: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta32 : null}
                    onChange={handleChangePregunta32}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Resumen médico semestrales."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario32"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario32 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario32: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta33 : null}
                    onChange={handleChangePregunta33}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Exámenes médicos anuales."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario33"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario33 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario33: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta34 : null}
                    onChange={handleChangePregunta34}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Exámenes periódicos, diabéticos, anticoagulados, renales, etc."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario34"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario34 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario34: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta35 : null}
                    onChange={handleChangePregunta35}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Interconsulta psiquiatría y evolución."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario35"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario35 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario35: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta36 : null}
                    onChange={handleChangePregunta36}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Interconsulta nutrición y evolución."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario36"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario36 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario36: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta37 : null}
                    onChange={handleChangePregunta37}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Evaluación de enfermería."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario37"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario37 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario37: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta38 : null}
                    onChange={handleChangePregunta38}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Indicaciones de enfermería."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario38"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario38 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario38: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta39 : null}
                    onChange={handleChangePregunta39}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Controles diarios de enfermería."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario39"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario39 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario39: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta40 : null}
                    onChange={handleChangePregunta40}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Controles de intercurrencias, caídas, upp, lesiones, escabiosis, etc."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario40"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario40 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario40: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta41 : null}
                    onChange={handleChangePregunta41}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Control de baños."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario41"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario41 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario41: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta42 : null}
                    onChange={handleChangePregunta42}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Control de entrega de pañales."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario42"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario42 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario42: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta43 : null}
                    onChange={handleChangePregunta43}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Pizarra actualizada."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario43"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario43 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario43: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta44 : null}
                    onChange={handleChangePregunta44}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cuaderno de novedades."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario44"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario44 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario44: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta45 : null}
                    onChange={handleChangePregunta45}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Control y respuesta de tel. familiares."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario45"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario45 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario45: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta46 : null}
                    onChange={handleChangePregunta46}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cartelería en buen estado."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario46"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario46 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario46: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta47 : null}
                    onChange={handleChangePregunta47}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Carpeta de capacitación."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario47"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario47 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario47: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta48 : null}
                    onChange={handleChangePregunta48}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Carpeta de nutricionista."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario48"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario48 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario48: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta49 : null}
                    onChange={handleChangePregunta49}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Registro de podología."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario49"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario49 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario49: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta50 : null}
                    onChange={handleChangePregunta50}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Registro de peluquería."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario50"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario50 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario50: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta51 : null}
                    onChange={handleChangePregunta51}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Libro de ingresos y egresos, óbitos."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario51"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario51 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario51: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta52 : null}
                    onChange={handleChangePregunta52}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Libro de internación a nivel seguimiento."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario52"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario52 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario52: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta53 : null}
                    onChange={handleChangePregunta53}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Vitrina. Con stock. Sin elementos vencidos."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario53"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario53 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario53: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta54 : null}
                    onChange={handleChangePregunta54}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Caja de paro. Con stock. Sin elementos vencidos."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario54"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario54 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario54: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta55 : null}
                    onChange={handleChangePregunta55}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Caja curaciones completa."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario55"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario55 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario55: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta56 : null}
                    onChange={handleChangePregunta56}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Descartador de agujas en condiciones."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario56"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario56 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario56: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta57 : null}
                    onChange={handleChangePregunta57}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Teclas de luz con módulo completo y funcionando."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario57"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario57 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario57: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta58 : null}
                    onChange={handleChangePregunta58}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Alacenas con llave."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario58"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario58 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario58: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEnfermerias ? chequeoEnfermerias.pregunta59 : null}
                    onChange={handleChangePregunta59}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Controlar funcionamiento de heladera y balanza."
                labelPlacement="end"
                //disabled={chequeoEnfermerias && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario59"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEnfermerias ? chequeoEnfermerias.comentario59 : null}
              onChange={(e) => {
                guardarChequeoEnfermerias({
                  ...chequeoEnfermerias,
                  comentario59: e.target.value,
                });
              }}
            />
          </Grid>


          <Grid item xs={12} md={12} />
        </Grid>
      </Fragment>
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
