import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../../context/AlertContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "../Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { jsPDF } from "jspdf";

export default function ListadosPacientes(props) {
  const camposSeleccionados = [
    { title: "Nº de Beneficiario", id: 1 },
    { title: "Apellido y Nombre", id: 2 },
    { title: "Obra Social Médica", id: 3 },
    { title: "Obra Social Contrato", id: 4 },
    { title: "Edad", id: 5 },
    { title: "Documento", id: 6 },
    { title: "Fecha Nacimiento", id: 7 },
    { title: "Fecha Ingreso/Egreso", id: 8 },
    { title: "Estado", id: 9 },
    { title: "Clínica Cápita", id: 10 },
    { title: "Responsable Nombre", id: 11 },
    { title: "Responsable Teléfono", id: 12 },
    { title: "Responsable Correo", id: 13 },
    { title: "Nº de Obra Social", id: 14 },
    { title: "Teléfono Urgencias Médicas", id: 15 },
  ];

  const [listado, setListado] = useState({
    orientacion: "vertical",
    camposSeleccionados: [],
  });
  const [visible, setVisible] = useState(true);
  const [exportColumns, setExportColumns] = useState([]);
  const toast = useRef(null);

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const { statusAcceso, pacientes, obtenerAcceso, listadoPacientes } =
    serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  const exitClick = (e) => {
    navigate("/");
  };

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Listados",
      });
    }
  }, [state]);

  const onHide = (e) => {
    setVisible(false);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const aceptarClick = async (exportType) => {
    //e.preventDefault();
    // Validar|

    if (
      (listado.primerIngreso === undefined ||
        listado.primerIngreso === false) &&
      (listado.altaMedica === undefined || listado.altaMedica === false) &&
      (listado.internacion === undefined || listado.internacion === false) &&
      (listado.altaVoluntaria === undefined ||
        listado.altaVoluntaria === false) &&
      (listado.altaTransitoria === undefined ||
        listado.altaTransitoria === false) &&
      (listado.obito === undefined || listado.obito === false)
    ) {
      mensajeAlerta(
        "Listado",
        "Es necesario seleccionar algún estado !.",
        "error"
      );
      return;
    }

    /*
    if (
      listado.camposSeleccionados === undefined ||
      listado.camposSeleccionados.length === 0
    ) {
      mensajeAlerta(
        "Listado",
        "Es necesario seleccionar algún campo !.",
        "error"
      );
      return;
    }

    */

    // Llamar al context

    let local = [];

    if (
      listado.camposSeleccionados !== undefined &&
      listado.camposSeleccionados.length > 0
    ) {
      local.push({
        title: " ",
        dataKey: "enum",
      });

      for (var i = 0; i < listado.camposSeleccionados.length; i++) {
        if (listado.camposSeleccionados[i].id === 1) {
          local.push({
            title: "Nº Beneficiario",
            dataKey: "id",
          });
        }
        if (listado.camposSeleccionados[i].id === 2) {
          local.push({
            title: "Apellido y Nombre",
            dataKey: "nombre",
          });
        }
        if (listado.camposSeleccionados[i].id === 3) {
          local.push({
            title: "Obra Social Médica",
            dataKey: "obraSocial",
          });
        }
        if (listado.camposSeleccionados[i].id === 4) {
          local.push({
            title: "Obra Social Contrato",
            dataKey: "obraSocialContrato",
          });
        }
        if (listado.camposSeleccionados[i].id === 5) {
          local.push({
            title: "Edad",
            dataKey: "edad",
          });
        }
        if (listado.camposSeleccionados[i].id === 5) {
          local.push({
            title: "Edad",
            dataKey: "edad",
          });
        }
        if (listado.camposSeleccionados[i].id === 6) {
          local.push({
            title: "Documento",
            dataKey: "numeroDocumento",
          });
        }
        if (listado.camposSeleccionados[i].id === 7) {
          local.push({
            title: "Nacimiento",
            dataKey: "fechaNacimiento",
          });
        }
        if (listado.camposSeleccionados[i].id === 8) {
          local.push({
            title: "Ingreso/Egreso",
            dataKey: "fechaIngresoEgreso",
          });
        }
        if (listado.camposSeleccionados[i].id === 9) {
          local.push({
            title: "Estado",
            dataKey: "motivoIngresoEgreso",
          });
        }
        if (listado.camposSeleccionados[i].id === 10) {
          local.push({
            title: "Cli. Cápita",
            dataKey: "capita",
          });
        }
        if (listado.camposSeleccionados[i].id === 11) {
          local.push({
            title: "Nombre Resp.",
            dataKey: "nombreRepresentante",
          });
        }
        if (listado.camposSeleccionados[i].id === 12) {
          local.push({
            title: "Tel. Resp.",
            dataKey: "telefonoRepresentante",
          });
        }
        if (listado.camposSeleccionados[i].id === 13) {
          local.push({
            title: "Correo Resp.",
            dataKey: "email",
          });
        }
        if (listado.camposSeleccionados[i].id === 14) {
          local.push({
            title: "Nº O. Social",
            dataKey: "numeroObraSocial",
          });
        }
        if (listado.camposSeleccionados[i].id === 15) {
          local.push({
            title: "Tel. Urg. Médicas",
            dataKey: "telefonoUrgencias",
          });
        }
      }
    } else {
      local.push({
        title: " ",
        dataKey: "enum",
      });
      local.push({
        title: "Nº Beneficiario",
        dataKey: "id",
      });
      local.push({
        title: "Apellido y Nombre",
        dataKey: "nombre",
      });
      local.push({
        title: "Obra Social Médica",
        dataKey: "obraSocial",
      });
      local.push({
        title: "Obra Social Contrato",
        dataKey: "obraSocialContrato",
      });
      local.push({
        title: "Edad",
        dataKey: "edad",
      });
      local.push({
        title: "Edad",
        dataKey: "edad",
      });
      local.push({
        title: "Documento",
        dataKey: "numeroDocumento",
      });
      local.push({
        title: "Nacimiento",
        dataKey: "fechaNacimiento",
      });
      local.push({
        title: "Ingreso/Egreso",
        dataKey: "fechaIngresoEgreso",
      });
      local.push({
        title: "Estado",
        dataKey: "motivoIngresoEgreso",
      });
      local.push({
        title: "Cli. Cápita",
        dataKey: "capita",
      });
      local.push({
        title: "Nombre Resp.",
        dataKey: "nombreRepresentante",
      });
      local.push({
        title: "Tel. Resp.",
        dataKey: "telefonoRepresentante",
      });
      local.push({
        title: "Correo Resp.",
        dataKey: "email",
      });
      local.push({
        title: "Nº O. Social",
        dataKey: "numeroObraSocial",
      });
      local.push({
        title: "Tel. Urg. Médicas",
        dataKey: "telefonoUrgencias",
      });
    }

    if (
      listado.columnasVacias !== undefined &&
      listado.columnasVacias !== null &&
      listado.columnasVacias !== "" &&
      listado.columnasVacias > 0
    ) {
      for (var i = 0; i < listado.columnasVacias; i++) {
        local.push({
          title: "     ",
          dataKey: "     ",
        });
      }
    }

    setExportColumns(local);

    var elem;

    await listadoPacientes({
      listado,
      desde: listado.fechaDesde,
      hasta: listado.fechaHasta,
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    }).then((data) => {
      if (exportType === "excel") {
        elem = document.getElementById("expButtonExcel");
        elem.click();
      }
      if (exportType === "pdf") {
        elem = document.getElementById("expButtonPdf");
        elem.click();
      }
    });
    onHide();
    //exitClick();
  };

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Listado de Residentes"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <button
          id="expButtonExcel"
          type="button"
          onClick={(e) => {
            exportExcel();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Excel
        </button>
        <button
          id="expButtonPdf"
          type="button"
          onClick={(e) => {
            exportPdf();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Pdf
        </button>
        <Button
          icon="pi pi-file-excel"
          onClick={(e) => {
            aceptarClick("excel");
          }}
          className="p-button-raised p-button-success"
          data-pr-tooltip="XLS"
          label="Excel"
        />
        <Button
          icon="pi pi-file-pdf"
          onClick={(e) => {
            aceptarClick("pdf");
          }}
          className="p-button-raised p-button-warning"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
          label="PDF"
        />
        {/*
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        */}
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const handleChange1 = (event) => {
    setListado({
      ...listado,
      primerIngreso: event.target.checked,
    });
  };

  const handleChange2 = (event) => {
    setListado({
      ...listado,
      altaMedica: event.target.checked,
    });
  };

  const handleChange3 = (event) => {
    setListado({
      ...listado,
      internacion: event.target.checked,
    });
  };

  const handleChange4 = (event) => {
    setListado({
      ...listado,
      altaVoluntaria: event.target.checked,
    });
  };

  const handleChange5 = (event) => {
    setListado({
      ...listado,
      altaTransitoria: event.target.checked,
    });
  };

  const handleChange6 = (event) => {
    setListado({
      ...listado,
      obito: event.target.checked,
    });
  };

  const handleChange7 = (event) => {
    setListado({
      ...listado,
      orientacion: event.target.value,
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(pacientes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "residentes");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName +
            "_export_" +
            new Date().toLocaleDateString() +
            EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
    startClick();
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc =
          listado.orientacion === "vertical"
            ? new jsPDF.default(0, 0)
            : new jsPDF.default("l");
        doc.setFontSize(12);
        if (listado.orientacion === "vertical") {
          if (listado.fechaDesde === undefined || listado.fechaDesde === null) {
            doc.text("Listado de Todos los Residentes", 70, 8);
          } else {
            doc.text(
              "Listado de Residentes Desde " +
                formatDate(listado.fechaDesde) +
                " Hasta " +
                formatDate(listado.fechaHasta),
              50,
              8
            );
          }
        } else {
          if (listado.fechaDesde === undefined || listado.fechaDesde === null) {
            doc.text("Listado de Todos los Residentes", 70, 8);
          } else {
            doc.text(
              "Listado de Residentes Desde " +
                formatDate(listado.fechaDesde) +
                " Hasta " +
                formatDate(listado.fechaHasta),
              120,
              8
            );
          }
        }
        doc.autoTable(exportColumns, pacientes, {
          styles: {
            fontSize: 7,
          },
          columnStyles: {
            0: { cellWidth: 10 },
          },
          bodyStyles: {
            lineWidth: 0.1,
            lineColor: [73, 138, 159],
          },
          theme: "plain",
          tableLineColor: [255, 255, 255, 255],
          tableLineWidth: 1,
        });
        window.open(doc.output("bloburl"));
        //doc.save("residentes_" + new Date().toLocaleDateString() + ".pdf");
      });
    });
    startClick();
  };

  const startClick = (e) => {
    setTimeout(() => {
      //navigate("listadosservicios");
      setVisible(true);
    }, 2000);
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  //console.log(pacientes);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeader()}
            visible={visible}
            closable={false}
            footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "75vw", paddingBottom: "0" }}
            position="center"
          >
            <Grid container spacing={3} style={{ marginTop: "0vw" }}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="activos"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                    }}
                  >
                    ACTIVOS
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={listado ? listado.primerIngreso : null}
                        onChange={handleChange1}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ marginRight: "1vw" }}
                      />
                    }
                    label="Primer Ingreso"
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={listado ? listado.altaMedica : null}
                        onChange={handleChange2}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ marginRight: "1vw" }}
                      />
                    }
                    label="Alta Médica/Reingresa"
                    labelPlacement="end"
                  />

                  <FormLabel
                    id="inactivos"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: "1vw",
                    }}
                  >
                    INACTIVOS
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={listado ? listado.internacion : null}
                        onChange={handleChange3}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ marginRight: "1vw" }}
                      />
                    }
                    label="Internación"
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={listado ? listado.altaVoluntaria : null}
                        onChange={handleChange4}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ marginRight: "1vw" }}
                      />
                    }
                    label="Alta Voluntaria"
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={listado ? listado.altaTransitoria : null}
                        onChange={handleChange5}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ marginRight: "1vw" }}
                      />
                    }
                    label="Alta Transitoria"
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={listado ? listado.obito : null}
                        onChange={handleChange6}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ marginRight: "1vw" }}
                      />
                    }
                    label="Obito"
                    labelPlacement="end"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="tags-standard"
                  options={camposSeleccionados}
                  getOptionLabel={(option) => option.title}
                  //defaultValue={[top100Films[13]]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Campos a Seleccionar"
                      placeholder=""
                    />
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      setListado({
                        ...listado,
                        camposSeleccionados: value,
                      });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={0} md={1} />

              <Grid item xs={12} md={2}>
                <FormControl>
                  <FormLabel
                    id="orientacion"
                    style={{ fontWeight: "bold", fontSize: "large" }}
                  >
                    Orientación
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="orientacion"
                    value={listado ? listado.orientacion : null}
                    onChange={handleChange7}
                  >
                    <FormControlLabel
                      value="vertical"
                      control={<Radio />}
                      label="Vertical"
                    />
                    <FormControlLabel
                      value="horizontal"
                      control={<Radio />}
                      label="Horizontal"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id="fechaDesde"
                  label="Fecha Desde"
                  type="date"
                  variant="standard"
                  fullWidth
                  value={listado ? listado.fechaDesde : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setListado({
                      ...listado,
                      fechaDesde: e.target.value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id="fechaHasta"
                  label="Fecha Hasta"
                  type="date"
                  variant="standard"
                  fullWidth
                  value={listado ? listado.fechaHasta : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setListado({
                      ...listado,
                      fechaHasta: e.target.value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3} />

              <Grid item xs={12} md={3}>
                <TextField
                  id="columnas"
                  label="Columnas Vacias"
                  variant="standard"
                  type="number"
                  fullWidth
                  value={listado ? listado.columnasVacias : null}
                  onChange={(e) => {
                    setListado({
                      ...listado,
                      columnasVacias: e.target.value,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Dialog>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}
/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
