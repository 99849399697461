import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Contrato from "./Contrato";
import Barthel from "./Barthel";
import Reloj from "./Reloj";
import Tinetti from "./Tinetti";
import Norton from "./Norton";
import LawtonBrody from "./LawtonBrody";
import Yesavage from "./Yesavage";
import Goldberg from "./Goldberg";
import EscalaValoracionMental from "./EscalaValoracionMental";
import EvaluacionNutricional from "./EvaluacionNutricional";
import Fragilidad from "./Fragilidad";
import "../../assets/css/toast.css";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import test from "../../assets/images/test.png";
import cdr from "../../assets/images/cdr.png";
import mapeo from "../../assets/images/mapeo.png";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function PreIngresos(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;

  const anios = [
    {
      id: 1,
      label: "2022",
      actual: false,
    },
    {
      id: 1,
      label: "2023",
      actual: true,
    },
    {
      id: 1,
      label: "2024",
      actual: false,
    },
    {
      id: 1,
      label: "2025",
      actual: false,
    },
  ];

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Domicilio", dataKey: "domicilio" },
    { title: "Teléfono", dataKey: "telefono" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [dialogPacientes, setDialogPacientes] = useState(false);
  const toast = useRef(null);

  const [exit, setExit] = useState(false);
  let navigate = useNavigate();

  const steps = [
    "Contrato de Internación",
    "Barthel",
    "Lawton y Brody",
    "Tinetti",
    "Minimental",
    "Test del Reloj",
    "Escala de Ansiedad y Depresión de Goldberg",
    "Evaluación Nutricional",
    "Marcadores de Fragilidad",
    "Norton",
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    statusAcceso2,
    statusAcceso3,
    statusAcceso4,
    statusAcceso5,
    preIngresos,
    paciente,
    localidades,
    obrasSociales,
    obrasSocialesMedicas,
    diagnosticosPacientes,
    coseguros,
    descartables,
    panales,
    pacientes,
    vinculos,
    clinicas,
    patologias,
    diagnosticos,
    preIngreso,
    medicacionesPacientes,
    geriatricoElegido,
    visible,
    barthel,
    reloj,
    norton,
    tinetti,
    lawtonBrody,
    escalaValoracionMental,
    yesavage,
    goldberg,
    evaluacionNutricional,
    tipoDieta,
    fragilidad,
    frases,
    ingresosEgresos,
    selectedRowPaciente,
    validarPreIngreso,
    obtenerPreIngresos,
    obtenerAcceso,
    obtenerAcceso2,
    obtenerAcceso3,
    obtenerAcceso4,
    obtenerAcceso5,
    agregarPreIngreso,
    actualizarPreIngreso,
    setGeriatricoElegido,
    obtenerPaciente,
    obtenerPacientes,
    guardarPreIngreso,
    verificarPreIngreso,
    setVisible,
    obtenerInformesPreIngreso,
    setSelectedRowPaciente,
    guardarEscalaValoracionMental,
    guardarFragilidad,
    obtenerLocalidadesPacientes,
    obtenerDiagnosticosPacientes,
    obtenerObrasSocialesPacientes,
    obtenerObrasSocialesMedicasPacientes,
    obtenerClinicasPacientes,
    obtenerPatologiasPacientes,
    obtenerVinculosPacientes,
    obtenerCosegurosPacientes,
    obtenerPanalesPacientes,
    obtenerDescartablesPacientes,
    obtenerMedicacionesPacientesIndividual,
    obtenerDiagnosticosPacientesIndividual,
    obtenerIngresosEgresos,
    obtenerTiposDietas,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "PreIngresos",
      });
      obtenerAcceso3({
        usuarioId: state.usuario.id,
        layout: "EditarPIHC",
      });
      obtenerAcceso4({
        usuarioId: state.usuario.id,
        layout: "Psicologia",
      });
      obtenerAcceso5({
        usuarioId: state.usuario.id,
        layout: "Nutricion",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    obtenerPreIngresos({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    });
    obtenerPacientes({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    });
    obtenerLocalidadesPacientes({ todos: false });
    obtenerDiagnosticosPacientes({ todos: false });
    obtenerObrasSocialesPacientes({ todos: false });
    obtenerObrasSocialesMedicasPacientes({ todos: false });
    obtenerClinicasPacientes({ todos: false });
    obtenerPatologiasPacientes({ todos: false });
    obtenerVinculosPacientes({ todos: false });
    obtenerCosegurosPacientes({ todos: false });
    obtenerPanalesPacientes({ todos: false });
    obtenerDescartablesPacientes({ todos: false });
    //obtenerFrases()
  }, []);

  const editClick = (e) => {
    if (statusAcceso3 !== 200) {
      toast.current.show({
        severity: "error",
        summary: "Editar Pre-Ingreso",
        detail: "No tiene permiso para acceder a esta pantalla !.",
        life: 3000,
      });
      return;
    }

    if (selectedRow) {
      guardarPreIngreso(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un pre-ingreso.",
        life: 3000,
      });
    }
  };

  const actClick = (e) => {
    if (selectedRow) {
      guardarPreIngreso(selectedRow);
      obtenerPaciente({ pacienteId: selectedRow.pacienteId });
      obtenerIngresosEgresos({ pacienteId: selectedRow.id });
      obtenerInformesPreIngreso({ pacienteId: selectedRow.pacienteId });
      obtenerDiagnosticosPacientesIndividual({
        pacienteId: selectedRow.pacienteId,
      });
      obtenerIngresosEgresos({ pacienteId: selectedRow.pacienteId });
      setActiveStep(0);
      setTimeout(() => {
        setVisible(true);
      }, 500);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un pre-ingreso.",
        life: 3000,
      });
    }
  };

  const imprimirClick = (e) => {
    if (selectedRow) {
      guardarPreIngreso(selectedRow);
      obtenerPaciente({ pacienteId: selectedRow.pacienteId });
      obtenerInformesPreIngreso({ pacienteId: selectedRow.pacienteId });
      obtenerMedicacionesPacientesIndividual({
        pacienteId: selectedRow.pacienteId,
      });
      obtenerDiagnosticosPacientesIndividual({
        pacienteId: selectedRow.pacienteId,
      });
      obtenerIngresosEgresos({ pacienteId: selectedRow.pacienteId });
      var today = new Date();
      var year = today.getFullYear();
      obtenerTiposDietas({
        anio: year,
        pacienteId: selectedRow.pacienteId,
      });
      toast.current.show({
        severity: "info",
        summary: "Imprimir",
        detail: "Obteniendo datos para imprimir.",
        life: 3000,
      });
      setTimeout(() => {
        var elem;
        elem = document.getElementById("imprimirClick");
        elem.click();
      }, 3000);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Imprimir",
        detail: "Debe seleccionar un pre-ingreso.",
        life: 3000,
      });
    }
  };

  const editClickPsicologia = (e) => {
    if (statusAcceso4 !== 200) {
      toast.current.show({
        severity: "error",
        summary: "Psicología Pre-Ingreso",
        detail: "No tiene permiso para acceder a esta pantalla !.",
        life: 3000,
      });
      return;
    }

    if (selectedRow) {
      guardarPreIngreso(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un pre-ingreso.",
        life: 3000,
      });
    }
  };

  const editClickNutricion = (e) => {
    if (statusAcceso5 !== 200) {
      toast.current.show({
        severity: "error",
        summary: "Nutrición Pre-Ingreso",
        detail: "No tiene permiso para acceder a esta pantalla !.",
        life: 3000,
      });
      return;
    }

    if (selectedRow) {
      guardarPreIngreso(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un pre-ingreso.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //props.history.push(`/preIngreso/0`);
    //setVisible(true);
    setDialogPacientes(true);
    setSelectedRowPaciente(null);
    setActiveStep(0);
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHidePacientes = (e) => {
    setSelectedRowPaciente(null);
    setDialogPacientes(false);
  };

  const aceptarClickPacientes = (e) => {
    if (selectedRowPaciente) {
      verificarPreIngreso({ selectedRowPaciente });
      setDialogPacientes(false);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  /*
  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !preIngreso ||
      preIngreso.nombre === undefined ||
      preIngreso.nombre === null ||
      preIngreso.nombre.trim() === ""
    ) {
      mensajeAlerta("Geriátricos", "El nombre es necesario !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarPreIngreso({ preIngreso });
    } else {
      agregarPreIngreso({ preIngreso });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerPreIngresos({
        geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
      });
      obtenerPacientes({
        geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
      });
    }, 2000);
    //exitClick();
  };

  */

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const fechaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDateTime(rowData.fecha)}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const documentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const ingresoEgresoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Ingreso/Egreso</span>
        {formatDate(rowData.fechaIngresoEgreso)}
      </Fragment>
    );
  };

  const motivoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Motivo</span>
        {rowData.motivoIngresoEgreso}
      </Fragment>
    );
  };

  const activoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Activo</span>
        <Checkbox checked={rowData.activo}></Checkbox>
      </Fragment>
    );
  };

  const telefonoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Télefono</span>
        {rowData.telefono}
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Habilitado</span>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, preIngresos);
        doc.save("preIngresos.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(preIngresos);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "preIngresos");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Pre-Ingresos"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        {selectedRowPaciente ? (
          <Tag value={"Pre-Ingreso de " + selectedRowPaciente.nombre}></Tag>
        ) : null}
      </div>
    );
  };

  /*
  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  */

  const headerPacientes = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            fullWidth
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Residentes"></Tag>
      </div>
    </Fragment>
  );

  const renderFooterPacientes = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickPacientes}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHidePacientes}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  function formatDateTime(dateString) {
    var allDate = dateString.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  // Steps

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (
      activeStep === 1 &&
      barthel &&
      barthel.length !== 0 &&
      barthel !== null
    ) {
      // Validar Barthel

      if (barthel.comer === undefined) {
        mensajeAlerta(
          "Barthel",
          "Debe marcar una opción para comer !.",
          "info"
        );
        return;
      }

      if (barthel.lavarse === undefined) {
        mensajeAlerta(
          "Barthel",
          "Debe marcar una opción para lavarse !.",
          "info"
        );
        return;
      }

      if (barthel.vestirse === undefined) {
        mensajeAlerta(
          "Barthel",
          "Debe marcar una opción para vestirse !.",
          "info"
        );
        return;
      }

      if (barthel.arreglarse === undefined) {
        mensajeAlerta(
          "Barthel",
          "Debe marcar una opción para arreglarse !.",
          "info"
        );
        return;
      }

      if (barthel.deposiciones === undefined) {
        mensajeAlerta(
          "Barthel",
          "Debe marcar una opción para deposiciones !.",
          "info"
        );
        return;
      }

      if (barthel.miccion === undefined) {
        mensajeAlerta(
          "Barthel",
          "Debe marcar una opción para micción !.",
          "info"
        );
        return;
      }

      if (barthel.retrete === undefined) {
        mensajeAlerta(
          "Barthel",
          "Debe marcar una opción para inodoro !.",
          "info"
        );
        return;
      }

      if (barthel.trasladarse === undefined) {
        mensajeAlerta(
          "Barthel",
          "Debe marcar una opción para trasladarse !.",
          "info"
        );
        return;
      }

      if (barthel.deambular === undefined) {
        mensajeAlerta(
          "Barthel",
          "Debe marcar una opción para deambular !.",
          "info"
        );
        return;
      }

      if (barthel.escalones === undefined) {
        mensajeAlerta(
          "Barthel",
          "Debe marcar una opción para escalones !.",
          "info"
        );
        return;
      }
    }

    if (
      activeStep === 2 &&
      lawtonBrody &&
      lawtonBrody.length !== 0 &&
      lawtonBrody !== null
    ) {
      // Validar Lawton y Brody

      if (lawtonBrody.telefono === undefined) {
        mensajeAlerta(
          "Lawton y Brody",
          "Debe marcar una opción para capacidad para usar el teléfono !.",
          "info"
        );
        return;
      }

      if (lawtonBrody.compras === undefined) {
        mensajeAlerta(
          "Lawton y Brody",
          "Debe marcar una opción para hacer compras !.",
          "info"
        );
        return;
      }

      if (lawtonBrody.comida === undefined) {
        mensajeAlerta(
          "Lawton y Brody",
          "Debe marcar una opción para preparación de la comida !.",
          "info"
        );
        return;
      }

      if (lawtonBrody.casa === undefined) {
        mensajeAlerta(
          "Lawton y Brody",
          "Debe marcar una opción para cuidado de la casa !.",
          "info"
        );
        return;
      }

      if (lawtonBrody.ropa === undefined) {
        mensajeAlerta(
          "Lawton y Brody",
          "Debe marcar una opción para lavado de la ropa !.",
          "info"
        );
        return;
      }

      if (lawtonBrody.transporte === undefined) {
        mensajeAlerta(
          "Lawton y Brody",
          "Debe marcar una opción para uso de medios de transporte !.",
          "info"
        );
        return;
      }

      if (lawtonBrody.medicacion === undefined) {
        mensajeAlerta(
          "Lawton y Brody",
          "Debe marcar una opción para responsabilidad respecto a su medicación !.",
          "info"
        );
        return;
      }

      if (lawtonBrody.economico === undefined) {
        mensajeAlerta(
          "Lawton y Brody",
          "Debe marcar una opción para manejo de sus asuntos economicos !.",
          "info"
        );
        return;
      }
    }

    if (
      activeStep === 3 &&
      tinetti &&
      tinetti.length !== 0 &&
      tinetti !== null
    ) {
      // Validar Barthel

      if (tinetti.equilibrioSentado === undefined) {
        mensajeAlerta(
          "Tinetti",
          "Debe marcar una opción para equilibrio sentado !.",
          "info"
        );
        return;
      }

      if (tinetti.levantarse === undefined) {
        mensajeAlerta(
          "Tinetti",
          "Debe marcar una opción para levantarse de la silla !.",
          "info"
        );
        return;
      }

      if (tinetti.intentoLevantarse === undefined) {
        mensajeAlerta(
          "Tinetti",
          "Debe marcar una opción para en el intento de levantarse !.",
          "info"
        );
        return;
      }

      if (tinetti.equilibrioSegundos === undefined) {
        mensajeAlerta(
          "Tinetti",
          "Debe marcar una opción para Equilibrio de pié (los primeros 5 segundos) !.",
          "info"
        );
        return;
      }

      if (tinetti.equilibrioProlongado === undefined) {
        mensajeAlerta(
          "Tinetti",
          "Debe marcar una opción para equilibrio de pié prolongado !.",
          "info"
        );
        return;
      }

      if (tinetti.rombergSensibilizado === undefined) {
        mensajeAlerta(
          "Tinetti",
          "Debe marcar una opción para Romberg sensibilizado !.",
          "info"
        );
        return;
      }

      if (tinetti.romberg === undefined) {
        mensajeAlerta(
          "Tinetti",
          "Debe marcar una opción para Romberg !.",
          "info"
        );
        return;
      }

      if (tinetti.girar === undefined) {
        mensajeAlerta(
          "Tinetti",
          "Debe marcar una opción para girar en 360° !.",
          "info"
        );
        return;
      }

      if (tinetti.girar2 === undefined) {
        mensajeAlerta(
          "Tinetti",
          "Debe marcar una opción para girar en 360° !.",
          "info"
        );
        return;
      }

      if (tinetti.sentarse === undefined) {
        mensajeAlerta(
          "Tinetti",
          "Debe marcar una opción para sentarse !.",
          "info"
        );
        return;
      }
    }

    if (
      activeStep === 4 &&
      escalaValoracionMental &&
      escalaValoracionMental.length !== 0 &&
      escalaValoracionMental !== null
    ) {
      // Validar escalaValoracionMental

      if (
        (escalaValoracionMental.fecha === undefined ||
          escalaValoracionMental.fecha === null ||
          escalaValoracionMental.fecha === "") &&
        (escalaValoracionMental.pregunta1 === undefined ||
          escalaValoracionMental.pregunta1 === false) &&
        (escalaValoracionMental.pregunta2 === undefined ||
          escalaValoracionMental.pregunta2 === false) &&
        (escalaValoracionMental.pregunta3 === undefined ||
          escalaValoracionMental.pregunta3 === false) &&
        (escalaValoracionMental.pregunta4 === undefined ||
          escalaValoracionMental.pregunta4 === false) &&
        (escalaValoracionMental.pregunta5 === undefined ||
          escalaValoracionMental.pregunta5 === false) &&
        (escalaValoracionMental.pregunta6 === undefined ||
          escalaValoracionMental.pregunta6 === false) &&
        (escalaValoracionMental.pregunta7 === undefined ||
          escalaValoracionMental.pregunta7 === false) &&
        (escalaValoracionMental.pregunta8 === undefined ||
          escalaValoracionMental.pregunta8 === false) &&
        (escalaValoracionMental.pregunta9 === undefined ||
          escalaValoracionMental.pregunta9 === false) &&
        (escalaValoracionMental.pregunta10 === undefined ||
          escalaValoracionMental.pregunta10 === false) &&
        (escalaValoracionMental.pregunta11 === undefined ||
          escalaValoracionMental.pregunta11 === false) &&
        (escalaValoracionMental.pregunta12 === undefined ||
          escalaValoracionMental.pregunta12 === false) &&
        (escalaValoracionMental.pregunta13 === undefined ||
          escalaValoracionMental.pregunta13 === false) &&
        (escalaValoracionMental.pregunta14 === undefined ||
          escalaValoracionMental.pregunta14 === false) &&
        (escalaValoracionMental.pregunta15 === undefined ||
          escalaValoracionMental.pregunta15 === false)
      ) {
        guardarEscalaValoracionMental([]);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        return;
      }
      if (
        escalaValoracionMental.fecha === undefined ||
        escalaValoracionMental.fecha === null ||
        escalaValoracionMental.fecha === ""
      ) {
        mensajeAlerta(
          "Escala de Valoración Mental",
          "Debe ingresar una fecha !.",
          "info"
        );
        return;
      }

      if (
        (escalaValoracionMental.pregunta1 === undefined ||
          escalaValoracionMental.pregunta1 === false) &&
        (escalaValoracionMental.pregunta2 === undefined ||
          escalaValoracionMental.pregunta2 === false) &&
        (escalaValoracionMental.pregunta3 === undefined ||
          escalaValoracionMental.pregunta3 === false) &&
        (escalaValoracionMental.pregunta4 === undefined ||
          escalaValoracionMental.pregunta4 === false) &&
        (escalaValoracionMental.pregunta5 === undefined ||
          escalaValoracionMental.pregunta5 === false) &&
        (escalaValoracionMental.pregunta6 === undefined ||
          escalaValoracionMental.pregunta6 === false) &&
        (escalaValoracionMental.pregunta7 === undefined ||
          escalaValoracionMental.pregunta7 === false) &&
        (escalaValoracionMental.pregunta8 === undefined ||
          escalaValoracionMental.pregunta8 === false) &&
        (escalaValoracionMental.pregunta9 === undefined ||
          escalaValoracionMental.pregunta9 === false) &&
        (escalaValoracionMental.pregunta10 === undefined ||
          escalaValoracionMental.pregunta10 === false) &&
        (escalaValoracionMental.pregunta11 === undefined ||
          escalaValoracionMental.pregunta11 === false) &&
        (escalaValoracionMental.pregunta12 === undefined ||
          escalaValoracionMental.pregunta12 === false) &&
        (escalaValoracionMental.pregunta13 === undefined ||
          escalaValoracionMental.pregunta13 === false) &&
        (escalaValoracionMental.pregunta14 === undefined ||
          escalaValoracionMental.pregunta14 === false) &&
        (escalaValoracionMental.pregunta15 === undefined ||
          escalaValoracionMental.pregunta15 === false)
      ) {
        mensajeAlerta(
          "Escala de Valoración Mental",
          "Debe marcar alguna de las opciónes !.",
          "info"
        );
        return;
      }
    }

    /*
    if (
      activeStep === 6 &&
      yesavage &&
      yesavage.length !== 0 &&
      yesavage !== null
    ) {
      // Validar Lawton y Brody

      if (yesavage.pregunta1 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 1 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta2 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 2 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta3 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 3 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta4 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 4 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta5 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 5 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta6 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 6 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta7 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 7 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta8 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 8 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta9 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 9 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta10 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 10 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta11 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 11 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta12 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 12 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta13 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 13 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta14 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 14 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta15 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 15 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta16 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 16 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta17 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 17 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta18 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 18 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta19 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 19 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta20 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 20 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta21 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 21 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta22 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 22 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta23 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 23 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta24 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 24 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta25 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 25 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta26 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 26 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta27 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 27 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta28 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 28 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta29 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 29 !.",
          "info"
        );
        return;
      }

      if (yesavage.pregunta30 === undefined) {
        mensajeAlerta(
          "Escala de Depresión Geriátrica",
          "Debe marcar una opción para la pregunta 30 !.",
          "info"
        );
        return;
      }
    }
    */

    if (
      activeStep === 6 &&
      goldberg &&
      goldberg.length !== 0 &&
      goldberg !== null
    ) {
      // Validar Lawton y Brody

      if (goldberg.pregunta1 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 1 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta2 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 1 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta3 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 2 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta4 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 2 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta5 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 3 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta6 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 3 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta7 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 4 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta8 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 4 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta9 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 5 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta10 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 5 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta11 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 6 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta12 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 6 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta13 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 7 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta14 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 7 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta15 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 8 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta16 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 8 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta17 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 9 !.",
          "info"
        );
        return;
      }

      if (goldberg.pregunta18 === undefined) {
        mensajeAlerta(
          "Escala de Ansiedad y Depresión de Goldberg",
          "Debe marcar una opción para la pregunta 9 !.",
          "info"
        );
        return;
      }
    }

    const { anio, ...copy } = evaluacionNutricional;

    if (
      activeStep === 7 &&
      copy &&
      copy.length !== 0 &&
      copy !== null &&
      Object.keys(copy).length !== 0 &&
      (evaluacionNutricional.edad !== null ||
        evaluacionNutricional.sexo !== null ||
        evaluacionNutricional.peso !== null ||
        evaluacionNutricional.dependencia !== null ||
        evaluacionNutricional.postrado !== null ||
        evaluacionNutricional.problemas !== null ||
        evaluacionNutricional.riesgoAlimentar !== null ||
        evaluacionNutricional.orales !== null ||
        evaluacionNutricional.nutricional !== null ||
        evaluacionNutricional.datosIngreso !== null ||
        evaluacionNutricional.diagnosticoMedico !== null ||
        evaluacionNutricional.tipoDieta !== null)
    ) {
      if (
        evaluacionNutricional.edad === undefined ||
        evaluacionNutricional.edad === null ||
        evaluacionNutricional.edad === ""
      ) {
        mensajeAlerta(
          "Evaluación Nutricional",
          "Debe ingresar la edad !.",
          "info"
        );
        return;
      }

      if (
        evaluacionNutricional.sexo === undefined ||
        evaluacionNutricional.sexo === null ||
        evaluacionNutricional.sexo === ""
      ) {
        mensajeAlerta(
          "Evaluación Nutricional",
          "Debe ingresar el sexo !.",
          "info"
        );
        return;
      }

      if (
        evaluacionNutricional.peso === undefined ||
        evaluacionNutricional.peso === null ||
        evaluacionNutricional.peso === ""
      ) {
        mensajeAlerta(
          "Evaluación Nutricional",
          "Debe ingresar el peso !.",
          "info"
        );
        return;
      }

      if (evaluacionNutricional.dependencia === undefined) {
        mensajeAlerta(
          "Evaluación Nutricional",
          "Debe ingresar dependencia !.",
          "info"
        );
        return;
      }

      if (evaluacionNutricional.postrado === undefined) {
        mensajeAlerta(
          "Evaluación Nutricional",
          "Debe ingresar postrado !.",
          "info"
        );
        return;
      }

      if (evaluacionNutricional.problemas === undefined) {
        mensajeAlerta(
          "Evaluación Nutricional",
          "Debe ingresar problemas !.",
          "info"
        );
        return;
      }

      if (evaluacionNutricional.riesgoAlimentar === undefined) {
        mensajeAlerta(
          "Evaluación Nutricional",
          "Debe ingresar riesgo p/alimentar !.",
          "info"
        );
        return;
      }

      if (evaluacionNutricional.orales === undefined) {
        mensajeAlerta(
          "Evaluación Nutricional",
          "Debe ingresar orales !.",
          "info"
        );
        return;
      }
      if (evaluacionNutricional.nutricional === undefined) {
        mensajeAlerta(
          "Evaluación Nutricional",
          "Debe ingresar nutricional !.",
          "info"
        );
        return;
      }

      /*
      if (
        evaluacionNutricional.datosIngreso === undefined ||
        evaluacionNutricional.datosIngreso === null ||
        evaluacionNutricional.datosIngreso === ""
      ) {
        mensajeAlerta(
          "Evaluación Nutricional",
          "Debe ingresar datos de ingreso !.",
          "info"
        );
        return;
      }

      if (
        evaluacionNutricional.diagnosticoMedico === undefined ||
        evaluacionNutricional.diagnosticoMedico === null ||
        evaluacionNutricional.diagnosticoMedico === ""
      ) {
        mensajeAlerta(
          "Evaluación Nutricional",
          "Debe ingresar el diagnóstico !.",
          "info"
        );
        return;
      }

            */

      if (
        evaluacionNutricional.anio === undefined ||
        evaluacionNutricional.anio === null ||
        evaluacionNutricional.anio === ""
      ) {
        mensajeAlerta(
          "Evaluación Nutricional",
          "Debe ingresar el anio !.",
          "info"
        );
        return;
      }

      for (var i = 0; i < 12; i++) {
        console.log(tipoDieta[i])
        if (tipoDieta[i].mes !== null) {
          if (tipoDieta[i].actitud === null) {
            mensajeAlerta(
              "Evaluación Nutricional",
              "Debe ingresar actitud frente a la comida !.",
              "info"
            );
            return;
          }

          if (tipoDieta[i].depende === null) {
            mensajeAlerta(
              "Evaluación Nutricional",
              "Debe ingresar depende p/alimentar !.",
              "info"
            );
            return;
          }

          if (tipoDieta[i].complemento === null) {
            mensajeAlerta(
              "Evaluación Nutricional",
              "Debe ingresar complemento alimentario !.",
              "info"
            );
            return;
          }

          if (tipoDieta[i].pesoMes === null) {
            mensajeAlerta(
              "Evaluación Nutricional",
              "Debe ingresar el peso !.",
              "info"
            );
            return;
          }

          if (tipoDieta[i].talla === null) {
            mensajeAlerta(
              "Evaluación Nutricional",
              "Debe ingresar la talla !.",
              "info"
            );
            return;
          }

          if (tipoDieta[i].imc === null) {
            mensajeAlerta(
              "Evaluación Nutricional",
              "Debe ingresar el imc !.",
              "info"
            );
            return;
          }

          if (tipoDieta[i].riesgoNutricional === null) {
            mensajeAlerta(
              "Evaluación Nutricional",
              "Debe ingresar riesgo nutricional !.",
              "info"
            );
            return;
          }
        }
      }

      /*
      if (
        evaluacionNutricional.diagnosticoPrimario === undefined ||
        evaluacionNutricional.diagnosticoPrimario === null ||
        evaluacionNutricional.diagnosticoPrimario === ""
      ) {
        mensajeAlerta(
          "Evaluación Nutricional",
          "Debe ingresar el diagnóstico primario !.",
          "info"
        );
        return;
      }
      */

      if (
        evaluacionNutricional.diagnosticoNutricional === undefined ||
        evaluacionNutricional.diagnosticoNutricional === null ||
        evaluacionNutricional.diagnosticoNutricional === ""
      ) {
        mensajeAlerta(
          "Evaluación Nutricional",
          "Debe ingresar el diagnóstico nutricional !.",
          "info"
        );
        return;
      }
    }

    if (
      activeStep === 8 &&
      fragilidad &&
      fragilidad.length !== 0 &&
      fragilidad !== null
    ) {
      // Validar fragilidad

      if (
        (fragilidad.lugar === undefined ||
          fragilidad.lugar === null ||
          fragilidad.lugar.trim() === "") &&
        (fragilidad.pregunta1 === undefined ||
          fragilidad.pregunta1 === false) &&
        (fragilidad.pregunta2 === undefined ||
          fragilidad.pregunta2 === false) &&
        (fragilidad.pregunta3 === undefined ||
          fragilidad.pregunta3 === false) &&
        (fragilidad.pregunta4 === undefined ||
          fragilidad.pregunta4 === false) &&
        (fragilidad.pregunta5 === undefined ||
          fragilidad.pregunta5 === false) &&
        (fragilidad.pregunta6 === undefined ||
          fragilidad.pregunta6 === false) &&
        (fragilidad.pregunta7 === undefined ||
          fragilidad.pregunta7 === false) &&
        (fragilidad.pregunta8 === undefined ||
          fragilidad.pregunta8 === false) &&
        (fragilidad.pregunta9 === undefined ||
          fragilidad.pregunta9 === false) &&
        (fragilidad.pregunta10 === undefined ||
          fragilidad.pregunta10 === false) &&
        (fragilidad.pregunta11 === undefined ||
          fragilidad.pregunta11 === false) &&
        (fragilidad.pregunta12 === undefined ||
          fragilidad.pregunta12 === false) &&
        (fragilidad.pregunta13 === undefined ||
          fragilidad.pregunta13 === false) &&
        (fragilidad.pregunta14 === undefined ||
          fragilidad.pregunta14 === false) &&
        (fragilidad.pregunta15 === undefined ||
          fragilidad.pregunta15 === false) &&
        (fragilidad.pregunta16 === undefined ||
          fragilidad.pregunta16 === false) &&
        (fragilidad.pregunta17 === undefined ||
          fragilidad.pregunta17 === false) &&
        (fragilidad.pregunta18 === undefined ||
          fragilidad.pregunta18 === false) &&
        (fragilidad.pregunta19 === undefined ||
          fragilidad.pregunta19 === false) &&
        (fragilidad.pregunta20 === undefined ||
          fragilidad.pregunta20 === false) &&
        (fragilidad.pregunta21 === undefined ||
          fragilidad.pregunta21 === false) &&
        (fragilidad.pregunta22 === undefined ||
          fragilidad.pregunta22 === false) &&
        (fragilidad.pregunta23 === undefined ||
          fragilidad.pregunta23 === false) &&
        (fragilidad.pregunta24 === undefined ||
          fragilidad.pregunta24 === false) &&
        (fragilidad.pregunta25 === undefined ||
          fragilidad.pregunta25 === false) &&
        (fragilidad.pregunta26 === undefined ||
          fragilidad.pregunta26 === false) &&
        (fragilidad.pregunta27 === undefined ||
          fragilidad.pregunta27 === false) &&
        (fragilidad.observaciones === undefined ||
          fragilidad.observaciones === null ||
          fragilidad.observaciones.trim() === "")
      ) {
        guardarFragilidad([]);
        //setActiveStep((prevActiveStep) => prevActiveStep + 1);
        //setSkipped(newSkipped);
        return;
      }

      if (
        fragilidad.lugar === undefined ||
        fragilidad.lugar === null ||
        fragilidad.lugar.trim() === ""
      ) {
        mensajeAlerta(
          "Marcadores de Fragilidad",
          "Debe indicar lugar de derivación !.",
          "info"
        );
        return;
      }

      if (
        fragilidad.observaciones === undefined ||
        fragilidad.observaciones === null ||
        fragilidad.observaciones.trim() === ""
      ) {
        mensajeAlerta(
          "Marcadores de Fragilidad",
          "Debe cuantificar gravedad de positivos !.",
          "info"
        );
        return;
      }
    }

    if (activeStep === 9 && norton && norton.length !== 0 && norton !== null) {
      // Validar Norton

      if (norton.estadoFisico === undefined) {
        mensajeAlerta(
          "Norton",
          "Debe marcar una opción para estado físico !.",
          "info"
        );
        return;
      }

      if (norton.estadoMental === undefined) {
        mensajeAlerta(
          "Norton",
          "Debe marcar una opción para estado mental !.",
          "info"
        );
        return;
      }

      if (norton.actividad === undefined) {
        mensajeAlerta(
          "Norton",
          "Debe marcar una opción para actividad !.",
          "info"
        );
        return;
      }

      if (norton.movilidad === undefined) {
        mensajeAlerta(
          "Norton",
          "Debe marcar una opción para movilidad !.",
          "info"
        );
        return;
      }

      if (norton.incontinencia === undefined) {
        mensajeAlerta(
          "Norton",
          "Debe marcar una opción para incontinencia !.",
          "info"
        );
        return;
      }
    }

    if (activeStep === 9) {
      showConfirm();
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const showConfirm = () => {
    confirmDialog({
      message: "Desea guardar los datos ?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Si",
      accept,
      reject,
    });
  };

  const accept = () => {
    agregarPreIngreso({
      selectedRowPaciente,
      geriatricoElegido,
      barthel,
      reloj,
      tinetti,
      lawtonBrody,
      escalaValoracionMental,
      yesavage,
      goldberg,
      evaluacionNutricional,
      tipoDieta,
      fragilidad,
      norton,
      selectedRow,
      usuario: state.usuario.usuario,
    });
    onHide();
    setTimeout(() => {
      obtenerPreIngresos({
        geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
      });
    }, 2000);
  };

  const reject = () => {
    toast.current.show({
      severity: "info",
      summary: "Pre-Ingreso",
      detail: "Los datos no se han guardado",
      life: 3000,
    });
  };

  function formatDate(dateString) {
    var allDate = dateString.split("-");
    var newDate = [allDate[2], allDate[1], allDate[0]].join("/");
    return newDate;
  }

  function getEdad(dateString) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  }

  const acceptImprimir = () => {
    const doc = new jsPDF();

    let hoy = new Date();

    let fechaDia = hoy.getDate();
    let fechaMes = hoy.getMonth() + 1;
    let fechaAnio = hoy.getFullYear();
    let nombreMes = "";

    if (fechaMes === 1) {
      nombreMes = "Enero";
    }
    if (fechaMes === 2) {
      nombreMes = "Febrero";
    }
    if (fechaMes === 3) {
      nombreMes = "Marzo";
    }
    if (fechaMes === 4) {
      nombreMes = "Abril";
    }
    if (fechaMes === 5) {
      nombreMes = "Mayo";
    }
    if (fechaMes === 6) {
      nombreMes = "Junio";
    }
    if (fechaMes === 7) {
      nombreMes = "Julio";
    }
    if (fechaMes === 8) {
      nombreMes = "Agosto";
    }
    if (fechaMes === 9) {
      nombreMes = "Septiembre";
    }
    if (fechaMes === 10) {
      nombreMes = "Octubre";
    }
    if (fechaMes === 11) {
      nombreMes = "Noviembre";
    }
    if (fechaMes === 12) {
      nombreMes = "Diciembre";
    }

    let row = 10;
    doc.setFontSize(10);
    doc.text("1", 200, row);
    row = 20;
    doc.setFontSize(11);
    let titulo = "CONTRATO DE INTERNACION";
    doc.text(titulo, 80, row);
    let textWidth = doc.getTextWidth(titulo);
    doc.line(80, row + 2, 80 + textWidth + 1, row + 2);
    doc.setFontSize(10);
    row = 30;
    doc.text(
      `Entre la residencia ${geriatricoElegido.nombre} Y el Sr./a: ${
        paciente.nombreRepresentante !== undefined &&
        paciente.nombreRepresentante !== null
          ? paciente.nombreRepresentante
          : " "
      } Con domicilio en la calle: ${
        paciente.domicilioRepresentante !== undefined &&
        paciente.domicilioRepresentante !== null
          ? paciente.domicilioRepresentante
          : " "
      } Localidad ${
        paciente.localidadRepresentanteId !== undefined &&
        paciente.localidadRepresentanteId !== null
          ? localidades[
              localidades.findIndex(
                (item) => item.id === paciente.localidadRepresentanteId
              )
            ].label
          : " "
      } titular de documento: Nº: ${
        paciente.numeroDocumentoRepresentante !== undefined &&
        paciente.numeroDocumentoRepresentante !== null
          ? paciente.numeroDocumentoRepresentante
          : " "
      } T.E.: ${
        paciente.telefonoRepresentante !== undefined &&
        paciente.telefonoRepresentante !== null
          ? paciente.telefonoRepresentante
          : " "
      }, quien se compromete a representar al Sr./Sra: ${
        paciente.nombre
      } Nacido el: ${formatDate(paciente.fechaNacimiento)} Documento: Nº: ${
        paciente.numeroDocumento
      } Lugar de Nacimiento: ${
        paciente.lugarNacimiento !== undefined &&
        paciente.lugarNacimiento !== null
          ? paciente.lugarNacimiento
          : " "
      } con domicilio en calle: ${paciente.domicilio} Localidad de ${
        paciente.localidadId !== undefined
          ? localidades[
              localidades.findIndex((item) => item.id === paciente.localidadId)
            ].label
          : " "
      } Obra Social ${
        paciente.obraSocialId !== undefined
          ? obrasSocialesMedicas[
              obrasSocialesMedicas.findIndex(
                (item) => item.id === paciente.obraSocialId
              )
            ].label
          : " "
      } carnet Nº: ${
        paciente.numeroObraSocial !== undefined &&
        paciente.numeroObraSocial !== null
          ? paciente.numeroObraSocial
          : " "
      }, edad ${getEdad(paciente.fechaNacimiento)}, estado civil: ${
        paciente.estadoCivil !== undefined && paciente.estadoCivil !== null
          ? paciente.estadoCivil
          : " "
      } En adelante denominado Internado.`,
      28,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 30;

    doc.text(`Convienen en celebrar el siguiente contrato:`, 30, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;

    doc.text(
      `PRIMERA: LA RESIDENCIA otorga servicio de internación geriátrica, más el servicio de hotelería, alimentación, Control Médico de Clínica General y Servicio de Emergencia Médica, al INTERNADO. Que al ingreso deberá completar la encuesta de satisfacción.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 15;

    let valorArancel = 0;
    if (
      paciente.obraSocialContratoId !== undefined &&
      paciente.obraSocialContratoId !== null &&
      paciente.obraSocialContratoId === 2
    ) {
      valorArancel = paciente.arancel;
    }

    let valorPanal = 0;
    if (paciente.panalId !== undefined && paciente.panalId !== null) {
      valorPanal =
        panales[panales.findIndex((item) => item.id === paciente.panalId)]
          .valor;
    }

    let valorDescartable = 0;
    if (
      paciente.descartableId !== undefined &&
      paciente.descartableId !== null
    ) {
      valorDescartable =
        descartables[
          descartables.findIndex((item) => item.id === paciente.descartableId)
        ].valor;
    }

    let valorTotal = parseFloat(
      Number(valorDescartable) + Number(valorPanal) + Number(valorArancel)
    ).toFixed(2);

    doc.text(
      `SEGUNDA: EL INTERNADO y/o REPRESENTANTE, pagarán mensualmente, en concepto de arancel, la suma de pesos ${valorTotal}. El importe pactado podrá ser ajustado por incrementos en el costo de vida o laboral y según convenios sindicales, en la misma moneda.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 15;

    doc.text(
      `TERCERA: El pago del arancel deberá hacerse en efectivo del día primero al cinco de cada mes, por período adelantado, en el domicilio de LA RESIDENCIA o donde este lo indique. Las internaciones efectuadas a partir del día cinco de cada mes, se abonarán mensualmente en la misma fecha que la anterior, también por adelantado. El pago que no se hiciere efectivo en las fechas mencionadas anteriormente, sufrirá un interés punitorio de 0.25 % diario.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 22;

    doc.text(
      `CUARTA: Ambas partes podrán resolver el presente contrato, previa notificación fehaciente, hecha con no menos de quince días (15) de anticipación. En caso de que la resolución sea por parte del INTERNADO y/o su REPRESENTANTE, deberá abonar el total del mes.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 16;

    doc.text(
      `QUINTA: Producida la rescisión o el fallecimiento del INTERNADO, este deberá ser retirado del instituto, previa liquidación de gastos, si los hubiere, En caso contrario el Instituto lo trasladará al domicilio de su REPRESENTANTE. En ausencia de este, en el domicilio de un familiar, Hospital Público o dependencia Policial, a costa de su REPRESENTANTE y/o derechohabientes, sin responsabilidad alguna por parte del Instituto.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 22;

    doc.text(
      `SEXTA: El REPRESENTANTE, se hará cargo ante un traslado o una emergencia de acompañar al INTERNADO, en caso de que el REPRESENTANTE, no esté dispuesto a acompañarlo o no se halle en su domicilio en dicha oportunidad.- Responderá por los gastos del acompañante.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 15;

    doc.text(
      `SEPTIMA: LA RESIDENCIA provee el Control Médico de Clínica Gral. (Realizado por profesionales médicos), en caso de que las dolencias que afectan al INTERNADO, requieran de medicina especializada, y/o atención que exija internación, y/o cuidados permanentes, el enfermo será trasladado al siguiente establecimiento: ${
        paciente.clinicaId !== undefined && paciente.clinicaId !== null
          ? clinicas[
              clinicas.findIndex((item) => item.id === paciente.clinicaId)
            ].label
          : " "
      } ubicado en la calle         ${
        paciente.clinicaId !== undefined && paciente.clinicaId !== null
          ? clinicas[
              clinicas.findIndex((item) => item.id === paciente.clinicaId)
            ].domicilio
          : " "
      }
de la localidad ${
        paciente.clinicaId !== undefined && paciente.clinicaId !== null
          ? clinicas[
              clinicas.findIndex((item) => item.id === paciente.clinicaId)
            ].localidad
          : " "
      } De no ser posible su internación en dicho establecimiento, se le internará en aquel que el Instituto considere adecuado, quedando los gastos a cargo de su REPRESENTANTE.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 30;

    doc.text(
      `OCTAVA: Una vez que el Instituto notifica al REPRESENTANTE de la internación o traslado a otro sitio, cesa la responsabilidad de LA RESIDENCIA. Aclarándose que en caso de fallecimiento, o pase a PAMI, cualquiera sea el día, no se reintegrará suma alguna del período mensual abonado.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 15;

    doc.text(
      `NOVENA: La falta de pago de una cuota, produce la mora de pleno derecho, quedando rescindido el contrato, sin necesidad de notificación alguna, siendo devuelto el internado a su domicilio, o al de su REPRESENTANTE. De no ser posible por cualquier causa, será internado en un Htal. Público o dependencia policial, siendo responsable su REPRESENTANTE y los parientes por los daños y perjuicios.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 22;

    doc.text(
      `DECIMA: Son obligaciones a cargo del INTERNADO y/o su REPRESENTANTE, los pagos de las cuotas mensuales, de los materiales descartables (pañales, etc.) de la medicación prescrita (cualquiera sea esta) , efectos de uso personales, elementos de higiene y confort, gastos de traslados y transporte; gastos de sepelio que hubiese obrado LA RESIDENCIA, en el caso que se le hubiere exigido la garantía para internación, sustituyendo dicha garantía por otra suficiente que se hiciere responsable por los daños y perjuicios que hubieran.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 25;

    doc.text(
      `UNDECIMA: El REPRESENTANTE DEL INTERNADO, se constituye como fiador de este contrato en carácter de codeudor solidario, liso y llanos y principal pagador; renunciando a los beneficios de excusión y división, hasta hacer íntegro el pago de todas las obligaciones emergentes del mismo, que pudiese adeudar respondiendo también por gastos y honorarios a que el mismo diese lugar.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("2", 200, row);
    row = 20;
    doc.setFontSize(10);

    doc.text(
      `DUODECIMA: LA RESIDENCIA no se responsabiliza por la pérdida de todo tipo de pertenencias de los INTERNADOS, que no sean de uso cotidiano, quedando bajo la exclusiva responsabilidad del INTERNADO y/o su REPRESENTANTE la pérdida de objetos de valor no declarados o que no fuesen de absoluta necesidad para el quehacer diario, valores, joyas, teléfonos celulares; ya que para lo cual LA RESIDENCIA pondrá todos los elementos para el desarrollo cotidiano y además de una caja de seguridad para el resguardo de dichos objetos, valores y cualquier tipo de moneda (siempre deberán ser declarados). Al ingreso se realizara un detalle de la ropa con que ingresa como así también del total de las pertenencias que se adjunta al presente y se tomara como válido...-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 50;

    doc.text(
      `PARA LOS AFILIADOS AL INSSJP (PAMI), OSPLAD, OSBA. El RESPONSABLE cumplirá las siguientes obligaciones; cuales se notifica:`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;

    doc.text(
      `1-Aceptar el descuento en concepto de co-seguro, explicitando los términos y alcances del mismo.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;

    doc.text(
      `2-Entrega de D.N.I y O.P.P en el establecimiento en que resida el/la titular.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;

    doc.text(
      `3-Presentación en OS ante el requerimiento de sus representantes.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;

    doc.text(
      `4-Cobertura de pañales, elementos de tocador, vestimenta, descartables y medicación.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;

    doc.text(
      `5-Las solicitudes de traslado de la Obra Social serán fehacientemente justificadas caso contrario no se dará lugar a la misma.-`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;

    doc.text(
      `6-Las comunicaciones entre ambas partes se realizaran en forma telefónica y vía mail, de acuerdo a las direcciones declarados en esta misma historia integral, de no responder estas direcciones serán tomadas como válidas, dejando copia como prueba suficiente de la comunicación, dando aviso a la oficina de la obra social, del INSSJP de la UGL correspondiente.`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 20;

    doc.text(
      `7-La residencia ejerce el derecho de aceptación y permanencia, de cada uno de los residentes.`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;

    doc.text(
      `LEIDO TODO LO QUE ANTECEDE. Las partes ratifican su contenido, que previamente les fue explicado, y de plena conformidad firman.`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 15;

    doc.text(
      `Mail: ${paciente.email ? paciente.email : ""} TEL: ${
        paciente.telefonoComunicaciones ? paciente.telefonoComunicaciones : ""
      }`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;

    doc.text(
      `Mail: ${
        paciente.emailAlternativo ? paciente.emailAlternativo : ""
      } TEL: ${paciente.telefonoUrgencias ? paciente.telefonoUrgencias : ""}`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;

    doc.text(
      `A los, ${fechaDia} Días del mes de ${nombreMes} De ${fechaAnio}.-`,
      108,
      row,
      "left",
      "middle"
    );

    row = row + 20;

    doc.text(
      `Firma del Representante ______________________________________________________________`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;

    doc.text(
      `Aclaración _________________________________________________________________________`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;

    doc.text(
      `LA RESIDENCIA ____________________________________________________________________`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;

    doc.text(
      `Aclaración _________________________________________________________________________`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("3", 200, row);
    row = 20;
    doc.setFontSize(11);
    titulo = "CUESTIONARIO FAMILIAR DE PRE-INGRESO: SOBRE FRAGILIDAD.";
    doc.setFont(undefined, "bold");
    doc.text(titulo, 50, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(50, row + 2, 50 + textWidth + 1, row + 2);
    doc.setFontSize(10);
    row = 30;

    doc.text(
      `Nombre y Apellido Responsable: ${
        paciente.nombreRepresentante !== undefined &&
        paciente.nombreRepresentante !== null
          ? paciente.nombreRepresentante
          : " "
      }  - Vínculo: ${
        paciente.vinculoId !== undefined && paciente.vinculoId !== null
          ? vinculos[
              vinculos.findIndex((item) => item.id === paciente.vinculoId)
            ].label
          : " "
      }`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      `Nombre y Apellido Residente: ${paciente.nombre}`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.setFont(undefined, "normal");
    doc.text(
      `Residentes a quienes se les debe considerar personas de alto riesgo, en cuanto a su estado de salud o su evolución. Los residentes necesitados de cuidados y vigilancia específicos, constituyen un conjunto para el que parece necesario que las Residencias cuenten con organización, infraestructuras, equipos y conocimientos adecuados.`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 20;

    titulo =
      "• Aquejados de problemas, deficiencias, incapacidades, psicológicas y sociales:";
    doc.setFont(undefined, "bold");
    doc.text(titulo, 30, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(30, row + 2, 30 + textWidth + 1, row + 2);

    row = row + 5;

    doc.setFont(undefined, "normal");
    doc.text(
      `
    • Recién ingresados.

    • Con antecedentes de suicidio.

    • Pacientes depresivos.

    • Que han sufrido una perdida familiar

    • Residentes agresivos (golpean o se golpean).

    • Que acaban de jubilarse y manifiestan problemas secundarios a la falta de trabajo.

    • Procedentes de altas hospitalarias.

    • Residentes abandonados por sus familiares.`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 75;

    titulo =
      "• Quejas de problemas, incapacidades o minusvalías psicofísicas y mentales:";
    doc.setFont(undefined, "bold");
    doc.text(titulo, 30, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(30, row + 2, 30 + textWidth + 1, row + 2);

    row = row + 5;

    doc.setFont(undefined, "normal");
    doc.text(
      `
    • Portadores de sondas o colostomías. Residentes con otras enfermedades digestivas que implican riesgo
      vital o alto componente incapacitante.

    • Con medicación vital o de difícil manejo.

    • Con enfermedades productivas de déficit de la visión (tensión ocular, anti-agregación, etc.).

    • Con graves déficit auditivos (hipoacusias que requieren audífonos o sorderas).

    • Amputados.

    • Residentes que necesitan de asistencia para la respiración (oxigenación y/o aspiración).

    • Residentes con necesidad de control de glucemia y de otras constantes vitales.

    • Con secuelas de fracturas de cadera, fractura de colles, aplastamientos vertebrales.

    • Con artrosis y osteoartritis.

    • Con epilepsia.

    • Residentes con incapacidad para salir al exterior.

    • Residentes con enfermedades oncológicas.

    • Alcohólicos/as o drogadictos/as.

    • Enfermos terminales.`,
      30,
      row,
      "left",
      "middle"
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("4", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 20;
    //doc.setFontSize(11);

    doc.text(
      `Diagnóstico Principal: ${
        paciente.diagnosticoId !== undefined &&
        paciente.diagnosticoId !== null &&
        paciente.diagnosticoId !== 0
          ? diagnosticos[
              diagnosticos.findIndex(
                (item) => item.id === paciente.diagnosticoId
              )
            ].label
          : " "
      }`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(`Medicación que recibe:`, 30, row, "left", "middle");

    row = row + 10;

    for (var i = 0; i < medicacionesPacientes.length; i++) {
      doc.text(
        `• ${medicacionesPacientes[i].label}`,
        40,
        row,
        "left",
        "middle"
      );
      row = row + 5;
    }

    doc.addPage();

    /*
    row = 10;
    doc.setFontSize(10);
    doc.text("5", 200, row);
    row = 20;


    doc.addPage();
    row = 10;
    doc.setFontSize(10);
    doc.text("6", 200, row);
    doc.addPage();
    */

    row = 10;
    doc.setFontSize(10);
    doc.text("5", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 20;
    titulo = "CONCENTIMIENTO INFORMADO:";
    doc.setFont(undefined, "bold");
    doc.text(titulo, 30, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(30, row + 2, 30 + textWidth + 1, row + 2);
    row = 30;

    doc.setFont(undefined, "normal");
    doc.text(`Declaración de consentimiento`, 30, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(
      `El sr/a ${paciente.nombre} de ${getEdad(
        paciente.fechaNacimiento
      )} años de edad, con domicilio en ${paciente.domicilio} ${
        paciente.localidadId !== undefined
          ? localidades[
              localidades.findIndex((item) => item.id === paciente.localidadId)
            ].label
          : " "
      } y DNI ${
        paciente.numeroDocumento
      } acepta las condiciones particulares explicadas y conoce los pormenores y los riesgos de su internación en la institución.`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 13;
    doc.text(
      `Representante legal (nombre y apellidos) ${
        paciente.nombreRepresentante !== undefined &&
        paciente.nombreRepresentante !== null
          ? paciente.nombreRepresentante
          : " "
      } de ${getEdad(
        paciente.fechaNacimientoRepresentante !== undefined &&
          paciente.fechaNacimientoRepresentante !== null
          ? paciente.fechaNacimientoRepresentante
          : " "
      )} años de edad, con domicilio en ${
        paciente.domicilioRepresentante !== undefined &&
        paciente.domicilioRepresentante !== null
          ? paciente.domicilioRepresentante
          : " "
      } ${
        paciente.localidadRepresentanteId !== undefined &&
        paciente.localidadRepresentanteId !== null
          ? localidades[
              localidades.findIndex(
                (item) => item.id === paciente.localidadRepresentanteId
              )
            ].label
          : " "
      } y DNI ${
        paciente.numeroDocumentoRepresentante
      }, en calidad de (indicar relación con la persona usuaria): ${
        paciente.vinculoId !== undefined && paciente.vinculoId !== null
          ? vinculos[
              vinculos.findIndex((item) => item.id === paciente.vinculoId)
            ].label
          : " "
      }  DECLARO: Que el DR. JOSE CLAUDIO RODRIGO, quien desempeña en el centro de internación Geriátrica ${
        geriatricoElegido.nombre
      } El cargo de Director médico y su plantel profesional me ha informado de forma comprensible y en un lugar privado y adecuado, acerca de los aspectos fundamentales relacionados con el ingreso en el centro. Ha contestado a mis dudas y me ha dado tiempo suficiente para reflexionar sobre la información recibida. Se me informo de la metodología de trabajo y funcionamiento del centro, mis obligaciones y derechos. Tanto así de cómo se desarrollan las actividades y las normativas de convivencia de la comunidad a la cual perteneceré. También para mis familiares. Sé que el instituto de internación es pago y privado, me informaron de los aranceles mensuales de este. Como así donde se encuentra ubicado. Acepto los alcances y accionar de mi obra social para con la institución, que se me ha explicado. También así las costumbres para una adecuada convivencia con los demás residentes, pudiendo rechazarlas en cualquier momento sin explicación alguna y conociendo la libertad que tengo de retirarme y/o ausentarme por mi propiavoluntad. ACEPTO las todas las actividades que se me ofrecen y que puedo realizar en forma voluntarias( no obligatorias), también los tratamientos que deberé cumplir, sabiendo que puedo concurrir a mi médico particular si así yo lo dispusiera y cambiarlo a mi propia voluntad. Por ello, otorgo libremente el presente consentimiento, del cual se me facilita una copia, sabiendo que lo puedo retirar en cualquier momento.`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 80;

    titulo = "INGRESO DE PERSONAS O ELEMENTOS NO AUTORIZADOS";
    doc.setFont(undefined, "bold");
    doc.text(titulo, 55, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(55, row + 2, 55 + textWidth + 1, row + 2);
    row = row + 10;

    doc.setFont(undefined, "normal");
    doc.text(
      `Por algún motivo deben ingresar a la institución algún material, remedios, cremas, comidas u otros útiles utensilios (no autorizados). Personas que por alguna razón no puedan estar debidamente autorizados. Con el control necesario y la autorización pertinente.`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 20;

    doc.text(
      `Cuales son:…………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………….........`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 20;

    doc.text(
      ` ${fechaDia} de ${nombreMes} de ${fechaAnio}.`,
      127,
      row,
      "left",
      "middle"
    );

    row = row + 20;

    doc.text(
      `Firma residente………………………………………………….DNI ${paciente.numeroDocumento}`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 20;

    doc.text(
      `Representante legal……………………………………………………………………………………………………`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 20;

    doc.text(
      `(Si la persona está incapacitada para firmar) DNI…………………………………………………………………`,
      30,
      row,
      "left",
      "middle"
    );

    doc.addPage();
    row = 10;
    doc.setFontSize(10);
    doc.text("6", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("7", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 14;
    titulo = "Indice Barthel";
    doc.setFont(undefined, "bold");
    doc.text(titulo, 80, row);
    //textWidth = doc.getTextWidth(titulo);
    //doc.line(80, row + 2, 80 + textWidth + 1, row + 2);
    row = 35;

    doc.setFont(undefined, "normal");

    row = row + 5;

    var columns = ["Actividad", "Descripción", "Puntaje", ""];
    var data = [
      [
        "",
        "Totalmente independiente.",
        "10",
        barthel.comer === "10" ? "*" : "",
      ],
      [
        "Comer",
        "Necesita ayuda para cortar carne, el pan, etc.",
        "5",
        barthel.comer === "5" ? "*" : "",
      ],
      ["", "Dependiente.", "0", barthel.comer === "0" ? "*" : ""],
      ["", "", "", ""],
      [
        "Lavarse",
        "Independiente: entra y sale solo del baño.",
        "5",
        barthel.lavarse === "5" ? "*" : "",
      ],
      ["", "Dependiente.", "0", barthel.lavarse === "0" ? "*" : ""],
      ["", "", "", ""],
      [
        "",
        "Independiente: capaz de ponerse y de quitarse la ropa, abotonarse, atarse los zapatos.",
        "10",
        barthel.vestirse === "10" ? "*" : "",
      ],
      ["Vestirse", "Necesita ayuda.", "5", barthel.vestirse === "5" ? "*" : ""],
      ["", "Dependiente.", "0", barthel.vestirse === "0" ? "*" : ""],
      ["", "", "", ""],
      [
        "Arreglarse",
        "Independiente para lavarse la cara, las manos, peinarse, afeitarse, maquillarse, etc.",
        "5",
        barthel.arreglarse === "5" ? "*" : "",
      ],
      ["", "Dependiente.", "0", barthel.arreglarse === "0" ? "*" : ""],
      ["", "", "", ""],
      [
        "",
        "Continencia normal.",
        "10",
        barthel.deposiciones === "10" ? "*" : "",
      ],
      [
        "Deposiciones",
        "Ocasionalmente algún episodio de incontinencia, o necesita ayuda para administrarse supositorios o lavativas.",
        "5",
        barthel.deposiciones === "5" ? "*" : "",
      ],
      ["", "Incontinencia.", "0", barthel.deposiciones === "0" ? "*" : ""],
      ["", "", "", ""],
      [
        "",
        "Continencia normal, o es capaz de cuidarse de la sonda si tiene una puesta.",
        "10",
        barthel.miccion === "10" ? "*" : "",
      ],
      [
        "Micción",
        "Un episodio diario como máximo de incontinencia, o necesita ayuda para cuidar de la sonda.",
        "5",
        barthel.miccion === "5" ? "*" : "",
      ],
      ["", "Incontinencia.", "0", barthel.miccion === "0" ? "*" : ""],
      ["", "", "", ""],
      [
        "",
        "Independiente para ir al cuarto de aseo, quitarse y ponerse la ropa…",
        "10",
        barthel.retrete === "10" ? "*" : "",
      ],
      [
        "Usar el inodoro",
        "Necesita ayuda para ir al inodoro, pero se limpia solo.",
        "5",
        barthel.retrete === "5" ? "*" : "",
      ],
      ["", "Dependiente.", "0", barthel.retrete === "0" ? "*" : ""],
      ["", "", "", ""],
      [
        "",
        "Independiente para ir del sillón a la cama.",
        "15",
        barthel.trasladarse === "15" ? "*" : "",
      ],
      [
        "Trasladarse",
        "Mínima ayuda física o supervisión para hacerlo.",
        "10",
        barthel.trasladarse === "10" ? "*" : "",
      ],
      [
        "",
        "Necesita gran ayuda, pero es capaz de mantenerse sentado solo.",
        "5",
        barthel.trasladarse === "5" ? "*" : "",
      ],
      ["", "Dependiente.", "0", barthel.trasladarse === "0" ? "*" : ""],
      ["", "", "", ""],
      [
        "",
        "Independiente, camina solo 50 metros.",
        "15",
        barthel.deambular === "15" ? "*" : "",
      ],
      [
        "Deambular",
        "Necesita ayuda física o supervisión para caminar 50 metros.",
        "10",
        barthel.deambular === "10" ? "*" : "",
      ],
      [
        "",
        "Independiente en silla de ruedas sin ayuda.",
        "5",
        barthel.deambular === "5" ? "*" : "",
      ],
      ["", "Dependiente.", "0", barthel.deambular === "0" ? "*" : ""],
      ["", "", "", ""],
      [
        "",
        "Independiente para bajar y subir escaleras.",
        "10",
        barthel.escalones === "10" ? "*" : "",
      ],
      [
        "Escalones",
        "Necesita ayuda física o supervisión para hacerlo.",
        "5",
        barthel.escalones === "5" ? "*" : "",
      ],
      ["", "Dependiente.", "0", barthel.escalones === "0" ? "*" : ""],
    ];

    doc.autoTable(columns, data, {
      margin: { top: 15, left: 30 },
      styles: {
        fontSize: 7,
      },
    });

    let finalY = doc.previousAutoTable.finalY;

    row = finalY + 2;

    doc.setFontSize(7);

    doc.text(
      `
    Máxima puntuación: 100 puntos (90 si va en silla de ruedas)
    Resultado - Grado de dependencia

    <20 Total
    20-35 Grave
    40-55 Moderado
    >= 60 Leve
    100 Independiente`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.setFontSize(10);

    doc.text(
      `
    Resultado : ${String(
      Number(barthel.comer ? barthel.comer : 0) +
        Number(barthel.lavarse ? barthel.lavarse : 0) +
        Number(barthel.vestirse ? barthel.vestirse : 0) +
        Number(barthel.arreglarse ? barthel.arreglarse : 0) +
        Number(barthel.deposiciones ? barthel.deposiciones : 0) +
        Number(barthel.miccion ? barthel.miccion : 0) +
        Number(barthel.retrete ? barthel.retrete : 0) +
        Number(barthel.trasladarse ? barthel.trasladarse : 0) +
        Number(barthel.deambular ? barthel.deambular : 0) +
        Number(barthel.escalones ? barthel.escalones : 0)
    )}`,
      150,
      row,
      "left",
      "middle"
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("8", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 14;
    titulo = "Escala de Lawton y Brody (AIVD)";
    doc.setFont(undefined, "bold");
    doc.text(titulo, 80, row);
    //textWidth = doc.getTextWidth(titulo);
    //doc.line(80, row + 2, 80 + textWidth + 1, row + 2);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.setFontSize(7);

    doc.text(
      `Anotar con la ayuda del cuidador principal, cuál es la situación concreta personal del paciente, respecto a estos 8 ítems de actividades instrumentales de la vida diaria`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    var columns = ["Descripción", "Puntaje", ""];
    var data = [
      ["A. CAPACIDAD PARA USAR EL TELÉFONO", "", ""],
      [
        "Utiliza el teléfono por iniciativa propia.",
        "1",
        lawtonBrody.telefono === "11" ? "*" : "",
      ],
      [
        "Es capaz de marcar bien algunos números familiares.",
        "1",
        lawtonBrody.telefono === "21" ? "*" : "",
      ],
      [
        "Es capaz de contestar al teléfono, pero no de marcar.",
        "1",
        lawtonBrody.telefono === "31" ? "*" : "",
      ],
      [
        "No es capaz de usar el teléfono.",
        "0",
        lawtonBrody.telefono === "40" ? "*" : "",
      ],
      ["B. IR DE COMPRAS", "", ""],
      [
        "Realiza todas las compras necesarias independientemente.",
        "1",
        lawtonBrody.compras === "11" ? "*" : "",
      ],
      [
        "Realiza independientemente pequeñas compras.",
        "0",
        lawtonBrody.compras === "20" ? "*" : "",
      ],
      [
        "Necesita ir acompañado para hacer cualquier compra.",
        "0",
        lawtonBrody.compras === "30" ? "*" : "",
      ],
      [
        "Totalmente incapaz de comprar.",
        "0",
        lawtonBrody.compras === "40" ? "*" : "",
      ],
      ["C. PREPARACIÓN DE LA COMIDA", "", ""],
      [
        "Organiza, prepara y sirve las comidas por sí solo adecuadamente.",
        "1",
        lawtonBrody.comida === "11" ? "*" : "",
      ],
      [
        "Prepara adecuadamente las comidas si se le proporcionan los ingredientes.",
        "0",
        lawtonBrody.comida === "20" ? "*" : "",
      ],
      [
        "Prepara, calienta y sirve las comidas, pero no sigue una dieta adecuada.",
        "0",
        lawtonBrody.comida === "30" ? "*" : "",
      ],
      [
        "Necesita que le preparen y sirvan las comidas.",
        "0",
        lawtonBrody.comida === "40" ? "*" : "",
      ],
      ["D. CUIDAR LA CASA", "", ""],
      [
        "Mantiene la casa solo o con ayuda ocasional (para trabajos pesados).",
        "1",
        lawtonBrody.casa === "11" ? "*" : "",
      ],
      [
        "Realiza tareas ligeras, como lavar los platos o hacer las camas.",
        "1",
        lawtonBrody.casa === "21" ? "*" : "",
      ],
      [
        "Realiza tareas ligeras, pero no puede mantener un adecuado nivel de limpieza.",
        "1",
        lawtonBrody.casa === "31" ? "*" : "",
      ],
      [
        "Necesita ayuda en todas las labores de la casa.",
        "1",
        lawtonBrody.casa === "41" ? "*" : "",
      ],
      [
        "No participa en ninguna labor de la casa.",
        "0",
        lawtonBrody.casa === "50" ? "*" : "",
      ],
      ["E. LAVADO DE ROPA", "", ""],
      [
        "Lava por sí solo toda su ropa.",
        "1",
        lawtonBrody.ropa === "11" ? "*" : "",
      ],
      [
        "Lava por sí solo pequeñas prendas.",
        "1",
        lawtonBrody.ropa === "21" ? "*" : "",
      ],
      [
        "Todo el lavado de ropa debe ser realizado por otro.",
        "0",
        lawtonBrody.ropa === "30" ? "*" : "",
      ],
      ["F. MEDIO DE TRANSPORTE", "", ""],
      [
        "Viaja solo en transporte público o conduce su propio coche..",
        "1",
        lawtonBrody.transporte === "11" ? "*" : "",
      ],
      [
        "Capaz de organizar su propio transporte en taxi, pero no usa transporte público.",
        "1",
        lawtonBrody.transporte === "21" ? "*" : "",
      ],
      [
        "Viaja en transporte público cuando va acompañado por otra persona.",
        "1",
        lawtonBrody.transporte === "31" ? "*" : "",
      ],
      [
        "Solo utiliza el taxi o el automóvil con ayuda de otros.",
        "0",
        lawtonBrody.transporte === "40" ? "*" : "",
      ],
      ["No viaja.", "0", lawtonBrody.transporte === "50" ? "*" : ""],
      ["G. RESPONSABILIDAD SOBRE LA MEDICACIÓN", "", ""],
      [
        "Es capaz de tomar su medicación a la hora y con la dosis correcta.",
        "1",
        lawtonBrody.medicacion === "11" ? "*" : "",
      ],
      [
        "Toma su medicación si la dosis le es preparada previamente.",
        "0",
        lawtonBrody.medicacion === "20" ? "*" : "",
      ],
      [
        "No es capaz de administrarse su medicación.",
        "0",
        lawtonBrody.medicacion === "30" ? "*" : "",
      ],

      ["H. CAPACIDAD DE UTILIZAR EL DINERO", "", ""],
      [
        "Se encarga de sus asuntos económicos por sí solo.",
        "1",
        lawtonBrody.economico === "11" ? "*" : "",
      ],
      [
        "Realiza las compras de cada día, pero necesita ayuda en las grandes compras, bancos…",
        "1",
        lawtonBrody.economico === "21" ? "*" : "",
      ],
      [
        "Incapaz de manejar dinero.",
        "0",
        lawtonBrody.economico === "30" ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: 15, left: 30 },
      styles: {
        fontSize: 7,
      },
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 2;

    doc.setFontSize(7);

    doc.text(
      `
    Máxima dependencia: 0 puntos
    Independencia total: 8 punto
    `,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.setFontSize(10);

    doc.text(
      `
    Resultado : ${String(
      Number(
        lawtonBrody.telefonoResultado ? lawtonBrody.telefonoResultado : 0
      ) +
        Number(
          lawtonBrody.comprasResultado ? lawtonBrody.comprasResultado : 0
        ) +
        Number(lawtonBrody.comidaResultado ? lawtonBrody.comidaResultado : 0) +
        Number(lawtonBrody.casaResultado ? lawtonBrody.casaResultado : 0) +
        Number(lawtonBrody.ropaResultado ? lawtonBrody.ropaResultado : 0) +
        Number(
          lawtonBrody.transporteResultado ? lawtonBrody.transporteResultado : 0
        ) +
        Number(
          lawtonBrody.medicacionResultado ? lawtonBrody.medicacionResultado : 0
        ) +
        Number(
          lawtonBrody.economicoResultado ? lawtonBrody.economicoResultado : 0
        )
    )}`,
      150,
      row,
      "left",
      "middle"
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("9", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 14;
    titulo = "Escala de Tinetti";
    doc.setFont(undefined, "bold");
    doc.text(titulo, 80, row);
    //textWidth = doc.getTextWidth(titulo);
    //doc.line(80, row + 2, 80 + textWidth + 1, row + 2);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.setFontSize(7);

    doc.text(
      `A) EQUILIBRIO (el sujeto está sentado en una silla rígida, sin apoyo para brazos).`,
      30,
      row,
      {
        maxWidth: 160,
        align: "left",
      }
    );

    var columns = ["Descripción", "Puntaje", ""];
    var data = [
      ["EQUILIBRIO SENTADO", "", ""],
      [
        "Se inclina o se desliza de la silla.",
        "0",
        tinetti.equilibrioSentado === "0" ? "*" : "",
      ],
      [
        "Está estable, seguro.",
        "1",
        tinetti.equilibrioSentado === "1" ? "*" : "",
      ],
      ["LEVANTARSE DE LA SILLA", "", ""],
      ["Es incapaz sin ayuda.", "0", tinetti.levantarse === "0" ? "*" : ""],
      [
        "Se debe ayudar con los brazos.",
        "1",
        tinetti.levantarse === "1" ? "*" : "",
      ],
      [
        "Se levanta sin usar los brazos.",
        "2",
        tinetti.levantarse === "2" ? "*" : "",
      ],
      ["EN EL INTENTO DE LEVANTARSE", "", ""],
      [
        "Es incapaz sin ayuda.",
        "0",
        tinetti.intentoLevantarse === "0" ? "*" : "",
      ],
      [
        "Es capaz pero necesita más de un intento.",
        "1",
        tinetti.intentoLevantarse === "1" ? "*" : "",
      ],
      [
        "Es capaz al primer intento.",
        "2",
        tinetti.intentoLevantarse === "2" ? "*" : "",
      ],
      ["EQUILIBRIO DE PIÉ (LOS PRIMEROS 5 SEGUNDOS)", "", ""],
      [
        "Inestable (vacila, mueve los piés, marcada oscilación del tronco).",
        "0",
        tinetti.equilibrioSegundos === "0" ? "*" : "",
      ],
      [
        "Estable gracias al bastón u otro auxilio para sujetarse.",
        "1",
        tinetti.equilibrioSegundos === "1" ? "*" : "",
      ],
      [
        "Estable sin soportes o auxilios.",
        "2",
        tinetti.equilibrioSegundos === "2" ? "*" : "",
      ],
      ["EQUILIBRIO DE PIÉ PROLONGADO", "", ""],
      [
        "Inestable (vacila, mueve los piés, marcada oscilación del tronco).",
        "0",
        tinetti.equilibrioProlongado === "0" ? "*" : "",
      ],
      [
        "Estable pero con base de apoyo amplia (maleolos mediales>10cm) o usa auxilio.",
        "1",
        tinetti.equilibrioProlongado === "1" ? "*" : "",
      ],
      [
        "Estable con base de apoyo estrecha, sin soportes o auxilios.",
        "2",
        tinetti.equilibrioProlongado === "2" ? "*" : "",
      ],
      [
        "ROMBERG SENSIBILIZADO (CON OJOS ABIERTOS, PIÉS JUNTOS, EMPUJAR LEVEMENTE CON LA PALMA DE LA MANO SOBRE EL ESTERNÓN DEL SUJETO EN 3 OPORTUNIDADES)",
        "",
        "",
      ],
      [
        "Comienza a caer.",
        "0",
        tinetti.rombergSensibilizado === "0" ? "*" : "",
      ],
      [
        "Oscila, pero se endereza solo.",
        "1",
        tinetti.rombergSensibilizado === "1" ? "*" : "",
      ],
      ["Estable.", "2", tinetti.rombergSensibilizado === "2" ? "*" : ""],
      ["ROMBERG (CON OJOS CERRADOS E IGUAL QUE EL ANTERIOR)", "", ""],
      ["Inestable.", "0", tinetti.romberg === "0" ? "*" : ""],
      ["Estable.", "1", tinetti.romberg === "1" ? "*" : ""],
      ["GIRAR EN 360°", "", ""],
      [
        "Con pasos discontinuos o movimiento no homogéneo.",
        "0",
        tinetti.girar === "0" ? "*" : "",
      ],
      [
        "Con pasos contínuos o movimiento homogéneo.",
        "1",
        tinetti.girar === "1" ? "*" : "",
      ],
      [
        "Inestable (se sujeta, oscila).",
        "0",
        tinetti.girar2 === "0" ? "*" : "",
      ],
      ["Estable.", "1", tinetti.girar2 === "1" ? "*" : ""],
      ["SENTARSE", "", ""],
      [
        "Inseguro (equivoca distancia, cae sobre la silla).",
        "0",
        tinetti.sentarse === "0" ? "*" : "",
      ],
      [
        "Usa los brazos o tiene un movimiento discontinuo.",
        "1",
        tinetti.sentarse === "1" ? "*" : "",
      ],
      [
        "Seguro, movimiento continuo.",
        "2",
        tinetti.sentarse === "2" ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: 30, left: 30 },
      styles: {
        fontSize: 7,
      },
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 2;

    row = row + 10;

    doc.setFontSize(10);

    doc.text(
      `
    PUNTAJE EQUILIBRADO  : ${String(
      Number(tinetti.equilibrioSentado ? tinetti.equilibrioSentado : 0) +
        Number(tinetti.levantarse ? tinetti.levantarse : 0) +
        Number(tinetti.intentoLevantarse ? tinetti.intentoLevantarse : 0) +
        Number(tinetti.equilibrioSegundos ? tinetti.equilibrioSegundos : 0) +
        Number(
          tinetti.equilibrioProlongado ? tinetti.equilibrioProlongado : 0
        ) +
        Number(
          tinetti.rombergSensibilizado ? tinetti.rombergSensibilizado : 0
        ) +
        Number(tinetti.romberg ? tinetti.romberg : 0) +
        Number(tinetti.girar ? tinetti.girar : 0) +
        Number(tinetti.girar2 ? tinetti.girar2 : 0) +
        Number(tinetti.sentarse ? tinetti.sentarse : 0)
    )} / 16`,
      100,
      row,
      "left",
      "middle"
    );

    doc.addPage();
    row = 10;
    doc.setFontSize(10);
    doc.text("10", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("11", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 14;
    titulo = "HC PSICOLOGICA";
    doc.setFont(undefined, "bold");
    doc.text(titulo, 80, row);
    //textWidth = doc.getTextWidth(titulo);
    //doc.line(80, row + 2, 80 + textWidth + 1, row + 2);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.setFontSize(7);

    doc.text(
      `    ESCALA DE VALORACION MENTAL:
    MINIMENTAL: Fecha:                         Punto de corte 23-24
      `,
      30,
      row,
      "left",
      "middle"
    );

    var columns = ["Descripción", "Puntaje", ""];
    var data = [
      ["ORIENTACION", "", ""],
      [
        "Dígame el día - Fecha - Mes - Estación - Año",
        "5",
        escalaValoracionMental.pregunta1Value === "5" ? "*" : "",
      ],
      [
        "Hospital (o lugar) - ciudad - Provincia - País",
        "5",
        escalaValoracionMental.pregunta2Value === "5" ? "*" : "",
      ],
      ["", "", ""],
      ["FIJACION", "", ""],
      [
        "Repita estas tres palabras: Peso - Caballo – Manzana (Repetirlas hasta que las aprenda)",
        "3",
        escalaValoracionMental.pregunta3Value === "3" ? "*" : "",
      ],
      ["", "", ""],
      ["CONCENTRACION Y CÁLCULO", "", ""],
      [
        "Si tiene 30 pesos. y me va dando de 3 en 3, cuantas le van quedando?",
        "5",
        escalaValoracionMental.pregunta4Value === "5" ? "*" : "",
      ],
      [
        "Repita estos números hacia atrás: 5-9-2 (Asignar 1 pto. por cada respuesta correcta)",
        "3",
        escalaValoracionMental.pregunta5,
      ],
      ["", "", ""],
      ["MEMORIA", "", ""],
      [
        "Recuerda las tres palabras que le he dicho antes?",
        "3",
        escalaValoracionMental.pregunta6Value === "3" ? "*" : "",
      ],
      ["", "", ""],
      ["LENGUAJE Y CONSTRUCCION", "", ""],
      [
        "Mostrar un bolígrafo: que es esto?",
        "1",
        escalaValoracionMental.pregunta7Value === "1" ? "*" : "",
      ],
      [
        "Repetirlo con un reloj de pulsera",
        "1",
        escalaValoracionMental.pregunta8Value === "1" ? "*" : "",
      ],
      [
        "Repetir esta frase: 'En un trigal había cinco perros",
        "1",
        escalaValoracionMental.pregunta9Value === "1" ? "*" : "",
      ],
      [
        "Si una manzana y una pera son frutas, que son el rojo y el verde?",
        "1",
        escalaValoracionMental.pregunta10Value === "1" ? "*" : "",
      ],
      [
        "un perro y un gato?",
        "2",
        escalaValoracionMental.pregunta11Value === "2" ? "*" : "",
      ],
      [
        "Tome este papel con la mano derecha, dóblelo y póngalo encima de la mesa.",
        "3",
        escalaValoracionMental.pregunta12Value === "3" ? "*" : "",
      ],
      ["(Lea esto y haga lo que dice)", "", ""],
      [
        "CIERRE LOS OJOS",
        "1",
        escalaValoracionMental.pregunta13Value === "1" ? "*" : "",
      ],
      [
        "Escriba una frase: ..........",
        "1",
        escalaValoracionMental.pregunta14Value === "1" ? "*" : "",
      ],
      [
        "Copie este dibujo",
        "1",
        escalaValoracionMental.pregunta15Value === "1" ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: 15, left: 30 },
      styles: {
        fontSize: 10,
      },
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 2;

    doc.addImage(test, "png", 20, row, 20, 20);

    row = row + 10;

    doc.setFontSize(10);

    doc.text(
      `
    Resultado : ${String(
      Number(
        escalaValoracionMental.pregunta1
          ? escalaValoracionMental.pregunta1Value
          : 0
      ) +
        Number(
          escalaValoracionMental.pregunta2
            ? escalaValoracionMental.pregunta2Value
            : 0
        ) +
        Number(
          escalaValoracionMental.pregunta3
            ? escalaValoracionMental.pregunta3Value
            : 0
        ) +
        Number(
          escalaValoracionMental.pregunta4
            ? escalaValoracionMental.pregunta4Value
            : 0
        ) +
        Number(
          escalaValoracionMental.pregunta5
            ? escalaValoracionMental.pregunta5
            : 0
        ) +
        Number(
          escalaValoracionMental.pregunta6
            ? escalaValoracionMental.pregunta6Value
            : 0
        ) +
        Number(
          escalaValoracionMental.pregunta7
            ? escalaValoracionMental.pregunta7Value
            : 0
        ) +
        Number(
          escalaValoracionMental.pregunta8
            ? escalaValoracionMental.pregunta8Value
            : 0
        ) +
        Number(
          escalaValoracionMental.pregunta9
            ? escalaValoracionMental.pregunta9Value
            : 0
        ) +
        Number(
          escalaValoracionMental.pregunta10
            ? escalaValoracionMental.pregunta10Value
            : 0
        ) +
        Number(
          escalaValoracionMental.pregunta11
            ? escalaValoracionMental.pregunta11Value
            : 0
        ) +
        Number(
          escalaValoracionMental.pregunta12
            ? escalaValoracionMental.pregunta12Value
            : 0
        ) +
        Number(
          escalaValoracionMental.pregunta13
            ? escalaValoracionMental.pregunta13Value
            : 0
        ) +
        Number(
          escalaValoracionMental.pregunta14
            ? escalaValoracionMental.pregunta14Value
            : 0
        ) +
        Number(
          escalaValoracionMental.pregunta15
            ? escalaValoracionMental.pregunta15Value
            : 0
        )
    )}`,
      150,
      row,
      "left",
      "middle"
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("12", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 20;
    titulo = "HC PSICOLOGICA";
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text(titulo, 80, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(80, row + 2, 80 + textWidth + 1, row + 2);
    row = 30;

    doc.setFont(undefined, "normal");

    doc.text(
      `ESCALA DE ANSIEDAD Y DEPRESION DE GOLDBERG`,
      35,
      row,
      "left",
      "middle"
    );

    var columns = ["Subescala de ansiedad", "Si", "No"];
    var data = [
      [
        "(1) - ¿Se ha sentido muy excitado, nervioso o en tensión?",
        goldberg.pregunta1 === "1" ? "*" : "",
        goldberg.pregunta1 === "0" ? "*" : "",
      ],
      [
        "(2) - ¿Ha estado muy preocupado poe algo?",
        goldberg.pregunta3 === "1" ? "*" : "",
        goldberg.pregunta3 === "0" ? "*" : "",
      ],
      [
        "(3) - ¿Se ha sentido muy irritable?",
        goldberg.pregunta5 === "1" ? "*" : "",
        goldberg.pregunta5 === "0" ? "*" : "",
      ],
      [
        "(4) - ¿Ha tenido dificultad para relajarse?",
        goldberg.pregunta7 === "1" ? "*" : "",
        goldberg.pregunta7 === "0" ? "*" : "",
      ],
      [
        "(5) - ¿Ha dormido mal, ha tenido dificultades para dormir?",
        goldberg.pregunta9 === "1" ? "*" : "",
        goldberg.pregunta9 === "0" ? "*" : "",
      ],
      [
        "(6) - ¿Ha tenido dolores de cabeza o nuca?",
        goldberg.pregunta11 === "1" ? "*" : "",
        goldberg.pregunta11 === "0" ? "*" : "",
      ],
      [
        "(7) - ¿Ha tenido algunos de estos síntomas: temblores, hormigueos, mareos, sudores, diarrea? (síntomas vegetativos)",
        goldberg.pregunta13 === "1" ? "*" : "",
        goldberg.pregunta13 === "0" ? "*" : "",
      ],
      [
        "(8) - ¿Ha estado preocupado por su salud?",
        goldberg.pregunta15 === "1" ? "*" : "",
        goldberg.pregunta15 === "0" ? "*" : "",
      ],
      [
        "(9) - ¿Ha tenido alguna dificultad para conciliar el sueño, para quedarse dormido?",
        goldberg.pregunta17 === "1" ? "*" : "",
        goldberg.pregunta17 === "0" ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: 35, left: 30 },
      styles: {
        fontSize: 7,
      },
    });

    finalY = doc.previousAutoTable.finalY;
    row = finalY + 5;

    doc.text(
      `
    Resultado : ${String(
      Number(goldberg.pregunta1 ? goldberg.pregunta1 : 0) +
        Number(goldberg.pregunta3 ? goldberg.pregunta3 : 0) +
        Number(goldberg.pregunta5 ? goldberg.pregunta5 : 0) +
        Number(goldberg.pregunta7 ? goldberg.pregunta7 : 0) +
        Number(goldberg.pregunta9 ? goldberg.pregunta9 : 0) +
        Number(goldberg.pregunta11 ? goldberg.pregunta11 : 0) +
        Number(goldberg.pregunta13 ? goldberg.pregunta13 : 0) +
        Number(goldberg.pregunta15 ? goldberg.pregunta15 : 0) +
        Number(goldberg.pregunta17 ? goldberg.pregunta17 : 0)
    )}`,
      60,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(`Puntuación`, 30, row, "left", "middle");

    row = row + 5;

    doc.text(`0-4 : Valoración normal`, 30, row, "left", "middle");

    row = row + 5;

    doc.text(`Mayor a 4 : Presencia de ansiedad`, 30, row, "left", "middle");

    var columns = ["Subescala de depresión", "Si", "No"];
    var data = [
      [
        "(1) - ¿Se ha sentido con poca energía?",
        goldberg.pregunta2 === "1" ? "*" : "",
        goldberg.pregunta2 === "0" ? "*" : "",
      ],
      [
        "(2) - ¿Ha perdido Ud. su interés por las cosas?",
        goldberg.pregunta4 === "1" ? "*" : "",
        goldberg.pregunta4 === "0" ? "*" : "",
      ],
      [
        "(3) - ¿Ha perdido la confianza en sí mismo?",
        goldberg.pregunta6 === "1" ? "*" : "",
        goldberg.pregunta6 === "0" ? "*" : "",
      ],
      [
        "(4) - ¿Se ha sentido Ud. desesperanzado, sin esperanza?",
        goldberg.pregunta8 === "1" ? "*" : "",
        goldberg.pregunta8 === "0" ? "*" : "",
      ],
      [
        "(5) - ¿Ha tenido dificultad para concentrarse?",
        goldberg.pregunta10 === "1" ? "*" : "",
        goldberg.pregunta10 === "0" ? "*" : "",
      ],
      [
        "(6) - ¿Ha perdido peso? (a causa de la falta de apetito)",
        goldberg.pregunta12 === "1" ? "*" : "",
        goldberg.pregunta12 === "0" ? "*" : "",
      ],
      [
        "(7) - ¿Se ha estado despertando demasiado temprano?",
        goldberg.pregunta14 === "1" ? "*" : "",
        goldberg.pregunta14 === "0" ? "*" : "",
      ],
      [
        "(8) - ¿Se ha sentido Ud. enlentecido?",
        goldberg.pregunta16 === "1" ? "*" : "",
        goldberg.pregunta16 === "0" ? "*" : "",
      ],
      [
        "(9) - ¿Cree Ud. que ha tenido tendencia de encontrarse peor por las mañanas?",
        goldberg.pregunta18 === "1" ? "*" : "",
        goldberg.pregunta18 === "0" ? "*" : "",
      ],
    ];

    row = row + 10;

    doc.autoTable(columns, data, {
      margin: { left: 30 },
      startY: row,
      styles: {
        fontSize: 8,
      },
      //showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;

    doc.setFontSize(10);

    doc.text(
      `
    Resultado : ${String(
      Number(goldberg.pregunta2 ? goldberg.pregunta2 : 0) +
        Number(goldberg.pregunta4 ? goldberg.pregunta4 : 0) +
        Number(goldberg.pregunta6 ? goldberg.pregunta6 : 0) +
        Number(goldberg.pregunta8 ? goldberg.pregunta8 : 0) +
        Number(goldberg.pregunta10 ? goldberg.pregunta10 : 0) +
        Number(goldberg.pregunta12 ? goldberg.pregunta12 : 0) +
        Number(goldberg.pregunta14 ? goldberg.pregunta14 : 0) +
        Number(goldberg.pregunta16 ? goldberg.pregunta16 : 0) +
        Number(goldberg.pregunta18 ? goldberg.pregunta18 : 0)
    )}`,
      60,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(`Puntuación`, 30, row, "left", "middle");

    row = row + 5;

    doc.text(`0-3 : Estado anímico normal`, 30, row, "left", "middle");

    row = row + 5;

    doc.text(`Mayor a 3 : Presencia de depresión`, 30, row, "left", "middle");

    //row = row + 5

    let textDiag = "";
    for (var i = 0; i < diagnosticosPacientes.length; i++) {
      textDiag = textDiag + "•" + diagnosticosPacientes[i].label + "  ";
    }

    row = row + 10;

    doc.text("Diagnóstico primario: " + textDiag, 30, row, {
      maxWidth: 140,
      align: "justify",
    });

    doc.setFont(undefined, "normal");

    let w = doc.getTextDimensions(textDiag);
    if (w.w > 180) {
      row = row + 12;
    } else {
      row = row + 5;
    }

    row = row + 5;

    doc.text(
      "Observaciones: " +
        (goldberg !== undefined &&
        goldberg !== null &&
        goldberg.observaciones !== undefined &&
        goldberg.observaciones !== null
          ? goldberg.observaciones
          : ""),
      30,
      row,
      {
        maxWidth: 140,
        align: "left",
      }
    );

    row = 290;

    doc.text("FIRMA Y SELLO DEL PROFESIONAL", 120, row, "left", "middle");
    doc.addPage();


    row = 10;
    doc.setFontSize(10);
    doc.text("13", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    /*
    row = 20;
    titulo = "Valoración Clínica de Demencia (Spanish version of CDR)";
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text(titulo, 60, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(60, row + 2, 60 + textWidth + 1, row + 2);
    */
    row = 30

    doc.setFont(undefined, "normal");



    doc.addImage(cdr, "png", 30, row, 170, 140);


    /*

    var columns = ["", "", "","","",""];
    var data = [
      [
        "Valoración Clínica de Demencia (CDR)",
        "0",
        "0.5",
        "1",
        "2",
        "3",
      ],
    ]

    doc.autoTable(columns, data, {
      margin: { top: 25, left: 30 },
      styles: {
        fontSize: 7,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;
    row = finalY + 5;

    var columns = ["Ninguno (0)", "Dudoso (0.5)", "Leve (1)", "Moderado (2)", "Grave (3)"];
    var data = [
      [
        "Memoria",
        "Sin pérdida de memoria o leves olvidos inconstantes",
        "Olvidos leves constantes",
      ],
      [
        "(2) - ¿Ha estado muy preocupado poe algo?",
        goldberg.pregunta3 === "1" ? "*" : "",
        goldberg.pregunta3 === "0" ? "*" : "",
      ],
      [
        "(3) - ¿Se ha sentido muy irritable?",
        goldberg.pregunta5 === "1" ? "*" : "",
        goldberg.pregunta5 === "0" ? "*" : "",
      ],
      [
        "(4) - ¿Ha tenido dificultad para relajarse?",
        goldberg.pregunta7 === "1" ? "*" : "",
        goldberg.pregunta7 === "0" ? "*" : "",
      ],
      [
        "(5) - ¿Ha dormido mal, ha tenido dificultades para dormir?",
        goldberg.pregunta9 === "1" ? "*" : "",
        goldberg.pregunta9 === "0" ? "*" : "",
      ],
      [
        "(6) - ¿Ha tenido dolores de cabeza o nuca?",
        goldberg.pregunta11 === "1" ? "*" : "",
        goldberg.pregunta11 === "0" ? "*" : "",
      ],
      [
        "(7) - ¿Ha tenido algunos de estos síntomas: temblores, hormigueos, mareos, sudores, diarrea? (síntomas vegetativos)",
        goldberg.pregunta13 === "1" ? "*" : "",
        goldberg.pregunta13 === "0" ? "*" : "",
      ],
      [
        "(8) - ¿Ha estado preocupado por su salud?",
        goldberg.pregunta15 === "1" ? "*" : "",
        goldberg.pregunta15 === "0" ? "*" : "",
      ],
      [
        "(9) - ¿Ha tenido alguna dificultad para conciliar el sueño, para quedarse dormido?",
        goldberg.pregunta17 === "1" ? "*" : "",
        goldberg.pregunta17 === "0" ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 30 },
      startY: row,
      styles: {
        fontSize: 7,
      },
    });

    finalY = doc.previousAutoTable.finalY;
    row = finalY + 5;

    doc.text(
      `
    Resultado : ${String(
      Number(goldberg.pregunta1 ? goldberg.pregunta1 : 0) +
        Number(goldberg.pregunta3 ? goldberg.pregunta3 : 0) +
        Number(goldberg.pregunta5 ? goldberg.pregunta5 : 0) +
        Number(goldberg.pregunta7 ? goldberg.pregunta7 : 0) +
        Number(goldberg.pregunta9 ? goldberg.pregunta9 : 0) +
        Number(goldberg.pregunta11 ? goldberg.pregunta11 : 0) +
        Number(goldberg.pregunta13 ? goldberg.pregunta13 : 0) +
        Number(goldberg.pregunta15 ? goldberg.pregunta15 : 0) +
        Number(goldberg.pregunta17 ? goldberg.pregunta17 : 0)
    )}`,
      60,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(`Puntuación`, 30, row, "left", "middle");

    row = row + 5;

    doc.text(`0-4 : Valoración normal`, 30, row, "left", "middle");

    row = row + 5;

    doc.text(`Mayor a 4 : Presencia de ansiedad`, 30, row, "left", "middle");

    var columns = ["Subescala de depresión", "Si", "No"];
    var data = [
      [
        "(1) - ¿Se ha sentido con poca energía?",
        goldberg.pregunta2 === "1" ? "*" : "",
        goldberg.pregunta2 === "0" ? "*" : "",
      ],
      [
        "(2) - ¿Ha perdido Ud. su interés por las cosas?",
        goldberg.pregunta4 === "1" ? "*" : "",
        goldberg.pregunta4 === "0" ? "*" : "",
      ],
      [
        "(3) - ¿Ha perdido la confianza en sí mismo?",
        goldberg.pregunta6 === "1" ? "*" : "",
        goldberg.pregunta6 === "0" ? "*" : "",
      ],
      [
        "(4) - ¿Se ha sentido Ud. desesperanzado, sin esperanza?",
        goldberg.pregunta8 === "1" ? "*" : "",
        goldberg.pregunta8 === "0" ? "*" : "",
      ],
      [
        "(5) - ¿Ha tenido dificultad para concentrarse?",
        goldberg.pregunta10 === "1" ? "*" : "",
        goldberg.pregunta10 === "0" ? "*" : "",
      ],
      [
        "(6) - ¿Ha perdido peso? (a causa de la falta de apetito)",
        goldberg.pregunta12 === "1" ? "*" : "",
        goldberg.pregunta12 === "0" ? "*" : "",
      ],
      [
        "(7) - ¿Se ha estado despertando demasiado temprano?",
        goldberg.pregunta14 === "1" ? "*" : "",
        goldberg.pregunta14 === "0" ? "*" : "",
      ],
      [
        "(8) - ¿Se ha sentido Ud. enlentecido?",
        goldberg.pregunta16 === "1" ? "*" : "",
        goldberg.pregunta16 === "0" ? "*" : "",
      ],
      [
        "(9) - ¿Cree Ud. que ha tenido tendencia de encontrarse peor por las mañanas?",
        goldberg.pregunta18 === "1" ? "*" : "",
        goldberg.pregunta18 === "0" ? "*" : "",
      ],
    ];

    row = row + 10;

    doc.autoTable(columns, data, {
      margin: { left: 30 },
      startY: row,
      styles: {
        fontSize: 8,
      },
      //showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    */

    row = finalY + 5;

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("14", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 14;
    titulo = "Test del Reloj";
    doc.setFont(undefined, "bold");
    doc.text(titulo, 80, row);
    //textWidth = doc.getTextWidth(titulo);
    //doc.line(80, row + 2, 80 + textWidth + 1, row + 2);
    row = 35;

    doc.setFont(undefined, "normal");

    row = row + 5;

    var columns = ["", "", ""];
    var data = [
      ["1- Esfera del reloj ( máximo 2 puntos)", "", ""],
      [
        "Dibujo normal. Esfera circular u ovalada con pequeñas distorsiones por temblor.",
        "2",
        reloj.esfera === "2" ? "*" : "",
      ],
      [
        "Incompleto o con alguna distorsión significativa. Esfera muy asimétrica.",
        "1",
        reloj.esfera === "1" ? "*" : "",
      ],
      [
        "Ausencia de dibujo o totalmente distorsionado.",
        "0",
        reloj.esfera === "0" ? "*" : "",
      ],
      ["2- Presencia o secuencia de los números ( máximo 4 puntos ) ", "", ""],
      [
        "Todos los números presentes y en el orden correcto. Sólo pequeños errores de la localización espacial en menos de 4 números. ",
        "4",
        reloj.numero === "40" ? "*" : "",
      ],
      [
        "Cuando los pequeños errores en la colocación espacial se dan en 4 o más números pequeños.",
        "3.5",
        reloj.numero === "35" ? "*" : "",
      ],
      [
        "Todos los presentes con error significativo en la localización espacial.",
        "3",
        reloj.numero === "30" ? "*" : "",
      ],
      [
        "Números con algún desorden en la secuencia (menos de 4 números).",
        "3",
        reloj.numero === "31" ? "*" : "",
      ],
      [
        "Omisión o adicción de algún número  pero sin distorsiones en los restantes.",
        "2",
        reloj.numero === "20" ? "*" : "",
      ],
      [
        "Números con algún desorden en la secuencia (4 o más números).",
        "2",
        reloj.numero === "21" ? "*" : "",
      ],
      [
        "Los 12 números colocados en sentido anti horario (rotación inversa). ",
        "2",
        reloj.numero === "22" ? "*" : "",
      ],
      [
        "Todos los números presentes, pero con gran distorsión espacial (números fuera del reloj o dibujados en la media esfera, etc.).",
        "2",
        reloj.numero === "23" ? "*" : "",
      ],
      [
        "Presencia de los 12 números en una línea vertical, horizontal u oblicua (alienación numérica).",
        "2",
        reloj.numero === "24" ? "*" : "",
      ],
      [
        "Ausencia o exceso de números con distorsión espacial.",
        "1",
        reloj.numero === "10" ? "*" : "",
      ],
      [
        "Alineación numérica con falta o exceso de números.",
        "1",
        reloj.numero === "11" ? "*" : "",
      ],
      [
        "Rotación inversa con falta o exceso de número. ",
        "1",
        reloj.numero === "12" ? "*" : "",
      ],
      [
        "Ausencia o escasa representación de números (menos de 6 números dibujados).",
        "0",
        reloj.numero === "00" ? "*" : "",
      ],
      ["3- Presencia y localización de agujas  ( máximo 4 puntos)", "", ""],
      [
        "Las agujas están en posición correcta y con las proporciones adecuadas de tamaño.",
        "4",
        reloj.aguja === "40" ? "*" : "",
      ],
      [
        "Las agujas están en posición correcta pero ambas de igual tamaño.",
        "3.5",
        reloj.aguja === "35" ? "*" : "",
      ],
      [
        "Pequeños errores de  la localización (situar alguna de  las agujas en el espacio destinado al número anterior o posterior).",
        "3",
        reloj.aguja === "30" ? "*" : "",
      ],
      [
        "Aguja de los minutos  más corta que la de la hora, con pauta horaria correcta.",
        "3",
        reloj.aguja === "31" ? "*" : "",
      ],
      [
        "Gran distorsión en la localización de las agujas (incluso si marcan las once y diez, cuando los números presentan errores significativos en la localización espacial).",
        "2",
        reloj.aguja === "20" ? "*" : "",
      ],
      [
        "Cuando las agujas no se juntan en el punto central y marcan la hora correcta.).",
        "2",
        reloj.aguja === "21" ? "*" : "",
      ],
      [
        "Cuando las agujas no se juntan en el punto central y marcan la hora incorrecta.",
        "1",
        reloj.aguja === "10" ? "*" : "",
      ],
      [
        "Presencia de una sola aguja o un esbozo de las dos.",
        "1",
        reloj.aguja === "11" ? "*" : "",
      ],
      [
        "Ausencia de una aguja o perseveración en el dibujo de las mismas. Efecto en forma de rueda de carro.",
        "0",
        reloj.aguja === "00" ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: 15, left: 30 },
      styles: {
        fontSize: 7,
      },
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 2;

    doc.setFontSize(7);

    doc.text(
      `
    Referencias de Puntuación: El punto de corte que muestra una mayor eficacia es el 6. Por lo tanto, se considera el test como positivo si el valor de la suma de las tres puntuaciones (esfera, números, agujas) a la "orden" es menor o igual a 6, y como negativo si la suma de las puntuaciones es mayor a seis. Las puntuaciones altas sirven para descartar la enfermedad, especialmente las puntuaciones próximas al 8 y al 9`,
      30,
      row,
      {
        maxWidth: 140,
        align: "justify",
      }
    );

    row = row + 10;

    doc.setFontSize(10);

    doc.text(
      `
    Resultado : ${String(
      Number(reloj.esfera ? reloj.esfera : 0) +
        Number(
          reloj.numero && reloj.numero / 10 === 3.5
            ? reloj.numero / 10
            : reloj.numero && reloj.numero / 10 !== 3.5
            ? Math.trunc(reloj.numero / 10)
            : 0
        ) +
        Number(
          reloj.aguja && reloj.aguja / 10 === 3.5
            ? reloj.aguja / 10
            : reloj.aguja && reloj.aguja / 10 !== 3.5
            ? Math.trunc(reloj.aguja / 10)
            : 0
        )
    )}`,
      140,
      row,
      "left",
      "middle"
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("15", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 20;
    titulo = "EVALUACION NUTRICIONAL";
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text(titulo, 80, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(80, row + 2, 80 + textWidth + 1, row + 2);
    row = 25;
    doc.setFontSize(10);

    row = row + 2;
    var columns = [
      "EDAD",
      "SEXO",
      "PESO",
      "DEPENDENCIA",
      "POSTRADO",
      "PROBLEMAS",
      "RIESGO P/ALIMENTAR",
      "ORALES",
      "NUTRICIONAL",
    ];
    var data = [
      [
        evaluacionNutricional.edad === undefined ||
        evaluacionNutricional.edad === null
          ? ""
          : evaluacionNutricional.edad,
        evaluacionNutricional.sexo === undefined ||
        evaluacionNutricional.sexo === null
          ? ""
          : evaluacionNutricional.sexo,
        evaluacionNutricional.peso === undefined ||
        evaluacionNutricional.peso === null
          ? ""
          : evaluacionNutricional.peso,
        evaluacionNutricional.dependencia === undefined ||
        evaluacionNutricional.dependencia === null
          ? ""
          : evaluacionNutricional.dependencia
          ? "SI"
          : "NO",
        evaluacionNutricional.postrado === undefined ||
        evaluacionNutricional.postrado === null
          ? ""
          : evaluacionNutricional.postrado
          ? "SI"
          : "NO",
        evaluacionNutricional.problemas === undefined ||
        evaluacionNutricional.problemas === null
          ? ""
          : evaluacionNutricional.problemas
          ? "SI"
          : "NO",
        evaluacionNutricional.riesgoAlimentar === undefined ||
        evaluacionNutricional.riesgoAlimentar === null
          ? ""
          : evaluacionNutricional.riesgoAlimentar
          ? "SI"
          : "NO",
        evaluacionNutricional.orales === undefined ||
        evaluacionNutricional.orales === null
          ? ""
          : evaluacionNutricional.orales
          ? "SI"
          : "NO",
        evaluacionNutricional.nutricional === undefined ||
        evaluacionNutricional.nutricional === null
          ? ""
          : evaluacionNutricional.nutricional
          ? "SI"
          : "NO",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
        cellPadding: 1,
      },
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;

    doc.text("DIAGNOSTICO", 10, row, "left", "middle");

    row = row + 2;
    var columns = ["", "", "", "", "", "", "", "", "", "", "", "", ""];
    var data = [
      [
        "Cambio de peso 6 meses:",
        "Ninguno",
        evaluacionNutricional.ningunoPeso6Meses === undefined
          ? ""
          : evaluacionNutricional.ningunoPeso6Meses
          ? "*"
          : "",
        "Perdida de peso",
        evaluacionNutricional.perdidaPeso6Meses === undefined
          ? ""
          : evaluacionNutricional.perdidaPeso6Meses
          ? "*"
          : "",
        "Aumento de peso",
        evaluacionNutricional.aumentoPeso6Meses === undefined
          ? ""
          : evaluacionNutricional.aumentoPeso6Meses
          ? "*"
          : "",
      ],
      [
        "Cambio de peso 2 semanas:",
        "Ninguno",
        evaluacionNutricional.ningunoPeso2Semanas === undefined
          ? ""
          : evaluacionNutricional.ningunoPeso2Semanas
          ? "*"
          : "",
        "Perdida de peso",
        evaluacionNutricional.perdidaPeso2Semanas === undefined
          ? ""
          : evaluacionNutricional.perdidaPeso2Semanas
          ? "*"
          : "",
        "Aumento de peso",
        evaluacionNutricional.aumentoPeso2Semanas === undefined
          ? ""
          : evaluacionNutricional.aumentoPeso2Semanas
          ? "*"
          : "",
      ],
      [
        "Síntomas gastrointestinales:",
        "Ninguno",
        evaluacionNutricional.ninguno === undefined
          ? ""
          : evaluacionNutricional.ninguno
          ? "*"
          : "",
        "Nauseas",
        evaluacionNutricional.nauseas === undefined
          ? ""
          : evaluacionNutricional.nauseas
          ? "*"
          : "",
        "Vómitos",
        evaluacionNutricional.vomitos === undefined
          ? ""
          : evaluacionNutricional.vomitos
          ? "*"
          : "",
        "Diarrea",
        evaluacionNutricional.diarrea === undefined
          ? ""
          : evaluacionNutricional.diarrea
          ? "*"
          : "",
        "Anorexia",
        evaluacionNutricional.anorexia === undefined
          ? ""
          : evaluacionNutricional.anorexia
          ? "*"
          : "",
        "Dolor",
        evaluacionNutricional.dolor === undefined
          ? ""
          : evaluacionNutricional.dolor
          ? "*"
          : "",
      ],
      [
        "Cambios en la ingesta:",
        "Ninguno",
        evaluacionNutricional.sinCambios === undefined
          ? ""
          : evaluacionNutricional.sinCambios
          ? "*"
          : "",
        "Ayuno",
        evaluacionNutricional.ayuno === undefined
          ? ""
          : evaluacionNutricional.ayuno
          ? "*"
          : "",
        "Hipocalóricos",
        evaluacionNutricional.hipocaloricos === undefined
          ? ""
          : evaluacionNutricional.hipocaloricos
          ? "*"
          : "",
        "Dieta liquida",
        evaluacionNutricional.dietaLiquida === undefined
          ? ""
          : evaluacionNutricional.dietaLiquida
          ? "*"
          : "",
        "Dieta Insuficiente",
        evaluacionNutricional.dietaInsuficiente === undefined
          ? ""
          : evaluacionNutricional.dietaInsuficiente
          ? "*"
          : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;

    columns = [
      "MES",
      "ACTITUD FRENTE A LA COMIDA",
      "DEPENDE P/ALIMENTAR",
      "COMPLEMENTO ALIMENTARIO",
      "PESO",
      "TALLA",
      "IMC",
      "RIESGO NUTRICIONAL",
    ];
    data = [
      [
        "ENERO",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[0].actitud !== null
          ? tipoDieta[0].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[0].depende !== null
          ? tipoDieta[0].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[0].complemento !== null
          ? tipoDieta[0].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[0].pesoMes !== null
          ? tipoDieta[0].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[0].talla !== null
          ? tipoDieta[0].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[0].imc !== null
          ? tipoDieta[0].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[0].riesgoNutricional !== null
          ? tipoDieta[0].riesgoNutricional
          : "",
      ],
      [
        "FEBRERO",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[1].actitud !== null
          ? tipoDieta[1].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[1].depende !== null
          ? tipoDieta[1].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[1].complemento !== null
          ? tipoDieta[1].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[1].pesoMes !== null
          ? tipoDieta[1].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[1].talla !== null
          ? tipoDieta[1].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[1].imc !== null
          ? tipoDieta[1].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[1].riesgoNutricional !== null
          ? tipoDieta[1].riesgoNutricional
          : "",
      ],
      [
        "MARZO",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[2].actitud !== null
          ? tipoDieta[2].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[2].depende !== null
          ? tipoDieta[2].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[2].complemento !== null
          ? tipoDieta[2].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[2].pesoMes !== null
          ? tipoDieta[2].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[2].talla !== null
          ? tipoDieta[2].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[2].imc !== null
          ? tipoDieta[2].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[2].riesgoNutricional !== null
          ? tipoDieta[2].riesgoNutricional
          : "",
      ],
      [
        "ABRIL",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[3].actitud !== null
          ? tipoDieta[3].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[3].depende !== null
          ? tipoDieta[3].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[3].complemento !== null
          ? tipoDieta[3].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[3].pesoMes !== null
          ? tipoDieta[3].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[3].talla !== null
          ? tipoDieta[3].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[3].imc !== null
          ? tipoDieta[3].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[3].riesgoNutricional !== null
          ? tipoDieta[3].riesgoNutricional
          : "",
      ],
      [
        "MAYO",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[4].actitud !== null
          ? tipoDieta[4].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[4].depende !== null
          ? tipoDieta[4].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[4].complemento !== null
          ? tipoDieta[4].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[4].pesoMes !== null
          ? tipoDieta[4].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[4].talla !== null
          ? tipoDieta[4].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[4].imc !== null
          ? tipoDieta[4].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[4].riesgoNutricional !== null
          ? tipoDieta[4].riesgoNutricional
          : "",
      ],
      [
        "JUNIO",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[5].actitud !== null
          ? tipoDieta[5].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[5].depende !== null
          ? tipoDieta[5].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[5].complemento !== null
          ? tipoDieta[5].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[5].pesoMes !== null
          ? tipoDieta[5].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[5].talla !== null
          ? tipoDieta[5].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[5].imc !== null
          ? tipoDieta[5].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[5].riesgoNutricional !== null
          ? tipoDieta[5].riesgoNutricional
          : "",
      ],
      [
        "JULIO",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[6].actitud !== null
          ? tipoDieta[6].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[6].depende !== null
          ? tipoDieta[6].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[6].complemento !== null
          ? tipoDieta[6].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[6].pesoMes !== null
          ? tipoDieta[6].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[6].talla !== null
          ? tipoDieta[6].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[6].imc !== null
          ? tipoDieta[6].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[6].riesgoNutricional !== null
          ? tipoDieta[6].riesgoNutricional
          : "",
      ],
      [
        "AGOSTO",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[7].actitud !== null
          ? tipoDieta[7].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[7].depende !== null
          ? tipoDieta[7].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[7].complemento !== null
          ? tipoDieta[7].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[7].pesoMes !== null
          ? tipoDieta[7].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[7].talla !== null
          ? tipoDieta[7].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[7].imc !== null
          ? tipoDieta[7].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[7].riesgoNutricional !== null
          ? tipoDieta[7].riesgoNutricional
          : "",
      ],
      [
        "SEPTIEMBRE",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[8].actitud !== null
          ? tipoDieta[8].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[8].depende !== null
          ? tipoDieta[8].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[8].complemento !== null
          ? tipoDieta[8].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[8].pesoMes !== null
          ? tipoDieta[8].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[8].talla !== null
          ? tipoDieta[8].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[8].imc !== null
          ? tipoDieta[8].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[8].riesgoNutricional !== null
          ? tipoDieta[8].riesgoNutricional
          : "",
      ],
      [
        "OCTUBRE",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[9].actitud !== null
          ? tipoDieta[9].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[9].depende !== null
          ? tipoDieta[9].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[9].complemento !== null
          ? tipoDieta[9].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[9].pesoMes !== null
          ? tipoDieta[9].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[9].talla !== null
          ? tipoDieta[9].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[9].imc !== null
          ? tipoDieta[9].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[9].riesgoNutricional !== null
          ? tipoDieta[9].riesgoNutricional
          : "",
      ],
      [
        "NOVIEMBRE",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[10].actitud !== null
          ? tipoDieta[10].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[10].depende !== null
          ? tipoDieta[10].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[10].complemento !== null
          ? tipoDieta[10].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[10].pesoMes !== null
          ? tipoDieta[10].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[10].talla !== null
          ? tipoDieta[10].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[10].imc !== null
          ? tipoDieta[10].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[10].riesgoNutricional !== null
          ? tipoDieta[10].riesgoNutricional
          : "",
      ],
      [
        "DICIEMBRE",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[11].actitud !== null
          ? tipoDieta[11].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[11].depende !== null
          ? tipoDieta[11].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[11].complemento !== null
          ? tipoDieta[11].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[11].pesoMes !== null
          ? tipoDieta[11].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[11].talla !== null
          ? tipoDieta[11].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[11].imc !== null
          ? tipoDieta[11].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[11].riesgoNutricional !== null
          ? tipoDieta[11].riesgoNutricional
          : "",
      ],
    ];

    doc.autoTable(columns, data, {
      startY: row,
      margin: { left: 10 },
      styles: {
        fontSize: 7,
      },
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 10;

    textDiag = "";
    for (var i = 0; i < diagnosticosPacientes.length; i++) {
      textDiag = textDiag + "•" + diagnosticosPacientes[i].label + "  ";
    }

    doc.text("Diagnóstico primario: " + textDiag, 10, row, {
      maxWidth: 180,
      align: "justify",
    });

    doc.setFont(undefined, "normal");

    w = doc.getTextDimensions(textDiag);
    if (w.w > 180) {
      row = row + 12;
    } else {
      row = row + 5;
    }

    doc.setFontSize(10);

    row = row + 5;

    doc.text("Requerimientos metabólicos (estrés)", 10, row, "left", "middle");

    row = row + 2;
    var columns = ["", "", "", "", "", "", "", ""];
    var data = [
      [
        "Sin estrés:",
        evaluacionNutricional.sinEstres !== undefined &&
        evaluacionNutricional.sinEstres !== null
          ? "*"
          : "",
        "Estrés bajo",
        evaluacionNutricional.estresBajo !== undefined &&
        evaluacionNutricional.estresBajo !== null
          ? "*"
          : "",
        "Estrés moderado",
        evaluacionNutricional.estresModerado !== undefined &&
        evaluacionNutricional.estresModerado !== null
          ? "*"
          : "",
        "Estrés alto",
        evaluacionNutricional.estresAlto !== undefined &&
        evaluacionNutricional.estresAlto !== null
          ? "*"
          : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;

    doc.setFont(undefined, "bold");

    doc.text("EXAMEN FISICO", 10, row, "left", "middle");

    doc.setFont(undefined, "normal");

    row = row + 2;
    var columns = [
      "",
      "Normal",
      "Déficit ligero",
      "Déficit moderado",
      "Déficit severo",
    ];
    var data = [
      [
        "Grasa subcutánea:",
        evaluacionNutricional.perdidaGrasaNormal !== undefined &&
        evaluacionNutricional.perdidaGrasaNormal !== null
          ? evaluacionNutricional.perdidaGrasaNormal
            ? "*"
            : ""
          : "",
        evaluacionNutricional.perdidaGrasaDeficitLigero !== undefined &&
        evaluacionNutricional.perdidaGrasaDeficitLigero !== null
          ? evaluacionNutricional.perdidaGrasaDeficitLigero
            ? "*"
            : ""
          : "",
        evaluacionNutricional.perdidaGrasaDeficitModerado !== undefined &&
        evaluacionNutricional.perdidaGrasaDeficitModerado !== null
          ? evaluacionNutricional.perdidaGrasaDeficitModerado
            ? "*"
            : ""
          : "",
        evaluacionNutricional.perdidaGrasaDeficitSevero !== undefined &&
        evaluacionNutricional.perdidaGrasaDeficitSevero !== null
          ? evaluacionNutricional.perdidaGrasaDeficitSevero
            ? "*"
            : ""
          : "",
      ],
      [
        "Masa muscular:",
        evaluacionNutricional.perdidaMasaNormal !== undefined &&
        evaluacionNutricional.perdidaMasaNormal !== null
          ? evaluacionNutricional.perdidaMasaNormal
            ? "*"
            : ""
          : "",
        evaluacionNutricional.perdidaMasaDeficitLigero !== undefined &&
        evaluacionNutricional.perdidaMasaDeficitLigero !== null
          ? evaluacionNutricional.perdidaMasaDeficitLigero
            ? "*"
            : ""
          : "",
        evaluacionNutricional.perdidaMasaDeficitModerado !== undefined &&
        evaluacionNutricional.perdidaMasaDeficitModerado !== null
          ? evaluacionNutricional.perdidaMasaDeficitModerado
            ? "*"
            : ""
          : "",
        evaluacionNutricional.perdidaMasaDeficitSevero !== undefined &&
        evaluacionNutricional.perdidaMasaDeficitSevero !== null
          ? evaluacionNutricional.perdidaMasaDeficitSevero
            ? "*"
            : ""
          : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
      },
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;

    var columns = ["", "", "", "", "", "", "", "", "", "", "", "", ""];
    var data = [
      [
        "Edema maleolar:",
        evaluacionNutricional.edemaMaleolar === undefined ||
        evaluacionNutricional.edemaMaleolar === null
          ? ""
          : evaluacionNutricional.edemaMaleolar
          ? "*"
          : "",
        "Grado",
        evaluacionNutricional.gradoEdemaMaleolar === undefined ||
        evaluacionNutricional.gradoEdemaMaleolar === null
          ? ""
          : evaluacionNutricional.gradoEdemaMaleolar,
        "Ulceras PP",
        evaluacionNutricional.ulcerasPP === undefined ||
        evaluacionNutricional.ulcerasPP === null
          ? ""
          : evaluacionNutricional.ulcerasPP
          ? "*"
          : "",
        "Grado",
        evaluacionNutricional.gradoUlcerasPP === undefined ||
        evaluacionNutricional.gradoUlcerasPP === null
          ? ""
          : evaluacionNutricional.gradoUlcerasPP,
      ],
      [
        "Edema sacro:",
        evaluacionNutricional.edemaSacro === undefined ||
        evaluacionNutricional.edemaSacro === null
          ? ""
          : evaluacionNutricional.edemaSacro
          ? "*"
          : "",
        "Grado",
        evaluacionNutricional.gradoEdemaSacro === undefined ||
        evaluacionNutricional.gradoEdemaSacro === null
          ? ""
          : evaluacionNutricional.gradoEdemaSacro,
        "Fiebre",
        evaluacionNutricional.fiebre === undefined ||
        evaluacionNutricional.fiebre === null
          ? ""
          : evaluacionNutricional.fiebre
          ? "*"
          : "",
      ],
      [
        "Ascitis:",
        evaluacionNutricional.ascitis === undefined ||
        evaluacionNutricional.ascitis === null
          ? ""
          : evaluacionNutricional.ascitis
          ? "*"
          : "",
        "Grado",
        evaluacionNutricional.gradoAscitis === undefined ||
        evaluacionNutricional.gradoAscitis === null
          ? ""
          : evaluacionNutricional.gradoAscitis,
        "Fiebre",
        evaluacionNutricional.sintomas === undefined ||
        evaluacionNutricional.sintomas === null
          ? ""
          : evaluacionNutricional.sintomas
          ? "*"
          : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;

    doc.setFont(undefined, "bold");

    doc.text("DIAGNOSTICO NUTRICIONAL", 10, row, "left", "middle");
    doc.setFont(undefined, "normal");

    row = row + 3;
    doc.setFontSize(7);
    doc.text(
      `No se establece un método numérico para la evaluación de este test. Se valora en A, Bo C seún la predominancia de síntomas, con especial atención a las siguientes variables: pérdida de peso, cambios en la injesta habitual, pérdida de tejido subcutáneo y pérdida de masa muscular.`,
      10,
      row,
      {
        maxWidth: 180,
        align: "justify",
      }
    );

    doc.setFontSize(9);
    row = row + 10;
    doc.text("A= Bien nutrído", 10, row, "left", "middle");
    doc.text(
      evaluacionNutricional.diagnosticoNutricional !== undefined &&
        evaluacionNutricional.diagnosticoNutricional !== null
        ? evaluacionNutricional.diagnosticoNutricional === "A"
          ? "*"
          : ""
        : "",
      50,
      row,
      "left",
      "middle"
    );

    row = row + 5;
    doc.text(
      "B= Sospecha de malnutrición o malnutrición moderada",
      10,
      row,
      "left",
      "middle"
    );
    doc.text(
      evaluacionNutricional.diagnosticoNutricional !== undefined &&
        evaluacionNutricional.diagnosticoNutricional !== null
        ? evaluacionNutricional.diagnosticoNutricional === "B"
          ? "*"
          : ""
        : "",
      100,
      row,
      "left",
      "middle"
    );

    row = row + 3;
    doc.setFontSize(7);
    doc.text(
      `5-10% de pérdida de peso en las últimas semanas antes del ingreso. Disminución de la injesta en las últimas semanas. Pérdida de tejido subcutáneo.`,
      10,
      row,
      {
        maxWidth: 180,
        align: "justify",
      }
    );

    doc.setFontSize(9);
    row = row + 5;
    doc.text("C= Malnutrición severa", 10, row, "left", "middle");
    doc.text(
      evaluacionNutricional.diagnosticoNutricional !== undefined &&
        evaluacionNutricional.diagnosticoNutricional !== null
        ? evaluacionNutricional.diagnosticoNutricional === "C"
          ? "*"
          : ""
        : "",
      50,
      row,
      "left",
      "middle"
    );

    row = row + 3;
    doc.setFontSize(7);
    doc.text(
      `Pérdida > 10% del peso. Severa pérdida de masa muscular y tejido subcutáneo. Edema.`,
      10,
      row,
      {
        maxWidth: 180,
        align: "justify",
      }
    );

    row = 290;

    doc.text("FIRMA Y SELLO", 90, row, "left", "middle");

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("16", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 20;
    titulo = "EVOLUCION NUTRICIONAL";
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text(titulo, 15, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(15, row + 2, 15 + textWidth + 1, row + 2);
    row = 30;

    doc.setFont(undefined, "normal");

    doc.text(`INTERCURRENCIAS:`, 15, row, "left", "middle");

    doc.addPage();
    row = 10;
    doc.setFontSize(10);
    doc.text("17", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 20;
    titulo = "HC SOCIAL";
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text(titulo, 35, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(35, row + 2, 35 + textWidth + 1, row + 2);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.setFontSize(10);
    row = row + 10;
    titulo = "Datos Personales";
    doc.text(titulo, 30, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(30, row + 2, 30 + textWidth + 1, row + 2);
    doc.setFontSize(9);
    row = row + 10;

    doc.text(
      "Domicilio: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.domicilio !== undefined &&
        paciente.domicilio !== null
          ? paciente.domicilio
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Localidad: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.localidadId !== undefined &&
        paciente.localidadId !== null
          ? localidades.find((item) => item.id === paciente.localidadId).label
          : ""),
      120,
      row,
      "left",
      "middle"
    );

    doc.text(
      "C.P.: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.cp !== undefined &&
        paciente.cp !== null
          ? paciente.cp
          : ""),
      170,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Documento: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.numeroDocumento !== undefined &&
        paciente.numeroDocumento !== null
          ? paciente.numeroDocumento
          : ""),

      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Fecha: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.fechaNacimiento !== undefined &&
        paciente.fechaNacimiento !== null
          ? formatDate(paciente.fechaNacimiento)
          : ""),
      70,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Lugar de Nac.: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.lugarNacimiento !== undefined &&
        paciente.lugarNacimiento !== null
          ? paciente.lugarNacimiento
          : ""),

      105,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Estado Civil: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.estadoCivil !== undefined &&
        paciente.estadoCivil !== null
          ? paciente.estadoCivil
          : ""),

      150,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "O. Social Médica: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.obraSocialId !== undefined &&
        paciente.obraSocialId !== null
          ? obrasSocialesMedicas.find(
              (item) => item.id === paciente.obraSocialId
            ).label
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Nº. O. Social: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.numeroObraSocial !== undefined &&
        paciente.numeroObraSocial !== null
          ? paciente.numeroObraSocial
          : ""),

      80,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Clínica Internación: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.clinicaId !== undefined &&
        paciente.clinicaId !== null
          ? clinicas.find((item) => item.id === paciente.clinicaId).label
          : ""),

      120,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    textDiag = "";
    for (var i = 0; i < diagnosticosPacientes.length; i++) {
      textDiag = textDiag + "•" + diagnosticosPacientes[i].label + "  ";
    }

    doc.text("Diagnóstico de Base: " + textDiag, 30, row, {
      maxWidth: 160,
      align: "justify",
    });

    w = doc.getTextDimensions(textDiag);
    if (w.w > 180) {
      row = row + 12;
    } else {
      row = row + 5;
    }

    doc.text(
      "Fecha Ingreso: " + formatDate(ingresosEgresos[0].fecha),
      30,
      row,
      "left",
      "middle"
    );

    if (paciente && paciente.motivoIngresoEgreso !== "PRIMER INGRESO") {
      doc.text(
        "Fecha de Egreso/Reingreso: " + formatDate(paciente.fechaIngresoEgreso),
        100,
        row,
        "left",
        "middle"
      );
    }

    row = row + 5;

    if (paciente.activo) {
      doc.text(
        "Motivo de Ingreso: " +
          (paciente !== undefined &&
          paciente !== null &&
          paciente.motivoIngresoEgreso !== undefined &&
          paciente.motivoIngresoEgreso !== null
            ? paciente.motivoIngresoEgreso
            : ""),
        30,
        row,
        "left",
        "middle"
      );
    } else {
      doc.text(
        "Motivo de Egreso: " +
          (paciente !== undefined &&
          paciente !== null &&
          paciente.motivoIngresoEgreso !== undefined &&
          paciente.motivoIngresoEgreso !== null
            ? paciente.motivoIngresoEgreso
            : ""),
        30,
        row,
        "left",
        "middle"
      );
    }

    doc.text(
      "Activo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.activo !== undefined &&
        paciente.activo !== null
          ? paciente.activo
            ? "Si"
            : "No"
          : ""),
      120,
      row,
      "left",
      "middle"
    );

    doc.setFontSize(10);
    row = row + 10;
    titulo = "Datos del Responsable";
    doc.text(titulo, 30, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(30, row + 2, 30 + textWidth + 1, row + 2);
    doc.setFontSize(9);
    row = row + 10;

    doc.text(
      "Apellido y Nombre: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.nombreRepresentante !== undefined &&
        paciente.nombreRepresentante !== null
          ? paciente.nombreRepresentante
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Domicilio: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.domicilioRepresentante !== undefined &&
        paciente.domicilioRepresentante !== null
          ? paciente.domicilioRepresentante
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Localidad: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.localidadRepresentanteId !== undefined &&
        paciente.localidadRepresentanteId !== null
          ? localidades.find(
              (item) => item.id === paciente.localidadRepresentanteId
            ).label
          : ""),
      130,
      row,
      "left",
      "middle"
    );

    doc.text(
      "C.P.: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.cpRepresentante !== undefined &&
        paciente.cpRepresentante !== null
          ? paciente.cpRepresentante
          : ""),
      180,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Documento: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.numeroDocumentoRepresentante !== undefined &&
        paciente.numeroDocumentoRepresentante !== null
          ? paciente.numeroDocumentoRepresentante
          : ""),

      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoRepresentante !== undefined &&
        paciente.telefonoRepresentante !== null
          ? paciente.telefonoRepresentante
          : ""),

      80,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono Comunicaciones: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoComunicaciones !== undefined &&
        paciente.telefonoComunicaciones !== null
          ? paciente.telefonoComunicaciones
          : ""),

      130,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Vínculo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.vinculoId !== undefined &&
        paciente.vinculoId !== null
          ? vinculos.find((item) => item.id === paciente.vinculoId).label
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Email: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.email !== undefined &&
        paciente.email !== null
          ? paciente.email
          : ""),

      100,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    /*

    doc.setFontSize(10);
    row = row + 10;
    titulo = "Nivel Socio - Económico";
    doc.text(titulo, 30, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(30, row + 2, 30 + textWidth + 1, row + 2);
    doc.setFontSize(9);
    row = row + 10;

    doc.text(
      "Escolaridad: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.escolaridad !== undefined &&
        paciente.escolaridad !== null
          ? paciente.escolaridad
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Completo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.completo !== undefined &&
        paciente.completo !== null
          ? paciente.completo
          : ""),
      70,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Situación Laboral: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.situacionLaboral !== undefined &&
        paciente.situacionLaboral !== null
          ? paciente.situacionLaboral
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Desempleño Laboral: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.desempenoLaboral !== undefined &&
        paciente.desempenoLaboral !== null
          ? paciente.desempenoLaboral
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Profesión, oficio o actividad: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.profesion !== undefined &&
        paciente.profesion !== null
          ? paciente.profesion
          : ""),
      80,
      row,
      "left",
      "middle"
    );

    doc.setFontSize(10);
    row = row + 10;
    titulo = "Dimensión Relacional y Convivencial";
    doc.text(titulo, 30, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(30, row + 2, 30 + textWidth + 1, row + 2);
    doc.setFontSize(9);
    row = row + 10;

    doc.text(
      "Red de contención social: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.contencion !== undefined &&
        paciente.contencion !== null
          ? paciente.contencion
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Cual: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.contencionCual !== undefined &&
        paciente.contencionCual !== null
          ? paciente.contencionCual
          : ""),
      90,
      row,
      "left",
      "middle"
    );

    */


    doc.setFontSize(10);
    row = row + 10;


    doc.addImage(mapeo, "png", 30, row, 130, 100);





    /*
    titulo = "Datos de Grupo Familiar";
    doc.text(titulo, 30, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(30, row + 2, 30 + textWidth + 1, row + 2);
    doc.setFontSize(9);
    row = row + 10;

    doc.text(
      "1- Apellido y Nombre: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.nombreGrupo1 !== undefined &&
        paciente.nombreGrupo1 !== null
          ? paciente.nombreGrupo1
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Domicilio: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.domicilioGrupo1 !== undefined &&
        paciente.domicilioGrupo1 !== null
          ? paciente.domicilioGrupo1
          : ""),
      120,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "   Vínculo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.vinculoIdGrupo1 !== undefined &&
        paciente.vinculoIdGrupo1 !== null
          ? vinculos.find((item) => item.id === paciente.vinculoIdGrupo1).label
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Ocupación: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.ocupacionGrupo1 !== undefined &&
        paciente.ocupacionGrupo1 !== null
          ? paciente.ocupacionGrupo1
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoGrupo1 !== undefined &&
        paciente.telefonoGrupo1 !== null
          ? paciente.telefonoGrupo1
          : ""),
      160,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "2- Apellido y Nombre: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.nombreGrupo2 !== undefined &&
        paciente.nombreGrupo2 !== null
          ? paciente.nombreGrupo2
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Domicilio: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.domicilioGrupo2 !== undefined &&
        paciente.domicilioGrupo2 !== null
          ? paciente.domicilioGrupo2
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "   Vínculo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.vinculoIdGrupo2 !== undefined &&
        paciente.vinculoIdGrupo2 !== null
          ? vinculos.find((item) => item.id === paciente.vinculoIdGrupo2).label
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Ocupación: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.ocupacionGrupo2 !== undefined &&
        paciente.ocupacionGrupo2 !== null
          ? paciente.ocupacionGrupo2
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoGrupo2 !== undefined &&
        paciente.telefonoGrupo2 !== null
          ? paciente.telefonoGrupo2
          : ""),
      160,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "3- Apellido y Nombre: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.nombreGrupo3 !== undefined &&
        paciente.nombreGrupo3 !== null
          ? paciente.nombreGrupo3
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Domicilio: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.domicilioGrupo3 !== undefined &&
        paciente.domicilioGrupo3 !== null
          ? paciente.domicilioGrupo3
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "   Vínculo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.vinculoIdGrupo3 !== undefined &&
        paciente.vinculoIdGrupo3 !== null
          ? vinculos.find((item) => item.id === paciente.vinculoIdGrupo3).label
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Ocupación: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.ocupacionGrupo3 !== undefined &&
        paciente.ocupacionGrupo3 !== null
          ? paciente.ocupacionGrupo3
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoGrupo3 !== undefined &&
        paciente.telefonoGrupo3 !== null
          ? paciente.telefonoGrupo3
          : ""),
      160,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "4- Apellido y Nombre: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.nombreGrupo4 !== undefined &&
        paciente.nombreGrupo4 !== null
          ? paciente.nombreGrupo4
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Domicilio: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.domicilioGrupo4 !== undefined &&
        paciente.domicilioGrupo4 !== null
          ? paciente.domicilioGrupo4
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "   Vínculo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.vinculoIdGrupo4 !== undefined &&
        paciente.vinculoIdGrupo4 !== null
          ? vinculos.find((item) => item.id === paciente.vinculoIdGrupo4).label
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Ocupación: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.ocupacionGrupo4 !== undefined &&
        paciente.ocupacionGrupo4 !== null
          ? paciente.ocupacionGrupo4
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoGrupo4 !== undefined &&
        paciente.telefonoGrupo4 !== null
          ? paciente.telefonoGrupo4
          : ""),
      160,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "5- Apellido y Nombre: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.nombreGrupo5 !== undefined &&
        paciente.nombreGrupo5 !== null
          ? paciente.nombreGrupo5
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Domicilio: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.domicilioGrupo5 !== undefined &&
        paciente.domicilioGrupo5 !== null
          ? paciente.domicilioGrupo5
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "   Vínculo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.vinculoIdGrupo5 !== undefined &&
        paciente.vinculoIdGrupo5 !== null
          ? vinculos.find((item) => item.id === paciente.vinculoIdGrupo5).label
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Ocupación: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.ocupacionGrupo5 !== undefined &&
        paciente.ocupacionGrupo5 !== null
          ? paciente.ocupacionGrupo5
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoGrupo5 !== undefined &&
        paciente.telefonoGrupo5 !== null
          ? paciente.telefonoGrupo5
          : ""),
      160,
      row,
      "left",
      "middle"
    );

    doc.setFontSize(10);
    row = row + 10;
    titulo = "Observaciones";
    doc.text(titulo, 30, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(30, row + 2, 30 + textWidth + 1, row + 2);
    doc.setFontSize(9);
    row = row + 10;

    doc.text(
      paciente !== undefined &&
        paciente !== null &&
        paciente.observaciones !== undefined &&
        paciente.observaciones !== null
        ? paciente.observaciones
        : "",
      30,
      row,
      {
        maxWidth: 160,
        align: "left",
      }
    );

    doc.setFontSize(10);
    row = row + 10;
    titulo = "Datos de Contacto";
    doc.text(titulo, 30, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(30, row + 2, 30 + textWidth + 1, row + 2);
    doc.setFontSize(9);
    row = row + 10;

    doc.text(
      "Email Alternativo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.emailAlternativo !== undefined &&
        paciente.emailAlternativo !== null
          ? paciente.emailAlternativo
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "WhatsApp: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.whatsApp !== undefined &&
        paciente.whatsApp !== null
          ? paciente.whatsApp
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono Urgencias: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoUrgencias !== undefined &&
        paciente.telefonoUrgencias !== null
          ? paciente.telefonoUrgencias
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "O. Social Contrato: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.obraSocialContratoId !== undefined &&
        paciente.obraSocialContratoId !== null
          ? obrasSociales.find(
              (item) => item.id === paciente.obraSocialContratoId
            ).label
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Arancel Mensual: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.arancel !== undefined &&
        paciente.arancel !== null
          ? paciente.arancel
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Coseguro: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.coseguroId !== undefined &&
        paciente.coseguroId !== null
          ? coseguros.find((item) => item.id === paciente.coseguroId).label +
            "(" +
            coseguros.find((item) => item.id === paciente.coseguroId).valor +
            ")"
          : ""),
      150,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Pañales: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.panalId !== undefined &&
        paciente.panalId !== null
          ? panales.find((item) => item.id === paciente.panalId).label +
            "(" +
            panales.find((item) => item.id === paciente.panalId).valor +
            ")"
          : ""),
      30,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Descartables: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.descartableId !== undefined &&
        paciente.descartableId !== null
          ? descartables.find((item) => item.id === paciente.descartableId)
              .label +
            "(" +
            descartables.find((item) => item.id === paciente.descartableId)
              .valor +
            ")"
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Fecha Pago: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.fechaPago !== undefined &&
        paciente.fechaPago !== null
          ? paciente.fechaPago === 1
            ? "01 a 15"
            : "16a 31"
          : ""),
      170,
      row,
      "left",
      "middle"
    );

    */

    row = 260;
    doc.text("LIC. ASISTENTE SOCIAL", 82, row, "left", "middle");

    row = 280;
    doc.text("FIRMA Y SELLO", 90, row, "left", "middle");

    doc.addPage();



    row = 10;
    doc.setFontSize(10);
    doc.text("18", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 20;
    titulo = "CUESTIONARIO DE EXPECTATIVAS DE LA PERSONA MAYOR EN RESIDENCIA";
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text(titulo, 30, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(30, row + 2, 30 + textWidth + 1, row + 2);
    row = 30;

    doc.setFont(undefined, "normal");
    doc.setFontSize(10);


    doc.text(
      `1- ¿ Cuál diría que fue el motivo por el cuál ingresó a la residencia ?`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 30

    doc.text(
      `2- ¿ Qué espera de su estadía en la residencia ?`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 30

    doc.text("3- ¿ Cuáles son los problemas relacionados a su salud en los que le interesaría recibir apoyo por parte del equipo de la residencia ?" , 30, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 30

    doc.text("4- ¿ Existe algún problema o situación que incida en su estado de ánimo y/o comportamiento en el que le gustaría recibir apoyo ?" , 30, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 30

    doc.text("5- ¿ Existe alguna situación que le provoque temor o preocupación respecto a su llegada y permanencia en la residencia ?" , 30, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 30

    doc.text(
      `6- De todas las preguntas realizadas, ¿ cuál de todas ellas es la que más le interesa ?`,
      30,
      row,
      "left",
      "middle"
    );

   
    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("19", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 20;
    titulo = "EVOLUCION SOCIAL";
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text(titulo, 15, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(15, row + 2, 15 + textWidth + 1, row + 2);
    row = 30;

    doc.setFont(undefined, "normal");

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("20", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 20;
    titulo = "MARCADORES DE FRAGILIDAD";
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text(titulo, 80, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(80, row + 2, 80 + textWidth + 1, row + 2);
    row = 30;

    doc.setFont(undefined, "normal");

    doc.setFont(undefined, "normal");
    doc.text(
      `LUGAR DE DERIVACION casa/ clínica / geriátrico/otro: ${fragilidad.lugar}`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    var columns = ["", "", "", "", "", ""];
    var data = [
      [
        "Mayor de 80 años.",
        fragilidad.pregunta1 ? "*" : "",
        "Hospitalización reciente.",
        fragilidad.pregunta2 ? "*" : "",
        "Estado de abandono.",
        fragilidad.pregunta3 ? "*" : "",
      ],
      [
        "Incontinencia.",
        fragilidad.pregunta4 ? "*" : "",
        "Inmovilización.",
        fragilidad.pregunta5 ? "*" : "",
        "Dependencia total.",
        fragilidad.pregunta6 ? "*" : "",
      ],
      [
        "Caídas recientes.",
        fragilidad.pregunta7 ? "*" : "",
        "Cirugías recientes.",
        fragilidad.pregunta8 ? "*" : "",
        "Ulceras  decúbito leves.",
        fragilidad.pregunta9 ? "*" : "",
      ],
      [
        "Ulceras  decúbito graves.",
        fragilidad.pregunta10 ? "*" : "",
        "Demencia.",
        fragilidad.pregunta11 ? "*" : "",
        "Depresión.",
        fragilidad.pregunta12 ? "*" : "",
      ],
      [
        "Anorexia.",
        fragilidad.pregunta13 ? "*" : "",
        "Enfermedad Terminal.",
        fragilidad.pregunta14 ? "*" : "",
        "Deshidratación.",
        fragilidad.pregunta15 ? "*" : "",
      ],
      [
        "Desnutrición.",
        fragilidad.pregunta16 ? "*" : "",
        "Mal estado general.",
        fragilidad.pregunta17 ? "*" : "",
        "Intento de suicidio.",
        fragilidad.pregunta18 ? "*" : "",
      ],
      [
        "Diabetes tipo I.",
        fragilidad.pregunta19 ? "*" : "",
        "Hta .grave.",
        fragilidad.pregunta20 ? "*" : "",
        "ACV.",
        fragilidad.pregunta21 ? "*" : "",
      ],
      [
        "Epilepsia.",
        fragilidad.pregunta22 ? "*" : "",
        "EPOC.",
        fragilidad.pregunta23 ? "*" : "",
        "Estado de coma.",
        fragilidad.pregunta24 ? "*" : "",
      ],
      [
        "Medicación psiquiatrica.",
        fragilidad.pregunta25 ? "*" : "",
        "Sondas.",
        fragilidad.pregunta26 ? "*" : "",
        "Ostomias.",
        fragilidad.pregunta27 ? "*" : "",
      ],
      ["Peligroso para si o terceros.", fragilidad.pregunta28 ? "*" : ""],
    ];

    doc.autoTable(columns, data, {
      margin: { top: 35, left: 30 },
      styles: {
        fontSize: 9,
      },
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 10;
    doc.setFontSize(8);

    doc.setFontSize(10);

    doc.text(
      `Resultado : ${String(
        Number(fragilidad.pregunta1 ? fragilidad.pregunta1Value : 0) +
          Number(fragilidad.pregunta2 ? fragilidad.pregunta2Value : 0) +
          Number(fragilidad.pregunta3 ? fragilidad.pregunta3Value : 0) +
          Number(fragilidad.pregunta4 ? fragilidad.pregunta4Value : 0) +
          Number(fragilidad.pregunta5 ? fragilidad.pregunta5Value : 0) +
          Number(fragilidad.pregunta6 ? fragilidad.pregunta6Value : 0) +
          Number(fragilidad.pregunta7 ? fragilidad.pregunta7Value : 0) +
          Number(fragilidad.pregunta8 ? fragilidad.pregunta8Value : 0) +
          Number(fragilidad.pregunta9 ? fragilidad.pregunta9Value : 0) +
          Number(fragilidad.pregunta10 ? fragilidad.pregunta10Value : 0) +
          Number(fragilidad.pregunta11 ? fragilidad.pregunta11Value : 0) +
          Number(fragilidad.pregunta12 ? fragilidad.pregunta12Value : 0) +
          Number(fragilidad.pregunta13 ? fragilidad.pregunta13Value : 0) +
          Number(fragilidad.pregunta14 ? fragilidad.pregunta14Value : 0) +
          Number(fragilidad.pregunta15 ? fragilidad.pregunta15Value : 0) +
          Number(fragilidad.pregunta16 ? fragilidad.pregunta16Value : 0) +
          Number(fragilidad.pregunta17 ? fragilidad.pregunta17Value : 0) +
          Number(fragilidad.pregunta18 ? fragilidad.pregunta18Value : 0) +
          Number(fragilidad.pregunta19 ? fragilidad.pregunta19Value : 0) +
          Number(fragilidad.pregunta20 ? fragilidad.pregunta20Value : 0) +
          Number(fragilidad.pregunta21 ? fragilidad.pregunta21Value : 0) +
          Number(fragilidad.pregunta22 ? fragilidad.pregunta22Value : 0) +
          Number(fragilidad.pregunta23 ? fragilidad.pregunta23Value : 0) +
          Number(fragilidad.pregunta24 ? fragilidad.pregunta24Value : 0) +
          Number(fragilidad.pregunta25 ? fragilidad.pregunta25Value : 0) +
          Number(fragilidad.pregunta26 ? fragilidad.pregunta26Value : 0) +
          Number(fragilidad.pregunta27 ? fragilidad.pregunta27Value : 0) +
          Number(fragilidad.pregunta28 ? fragilidad.pregunta28Value : 0)
      )}`,
      35,
      row,
      "left",
      "middle"
    );

    doc.setFontSize(10);

    row = row + 10;

    doc.text(
      `Cuantificar gravedad de los positivos:  ${
        fragilidad.observaciones !== undefined ? fragilidad.observaciones : ""
      }`,
      35,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("21", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${hoy.toLocaleDateString("en-GB")}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 14;
    titulo = "Escala de Norton";
    doc.setFont(undefined, "bold");
    doc.text(titulo, 80, row);
    //textWidth = doc.getTextWidth(titulo);
    //doc.line(80, row + 2, 80 + textWidth + 1, row + 2);
    row = 35;

    doc.setFont(undefined, "normal");

    row = row + 5;

    var columns = ["Clasificación", "Puntaje", ""];
    var data = [
      ["ESTADO FISICO GENERAL.", ""],
      ["Bueno", "4", norton.estadoFisico === "4" ? "*" : ""],
      ["Mediano", "3", norton.estadoFisico === "3" ? "*" : ""],
      ["Regular", "2", norton.estadoFisico === "2" ? "*" : ""],
      ["Muy malo", "1", norton.estadoFisico === "1" ? "*" : ""],
      ["", "", "", ""],
      ["ESTADO MENTAL.", ""],
      ["Alerta", "4", norton.estadoMental === "4" ? "*" : ""],
      ["Apático", "3", norton.estadoMental === "3" ? "*" : ""],
      ["Confuso", "2", norton.estadoMental === "2" ? "*" : ""],
      ["Estuporoso o comatoso", "1", norton.estadoMental === "1" ? "*" : ""],
      ["", "", "", ""],
      ["ACTIVIDAD.", ""],
      ["Ambulante", "4", norton.actividad === "4" ? "*" : ""],
      ["Disminuida", "3", norton.actividad === "3" ? "*" : ""],
      ["Muy limitada", "2", norton.actividad === "2" ? "*" : ""],
      ["Inmóvil", "1", norton.actividad === "1" ? "*" : ""],
      ["", "", "", ""],
      ["MOVILIDAD.", ""],
      ["Total", "4", norton.movilidad === "4" ? "*" : ""],
      ["Camina con ayuda", "3", norton.movilidad === "3" ? "*" : ""],
      ["Sentado", "2", norton.movilidad === "2" ? "*" : ""],
      ["Encamado", "1", norton.movilidad === "1" ? "*" : ""],
      ["", "", "", ""],
      ["INCONTINENCIA.", ""],
      ["Ninguna", "4", norton.incontinencia === "4" ? "*" : ""],
      ["Ocasional", "3", norton.incontinencia === "3" ? "*" : ""],
      ["Urinaria o fecal", "2", norton.incontinencia === "2" ? "*" : ""],
      ["Urinaria y fecal", "1", norton.incontinencia === "1" ? "*" : ""],
    ];

    doc.autoTable(columns, data, {
      margin: { top: 30, left: 30 },
      styles: {
        fontSize: 7,
      },
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 2;

    doc.setFontSize(7);

    doc.text(
      `
    Escala de Norton (Valoración del riesgo de formación de úlceras por presión)

    La clasificación de riesgo sería:
    - Puntuación de 5 a 11: Paciente de alto riesgo.
    - Puntuación de 12 a 14: Paciente de riesgo medio o evidente.
    - Puntuación de más de 14: Paciente de riesgo bajo o no riesgo.`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.setFontSize(10);

    doc.text(
      `
    Resultado : ${String(
      Number(norton.estadoFisico ? norton.estadoFisico : 0) +
        Number(norton.estadoMental ? norton.estadoMental : 0) +
        Number(norton.actividad ? norton.actividad : 0) +
        Number(norton.movilidad ? norton.movilidad : 0) +
        Number(norton.incontinencia ? norton.incontinencia : 0)
    )}`,
      150,
      row,
      "left",
      "middle"
    );

    window.open(doc.output("bloburl"));
  };

  const rejectImprimir = () => {};

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  ///

  //console.log(fragilidad);
  //console.log(tinetti);
  //console.log(tipoDieta);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {preIngresos ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  align: "center",
                  margin: "1em",
                  marginTop:
                    isMobile && !isPortrait
                      ? "22vw"
                      : isMobile && isPortrait
                      ? "55vw"
                      : "0vw",
                }}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-sync"
                  tooltip="Actualizar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "1em" }}
                  onClick={actClick}
                />
                <Button
                  icon="pi pi-print"
                  tooltip="Imprimir"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "1em" }}
                  onClick={imprimirClick}
                />
                <Button
                  id="imprimirClick"
                  icon="pi pi-print"
                  tooltip="Imprimir"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "1em", visibility: "hidden" }}
                  onClick={acceptImprimir}
                />
                {/*
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-reddit"
                  tooltip="Psicología"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClickPsicologia}
                />
                <Button
                  icon="pi pi-apple"
                  tooltip="Nutrición"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClickNutricion}
                />
                */}
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={preIngresos}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      responsiveLayout="scroll"

                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha"
                        //body={fechaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Nombre"
                        body={nombreTemplate}
                        style={{ width: "40%" }}
                        sortable
                      ></Column>
                      <Column
                        field="numeroDocumento"
                        header="Documento"
                        body={documentoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={true}
                maximizable
                modal
                //footer={renderFooter()}
                onHide={onHide}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "100vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        style={{ marginLeft: "1vw" }}
                      >
                        Anterior
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleNext}>
                        {activeStep === steps.length - 1
                          ? "Guardar"
                          : "Siguiente"}
                      </Button>
                    </Box>

                    <Fragment>
                      {activeStep === 0 && selectedRowPaciente ? (
                        <Contrato
                          paciente={selectedRowPaciente}
                          geriatrico={geriatricoElegido}
                        />
                      ) : null}

                      {activeStep === 1 && selectedRowPaciente ? (
                        <Barthel />
                      ) : null}

                      {activeStep === 2 && selectedRowPaciente ? (
                        <LawtonBrody />
                      ) : null}

                      {activeStep === 3 && selectedRowPaciente ? (
                        <Tinetti />
                      ) : null}

                      {activeStep === 4 && selectedRowPaciente ? (
                        <EscalaValoracionMental />
                      ) : null}

                      {activeStep === 5 && selectedRowPaciente ? (
                        <Reloj />
                      ) : null}

                      {activeStep === 6 && selectedRowPaciente ? (
                        <Goldberg />
                      ) : null}
                      {activeStep === 7 && selectedRowPaciente ? (
                        <EvaluacionNutricional />
                      ) : null}
                      {activeStep === 8 && selectedRowPaciente ? (
                        <Fragilidad />
                      ) : null}
                      {activeStep === 9 && selectedRowPaciente ? (
                        <Norton />
                      ) : null}

                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                          style={{ marginLeft: "1vw" }}
                        >
                          Anterior
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleNext}>
                          {activeStep === steps.length - 1
                            ? "Guardar"
                            : "Siguiente"}
                        </Button>
                      </Box>
                    </Fragment>
                  </Box>
                </Grid>
              </Dialog>

              <Dialog
                showHeader={false}
                visible={dialogPacientes}
                closable={false}
                footer={renderFooterPacientes()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <Tooltip
                          target=".export-buttons>button"
                          position="bottom"
                        />
                        <DataTable
                          value={pacientes}
                          selection={selectedRowPaciente}
                          onSelectionChange={(e) =>
                            setSelectedRowPaciente(e.value)
                          }
                          selectionMode="single"
                          dataKey="id"
                          header={headerPacientes}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "small", marginTop: "1vw" }}
                          globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          paginator
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          rows={100}
                          rowsPerPageOptions={[10, 20, 50, 100, 500]}
                          responsiveLayout="scroll"

                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                        >
                          <Column
                            field="id"
                            header="ID"
                            body={idTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="nombre"
                            header="Apellido y Nombre"
                            body={nombreTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="domicilio"
                            header="Domicilio"
                            body={domicilioTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="numeroDocumento"
                            header="Documento"
                            body={documentoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="fechaIngresoEgreso"
                            header="Ingreso/Egreso"
                            body={ingresoEgresoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="motivoIngresoEgreso"
                            header="Motivo"
                            body={motivoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="activo"
                            header="Activo"
                            body={activoTemplate}
                            sortable
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
      {exit ? <Navigate to="/login" /> : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
