import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import DatosPersonales from "./DatosPersonales";
import Barthel from "./Barthel";
import LawtonBrody from "./LawtonBrody";
import ExamenI from "./ExamenI";
import ExamenII from "./ExamenII";
import ExamenIII from "./ExamenIII";
import Evoluciones from "./Evoluciones";
import EscalaValoracionMental from "./EscalaValoracionMental";
import EvaluacionNutricional from "./EvaluacionNutricional";
import Fragilidad from "./Fragilidad";
import Antecedentes from "./Antecedentes";
import Actual from "./Actual";
import "../../assets/css/toast.css";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";


export default function Diabeticos(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Domicilio", dataKey: "domicilio" },
    { title: "Teléfono", dataKey: "telefono" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [dialogPacientes, setDialogPacientes] = useState(false);
  const toast = useRef(null);
  const [diabete, guardarDiabete] = useState(null);

  const [exit, setExit] = useState(false);
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    statusAcceso2,
    statusAcceso3,
    statusAcceso4,
    statusAcceso5,
    historiasClinicas,
    paciente,
    pacientes,
    diabetes,
    geriatricoElegido,
    visible,
    fragilidad,
    antecedente,
    actual,
    examenI,
    examenII,
    examenIII,
    evolucion,
    selectedRowPaciente,
    validarHistoriaClinica,
    obtenerDiabetes,
    obtenerAcceso,
    obtenerAcceso2,
    obtenerAcceso3,
    obtenerAcceso4,
    obtenerAcceso5,
    agregarDiabetes,
    actualizarDiabetes,
    setGeriatricoElegido,
    obtenerPaciente,
    obtenerPacientes,
    guardarHistoriaClinica,
    verificarHistoriaClinica,
    setVisible,
    obtenerInformesHistoriaClinica,
    setSelectedRowPaciente,
    guardarEscalaValoracionMental,
    guardarFragilidad,
    guardarAntecedentes,
    obtenerInformeFragilidad,
    guardarEvolucion,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Diabetes",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    obtenerDiabetes({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    });
    obtenerPacientes({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    });
  }, []);

  const editClick = (e) => {
    if (selectedRow) {
      guardarDiabete(selectedRow);
      //obtenerPaciente({ pacienteId: selectedRow.pacienteId });
      //obtenerInformesHistoriaClinica({ pacienteId: selectedRow.pacienteId });
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un control.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //props.history.push(`/historiaClínica/0`);
    //setVisible(true);
    //obtenerInformeFragilidad({ pacienteId: selectedRow.pacienteId });
    setDialogPacientes(true);
    setSelectedRowPaciente(null);
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHidePacientes = (e) => {
    setSelectedRowPaciente(null);
    setDialogPacientes(false);
  };

  const aceptarClickPacientes = (e) => {
    if (selectedRowPaciente) {
      //verificarHistoriaClinica({ selectedRowPaciente });
      //obtenerInformeFragilidad({ pacienteId: selectedRowPaciente.id });
      //obtenerInformesHistoriaClinica({ pacienteId: selectedRowPaciente.id });
      setDialogPacientes(false);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !diabete ||
      diabete.tipo === undefined ||
      diabete.tipo === null ||
      diabete.tipo.trim() === ""
    ) {
      mensajeAlerta("Diabetes", "El tipo es necesario !.", "error");
      return;
    }

    if (
      !diabete ||
      diabete.medicacion === undefined ||
      diabete.medicacion === null ||
      diabete.medicacion.trim() === ""
    ) {
      mensajeAlerta("Diabetes", "La medicación es necesario !.", "error");
      return;
    }

    if (
      !diabete ||
      diabete.valor === undefined ||
      diabete.valor === null ||
      diabete.valor.trim() === ""
    ) {
      mensajeAlerta("Diabetes", "El valor es necesario !.", "error");
      return;
    }

    if (
      !diabete ||
      diabete.control === undefined ||
      diabete.control === null ||
      diabete.control.trim() === ""
    ) {
      mensajeAlerta(
        "Diabetes",
        "El control de estudio es necesario !.",
        "error"
      );
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarDiabetes({
        diabete,
        geriatricoId: geriatricoElegido.id,
        pacienteId: selectedRow.pacienteId,
        usuario: state.usuario.usuario,
      });
    } else {
      agregarDiabetes({
        diabete,
        geriatricoId: geriatricoElegido.id,
        pacienteId: selectedRowPaciente.id,
        usuario: state.usuario.usuario,
      });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerDiabetes({
        geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
      });
      obtenerPacientes({
        geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
      });
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const fechaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDate(rowData.fecha)}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const documentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const tipoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Tipo</span>
        {rowData.tipo}
      </Fragment>
    );
  };

  const medicacionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Medicación</span>
        {rowData.medicacion}
      </Fragment>
    );
  };

  const valorTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Valor</span>
        {rowData.valor}
      </Fragment>
    );
  };

  const controlTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Control</span>
        {rowData.control}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const ingresoEgresoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Ingreso/Egreso</span>
        {formatDate(rowData.fechaIngresoEgreso)}
      </Fragment>
    );
  };

  const motivoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Motivo</span>
        {rowData.motivoIngresoEgreso}
      </Fragment>
    );
  };

  const activoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Activo</span>
        <Checkbox checked={rowData.activo}></Checkbox>
      </Fragment>
    );
  };

  const telefonoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Télefono</span>
        {rowData.telefono}
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Habilitado</span>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, historiasClinicas);
        doc.save("Diabetes.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(diabetes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Diabetes");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Control Diabetes"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        {selectedRowPaciente ? (
          <Tag
            value={"Contro Diabético de " + selectedRowPaciente.nombre}
          ></Tag>
        ) : null}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const headerPacientes = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            fullWidth
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Residentes"></Tag>
      </div>
    </Fragment>
  );

  const renderFooterPacientes = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickPacientes}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHidePacientes}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  function formatDateTime(dateString) {
    var allDate = dateString.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  //console.log(selectedRow);
  //console.log(lawtonBrody);
  //console.log(historiasClinicas);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {diabetes ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
               style={{
                  align: "center",
                  margin: "1em",
                  marginTop:
                    isMobile && !isPortrait
                      ? "22vw"
                      : isMobile && isPortrait
                      ? "55vw"
                      : "0vw",
                }}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={diabetes}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      responsiveLayout="scroll"

                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha"
                        body={fechaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Nombre"
                        body={nombreTemplate}
                        style={{ width: "40%" }}
                        sortable
                      ></Column>
                      <Column
                        field="numeroDocumento"
                        header="Documento"
                        body={documentoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="tipo"
                        header="Tipo"
                        body={tipoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="medicacion"
                        header="Medicación"
                        body={medicacionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="valor"
                        header="Valor"
                        body={valorTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="control"
                        header="Control"
                        body={controlTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                showHeader={false}
                visible={dialogPacientes}
                closable={false}
                footer={renderFooterPacientes()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <Tooltip
                          target=".export-buttons>button"
                          position="bottom"
                        />
                        <DataTable
                          value={pacientes}
                          selection={selectedRowPaciente}
                          onSelectionChange={(e) =>
                            setSelectedRowPaciente(e.value)
                          }
                          selectionMode="single"
                          dataKey="id"
                          header={headerPacientes}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "small", marginTop: "1vw" }}
                          globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          paginator
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          rows={10}
                          rowsPerPageOptions={[10, 20, 50]}
                          responsiveLayout="scroll"

                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                        >
                          <Column
                            field="id"
                            header="ID"
                            body={idTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="nombre"
                            header="Apellido y Nombre"
                            body={nombreTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="domicilio"
                            header="Domicilio"
                            body={domicilioTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="numeroDocumento"
                            header="Documento"
                            body={documentoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="fechaIngresoEgreso"
                            header="Ingreso/Egreso"
                            body={ingresoEgresoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="motivoIngresoEgreso"
                            header="Motivo"
                            body={motivoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="activo"
                            header="Activo"
                            body={activoTemplate}
                            sortable
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Dialog>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "60vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                <Grid item xs={6} md={3}>
                    <TextField
                      id="fecha"
                      label="Fecha"
                      type="date"
                      fullWidth
                      variant="standard"
                      defaultValue={
                        diabete && diabete.fecha ? diabete.fecha : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarDiabete({
                          ...diabete,
                          fecha: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      value={diabete && diabete.tipo ? diabete.tipo : null}
                      required
                      id="tipo"
                      label="Tipo Diabetes"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarDiabete({
                          ...diabete,
                          tipo: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={
                        diabete && diabete.medicacion
                          ? diabete.medicacion
                          : null
                      }
                      required
                      id="medicacion"
                      label="Medicación"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarDiabete({
                          ...diabete,
                          medicacion: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={diabete && diabete.valor ? diabete.valor : null}
                      required
                      id="valor"
                      label="Valor"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarDiabete({
                          ...diabete,
                          valor: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={
                        diabete && diabete.control ? diabete.control : null
                      }
                      required
                      id="control"
                      label="Control Estudio"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarDiabete({
                          ...diabete,
                          control: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
      {exit ? <Navigate to="/login" /> : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
