import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { InputTextarea } from "primereact/inputtextarea";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function ExamenIII(props) {
  let navigate = useNavigate();
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;

  const acMedica = useRef();
  const acSocial = useRef();
  const acPsicologica = useRef();
  const acNutricional = useRef();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    barthel,
    fragilidad,
    lawtonBrody,
    yesavage,
    tinetti,
    norton,
    examenIII,
    diagnosticos,
    escalaValoracionMental,
    frases,
    reloj,
    estudios,
    medicaciones,
    medicacionesPacientes,
    selectedRowPaciente,
    diagnosticosPacientes,
    estudiosPacientes,
    statusAcceso3,
    localidades,
    guardarExamenIII,
    obtenerAcceso,
    guardarEstudiosPacientes,
  } = serviciosContext;

  const handleChangePregunta1 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta1: event.target.checked,
    });
  };

  const handleChangePregunta2 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta2: event.target.checked,
    });
  };

  const handleChangePregunta3 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta3: event.target.checked,
    });
  };

  const handleChangePregunta4 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta4: event.target.checked,
    });
  };

  const handleChangePregunta5 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta5: event.target.checked,
    });
  };

  const handleChangePregunta6 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta6: event.target.checked,
    });
  };

  const handleChangePregunta7 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta7: event.target.checked,
    });
  };

  const handleChangePregunta8 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta8: event.target.checked,
    });
  };

  const handleChangePregunta9 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta9: event.target.value,
    });
  };

  const handleChangePregunta10 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta10: event.target.value,
    });
  };

  const handleChangePregunta11 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta11: event.target.value,
    });
  };

  const handleChangePregunta12 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta12: event.target.value,
    });
  };

  const handleChangePregunta13 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta13: event.target.value,
    });
  };

  const handleChangePregunta14 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta14: event.target.value,
    });
  };

  const handleChangePregunta15 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta15: event.target.value,
    });
  };

  const handleChangePregunta16 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta16: event.target.value,
    });
  };

  const handleChangePregunta17 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta17: event.target.value,
    });
  };

  const handleChangePregunta18 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta18: event.target.value,
    });
  };

  const handleChangePregunta19 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta19: event.target.value,
    });
  };

  const handleChangePregunta20 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta20: event.target.value,
    });
  };

  const handleChangePregunta21 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta21: event.target.value,
    });
  };

  const handleChangePregunta22 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta22: event.target.value,
    });
  };

  const handleChangePregunta23 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta23: event.target.value,
    });
  };

  const handleChangePregunta24 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta24: event.target.value,
    });
  };

  const handleChangePregunta25 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta25: event.target.value,
    });
  };

  const handleChangePregunta26 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta26: event.target.value,
    });
  };

  const handleChangePregunta27 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta27: event.target.value,
    });
  };

  const handleChangePregunta28 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta28: event.target.value,
    });
  };

  const handleChangePregunta29 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta29: event.target.value,
    });
  };

  const handleChangePregunta30 = (event) => {
    guardarExamenIII({
      ...examenIII,
      pregunta30: event.target.value,
      pregunta18: event.target.value === "true" ? "false" : null,
      pregunta19: event.target.value === "true" ? "false" : null,
      pregunta20: event.target.value === "true" ? "false" : null,
      pregunta21: event.target.value === "true" ? "false" : null,
      pregunta22: event.target.value === "true" ? "false" : null,
      pregunta23: event.target.value === "true" ? "false" : null,
      pregunta24: event.target.value === "true" ? "false" : null,
      pregunta25: event.target.value === "true" ? "false" : null,
      pregunta26: event.target.value === "true" ? "false" : null,
      pregunta27: event.target.value === "true" ? "false" : null,
      pregunta28: event.target.value === "true" ? "false" : null,
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  console.log(reloj);

  return (
    <Fragment>
      {examenIII ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "1vw", marginLeft: "0vw" }}
          >
            {/*}
            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                ESTADO NUTRICIONAL:
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <TextField
                value={examenIII ? examenIII.circunferenciaBrazo : null}
                id="circnferenciaBrazo"
                label="Circunferencia del brazo cm"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenIII({
                    ...examenIII,
                    circunferenciaBrazo: e.target.value,
                  })
                }
                disabled={
                  examenIII && examenIII.status && statusAcceso3 !== 200
                }
              />
            </Grid>
            <Grid item xs={12} md={6} />

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta1 : null}
                      onChange={handleChangePregunta1}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Ingesta adecuada."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta2 : null}
                      onChange={handleChangePregunta2}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Ingesta inadecuada."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta3 : null}
                      onChange={handleChangePregunta3}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Fatiga."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta4 : null}
                      onChange={handleChangePregunta4}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Demencia Anorexia."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta5 : null}
                      onChange={handleChangePregunta5}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Mal estado de la boca."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta6 : null}
                      onChange={handleChangePregunta6}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Alcoholismo."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta7 : null}
                      onChange={handleChangePregunta7}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Pérdida de peso (> 10% en < 6 sem.)."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta8 : null}
                      onChange={handleChangePregunta8}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Déficit bioquímicos."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta9 : null}
                      onChange={handleChangePregunta9}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Proteínas totales."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta10 : null}
                      onChange={handleChangePregunta10}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Hierro."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta11 : null}
                      onChange={handleChangePregunta11}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Albumina."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta12 : null}
                      onChange={handleChangePregunta12}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Ac Fólico."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta13 : null}
                      onChange={handleChangePregunta13}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Transferrina."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta14 : null}
                      onChange={handleChangePregunta14}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Vit. B12."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta15 : null}
                      onChange={handleChangePregunta15}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Colesterol."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

                */}

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                FUNCION VISUAL:
              </Typography>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta1 : null}
                      onChange={handleChangePregunta1}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="20/400."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta2 : null}
                      onChange={handleChangePregunta2}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="20/200."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta3 : null}
                      onChange={handleChangePregunta3}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="20/100."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta4 : null}
                      onChange={handleChangePregunta4}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="20/70."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta5 : null}
                      onChange={handleChangePregunta5}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="20/50."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta6 : null}
                      onChange={handleChangePregunta6}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="20/40."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta7 : null}
                      onChange={handleChangePregunta7}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="20/30."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examenIII ? examenIII.pregunta8 : null}
                      onChange={handleChangePregunta8}
                      inputProps={{ "aria-label": "controlled" }}
                      value={true}
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="20/20."
                  labelPlacement="end"
                  disabled={
                    examenIII && examenIII.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }} />

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenIII ? examenIII.pregunta9 : null}
                  onChange={handleChangePregunta9}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Usa anteojos
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenIII ? examenIII.pregunta10 : null}
                  onChange={handleChangePregunta10}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Graduación
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenIII ? examenIII.pregunta11 : null}
                  onChange={handleChangePregunta11}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Glaucoma
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenIII ? examenIII.pregunta12 : null}
                  onChange={handleChangePregunta12}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Maculopatía degenerativa
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenIII ? examenIII.pregunta13 : null}
                  onChange={handleChangePregunta13}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Arco senil
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenIII ? examenIII.pregunta14 : null}
                  onChange={handleChangePregunta14}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Agudeza visual
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenIII ? examenIII.pregunta15 : null}
                  onChange={handleChangePregunta15}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Cataratas
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={8} style={{ marginTop: "-1vw" }} />

            <Typography
              variant="caption"
              display="block"
              gutterBottom
              style={{ paddingLeft: "1.5vw" }}
            >
              Se mantiene esta tarjeta a unos 36 cm. de los ojos, con buena
              iluminación. Se evalúa la visión para cada ojo de forma separada,
              con y sin gafas. Los pacientes con presbicia usaran sus gafas
              bifocales.
            </Typography>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              style={{ paddingLeft: "1.5vw" }}
            >
              Los miopes se evaluarán solamente con gafas. — CEGUERA: AV menor
              20/200 en el mejor ojo — C. PARCIAL: AV menor 20/70 en el mejor
              ojo — DETERIORO VISUAL FUNCIONAL: AV menor 20/50 en el mejor ojo,
              con alteración de la vida diaria.
            </Typography>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                FUNCION AUDITIVA:
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    examenIII && examenIII.pregunta30
                      ? examenIII.pregunta30
                      : null
                  }
                  onChange={handleChangePregunta30}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Revisado normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    examenIII && examenIII.pregunta18
                      ? examenIII.pregunta18
                      : null
                  }
                  onChange={handleChangePregunta18}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Usa Audífono
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    examenIII && examenIII.pregunta19
                      ? examenIII.pregunta19
                      : null
                  }
                  onChange={handleChangePregunta19}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    1) Le ha ocasionado un problema de audición el sentirse
                    avergonzado cuando ha conocí-do a una persona?
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    examenIII && examenIII.pregunta20
                      ? examenIII.pregunta20
                      : null
                  }
                  onChange={handleChangePregunta20}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    2) Le ha ocasionado un problema de audición el sentirse
                    frustrado cuando habla con miembros de su familia?
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    examenIII && examenIII.pregunta21
                      ? examenIII.pregunta21
                      : null
                  }
                  onChange={handleChangePregunta21}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    3) Tiene usted dificultades para escuchar cuando alguien le
                    habla susurrando?
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    examenIII && examenIII.pregunta22
                      ? examenIII.pregunta22
                      : null
                  }
                  onChange={handleChangePregunta22}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    4) Se siente usted incapacitado por un problema de audición?
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    examenIII && examenIII.pregunta23
                      ? examenIII.pregunta23
                      : null
                  }
                  onChange={handleChangePregunta23}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    5) Le ha ocasionado un problema de audición dificultades
                    cuando visita amigos, familiares o vecinos?
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    examenIII && examenIII.pregunta24
                      ? examenIII.pregunta24
                      : null
                  }
                  onChange={handleChangePregunta24}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    6) Le ha ocasionado un problema de audición que acuda a los
                    servicios religiosos menos frecuentemente de lo que quería?
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    examenIII && examenIII.pregunta25
                      ? examenIII.pregunta25
                      : null
                  }
                  onChange={handleChangePregunta25}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    7) Le ha ocasionado un problema de audición el tener
                    discusiones con los miembros de su familia?
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    examenIII && examenIII.pregunta26
                      ? examenIII.pregunta26
                      : null
                  }
                  onChange={handleChangePregunta26}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    8) Le ha ocasionado un problema de audición ificultades para
                    escuchar la televisión o la radio?
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    examenIII && examenIII.pregunta27
                      ? examenIII.pregunta27
                      : null
                  }
                  onChange={handleChangePregunta27}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    9) Siente usted que algún problema de audición le limita o
                    estorba su vida personal o social?
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    examenIII && examenIII.pregunta28
                      ? examenIII.pregunta28
                      : null
                  }
                  onChange={handleChangePregunta28}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    10) Le ha ocasionado un problema de audición dificultades
                    Cuando está en un restaurante con familiares o amigos?
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h5" gutterBottom component="div">
                Resultado
              </Typography>
              <Typography variant="h4" gutterBottom component="div">
                {Number(
                  examenIII.pregunta19 === "true" ||
                    examenIII.pregunta19 === true
                    ? 1
                    : 0
                ) +
                  Number(
                    examenIII.pregunta20 === "true" ||
                      examenIII.pregunta20 === true
                      ? 1
                      : 0
                  ) +
                  Number(
                    examenIII.pregunta21 === "true" ||
                      examenIII.pregunta21 === true
                      ? 1
                      : 0
                  ) +
                  Number(
                    examenIII.pregunta22 === "true" ||
                      examenIII.pregunta22 === true
                      ? 1
                      : 0
                  ) +
                  Number(
                    examenIII.pregunta23 === "true" ||
                      examenIII.pregunta23 === true
                      ? 1
                      : 0
                  ) +
                  Number(
                    examenIII.pregunta24 === "true" ||
                      examenIII.pregunta24 === true
                      ? 1
                      : 0
                  ) +
                  Number(
                    examenIII.pregunta25 === "true" ||
                      examenIII.pregunta25 === true
                      ? 1
                      : 0
                  ) +
                  Number(
                    examenIII.pregunta26 === "true" ||
                      examenIII.pregunta26 === true
                      ? 1
                      : 0
                  ) +
                  Number(
                    examenIII.pregunta27 === "true" ||
                      examenIII.pregunta27 === true
                      ? 1
                      : 0
                  ) +
                  Number(
                    examenIII.pregunta28 === "true" ||
                      examenIII.pregunta28 === true
                      ? 1
                      : 0
                  )}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <Typography variant="caption" display="block" gutterBottom>
                Total: mas de 4 positivos requiere audiometría y tratamiento:
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenIII ? examenIII.pregunta29 : null}
                  onChange={handleChangePregunta29}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Recibe tratamiento:
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenIII && examenIII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} style={{ marginTop: "-3vw" }}>
              <TextField
                value={examenIII ? examenIII.cualAuditiva : null}
                id="cualAuditiva"
                label="Cual"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenIII({
                    ...examenIII,
                    cualAuditiva: e.target.value,
                  })
                }
                disabled={
                  examenIII && examenIII.status && statusAcceso3 !== 200
                }
              />
            </Grid>

            <Grid item xs={12} md={12} />

            <Grid item xs={12} md={12}>
              <Autocomplete
                id="diagnostico"
                fullWidth
                multiple
                options={diagnosticos}
                disablePortal
                disabled
                defaultValue={
                  selectedRowPaciente &&
                  diagnosticosPacientes &&
                  diagnosticosPacientes !== null &&
                  diagnosticosPacientes.length > 0
                    ? diagnosticosPacientes
                    : []
                }
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    style={{ marginTop: "-1vw" }}
                    label="DIAGNOSTICO DE INGRESO:"
                  />
                )}
              />
            </Grid>
            {/*

            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextareaAutosize
                value={examenIII ? examenIII.diagnosticoIngreso : null}
                id="DiagnósticoIngreso"
                aria-label="minimum height"
                minRows={2}
                maxRows={6}
                fullWidth
                placeholder="Diagnóstico de Ingreso"
                style={{ width: "100%" }}
                onChange={(e) =>
                  guardarExamenIII({
                    ...examenIII,
                    diagnosticoIngreso: e.target.value,
                  })
                }
                disabled={
                  examenIII && examenIII.status && statusAcceso3 !== 200
                }
              />
            </Grid>

              */}

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                VALORACION GERIATRICA INTEGRAL: Conclusión
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Funcional
              </Typography>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  barthel
                    ? Number(barthel.comer ? barthel.comer : 0) +
                      Number(barthel.lavarse ? barthel.lavarse : 0) +
                      Number(barthel.vestirse ? barthel.vestirse : 0) +
                      Number(barthel.arreglarse ? barthel.arreglarse : 0) +
                      Number(barthel.deposiciones ? barthel.deposiciones : 0) +
                      Number(barthel.miccion ? barthel.miccion : 0) +
                      Number(barthel.retrete ? barthel.retrete : 0) +
                      Number(barthel.trasladarse ? barthel.trasladarse : 0) +
                      Number(barthel.deambular ? barthel.deambular : 0) +
                      Number(barthel.escalones ? barthel.escalones : 0)
                    : null
                }
                id="barthel"
                label="ABVD BARTHEL"
                fullWidth
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  lawtonBrody
                    ? Number(
                        lawtonBrody.telefonoResultado
                          ? lawtonBrody.telefonoResultado
                          : 0
                      ) +
                      Number(
                        lawtonBrody.comprasResultado
                          ? lawtonBrody.comprasResultado
                          : 0
                      ) +
                      Number(
                        lawtonBrody.comidaResultado
                          ? lawtonBrody.comidaResultado
                          : 0
                      ) +
                      Number(
                        lawtonBrody.casaResultado
                          ? lawtonBrody.casaResultado
                          : 0
                      ) +
                      Number(
                        lawtonBrody.ropaResultado
                          ? lawtonBrody.ropaResultado
                          : 0
                      ) +
                      Number(
                        lawtonBrody.transporteResultado
                          ? lawtonBrody.transporteResultado
                          : 0
                      ) +
                      Number(
                        lawtonBrody.medicacionResultado
                          ? lawtonBrody.medicacionResultado
                          : 0
                      ) +
                      Number(
                        lawtonBrody.economicoResultado
                          ? lawtonBrody.economicoResultado
                          : 0
                      )
                    : null
                }
                id="lawton"
                label="AIVB LAWTON"
                fullWidth
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  tinetti
                    ? Number(
                        tinetti.equilibrioSentado
                          ? tinetti.equilibrioSentado
                          : 0
                      ) +
                      Number(tinetti.levantarse ? tinetti.levantarse : 0) +
                      Number(
                        tinetti.intentoLevantarse
                          ? tinetti.intentoLevantarse
                          : 0
                      ) +
                      Number(
                        tinetti.equilibrioSegundos
                          ? tinetti.equilibrioSegundos
                          : 0
                      ) +
                      Number(
                        tinetti.equilibrioProlongado
                          ? tinetti.equilibrioProlongado
                          : 0
                      ) +
                      Number(
                        tinetti.rombergSensibilizado
                          ? tinetti.rombergSensibilizado
                          : 0
                      ) +
                      Number(tinetti.romberg ? tinetti.romberg : 0) +
                      Number(tinetti.girar ? tinetti.girar : 0) +
                      Number(tinetti.girar2 ? tinetti.girar2 : 0) +
                      Number(tinetti.sentarse ? tinetti.sentarse : 0) / 16
                    : null
                }
                id="tinetti"
                label="TINETTI"
                fullWidth
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  escalaValoracionMental
                    ? Number(
                        escalaValoracionMental.pregunta1
                          ? escalaValoracionMental.pregunta1Value
                          : 0
                      ) +
                      Number(
                        escalaValoracionMental.pregunta2
                          ? escalaValoracionMental.pregunta2Value
                          : 0
                      ) +
                      Number(
                        escalaValoracionMental.pregunta3
                          ? escalaValoracionMental.pregunta3Value
                          : 0
                      ) +
                      Number(
                        escalaValoracionMental.pregunta4
                          ? escalaValoracionMental.pregunta4Value
                          : 0
                      ) +
                      Number(
                        escalaValoracionMental.pregunta5
                          ? escalaValoracionMental.pregunta5
                          : 0
                      ) +
                      Number(
                        escalaValoracionMental.pregunta6
                          ? escalaValoracionMental.pregunta6Value
                          : 0
                      ) +
                      Number(
                        escalaValoracionMental.pregunta7
                          ? escalaValoracionMental.pregunta7Value
                          : 0
                      ) +
                      Number(
                        escalaValoracionMental.pregunta8
                          ? escalaValoracionMental.pregunta8Value
                          : 0
                      ) +
                      Number(
                        escalaValoracionMental.pregunta9
                          ? escalaValoracionMental.pregunta9Value
                          : 0
                      ) +
                      Number(
                        escalaValoracionMental.pregunta10
                          ? escalaValoracionMental.pregunta10Value
                          : 0
                      ) +
                      Number(
                        escalaValoracionMental.pregunta11
                          ? escalaValoracionMental.pregunta11Value
                          : 0
                      ) +
                      Number(
                        escalaValoracionMental.pregunta12
                          ? escalaValoracionMental.pregunta12Value
                          : 0
                      ) +
                      Number(
                        escalaValoracionMental.pregunta13
                          ? escalaValoracionMental.pregunta13Value
                          : 0
                      ) +
                      Number(
                        escalaValoracionMental.pregunta14
                          ? escalaValoracionMental.pregunta14Value
                          : 0
                      ) +
                      Number(
                        escalaValoracionMental.pregunta15
                          ? escalaValoracionMental.pregunta15Value
                          : 0
                      )
                    : null
                }
                id="lobo"
                label="MINIMENTAL"
                fullWidth
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  yesavage
                    ? Number(yesavage.pregunta1 ? yesavage.pregunta1 : 0) +
                      Number(yesavage.pregunta2 ? yesavage.pregunta2 : 0) +
                      Number(yesavage.pregunta3 ? yesavage.pregunta3 : 0) +
                      Number(yesavage.pregunta4 ? yesavage.pregunta4 : 0) +
                      Number(yesavage.pregunta5 ? yesavage.pregunta5 : 0) +
                      Number(yesavage.pregunta6 ? yesavage.pregunta6 : 0) +
                      Number(yesavage.pregunta7 ? yesavage.pregunta7 : 0) +
                      Number(yesavage.pregunta8 ? yesavage.pregunta8 : 0) +
                      Number(yesavage.pregunta9 ? yesavage.pregunta9 : 0) +
                      Number(yesavage.pregunta10 ? yesavage.pregunta10 : 0) +
                      Number(yesavage.pregunta11 ? yesavage.pregunta11 : 0) +
                      Number(yesavage.pregunta12 ? yesavage.pregunta12 : 0) +
                      Number(yesavage.pregunta13 ? yesavage.pregunta13 : 0) +
                      Number(yesavage.pregunta14 ? yesavage.pregunta14 : 0) +
                      Number(yesavage.pregunta15 ? yesavage.pregunta15 : 0) +
                      Number(yesavage.pregunta16 ? yesavage.pregunta16 : 0) +
                      Number(yesavage.pregunta17 ? yesavage.pregunta17 : 0) +
                      Number(yesavage.pregunta18 ? yesavage.pregunta18 : 0) +
                      Number(yesavage.pregunta19 ? yesavage.pregunta19 : 0) +
                      Number(yesavage.pregunta20 ? yesavage.pregunta20 : 0) +
                      Number(yesavage.pregunta21 ? yesavage.pregunta21 : 0) +
                      Number(yesavage.pregunta22 ? yesavage.pregunta22 : 0) +
                      Number(yesavage.pregunta23 ? yesavage.pregunta23 : 0) +
                      Number(yesavage.pregunta24 ? yesavage.pregunta24 : 0) +
                      Number(yesavage.pregunta25 ? yesavage.pregunta25 : 0) +
                      Number(yesavage.pregunta26 ? yesavage.pregunta26 : 0) +
                      Number(yesavage.pregunta27 ? yesavage.pregunta27 : 0) +
                      Number(yesavage.pregunta28 ? yesavage.pregunta28 : 0) +
                      Number(yesavage.pregunta29 ? yesavage.pregunta29 : 0) +
                      Number(yesavage.pregunta30 ? yesavage.pregunta30 : 0)
                    : null
                }
                id="depresion"
                label="DEPRESION"
                fullWidth
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  norton
                    ? Number(norton.estadoFisico ? norton.estadoFisico : 0) +
                      Number(norton.estadoMental ? norton.estadoMental : 0) +
                      Number(norton.actividad ? norton.actividad : 0) +
                      Number(norton.movilidad ? norton.movilidad : 0) +
                      Number(norton.incontinencia ? norton.incontinencia : 0)
                    : null
                }
                id="norton"
                label="NORTON"
                fullWidth
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  fragilidad
                    ? Number(
                        fragilidad.pregunta1 ? fragilidad.pregunta1Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta2 ? fragilidad.pregunta2Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta3 ? fragilidad.pregunta3Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta4 ? fragilidad.pregunta4Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta5 ? fragilidad.pregunta5Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta6 ? fragilidad.pregunta6Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta7 ? fragilidad.pregunta7Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta8 ? fragilidad.pregunta8Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta9 ? fragilidad.pregunta9Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta10 ? fragilidad.pregunta10Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta11 ? fragilidad.pregunta11Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta12 ? fragilidad.pregunta12Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta13 ? fragilidad.pregunta13Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta14 ? fragilidad.pregunta14Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta15 ? fragilidad.pregunta15Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta16 ? fragilidad.pregunta16Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta17 ? fragilidad.pregunta17Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta18 ? fragilidad.pregunta18Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta19 ? fragilidad.pregunta19Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta20 ? fragilidad.pregunta20Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta21 ? fragilidad.pregunta21Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta22 ? fragilidad.pregunta22Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta23 ? fragilidad.pregunta23Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta24 ? fragilidad.pregunta24Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta25 ? fragilidad.pregunta25Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta26 ? fragilidad.pregunta26Value : 0
                      ) +
                      Number(
                        fragilidad.pregunta27 ? fragilidad.pregunta27Value : 0
                      )
                    : null
                }
                id="fragilidad"
                label="FRAGILIDAD"
                fullWidth
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  examenIII
                    ? Number(
                        examenIII.pregunta19 === "true" ||
                          examenIII.pregunta19 === true
                          ? 1
                          : 0
                      ) +
                      Number(
                        examenIII.pregunta20 === "true" ||
                          examenIII.pregunta20 === true
                          ? 1
                          : 0
                      ) +
                      Number(
                        examenIII.pregunta21 === "true" ||
                          examenIII.pregunta21 === true
                          ? 1
                          : 0
                      ) +
                      Number(
                        examenIII.pregunta22 === "true" ||
                          examenIII.pregunta22 === true
                          ? 1
                          : 0
                      ) +
                      Number(
                        examenIII.pregunta23 === "true" ||
                          examenIII.pregunta23 === true
                          ? 1
                          : 0
                      ) +
                      Number(
                        examenIII.pregunta24 === "true" ||
                          examenIII.pregunta24 === true
                          ? 1
                          : 0
                      ) +
                      Number(
                        examenIII.pregunta25 === "true" ||
                          examenIII.pregunta25 === true
                          ? 1
                          : 0
                      ) +
                      Number(
                        examenIII.pregunta26 === "true" ||
                          examenIII.pregunta26 === true
                          ? 1
                          : 0
                      ) +
                      Number(
                        examenIII.pregunta27 === "true" ||
                          examenIII.pregunta27 === true
                          ? 1
                          : 0
                      ) +
                      Number(
                        examenIII.pregunta28 === "true" ||
                          examenIII.pregunta28 === true
                          ? 1
                          : 0
                      )
                    : null
                }
                id="auditivo"
                label="AUDITIVO"
                fullWidth
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  examenIII
                    ? examenIII.pregunta1 || examenIII.pregunta2
                      ? "CEGUERA"
                      : examenIII.pregunta3 || examenIII.pregunta4
                      ? "CEGUERA PARCIAL"
                      : "DETERIORO VISUAL FUNCIONAL "
                    : ""
                }
                id="visual"
                label="VISUAL"
                fullWidth
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  reloj
                    ? Number(reloj.esfera ? reloj.esfera : 0) +
                      Number(
                        reloj.numero && reloj.numero / 10 === 3.5
                          ? reloj.numero / 10
                          : reloj.numero && reloj.numero / 10 !== 3.5
                          ? Math.trunc(reloj.numero / 10)
                          : 0
                      ) +
                      Number(
                        reloj.aguja && reloj.aguja / 10 === 3.5
                          ? reloj.aguja / 10
                          : reloj.aguja && reloj.aguja / 10 !== 3.5
                          ? Math.trunc(reloj.aguja / 10)
                          : 0
                      )
                    : null
                }
                id="reloj"
                label="TEST DEL RELOJ"
                fullWidth
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Médica
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Autocomplete
                id="frases"
                ref={acMedica}
                fullWidth
                options={
                  frases
                    ? frases.filter(
                        (element) =>
                          element.habilitado && element.tipo === "medica"
                      )
                    : null
                }
                disablePortal
                getOptionLabel={(option) => option.frase}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Frases Médicas"
                    size="small"
                  />
                )}
                onChange={(event, value) => {
                  if (value && value.frase && value.frase !== null) {
                    if (examenIII.vgimedica && examenIII.vgimedica.length > 0) {
                      guardarExamenIII({
                        ...examenIII,
                        vgimedica: examenIII.vgimedica + value.frase + "\n",
                      });
                    } else {
                      guardarExamenIII({
                        ...examenIII,
                        vgimedica: value.frase + "\n",
                      });
                    }
                    setTimeout(() => {
                      const ele = acMedica.current.getElementsByClassName(
                        "MuiAutocomplete-clearIndicator"
                      )[0];
                      if (ele) ele.click();
                    }, 1000);
                  }
                }}

                //onKeyDown={handleEnter}
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ margin: "1vw", width: "auto" }}>
              <Grid item xs={12} md={12}>
                <InputTextarea
                  value={
                    examenIII && examenIII.vgimedica
                      ? examenIII.vgimedica
                      : null
                  }
                  onChange={(e) =>
                    guardarExamenIII({
                      ...examenIII,
                      vgimedica: e.target.value,
                    })
                  }
                  rows={5}
                  cols={
                    isMobile && !isPortrait
                      ? 90
                      : isMobile && isPortrait
                      ? 30
                      : 120
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Social
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Autocomplete
                id="frases"
                ref={acSocial}
                fullWidth
                options={
                  frases
                    ? frases.filter(
                        (element) =>
                          element.habilitado && element.tipo === "social"
                      )
                    : null
                }
                disablePortal
                getOptionLabel={(option) => option.frase}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Frases Sociales"
                    size="small"
                  />
                )}
                onChange={(event, value) => {
                  if (value && value.frase && value.frase !== null) {
                    if (examenIII.vgisocial && examenIII.vgisocial.length > 0) {
                      guardarExamenIII({
                        ...examenIII,
                        vgisocial: examenIII.vgisocial + value.frase + "\n",
                      });
                    } else {
                      guardarExamenIII({
                        ...examenIII,
                        vgisocial: value.frase + "\n",
                      });
                    }
                    setTimeout(() => {
                      const ele = acSocial.current.getElementsByClassName(
                        "MuiAutocomplete-clearIndicator"
                      )[0];
                      if (ele) ele.click();
                    }, 1000);
                  }
                }}

                //onKeyDown={handleEnter}
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ margin: "1vw", width: "auto" }}>
              <Grid item xs={12} md={12}>
                <InputTextarea
                  value={
                    examenIII && examenIII.vgisocial
                      ? examenIII.vgisocial
                      : null
                  }
                  onChange={(e) =>
                    guardarExamenIII({
                      ...examenIII,
                      vgisocial: e.target.value,
                    })
                  }
                  rows={5}
                  cols={
                    isMobile && !isPortrait
                      ? 90
                      : isMobile && isPortrait
                      ? 30
                      : 120
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Psicológica
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Autocomplete
                id="frases"
                ref={acPsicologica}
                fullWidth
                options={
                  frases
                    ? frases.filter(
                        (element) =>
                          element.habilitado && element.tipo === "psicologica"
                      )
                    : null
                }
                disablePortal
                getOptionLabel={(option) => option.frase}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Frases Psicológicas"
                    size="small"
                  />
                )}
                onChange={(event, value) => {
                  if (value && value.frase && value.frase !== null) {
                    if (
                      examenIII.vgipsicologica &&
                      examenIII.vgipsicologica.length > 0
                    ) {
                      guardarExamenIII({
                        ...examenIII,
                        vgipsicologica:
                          examenIII.vgipsicologica + value.frase + "\n",
                      });
                    } else {
                      guardarExamenIII({
                        ...examenIII,
                        vgipsicologica: value.frase + "\n",
                      });
                    }
                    setTimeout(() => {
                      const ele = acPsicologica.current.getElementsByClassName(
                        "MuiAutocomplete-clearIndicator"
                      )[0];
                      if (ele) ele.click();
                    }, 1000);
                  }
                }}

                //onKeyDown={handleEnter}
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ margin: "1vw", width: "auto" }}>
              <Grid item xs={12} md={12}>
                <InputTextarea
                  value={
                    examenIII && examenIII.vgipsicologica
                      ? examenIII.vgipsicologica
                      : null
                  }
                  onChange={(e) =>
                    guardarExamenIII({
                      ...examenIII,
                      vgipsicologica: e.target.value,
                    })
                  }
                  rows={5}
                  cols={
                    isMobile && !isPortrait
                      ? 90
                      : isMobile && isPortrait
                      ? 30
                      : 120
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Nutricional
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Autocomplete
                id="frases"
                ref={acNutricional}
                fullWidth
                options={
                  frases
                    ? frases.filter(
                        (element) =>
                          element.habilitado && element.tipo === "nutricional"
                      )
                    : null
                }
                disablePortal
                getOptionLabel={(option) => option.frase}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Frases Nutricionales"
                    size="small"
                  />
                )}
                onChange={(event, value) => {
                  if (value && value.frase && value.frase !== null) {
                    if (
                      examenIII.vginutricional &&
                      examenIII.vginutricional.length > 0
                    ) {
                      guardarExamenIII({
                        ...examenIII,
                        vginutricional:
                          examenIII.vginutricional + value.frase + "\n",
                      });
                    } else {
                      guardarExamenIII({
                        ...examenIII,
                        vginutricional: value.frase + "\n",
                      });
                    }
                    setTimeout(() => {
                      const ele = acNutricional.current.getElementsByClassName(
                        "MuiAutocomplete-clearIndicator"
                      )[0];
                      if (ele) ele.click();
                    }, 1000);
                  }
                }}

                //onKeyDown={handleEnter}
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ margin: "1vw", width: "auto" }}>
              <Grid item xs={12} md={12}>
                <InputTextarea
                  value={
                    examenIII && examenIII.vginutricional
                      ? examenIII.vginutricional
                      : null
                  }
                  onChange={(e) =>
                    guardarExamenIII({
                      ...examenIII,
                      vginutricional: e.target.value,
                    })
                  }
                  rows={5}
                  cols={
                    isMobile && !isPortrait
                      ? 90
                      : isMobile && isPortrait
                      ? 30
                      : 120
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <Autocomplete
                id="estudios"
                fullWidth
                multiple
                options={estudios}
                disablePortal
                defaultValue={
                  selectedRowPaciente &&
                  estudiosPacientes &&
                  estudiosPacientes !== null &&
                  estudiosPacientes.length > 0
                    ? estudiosPacientes
                    : []
                }
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    style={{ marginTop: "-1vw" }}
                    label="ESTUDIOS SOLICITADOS:"
                  />
                )}
                onChange={(event, values) => {
                  if (values) {
                    guardarEstudiosPacientes(values);
                  } else {
                    guardarEstudiosPacientes(null);
                  }
                }}
              />
            </Grid>

            {/*      
            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextField
                value={examenIII ? examenIII.estudios : null}
                id="estudios"
                label="ESTUDIOS SOLICITADOS"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenIII({
                    ...examenIII,
                    estudios: e.target.value,
                  })
                }
                disabled={
                  examenIII && examenIII.status && statusAcceso3 !== 200
                }
              />
            </Grid>
            */}

            <Grid item xs={12} md={12}>
              <Autocomplete
                id="medicacion"
                fullWidth
                multiple
                options={medicaciones}
                disablePortal
                disabled
                defaultValue={
                  selectedRowPaciente &&
                  medicacionesPacientes &&
                  medicacionesPacientes !== null &&
                  medicacionesPacientes.length > 0
                    ? medicacionesPacientes
                    : []
                }
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    style={{ marginTop: "-1vw" }}
                    label="PLAN TERAPEUTICO/REHABILITACION: "
                  />
                )}
                //onKeyDown={handleEnter}
                /*
                      onChange={(event, values) => {
                        if (values) {
                          guardarDiagnosticosPacientes(values);
                        } else {
                          guardarDiagnosticosPacientes(null);
                        }
                      }}
                      */
              />
            </Grid>

            {/*
            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextField
                value={examenIII ? examenIII.otros : null}
                id="otros"
                label="OTROS"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenIII({
                    ...examenIII,
                    otros: e.target.value,
                  })
                }
                disabled={
                  examenIII && examenIII.status && statusAcceso3 !== 200
                }
              />
            </Grid>
              */}
            <Grid item xs={12} md={2} style={{ marginTop: "0vw" }} />
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
