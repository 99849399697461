import { ConnectedOverlayScrollHandler } from "primereact/utils";
import React, { useState, useContext, Fragment } from "react";
import { createContext } from "react";
import clienteAxios from "../config/axios";
import { AlertContext } from "./AlertContext";

const ServiciosContext = createContext();
const { Provider, Consumer } = ServiciosContext;

function ServiciosProvider({ children }) {
  const alertContext = useContext(AlertContext);
  const { mensajeAlerta, mensajeAlerta2, cerrarAlerta } = alertContext;

  const [establecimientos, guardarEstablecimientos] = useState(null);
  const [establecimientosUsuarios, guardarEstablecimientosUsuarios] =
    useState(null);
  //const [establecimiento, guardarEstablecimiento] = useState(null);
  const [pacientes, guardarPacientes] = useState(null);
  const [paciente, guardarPaciente] = useState(null);
  const [obrasSociales, guardarObrasSociales] = useState(null);
  const [obrasSocialesMedicas, guardarObrasSocialesMedicas] = useState(null);
  //const [obraSocial, guardarObraSocial] = useState(null);
  const [diagnosticos, guardarDiagnosticos] = useState(null);
  const [estudios, guardarEstudios] = useState(null);
  //const [diagnostico, guardarDiagnostico] = useState(null);
  const [localidades, guardarLocalidades] = useState(null);
  //const [localidad, guardarLocalidad] = useState(null);
  const [statusAcceso, setStatusAcceso] = useState(0);
  const [statusAcceso2, setStatusAcceso2] = useState(0);
  const [statusAcceso3, setStatusAcceso3] = useState(0);
  const [statusAcceso4, setStatusAcceso4] = useState(0);
  const [statusAcceso5, setStatusAcceso5] = useState(0);
  const [usuarios, guardarUsuarios] = useState(null);
  const [pantallas, guardarPantallas] = useState(null);
  const [rows, setRows] = useState(null);
  const [geriatricoElegido, setGeriatricoElegido] = useState(null);
  const [ingresosEgresos, guardarIngresosEgresos] = useState(null);
  const [historiasClinicas, guardarHistoriasClinicas] = useState(null);
  const [historiaClinica, guardarHistoriaClinica] = useState(null);
  const [barthel, guardarBarthel] = useState([]);
  const [reloj, guardarReloj] = useState([]);
  const [tinetti, guardarTinetti] = useState([]);
  const [lawtonBrody, guardarLawtonBrody] = useState([]);
  const [yesavage, guardarYesavage] = useState([]);
  const [goldberg, guardarGoldberg] = useState([]);
  const [preIngresos, guardarPreIngresos] = useState(null);
  const [preIngreso, guardarPreIngreso] = useState(null);
  const [escalaValoracionMental, guardarEscalaValoracionMental] = useState([]);
  const [evaluacionNutricional, guardarEvaluacionNutricional] = useState([]);
  const [tipoDieta, guardarTipoDieta] = useState([]);
  const [fragilidad, guardarFragilidad] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedRowPaciente, setSelectedRowPaciente] = useState(false);
  const [antecedente, guardarAntecedente] = useState([]);
  const [actual, guardarActual] = useState([]);
  const [examenI, guardarExamenI] = useState([]);
  const [examenII, guardarExamenII] = useState([]);
  const [examenIII, guardarExamenIII] = useState([]);
  const [evolucion, guardarEvolucion] = useState(null);
  const [vgi, guardarVgi] = useState(null);
  const [datosPlanilla, guardarDatosPlanilla] = useState([]);
  const [datosPlanillaExport, guardarDatosPlanillaExport] = useState([]);
  const [coseguro, guardarCoseguro] = useState(null);
  const [datosPagos, guardarDatosPagos] = useState([]);
  const [totalesPlanilla, guardarTotalesPlanilla] = useState([]);
  const [protocolos, guardarProtocolos] = useState([]);
  const [protocolo, guardarProtocolo] = useState(null);
  const [realizado, setRealizado] = useState("No");
  const [diabetes, guardarDiabetes] = useState([]);
  const [anticoagulados, guardarAnticoagulados] = useState([]);
  const [evoluciones, guardarEvoluciones] = useState([]);
  const [caidas, guardarCaidas] = useState([]);
  const [ulceras, guardarUlceras] = useState([]);
  const [enfermerias, guardarEnfermerias] = useState([]);
  const [frases, guardarFrases] = useState([]);
  const [frasesVGI, guardarFrasesVGI] = useState([]);
  const [patologias, guardarPatologias] = useState(null);
  const [clinicas, guardarClinicas] = useState(null);
  const [medicaciones, guardarMedicaciones] = useState(null);
  const [medicacionesPacientes, guardarMedicacionesPacientes] = useState([]);
  const [vinculos, guardarVinculos] = useState(null);
  const [coseguros, guardarCoseguros] = useState(null);
  const [descartables, guardarDescartables] = useState(null);
  const [panales, guardarPanales] = useState(null);
  const [notas, guardarNotas] = useState(null);
  const [norton, guardarNorton] = useState([]);
  const [articulos, guardarArticulos] = useState([]);
  const [movimientos, guardarMovimientos] = useState([]);
  const [stock, guardarStock] = useState([]);
  const [evaluaciones, guardarEvaluaciones] = useState([]);
  const [diagnosticosPacientes, guardarDiagnosticosPacientes] = useState([]);
  const [estudiosPacientes, guardarEstudiosPacientes] = useState([]);
  const [chequeos, guardarChequeos] = useState(null);
  const [chequeoBanos, guardarChequeoBanos] = useState(null);
  const [chequeoHabitaciones, guardarChequeoHabitaciones] = useState(null);
  const [chequeoEspacios, guardarChequeoEspacios] = useState(null);
  const [chequeoCocinas, guardarChequeoCocinas] = useState(null);
  const [chequeoEnfermerias, guardarChequeoEnfermerias] = useState(null);
  const [chequeoOficinas, guardarChequeoOficinas] = useState(null);
  const [chequeoLavaderos, guardarChequeoLavaderos] = useState(null);
  const [chequeoElementos, guardarChequeoElementos] = useState(null);
  const [chequeoCumplimientos, guardarChequeoCumplimientos] = useState(null);
  const [formatos, guardarFormatos] = useState([]);
  const [programadas, guardarProgramadas] = useState([]);
  const [horarios, guardarHorarios] = useState([]);
  const [unidades, guardarUnidades] = useState([]);
  const [vias, guardarVias] = useState([]);
  const [planes, guardarPlanes] = useState([]);
  const [dietas, guardarDietas] = useState([]);
  const [practicas, guardarPracticas] = useState([]);
  const [guardadas, guardarGuardadas] = useState([]);
  const [velocidades, guardarVelocidades] = useState([]);

  const enviarCertificadoEmail = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/enviarcertificadoemail",
        datos
      );

      let data = respuesta.data;

      mensajeAlerta("Certificado", data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Certificado", error.response.data, "error");
    }
  };

  // Frases

  const obtenerEvaluacionAnterior = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerevaluacionanterior",
        datos
      );

      let data = respuesta.data;
      guardarVgi(data.vgi);
      guardarEvolucion(data.evolucion);
    } catch (error) {
      guardarVgi(null);
      guardarEvolucion(null);
      console.log(error.response);
      //mensajeAlerta("V.G.I", error.response.data, "error");
    }
  };

  const obtenerFrases = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerfrases",
        datos
      );

      let data = respuesta.data;

      guardarFrases(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerFrasesVgiHc = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerfrasesvgihc",
        datos
      );

      let data = respuesta.data;

      guardarFrases(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerFrasesVGI = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerfrasesvgi",
        datos
      );

      let data = respuesta.data;

      guardarFrasesVGI(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Establecimientos

  const obtenerEstablecimientos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerestablecimientos",
        datos
      );

      let data = respuesta.data;

      guardarEstablecimientos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerEstablecimientosUsuarios = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerestablecimientosusuarios",
        datos
      );

      let data = respuesta.data;
      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i]["establecimientos"].id,
          label: data[i]["establecimientos"].nombre,
          domicilio: data[i]["establecimientos"].domicilio,
          telefono: data[i]["establecimientos"].telefono,
        });
      }

      guardarEstablecimientosUsuarios(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  /*
  const obtenerEstablecimiento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerestablecimiento",
        datos
      );

      let data = respuesta.data;

      guardarEstablecimiento(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Residencias", error.response.data, "error");
    }
  };
  */

  const actualizarEstablecimiento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarestablecimiento",
        datos
      );

      mensajeAlerta("Residencias", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Residencias", error.response.data, "error");
    }
  };

  const agregarEstablecimiento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarestablecimiento",
        datos
      );

      mensajeAlerta("Residencias", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Residencias", error.response.data, "error");
    }
  };

  // Coseguros
  const agregarCoseguros = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarcoseguros",
        datos
      );

      let data = respuesta.data;

      if (data.msg !== undefined) {
        mensajeAlerta("Planilla Mensual", data.msg, "error");
        return;
      }

      mensajeAlerta("Coseguros", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Coseguros", error.response.data, "error");
    }
  };

  // Pendientes
  const obtenerPendientesPlanilla = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerpendientesplanilla",
        datos
      );

      let local = [];

      let data = respuesta.data;

      //console.log(data)

      if (data.msg !== undefined) {
        mensajeAlerta("Pendientes", data.msg, "error");
        return;
      }

      let h = 0;
      data = respuesta.data.responseMensual;
      for (var i = 0; i < data.length; i++) {
        h = h + 1;
        local.push({
          key: h,
          id: data[i].id,
          tipo: "mensual",
          descripcion: "Abono mensual",
          pendiente: data[i].saldo,
        });
      }

      data = respuesta.data.responseCoseguros;
      for (var i = 0; i < data.length; i++) {
        h = h + 1;
        local.push({
          key: h,
          id: data[i].id,
          tipo: "coseguro",
          descripcion: "Coseguro mensual",
          pendiente: data[i].importe,
        });
      }

      data = respuesta.data.responsePanales;
      for (var i = 0; i < data.length; i++) {
        console.log(
          data[i].descripcion !== null && data[i].descripcion !== "null"
        );
        h = h + 1;
        local.push({
          key: h,
          id: data[i].id,
          tipo: "panales",
          descripcion:
            data[i].descripcion !== null
              ? "Pañales - " + data[i].descripcion
              : "Pañales",
          pendiente: data[i].importe,
        });
      }

      data = respuesta.data.responseDescartables;
      for (var i = 0; i < data.length; i++) {
        h = h + 1;
        local.push({
          key: h,
          id: data[i].id,
          tipo: "descartables",
          descripcion:
            data[i].descripcion != null
              ? "Descartables - " + data[i].descripcion
              : "Descartables",
          pendiente: data[i].importe,
        });
      }

      data = respuesta.data.responseEstudios;
      for (var i = 0; i < data.length; i++) {
        h = h + 1;
        local.push({
          key: h,
          id: data[i].id,
          tipo: "estudios",
          descripcion:
            data[i].descripcion != null
              ? "Estudios - " + data[i].descripcion
              : "Estudios",
          pendiente: data[i].importe,
        });
      }

      data = respuesta.data.responseDerivadas;
      for (var i = 0; i < data.length; i++) {
        h = h + 1;
        local.push({
          key: h,
          id: data[i].id,
          tipo: "derivadas",
          descripcion:
            data[i].descripcion != null
              ? "Deuda Anterior - " + data[i].descripcion
              : "Deuda Anterior",
          pendiente: data[i].importe,
        });
      }

      guardarDatosPagos(local);

      //mensajeAlerta("Pendientes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta("Pendientes", error.response.data, "error");
    }
  };

  const generarPagoPendiente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/generarpagopendiente",
        datos
      );

      //let data = respuesta.data;

      mensajeAlerta("Pagos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Coseguros", error.response.data, "error");
    }
  };

  //Pacientes

  const obtenerPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerpacientes",
        datos
      );

      let data = respuesta.data;

      if (datos.planilla && datos.planilla === true) {
        let local = [];
        for (var i = 0; i < data.length; i++) {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
            numeroDocumento: data[i].numeroDocumento,
            obraSocialContrato:
              data[i].obrassociales !== null
                ? data[i].obrassociales.nombre
                : null,
            arancel:
              data[i].obrassociales !== null && data[i].obrassociales.id !== 2
                ? data[i].obrassociales.arancel
                : data[i].arancel,
            coseguroId: data[i].coseguroId ? data[i].coseguroId : null,
            panalId: data[i].panalId ? data[i].panalId : null,
            descartableId: data[i].descartableId ? data[i].descartableId : null,
            ingreso: data[i].fechaIngresoEgreso,
            iva: data[i].iva ? data[i].iva : null,
          });
        }
        guardarPacientes(local);
      } else {
        guardarPacientes(data);
      }
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerPaciente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerpaciente",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      guardarPaciente(data);
      setSelectedRowPaciente(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Pacientes", error.response.data, "error");
    }
  };

  const actualizarPaciente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarpaciente",
        datos
      );

      mensajeAlerta("Pacientes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Pacientes", error.response.data, "error");
    }
  };

  const actualizarPacienteIngresoEgreso = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarpacienteingresoegreso",
        datos
      );

      mensajeAlerta("Pacientes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Pacientes", error.response.data, "error");
    }
  };

  const agregarPaciente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarpaciente",
        datos
      );

      mensajeAlerta("Pacientes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Pacientes", error.response.data, "error");
    }
  };

  const listadoPacientes = async (datos) => {
    const calcularEdad = (fecha) => {
      var year = Number(fecha.substr(0, 4));
      var month = Number(fecha.substr(5, 2)) - 1;
      var day = Number(fecha.substr(8, 2));

      var today = new Date();
      var age = today.getFullYear() - year;
      if (
        today.getMonth() < month ||
        (today.getMonth() == month && today.getDate() < day)
      ) {
        age--;
      }
      return age;
    };

    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/listadopacientes",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      let local = [];

      if (
        datos.listado.camposSeleccionados === undefined ||
        datos.listado.camposSeleccionados === 0
      ) {
        for (var i = 0; i < data.length; i++) {
          local.push({
            enum: i + 1,
            id: data[i].numeroObraSocial,
            fechaIngresoEgreso: data[i].fechaIngresoEgreso
              ? formatDate2(data[i].fechaIngresoEgreso)
              : null,
            motivoIngresoEgreso: data[i].motivoIngresoEgreso,
            activo: data[i].activo ? "SI" : "NO",
            nombre: data[i].nombre,
            domicilio: data[i].domicilio,
            cp: data[i].cp,
            localidad:
              data[i].localidades !== null ? data[i].localidades.nombre : null,
            numeroDocumento: data[i].numeroDocumento,
            fechaNacimiento: data[i].fechaNacimiento
              ? formatDate2(data[i].fechaNacimiento)
              : null,
            lugarNacimiento: data[i].lugarNacimiento,
            estadoCivil: data[i].estadoCivil,
            edad: calcularEdad(data[i].fechaNacimiento),
            telefono: data[i].telefono,
            obraSocial:
              data[i].obrassocialesmedicas !== null
                ? data[i].obrassocialesmedicas.nombre
                : null,
            numeroObraSocial: data[i].numeroObraSocial,
            clinicaInternacion: data[i].clinicaInternacion,
            nombreRepresentante: data[i].nombreRepresentante,
            parentezco: data[i].parentezco,
            domicilioRepresentante: data[i].domicilioRepresentante,
            cpRepresentante: data[i].cpRepresentante,
            localidadRepresentante:
              data[i].localidadesrepresentantes !== null
                ? data[i].localidadesrepresentantes.nombre
                : null,
            telefonoRepresentante: data[i].telefonoRepresentante,
            email: data[i].email,
            emailAlternativo: data[i].emailAlternativo,
            telefonoComunicaciones: data[i].telefonoComunicaciones,
            whatsApp: data[i].whatsApp,
            telefonoUrgencias: data[i].telefonoUrgencias,
            arancel: data[i].arancel,
            coseguro: data[i].coseguro,
            geriatrico:
              data[i].establecimientos !== null
                ? data[i].establecimientos.nombre
                : null,
            fechaPago:
              data[i].fechaPago && data[i].fechaPago === 1
                ? "01 a 15"
                : data[i].fechaPago === 2
                ? "16 a 31"
                : null,
            obraSocialContrato:
              data[i].obrassociales !== null
                ? data[i].obrassociales.nombre
                : null,
            capita: data[i].capita,
            vacio: "              ",
            /*    
          diagnostico:
            data[i].pacientes.diagnosticos !== null
              ? data[i].pacientes.diagnosticos.nombre
              : null,
          */
          });
        }
      } else {
        for (var i = 0; i < data.length; i++) {
          local.push({
            enum: i + 1,
            id:
              datos.listado.camposSeleccionados.findIndex(
                (elem) => elem.id === 1
              ) !== -1
                ? data[i].numeroObraSocial
                : null,
            nombre:
              datos.listado.camposSeleccionados.findIndex(
                (elem) => elem.id === 2
              ) !== -1
                ? data[i].nombre
                : null,
            obraSocial:
              datos.listado.camposSeleccionados.findIndex(
                (elem) => elem.id === 3
              ) !== -1 && data[i].obrassocialesmedicas !== null
                ? data[i].obrassocialesmedicas.nombre
                : null,

            obraSocialContrato:
              datos.listado.camposSeleccionados.findIndex(
                (elem) => elem.id === 4
              ) !== -1 && data[i].obrassociales !== null
                ? data[i].obrassociales.nombre
                : null,
            edad:
              datos.listado.camposSeleccionados.findIndex(
                (elem) => elem.id === 5
              ) !== -1
                ? calcularEdad(data[i].fechaNacimiento)
                : null,
            numeroDocumento:
              datos.listado.camposSeleccionados.findIndex(
                (elem) => elem.id === 6
              ) !== -1
                ? data[i].numeroDocumento
                : null,
            fechaNacimiento:
              datos.listado.camposSeleccionados.findIndex(
                (elem) => elem.id === 7
              ) !== -1 && data[i].fechaNacimiento
                ? formatDate2(data[i].fechaNacimiento)
                : null,

            fechaIngresoEgreso:
              datos.listado.camposSeleccionados.findIndex(
                (elem) => elem.id === 8
              ) !== -1 && data[i].fechaIngresoEgreso
                ? formatDate2(data[i].fechaIngresoEgreso)
                : null,

            motivoIngresoEgreso:
              datos.listado.camposSeleccionados.findIndex(
                (elem) => elem.id === 9
              ) !== -1
                ? data[i].motivoIngresoEgreso
                : null,

            capita:
              datos.listado.camposSeleccionados.findIndex(
                (elem) => elem.id === 10
              ) !== -1
                ? data[i].capita
                : null,

            nombreRepresentante:
              datos.listado.camposSeleccionados.findIndex(
                (elem) => elem.id === 11
              ) !== -1
                ? data[i].nombreRepresentante
                : null,

            telefonoRepresentante:
              datos.listado.camposSeleccionados.findIndex(
                (elem) => elem.id === 12
              ) !== -1
                ? data[i].telefonoRepresentante
                : null,
            email:
              datos.listado.camposSeleccionados.findIndex(
                (elem) => elem.id === 13
              ) !== -1
                ? data[i].email
                : null,
            numeroObraSocial:
              datos.listado.camposSeleccionados.findIndex(
                (elem) => elem.id === 14
              ) !== -1
                ? data[i].numeroObraSocial
                : null,
            telefonoUrgencias:
              datos.listado.camposSeleccionados.findIndex(
                (elem) => elem.id === 15
              ) !== -1
                ? data[i].telefonoUrgencias
                : null,

            vacio: "              ",
          });
        }
      }

      guardarPacientes(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Listados", error.response.data, "error");
    }
  };

  const listadoPacientesDiabeticos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/listadopacientesdiabeticos",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          //id: data[i].id,
          fecha: data[i].fecha,
          //pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          //numeroDocumento: data[i].pacientes.numeroDocumento,
          tipo: data[i].tipo,
          medicacion: data[i].medicacion,
          valor: data[i].valor,
          control: data[i].control,
        });
      }

      guardarPacientes(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Listados", error.response.data, "error");
    }
  };

  const listadoPacientesEvaluaciones = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/listadopacientesevaluaciones",
        datos
      );

      let data = respuesta.data.response;
      let dataMedicaciones = respuesta.data.responseMedicaciones;
      let dataDiagnosticos = respuesta.data.responseDiagnosticos;
      let dataIngresosEgresos = respuesta.data.responseIngresosEgresos;

      let local = [];

      /*
      for (var i = 0; i < data.length; i++) {
        local.push({
          //id: data[i].id,
          fecha: data[i].fecha,
          //pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          //numeroDocumento: data[i].pacientes.numeroDocumento,
        });
      }
      */

      guardarEvaluaciones(data);
      guardarMedicacionesPacientes(dataMedicaciones);
      guardarDiagnosticosPacientes(dataDiagnosticos);
      guardarIngresosEgresos(dataIngresosEgresos);

      let dataBarthels = respuesta.data.responseBarthel;
      if (dataBarthels === null) {
        dataBarthels = [];
      }
      guardarBarthel(dataBarthels);

      let dataRelojes = respuesta.data.responseReloj;
      if (dataRelojes === null) {
        dataRelojes = [];
      }
      guardarReloj(dataRelojes);

      let dataTinettis = respuesta.data.responseTinetti;
      if (dataTinettis === null) {
        dataTinettis = [];
      }
      guardarTinetti(dataTinettis);

      let dataLawtonBrody = respuesta.data.responseLawtonBrody;
      if (dataLawtonBrody === null) {
        dataLawtonBrody = [];
      }
      guardarLawtonBrody(dataLawtonBrody);

      let dataEscalaValoracion = respuesta.data.responseEscalaValoracion;
      if (dataEscalaValoracion === null) {
        dataEscalaValoracion = [];
      }
      guardarEscalaValoracionMental(dataEscalaValoracion);

      let dataYesavage = respuesta.data.responseYesavage;
      if (dataYesavage === null) {
        dataYesavage = [];
      }
      guardarYesavage(dataYesavage);

      let dataGoldberg = respuesta.data.responseGoldberg;
      if (dataGoldberg === null) {
        dataGoldberg = [];
      }
      guardarGoldberg(dataGoldberg);

      let dataEvaluacion = respuesta.data.responseEvaluacion;
      if (dataEvaluacion === null) {
        dataEvaluacion = [];
      }
      guardarEvaluacionNutricional(dataEvaluacion);

      let dataFragilidad = respuesta.data.responseFragilidad;
      if (dataFragilidad === null) {
        dataFragilidad = [];
      }
      guardarFragilidad(dataFragilidad);

      let dataNortons = respuesta.data.responseNorton;
      if (dataNortons === null) {
        dataNortons = [];
      }
      guardarNorton(dataNortons);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Listados", error.response.data, "error");
    }
  };

  const listadoPacientesAnticoagulados = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/listadopacientesanticoagulados",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          //id: data[i].id,
          fecha: data[i].fecha,
          //pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          //numeroDocumento: data[i].pacientes.numeroDocumento,
          tipo: data[i].tipo,
          medicacion: data[i].medicacion,
          valor: data[i].valor,
          control: data[i].control,
        });
      }

      guardarPacientes(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Listados", error.response.data, "error");
    }
  };

  const listadoPacientesCaidas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/listadopacientescaidas",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          //id: data[i].id,
          fecha: data[i].fecha,
          //pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          //numeroDocumento: data[i].pacientes.numeroDocumento,
          consecuencias: data[i].consecuencias,
          lugar: data[i].lugar,
          tratamiento: data[i].tratamiento,
        });
      }

      guardarPacientes(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Listados", error.response.data, "error");
    }
  };

  //Movimientos

  const agregarMovimiento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarmovimiento",
        datos
      );

      mensajeAlerta("Movimientos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Movimientos", error.response.data, "error");
    }
  };

  const agregarMovimientoAjuste = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarmovimientoajuste",
        datos
      );

      mensajeAlerta("Movimientos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Movimientos", error.response.data, "error");
    }
  };

  const obtenerMovimientos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenermovimientos",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          fecha: formatDate(data[i].fecha),
          descripcion: data[i].articulos.descripcion,
          tipoMovimiento: data[i].tipoMovimiento,
          detalle: data[i].detalle,
          cantidad: data[i].cantidad,
        });
      }
      guardarMovimientos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Movimientos", error.response.data, "error");
    }
  };

  //Articulos

  const actualizarArticulo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizararticulo",
        datos
      );

      mensajeAlerta("Artículos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Artículos", error.response.data, "error");
    }
  };

  const agregarArticulo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregararticulo",
        datos
      );

      mensajeAlerta("Artículos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Artículos", error.response.data, "error");
    }
  };

  const obtenerArticulos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerarticulos",
        datos
      );

      let data = respuesta.data;

      guardarArticulos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Artículos", error.response.data, "error");
    }
  };

  //Patologias

  const obtenerPatologias = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerpatologias",
        datos
      );

      let data = respuesta.data;

      guardarPatologias(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Diagnósticos", error.response.data, "error");
    }
  };

  const obtenerPatologiasPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerpatologias",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          label: data[i].nombre,
        });
      }

      guardarPatologias(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Diagnósticos", error.response.data, "error");
    }
  };

  const actualizarPatologia = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarpatologia",
        datos
      );

      mensajeAlerta("Patologías", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Patologías", error.response.data, "error");
    }
  };

  const agregarPatologia = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarpatologia",
        datos
      );

      mensajeAlerta("Patologías", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Patologías", error.response.data, "error");
    }
  };

  //Clinicas

  const obtenerClinicas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerclinicas",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          nombre: data[i].nombre,
          domicilio: data[i].domicilio,
          localidadId: data[i].localidadId,
          localidad: data[i].localidades.nombre,
          habilitado: data[i].habilitado,
        });
      }
      guardarClinicas(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Residencias", error.response.data, "error");
    }
  };

  const obtenerClinicasPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerclinicas",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          label: data[i].nombre,
          domicilio: data[i].domicilio,
          localidadId: data[i].localidadId,
          localidad: data[i].localidades.nombre,
          habilitado: data[i].habilitado,
        });
      }

      guardarClinicas(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Residencias", error.response.data, "error");
    }
  };

  const actualizarClinica = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarclinica",
        datos
      );

      mensajeAlerta("Clínicas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Clínicas", error.response.data, "error");
    }
  };

  const agregarClinica = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarclinica",
        datos
      );

      mensajeAlerta("Clínicas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Clínicas", error.response.data, "error");
    }
  };

  //Medicaciones

  const actualizarProgramada = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarprogramada",
        datos
      );

      mensajeAlerta("Medicación", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Medicación", error.response.data, "error");
    }
  };

  const agregarProgramada = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarprogramada",
        datos
      );

      mensajeAlerta("Medicación", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Medicación", error.response.data, "error");
    }
  };

  const actualizarHorario = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarhorario",
        datos
      );

      mensajeAlerta("Horarios", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Horarios", error.response.data, "error");
    }
  };

  const agregarHorario = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarhorario",
        datos
      );

      mensajeAlerta("Horarios", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Horarios", error.response.data, "error");
    }
  };

  const eliminarHorario = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/eliminarhorario",
        datos
      );

      mensajeAlerta("Horarios", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Horarios", error.response.data, "error");
    }
  };

  const obtenerProgramadas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerprogramadas",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        let horarios = "";
        for (var j = 0; j < data[i].horariosprogramados.length; j++) {
          if (horarios === "") {
            horarios = data[i].horariosprogramados[j].hora;
          } else {
            horarios = horarios + " - " + data[i].horariosprogramados[j].hora;
          }
        }

        local.push({
          id: data[i].id,
          inicio: formatDate(data[i].inicio),
          final: data[i].final ? formatDate(data[i].final) : null,
          medicacionId: data[i].medicacionId,
          nombre: data[i].medicaciones.nombre,
          activo: data[i].activo,
          horarios: horarios,
          horariosprogramados: data[i].horariosprogramados,
        });
      }
      guardarProgramadas(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Control de Medicación", error.response.data, "error");
    }
  };

  const obtenerHorarios = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerhorarios",
        datos
      );

      let data = respuesta.data;

      guardarHorarios(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarFormato = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarformato",
        datos
      );

      mensajeAlerta("Formatos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Formatos", error.response.data, "error");
    }
  };

  const agregarFormato = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarformato",
        datos
      );

      mensajeAlerta("Formatos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Formatos", error.response.data, "error");
    }
  };

  const eliminarFormato = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/eliminarformato",
        datos
      );

      mensajeAlerta("Formatos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Formatos", error.response.data, "error");
    }
  };

  const obtenerFormatos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerformatos",
        datos
      );

      let data = respuesta.data;

      guardarFormatos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerUnidades = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerunidades"
      );

      let data = respuesta.data;

      guardarUnidades(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarUnidad = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarunidad",
        datos
      );

      mensajeAlerta("Unidades", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Unidades", error.response.data, "error");
    }
  };

  const agregarUnidad = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarunidad",
        datos
      );

      mensajeAlerta("Unidades", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Unidades", error.response.data, "error");
    }
  };

  const obtenerMedicaciones = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenermedicaciones",
        datos
      );

      let data = respuesta.data;

      guardarMedicaciones(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Medicación", error.response.data, "error");
    }
  };

  const obtenerMedicacionesPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenermedicaciones",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          label: data[i].nombre,
        });
      }

      guardarMedicaciones(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Medicación", error.response.data, "error");
    }
  };

  const listadoMedicacionesActivas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/listadomedicacionesactivas",
        datos
      );

      let data = respuesta.data;
      let data2 = respuesta.data;
      let local = [];
      let local2 = [];
      let local3 = [];
      let pacId = null;

      for (var i = 0; i < data.length; i++) {
        if (data[i].pacienteId !== pacId) {
          pacId = data[i].pacienteId;
          let index = data.findIndex(
            (elem) => elem.pacienteId === pacId && elem.activo
          );
          if (index !== -1) {
            for (var j = 0; j < data2.length; j++) {
              if (data2[j].pacienteId === pacId) {
                if (
                  data2[j].medicaciones.tipoMedicacionId === 1 &&
                  data2[j].activo
                ) {
                  let total = 0;
                  if (
                    data2[j].cantidadHora1 &&
                    Number(data2[j].cantidadHora1) > 0
                  ) {
                    total = total + Number(data2[j].cantidadHora1);
                  }
                  if (
                    data2[j].cantidadHora2 &&
                    Number(data2[j].cantidadHora2) > 0
                  ) {
                    total = total + Number(data2[j].cantidadHora2);
                  }
                  if (data2[j].d && Number(data2[j].d) > 0) {
                    total = total + Number(data2[j].d);
                  }
                  if (data2[j].a && Number(data2[j].a) > 0) {
                    total = total + Number(data2[j].a);
                  }
                  if (data2[j].m && Number(data2[j].m) > 0) {
                    total = total + Number(data2[j].m);
                  }
                  if (data2[j].c && Number(data2[j].c) > 0) {
                    total = total + Number(data2[j].c);
                  }

                  local.push({
                    id: data2[j].medicaciones.id,
                    id2: data2[j].id,
                    pacienteId: data2[j].pacienteId,
                    label: data2[j].medicaciones.nombre,
                    inicio: data2[j].inicio,
                    final: data2[j].final,
                    activo: data2[j].activo,
                    dosis: data2[j].dosis,
                    viaId: data2[j].viaId,
                    velocidadId: data2[j].velocidadId,
                    necesidad: data2[j].necesidad,
                    dias: data2[j].dias,
                    via: data2[j].vias ? data2[j].vias.nombre : null,
                    velocidad: data2[j].velocidades
                      ? data2[j].velocidades.nombre
                      : null,
                    hora1: data2[j].hora1 ? data2[j].hora1 : null,
                    headerHora1:
                      Number(data2[j].cantidadHora1) > 0
                        ? data2[j].hora1.substring(0, 5) +
                          "(" +
                          data2[j].cantidadHora1 +
                          ")"
                        : null,
                    cantidadHora1:
                      Number(data2[j].cantidadHora1) > 0
                        ? data2[j].cantidadHora1
                        : 0,
                    d: Number(data2[j].d) > 0 ? data2[j].d : 0,
                    a: Number(data2[j].a) > 0 ? data2[j].a : 0,
                    m: Number(data2[j].m) > 0 ? data2[j].m : 0,
                    c: Number(data2[j].c) > 0 ? data2[j].c : 0,
                    hora2: data2[j].hora2 ? data2[j].hora2 : null,
                    headerHora2:
                      Number(data2[j].cantidadHora2) > 0
                        ? data2[j].hora2.substring(0, 5) +
                          "(" +
                          data2[j].cantidadHora2 +
                          ")"
                        : null,
                    cantidadHora2:
                      Number(data2[j].cantidadHora2) > 0
                        ? data2[j].cantidadHora2
                        : 0,
                    cantidad: total,
                    pacientes: data2[j].pacientes,
                  });
                }
                if (data2[j].medicaciones.tipoMedicacionId === 2) {
                  local2.push({
                    id: data2[j].id,
                    pacienteId: data2[j].pacienteId,
                    label: data2[j].medicaciones.nombre,
                    pacientes: data2[j].pacientes,
                  });
                }
                if (data2[j].medicaciones.tipoMedicacionId === 3) {
                  local3.push({
                    id: data2[j].id,
                    pacienteId: data2[j].pacienteId,
                    label: data2[j].medicaciones.nombre,
                    pacientes: data2[j].pacientes,
                  });
                }
              }
            }
          } else {
            for (var j = 0; j < data2.length; j++) {
              if (data2[j].pacienteId === pacId) {
                if (data2[j].medicaciones.tipoMedicacionId === 2) {
                  local2.push({
                    id: data2[j].id,
                    pacienteId: data2[j].pacienteId,
                    label: data2[j].medicaciones.nombre,
                    pacientes: data2[j].pacientes,
                  });
                }
                if (data2[j].medicaciones.tipoMedicacionId === 3) {
                  local3.push({
                    id: data2[j].id,
                    pacienteId: data2[j].pacienteId,
                    label: data2[j].medicaciones.nombre,
                    pacientes: data2[j].pacientes,
                  });
                }
              }
            }
          }
        }
      }

      guardarMedicacionesPacientes(local);
      guardarDietas(local2);
      guardarPracticas(local3);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Medicación", error.response.data, "error");
    }
  };

  const groupBy = (prop) => (data) => {
    return data.reduce((dict, item) => {
      const { [prop]: _, ...rest } = item;
      dict[item[prop]] = [...(dict[item[prop]] || []), rest];
      return dict;
    }, {});
  };

  const listadoTotalesMedicamentos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/listadototalesmedicamentos",
        datos
      );

      let data = respuesta.data;
      let local = [];
      let dats = [];

      const result = Object.entries(groupBy("medicacionId")(data)).map(
        ([key, value]) => ({ id: key, datos: value })
      );

      for (var i = 0; i < result.length; i++) {
        dats = result[i].datos;

        if (result[i].datos.length > 1) {
          dats.sort(function (a, b) {
            if (a.dosis > b.dosis) {
              return 1;
            }
            if (a.dosis < b.dosis) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        }

        let droga = dats[0].medicaciones.nombre;
        let total = 0;
        let dos = null;
        for (var j = 0; j < dats.length; j++) {
          if (dos !== dats[j].dosis) {
            if (j !== 0) {
              local.push({
                nombre: droga,
                dosis: dos,
                cantidad: total,
              });
            }

            dos = dats[j].dosis;
            total = 0;

            if (dats[j].cantidadHora1 && Number(dats[j].cantidadHora1) > 0) {
              total = total + Number(dats[j].cantidadHora1);
            }
            if (dats[j].cantidadHora2 && Number(dats[j].cantidadHora2) > 0) {
              total = total + Number(dats[j].cantidadHora2);
            }
            if (dats[j].d && Number(dats[j].d) > 0) {
              total = total + Number(dats[j].d);
            }
            if (dats[j].a && Number(dats[j].a) > 0) {
              total = total + Number(dats[j].a);
            }
            if (dats[j].m && Number(dats[j].m) > 0) {
              total = total + Number(dats[j].m);
            }
            if (dats[j].c && Number(dats[j].c) > 0) {
              total = total + Number(dats[j].c);
            }
          } else {
            if (dats[j].cantidadHora1 && Number(dats[j].cantidadHora1) > 0) {
              total = total + Number(dats[j].cantidadHora1);
            }
            if (dats[j].cantidadHora2 && Number(dats[j].cantidadHora2) > 0) {
              total = total + Number(dats[j].cantidadHora2);
            }
            if (dats[j].d && Number(dats[j].d) > 0) {
              total = total + Number(dats[j].d);
            }
            if (dats[j].a && Number(dats[j].a) > 0) {
              total = total + Number(dats[j].a);
            }
            if (dats[j].m && Number(dats[j].m) > 0) {
              total = total + Number(dats[j].m);
            }
            if (dats[j].c && Number(dats[j].c) > 0) {
              total = total + Number(dats[j].c);
            }
          }
        }

        local.push({
          nombre: droga,
          dosis: dos,
          cantidad: total,
        });
      }

      guardarMedicacionesPacientes(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Medicación", error.response.data, "error");
    }
  };

  const actualizarMedicacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarmedicacion",
        datos
      );

      mensajeAlerta("Medicaciones", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Medicaciones", error.response.data, "error");
    }
  };

  const agregarMedicacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarmedicacion",
        datos
      );

      mensajeAlerta("Medicaciones", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Medicaciones", error.response.data, "error");
    }
  };

  const obtenerMedicacionesPacientesIndividual = async (datos) => {
    const { tipo } = datos;

    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenermedicacionespacientes",
        datos
      );

      let data = respuesta.data;

      let local = [];
      let dieta = [];
      let practica = [];

      for (var i = 0; i < data.length; i++) {
        if (tipo === "M") {
          if (data[i].medicaciones.tipoMedicacionId === 1) {
            let total = 0;
            if (data[i].cantidadHora1 && Number(data[i].cantidadHora1) > 0) {
              total = total + Number(data[i].cantidadHora1);
            }
            if (data[i].cantidadHora2 && Number(data[i].cantidadHora2) > 0) {
              total = total + Number(data[i].cantidadHora2);
            }
            if (data[i].d && Number(data[i].d) > 0) {
              total = total + Number(data[i].d);
            }
            if (data[i].a && Number(data[i].a) > 0) {
              total = total + Number(data[i].a);
            }
            if (data[i].m && Number(data[i].m) > 0) {
              total = total + Number(data[i].m);
            }
            if (data[i].c && Number(data[i].c) > 0) {
              total = total + Number(data[i].c);
            }

            let diasId = [];
            if (data[i].dias && data[i].dias !== "") {
              for (var j = 0; j < data[i].dias.length; j++) {
                switch (data[i].dias[j]) {
                  case "L":
                    diasId.push({
                      id: 1,
                      nombre: "L",
                    });
                    break;
                  case "M":
                    diasId.push({
                      id: 2,
                      nombre: "M",
                    });
                    break;
                  case "X":
                    diasId.push({
                      id: 3,
                      nombre: "X",
                    });
                    break;
                  case "J":
                    diasId.push({
                      id: 4,
                      nombre: "J",
                    });
                    break;
                  case "V":
                    diasId.push({
                      id: 5,
                      nombre: "V",
                    });
                    break;
                  case "S":
                    diasId.push({
                      id: 6,
                      nombre: "S",
                    });
                    break;
                  case "D":
                    diasId.push({
                      id: 7,
                      nombre: "D",
                    });
                    break;
                }
              }
            }

            local.push({
              id: data[i].medicaciones.id,
              id2: data[i].id,
              label: data[i].medicaciones.nombre,
              inicio: data[i].inicio,
              final: data[i].final,
              activo: data[i].activo,
              dosis: data[i].dosis,
              viaId: data[i].viaId,
              via: data[i].vias ? data[i].vias.nombre : null,
              velocidadId: data[i].velocidadId,
              velocidad: data[i].velocidades
                ? data[i].velocidades.nombre
                : null,
              necesidad: data[i].necesidad,
              diasId,
              dias: data[i].dias ? data[i].dias : null,
              hora1: data[i].hora1 ? data[i].hora1 : null,
              headerHora1:
                Number(data[i].cantidadHora1) > 0
                  ? data[i].hora1.substring(0, 5) +
                    "(" +
                    data[i].cantidadHora1 +
                    ")"
                  : null,
              cantidadHora1:
                Number(data[i].cantidadHora1) > 0 ? data[i].cantidadHora1 : 0,
              d: Number(data[i].d) > 0 ? data[i].d : 0,
              a: Number(data[i].a) > 0 ? data[i].a : 0,
              m: Number(data[i].m) > 0 ? data[i].m : 0,
              c: Number(data[i].c) > 0 ? data[i].c : 0,
              hora2: data[i].hora2 ? data[i].hora2 : null,
              headerHora2:
                Number(data[i].cantidadHora2) > 0
                  ? data[i].hora2.substring(0, 5) +
                    "(" +
                    data[i].cantidadHora2 +
                    ")"
                  : null,
              cantidadHora2:
                Number(data[i].cantidadHora2) > 0 ? data[i].cantidadHora2 : 0,
              cantidad: total,
            });
          }
          if (data[i].medicaciones.tipoMedicacionId === 2) {
            dieta.push({
              id: data[i].medicaciones.id,
              label: data[i].medicaciones.nombre,
            });
          }
          if (data[i].medicaciones.tipoMedicacionId === 3) {
            practica.push({
              id: data[i].medicaciones.id,
              label: data[i].medicaciones.nombre,
            });
          }
        } else {
          local.push({
            id: data[i].medicaciones.id,
            label: data[i].medicaciones.nombre,
          });
        }
      }

      //console.log(local)

      guardarMedicacionesPacientes(local);
      guardarDietas(dieta);
      guardarPracticas(practica);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta("Ingresos/Egresos", error.response.data, "error");
    }
  };

  //Vias

  const obtenerVias = async () => {
    try {
      const respuesta = await clienteAxios.post("/api/servicios/obtenervias");

      let data = respuesta.data;

      guardarVias(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarVia = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarvia",
        datos
      );

      mensajeAlerta("Vías", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Vías", error.response.data, "error");
    }
  };

  const agregarVia = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarvia",
        datos
      );

      mensajeAlerta("Vías", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Vías", error.response.data, "error");
    }
  };

  //Velocidades

  const obtenerVelocidades = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenervelocidades"
      );

      let data = respuesta.data;

      guardarVelocidades(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarVelocidad = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarvelocidad",
        datos
      );

      mensajeAlerta("Velocidades", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Velocidades", error.response.data, "error");
    }
  };

  const agregarVelocidad = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarvelocidad",
        datos
      );

      mensajeAlerta("Velocidades", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Velocidades", error.response.data, "error");
    }
  };

  //Planes

  const obtenerPlanes = async () => {
    try {
      const respuesta = await clienteAxios.post("/api/servicios/obtenerplanes");

      let data = respuesta.data;

      guardarPlanes(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarPlan = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarplan",
        datos
      );

      mensajeAlerta("Planes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Planes", error.response.data, "error");
    }
  };

  const agregarPlan = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarplan",
        datos
      );

      mensajeAlerta("Planes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Planes", error.response.data, "error");
    }
  };

  //Notas

  const obtenerNotas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenernotas",
        datos
      );

      let data = respuesta.data;

      guardarNotas(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerNotasPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenernotas",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          label: data[i].asunto,
        });
      }

      guardarNotas(local);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarNota = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarnota",
        datos
      );

      mensajeAlerta("Notas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Notas", error.response.data, "error");
    }
  };

  const agregarNota = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarnota",
        datos
      );

      mensajeAlerta("Notas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Notas", error.response.data, "error");
    }
  };

  //Vinculos

  const obtenerVinculos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenervinculos",
        datos
      );

      let data = respuesta.data;

      guardarVinculos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerVinculosPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenervinculos",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          label: data[i].nombre,
        });
      }

      guardarVinculos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarVinculo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarvinculo",
        datos
      );

      mensajeAlerta("Vínculos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Vínculos", error.response.data, "error");
    }
  };

  const agregarVinculo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarvinculo",
        datos
      );

      mensajeAlerta("Vínculos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Vínculos", error.response.data, "error");
    }
  };

  //Coseguros

  const obtenerCoseguros = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenercoseguros",
        datos
      );

      let data = respuesta.data;

      guardarCoseguros(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerCosegurosPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenercoseguros",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          label: data[i].nombre,
          valor: data[i].valor,
        });
      }

      guardarCoseguros(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarCoseguro = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarcoseguro",
        datos
      );

      mensajeAlerta("Coseguros", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Coseguros", error.response.data, "error");
    }
  };

  const agregarCoseguro = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarcoseguro",
        datos
      );

      mensajeAlerta("Coseguros", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Coseguros", error.response.data, "error");
    }
  };

  //Descartables

  const obtenerDescartables = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerdescartables",
        datos
      );

      let data = respuesta.data;

      guardarDescartables(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerDescartablesPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerdescartables",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          label: data[i].nombre,
          valor: data[i].valor,
        });
      }

      guardarDescartables(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarDescartable = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizardescartable",
        datos
      );

      mensajeAlerta("Descartables", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Descartables", error.response.data, "error");
    }
  };

  const agregarDescartable = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregardescartable",
        datos
      );

      mensajeAlerta("Descartables", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Descartables", error.response.data, "error");
    }
  };

  //Pañales

  const obtenerPanales = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerpanales",
        datos
      );

      let data = respuesta.data;

      guardarPanales(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerPanalesPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerpanales",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          label: data[i].nombre,
          valor: data[i].valor,
        });
      }

      guardarPanales(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarPanal = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarpanal",
        datos
      );

      mensajeAlerta("Pañales", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Pañales", error.response.data, "error");
    }
  };

  const agregarPanal = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarpanal",
        datos
      );

      mensajeAlerta("Pañales", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Pañales", error.response.data, "error");
    }
  };

  //Localidades

  const obtenerLocalidades = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerlocalidades",
        datos
      );

      let data = respuesta.data;

      guardarLocalidades(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerLocalidadesPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerlocalidades",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          label: data[i].nombre,
        });
      }

      guardarLocalidades(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  /*
  const obtenerLocalidad = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerlocalidad",
        datos
      );

      let data = respuesta.data;

      guardarLocalidad(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Localidades", error.response.data, "error");
    }
  };
  */

  const actualizarLocalidad = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarlocalidad",
        datos
      );

      mensajeAlerta("Localidades", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Localidades", error.response.data, "error");
    }
  };

  const agregarLocalidad = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarlocalidad",
        datos
      );

      mensajeAlerta("Localidades", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Localidades", error.response.data, "error");
    }
  };

  //Chequeos

  const obtenerChequeos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerchequeos",
        datos
      );

      let data = respuesta.data;

      guardarChequeos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerChequeo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerchequeo",
        datos
      );

      let dataBano = respuesta.data.responseBano;
      let dataHabitacion = respuesta.data.responseHabitacion;
      let dataEspacio = respuesta.data.responseEspacio;
      let dataCocina = respuesta.data.responseCocina;
      let dataEnfermeria = respuesta.data.responseEnfermeria;
      let dataOficina = respuesta.data.responseOficina;
      let dataLavadero = respuesta.data.responseLavadero;
      let dataElemento = respuesta.data.responseElemento;
      let dataCumplimiento = respuesta.data.responseCumplimiento;

      guardarChequeoBanos(dataBano);
      guardarChequeoHabitaciones(dataHabitacion);
      guardarChequeoEspacios(dataEspacio);
      guardarChequeoCocinas(dataCocina);
      guardarChequeoEnfermerias(dataEnfermeria);
      guardarChequeoOficinas(dataOficina);
      guardarChequeoLavaderos(dataLavadero);
      guardarChequeoElementos(dataElemento);
      guardarChequeoCumplimientos(dataCumplimiento);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarChequeo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarchequeo",
        datos
      );

      mensajeAlerta("Chequeos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Chequeos", error.response.data, "error");
    }
  };

  const agregarChequeo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarchequeo",
        datos
      );

      mensajeAlerta("Chequeos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Chequeos", error.response.data, "error");
    }
  };

  //Obras Sociales

  const obtenerObrasSociales = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerobrassociales",
        datos
      );

      let data = respuesta.data;

      guardarObrasSociales(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerObrasSocialesPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerobrassociales",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          label: data[i].nombre,
        });
      }

      guardarObrasSociales(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  /*
  const obtenerObraSocial = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerobrasocial",
        datos
      );

      let data = respuesta.data;

      guardarObraSocial(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Obras Sociales", error.response.data, "error");
    }
  };
  */

  const actualizarObraSocial = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarobrasocial",
        datos
      );

      mensajeAlerta("Obras Sociales", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Obras Sociales", error.response.data, "error");
    }
  };

  const agregarObraSocial = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarobrasocial",
        datos
      );

      mensajeAlerta("Obras Sociales", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Obras Sociales", error.response.data, "error");
    }
  };

  //Obras Sociales Medicas

  const obtenerObrasSocialesMedicas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerobrassocialesmedicas",
        datos
      );

      let data = respuesta.data;

      guardarObrasSocialesMedicas(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerObrasSocialesMedicasPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerobrassocialesmedicas",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          label: data[i].nombre,
        });
      }

      guardarObrasSocialesMedicas(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  /*
  const obtenerObraSocial = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerobrasocial",
        datos
      );

      let data = respuesta.data;

      guardarObraSocial(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Obras Sociales", error.response.data, "error");
    }
  };
  */

  const actualizarObraSocialMedica = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarobrasocialmedica",
        datos
      );

      mensajeAlerta("Obras Sociales", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Obras Sociales", error.response.data, "error");
    }
  };

  const agregarObraSocialMedica = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarobrasocialmedica",
        datos
      );

      mensajeAlerta("Obras Sociales", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Obras Sociales", error.response.data, "error");
    }
  };

  //Diagnosticos

  const obtenerDiagnosticos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerdiagnosticos",
        datos
      );

      let data = respuesta.data;

      guardarDiagnosticos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerDiagnosticosPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerdiagnosticos",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          label: data[i].nombre,
        });
      }

      guardarDiagnosticos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  /*
  const obtenerDiagnostico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerdiagnostico",
        datos
      );

      let data = respuesta.data;

      guardarDiagnostico(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Diagnósticos", error.response.data, "error");
    }
  };
  */

  const actualizarDiagnostico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizardiagnostico",
        datos
      );

      mensajeAlerta("Diagnósticos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Diagnósticos", error.response.data, "error");
    }
  };

  const agregarDiagnostico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregardiagnostico",
        datos
      );

      mensajeAlerta("Diagnósticos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Diagnósticos", error.response.data, "error");
    }
  };

  const obtenerDiagnosticosPacientesIndividual = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerdiagnosticospacientes",
        datos
      );

      let data = respuesta.data;
      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].diagnosticos.id,
          label: data[i].diagnosticos.nombre,
        });
      }

      guardarDiagnosticosPacientes(local);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta("Ingresos/Egresos", error.response.data, "error");
    }
  };

  //Estudios

  const obtenerEstudios = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerestudios",
        datos
      );

      let data = respuesta.data;

      guardarEstudios(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerEstudiosPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerestudios",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          label: data[i].nombre,
        });
      }

      guardarEstudios(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarEstudio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarestudio",
        datos
      );

      mensajeAlerta("Estudios", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Estudios", error.response.data, "error");
    }
  };

  const agregarEstudio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarestudio",
        datos
      );

      mensajeAlerta("Estudios", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Estudios", error.response.data, "error");
    }
  };

  const obtenerEstudiosPacientesIndividual = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerestudiospacientes",
        datos
      );

      let data = respuesta.data;
      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].estudios.id,
          label: data[i].estudios.nombre,
        });
      }

      guardarEstudiosPacientes(local);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta("Ingresos/Egresos", error.response.data, "error");
    }
  };

  //Accesos

  //Usuarios

  const obtenerUsuarios = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerusuarios"
      );
      let data = respuesta.data;
      guardarUsuarios(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Usuarios", error.response.data, "error");
    }
  };

  //Pantallas

  const obtenerAccesos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obteneraccesos",
        datos
      );
      let data = respuesta.data;

      const local = [];
      const rows = [];

      if (data.response2.length === 0) {
        for (var i = 0; i < data.response.length; i++) {
          local.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: false },
          });
        }
        guardarPantallas(local);
        return;
      }

      for (var i = 0; i < data.response.length; i++) {
        let band = false;
        for (var j = 0; j < data.response2.length; j++) {
          if (data.response[i].nombre === data.response2[j].layout) {
            band = true;
          }
        }
        if (band) {
          local.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: true },
          });
          rows.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: true },
          });
          setRows(rows);
        } else {
          local.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: false },
          });
        }
      }

      guardarPantallas(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Accesos", error.response.data, "error");
    }
  };

  const obtenerAccesosGeriatricos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obteneraccesosgeriatricos",
        datos
      );
      let data = respuesta.data;

      const local = [];
      const rows = [];

      if (data.response2.length === 0) {
        for (var i = 0; i < data.response.length; i++) {
          local.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            tableData: { checked: false },
          });
        }
        guardarEstablecimientos(local);
        return;
      }

      for (var i = 0; i < data.response.length; i++) {
        let band = false;
        for (var j = 0; j < data.response2.length; j++) {
          if (data.response[i].id === data.response2[j].id_geriatrico) {
            band = true;
          }
        }
        if (band) {
          local.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            tableData: { checked: true },
          });
          rows.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            tableData: { checked: true },
          });
          setRows(rows);
        } else {
          local.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            tableData: { checked: false },
          });
        }
      }

      guardarEstablecimientos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Accesos", error.response.data, "error");
    }
  };

  const agregarAccesos = async (datos) => {
    const { rows, usuario } = datos;

    const local = [];

    for (var i = 0; i < rows.length; i++) {
      local.push({
        id: 0,
        id_usuario: usuario.usuarioId,
        layout: rows[i].nombre,
      });
    }

    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregaraccesos",
        { local, usuario }
      );

      mensajeAlerta("Accesos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Accesos", error.response.data, "error");
    }
  };

  const agregarAccesosGeriatricos = async (datos) => {
    const { rows, usuario } = datos;

    const local = [];

    for (var i = 0; i < rows.length; i++) {
      local.push({
        id: 0,
        id_usuario: usuario.usuarioId,
        id_geriatrico: rows[i].id,
      });
    }

    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregaraccesosgeriatricos",
        { local, usuario }
      );

      mensajeAlerta("Accesos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Accesos", error.response.data, "error");
    }
  };

  const obtenerAcceso = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obteneracceso",
        datos
      );
      //let data = respuesta.data;
      setStatusAcceso(200);
    } catch (error) {
      setStatusAcceso(400);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAcceso2 = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obteneracceso",
        datos
      );
      //let data = respuesta.data;
      setStatusAcceso2(200);
    } catch (error) {
      setStatusAcceso2(400);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAcceso3 = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obteneracceso",
        datos
      );
      //let data = respuesta.data;
      setStatusAcceso3(200);
    } catch (error) {
      setStatusAcceso3(400);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAcceso4 = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obteneracceso",
        datos
      );
      //let data = respuesta.data;
      setStatusAcceso4(200);
    } catch (error) {
      setStatusAcceso4(400);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAcceso5 = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obteneracceso",
        datos
      );
      //let data = respuesta.data;
      setStatusAcceso5(200);
    } catch (error) {
      setStatusAcceso5(400);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  //Ingresos Egresos

  const obtenerIngresosEgresos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obteneringresosegresos",
        datos
      );

      let data = respuesta.data;

      guardarIngresosEgresos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Ingresos/Egresos", error.response.data, "error");
    }
  };

  //Historias Clinicas

  const obtenerHistoriasClinicas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerhistoriasclinicas",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          fecha: data[i].fecha,
          pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          numeroDocumento: data[i].pacientes.numeroDocumento,
        });
      }

      guardarHistoriasClinicas(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerHistoriaClinica = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerhistoriaclinica",
        datos
      );

      let data = respuesta.data;

      guardarHistoriaClinica(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Historias Clínicas", error.response.data, "error");
    }
  };

  const actualizarHistoriaClinica = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarhistoriaclinica",
        datos
      );

      mensajeAlerta("Historias Clínicas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Historias Clínicas", error.response.data, "error");
    }
  };

  const agregarHistoriaClinica = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarhistoriaclinica",
        datos
      );

      mensajeAlerta("Historias Clínicas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Historias Clínicas", error.response.data, "error");
    }
  };

  const verificarHistoriaClinica = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/verificarhistoriaclinica",
        datos
      );

      let data = respuesta.data;
      if (data === "OK") {
        setVisible(true);
      } else {
        setVisible(false);
        mensajeAlerta(
          "Historias Clínicas",
          "El paciente ya tiene historia clínica cargada.",
          "error"
        );
      }
    } catch (error) {
      setVisible(false);
      mensajeAlerta(
        "Historias Clínicas",
        "El paciente ya tiene historia clínica cargada.",
        "error"
      );
      console.log(error.response);
    }
  };

  const obtenerInformesHistoriaClinica = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerinformeshistoriaclinica",
        datos
      );

      let dataFragilidad = respuesta.data.responseFragilidad;

      if (dataFragilidad === null) {
        dataFragilidad = [];
      }
      guardarFragilidad(dataFragilidad);

      let dataBarthels = respuesta.data.responseBarthel;
      if (dataBarthels === null) {
        dataBarthels = [];
      }
      guardarBarthel(dataBarthels);

      let dataRelojes = respuesta.data.responseReloj;
      if (dataRelojes === null) {
        dataRelojes = [];
      }
      guardarReloj(dataRelojes);

      let dataLawtonBrody = respuesta.data.responseLawtonBrody;
      if (dataLawtonBrody === null) {
        dataLawtonBrody = [];
      }
      guardarLawtonBrody(dataLawtonBrody);

      let dataYesavage = respuesta.data.responseYesavage;
      if (dataYesavage === null) {
        dataYesavage = [];
      }
      guardarYesavage(dataYesavage);

      let dataGoldberg = respuesta.data.responseGoldberg;
      if (dataGoldberg === null) {
        dataGoldberg = [];
      }
      guardarGoldberg(dataGoldberg);

      let dataTinetti = respuesta.data.responseTinetti;
      if (dataTinetti === null) {
        dataTinetti = [];
      }
      guardarTinetti(dataTinetti);

      let dataNorton = respuesta.data.responseNorton;
      if (dataNorton === null) {
        dataNorton = [];
      }
      guardarNorton(dataNorton);

      let dataAntecedente = respuesta.data.responseAntecedente;
      if (dataAntecedente === null) {
        dataAntecedente = [];
      }
      guardarAntecedente(dataAntecedente);

      let dataActual = respuesta.data.responseActual;
      if (dataActual === null) {
        dataActual = [];
      }
      guardarActual(dataActual);

      let dataExamenI = respuesta.data.responseExamenI;
      if (dataExamenI === null) {
        dataExamenI = [];
      }
      guardarExamenI(dataExamenI);

      let dataExamenII = respuesta.data.responseExamenII;
      if (dataExamenII === null) {
        dataExamenII = [];
      }
      guardarExamenII(dataExamenII);

      let dataExamenIII = respuesta.data.responseExamenIII;
      if (dataExamenIII === null) {
        dataExamenIII = [];
      }

      guardarExamenIII(dataExamenIII);

      let dataEvaluacion = respuesta.data.responseEvaluacion;
      if (dataEvaluacion === null) {
        dataEvaluacion = [];
      }
      guardarEvaluacionNutricional(dataEvaluacion);

      let dataEscalaValoracion = respuesta.data.responseEscalaValoracion;

      if (dataEscalaValoracion === null) {
        dataEscalaValoracion = [];
      }
      guardarEscalaValoracionMental(dataEscalaValoracion);

      /*

      let dataEvolucion = respuesta.data.responseEvolucion;
      if (dataEvolucion === null) {
        dataEvolucion = [];
      }
      guardarEvolucion(dataEvolucion);
      */
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Historias Clínicas", error.response.data, "error");
    }
  };

  const obtenerInformeFragilidad = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerinformefragilidad",
        datos
      );

      let dataFragilidad = respuesta.data.responseFragilidad;

      if (dataFragilidad === null) {
        dataFragilidad = [];
      }
      guardarFragilidad(dataFragilidad);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Historias Clínicas", error.response.data, "error");
    }
  };

  // Caidas

  const obtenerFichasCaidas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerfichascaidas",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          fecha: data[i].fecha,
          hora: data[i].hora,
          pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          numeroDocumento: data[i].pacientes.numeroDocumento,
          testigo: data[i].testigo,
          lugar: data[i].lugar,
          lugar2: data[i].lugar2,
          circunstancia: data[i].circunstancia,
          diagnostico: data[i].diagnostico,
          intercurrencias: data[i].intercurrencias,
          medicamentos: data[i].medicamentos,
          piso: data[i].piso,
          iluminacion: data[i].iluminacion,
          obstaculos: data[i].obstaculos,
          barandas: data[i].barandas,
          cama: data[i].cama,
          escaleras: data[i].escaleras,
          calzado: data[i].calzado,
          silla: data[i].silla,
          acompanante: data[i].acompanante,
          primeraVez: data[i].primeraVez,
          tinetti: data[i].tinetti,
          consecuencias: data[i].consecuencias,
          tratamiento: data[i].tratamiento,
        });
      }
      guardarCaidas(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Caidas", error.response.data, "error");
    }
  };

  const obtenerControlCaidas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenercontrolcaidas",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          fecha: data[i].fecha,
          pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          numeroDocumento: data[i].pacientes.numeroDocumento,
          lugar: data[i].lugar,
          consecuencias: data[i].consecuencias,
          tratamiento: data[i].tratamiento,
        });
      }
      guardarCaidas(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Caidas", error.response.data, "error");
    }
  };

  //Evoluciones

  const obtenerEvoluciones = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerevoluciones",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          fecha: data[i].fecha,
          pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          numeroDocumento: data[i].pacientes.numeroDocumento,
          evolucion: data[i].evolucion,
          usuario: data[i].usuario,
        });
      }
      guardarEvoluciones(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Historias Clínicas", error.response.data, "error");
    }
  };

  const agregarEvolucion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarevolucion",
        datos
      );

      mensajeAlerta("Evoluciones", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Evoluciones", error.response.data, "error");
    }
  };

  //Evaluaciones

  const obtenerEvaluaciones = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerevaluaciones",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          fecha: data[i].fecha,
          pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          numeroDocumento: data[i].pacientes.numeroDocumento,
          vgi: data[i].vgi,
          evolucion: data[i].evolucion,
          usuario: data[i].usuario,
        });
      }
      guardarEvaluaciones(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Evaluaciones Periódicas", error.response.data, "error");
    }
  };

  const agregarEvaluacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarevaluacion",
        datos
      );

      mensajeAlerta("Evaluaciones Periódicas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Evaluaciones Periódicas", error.response.data, "error");
    }
  };

  const actualizarEvaluacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarevaluacion",
        datos
      );

      mensajeAlerta("Evaluaciones Periódicas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Evaluaciones Periódicas", error.response.data, "error");
    }
  };

  // Frases

  const agregarFraseVgiHc = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarfrasevgihc",
        datos
      );

      mensajeAlerta("V.G.I. H.C.", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("V.G.I. H.C.", error.response.data, "error");
    }
  };

  const actualizarFraseVgiHc = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarfrasevgihc",
        datos
      );

      mensajeAlerta("V.G.I. H.C.", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("V.G.I. H.C.", error.response.data, "error");
    }
  };

  const agregarFrases = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarfrases",
        datos
      );

      mensajeAlerta("Opciones Evolución", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Opciones Evolución", error.response.data, "error");
    }
  };

  const agregarFrasesVGI = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarfrasesvgi",
        datos
      );

      mensajeAlerta("Opciones VGI", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Opciones VGI", error.response.data, "error");
    }
  };

  // Caidas

  const agregarFichasCaidas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarfichascaidas",
        datos
      );

      mensajeAlerta("Caídas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Caídas", error.response.data, "error");
    }
  };

  const actualizarFichasCaidas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarfichascaidas",
        datos
      );

      mensajeAlerta("Caídas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Caídas", error.response.data, "error");
    }
  };

  const agregarControlCaidas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarcontrolcaidas",
        datos
      );

      mensajeAlerta("Caídas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Caídas", error.response.data, "error");
    }
  };

  const actualizarControlCaidas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarcontrolcaidas",
        datos
      );

      mensajeAlerta("Caídas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Caídas", error.response.data, "error");
    }
  };

  //Protocolos

  const obtenerProtocolos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerprotocolos",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          fecha: data[i].fecha,
          tipo: data[i].tipo,
          pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          numeroDocumento: data[i].pacientes.numeroDocumento,
        });
      }

      guardarProtocolos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Protocolos", error.response.data, "error");
    }
  };

  const obtenerProtocolo = async (datos) => {
    const { selectedRow } = datos;

    if (selectedRow.tipo === "caidas") {
      try {
        const respuesta = await clienteAxios.post(
          "/api/servicios/obtenerprotocolocaidas",
          datos
        );

        let data = respuesta.data;

        //console.log(data);

        guardarProtocolo(data);
      } catch (error) {
        console.log(error.response);
        setRealizado("No");
        //mensajeAlerta("Protocolos", error.response.data, "error");
      }
    }
    if (selectedRow.tipo === "escabiosis") {
      try {
        const respuesta = await clienteAxios.post(
          "/api/servicios/obtenerprotocoloescabiosis",
          datos
        );

        let data = respuesta.data;

        setRealizado(data.pendiente === true ? "No" : "Si");
      } catch (error) {
        console.log(error.response);
        setRealizado("No");
        //mensajeAlerta("Protocolos", error.response.data, "error");
      }
    }
    if (selectedRow.tipo === "ulceras") {
      try {
        const respuesta = await clienteAxios.post(
          "/api/servicios/obtenerprotocoloulceras",
          datos
        );

        let data = respuesta.data;

        //console.log(data)
        setRealizado(data.pendiente === true ? "No" : "Si");
      } catch (error) {
        console.log(error.response);
        setRealizado("No");
        //mensajeAlerta("Protocolos", error.response.data, "error");
      }
    }
  };

  const actualizarProtocolo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarprotocolo",
        datos
      );

      mensajeAlerta("Protocolos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Protocolos", error.response.data, "error");
    }
  };

  //Diabetes

  const obtenerDiabetes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerdiabetes",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          fecha: data[i].fecha,
          pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          numeroDocumento: data[i].pacientes.numeroDocumento,
          tipo: data[i].tipo,
          medicacion: data[i].medicacion,
          valor: data[i].valor,
          control: data[i].control,
        });
      }

      guardarDiabetes(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarDiabetes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizardiabetes",
        datos
      );

      mensajeAlerta("Diabetes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Diabetes", error.response.data, "error");
    }
  };

  const agregarDiabetes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregardiabetes",
        datos
      );

      mensajeAlerta("Diabetes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Diabetes", error.response.data, "error");
    }
  };

  //Anticoagulados

  const obtenerAnticoagulados = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obteneranticoagulados",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          fecha: data[i].fecha,
          pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          numeroDocumento: data[i].pacientes.numeroDocumento,
          tipo: data[i].tipo,
          medicacion: data[i].medicacion,
          valor: data[i].valor,
          control: data[i].control,
        });
      }

      guardarAnticoagulados(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarAnticoagulados = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizaranticoagulados",
        datos
      );

      mensajeAlerta("Anticoagulados", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Anticoagulados", error.response.data, "error");
    }
  };

  const agregarAnticoagulados = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregaranticoagulados",
        datos
      );

      mensajeAlerta("Anticoagulados", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Anticoagulados", error.response.data, "error");
    }
  };

  //Ulceras

  const obtenerUlceras = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerulceras",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          fecha: data[i].fecha,
          pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          numeroDocumento: data[i].pacientes.numeroDocumento,
          valoracion: data[i].valoracion,
          localizacion: data[i].localizacion,
          mejora: data[i].mejora,
          estable: data[i].estable,
          empeora: data[i].empeora,
          curada: data[i].curada,
          evolucion: data[i].mejora
            ? "1"
            : data[i].estable
            ? "2"
            : data[i].empeora
            ? "3"
            : data[i].curada
            ? "4"
            : null,
          tto: data[i].tto,
        });
      }

      guardarUlceras(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarUlceras = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarulceras",
        datos
      );

      mensajeAlerta("Ulceras", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Ulceras", error.response.data, "error");
    }
  };

  const agregarUlceras = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarulceras",
        datos
      );

      mensajeAlerta("Ulceras", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Ulceras", error.response.data, "error");
    }
  };

  const listadoPacientesUlceras = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/listadopacientesulceras",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          //id: data[i].id,
          fecha: data[i].fecha,
          //pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          //numeroDocumento: data[i].pacientes.numeroDocumento,
          valoracion: data[i].valoracion,
          localizacion: data[i].localizacion,
          evolucion: data[i].mejora
            ? "Mejora"
            : data[i].estable
            ? "Estable"
            : data[i].empeora
            ? "Empeora"
            : data[i].curada
            ? "Curada"
            : null,
          tto: data[i].tto,
        });
      }

      guardarPacientes(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Enfermerias

  const obtenerEnfermerias = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerenfermerias",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          fecha: data[i].fecha,
          pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          numeroDocumento: data[i].pacientes.numeroDocumento,
          medicacion: data[i].medicacion,
          temperatura: data[i].temperatura,
          tension: data[i].tension,
          frecuencia: data[i].frecuencia,
          observaciones: data[i].observaciones,
        });
      }

      guardarEnfermerias(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarEnfermerias = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizarenfermerias",
        datos
      );

      mensajeAlerta("Enfermería", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Enfermería", error.response.data, "error");
    }
  };

  const agregarEnfermerias = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarenfermerias",
        datos
      );

      mensajeAlerta("Enfermería", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Enfermería", error.response.data, "error");
    }
  };

  const listadoPacientesEnfermeria = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/listadopacientesenfermeria",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          //id: data[i].id,
          fecha: data[i].fecha,
          //pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          //numeroDocumento: data[i].pacientes.numeroDocumento,
          medicacion: data[i].medicacion,
          temperatura: data[i].temperatura,
          tension: data[i].tension,
          frecuencia: data[i].frecuencia,
          observaciones: data[i].observaciones,
        });
      }

      guardarPacientes(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Pre-Ingresos

  const obtenerPreIngresos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerpreingresos",
        datos
      );

      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          fecha: formatDate2(data[i].fecha),
          pacienteId: data[i].pacientes.id,
          nombre: data[i].pacientes.nombre,
          numeroDocumento: data[i].pacientes.numeroDocumento,
        });
      }

      guardarPreIngresos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerPreIngreso = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerpreingreso",
        datos
      );

      let data = respuesta.data;

      guardarPreIngreso(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Pre-Ingresos", error.response.data, "error");
    }
  };

  const agregarPreIngreso = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarpreingreso",
        datos
      );

      mensajeAlerta("Pre-Ingresos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Pre-Ingresos", error.response.data, "error");
    }
  };

  const verificarPreIngreso = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/verificarpreingreso",
        datos
      );

      let data = respuesta.data;
      if (data === "OK") {
        setVisible(true);
        return;
      } else {
        setVisible(false);
        mensajeAlerta(
          "Pre-Ingresos",
          "El paciente ya tiene pre-ingreso cargado",
          "error"
        );
      }
    } catch (error) {
      setVisible(false);
      mensajeAlerta(
        "Pre-Ingresos",
        "El paciente ya tiene pre-ingreso cargado",
        "error"
      );
      console.log(error.response);
    }
  };

  const obtenerTiposDietas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertiposdietas",
        datos
      );

      let local = [];
      let dataTipoDieta = respuesta.data.responseTipoDieta;

      //console.log(dataTipoDieta);

      if (dataTipoDieta === null) {
        dataTipoDieta = [];
      } else {
        for (var i = 0; i < 12; i++) {
          local.push({
            id: i + 1,
            pacienteId: null,
            mes: null,
            anio: null,
            actitud: null,
            depende: null,
            complemento: null,
            pesoMes: null,
            talla: null,
            imc: null,
            riesgoNutricional: null,
          });
        }
        for (var i = 0; i < dataTipoDieta.length; i++) {
          local[dataTipoDieta[i].mes - 1].pacienteId =
            dataTipoDieta[i].pacienteId;
          local[dataTipoDieta[i].mes - 1].mes = dataTipoDieta[i].mes;
          local[dataTipoDieta[i].mes - 1].anio = dataTipoDieta[i].anio;
          local[dataTipoDieta[i].mes - 1].actitud = dataTipoDieta[i].actitud;
          local[dataTipoDieta[i].mes - 1].depende = dataTipoDieta[i].depende;
          local[dataTipoDieta[i].mes - 1].complemento =
            dataTipoDieta[i].complemento;
          local[dataTipoDieta[i].mes - 1].pesoMes = dataTipoDieta[i].pesoMes;
          local[dataTipoDieta[i].mes - 1].talla = dataTipoDieta[i].talla;
          local[dataTipoDieta[i].mes - 1].imc = dataTipoDieta[i].imc;
          local[dataTipoDieta[i].mes - 1].riesgoNutricional =
            dataTipoDieta[i].riesgoNutricional;
        }
      }
      guardarTipoDieta(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Pre-Ingresos", error.response.data, "error");
    }
  };

  const obtenerInformesPreIngreso = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerinformespreingreso",
        datos
      );

      let dataBarthels = respuesta.data.responseBarthel;
      if (dataBarthels === null) {
        dataBarthels = [];
      }
      guardarBarthel(dataBarthels);

      let dataRelojes = respuesta.data.responseReloj;
      if (dataRelojes === null) {
        dataRelojes = [];
      }
      guardarReloj(dataRelojes);

      let dataTinettis = respuesta.data.responseTinetti;
      if (dataTinettis === null) {
        dataTinettis = [];
      }
      guardarTinetti(dataTinettis);

      let dataLawtonBrody = respuesta.data.responseLawtonBrody;
      if (dataLawtonBrody === null) {
        dataLawtonBrody = [];
      }
      guardarLawtonBrody(dataLawtonBrody);

      let dataEscalaValoracion = respuesta.data.responseEscalaValoracion;
      if (dataEscalaValoracion === null) {
        dataEscalaValoracion = [];
      }
      guardarEscalaValoracionMental(dataEscalaValoracion);

      let dataYesavage = respuesta.data.responseYesavage;
      if (dataYesavage === null) {
        dataYesavage = [];
      }
      guardarYesavage(dataYesavage);

      let dataGoldberg = respuesta.data.responseGoldberg;
      if (dataGoldberg === null) {
        dataGoldberg = [];
      }
      guardarGoldberg(dataGoldberg);

      let dataEvaluacion = respuesta.data.responseEvaluacion;
      if (dataEvaluacion === null) {
        dataEvaluacion = [];
      }
      guardarEvaluacionNutricional(dataEvaluacion);

      /*
      let local = [];
      let dataTipoDieta = respuesta.data.responseTipoDieta;
      if (dataTipoDieta === null) {
        dataTipoDieta = [];
      } else {
        for (var i = 0; i < 12; i++) {
          local.push({
            id: i+1,
            pacienteId: null,
            mes: null,
            anio: null,
            actitud: null,
            depende: null,
            complemento: null,
            pesoMes: null,
            talla: null,
            imc: null,
            riesgoNutricional: null,
          });
        }
        for (var i = 0; i < dataTipoDieta.length; i++) {
          local[dataTipoDieta[i].mes - 1].pacienteId = dataTipoDieta[i].pacienteId
          local[dataTipoDieta[i].mes - 1].mes = dataTipoDieta[i].mes
          local[dataTipoDieta[i].mes - 1].anio = dataTipoDieta[i].anio
          local[dataTipoDieta[i].mes - 1].actitud = dataTipoDieta[i].actitud
          local[dataTipoDieta[i].mes - 1].depende = dataTipoDieta[i].depende
          local[dataTipoDieta[i].mes - 1].complemento = dataTipoDieta[i].complemento
          local[dataTipoDieta[i].mes - 1].pesoMes = dataTipoDieta[i].pesoMes
          local[dataTipoDieta[i].mes - 1].talla = dataTipoDieta[i].talla
          local[dataTipoDieta[i].mes - 1].imc = dataTipoDieta[i].imc
          local[dataTipoDieta[i].mes - 1].riesgoNutricional = dataTipoDieta[i].riesgoNutricional
        }
      }
      guardarTipoDieta(local);
      */

      let dataFragilidad = respuesta.data.responseFragilidad;
      if (dataFragilidad === null) {
        dataFragilidad = [];
      }
      guardarFragilidad(dataFragilidad);

      let dataNortons = respuesta.data.responseNorton;
      if (dataNortons === null) {
        dataNortons = [];
      }
      guardarNorton(dataNortons);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Pre-Ingresos", error.response.data, "error");
    }
  };

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  function formatDate2(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  const obtenerDatosPlanilla = async (datos) => {
    mensajeAlerta2("Planilla Mensual", "Obteniendo información ...", "warn");

    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerdatosplanilla",
        datos
      );

      cerrarAlerta();
      let data = respuesta.data;

      //console.log(data);

      let local = [];
      let totalMensual = 0;
      let totalEfectivo = 0;
      let totalPendiente = 0;
      let totalObraSocial = 0;
      let totalDeuda = 0;
      let totalDescartables = 0;

      let totalMensualOS = [];
      let totalEfectivoOS = [];
      let totalPendienteOS = [];
      let totalObraSocialOS = [];
      let totalDeudaOS = [];
      let totalDescartablesOS = [];
      let index = null;
      const unicos = [];

      for (var i = 0; i < data.mensual.length; i++) {
        const elemento = data.mensual[i].pacientes.obrassociales.nombre;

        if (!unicos.includes(data.mensual[i].pacientes.obrassociales.nombre)) {
          unicos.push(elemento);
        }
      }

      for (var i = 0; i < data.mensual.length; i++) {
        index = null;
        local.push({
          numero: 0,
          planillaId: data.mensual[i].planillaId,
          id: data.mensual[i].pacienteId,
          nombre: data.mensual[i].pacientes.nombre,
          obraSocial: data.mensual[i].pacientes.obrassociales.nombre,
          mensual: data.mensual[i].importe,
          iva: data.mensual[i].pacientes.iva
            ? parseFloat((Number(data.mensual[i].importe) * 21) / 100).toFixed(
                2
              )
            : parseFloat(0).toFixed(2),
          total: data.mensual[i].pacientes.iva
            ? parseFloat(
                (Number(data.mensual[i].importe) * 21) / 100 +
                  Number(data.mensual[i].importe)
              ).toFixed(2)
            : parseFloat(Number(data.mensual[i].importe)).toFixed(2),
          coseguro: parseFloat(0).toFixed(2),
          panales: parseFloat(0).toFixed(2),
          estudios: parseFloat(0).toFixed(2),
          importeObraSocial: data.mensual[i].pacientes.iva
            ? parseFloat(
                (Number(data.mensual[i].importe) * 21) / 100 +
                  Number(data.mensual[i].importe)
              ).toFixed(2)
            : parseFloat(Number(data.mensual[i].importe)).toFixed(2),
          efectivo: parseFloat(data.mensual[i].efectivo).toFixed(2),
          ajuste: parseFloat(0).toFixed(2),
          pendiente: parseFloat(data.mensual[i].saldo).toFixed(2),
          ingreso: data.mensual[i].pacientes.fechaIngresoEgreso,
        });
        totalMensual = totalMensual + Number(data.mensual[i].importe);
        totalEfectivo = totalEfectivo + Number(data.mensual[i].efectivo);
        totalPendiente = totalPendiente + Number(data.mensual[i].saldo);
        totalObraSocial =
          data.mensual[i].pacientes.obrassociales.nombre !== "PARTICULAR"
            ? totalObraSocial + Number(data.mensual[i].importe)
            : totalObraSocial + 0;

        totalDescartables = data.mensual[i].pacientes.iva
          ? totalDescartables + (Number(data.mensual[i].importe) * 21) / 100
          : totalDescartables + 0;

        totalDeuda = data.mensual[i].pacientes.iva
          ? totalDeuda +
            (Number(data.mensual[i].importe) * 21) / 100 +
            Number(data.mensual[i].importe)
          : totalDeuda + Number(data.mensual[i].importe);

        // linea x linea

        index = unicos.findIndex(
          (elem) => elem === data.mensual[i].pacientes.obrassociales.nombre
        );
        totalMensualOS[index] =
          totalMensualOS[index] !== undefined
            ? totalMensualOS[index] + Number(data.mensual[i].importe)
            : 0 + Number(data.mensual[i].importe);
        totalEfectivoOS[index] =
          totalEfectivoOS[index] !== undefined
            ? totalEfectivoOS[index] + Number(data.mensual[i].efectivo)
            : 0 + Number(data.mensual[i].efectivo);
        totalPendienteOS[index] =
          totalPendienteOS[index] !== undefined
            ? totalPendienteOS[index] + Number(data.mensual[i].saldo)
            : 0 + Number(data.mensual[i].saldo);
        totalObraSocialOS[index] =
          data.mensual[i].pacientes.obrassociales.nombre !== "PARTICULAR"
            ? totalObraSocialOS[index] !== undefined
              ? totalObraSocialOS[index] + Number(data.mensual[i].importe)
              : 0 + Number(data.mensual[i].importe)
            : totalObraSocialOS[index] !== undefined
            ? totalObraSocialOS[index] + 0
            : 0 + 0;
        // IVA
        totalDescartablesOS[index] =
          totalDescartablesOS[index] !== undefined
            ? data.mensual[i].pacientes.iva
              ? totalDescartablesOS[index] +
                (Number(data.mensual[i].importe) * 21) / 100
              : totalDescartablesOS[index] + 0
            : data.mensual[i].pacientes.iva
            ? 0 + (Number(data.mensual[i].importe) * 21) / 100
            : 0 + 0;
        //Total
        totalDeudaOS[index] =
          totalDeudaOS[index] !== undefined
            ? data.mensual[i].pacientes.iva
              ? totalDeudaOS[index] +
                Number(data.mensual[i].importe) +
                (Number(data.mensual[i].importe) * 21) / 100
              : totalDeudaOS[index] + Number(data.mensual[i].importe)
            : data.mensual[i].pacientes.iva
            ? 0 +
              Number(data.mensual[i].importe) +
              (Number(data.mensual[i].importe) * 21) / 100
            : 0 + Number(data.mensual[i].importe);
      }

      let totalCoseguro = 0;
      let totalCoseguroOS = [];
      for (var i = 0; i < local.length; i++) {
        for (var j = 0; j < data.coseguro.length; j++) {
          if (local[i].id === data.coseguro[j].pacienteId) {
            index = null;
            local[i].coseguro = data.coseguro[j].importe;
            local[i].pendiente = parseFloat(
              Number(local[i].pendiente) + Number(data.coseguro[j].saldo)
            ).toFixed(2);
            local[i].efectivo = parseFloat(
              Number(local[i].efectivo) + Number(data.coseguro[j].efectivo)
            ).toFixed(2);
            local[i].importeObraSocial =
              local[i].obraSocial !== "PARTICULAR"
                ? parseFloat(
                    Number(local[i].importeObraSocial) -
                      Number(data.coseguro[i].importe)
                  ).toFixed(2)
                : parseFloat(0).toFixed(2);

            totalCoseguro = totalCoseguro + Number(data.coseguro[j].importe);
            totalEfectivo = totalEfectivo + Number(data.coseguro[j].efectivo);
            totalPendiente = totalPendiente + Number(data.coseguro[j].saldo);
            totalObraSocial =
              local[i].obraSocial !== "PARTICULAR"
                ? totalObraSocial - Number(data.coseguro[i].importe)
                : totalObraSocial + 0;

            // linea x linea
            index = unicos.findIndex((elem) => elem === local[i].obraSocial);
            totalCoseguroOS[index] =
              totalCoseguroOS[index] !== undefined
                ? totalCoseguroOS[index] + Number(data.coseguro[j].importe)
                : 0 + Number(data.coseguro[j].importe);
            totalEfectivoOS[index] =
              totalEfectivoOS[index] !== undefined
                ? totalEfectivoOS[index] + Number(data.coseguro[j].efectivo)
                : 0 + Number(data.coseguro[j].efectivo);
            totalPendienteOS[index] =
              totalPendienteOS[index] !== undefined
                ? totalPendienteOS[index] + Number(data.coseguro[j].saldo)
                : 0 + Number(data.coseguro[j].saldo);
            totalObraSocialOS[index] =
              local[i].obraSocial !== "PARTICULAR"
                ? totalObraSocialOS[index] !== undefined
                  ? totalObraSocialOS[index] + Number(data.coseguro[i].importe)
                  : 0 + Number(data.coseguro[i].importe)
                : totalObraSocialOS[index] !== undefined
                ? totalObraSocialOS[index] + 0
                : 0 + 0;
          }
        }
      }

      let totalPanales = 0;
      let totalPanalesOS = [];
      for (var i = 0; i < local.length; i++) {
        for (var j = 0; j < data.panales.length; j++) {
          if (local[i].id === data.panales[j].pacienteId) {
            index = null;
            local[i].panales = parseFloat(
              Number(local[i].panales) + Number(data.panales[j].importe)
            ).toFixed(2);
            local[i].pendiente = parseFloat(
              Number(local[i].pendiente) + Number(data.panales[j].saldo)
            ).toFixed(2);
            local[i].efectivo = parseFloat(
              Number(local[i].efectivo) + Number(data.panales[j].efectivo)
            ).toFixed(2);
            totalPanales = totalPanales + Number(data.panales[j].importe);
            totalEfectivo = totalEfectivo + Number(data.panales[j].efectivo);
            totalPendiente = totalPendiente + Number(data.panales[j].saldo);

            // linea x linea
            index = unicos.findIndex((elem) => elem === local[i].obraSocial);
            totalPanalesOS[index] =
              totalPanalesOS[index] !== undefined
                ? totalPanalesOS[index] + Number(data.panales[j].importe)
                : 0 + Number(data.panales[j].importe);
            totalEfectivoOS[index] =
              totalEfectivoOS[index] !== undefined
                ? totalEfectivoOS[index] + Number(data.panales[j].efectivo)
                : 0 + Number(data.panales[j].efectivo);
            totalPendienteOS[index] =
              totalPendienteOS[index] !== undefined
                ? totalPendienteOS[index] + Number(data.panales[j].saldo)
                : 0 + Number(data.panales[j].saldo);
          }
        }
      }

      /*
      let totalDescartables = 0;
      let totalDescartablesOS = [];
      for (var i = 0; i < local.length; i++) {
        for (var j = 0; j < data.descartables.length; j++) {
          if (local[i].id === data.descartables[j].pacienteId) {
            index = null;
            local[i].descartables = parseFloat(
              Number(local[i].descartables) +
                Number(data.descartables[j].importe)
            ).toFixed(2);
            local[i].pendiente = parseFloat(
              Number(local[i].pendiente) + Number(data.descartables[j].saldo)
            ).toFixed(2);
            local[i].efectivo = parseFloat(
              Number(local[i].efectivo) + Number(data.descartables[j].efectivo)
            ).toFixed(2);
            totalDescartables =
              totalDescartables + Number(data.descartables[j].importe);
            totalEfectivo =
              totalEfectivo + Number(data.descartables[j].efectivo);
            totalPendiente =
              totalPendiente + Number(data.descartables[j].saldo);

            // linea x linea
            index = unicos.findIndex((elem) => elem === local[i].obraSocial);
            totalDescartablesOS[index] =
              totalDescartablesOS[index] !== undefined
                ? totalDescartablesOS[index] +
                  Number(data.descartables[j].importe)
                : 0 + Number(data.descartables[j].importe);
            totalEfectivoOS[index] =
              totalEfectivoOS[index] !== undefined
                ? totalEfectivoOS[index] + Number(data.descartables[j].efectivo)
                : 0 + Number(data.descartables[j].efectivo);
            totalPendienteOS[index] =
              totalPendienteOS[index] !== undefined
                ? totalPendienteOS[index] + Number(data.descartables[j].saldo)
                : 0 + Number(data.descartables[j].saldo);
          }
        }
      }
      */

      let totalEstudios = 0;
      let totalEstudiosOS = [];
      for (var i = 0; i < local.length; i++) {
        for (var j = 0; j < data.estudios.length; j++) {
          if (local[i].id === data.estudios[j].pacienteId) {
            index = null;
            local[i].estudios = parseFloat(
              Number(local[i].estudios) + Number(data.estudios[j].importe)
            ).toFixed(2);
            local[i].pendiente = parseFloat(
              Number(local[i].pendiente) + Number(data.estudios[j].saldo)
            ).toFixed(2);
            local[i].efectivo = parseFloat(
              Number(local[i].efectivo) + Number(data.estudios[j].efectivo)
            ).toFixed(2);
            totalEstudios = totalEstudios + Number(data.estudios[j].importe);
            totalEfectivo = totalEfectivo + Number(data.estudios[j].efectivo);
            totalPendiente = totalPendiente + Number(data.estudios[j].saldo);

            // linea x linea
            index = unicos.findIndex((elem) => elem === local[i].obraSocial);
            totalEstudiosOS[index] =
              totalEstudiosOS[index] !== undefined
                ? totalEstudiosOS[index] + Number(data.estudios[j].importe)
                : 0 + Number(data.estudios[j].importe);
            totalEfectivoOS[index] =
              totalEfectivoOS[index] !== undefined
                ? totalEfectivoOS[index] + Number(data.estudios[j].efectivo)
                : 0 + Number(data.estudios[j].efectivo);
            totalPendienteOS[index] =
              totalPendienteOS[index] !== undefined
                ? totalPendienteOS[index] + Number(data.estudios[j].saldo)
                : 0 + Number(data.estudios[j].saldo);
          }
        }
      }

      let totalAjuste = 0;
      let totalAjusteOS = [];
      for (var i = 0; i < local.length; i++) {
        for (var j = 0; j < data.ajustes.length; j++) {
          if (local[i].id === data.ajustes[j].pacienteId) {
            index = null;
            local[i].ajuste = parseFloat(
              Number(local[i].ajuste) + Number(data.ajustes[j].importe)
            ).toFixed(2);
            local[i].pendiente = parseFloat(
              Number(local[i].pendiente) + Number(data.ajustes[j].importe)
            ).toFixed(2);
            totalAjuste = totalAjuste + Number(data.ajustes[j].importe);
            totalPendiente = totalPendiente + Number(data.ajustes[j].importe);

            // linea x linea
            index = unicos.findIndex((elem) => elem === local[i].obraSocial);
            totalAjusteOS[index] =
              totalAjusteOS[index] !== undefined
                ? totalAjusteOS[index] + Number(data.ajustes[j].importe)
                : 0 + Number(data.ajustes[j].importe);
            totalPendienteOS[index] =
              totalPendienteOS[index] !== undefined
                ? totalPendienteOS[index] + Number(data.ajustes[j].importe)
                : 0 + Number(data.ajustes[j].importe);
          }
        }
      }

      /*
      let totalDeuda = 0;
      let totalDeudaOS = [];
      for (var i = 0; i < local.length; i++) {
        for (var j = 0; j < data.derivadas.length; j++) {
          if (local[i].id === data.derivadas[j].pacienteId) {
            index = null;
            local[i].deuda = parseFloat(
              Number(data.derivadas[j].importe)
            ).toFixed(2);
            local[i].pendiente = parseFloat(
              Number(local[i].pendiente) + Number(data.derivadas[j].saldo)
            ).toFixed(2);
            local[i].efectivo = parseFloat(
              Number(local[i].efectivo) +
                (Number(data.derivadas[j].importe) -
                  Number(data.derivadas[j].saldo))
            ).toFixed(2);
            totalDeuda = totalDeuda + Number(data.derivadas[j].importe);
            totalPendiente = totalPendiente + Number(data.derivadas[j].saldo);
            totalEfectivo =
              totalEfectivo +
              (Number(data.derivadas[j].importe) -
                Number(data.derivadas[j].saldo));

            // linea x linea
            index = unicos.findIndex((elem) => elem === local[i].obraSocial);
            totalDeudaOS[index] =
              totalDeudaOS[index] !== undefined
                ? totalDeudaOS[index] + Number(data.derivadas[j].importe)
                : 0 + Number(data.derivadas[j].importe);
            totalPendienteOS[index] =
              totalPendienteOS[index] !== undefined
                ? totalPendienteOS[index] + Number(data.derivadas[j].saldo)
                : 0 + Number(data.derivadas[j].saldo);
            totalEfectivoOS[index] =
              totalEfectivoOS[index] !== undefined
                ? totalEfectivoOS[index] +
                  Number(data.derivadas[j].efectivo) -
                  Number(data.derivadas[j].saldo)
                : 0 +
                  Number(data.derivadas[j].efectivo) -
                  Number(data.derivadas[j].saldo);
          }
        }
      }
      */

      local.sort(function (a, b) {
        if (a.obraSocial > b.obraSocial) {
          return 1;
        }
        if (a.obraSocial < b.obraSocial) {
          return -1;
        }
        // a must be equal to b
        if (a.nombre > b.nombre) {
          return 1;
        }
        if (a.nombre < b.nombre) {
          return -1;
        }
        return 0;
      });

      for (var i = 0; i < local.length; i++) {
        local[i].numero = i + 1;
      }

      let totalPlanilla = [];

      for (var i = 0; i < unicos.length; i++) {
        totalPlanilla.push({
          nombre: "TOTAL " + unicos[i],
          mensual:
            totalMensualOS[i] !== undefined
              ? parseFloat(totalMensualOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          iva:
            totalDescartablesOS[i] !== undefined
              ? parseFloat(totalDescartablesOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          total:
            totalDeudaOS[i] !== undefined
              ? parseFloat(totalDeudaOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          coseguro:
            totalCoseguroOS[i] !== undefined
              ? parseFloat(totalCoseguroOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          panales:
            totalPanalesOS[i] !== undefined
              ? parseFloat(totalPanalesOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          estudios:
            totalEstudiosOS[i] !== undefined
              ? parseFloat(totalEstudiosOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          pendiente:
            totalPendienteOS[i] !== undefined
              ? parseFloat(totalPendienteOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          ajuste:
            totalAjusteOS[i] !== undefined
              ? parseFloat(totalAjusteOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          efectivo:
            totalEfectivoOS[i] !== undefined
              ? parseFloat(totalEfectivoOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          obraSocial:
            totalObraSocialOS[i] !== undefined
              ? parseFloat(totalObraSocialOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
        });
      }

      totalPlanilla.push({
        nombre: "TOTAL GRAL.",
        mensual: parseFloat(totalMensual).toFixed(2),
        iva: parseFloat(totalDescartables).toFixed(2),
        total: parseFloat(totalDeuda).toFixed(2),
        coseguro: parseFloat(totalCoseguro).toFixed(2),
        panales: parseFloat(totalPanales).toFixed(2),
        estudios: parseFloat(totalEstudios).toFixed(2),
        pendiente: parseFloat(totalPendiente).toFixed(2),
        ajuste: parseFloat(totalAjuste).toFixed(2),
        efectivo: parseFloat(totalEfectivo).toFixed(2),
        obraSocial: parseFloat(totalObraSocial).toFixed(2),
      });

      //console.log(data)
      guardarDatosPlanilla(local);
      guardarTotalesPlanilla(totalPlanilla);

      /*
      let localExport = []

      localExport.push({
        id: null,
        numero: "",
        nombre: "TOTAL",
        obraSocial: "",
        mensual: parseFloat(totalMensual).toFixed(2),
        coseguro: parseFloat(totalCoseguro).toFixed(2),
        panales: parseFloat(totalPanales).toFixed(2),
        descartables: parseFloat(totalDescartables).toFixed(2),
        estudios: parseFloat(totalEstudios).toFixed(2),
        deuda: parseFloat(totalDeuda).toFixed(2),
        importeObraSocial: parseFloat(totalObraSocial).toFixed(2),
        ajuste: parseFloat(totalAjuste).toFixed(2),
        pendiente: parseFloat(totalPendiente).toFixed(2),
        efectivo: parseFloat(totalEfectivo).toFixed(2),
        ingreso: "",
      });
      */

      let totalPlanillaExcell = [];
      for (var i = 0; i < unicos.length; i++) {
        totalPlanillaExcell.push({
          id: null,
          numero: "",
          nombre: "TOTAL " + unicos[i],
          obraSocial: "",
          mensual:
            totalMensualOS[i] !== undefined
              ? parseFloat(totalMensualOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          iva:
            totalDescartablesOS[i] !== undefined
              ? parseFloat(totalDescartablesOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          total:
            totalDeudaOS[i] !== undefined
              ? parseFloat(totalDeudaOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          coseguro:
            totalCoseguroOS[i] !== undefined
              ? parseFloat(totalCoseguroOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          panales:
            totalPanalesOS[i] !== undefined
              ? parseFloat(totalPanalesOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          estudios:
            totalEstudiosOS[i] !== undefined
              ? parseFloat(totalEstudiosOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          importeObraSocial:
            totalObraSocialOS[i] !== undefined
              ? parseFloat(totalObraSocialOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          ajuste:
            totalAjusteOS[i] !== undefined
              ? parseFloat(totalAjusteOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          pendiente:
            totalPendienteOS[i] !== undefined
              ? parseFloat(totalPendienteOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          efectivo:
            totalEfectivoOS[i] !== undefined
              ? parseFloat(totalEfectivoOS[i]).toFixed(2)
              : parseFloat(0).toFixed(2),
          ingreso: "",
        });
      }

      let exportEmpty = {
        id: "",
        numero: "",
        nombre: "",
        obraSocial: "",
        mensual: "",
        iva: "",
        total: "",
        coseguro: "",
        panales: "",
        estudios: "",
        importeObraSocial: "",
        ajuste: "",
        pendiente: "",
        efectivo: "",
        ingreso: "",
      };

      let exportGral = {
        id: null,
        numero: "",
        nombre: "TOTAL GRAL.",
        obraSocial: "",
        mensual: parseFloat(totalMensual).toFixed(2),
        iva: parseFloat(totalDescartables).toFixed(2),
        total: parseFloat(totalDeuda).toFixed(2),
        coseguro: parseFloat(totalCoseguro).toFixed(2),
        panales: parseFloat(totalPanales).toFixed(2),
        estudios: parseFloat(totalEstudios).toFixed(2),
        importeObraSocial: parseFloat(totalObraSocial).toFixed(2),
        ajuste: parseFloat(totalAjuste).toFixed(2),
        pendiente: parseFloat(totalPendiente).toFixed(2),
        efectivo: parseFloat(totalEfectivo).toFixed(2),
        ingreso: "",
      };

      let localExportFinal = local.concat(
        exportEmpty,
        totalPlanillaExcell,
        exportGral
      );

      /*
      let localExportFinal = [
        ...local,
        {
          id: null,
          numero: "",
          nombre: "TOTAL GRAL.",
          obraSocial: "",
          mensual: parseFloat(totalMensual).toFixed(2),
          coseguro: parseFloat(totalCoseguro).toFixed(2),
          panales: parseFloat(totalPanales).toFixed(2),
          descartables: parseFloat(totalDescartables).toFixed(2),
          estudios: parseFloat(totalEstudios).toFixed(2),
          deuda: parseFloat(totalDeuda).toFixed(2),
          importeObraSocial: parseFloat(totalObraSocial).toFixed(2),
          ajuste: parseFloat(totalAjuste).toFixed(2),
          pendiente: parseFloat(totalPendiente).toFixed(2),
          efectivo: parseFloat(totalEfectivo).toFixed(2),
          ingreso: "",
        },
      ];
      */

      //console.log(localExportFinal);

      guardarDatosPlanillaExport(localExportFinal);
    } catch (error) {
      cerrarAlerta();
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const facturacionMensual = async (datos) => {
    try {
      mensajeAlerta2(
        "Planilla Mensual",
        "Procesando Residentes. Espere un momento ...",
        "warn"
      );

      const respuesta = await clienteAxios.post(
        "/api/servicios/facturacionmensual",
        datos
      );

      let data = respuesta.data;

      if (data.msg !== undefined) {
        mensajeAlerta("Planilla Mensual", data.msg, "error");
      } else {
        cerrarAlerta();
        window.location.reload();
      }

      //console.log(data.msg)
      //guardarDatosPlanilla(data.datosPlanilla);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const cerrarPlanilla = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/cerrarplanilla",
        datos
      );

      let data = respuesta.data;

      if (data.msg !== undefined) {
        mensajeAlerta("Planilla Mensual", data.msg, "error");
        return;
      }

      mensajeAlerta("Planilla Mensual", data, "success");
      //console.log(data);
      //guardarDatosPlanilla(data.datosPlanilla);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const borrarPlanilla = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/borrarplanilla",
        datos
      );

      let data = respuesta.data;

      if (data.msg !== undefined) {
        mensajeAlerta("Planilla Mensual", data.msg, "error");
        return;
      } else {
        mensajeAlerta("Planilla Mensual", data, "success");
      }
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Pacientes", error.response.data, "error");
    }
  };

  const ajustesPlanilla = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/ajustesplanilla",
        datos
      );

      let data = respuesta.data;

      if (data.msg !== undefined) {
        mensajeAlerta("Planilla Mensual", data.msg, "error");
        return;
      } else {
        mensajeAlerta("Planilla Mensual", data, "success");
      }
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Planilla Mensual", error.response.data, "error");
    }
  };

  const enviarCorreo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/enviaremail",
        datos
      );

      mensajeAlerta("Email", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Email", error.response.data, "error");
    }
  };

  const listadoStock = async (datos) => {
    const { puntoPedido } = datos;
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/listadostock",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      let local = [];
      for (var i = 0; i < data.length; i++) {
        if (puntoPedido) {
          if (data[i].stock < data[i].puntoPedido) {
            local.push({
              id: data[i].id,
              descripcion: data[i].descripcion,
              stock: data[i].stock,
              puntoPedido: data[i].puntoPedido,
            });
          }
        } else {
          local.push({
            id: data[i].id,
            descripcion: data[i].descripcion,
            stock: data[i].stock,
            puntoPedido: data[i].puntoPedido,
          });
        }
      }

      guardarStock(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Listado Stock", error.response.data, "error");
    }
  };

  // Recetas

  const agregarReceta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarreceta",
        datos
      );

      mensajeAlerta("Recetas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Recetas", error.response.data, "error");
    }
  };

  const obtenerRecetasPaciente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerrecetaspaciente",
        datos
      );

      let data = respuesta.data;

      guardarGuardadas(data);

      /*
        let local = [];
  
        for (var i = 0; i < data.length; i++) {
          local.push({
            id: data[i].diagnosticos.id,
            label: data[i].diagnosticos.nombre,
          });
        }
  
        guardarDiagnosticosPacientes(local);
        */
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta("Ingresos/Egresos", error.response.data, "error");
    }
  };

  const borrarReceta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/eliminarreceta",
        datos
      );

      mensajeAlerta("Recetas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Recetas", error.response.data, "error");
    }
  };

  const listadoRecetas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/listadorecetas",
        datos
      );

      let data = respuesta.data;

      guardarGuardadas(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Recetas", error.response.data, "error");
    }
  };

  const upload = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/uploadfiles",
        datos
      );
      mensajeAlerta("Subir Archivos", respuesta.data.msg, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Subir Archivos", error.response.data, "error");
    }
  };

  return (
    <Fragment>
      <Provider
        value={{
          establecimientos,
          statusAcceso,
          statusAcceso2,
          statusAcceso3,
          statusAcceso4,
          statusAcceso5,
          usuarios,
          pantallas,
          rows,
          geriatricoElegido,
          paciente,
          pacientes,
          obrasSociales,
          diagnosticos,
          localidades,
          ingresosEgresos,
          historiasClinicas,
          historiaClinica,
          barthel,
          reloj,
          tinetti,
          norton,
          lawtonBrody,
          yesavage,
          goldberg,
          preIngresos,
          preIngreso,
          escalaValoracionMental,
          evaluacionNutricional,
          tipoDieta,
          fragilidad,
          visible,
          selectedRowPaciente,
          antecedente,
          actual,
          examenI,
          examenII,
          examenIII,
          evolucion,
          vgi,
          datosPlanilla,
          datosPlanillaExport,
          datosPagos,
          coseguro,
          totalesPlanilla,
          establecimientosUsuarios,
          protocolos,
          protocolo,
          realizado,
          diabetes,
          anticoagulados,
          evoluciones,
          caidas,
          ulceras,
          enfermerias,
          frases,
          frasesVGI,
          patologias,
          clinicas,
          medicaciones,
          medicacionesPacientes,
          vinculos,
          coseguros,
          descartables,
          panales,
          obrasSocialesMedicas,
          notas,
          articulos,
          movimientos,
          stock,
          evaluaciones,
          diagnosticosPacientes,
          estudios,
          estudiosPacientes,
          chequeos,
          chequeoBanos,
          chequeoHabitaciones,
          chequeoEspacios,
          chequeoCocinas,
          chequeoEnfermerias,
          chequeoOficinas,
          chequeoLavaderos,
          chequeoElementos,
          chequeoCumplimientos,
          formatos,
          programadas,
          horarios,
          unidades,
          vias,
          velocidades,
          planes,
          dietas,
          practicas,
          guardadas,
          //obtenerEstablecimiento,
          obtenerEstablecimientos,
          actualizarEstablecimiento,
          agregarEstablecimiento,
          obtenerAcceso,
          obtenerAcceso2,
          obtenerAcceso3,
          obtenerAcceso4,
          obtenerAcceso5,
          setRows,
          agregarAccesos,
          obtenerAccesos,
          obtenerUsuarios,
          agregarAccesosGeriatricos,
          obtenerAccesosGeriatricos,
          obtenerEstablecimientosUsuarios,
          setGeriatricoElegido,
          obtenerPaciente,
          obtenerPacientes,
          actualizarPaciente,
          agregarPaciente,
          //obtenerObraSocial,
          obtenerObrasSociales,
          actualizarObraSocial,
          agregarObraSocial,
          //obtenerDiagnostico,
          obtenerDiagnosticos,
          actualizarDiagnostico,
          agregarDiagnostico,
          //obtenerLocalidad,
          obtenerLocalidades,
          actualizarLocalidad,
          agregarLocalidad,
          obtenerLocalidadesPacientes,
          obtenerObrasSocialesPacientes,
          obtenerDiagnosticosPacientes,
          actualizarPacienteIngresoEgreso,
          obtenerIngresosEgresos,
          obtenerHistoriaClinica,
          obtenerHistoriasClinicas,
          actualizarHistoriaClinica,
          agregarHistoriaClinica,
          guardarHistoriaClinica,
          guardarBarthel,
          guardarReloj,
          guardarTinetti,
          guardarNorton,
          guardarLawtonBrody,
          listadoPacientes,
          guardarYesavage,
          guardarGoldberg,
          guardarPreIngreso,
          obtenerPreIngreso,
          obtenerPreIngresos,
          agregarPreIngreso,
          guardarEscalaValoracionMental,
          guardarEvaluacionNutricional,
          guardarTipoDieta,
          guardarFragilidad,
          verificarPreIngreso,
          setVisible,
          obtenerInformesPreIngreso,
          setSelectedRowPaciente,
          verificarHistoriaClinica,
          guardarAntecedente,
          guardarActual,
          obtenerInformesHistoriaClinica,
          guardarExamenI,
          guardarExamenII,
          guardarExamenIII,
          guardarEvolucion,
          guardarVgi,
          obtenerInformeFragilidad,
          obtenerTiposDietas,
          obtenerDatosPlanilla,
          guardarDatosPlanilla,
          guardarDatosPlanillaExport,
          guardarDatosPagos,
          facturacionMensual,
          guardarCoseguro,
          agregarCoseguros,
          obtenerPendientesPlanilla,
          generarPagoPendiente,
          cerrarPlanilla,
          obtenerProtocolos,
          guardarProtocolo,
          obtenerProtocolo,
          actualizarProtocolo,
          setRealizado,
          obtenerDiabetes,
          obtenerAnticoagulados,
          guardarDiabetes,
          guardarAnticoagulados,
          actualizarDiabetes,
          agregarDiabetes,
          actualizarAnticoagulados,
          agregarAnticoagulados,
          listadoPacientesDiabeticos,
          listadoPacientesAnticoagulados,
          listadoPacientesCaidas,
          obtenerUlceras,
          actualizarUlceras,
          agregarUlceras,
          ajustesPlanilla,
          borrarPlanilla,
          obtenerEvoluciones,
          guardarEvoluciones,
          guardarCaidas,
          agregarEvolucion,
          obtenerFichasCaidas,
          obtenerControlCaidas,
          agregarFichasCaidas,
          actualizarFichasCaidas,
          agregarControlCaidas,
          actualizarControlCaidas,
          guardarUlceras,
          guardarEnfermerias,
          obtenerEnfermerias,
          actualizarEnfermerias,
          agregarEnfermerias,
          listadoPacientesUlceras,
          listadoPacientesEnfermeria,
          agregarFrases,
          obtenerFrases,
          obtenerFrasesVGI,
          obtenerPatologias,
          actualizarPatologia,
          agregarPatologia,
          obtenerPatologiasPacientes,
          obtenerClinicas,
          actualizarClinica,
          agregarClinica,
          obtenerClinicasPacientes,
          obtenerMedicaciones,
          obtenerMedicacionesPacientes,
          actualizarMedicacion,
          agregarMedicacion,
          obtenerMedicacionesPacientesIndividual,
          obtenerVinculos,
          actualizarVinculo,
          agregarVinculo,
          obtenerVinculosPacientes,
          obtenerCoseguros,
          actualizarCoseguro,
          agregarCoseguro,
          obtenerCosegurosPacientes,
          obtenerDescartables,
          actualizarDescartable,
          agregarDescartable,
          obtenerDescartablesPacientes,
          obtenerPanales,
          actualizarPanal,
          agregarPanal,
          obtenerPanalesPacientes,
          obtenerObrasSocialesMedicas,
          actualizarObraSocialMedica,
          agregarObraSocialMedica,
          obtenerObrasSocialesMedicasPacientes,
          guardarMedicacionesPacientes,
          obtenerNotas,
          obtenerNotasPacientes,
          actualizarNota,
          agregarNota,
          enviarCorreo,
          obtenerArticulos,
          actualizarArticulo,
          agregarArticulo,
          agregarMovimiento,
          obtenerMovimientos,
          agregarMovimientoAjuste,
          listadoStock,
          agregarFrasesVGI,
          guardarDiagnosticosPacientes,
          obtenerDiagnosticosPacientesIndividual,
          obtenerEvaluaciones,
          agregarEvaluacion,
          actualizarEvaluacion,
          obtenerEvaluacionAnterior,
          obtenerEstudiosPacientesIndividual,
          agregarEstudio,
          actualizarEstudio,
          obtenerEstudiosPacientes,
          obtenerEstudios,
          guardarEstudiosPacientes,
          obtenerFrasesVgiHc,
          agregarFraseVgiHc,
          actualizarFraseVgiHc,
          obtenerChequeos,
          agregarChequeo,
          actualizarChequeo,
          obtenerChequeo,
          guardarChequeoBanos,
          guardarChequeoHabitaciones,
          guardarChequeoEspacios,
          guardarChequeoCocinas,
          guardarChequeoEnfermerias,
          guardarChequeoOficinas,
          guardarChequeoLavaderos,
          guardarChequeoElementos,
          guardarChequeoCumplimientos,
          guardarFormatos,
          obtenerFormatos,
          agregarFormato,
          actualizarFormato,
          eliminarFormato,
          obtenerProgramadas,
          obtenerHorarios,
          guardarHorarios,
          actualizarHorario,
          agregarHorario,
          eliminarHorario,
          actualizarProgramada,
          agregarProgramada,
          obtenerUnidades,
          agregarUnidad,
          actualizarUnidad,
          obtenerVias,
          agregarVia,
          actualizarVia,
          obtenerVelocidades,
          agregarVelocidad,
          actualizarVelocidad,
          obtenerPlanes,
          agregarPlan,
          actualizarPlan,
          listadoPacientesEvaluaciones,
          listadoMedicacionesActivas,
          listadoTotalesMedicamentos,
          agregarReceta,
          obtenerRecetasPaciente,
          borrarReceta,
          listadoRecetas,
          upload,
          enviarCertificadoEmail,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export { ServiciosProvider, Consumer as ServiciosConsumer, ServiciosContext };
