import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

export default function LawtonBrody(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const { statusAcceso3, lawtonBrody, guardarLawtonBrody } = serviciosContext;

  const handleChangeTelefono = (event) => {
    guardarLawtonBrody({
      ...lawtonBrody,
      telefono: event.target.value,
      telefonoResultado: event.target.value.substring(2, 1),
    });
  };

  const handleChangeCompras = (event) => {
    guardarLawtonBrody({
      ...lawtonBrody,
      compras: event.target.value,
      comprasResultado: event.target.value.substring(2, 1),
    });
  };

  const handleChangeComida = (event) => {
    guardarLawtonBrody({
      ...lawtonBrody,
      comida: event.target.value,
      comidaResultado: event.target.value.substring(2, 1),
    });
  };

  const handleChangeCasa = (event) => {
    guardarLawtonBrody({
      ...lawtonBrody,
      casa: event.target.value,
      casaResultado: event.target.value.substring(2, 1),
    });
  };

  const handleChangeRopa = (event) => {
    guardarLawtonBrody({
      ...lawtonBrody,
      ropa: event.target.value,
      ropaResultado: event.target.value.substring(2, 1),
    });
  };

  const handleChangeTransporte = (event) => {
    guardarLawtonBrody({
      ...lawtonBrody,
      transporte: event.target.value,
      transporteResultado: event.target.value.substring(2, 1),
    });
  };

  const handleChangeMedicacion = (event) => {
    guardarLawtonBrody({
      ...lawtonBrody,
      medicacion: event.target.value,
      medicacionResultado: event.target.value.substring(2, 1),
    });
  };

  const handleChangeEconomico = (event) => {
    guardarLawtonBrody({
      ...lawtonBrody,
      economico: event.target.value,
      economicoResultado: event.target.value.substring(2, 1),
    });
  };

  //console.log(paciente);
  //console.log(statusAcceso3);

  return (
    <Fragment>
      {lawtonBrody ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "0vw", marginLeft: "0vw" }}
          >
            <Grid item xs={12} md={12}>
              <Typography variant="caption" display="block" gutterBottom>
                Anotar con la ayuda del cuidador principal, cuál es la
                sitiuación concreta personal del paciente, respecto a estos 8
                items de actividades instrumentales de la vida diaria.
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="telefono"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (1) - Capacidad para usar el Teléfono
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="telefono"
                  value={lawtonBrody ? lawtonBrody.telefono : null}
                  onChange={handleChangeTelefono}
                >
                  <FormControlLabel
                    value="11"
                    control={<Radio />}
                    label="Utiliza el teléfono por iniciativa propia."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="21"
                    control={<Radio />}
                    label="Es capaz de marcar bien algunos números familiares."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="31"
                    control={<Radio />}
                    label="Es capaz de contestar al teléfono, pero no de marcar."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="40"
                    control={<Radio />}
                    label="No es capaz de usar el teléfono."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="compras"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (2) - Hacer compras
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="compras"
                  value={lawtonBrody ? lawtonBrody.compras : null}
                  onChange={handleChangeCompras}
                >
                  <FormControlLabel
                    value="11"
                    control={<Radio />}
                    label="Realiza todas las compras necesarias independientemente."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="20"
                    control={<Radio />}
                    label="Realiza independientemente pequeñas compras."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="30"
                    control={<Radio />}
                    label="Necesita ir acompañado para hacer cualquier compra."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="40"
                    control={<Radio />}
                    label="Totalmente incapaz de comprar."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="comida"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (3) - Preparación de la comida
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="comida"
                  value={lawtonBrody ? lawtonBrody.comida : null}
                  onChange={handleChangeComida}
                >
                  <FormControlLabel
                    value="11"
                    control={<Radio />}
                    label="Organiza, prepara y sirve las comidas por sí solo adecuadamente."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="20"
                    control={<Radio />}
                    label="Prepara adecuadamente las comidas si se le proporcionan los ingredientes."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="30"
                    control={<Radio />}
                    label="Prepara, calienta y sirve las comidas, pero no sigue una dieta adecuada."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="40"
                    control={<Radio />}
                    label="Necesita que le preparen y sirvan las comidas."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="casa"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (4) - Cuidado de la casa
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="casa"
                  value={lawtonBrody ? lawtonBrody.casa : null}
                  onChange={handleChangeCasa}
                >
                  <FormControlLabel
                    value="11"
                    control={<Radio />}
                    label="Mantiene la casa solo o con ayuda ocasional (para trabajos pesados)."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="21"
                    control={<Radio />}
                    label="Realiza tareas ligeras, como lavar los platos o hacer las camas."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="31"
                    control={<Radio />}
                    label="Realiza tareas ligeras, pero no puede mantener un adecuado nivel de limpieza."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="41"
                    control={<Radio />}
                    label="Necesita ayuda en todas las labores de la casa."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="50"
                    control={<Radio />}
                    label="No participa en ninguna labor de la casa."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="ropa"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (5) - Lavado de la ropa
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="ropa"
                  value={lawtonBrody ? lawtonBrody.ropa : null}
                  onChange={handleChangeRopa}
                >
                  <FormControlLabel
                    value="11"
                    control={<Radio />}
                    label="Lava por sí solo toda su ropa."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="21"
                    control={<Radio />}
                    label="Lava por sí solo pequeñas prendas."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="30"
                    control={<Radio />}
                    label="Todo el lavado de ropa debe ser realizado por otro."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="transporte"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (6) - Uso de medios de transporte
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="transporte"
                  value={lawtonBrody ? lawtonBrody.transporte : null}
                  onChange={handleChangeTransporte}
                >
                  <FormControlLabel
                    value="11"
                    control={<Radio />}
                    label="Viaja solo en transporte público o conduce su propio coche."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="21"
                    control={<Radio />}
                    label="Capaz de organizar su propio transporte en taxi, pero
                    no usa transporte público."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="31"
                    control={<Radio />}
                    label="Viaja en transporte público cuando va acompañado por otra persona."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="40"
                    control={<Radio />}
                    label="Solo utiliza el taxi o el automóvil con ayuda de otros."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="50"
                    control={<Radio />}
                    label="No viaja."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="medicacion"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (7) - Responsabilidad respecto a su medicación
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="medicacion"
                  value={lawtonBrody ? lawtonBrody.medicacion : null}
                  onChange={handleChangeMedicacion}
                >
                  <FormControlLabel
                    value="11"
                    control={<Radio />}
                    label="Es capaz de tomar su medicación a la hora y con la dosis correcta."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="20"
                    control={<Radio />}
                    label="Toma su medicación si la dosis le es preparada previamente."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="30"
                    control={<Radio />}
                    label="No es capaz de administrarse su medicación."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="economico"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  (8) - Manejo de sus asuntos economicos
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="economico"
                  value={lawtonBrody ? lawtonBrody.economico : null}
                  onChange={handleChangeEconomico}
                >
                  <FormControlLabel
                    value="11"
                    control={<Radio />}
                    label="Se encarga de sus asuntos económicos por sí solo."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="21"
                    control={<Radio />}
                    label="Realiza las compras de cada día, pero necesita ayuda en las grandes compras, bancos…"
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                  <FormControlLabel
                    value="30"
                    control={<Radio />}
                    label="Incapaz de manejar dinero."
                    disabled={lawtonBrody && lawtonBrody.status && statusAcceso3 !== 200}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} />

            <Grid item xs={12} md={8}>
              <Typography variant="caption" display="block" gutterBottom>
                La información se obtendrá de un cuidador fidedigno.
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                La máxima dependencia estaría marcada por la obtención de cero
                puntos, y 8 puntos expresarían una independencia total.
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                La escala es más útil en mujeres, ya que muchos hombres nunca
                han realizado algunas de las actividades que se evalúan.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" gutterBottom component="div">
                Resultado
              </Typography>
              <Typography variant="h4" gutterBottom component="div">
                {Number(
                  lawtonBrody.telefonoResultado
                    ? lawtonBrody.telefonoResultado
                    : 0
                ) +
                  Number(
                    lawtonBrody.comprasResultado
                      ? lawtonBrody.comprasResultado
                      : 0
                  ) +
                  Number(
                    lawtonBrody.comidaResultado
                      ? lawtonBrody.comidaResultado
                      : 0
                  ) +
                  Number(
                    lawtonBrody.casaResultado ? lawtonBrody.casaResultado : 0
                  ) +
                  Number(
                    lawtonBrody.ropaResultado ? lawtonBrody.ropaResultado : 0
                  ) +
                  Number(
                    lawtonBrody.transporteResultado
                      ? lawtonBrody.transporteResultado
                      : 0
                  ) +
                  Number(
                    lawtonBrody.medicacionResultado
                      ? lawtonBrody.medicacionResultado
                      : 0
                  ) +
                  Number(
                    lawtonBrody.economicoResultado
                      ? lawtonBrody.economicoResultado
                      : 0
                  )}
              </Typography>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
