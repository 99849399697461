import React, { useState, useContext, Fragment } from "react";
import clienteAxios from "../config/axios";
import tokenAuth from "../config/token";
import { createContext } from "react";
import { AlertContext } from "./AlertContext";

const AuthContext = createContext();
const { Provider, Consumer } = AuthContext;

function AuthProvider({ children }) {
  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const [state, guardarState] = useState({
    token: localStorage.getItem("token"),
    autenticado: null,
    usuario: null,
    cargando: true,
  });

  const registrarUsuario = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/auth/crearusuario",
        datos
      );
      console.log(respuesta);
      mensajeAlerta(
        "Cuenta",
        "El usuario se ha creado correctamente.",
        "success"
      );
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cuenta", "El usuario ya existe !.", "error");
      localStorage.removeItem("token");
      guardarState({
        ...state,
        token: null,
        usuario: null,
        autenticado: null,
        cargando: false,
      });
    }
  };

  // Retorna el usuario autenticado
  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token");
    tokenAuth(token);

    try {
      const respuesta = await clienteAxios.get("/api/auth");

      if (token === null) {
        guardarState({
          ...state,
          autenticado: false,
          usuario: null,
          cargando: false,
        });
        return
      }

      guardarState({
        ...state,
        autenticado: true,
        usuario: respuesta.data.usuario,
        cargando: false,
      });


      window.$usuario = respuesta.data.usuario;
    } catch (error) {
      console.log(error.response);
      guardarState({
        ...state,
        autenticado: false,
        usuario: null,
        cargando: false,
      });
    }
  };

  // Cuando el usuario inicia sesión
  const iniciarSesion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("/api/auth", datos);
      localStorage.setItem("token", respuesta.data.token);
      //console.log(localStorage)
      // Obtener el usuario
      usuarioAutenticado();
    } catch (error) {
      console.log(error.response.data.msg);
      mensajeAlerta("Login", error.response.data.msg, "error");
      localStorage.removeItem("token");
      guardarState({
        ...state,
        token: null,
        usuario: null,
        autenticado: null,
        cargando: false,
      });
    }
  };

  // Cierra la sesión del usuario
  const cerrarSesion = () => {
    localStorage.removeItem("token");
    guardarState({
      ...state,
      token: null,
      usuario: null,
      autenticado: null,
      cargando: false,
    });
  };

  const verificacionEmail = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("/api/auth/enviarEmail", datos);

      mensajeAlerta("Reset", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Ingresos/Egresos", error.response.data, "error");
    }
  };

  const cambiarPassword = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/auth/cambiarpassword",
        datos
      );

      mensajeAlerta("Reset", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Ingresos/Egresos", error.response.data, "error");
    }
  };

  return (
    <Fragment>
      <Provider
        value={{
          token: state.token,
          autenticado: state.autenticado,
          usuario: state.usuario,
          cargando: state.cargando,
          state,
          geriatricoPredeterminadoId: state.geriatricoId,
          geriatricoPredeterminadoNombre: state.geriatricoNombre,
          cargando: state.cargando,

          registrarUsuario,
          iniciarSesion,
          usuarioAutenticado,
          cerrarSesion,
          verificacionEmail,
          cambiarPassword,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export { AuthProvider, Consumer as AuthConsumer, AuthContext };
