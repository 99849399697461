import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";

export default function Goldberg(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    goldberg,
    statusAcceso3,
    statusAcceso4,
    selectedRowPaciente,
    diagnosticos,
    diagnosticosPacientes,
    localidades,
    guardarGoldberg,
    obtenerAcceso3,
    obtenerAcceso4,

  } = serviciosContext;

  useEffect(() => {
    obtenerAcceso4({
      usuarioId: state.usuario.id,
      layout: "Psicologia",
    });
    obtenerAcceso3({
      usuarioId: state.usuario.id,
      layout: "EditarPIHC",
    });
  }, []);

  const handleChangePregunta1 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta1: event.target.value,
    });
  };

  const handleChangePregunta2 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta2: event.target.value,
    });
  };

  const handleChangePregunta3 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta3: event.target.value,
    });
  };

  const handleChangePregunta4 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta4: event.target.value,
    });
  };

  const handleChangePregunta5 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta5: event.target.value,
    });
  };

  const handleChangePregunta6 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta6: event.target.value,
    });
  };

  const handleChangePregunta7 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta7: event.target.value,
    });
  };

  const handleChangePregunta8 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta8: event.target.value,
    });
  };

  const handleChangePregunta9 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta9: event.target.value,
    });
  };

  const handleChangePregunta10 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta10: event.target.value,
    });
  };

  const handleChangePregunta11 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta11: event.target.value,
    });
  };

  const handleChangePregunta12 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta12: event.target.value,
    });
  };

  const handleChangePregunta13 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta13: event.target.value,
    });
  };

  const handleChangePregunta14 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta14: event.target.value,
    });
  };

  const handleChangePregunta15 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta15: event.target.value,
    });
  };

  const handleChangePregunta16 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta16: event.target.value,
    });
  };

  const handleChangePregunta17 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta17: event.target.value,
    });
  };

  const handleChangePregunta18 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta18: event.target.value,
    });
  };

  const handleChangePregunta19 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta19: event.target.value,
    });
  };

  const handleChangePregunta20 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta20: event.target.value,
    });
  };

  const handleChangePregunta21 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta21: event.target.value,
    });
  };

  const handleChangePregunta22 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta22: event.target.value,
    });
  };

  const handleChangePregunta23 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta23: event.target.value,
    });
  };

  const handleChangePregunta24 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta24: event.target.value,
    });
  };

  const handleChangePregunta25 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta25: event.target.value,
    });
  };

  const handleChangePregunta26 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta26: event.target.value,
    });
  };

  const handleChangePregunta27 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta27: event.target.value,
    });
  };

  const handleChangePregunta28 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta28: event.target.value,
    });
  };

  const handleChangePregunta29 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta29: event.target.value,
    });
  };

  const handleChangePregunta30 = (event) => {
    guardarGoldberg({
      ...goldberg,
      pregunta30: event.target.value,
    });
  };

  //console.log(paciente);
  //console.log(goldberg);

  return (
    <Fragment>
      {goldberg ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "0vw", marginLeft: "0vw" }}
          >
            <Grid item xs={12} md={6}>
              <FormLabel
                id="equilibrioProlongado"
                style={{ fontWeight: "bold", fontSize: "large" }}
              >
                SUBESCALA DE ANSIEDAD
              </FormLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel
                id="equilibrioProlongado"
                style={{ fontWeight: "bold", fontSize: "large" }}
              >
                SUBESCALA DE DEPRESION
              </FormLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta1 : null}
                  onChange={handleChangePregunta1}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (1) - ¿Se ha sentido muy excitado, nervioso o en tensión?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta2 : null}
                  onChange={handleChangePregunta2}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (1) - ¿Se ha sentido con poca energía?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta3 : null}
                  onChange={handleChangePregunta3}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (2) - ¿Ha estado muy preocupado poe algo?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta4 : null}
                  onChange={handleChangePregunta4}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (2) - ¿Ha perdido Ud. su interés por las cosas?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta5 : null}
                  onChange={handleChangePregunta5}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (3) - ¿Se ha sentido muy irritable?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta6 : null}
                  onChange={handleChangePregunta6}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (3) - ¿Ha perdido la confianza en sí mismo?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta7 : null}
                  onChange={handleChangePregunta7}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (4) - ¿Ha tenido dificultad para relajarse?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta8 : null}
                  onChange={handleChangePregunta8}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (4) - ¿Se ha sentido Ud. desesperanzado, sin esperanza?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta9 : null}
                  onChange={handleChangePregunta9}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (5) - ¿Ha dormido mal, ha tenido dificultades para dormir?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta10 : null}
                  onChange={handleChangePregunta10}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (5) - ¿Ha tenido dificultad para concentrarse?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta11 : null}
                  onChange={handleChangePregunta11}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (6) - ¿Ha tenido dolores de cabeza o nuca?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta12 : null}
                  onChange={handleChangePregunta12}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (6) - ¿Ha perdido peso? (a causa de la falta de apetito)
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta13 : null}
                  onChange={handleChangePregunta13}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (7) - ¿Ha tenido algunos de estos síntomas: temblores, hormigueos, mareos, sudores, diarrea? (síntomas vegetativos)
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta14 : null}
                  onChange={handleChangePregunta14}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (7) - ¿Se ha estado despertando demasiado temprano?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta15 : null}
                  onChange={handleChangePregunta15}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (8) - ¿Ha estado preocupado por su salud?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta16 : null}
                  onChange={handleChangePregunta16}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (8) - ¿Se ha sentido Ud. enlentecido?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta17 : null}
                  onChange={handleChangePregunta17}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (9) - ¿Ha tenido alguna dificultad para conciliar el sueño, para quedarse dormido?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={goldberg ? goldberg.pregunta18 : null}
                  onChange={handleChangePregunta18}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    (9) - ¿Cree Ud. que ha tenido tendencia de encontrarse peor por las mañanas?
                  </FormLabel>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (goldberg && goldberg.status && statusAcceso3 !== 200) ||
                      (goldberg && !goldberg.status && statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>


            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom component="div">
                Resultado Ansiedad
              </Typography>
              <Typography variant="h4" gutterBottom component="div">
                {Number(goldberg.pregunta1 ? goldberg.pregunta1 : 0) +
                  Number(goldberg.pregunta3 ? goldberg.pregunta3 : 0) +
                  Number(goldberg.pregunta5 ? goldberg.pregunta5 : 0) +
                  Number(goldberg.pregunta7 ? goldberg.pregunta7 : 0) +
                  Number(goldberg.pregunta9 ? goldberg.pregunta9 : 0) +
                  Number(goldberg.pregunta11 ? goldberg.pregunta11 : 0) +
                  Number(goldberg.pregunta13 ? goldberg.pregunta13 : 0) +
                  Number(goldberg.pregunta15 ? goldberg.pregunta15 : 0) +
                  Number(goldberg.pregunta17 ? goldberg.pregunta17 : 0)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom component="div">
                Resultado Depresión
              </Typography>
              <Typography variant="h4" gutterBottom component="div">
                {Number(goldberg.pregunta2 ? goldberg.pregunta2 : 0) +
                  Number(goldberg.pregunta4 ? goldberg.pregunta4 : 0) +
                  Number(goldberg.pregunta6 ? goldberg.pregunta6 : 0) +
                  Number(goldberg.pregunta8 ? goldberg.pregunta8 : 0) +
                  Number(goldberg.pregunta10 ? goldberg.pregunta10 : 0) +
                  Number(goldberg.pregunta12 ? goldberg.pregunta12 : 0) +
                  Number(goldberg.pregunta14 ? goldberg.pregunta14 : 0) +
                  Number(goldberg.pregunta16 ? goldberg.pregunta16 : 0) +
                  Number(goldberg.pregunta18 ? goldberg.pregunta18 : 0)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="caption" display="block" gutterBottom>
                Puntuación 
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                0-4 : Valoración normal
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                Mayor a 4 : Presencia de ansiedad  
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="caption" display="block" gutterBottom>
                Puntuación 
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                0-3 : Estado anímico normal
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                Mayor a 3 : Presencia de depresión  
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="diagnostico"
                      fullWidth
                      multiple
                      options={diagnosticos}
                      disablePortal
                      defaultValue={
                        selectedRowPaciente &&
                        diagnosticosPacientes &&
                        diagnosticosPacientes !== null &&
                        diagnosticosPacientes.length > 0
                          ? diagnosticosPacientes
                          : []
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Diagnósticos"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      /*
                      onChange={(event, values) => {
                        if (values) {
                          guardarDiagnosticosPacientes(values);
                        } else {
                          guardarDiagnosticosPacientes(null);
                        }
                      }}
                      */
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
              Observaciones
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextareaAutosize
                value={
                  goldberg
                    ? goldberg.observaciones
                    : null
                }
                id="observaciones"
                aria-label="minimum height"
                minRows={2}
                maxRows={6}
                fullWidth
                placeholder="Observaciones"
                style={{ width: "100%" }}
                onChange={(e) =>
                  guardarGoldberg({
                    ...goldberg,
                    observaciones: e.target.value,
                  })
                }
                disabled={goldberg && goldberg.status && statusAcceso3 !== 200}
              />
            </Grid>


          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
