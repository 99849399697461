import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const fechasPagos = [
  { label: "1 a 15", id: 1 },
  { label: "16 a 31", id: 2 },
];

export default function Contrato(props) {
  const paciente = props.paciente;
  const geriatrico = props.geriatrico;

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    pacientes,
    localidades,
    ingresosEgresos,
    obrasSociales,
    obrasSocialesMedicas,
    coseguros,
    diagnosticos,
    descartables,
    panales,
    vinculos,
    clinicas,
    medicaciones,
    medicacionesPacientes,
    geriatricoElegido,
    obtenerPacientes,
    obtenerAcceso,
    agregarPaciente,
    actualizarPaciente,
    setGeriatricoElegido,
    obtenerLocalidadesPacientes,
    obtenerObrasSocialesPacientes,
    obtenerObrasSocialesMedicasPacientes,
    obtenerClinicasPacientes,
    obtenerPatologiasPacientes,
    obtenerVinculosPacientes,
    obtenerCosegurosPacientes,
    obtenerPanalesPacientes,
    obtenerDescartablesPacientes,
    actualizarPacienteIngresoEgreso,
    obtenerIngresosEgresos,
    obtenerDiagnosticosPacientes,
    obtenerMedicacionesPacientes,
    obtenerMedicacionesPacientesIndividual,
  } = serviciosContext;

  useEffect(() => {
    obtenerLocalidadesPacientes({ todos: false });
    obtenerDiagnosticosPacientes({ todos: false });
    obtenerObrasSocialesPacientes({ todos: false });
    obtenerObrasSocialesMedicasPacientes({ todos: false });
    obtenerClinicasPacientes({ todos: false });
    obtenerPatologiasPacientes({ todos: false });
    obtenerVinculosPacientes({ todos: false });
    obtenerCosegurosPacientes({ todos: false });
    obtenerPanalesPacientes({ todos: false });
    obtenerDescartablesPacientes({ todos: false });
    obtenerMedicacionesPacientes({ todos: false });
    if (paciente) {
      obtenerMedicacionesPacientesIndividual({ pacienteId: paciente.id });
    }
  }, [paciente.id]);

  function formatDate(dateString) {
    var allDate = dateString.split("-");
    var newDate = [allDate[2], allDate[1], allDate[0]].join("/");
    return newDate;
  }

  function getEdad(dateString) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  }
  //console.log(paciente);
  //console.log(medicacionesPacientes);

  function obtenerValores() {

    let valorArancel = 0
    if (paciente.obraSocialContratoId !== undefined && paciente.obraSocialContratoId !== null && paciente.obraSocialContratoId === 2) {     
      valorArancel = paciente.arancel
    }

    console.log(paciente)
    console.log(valorArancel)

    let valorPanal = 0;
    if (paciente.panalId !== undefined && paciente.panalId !== null) {
      valorPanal =
        panales[panales.findIndex((item) => item.id === paciente.panalId)]
          .valor;
    }

    let valorDescartable = 0;
    if (
      paciente.descartableId !== undefined &&
      paciente.descartableId !== null
    ) {
      valorDescartable =
        descartables[
          descartables.findIndex((item) => item.id === paciente.descartableId)
        ].valor;
    }

    let valorTotal = parseFloat(
      Number(valorDescartable) + Number(valorPanal)  + Number(valorArancel)
    ).toFixed(2);

    return valorTotal;
  }

  //console.log(paciente);
  //console.log(clinicas);

  return (
    <Fragment>
      {paciente && geriatrico ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{
              marginTop: "0vw",
              marginRight: "3vw",
              padding: "10vw",
              textAlign: "justify",
            }}
          >
            <Grid item xs={12} md={12}>
              <Typography
                variant="h6"
                style={{ textDecoration: "underline" }}
                align="center"
              >
                CONTRATO DE INTERNACION
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="subtitle2" gutterBottom component="div">
                Entre la residencia {geriatrico.nombre} Y el Sr./a:{" "}
                {paciente.nombreRepresentante !== undefined &&
                paciente.nombreRepresentante !== null
                  ? paciente.nombreRepresentante
                  : " "}{" "}
                Con domicilio en la calle:{" "}
                {paciente.domicilioRepresentante !== undefined &&
                paciente.domicilioRepresentante !== null
                  ? paciente.domicilioRepresentante
                  : " "}{" "}
                Localidad{" "}
                {paciente &&
                localidades &&
                paciente.localidadRepresentanteId !== undefined &&
                paciente.localidadRepresentanteId !== null
                  ? localidades[
                      localidades.findIndex(
                        (item) => item.id === paciente.localidadRepresentanteId
                      )
                    ].label
                  : " "}{" "}
                titular de documento: Nº:{" "}
                {paciente.numeroDocumentoRepresentante} T.E.:{" "}
                {paciente.telefonoRepresentante}, quien se compromete a
                representar al Sr./Sra: {paciente.nombre} Nacido el:{" "}
                {formatDate(paciente.fechaNacimiento)} Documento: Nº:{" "}
                {paciente.numeroDocumento} Lugar de Nacimiento:{" "}
                {paciente.lugarNacimiento} con domicilio en calle:{" "}
                {paciente.domicilio} Localidad de{" "}
                {paciente && localidades && paciente.localidadId !== undefined
                  ? localidades[
                      localidades.findIndex(
                        (item) => item.id === paciente.localidadId
                      )
                    ].label
                  : " "}{" "}
                Obra Social{" "}
                {paciente &&
                obrasSocialesMedicas &&
                paciente.obraSocialId !== undefined
                  ? obrasSocialesMedicas[
                      obrasSocialesMedicas.findIndex(
                        (item) => item.id === paciente.obraSocialId
                      )
                    ].label
                  : " "}{" "}
                carnet Nº: {paciente.numeroObraSocial}, edad{" "}
                {getEdad(paciente.fechaNacimiento)}, estado civil:{" "}
                {paciente.estadoCivil} En adelante denominado Internado.
                Convienen en celebrar el siguiente contrato:
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                PRIMERA: LA RESIDENCIA otorga servicio de internación
                geriátrica, más el servicio de hotelería, alimentación, Control
                Médico de Clínica General y Servicio de Emergencia Médica, al
                INTERNADO. Que al ingreso deberá completar la encuesta de
                satisfacción.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                SEGUNDA: EL INTERNADO y/o REPRESENTANTE, pagarán mensualmente,
                en concepto de arancel, la suma de pesos {obtenerValores()}. El
                importe pactado podrá ser ajustado por incrementos en el costo
                de vida o laboral y según convenios sindicales, en la misma
                moneda.-
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                TERCERA: El pago del arancel deberá hacerse en efectivo del día
                primero al cinco de cada mes, por período adelantado, en el
                domicilio de LA RESIDENCIA o donde este lo indique. Las
                internaciones efectuadas a partir del día cinco de cada mes, se
                abonarán mensualmente en la misma fecha que la anterior, también
                por adelantado. El pago que no se hiciere efectivo en las fechas
                mencionadas anteriormente, sufrirá un interés punitorio de 0.25
                % diario.-
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                CUARTA: Ambas partes podrán resolver el presente contrato,
                previa notificación fehaciente, hecha con no menos de quince
                días (15) de anticipación. En caso de que la resolución sea por
                parte del INTERNADO y/o su REPRESENTANTE, deberá abonar el total
                del mes.-
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                QUINTA: Producida la rescisión o el fallecimiento del INTERNADO,
                este deberá ser retirado del instituto, previa liquidación de
                gastos, si los hubiere, En caso contrario el Instituto lo
                trasladará al domicilio de su REPRESENTANTE. En ausencia de
                este, en el domicilio de un familiar, Hospital Público o
                dependencia Policial, a costa de su REPRESENTANTE y/o
                derechohabientes, sin responsabilidad alguna por parte del
                Instituto.-
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                SEXTA: El REPRESENTANTE, se hará cargo ante un traslado o una
                emergencia de acompañar al INTERNADO, en caso de que el
                REPRESENTANTE, no esté dispuesto a acompañarlo o no se halle en
                su domicilio en dicha oportunidad.- Responderá por los gastos
                del acompañante.
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                SEPTIMA: LA RESIDENCIA provee el Control Médico de Clínica Gral.
                (Realizado por profesionales médicos), en caso de que las
                dolencias que afectan al INTERNADO, requieran de medicina
                especializada, y/o atención que exija internación, y/o cuidados
                permanentes, el enfermo será trasladado al siguiente
                establecimiento:{" "}
                {paciente &&
                clinicas &&
                paciente.clinicaId !== undefined &&
                paciente.clinicaId !== null &&
                paciente.clinicaId !== 0
                  ? clinicas[
                      clinicas.findIndex(
                        (item) => item.id === paciente.clinicaId
                      )
                    ].label
                  : " "}{" "}
                ubicado en la calle{" "}
                {paciente &&
                clinicas &&
                paciente.clinicaId !== undefined &&
                paciente.clinicaId !== null &&
                paciente.clinicaId !== 0
                  ? clinicas[
                      clinicas.findIndex(
                        (item) => item.id === paciente.clinicaId
                      )
                    ].domicilio
                  : " "}{" "}
                de la localidad{" "}
                {paciente &&
                clinicas &&
                paciente.clinicaId !== undefined &&
                paciente.clinicaId !== null &&
                paciente.clinicaId !== 0
                  ? clinicas[
                      clinicas.findIndex(
                        (item) => item.id === paciente.clinicaId
                      )
                    ].localidad
                  : " "}{" "}
                De no ser posible su internación en dicho establecimiento, se le
                internará en aquel que el Instituto considere adecuado, quedando
                los gastos a cargo de su REPRESENTANTE.-
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                OCTAVA: Una vez que el Instituto notifica al REPRESENTANTE de la
                internación o traslado a otro sitio, cesa la responsabilidad de
                LA RESIDENCIA. Aclarándose que en caso de fallecimiento, o pase
                a PAMI, cualquiera sea el día, no se reintegrará suma alguna del
                período mensual abonado.-{" "}
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                NOVENA: La falta de pago de una cuota, produce la mora de pleno
                derecho, quedando rescindido el contrato, sin necesidad de
                notificación alguna, siendo devuelto el internado a su
                domicilio, o al de su REPRESENTANTE. De no ser posible por
                cualquier causa, será internado en un Htal. Público o
                dependencia policial, siendo responsable su REPRESENTANTE y los
                parientes por los daños y perjuicios.-
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle2" gutterBottom component="div">
                DECIMA: Son obligaciones a cargo del INTERNADO y/o su
                REPRESENTANTE, los pagos de las cuotas mensuales, de los
                materiales descartables (pañales, etc.) de la medicación
                prescrita (cualquiera sea esta) , efectos de uso personales,
                elementos de higiene y confort, gastos de traslados y
                transporte; gastos de sepelio que hubiese obrado LA RESIDENCIA,
                en el caso que se le hubiere exigido la garantía para
                internación, sustituyendo dicha garantía por otra suficiente que
                se hiciere responsable por los daños y perjuicios que hubieran.-
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                UNDECIMA: El REPRESENTANTE DEL INTERNADO, se constituye como
                fiador de este contrato en carácter de codeudor solidario, liso
                y llanos y principal pagador; renunciando a los beneficios de
                excusión y división, hasta hacer íntegro el pago de todas las
                obligaciones emergentes del mismo, que pudiese adeudar
                respondiendo también por gastos y honorarios a que el mismo
                diese lugar.-
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                DUODECIMA: LA RESIDENCIA no se responsabiliza por la pérdida de
                todo tipo de pertenencias de los INTERNADOS, que no sean de uso
                cotidiano, quedando bajo la exclusiva responsabilidad del
                INTERNADO y/o su REPRESENTANTE la pérdida de objetos de valor no
                declarados o que no fuesen de absoluta necesidad para el
                quehacer diario, valores, joyas, teléfonos celulares; ya que
                para lo cual LA RESIDENCIA pondrá todos los elementos para el
                desarrollo cotidiano y además de una caja de seguridad para el
                resguardo de dichos objetos, valores y cualquier tipo de moneda
                (siempre deberán ser declarados). Al ingreso se realizara un
                detalle de la ropa con que ingresa como así también del total de
                las pertenencias que se adjunta al presente y se tomara como
                válido...-
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                PARA LOS AFILIADOS AL INSSJP (PAMI), OSPLAD, OSBA. El
                RESPONSABLE cumplirá las siguientes obligaciones; cuales se
                notifica:
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                1-Aceptar el descuento en concepto de co-seguro, explicitando
                los términos y alcances del mismo.-
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                2-Entrega de D.N.I y O.P.P en el establecimiento en que resida
                el/la titular.-
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                3-Presentación en OS ante el requerimiento de sus
                representantes.-
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                4-Cobertura de pañales, elementos de tocador, vestimenta,
                descartables y medicación.-
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                5-Las solicitudes de traslado de la Obra Social serán
                fehacientemente justificadas caso contrario no se dará lugar a
                la misma.-
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                6-Las comunicaciones entre ambas partes se realizaran en forma
                telefónica y vía mail, de acuerdo a las direcciones declarados
                en esta misma historia integral, de no responder estas
                direcciones serán tomadas como válidas, dejando copia como
                prueba suficiente de la comunicación, dando aviso a la oficina
                de la obra social, del INSSJP de la UGL correspondiente.
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                7-La residencia ejerce el derecho de aceptación y permanencia,
                de cada uno de los residentes.
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                LEIDO TODO LO QUE ANTECEDE. Las partes ratifican su contenido,
                que previamente les fue explicado, y de plena conformidad
                firman.
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                Mail: {paciente.email} TEL: {paciente.telefonoComunicaciones}
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                Mail: {paciente.emailAlternativo} TEL:{" "}
                {paciente.telefonoUrgencias}
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                component="div"
                align="right"
              >
                A los, ….. Días del mes de: ………..….…. De 20..….-
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                Firma del Representante
                ______________________________________________________________
              </Typography>

              <Typography
                variant="subtitle2"
                gutterBottom
                component="div"
                marginTop={"1vw"}
              >
                Aclaración:__________________________________________________________________________
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                component="div"
                marginTop={"1vw"}
              >
                LA RESIDENCIA
                ____________________________________________________________________
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                component="div"
                marginTop={"1vw"}
              >
                Aclaración___________________________________________________________________________
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h6"
                style={{ textDecoration: "underline" }}
                align="center"
                marginBottom="1vw"
              >
                CUESTIONARIO FAMILIAR DE PRE-INGRESO: SOBRE FRAGILIDAD.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                Nombre y Apellido Responsable: {paciente.nombreRepresentante} -
                Vínculo:{" "}
                {paciente.vinculoId !== undefined && paciente.vinculoId !== null
                  ? vinculos[
                      vinculos.findIndex(
                        (item) => item.id === paciente.vinculoId
                      )
                    ].label
                  : " "}
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                Nombre y Apellido Residente: {paciente.nombre}
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                Residentes a quienes se les debe considerar personas de alto
                riesgo, en cuanto a su estado de salud o su evolución. Los
                residentes necesitados de cuidados y vigilancia específicos,
                constituyen un conjunto para el que parece necesario que las
                Residencias cuenten con organización, infraestructuras, equipos
                y conocimientos adecuados.
              </Typography>

              <Typography
                variant="subtitle2"
                style={{ textDecoration: "underline", fontWeight: "bold" }}
              >
                • Aquejados de problemas, deficiencias, incapacidades,
                psicológicas y sociales:
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Recién ingresados.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Con antecedentes de suicidio.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Pacientes depresivos.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Que han sufrido una perdida familiar
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Residentes agresivos (golpean o se golpean).
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Que acaban de jubilarse y manifiestan problemas secundarios a
                la falta de trabajo.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Procedentes de altas hospitalarias.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Residentes abandonados por sus familiares.
              </Typography>

              <Typography
                variant="subtitle2"
                style={{ textDecoration: "underline", fontWeight: "bold" }}
              >
                • Quejas de problemas, incapacidades o minusvalías psicofísicas
                y mentales:
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Portadores de sondas o colostomías. Residentes con otras
                enfermedades digestivas que implican riesgo vital o alto
                componente incapacitante.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Con medicación vital o de difícil manejo.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Con enfermedades productivas de déficit de la visión (tensión
                ocular, anti-agregación, etc.).
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Con graves déficit auditivos (hipoacusias que requieren
                audífonos o sorderas).
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Amputados.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Residentes que necesitan de asistencia para la respiración
                (oxigenación y/o aspiración).
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Residentes con necesidad de control de glucemia y de otras
                constantes vitales.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Con secuelas de fracturas de cadera, fractura de colles,
                aplastamientos vertebrales.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Con artrosis y osteoartritis.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Con epilepsia.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Residentes con incapacidad para salir al exterior.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Residentes con enfermedades oncológicas.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Alcohólicos/as o drogadictos/as.
              </Typography>

              <Typography variant="subtitle2" gutterBottom component="div">
                • Enfermos terminales.
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="subtitle2" gutterBottom component="div">
                Diagnóstico Principal:{" "}
                {paciente &&
                diagnosticos &&
                paciente.diagnosticoId !== undefined && paciente.diagnosticoId !== null && paciente.diagnosticoId !== 0
                  ? diagnosticos[
                      diagnosticos.findIndex(
                        (item) => item.id === paciente.diagnosticoId
                      )
                    ].label
                  : " "}{" "}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="subtitle2" gutterBottom component="div">
                Medicación que recibe:{" "}
                {medicacionesPacientes
                  ? medicacionesPacientes.map((medicacion) => (
                         "•" + medicacion.label + "  "
                    ))
                  : null}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography
                variant="h6"
                style={{ textDecoration: "underline" }}
                align="center"
                marginBottom="1vw"
              >
                CONCENTIMIENTO INFORMADO:
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                Declaración de consentimiento
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                El sr/a {paciente.nombre} de {getEdad(paciente.fechaNacimiento)}{" "}
                años de edad, con domicilio en {paciente.domicilio}{" "}
                {paciente && localidades && paciente.localidadId !== undefined
                  ? localidades[
                      localidades.findIndex(
                        (item) => item.id === paciente.localidadId
                      )
                    ].label
                  : " "}{" "}
                y DNI {paciente.numeroDocumento} acepta las condiciones
                particulares explicadas y conoce los pormenores y los riesgos de
                su internación en la institución. Representante legal (nombre y
                apellidos) {paciente.nombreRepresentante} de{" "}
                {getEdad(paciente.fechaNacimientoRepresentante)} años de edad,
                con domicilio en {paciente.domicilioRepresentante}{" "}
                {paciente &&
                localidades &&
                paciente.localidadRepresentanteId !== undefined &&
                paciente.localidadRepresentanteId !== null
                  ? localidades[
                      localidades.findIndex(
                        (item) => item.id === paciente.localidadRepresentanteId
                      )
                    ].label
                  : " "}{" "}
                y DNI {paciente.numeroDocumentoRepresentante}, en calidad de
                (indicar relación con la persona usuaria):{" "}
                {paciente.vinculoRepresentante} DECLARO: Que el DR. JOSE CLAUDIO
                RODRIGO, quien desempeña en el centro de internación Geriátrica{" "}
                {geriatrico.nombre} El cargo de Director médico y su plantel
                profesional me ha informado de forma comprensible y en un lugar
                privado y adecuado, acerca de los aspectos fundamentales
                relacionados con el ingreso en el centro. Ha contestado a mis
                dudas y me ha dado tiempo suficiente para reflexionar sobre la
                información recibida. Se me informo de la metodología de trabajo
                y funcionamiento del centro, mis obligaciones y derechos. Tanto
                así de cómo se desarrollan las actividades y las normativas de
                convivencia de la comunidad a la cual perteneceré. También para
                mis familiares. Sé que el instituto de internación es pago y
                privado, me informaron de los aranceles mensuales de este. Como
                así donde se encuentra ubicado. Acepto los alcances y accionar
                de mi obra social para con la institución, que se me ha
                explicado. También así las costumbres para una adecuada
                convivencia con los demás residentes, pudiendo rechazarlas en
                cualquier momento sin explicación alguna y conociendo la
                libertad que tengo de retirarme y/o ausentarme por mi propia
                voluntad. ACEPTO las todas las actividades que se me ofrecen y
                que puedo realizar en forma voluntarias( no obligatorias),
                también los tratamientos que deberé cumplir, sabiendo que puedo
                concurrir a mi médico particular si así yo lo dispusiera y
                cambiarlo a mi propia voluntad. Por ello, otorgo libremente el
                presente consentimiento, del cual se me facilita una copia,
                sabiendo que lo puedo retirar en cualquier momento.
              </Typography>
              <Typography
                variant="h6"
                style={{ textDecoration: "underline" }}
                align="center"
                marginBottom="1vw"
              >
                INGRESO DE PERSONAS O ELEMENTOS NO AUTORIZADOS
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                Por algún motivo deben ingresar a la institución algún material,
                remedios, cremas, comidas u otros útiles utensilios (no
                autorizados). Personas que por alguna razón no puedan estar
                debidamente autorizados. Con el control necesario y la
                autorización pertinente.
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                Cuales son:……………………………………………………………………………………………………………..
                ……………………………………………………………………………………………………………………………………………………………………………….
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                ………de………………………de 20…….
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                Firma residente………………………………………………….DNI…………………………………………………………..
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                Representante
                legal……………………………………………………………………………………………………………………..…………
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                (Si la persona está incapacitada para firmar)
                DNI…………………………………………………………………..…….………
              </Typography>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
