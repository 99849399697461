import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../../context/AlertContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "../Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { jsPDF } from "jspdf";

export default function ListadosCaidas(props) {

  const exportColumns = [
    { title: "Apellido Nombre", dataKey: "nombre" },
    { title: "Fecha", dataKey: "fecha" },
    { title: "Consecuencias", dataKey: "consecuencias" },
    { title: "Lugar", dataKey: "lugar" },
    { title: "Tratamiento", dataKey: "tratamiento" },
  ];

  const [listado, setListado] = useState({
    orientacion: "vertical",
    camposSeleccionados: [],
  });
  const [visible, setVisible] = useState(true);
  const toast = useRef(null);

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const { statusAcceso, pacientes, obtenerAcceso, listadoPacientesCaidas } =
    serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  const exitClick = (e) => {
    navigate("/");
  };

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Listados",
      });
    }
  }, [state]);

  const onHide = (e) => {
    setVisible(false);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };


  const aceptarClick = async (exportType) => {
    //e.preventDefault();
    // Validar|

    if (
      !listado ||
      listado.fechaDesde === undefined ||
      listado.fechaDesde === null ||
      listado.fechaDesde.trim() === ""
    ) {
      mensajeAlerta("Caídas", "Fecha desde es necesario !.", "error");
      return;
    }


    if (
      !listado ||
      listado.fechaHasta === undefined ||
      listado.fechaHasta === null ||
      listado.fechaHasta.trim() === ""
    ) {
      mensajeAlerta("Caídas", "Fecha hasta es necesario !.", "error");
      return;
    }

    var elem;

    await listadoPacientesCaidas({
      listado,
      desde: listado.fechaDesde,
      hasta: listado.fechaHasta,
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    }).then((data) => {
      if (exportType === "excel") {
        elem = document.getElementById("expButtonExcel");
        elem.click();
      }
      if (exportType === "pdf") {
        elem = document.getElementById("expButtonPdf");
        elem.click();
      }
    });
    onHide();
    //exitClick();
  };

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Listado de Caídas"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <button
          id="expButtonExcel"
          type="button"
          onClick={(e) => {
            exportExcel();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Excel
        </button>
        <button
          id="expButtonPdf"
          type="button"
          onClick={(e) => {
            exportPdf();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Pdf
        </button>
        <Button
          icon="pi pi-file-excel"
          onClick={(e) => {
            aceptarClick("excel");
          }}
          className="p-button-raised p-button-success"
          data-pr-tooltip="XLS"
          label="Excel"
        />
        <Button
          icon="pi pi-file-pdf"
          onClick={(e) => {
            aceptarClick("pdf");
          }}
          className="p-button-raised p-button-warning"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
          label="PDF"
        />
        {/*
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        */}
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };


  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(pacientes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "residentes_caidas");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().toLocaleDateString() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
    startClick();
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc =
          listado.orientacion === "vertical"
            ? new jsPDF.default(0, 0)
            : new jsPDF.default("l");
        doc.setFontSize(12);
        if (listado.orientacion === "vertical") {
          doc.text("Listado de caídas Desde " + formatDate(listado.fechaDesde) + " Hasta " + formatDate(listado.fechaHasta) , 30, 8);
        } else {
          doc.text("Listado de caídas Desde " + formatDate(listado.fechaDesde) + " Hasta " + formatDate(listado.fechaHasta), 120, 8);
        }
        doc.autoTable(exportColumns, pacientes, {
          styles: {
            fontSize: 7,
          },
        });
        window.open(doc.output('bloburl'))        
        //doc.save("residentes_caidas_" + new Date().toLocaleDateString() +".pdf");
      });
    });
    startClick();
  };

  const startClick = (e) => {
    setTimeout(() => {
      //navigate("listadosservicios");
      setVisible(true);
    }, 2000);
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  //console.log(pacientes);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeader()}
            visible={visible}
            closable={false}
            footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "75vw", paddingBottom: "0" }}
            position="center"
          >
            <Grid container spacing={3} style={{ marginTop: "0vw" }}>
              <Grid item xs={12} md={3}>
                <TextField
                  id="fechaDesde"
                  label="Fecha Desde"
                  type="date"
                  variant="standard"
                  fullWidth
                  value={listado ? listado.fechaDesde : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setListado({
                      ...listado,
                      fechaDesde: e.target.value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id="fechaHasta"
                  label="Fecha Hasta"
                  type="date"
                  variant="standard"
                  fullWidth
                  value={listado ? listado.fechaHasta : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setListado({
                      ...listado,
                      fechaHasta: e.target.value,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Dialog>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}
/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
