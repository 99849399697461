//imports front
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { AuthContext } from "../../context/AuthContext";
import { AlertContext } from "../../context/AlertContext";
import { Navigate } from "react-router";
import * as EmailValidator from "email-validator";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';


const theme = createTheme();

export default function Login(props) {
  const authContext = useContext(AuthContext);
  const { autenticado, iniciarSesion } = authContext;

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const [usuario, guardarUsuario] = useState({
    email: "",
    password: "",
  });

  const [redirect, setRedirect] = useState(false);

  // extraer de usuario
  const { email, password } = usuario;

  const login = (e) => {
    e.preventDefault();
    //Validar

    if (email.trim() === "") {
      mensajeAlerta("Login", "El email es necesario !.", "error");
      return;
    }
    if (!EmailValidator.validate(email)) {
      mensajeAlerta("Login", "El email es incorrecto !.", "error");
      return;
    }

    if (password.trim() === "") {
      mensajeAlerta("Login", "El password es incorrecto !.", "error");
      return;
    }

    if (password.length < 6) {
      mensajeAlerta(
        "Login",
        "El password debe tener al menos 6 caracteres !.",
        "error"
      );
      return;
    }

    // Llamar al context
    iniciarSesion({ email, password });
    setRedirect(true);
  };

  //console.log(configuracion);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Iniciar Sesión
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Dirección de Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) =>
                guardarUsuario({
                  ...usuario,
                  email: e.target.value,
                })
              }
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) =>
                guardarUsuario({
                  ...usuario,
                  password: e.target.value,
                })
              }
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recuerdame"
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={login}
            >
              Iniciar Sesión
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/reset" variant="body2">
                  ¿Olvido su password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {redirect ? <Navigate to="/" /> : null}
      </Container>
    </ThemeProvider>
  );
}
