import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../../context/AlertContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "../Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { jsPDF } from "jspdf";
import { NumeroLetras } from "../util/NumeroaLetras";

export default function ListadosRecetas(props) {
  const exportColumns = [
    { title: "Medicación", dataKey: "label" },
    { title: "Inicio", dataKey: "inicio" },
    { title: "Final", dataKey: "final" },
    { title: "Dosis Mg.", dataKey: "dosis" },
    { title: "Vía", dataKey: "via" },
    { title: "Hora 1", dataKey: "headerHora1" },
    { title: "D", dataKey: "d" },
    { title: "A", dataKey: "a" },
    { title: "M", dataKey: "m" },
    { title: "C", dataKey: "c" },
    { title: "Hora 2", dataKey: "headerHora2" },
    { title: "Cantidad", dataKey: "cantidad" },
  ];

  const [listado, setListado] = useState({
    orientacion: "vertical",
    camposSeleccionados: [],
  });
  const [visible, setVisible] = useState(true);
  const toast = useRef(null);

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    guardadas,
    pacientes,
    planes,
    barthel,
    fragilidad,
    lawtonBrody,
    yesavage,
    tinetti,
    norton,
    dietas,
    medicacionesPacientes,
    diagnosticosPacientes,
    ingresosEgresos,
    evaluaciones,
    obrasSociales,
    obrasSocialesMedicas,
    obtenerAcceso,
    geriatricoElegido,
    obtenerObrasSociales,
    obtenerObrasSocialesMedicasPacientes,
    obtenerPlanes,
    listadoRecetas,
    obtenerMedicacionesPacientesIndividual,
    obtenerDiagnosticosPacientesIndividual,
    obtenerIngresosEgresos,
    obtenerInformesPreIngreso,
    setGeriatricoElegido,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  const exitClick = (e) => {
    navigate("/");
  };

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Listados",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    //obtenerObrasSociales({ todos: true });
    obtenerObrasSocialesMedicasPacientes({ todos: false });
    obtenerPlanes();
  }, []);

  const onHide = (e) => {
    setVisible(false);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  function getEdad(dateString) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  }

  function formatDate2(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  const imprimir = async (e) => {
    const doc = new jsPDF();

    let hoy = new Date();

    let fechaDia = hoy.getDate();
    let fechaMes = hoy.getMonth() + 1;
    let fechaAnio = hoy.getFullYear();
    let nombreMes = "";

    if (fechaMes === 1) {
      nombreMes = "Enero";
    }
    if (fechaMes === 2) {
      nombreMes = "Febrero";
    }
    if (fechaMes === 3) {
      nombreMes = "Marzo";
    }
    if (fechaMes === 4) {
      nombreMes = "Abril";
    }
    if (fechaMes === 5) {
      nombreMes = "Mayo";
    }
    if (fechaMes === 6) {
      nombreMes = "Junio";
    }
    if (fechaMes === 7) {
      nombreMes = "Julio";
    }
    if (fechaMes === 8) {
      nombreMes = "Agosto";
    }
    if (fechaMes === 9) {
      nombreMes = "Septiembre";
    }
    if (fechaMes === 10) {
      nombreMes = "Octubre";
    }
    if (fechaMes === 11) {
      nombreMes = "Noviembre";
    }
    if (fechaMes === 12) {
      nombreMes = "Diciembre";
    }

    for (var i = 0; i < guardadas.length; i++) {
      doc.setFontSize(12);

      doc.setFont("Time-BoldItalic", "bold");
      doc.text(geriatricoElegido.nombre, 15, 8);

      doc.rect(10, 12, 190, 25);

      doc.setFontSize(14);

      doc.setFont("Time-BoldItalic", "bold");
      doc.text("RECETA", 85, 20);

      doc.setFont("Times-Roman", "normal");

      doc.setFontSize(10);

      let row = 25;

      doc.text(
        "Paciente: " +
          (guardadas[i].pacientes.nombre ? guardadas[i].pacientes.nombre : ""),
        12,
        row,
        "left",
        "middle"
      );

      doc.text(
        "D.N.I.: " +
          (guardadas[i].pacientes.numeroDocumento
            ? guardadas[i].pacientes.numeroDocumento
            : ""),

        130,
        row,
        "left",
        "middle"
      );

      row = row + 5;

      doc.text(
        "Obra Social Médica: " +
          (guardadas[i].pacientes.obraSocialId
            ? obrasSocialesMedicas[
                obrasSocialesMedicas.findIndex(
                  (item) => item.id === guardadas[i].pacientes.obraSocialId
                )
              ].label
            : ""),
        12,
        row,
        "left",
        "middle"
      );

      doc.text(
        "Nº Obra Social: " +
          (guardadas[i].pacientes.numeroObraSocial
            ? guardadas[i].pacientes.numeroObraSocial
            : ""),

        130,
        row,
        "left",
        "middle"
      );

      row = row + 5;

      doc.text(
        "Plan: " +
          (guardadas[i].pacientes.planId
            ? planes[
                planes.findIndex(
                  (item) => item.id === guardadas[i].pacientes.planId
                )
              ].nombre
            : ""),
        12,
        row,
        "left",
        "middle"
      );

      doc.text("Fecha: " + formatDate(hoy), 130, row, "left", "middle");

      //Medicamento 1
      if (guardadas[i].detalle1) {
        row = row + 15;

        doc.text(
          guardadas[i].medicamento1 + " " + guardadas[i].detalle1,
          12,
          row,
          "left",
          "middle"
        );

        if (guardadas[i].marca1) {
          row = row + 7;

          doc.text(
            guardadas[i].marca1 + " " + guardadas[i].detalle1,
            12,
            row,
            "left",
            "middle"
          );
        }

        row = row + 7;

        doc.text(
          String(guardadas[i].cantidad1) +
            " = " +
            NumeroLetras(guardadas[i].cantidad1, false),
          15,
          row,
          "left",
          "middle"
        );

        if (guardadas[i].observacion1) {
          doc.text(guardadas[i].observacion1, 40, row, "left", "middle");
        }
      }
      row = row + 7;

      //Medicamento 2
      if (guardadas[i].detalle2) {
        row = row + 15;

        doc.text(
          guardadas[i].medicamento2 + " " + guardadas[i].detalle2,
          12,
          row,
          "left",
          "middle"
        );

        if (guardadas[i].marca2) {
          row = row + 7;

          doc.text(
            guardadas[i].marca2 + " " + guardadas[i].detalle2,
            12,
            row,
            "left",
            "middle"
          );
        }

        row = row + 7;

        doc.text(
          String(guardadas[i].cantidad2) +
            " = " +
            NumeroLetras(guardadas[i].cantidad2, false),
          15,
          row,
          "left",
          "middle"
        );

        if (guardadas[i].observacion2) {
          doc.text(guardadas[i].observacion2, 40, row, "left", "middle");
        }
      }
      row = row + 7;

      //Medicamento 3
      if (guardadas[i].detalle3) {
        row = row + 15;

        doc.text(
          guardadas[i].medicamento3 + " " + guardadas[i].detalle3,
          12,
          row,
          "left",
          "middle"
        );

        if (guardadas[i].marca3) {
          row = row + 7;

          doc.text(
            guardadas[i].marca3 + " " + guardadas[i].detalle3,
            12,
            row,
            "left",
            "middle"
          );
        }

        row = row + 7;

        doc.text(
          String(guardadas[i].cantidad3) +
            " = " +
            NumeroLetras(guardadas[i].cantidad3, false),
          15,
          row,
          "left",
          "middle"
        );

        if (guardadas[i].observacion3) {
          doc.text(guardadas[i].observacion3, 40, row, "left", "middle");
        }
      }
      row = row + 7;

      row = 270;

      doc.text(
        "_______________________________________",
        70,
        row,
        "left",
        "middle"
      );

      if (i < guardadas.length - 1) {
        doc.addPage();
      }
    }

    window.open(doc.output("bloburl"));
  };

  const aceptarClick = async (exportType) => {
    //e.preventDefault();
    // Validar|

    var elem;

    await listadoRecetas({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    }).then((data) => {
      if (exportType === "pdf") {
        elem = document.getElementById("expButtonPdf");
        elem.click();
      }
    });
    onHide();
    //exitClick();
  };

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Listado de Recetas"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <button
          id="expButtonPdf"
          type="button"
          onClick={(e) => {
            imprimir();
          }}
          style={{ display: "none" }}
        >
          Export Pdf
        </button>

        <ButtonMui
          onClick={(e) => {
            aceptarClick("pdf");
          }}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
          autoFocus
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("-");
  }

  //console.log(guardadas);
  //console.log(dietas);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeader()}
            visible={visible}
            closable={false}
            footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "75vw", paddingBottom: "0" }}
            position="center"
          ></Dialog>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}
/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
