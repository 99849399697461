import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";

export default function ChequeoCumplimientos(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    chequeoCumplimientos,
    statusAcceso,
    statusAcceso3,
    localidades,
    guardarChequeoCumplimientos,
    obtenerAcceso,
  } = serviciosContext;

  const handleChangePregunta1 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta1: event.target.checked,
    });
  };

  const handleChangePregunta2 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta2: event.target.checked,
    });
  };

  const handleChangePregunta3 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta3: event.target.checked,
    });
  };

  const handleChangePregunta4 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta4: event.target.checked,
    });
  };

  const handleChangePregunta5 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta5: event.target.checked,
    });
  };

  const handleChangePregunta6 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta6: event.target.checked,
    });
  };

  const handleChangePregunta7 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta7: event.target.checked,
    });
  };

  const handleChangePregunta8 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta8: event.target.checked,
    });
  };

  const handleChangePregunta9 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta9: event.target.checked,
    });
  };

  const handleChangePregunta10 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta10: event.target.checked,
    });
  };

  const handleChangePregunta11 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta11: event.target.checked,
    });
  };

  const handleChangePregunta12 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta12: event.target.checked,
    });
  };

  const handleChangePregunta13 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta13: event.target.checked,
    });
  };

  const handleChangePregunta14 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta14: event.target.checked,
    });
  };

  const handleChangePregunta15 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta15: event.target.checked,
    });
  };

  const handleChangePregunta16 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta16: event.target.checked,
    });
  };

  const handleChangePregunta17 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta17: event.target.checked,
    });
  };

  const handleChangePregunta18 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta18: event.target.checked,
    });
  };

  const handleChangePregunta19 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta19: event.target.checked,
    });
  };

  const handleChangePregunta20 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta20: event.target.checked,
    });
  };

  const handleChangePregunta21 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta21: event.target.checked,
    });
  };

  const handleChangePregunta22 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta22: event.target.checked,
    });
  };

  const handleChangePregunta23 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta23: event.target.checked,
    });
  };

  const handleChangePregunta24 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta24: event.target.checked,
    });
  };

  const handleChangePregunta25 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta25: event.target.checked,
    });
  };

  const handleChangePregunta26 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta26: event.target.checked,
    });
  };

  const handleChangePregunta27 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta27: event.target.checked,
    });
  };

  const handleChangePregunta28 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta28: event.target.checked,
    });
  };

  const handleChangePregunta29 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta29: event.target.checked,
    });
  };
  const handleChangePregunta30 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta30: event.target.checked,
    });
  };
  const handleChangePregunta31 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta31: event.target.checked,
    });
  };
  const handleChangePregunta32 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta32: event.target.checked,
    });
  };
  const handleChangePregunta33 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta33: event.target.checked,
    });
  };
  const handleChangePregunta34 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta34: event.target.checked,
    });
  };
  const handleChangePregunta35 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta35: event.target.checked,
    });
  };
  const handleChangePregunta36 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta36: event.target.checked,
    });
  };
  const handleChangePregunta37 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta37: event.target.checked,
    });
  };
  const handleChangePregunta38 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta38: event.target.checked,
    });
  };
  const handleChangePregunta39 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta39: event.target.checked,
    });
  };
  const handleChangePregunta40 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta40: event.target.checked,
    });
  };
  const handleChangePregunta41 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta41: event.target.checked,
    });
  };
  const handleChangePregunta42 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta42: event.target.checked,
    });
  };
  const handleChangePregunta43 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta43: event.target.checked,
    });
  };

  const handleChangePregunta44 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta44: event.target.checked,
    });
  };

  const handleChangePregunta45 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta45: event.target.checked,
    });
  };

  const handleChangePregunta46 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta46: event.target.checked,
    });
  };

  const handleChangePregunta47 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta47: event.target.checked,
    });
  };

  const handleChangePregunta48 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta48: event.target.checked,
    });
  };

  const handleChangePregunta49 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta49: event.target.checked,
    });
  };

  const handleChangePregunta50 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta50: event.target.checked,
    });
  };

  const handleChangePregunta51 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta51: event.target.checked,
    });
  };

  const handleChangePregunta52 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta52: event.target.checked,
    });
  };

  const handleChangePregunta53 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta53: event.target.checked,
    });
  };

  const handleChangePregunta54 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta54: event.target.checked,
    });
  };

  const handleChangePregunta55 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta55: event.target.checked,
    });
  };

  const handleChangePregunta56 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta56: event.target.checked,
    });
  };

  const handleChangePregunta57 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta57: event.target.checked,
    });
  };

  const handleChangePregunta58 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta58: event.target.checked,
    });
  };

  const handleChangePregunta59 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta59: event.target.checked,
    });
  };

  const handleChangePregunta60 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta60: event.target.checked,
    });
  };

  const handleChangePregunta61 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta61: event.target.checked,
    });
  };

  const handleChangePregunta62 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta62: event.target.checked,
    });
  };

  const handleChangePregunta63 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta63: event.target.checked,
    });
  };
  const handleChangePregunta64 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta64: event.target.checked,
    });
  };
  const handleChangePregunta65 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta65: event.target.checked,
    });
  };
  const handleChangePregunta66 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta66: event.target.checked,
    });
  };
  const handleChangePregunta67 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta67: event.target.checked,
    });
  };
  const handleChangePregunta68 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta68: event.target.checked,
    });
  };
  const handleChangePregunta69 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta69: event.target.checked,
    });
  };
  const handleChangePregunta70 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta70: event.target.checked,
    });
  };
  const handleChangePregunta71 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta71: event.target.checked,
    });
  };
  const handleChangePregunta72 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta72: event.target.checked,
    });
  };
  const handleChangePregunta73 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta73: event.target.checked,
    });
  };
  const handleChangePregunta74 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta74: event.target.checked,
    });
  };
  const handleChangePregunta75 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta75: event.target.checked,
    });
  };
  const handleChangePregunta76 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta76: event.target.checked,
    });
  };
  const handleChangePregunta77 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta77: event.target.checked,
    });
  };
  const handleChangePregunta78 = (event) => {
    guardarChequeoCumplimientos({
      ...chequeoCumplimientos,
      pregunta78: event.target.checked,
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  //console.log(chequeoCumplimientos);

  return (
    <Fragment>
      <Fragment>
        <Grid
          container
          spacing={3}
          style={{ marginTop: "0vw", marginLeft: "0vw" }}
        >
          <Grid item xs={12} md={7} style={{ marginTop: "1vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCumplimientos ? chequeoCumplimientos.pregunta1 : null}
                    onChange={handleChangePregunta1}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Medición de intensidad lumínica."
                labelPlacement="end"
                //disabled={chequeoCumplimientos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "0vw" }}>
            <TextField
              id="comentario1"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCumplimientos ? chequeoCumplimientos.comentario1 : null}
              onChange={(e) => {
                guardarChequeoCumplimientos({
                  ...chequeoCumplimientos,
                  comentario1: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCumplimientos ? chequeoCumplimientos.pregunta2 : null}
                    onChange={handleChangePregunta2}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Simulacros de evacuación."
                labelPlacement="end"
                //disabled={chequeoCumplimientos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario2"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCumplimientos ? chequeoCumplimientos.comentario2 : null}
              onChange={(e) => {
                guardarChequeoCumplimientos({
                  ...chequeoCumplimientos,
                  comentario2: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCumplimientos ? chequeoCumplimientos.pregunta3 : null}
                    onChange={handleChangePregunta3}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Práctica de extinción de incendios."
                labelPlacement="end"
                //disabled={chequeoCumplimientos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario3"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCumplimientos ? chequeoCumplimientos.comentario3 : null}
              onChange={(e) => {
                guardarChequeoCumplimientos({
                  ...chequeoCumplimientos,
                  comentario3: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCumplimientos ? chequeoCumplimientos.pregunta4 : null}
                    onChange={handleChangePregunta4}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Presentación de RAR (Relevamientos de Agentes de Riesgo)."
                labelPlacement="end"
                //disabled={chequeoCumplimientos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario4"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCumplimientos ? chequeoCumplimientos.comentario4 : null}
              onChange={(e) => {
                guardarChequeoCumplimientos({
                  ...chequeoCumplimientos,
                  comentario4: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCumplimientos ? chequeoCumplimientos.pregunta5 : null}
                    onChange={handleChangePregunta5}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Plan de capacitación anual. Roles del personal."
                labelPlacement="end"
                //disabled={chequeoCumplimientos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario5"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCumplimientos ? chequeoCumplimientos.comentario5 : null}
              onChange={(e) => {
                guardarChequeoCumplimientos({
                  ...chequeoCumplimientos,
                  comentario5: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCumplimientos ? chequeoCumplimientos.pregunta6 : null}
                    onChange={handleChangePregunta6}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Capacitación: Tipos de fuego y métodos de extinción."
                labelPlacement="end"
                //disabled={chequeoCumplimientos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario6"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCumplimientos ? chequeoCumplimientos.comentario6 : null}
              onChange={(e) => {
                guardarChequeoCumplimientos({
                  ...chequeoCumplimientos,
                  comentario6: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCumplimientos ? chequeoCumplimientos.pregunta7 : null}
                    onChange={handleChangePregunta7}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Análisis de agua. Bactereológico y físico químico."
                labelPlacement="end"
                //disabled={chequeoCumplimientos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario7"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCumplimientos ? chequeoCumplimientos.comentario7 : null}
              onChange={(e) => {
                guardarChequeoCumplimientos({
                  ...chequeoCumplimientos,
                  comentario7: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCumplimientos ? chequeoCumplimientos.pregunta8 : null}
                    onChange={handleChangePregunta8}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Limpieza de tanques."
                labelPlacement="end"
                //disabled={chequeoCumplimientos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario8"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCumplimientos ? chequeoCumplimientos.comentario8 : null}
              onChange={(e) => {
                guardarChequeoCumplimientos({
                  ...chequeoCumplimientos,
                  comentario8: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCumplimientos ? chequeoCumplimientos.pregunta9 : null}
                    onChange={handleChangePregunta9}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Desratización."
                labelPlacement="end"
                //disabled={chequeoCumplimientos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario9"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCumplimientos ? chequeoCumplimientos.comentario9 : null}
              onChange={(e) => {
                guardarChequeoCumplimientos({
                  ...chequeoCumplimientos,
                  comentario9: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCumplimientos ? chequeoCumplimientos.pregunta10 : null}
                    onChange={handleChangePregunta10}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Estudio ergonómico en caso de ser requerido."
                labelPlacement="end"
                //disabled={chequeoCumplimientos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario10"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCumplimientos ? chequeoCumplimientos.comentario10 : null}
              onChange={(e) => {
                guardarChequeoCumplimientos({
                  ...chequeoCumplimientos,
                  comentario10: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCumplimientos ? chequeoCumplimientos.pregunta11 : null}
                    onChange={handleChangePregunta11}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Espacio de trabajo."
                labelPlacement="end"
                //disabled={chequeoCumplimientos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario11"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCumplimientos ? chequeoCumplimientos.comentario11 : null}
              onChange={(e) => {
                guardarChequeoCumplimientos({
                  ...chequeoCumplimientos,
                  comentario11: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCumplimientos ? chequeoCumplimientos.pregunta12 : null}
                    onChange={handleChangePregunta12}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Contar con las herramientas necesarias y control de stock de materiales."
                labelPlacement="end"
                //disabled={chequeoCumplimientos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario12"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCumplimientos ? chequeoCumplimientos.comentario12 : null}
              onChange={(e) => {
                guardarChequeoCumplimientos({
                  ...chequeoCumplimientos,
                  comentario12: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCumplimientos ? chequeoCumplimientos.pregunta13 : null}
                    onChange={handleChangePregunta13}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Limpio y ordenado."
                labelPlacement="end"
                //disabled={chequeoCumplimientos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario13"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCumplimientos ? chequeoCumplimientos.comentario13 : null}
              onChange={(e) => {
                guardarChequeoCumplimientos({
                  ...chequeoCumplimientos,
                  comentario13: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCumplimientos ? chequeoCumplimientos.pregunta14 : null}
                    onChange={handleChangePregunta14}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Iluminado y ventilado."
                labelPlacement="end"
                //disabled={chequeoCumplimientos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario14"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCumplimientos ? chequeoCumplimientos.comentario14 : null}
              onChange={(e) => {
                guardarChequeoCumplimientos({
                  ...chequeoCumplimientos,
                  comentario14: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoCumplimientos ? chequeoCumplimientos.pregunta15 : null}
                    onChange={handleChangePregunta15}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Medición de puesta a tierra."
                labelPlacement="end"
                //disabled={chequeoCumplimientos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario15"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoCumplimientos ? chequeoCumplimientos.comentario15 : null}
              onChange={(e) => {
                guardarChequeoCumplimientos({
                  ...chequeoCumplimientos,
                  comentario15: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} />
        </Grid>
      </Fragment>
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
