import React, { Fragment, useContext, useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { AlertContext } from "../../context/AlertContext";
import { Link, Navigate, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { ServiciosContext } from "../../context/ServiciosContext";
import Spinner from "../layout/Spinner";
import Autocomplete from "@mui/material/Autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { AuthContext } from "../../context/AuthContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Menu from "./Menu";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";

const theme = createTheme();

export default function AccesosGeriatricos(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  let navigate = useNavigate();

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    usuarios,
    pantallas,
    rows,
    establecimientos,
    statusAcceso,
    geriatricoElegido,
    obtenerUsuarios,
    agregarAccesosGeriatricos,
    obtenerAccesos,
    obtenerAcceso,
    obtenerAccesosGeriatricos,
    setRows,
    setGeriatricoElegido,
  } = serviciosContext;

  const [usuario, guardarUsuario] = useState(null);
  const [id, setId] = useState(null);

  const [selectedRow, setSelectedRow] = useState([]);

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    obtenerUsuarios();
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "AccesosGeriatricos" });
    }
  }, [state]);

  const onChangeUsuario = (e, value) => {
    e.preventDefault();
    if (value) {
      guardarUsuario({
        ...usuario,
        usuarioId: value.id,
        usuarioNombre: value.usuario,
        usuarioLocal: value.local,
        usuarioExterior: value.exterior,
      });

      obtenerAccesosGeriatricos({ usuarioId: value.id });
    } else {
      guardarUsuario(null);
    }
  };

  const exitClick = (e) => {
    navigate("/");
    window.location.reload();
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    if (!usuario || usuario.usuarioNombre.trim() === "") {
      mensajeAlerta("El usuario es necesario !.", "error");
      return;
    }

    if (!rows) {
      mensajeAlerta("Debe seleccionar algún geriátrico !.", "error");
      return;
    }

    agregarAccesosGeriatricos({ rows, usuario });
    localStorage.removeItem("geriatricoElegidoId");
    localStorage.removeItem("geriatricoElegidoNombre");
    setGeriatricoElegido(null)
    exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Acceso",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const estadoCheckbox = (rowData) => {
    console.log("si");
  };

  //console.log(statusAcceso);

  return (
    <Fragment>
      <Menu />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="md">
              <CssBaseline />
              <Box
                sx={{
                  width: 800,
                  marginTop: 8,
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Accesos a usuarios
                </Typography>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="usuarios"
                      fullWidth
                      options={usuarios}
                      getOptionLabel={(option) => option.usuario}
                      renderInput={(params) => (
                        <TextField {...params} label="Usuario" />
                      )}
                      onChange={(event, value) => onChangeUsuario(event, value)}
                    />
                  </Grid>
                  {usuario && establecimientos ? (
                    <Fragment>
                      <Grid item xs={12} md={12}>
                        <div className="datatable-responsive-demo">
                          <div className="card">
                            <DataTable
                              value={establecimientos}
                              selection={rows}
                              onSelectionChange={(e) => setRows(e.value)}
                              dataKey="id"
                              //header={header}
                              className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                              style={{ fontSize: "small" }}
                              globalFilter={globalFilter}
                              emptyMessage="No hay datos."
                              paginator
                              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                              currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                              rows={10}
                              rowsPerPageOptions={[10, 20, 50]}
                              //paginatorLeft={paginatorLeft}
                              //paginatorRight={paginatorRight}
                            >
                              <Column field="id" header="ID" sortable></Column>
                              <Column
                                field="nombre"
                                header="Nombre"
                                sortable
                              ></Column>
                              <Column
                                selectionMode="multiple"
                                headerStyle={{ width: "3em" }}
                              ></Column>
                            </DataTable>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Button
                          type="button"
                          fullWidth
                          variant="contained"
                          style={{ backgroundColor: "green" }}
                          onClick={aceptarClick}
                        >
                          Aceptar
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Button
                          type="button"
                          fullWidth
                          variant="contained"
                          style={{ backgroundColor: "#D8261C" }}
                          onClick={exitClick}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                    </Fragment>
                  ) : null}
                </Grid>
              </Box>
            </Container>
          </ThemeProvider>
        </Fragment>
      ) : null}
    </Fragment>
  );
}
