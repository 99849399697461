import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Typography from "@mui/material/Typography";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Autocomplete from "@mui/material/Autocomplete";


export default function Medicaciones(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowFormato, setSelectedRowFormato] = useState(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Tipo de Medicación", dataKey: "tipoMedicacion" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const tiposMedicaciones = [
    { id: 1, nombre: "MEDICACION" },
    { id: 2, nombre: "DIETA" },
    { id: 3, nombre: "PRACTICA" },
  ];


  const [medicacion, guardarMedicacion] = useState(null);
  const [formato, guardarFormato] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleFormato, setVisibleFormato] = useState(false);
  const toast = useRef(null);
  const [exit, setExit] = useState(false);
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    medicaciones,
    formatos,
    unidades,
    vias,
    obtenerMedicaciones,
    obtenerAcceso,
    agregarMedicacion,
    actualizarMedicacion,
    guardarFormatos,
    obtenerFormatos,
    actualizarFormato,
    agregarFormato,
    eliminarFormato,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Medicaciones",
      });
    }
    obtenerMedicaciones({ todos: true });
  }, [state]);

  const editClick = (e) => {
    if (selectedRow) {
      obtenerFormatos({ id: selectedRow.id });
      guardarMedicacion(selectedRow);
      setSelectedRowFormato(null);
      setTimeout(() => {
        setVisible(true);
      }, 1000);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una medicación.",
        life: 3000,
      });
    }
  };

  const editClickFormato = (e) => {
    if (selectedRowFormato) {
      guardarFormato(selectedRowFormato);
      setTimeout(() => {
        setVisibleFormato(true);
      }, 1000);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un formato .",
        life: 3000,
      });
    }
  };

  const deleteClickFormato = (e) => {
    if (selectedRowFormato) {
      confirmDialog({
        message: `Desea eliminar ${selectedRowFormato.detalle} ?`,
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si",
        accept,
        //reject,
      });
    } else {
      toast.current.show({
        severity: "info",
        summary: "Eliminar",
        detail: "Debe seleccionar un formato .",
        life: 3000,
      });
    }
  };

  const accept = () => {
    eliminarFormato({ id: selectedRowFormato.id });
    setTimeout(() => {
      obtenerFormatos({ id: selectedRow.id });
    }, 2000);
  };

  const addClick = (e) => {
    toast.current.show({
      severity: "info",
      summary: "Horarios",
      detail:
        "Despues de agregar el medicamento, puede editarlo y agregar las presentaciónes.",
      life: 5000,
    });
    //props.history.push(`/medicacion/0`);
    setVisible(true);
    setSelectedRow(null);
    guardarMedicacion({
      habilitado: true,
    });
  };

  const addClickFormato = (e) => {
    //props.history.push(`/medicacion/0`);
    setVisibleFormato(true);
    setSelectedRowFormato(null);
    guardarFormato(null);
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHideFormato = (e) => {
    setVisibleFormato(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !medicacion ||
      medicacion.nombre === undefined ||
      medicacion.nombre === null ||
      medicacion.nombre.trim() === ""
    ) {
      mensajeAlerta("Medicaciones", "El nombre es necesario !.", "error");
      return;
    }

    if (
      !medicacion ||
      medicacion.tipoMedicacionId === undefined ||
      medicacion.tipoMedicacionId === null ||
      medicacion.tipoMedicacionId === ""
    ) {
      mensajeAlerta("Medicaciones", "El tipo es necesario !.", "error");
      return;
    }


    // Llamar al context
    if (selectedRow) {
      actualizarMedicacion({ medicacion });
    } else {
      agregarMedicacion({ medicacion });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerMedicaciones({ todos: true });
    }, 2000);
    //exitClick();
  };

  const aceptarClickFormato = (e) => {
    e.preventDefault();
    //Validar
    if (
      !formato ||
      formato.detalle === undefined ||
      formato.detalle === null ||
      formato.detalle.trim() === ""
    ) {
      mensajeAlerta("Presentación", "El detalle es necesario !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRowFormato) {
      actualizarFormato({ formato, medicacionId: selectedRow.id });
    } else {
      agregarFormato({ formato, medicacionId: selectedRow.id });
    }
    
    onHideFormato();
    setSelectedRowFormato(null);
    
    setTimeout(() => {
      obtenerFormatos({ id: selectedRow.id });
    }, 2000);
    
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const telefonoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Télefono</span>
        {rowData.telefono}
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Habilitado</span>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, medicaciones);
        doc.save("medicaciones.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(medicaciones);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "medicaciones");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Medicaciones"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Medicación"></Tag>
      </div>
    );
  };

  const renderHeaderFormato = () => {
    return (
      <div>
        <Tag value="Presentaciones de Medicamento"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e) => aceptarClick(e)}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={(e) => onHide()}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderFooterFormato = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e) => aceptarClickFormato(e)}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideFormato}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(unidades);
  //console.log(statusAcceso);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {medicaciones ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  align: "center",
                  margin: "1em",
                  marginTop:
                    isMobile && !isPortrait
                      ? "22vw"
                      : isMobile && isPortrait
                      ? "55vw"
                      : "0vw",
                }}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={(e) => editClick()}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={medicaciones}
                      selection={selectedRow}
                      onSelectionChange={(e) => {
                        guardarFormatos([]);
                        setSelectedRow(e.value);
                      }}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50, 100, 500]}
                      responsiveLayout="scroll"
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Nombre"
                        body={nombreTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="tipoMedicacion"
                        header="Tipo de Medicación"
                        //body={nombreTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="habilitado"
                        header="Habilitado"
                        body={habilitadoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  {selectedRow ? (
                    <Grid item xs={12} md={2}>
                      <TextField
                        value={medicacion ? medicacion.id : null}
                        required
                        id="id"
                        label="ID"
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  ) : null}

                  <Grid item xs={12} md={10}>
                    <TextField
                      value={medicacion ? medicacion.nombre : null}
                      required
                      id="nombre"
                      label="Nombre"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedicacion({
                          ...medicacion,
                          nombre: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="tipo"
                      fullWidth
                      options={
                        tiposMedicaciones
                          ? tiposMedicaciones
                          : null
                      }
                      disablePortal
                      getOptionLabel={(option) => option.nombre}
                      value={
                        medicacion && medicacion.tipoMedicacion
                          ? tiposMedicaciones[
                              tiposMedicaciones.findIndex(
                                (elem) => elem.id === medicacion.tipoMedicacionId
                              )
                            ]
                          : null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Tipo *"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, values) => {
                        if (values) {
                          guardarMedicacion({
                            ...medicacion,
                            tipoMedicacionId: values.id,
                            tipoMedicacion: values.nombre,
                          })
                        } else {
                          guardarMedicacion({
                            ...medicacion,
                            tipoMedicacionId: null,
                            tipoMedicacion: null,
                          })
                        }
                      }}
                    />
                  </Grid>


                  <Grid item xs={12} md={6} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={medicacion ? medicacion.habilitado : null}
                          style={{ marginLeft: "1em" }}
                          onChange={(e) =>
                            guardarMedicacion({
                              ...medicacion,
                              habilitado: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Habilitado"
                      labelPlacement="start"
                    />
                  </Grid>

                  {selectedRow ? (
                    <Fragment>
                      <Grid item xs={12} md={12} style={{ marginTop: "0vw" }}>
                        <Typography
                          variant="h6"
                          style={{
                            textDecoration: "underline",
                            color: "coral",
                          }}
                        >
                          Presentación
                        </Typography>
                      </Grid>

                      <Grid
                        container
                        spacing={1}
                        style={{ marginTop: "0vw", marginLeft: "0vw" }}
                      >
                        <Grid item xs={12} md={12}>
                          <Button
                            icon="pi pi-plus"
                            tooltip="Agregar"
                            className="p-button-rounded p-button-help"
                            style={{ marginLeft: "0em" }}
                            onClick={addClickFormato}
                          />
                          <Button
                            icon="pi pi-pencil"
                            tooltip="Editar"
                            className="p-button-rounded p-button-primary"
                            style={{ marginLeft: "1em" }}
                            onClick={(e) => editClickFormato()}
                          />
                          <Button
                            icon="pi pi-trash"
                            tooltip="Eliminar"
                            className="p-button-rounded p-button-danger"
                            style={{ marginLeft: "1em" }}
                            onClick={deleteClickFormato}
                          />

                          <div className="datatable-responsive-demo">
                            <div className="card">
                              <Tooltip
                                target=".export-buttons>button"
                                position="bottom"
                              />
                              <DataTable
                                value={formatos}
                                selection={selectedRowFormato}
                                onSelectionChange={(e) =>
                                  setSelectedRowFormato(e.value)
                                }
                                selectionMode="single"
                                dataKey="id"
                                //header={header}
                                className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                                style={{ fontSize: "small", marginTop: "1vw" }}
                                //globalFilter={globalFilter}
                                emptyMessage="No hay datos."
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                responsiveLayout="scroll"

                                //paginatorLeft={paginatorLeft}
                                //paginatorRight={paginatorRight}
                              >
                                <Column
                                  field="id"
                                  header="ID"
                                  //body={idTemplate}
                                  sortable
                                ></Column>
                                <Column
                                  field="detalle"
                                  header="Presentación / Receta"
                                  //body={nombreTemplate}
                                  sortable
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Fragment>
                  ) : null}
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderFormato()}
                visible={visibleFormato}
                closable={false}
                footer={renderFooterFormato()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  {selectedRow ? (
                    <Grid item xs={12} md={2}>
                      <TextField
                        value={formato ? formato.id : null}
                        required
                        id="id"
                        label="ID"
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={10}>
                    <TextField
                      value={formato ? formato.detalle : null}
                      required
                      id="detalle"
                      label="Detalle / Receta"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarFormato({
                          ...formato,
                          detalle: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
      {exit ? <Navigate to="/login" /> : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
