import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import DatosPersonales from "./DatosPersonales";
import Barthel from "./Barthel";
import LawtonBrody from "./LawtonBrody";
import ExamenI from "./ExamenI";
import ExamenII from "./ExamenII";
import ExamenIII from "./ExamenIII";
import Evoluciones from "./Evoluciones";
import EscalaValoracionMental from "./EscalaValoracionMental";
import EvaluacionNutricional from "./EvaluacionNutricional";
import Fragilidad from "./Fragilidad";
import Antecedentes from "./Antecedentes";
import Actual from "./Actual";
import "../../assets/css/toast.css";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import vgi from "../../assets/images/vgi.png";



export default function HistoriasClinicas(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;

  const anios = [
    {
      id: 1,
      label: "2022",
      actual: false,
    },
    {
      id: 1,
      label: "2023",
      actual: true,
    },
    {
      id: 1,
      label: "2024",
      actual: false,
    },
    {
      id: 1,
      label: "2025",
      actual: false,
    },
  ];

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Domicilio", dataKey: "domicilio" },
    { title: "Teléfono", dataKey: "telefono" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [dialogPacientes, setDialogPacientes] = useState(false);
  const toast = useRef(null);

  const [exit, setExit] = useState(false);
  let navigate = useNavigate();

  const steps = [
    "Datos Personales",
    "Marcadores de Fragilidad",
    "Antecedentes Personales",
    "Estado Actual",
    "Exámen Físico I",
    "Exámen Físico II",
    "Exámen Físico III",
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    statusAcceso2,
    statusAcceso3,
    statusAcceso4,
    statusAcceso5,
    historiasClinicas,
    ingresosEgresos,
    obrasSociales,
    obrasSocialesMedicas,
    medicacionesPacientes,
    diagnosticosPacientes,
    estudiosPacientes,
    paciente,
    pacientes,
    barthel,
    lawtonBrody,
    tinetti,
    reloj,
    escalaValoracionMental,
    norton,
    yesavage,
    tipoDieta,
    evaluacionNutricional,
    historiaClinica,
    geriatricoElegido,
    visible,
    fragilidad,
    antecedente,
    actual,
    row,
    examenI,
    examenII,
    examenIII,
    frases,
    evolucion,
    selectedRowPaciente,
    validarHistoriaClinica,
    obtenerHistoriasClinicas,
    obtenerAcceso,
    obtenerAcceso2,
    obtenerAcceso3,
    obtenerAcceso4,
    obtenerAcceso5,
    agregarHistoriaClinica,
    actualizarHistoriaClinica,
    setGeriatricoElegido,
    obtenerPaciente,
    obtenerPacientes,
    guardarHistoriaClinica,
    verificarHistoriaClinica,
    setVisible,
    obtenerInformesHistoriaClinica,
    setSelectedRowPaciente,
    guardarEscalaValoracionMental,
    guardarFragilidad,
    guardarAntecedentes,
    obtenerInformeFragilidad,
    guardarEvolucion,
    obtenerMedicacionesPacientesIndividual,
    obtenerDiagnosticosPacientesIndividual,
    obtenerDiagnosticosPacientes,
    obtenerEstudiosPacientesIndividual,
    obtenerEstudiosPacientes,
    obtenerMedicacionesPacientes,
    obtenerIngresosEgresos,
    obtenerObrasSocialesPacientes,
    obtenerObrasSocialesMedicasPacientes,
    obtenerTiposDietas,
    obtenerFrasesVgiHc,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "HistoriasClinicas",
      });
      obtenerAcceso3({
        usuarioId: state.usuario.id,
        layout: "EditarPIHC",
      });
      obtenerAcceso4({
        usuarioId: state.usuario.id,
        layout: "Psicologia",
      });
      obtenerAcceso5({
        usuarioId: state.usuario.id,
        layout: "Nutricion",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    obtenerHistoriasClinicas({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    });
    obtenerPacientes({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    });
    obtenerObrasSocialesPacientes({ todos: false });
    obtenerObrasSocialesMedicasPacientes({ todos: false });
    obtenerDiagnosticosPacientes({ todos: false });
    obtenerMedicacionesPacientes({ todos: false });
    obtenerEstudiosPacientes({ todos: false });
    obtenerFrasesVgiHc();
  }, []);

  const editClick = (e) => {
    if (statusAcceso3 !== 200) {
      toast.current.show({
        severity: "error",
        summary: "Editar historia clínica",
        detail: "No tiene permiso para acceder a esta pantalla !.",
        life: 3000,
      });
      return;
    }

    if (selectedRow) {
      guardarHistoriaClinica(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una historia clínica.",
        life: 3000,
      });
    }
  };

  const actClick = (e) => {
    if (selectedRow) {
      guardarHistoriaClinica(selectedRow);
      obtenerPaciente({ pacienteId: selectedRow.pacienteId });
      obtenerInformesHistoriaClinica({ pacienteId: selectedRow.pacienteId });
      obtenerMedicacionesPacientesIndividual({
        pacienteId: selectedRow.pacienteId,
      });
      obtenerDiagnosticosPacientesIndividual({
        pacienteId: selectedRow.pacienteId,
      });
      obtenerEstudiosPacientesIndividual({
        pacienteId: selectedRow.pacienteId,
      });
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una historia clínica.",
        life: 3000,
      });
    }
  };

  const editClickPsicologia = (e) => {
    if (statusAcceso4 !== 200) {
      toast.current.show({
        severity: "error",
        summary: "Psicología historia clínica",
        detail: "No tiene permiso para acceder a esta pantalla !.",
        life: 3000,
      });
      return;
    }

    if (selectedRow) {
      guardarHistoriaClinica(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una historia clínica.",
        life: 3000,
      });
    }
  };

  const editClickNutricion = (e) => {
    if (statusAcceso5 !== 200) {
      toast.current.show({
        severity: "error",
        summary: "Nutrición historia clínica",
        detail: "No tiene permiso para acceder a esta pantalla !.",
        life: 3000,
      });
      return;
    }

    if (selectedRow) {
      guardarHistoriaClinica(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una historia clínica.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //props.history.push(`/historiaClínica/0`);
    //setVisible(true);
    //obtenerInformeFragilidad({ pacienteId: selectedRow.pacienteId });
    setDialogPacientes(true);
    setSelectedRowPaciente(null);
    setActiveStep(0);
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHidePacientes = (e) => {
    setSelectedRowPaciente(null);
    setDialogPacientes(false);
  };

  const aceptarClickPacientes = (e) => {
    if (selectedRowPaciente) {
      verificarHistoriaClinica({ selectedRowPaciente });
      //obtenerInformeFragilidad({ pacienteId: selectedRowPaciente.id });
      obtenerInformesHistoriaClinica({ pacienteId: selectedRowPaciente.id });
      setDialogPacientes(false);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  /*
  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !historiaClínica ||
      historiaClínica.nombre === undefined ||
      historiaClínica.nombre === null ||
      historiaClínica.nombre.trim() === ""
    ) {
      mensajeAlerta("Geriátricos", "El nombre es necesario !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarHistoriaClinica({ historiaClínica });
    } else {
      agregarHistoriaClinica({ historiaClínica });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerHistoriasClinicas({
        geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
      });
      obtenerPacientes({
        geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
      });
    }, 2000);
    //exitClick();
  };

  */

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const fechaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDate(rowData.fecha)}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const documentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const ingresoEgresoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Ingreso/Egreso</span>
        {formatDate(rowData.fechaIngresoEgreso)}
      </Fragment>
    );
  };

  const motivoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Motivo</span>
        {rowData.motivoIngresoEgreso}
      </Fragment>
    );
  };

  const activoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Activo</span>
        <Checkbox checked={rowData.activo}></Checkbox>
      </Fragment>
    );
  };

  const telefonoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Télefono</span>
        {rowData.telefono}
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Habilitado</span>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, historiasClinicas);
        doc.save("HistoriasClinicas.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(historiasClinicas);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "HistoriasClinicas");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Historias Clínicas"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        {selectedRowPaciente ? (
          <Tag
            value={"Historia Clínica de " + selectedRowPaciente.nombre}
          ></Tag>
        ) : null}
      </div>
    );
  };

  /*
  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  */

  const headerPacientes = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            fullWidth
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Residentes"></Tag>
      </div>
    </Fragment>
  );

  const renderFooterPacientes = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickPacientes}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHidePacientes}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  function formatDateTime(dateString) {
    var allDate = dateString.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  function formatDate2(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  // Steps

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (
      activeStep === 1 &&
      fragilidad &&
      fragilidad.length !== 0 &&
      fragilidad !== null
    ) {
      // Validar fragilidad

      if (
        (fragilidad.lugar === undefined ||
          fragilidad.lugar === null ||
          fragilidad.lugar.trim() === "") &&
        (fragilidad.pregunta1 === undefined ||
          fragilidad.pregunta1 === false) &&
        (fragilidad.pregunta2 === undefined ||
          fragilidad.pregunta2 === false) &&
        (fragilidad.pregunta3 === undefined ||
          fragilidad.pregunta3 === false) &&
        (fragilidad.pregunta4 === undefined ||
          fragilidad.pregunta4 === false) &&
        (fragilidad.pregunta5 === undefined ||
          fragilidad.pregunta5 === false) &&
        (fragilidad.pregunta6 === undefined ||
          fragilidad.pregunta6 === false) &&
        (fragilidad.pregunta7 === undefined ||
          fragilidad.pregunta7 === false) &&
        (fragilidad.pregunta8 === undefined ||
          fragilidad.pregunta8 === false) &&
        (fragilidad.pregunta9 === undefined ||
          fragilidad.pregunta9 === false) &&
        (fragilidad.pregunta10 === undefined ||
          fragilidad.pregunta10 === false) &&
        (fragilidad.pregunta11 === undefined ||
          fragilidad.pregunta11 === false) &&
        (fragilidad.pregunta12 === undefined ||
          fragilidad.pregunta12 === false) &&
        (fragilidad.pregunta13 === undefined ||
          fragilidad.pregunta13 === false) &&
        (fragilidad.pregunta14 === undefined ||
          fragilidad.pregunta14 === false) &&
        (fragilidad.pregunta15 === undefined ||
          fragilidad.pregunta15 === false) &&
        (fragilidad.pregunta16 === undefined ||
          fragilidad.pregunta16 === false) &&
        (fragilidad.pregunta17 === undefined ||
          fragilidad.pregunta17 === false) &&
        (fragilidad.pregunta18 === undefined ||
          fragilidad.pregunta18 === false) &&
        (fragilidad.pregunta19 === undefined ||
          fragilidad.pregunta19 === false) &&
        (fragilidad.pregunta20 === undefined ||
          fragilidad.pregunta20 === false) &&
        (fragilidad.pregunta21 === undefined ||
          fragilidad.pregunta21 === false) &&
        (fragilidad.pregunta22 === undefined ||
          fragilidad.pregunta22 === false) &&
        (fragilidad.pregunta23 === undefined ||
          fragilidad.pregunta23 === false) &&
        (fragilidad.pregunta24 === undefined ||
          fragilidad.pregunta24 === false) &&
        (fragilidad.pregunta25 === undefined ||
          fragilidad.pregunta25 === false) &&
        (fragilidad.pregunta26 === undefined ||
          fragilidad.pregunta26 === false) &&
        (fragilidad.pregunta27 === undefined ||
          fragilidad.pregunta27 === false) &&
        (fragilidad.observaciones === undefined ||
          fragilidad.observaciones === null ||
          fragilidad.observaciones.trim() === "")
      ) {
        guardarFragilidad([]);
        //setActiveStep((prevActiveStep) => prevActiveStep + 1);
        //setSkipped(newSkipped);
        return;
      }

      if (
        fragilidad.lugar === undefined ||
        fragilidad.lugar === null ||
        fragilidad.lugar.trim() === ""
      ) {
        mensajeAlerta(
          "Marcadores de Fragilidad",
          "Debe indicar lugar de derivación !.",
          "info"
        );
        return;
      }

      if (
        fragilidad.observaciones === undefined ||
        fragilidad.observaciones === null ||
        fragilidad.observaciones.trim() === ""
      ) {
        mensajeAlerta(
          "Marcadores de Fragilidad",
          "Debe cuantificar gravedad de positivos !.",
          "info"
        );
        return;
      }
    }

    if (
      activeStep === 4 &&
      examenI &&
      examenI.length !== 0 &&
      examenI !== null
    ) {
      // Validar Lawton y Brody

      {
        /*

      if (examenI.pregunta1 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Estado de Conciencia !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta2 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Estado de Conciencia !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta3 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Estado de Conciencia !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta4 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Estado de Conciencia !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta5 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Estado de Conciencia !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta6 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Piel !.", "info");
        return;
      }

      if (examenI.pregunta7 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Piel !.", "info");
        return;
      }

      if (examenI.pregunta8 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Piel !.", "info");
        return;
      }

      if (examenI.pregunta9 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Piel !.", "info");
        return;
      }

      if (examenI.pregunta10 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Piel !.", "info");
        return;
      }

      if (examenI.pregunta11 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Piel !.", "info");
        return;
      }

      if (examenI.pregunta12 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Cabeza !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta13 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Cabeza !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta14 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Cabeza !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta15 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Cabeza !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta16 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Cabeza !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta71 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Cabeza !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta17 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Ojos !.", "info");
        return;
      }

      if (examenI.pregunta18 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Ojos !.", "info");
        return;
      }

      if (examenI.pregunta19 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Ojos !.", "info");
        return;
      }

      if (examenI.pregunta20 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Ojos !.", "info");
        return;
      }

      if (examenI.pregunta21 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Ojos !.", "info");
        return;
      }

      if (examenI.pregunta22 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Ojos !.", "info");
        return;
      }

      if (examenI.pregunta23 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Ojos !.", "info");
        return;
      }

      if (examenI.pregunta24 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Ojos !.", "info");
        return;
      }

      if (examenI.pregunta25 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Ojos !.", "info");
        return;
      }

      if (examenI.pregunta26 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Ojos !.", "info");
        return;
      }

      if (examenI.pregunta72 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Ojos !.", "info");
        return;
      }

      if (examenI.pregunta27 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Oidos !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta28 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Oidos !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta29 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Oidos !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta30 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Oidos !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta31 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Oidos !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta32 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Oidos !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta73 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Oidos !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta33 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Boca !.", "info");
        return;
      }

      if (examenI.pregunta34 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Boca !.", "info");
        return;
      }

      if (examenI.pregunta35 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Boca !.", "info");
        return;
      }

      if (examenI.pregunta36 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Boca !.", "info");
        return;
      }

      if (examenI.pregunta37 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Boca !.", "info");
        return;
      }

      if (examenI.pregunta38 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Boca !.", "info");
        return;
      }

      if (examenI.pregunta39 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Boca !.", "info");
        return;
      }

      if (examenI.pregunta40 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Boca !.", "info");
        return;
      }

      if (examenI.pregunta41 === undefined) {
        mensajeAlerta("ExamenI", "Debe marcar una opción para Boca !.", "info");
        return;
      }

      if (examenI.pregunta42 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Cuello !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta43 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Cuello !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta44 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Cuello !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta45 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Cuello !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta46 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Cuello !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta47 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Cuello !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta48 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Cuello !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta49 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Cuello !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta50 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Torax !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta51 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Torax !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta52 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Torax !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta53 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Torax !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta54 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Torax !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta55 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Torax !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta56 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Torax !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta57 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Torax !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta58 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Torax !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta59 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Torax !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta60 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Torax !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta61 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Torax !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta74 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Torax !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta62 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Abdomen !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta63 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Abdomen !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta64 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Abdomen !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta65 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Abdomen !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta66 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Abdomen !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta67 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Abdomen !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta68 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Abdomen !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta69 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Abdomen !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta70 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Abdomen !.",
          "info"
        );
        return;
      }

      if (examenI.pregunta75 === undefined) {
        mensajeAlerta(
          "ExamenI",
          "Debe marcar una opción para Abdomen !.",
          "info"
        );
        return;
      }

    */
      }
    }

    if (
      activeStep === 5 &&
      examenII &&
      examenII.length !== 0 &&
      examenII !== null
    ) {
      /*
      if (examenII.pregunta1 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Genitourinarios !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta2 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Genitourinarios !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta3 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Genitourinarios !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta4 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Genitourinarios !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta5 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Genitourinarios !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta6 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Genitourinarios !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta7 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Genitourinarios !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta8 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Genitourinarios !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta9 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Genitourinarios !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta10 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Genitourinarios !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta11 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Genitourinarios !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta12 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Locomotor !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta13 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Locomotor !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta14 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Locomotor !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta15 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Locomotor !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta16 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Locomotor !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta17 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Pulsos !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta18 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Pulsos !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta19 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Pulsos !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta20 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Pulsos !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta21 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Pies !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta22 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Pies !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta23 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Pies !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta24 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Pies !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta25 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Pies !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta26 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Piel y Faneras !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta27 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Piel y Faneras !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta28 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Piel y Faneras !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta29 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Piel y Faneras !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta30 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Piel y Faneras !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta31 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Piel y Faneras !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta32 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Piel y Faneras !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta33 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Piel y Faneras !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta34 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Sistema Nervioso !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta35 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Sistema Nervioso !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta36 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Sistema Nervioso !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta37 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Sistema Nervioso !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta38 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Sistema Nervioso !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta39 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Sistema Nervioso !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta40 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Sistema Nervioso !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta41 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Sistema Nervioso !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta42 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Sistema Nervioso !.",
          "info"
        );
        return;
      }

      if (examenII.pregunta43 === undefined) {
        mensajeAlerta(
          "ExamenII",
          "Debe marcar una opción para Sistema Nervioso !.",
          "info"
        );
        return;
      }

      */
    }

    if (
      activeStep === 6 &&
      examenIII &&
      examenIII.length !== 0 &&
      examenIII !== null
    ) {
      {
        /*
      if (examenIII.pregunta9 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función visual !.",
          "info"
        );
        return;
      }

      if (examenIII.pregunta10 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función visual !.",
          "info"
        );
        return;
      }

      if (examenIII.pregunta11 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función visual !.",
          "info"
        );
        return;
      }
      if (examenIII.pregunta12 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función visual !.",
          "info"
        );
        return;
      }
      if (examenIII.pregunta13 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función visual !.",
          "info"
        );
        return;
      }
      if (examenIII.pregunta14 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función visual !.",
          "info"
        );
        return;
      }
      if (examenIII.pregunta15 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función visual !.",
          "info"
        );
        return;
      }

      if (examenIII.pregunta18 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función auditiva !.",
          "info"
        );
        return;
      }

      if (examenIII.pregunta19 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función auditiva !.",
          "info"
        );
        return;
      }

      if (examenIII.pregunta20 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función auditiva !.",
          "info"
        );
        return;
      }

      if (examenIII.pregunta21 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función auditiva !.",
          "info"
        );
        return;
      }

      if (examenIII.pregunta22 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función auditiva !.",
          "info"
        );
        return;
      }

      if (examenIII.pregunta23 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función auditiva !.",
          "info"
        );
        return;
      }

      if (examenIII.pregunta24 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función auditiva !.",
          "info"
        );
        return;
      }

      if (examenIII.pregunta25 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función auditiva !.",
          "info"
        );
        return;
      }

      if (examenIII.pregunta26 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función auditiva !.",
          "info"
        );
        return;
      }

      if (examenIII.pregunta27 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función auditiva !.",
          "info"
        );
        return;
      }

      if (examenIII.pregunta28 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función auditiva !.",
          "info"
        );
        return;
      }

      if (examenIII.pregunta29 === undefined) {
        mensajeAlerta(
          "ExamenIII",
          "Debe marcar una opción para función auditiva !.",
          "info"
        );
        return;
      }
    */
      }
    }

    if (activeStep === 6) {
      showConfirm();
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const showConfirm = () => {
    confirmDialog({
      message: "Desea guardar los datos ?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  const accept = () => {
    agregarHistoriaClinica({
      selectedRowPaciente,
      geriatricoElegido,
      fragilidad,
      antecedente,
      actual,
      examenI,
      examenII,
      examenIII,
      estudiosPacientes,
      //evolucion,
      selectedRow,
      usuario: state.usuario.usuario,
    });
    guardarEvolucion([]);
    onHide();
    setTimeout(() => {
      obtenerHistoriasClinicas({
        geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
      });
    }, 2000);
  };

  const reject = () => {
    toast.current.show({
      severity: "info",
      summary: "Historia Clínica",
      detail: "Los datos no se han guardado",
      life: 3000,
    });
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const imprimirClick = (e) => {
    if (selectedRow) {
      guardarHistoriaClinica(selectedRow);
      obtenerPaciente({ pacienteId: selectedRow.pacienteId });
      obtenerInformesHistoriaClinica({ pacienteId: selectedRow.pacienteId });
      obtenerMedicacionesPacientesIndividual({
        pacienteId: selectedRow.pacienteId,
      });
      obtenerDiagnosticosPacientesIndividual({
        pacienteId: selectedRow.pacienteId,
      });
      obtenerEstudiosPacientesIndividual({
        pacienteId: selectedRow.pacienteId,
      });
      obtenerIngresosEgresos({ pacienteId: selectedRow.pacienteId });
      var today = new Date();
      var year = today.getFullYear();
      obtenerTiposDietas({
        anio: year,
        pacienteId: selectedRow.pacienteId,
      });

      toast.current.show({
        severity: "info",
        summary: "Imprimir",
        detail: "Obteniendo datos para imprimir.",
        life: 3000,
      });
      setTimeout(() => {
        var elem;
        elem = document.getElementById("imprimirClick");
        elem.click();
      }, 3000);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Imprimir",
        detail: "Debe seleccionar un pre-ingreso.",
        life: 3000,
      });
    }
  };

  function getEdad(dateString) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  }

  const acceptImprimir = () => {
    const doc = new jsPDF();

    let hoy = new Date();

    let fechaDia = hoy.getDate();
    let fechaMes = hoy.getMonth() + 1;
    let fechaAnio = hoy.getFullYear();
    let nombreMes = "";

    if (fechaMes === 1) {
      nombreMes = "Enero";
    }
    if (fechaMes === 2) {
      nombreMes = "Febrero";
    }
    if (fechaMes === 3) {
      nombreMes = "Marzo";
    }
    if (fechaMes === 4) {
      nombreMes = "Abril";
    }
    if (fechaMes === 5) {
      nombreMes = "Mayo";
    }
    if (fechaMes === 6) {
      nombreMes = "Junio";
    }
    if (fechaMes === 7) {
      nombreMes = "Julio";
    }
    if (fechaMes === 8) {
      nombreMes = "Agosto";
    }
    if (fechaMes === 9) {
      nombreMes = "Septiembre";
    }
    if (fechaMes === 10) {
      nombreMes = "Octubre";
    }
    if (fechaMes === 11) {
      nombreMes = "Noviembre";
    }
    if (fechaMes === 12) {
      nombreMes = "Diciembre";
    }

    let row = 10;
    doc.setFontSize(10);
    doc.text("1", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${formatDate2(selectedRow.fecha)}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 20;
    let titulo = "MARCADORES DE FRAGILIDAD";
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text(titulo, 80, row);
    let textWidth = doc.getTextWidth(titulo);
    doc.line(80, row + 2, 80 + textWidth + 1, row + 2);
    row = 40;

    doc.setFont(undefined, "normal");

    doc.text(
      `LUGAR DE DERIVACION casa/ clínica / geriátrico/otro: ${fragilidad.lugar}`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    var columns = ["", "", "", ""];
    var data = [
      [
        "Mayor de 80 años.",
        fragilidad.pregunta1 ? "*" : "",
        "Hospitalización reciente.",
        fragilidad.pregunta2 ? "*" : "",
      ],
      [
        "Estado de abandono.",
        fragilidad.pregunta3 ? "*" : "",
        "Incontinencia.",
        fragilidad.pregunta4 ? "*" : "",
      ],
      [
        "Inmovilización.",
        fragilidad.pregunta5 ? "*" : "",
        "Dependencia total.",
        fragilidad.pregunta6 ? "*" : "",
      ],
      [
        "Caídas recientes.",
        fragilidad.pregunta7 ? "*" : "",
        "Cirugías recientes.",
        fragilidad.pregunta8 ? "*" : "",
      ],
      [
        "Ulceras  decúbito leves.",
        fragilidad.pregunta9 ? "*" : "",
        "Ulceras  decúbito graves.",
        fragilidad.pregunta10 ? "*" : "",
      ],
      [
        "Demencia.",
        fragilidad.pregunta11 ? "*" : "",
        "Depresión.",
        fragilidad.pregunta12 ? "*" : "",
      ],
      [
        "Anorexia.",
        fragilidad.pregunta13 ? "*" : "",
        "Enfermedad Terminal.",
        fragilidad.pregunta14 ? "*" : "",
      ],
      [
        "Deshidratación.",
        fragilidad.pregunta15 ? "*" : "",
        "Desnutrición.",
        fragilidad.pregunta16 ? "*" : "",
      ],
      [
        "Mal estado general.",
        fragilidad.pregunta17 ? "*" : "",
        "Intento de suicidio.",
        fragilidad.pregunta18 ? "*" : "",
      ],
      [
        "Diabetes tipo I.",
        fragilidad.pregunta19 ? "*" : "",
        "Hta .grave.",
        fragilidad.pregunta20 ? "*" : "",
      ],
      [
        "ACV.",
        fragilidad.pregunta21 ? "*" : "",
        "Epilepsia.",
        fragilidad.pregunta22 ? "*" : "",
      ],
      [
        "EPOC.",
        fragilidad.pregunta23 ? "*" : "",
        "Estado de coma.",
        fragilidad.pregunta24 ? "*" : "",
      ],
      [
        "Medicación psiquiatrica.",
        fragilidad.pregunta25 ? "*" : "",
        "Sondas.",
        fragilidad.pregunta26 ? "*" : "",
      ],
      [
        "Ostomias.",
        fragilidad.pregunta27 ? "*" : "",
        "Peligroso para si o terceros.",
        fragilidad.pregunta28 ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: 50, left: 30 },
      styles: {
        fontSize: 9,
      },
      showHead: "never",
    });

    let finalY = doc.previousAutoTable.finalY;

    row = finalY + 10;
    doc.setFontSize(8);

    doc.setFontSize(10);

    doc.text(
      `Total de puntos positivos : ${String(
        Number(fragilidad.pregunta1 ? fragilidad.pregunta1Value : 0) +
          Number(fragilidad.pregunta2 ? fragilidad.pregunta2Value : 0) +
          Number(fragilidad.pregunta3 ? fragilidad.pregunta3Value : 0) +
          Number(fragilidad.pregunta4 ? fragilidad.pregunta4Value : 0) +
          Number(fragilidad.pregunta5 ? fragilidad.pregunta5Value : 0) +
          Number(fragilidad.pregunta6 ? fragilidad.pregunta6Value : 0) +
          Number(fragilidad.pregunta7 ? fragilidad.pregunta7Value : 0) +
          Number(fragilidad.pregunta8 ? fragilidad.pregunta8Value : 0) +
          Number(fragilidad.pregunta9 ? fragilidad.pregunta9Value : 0) +
          Number(fragilidad.pregunta10 ? fragilidad.pregunta10Value : 0) +
          Number(fragilidad.pregunta11 ? fragilidad.pregunta11Value : 0) +
          Number(fragilidad.pregunta12 ? fragilidad.pregunta12Value : 0) +
          Number(fragilidad.pregunta13 ? fragilidad.pregunta13Value : 0) +
          Number(fragilidad.pregunta14 ? fragilidad.pregunta14Value : 0) +
          Number(fragilidad.pregunta15 ? fragilidad.pregunta15Value : 0) +
          Number(fragilidad.pregunta16 ? fragilidad.pregunta16Value : 0) +
          Number(fragilidad.pregunta17 ? fragilidad.pregunta17Value : 0) +
          Number(fragilidad.pregunta18 ? fragilidad.pregunta18Value : 0) +
          Number(fragilidad.pregunta19 ? fragilidad.pregunta19Value : 0) +
          Number(fragilidad.pregunta20 ? fragilidad.pregunta20Value : 0) +
          Number(fragilidad.pregunta21 ? fragilidad.pregunta21Value : 0) +
          Number(fragilidad.pregunta22 ? fragilidad.pregunta22Value : 0) +
          Number(fragilidad.pregunta23 ? fragilidad.pregunta23Value : 0) +
          Number(fragilidad.pregunta24 ? fragilidad.pregunta24Value : 0) +
          Number(fragilidad.pregunta25 ? fragilidad.pregunta25Value : 0) +
          Number(fragilidad.pregunta26 ? fragilidad.pregunta26Value : 0) +
          Number(fragilidad.pregunta27 ? fragilidad.pregunta27Value : 0) +
          Number(fragilidad.pregunta28 ? fragilidad.pregunta28Value : 0)
      )}`,
      30,
      row,
      "left",
      "middle"
    );

    doc.setFontSize(10);

    row = row + 10;

    doc.text(
      `Cuantificar gravedad de los positivos:  ${
        fragilidad.observaciones !== undefined ? fragilidad.observaciones : ""
      }`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    for (var i = 0; i < 5; i++) {
      row = row + 10;
      doc.text(
        "___________________________________________________________________________________",
        30,
        row,
        "left",
        "middle"
      );
    }
    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("2", 200, row);
    row = 10;
    doc.setFontSize(11);
    titulo = "DATOS FILIATORIOS";
    doc.text(titulo, 80, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(80, row + 2, 80 + textWidth + 1, row + 2);
    doc.setFontSize(10);
    row = 20;
    doc.text(
      "Apellido y Nombre: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.nombre !== undefined &&
        selectedRowPaciente.nombre !== null
          ? selectedRowPaciente.nombre
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "D.N.I.: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.numeroDocumento !== undefined &&
        selectedRowPaciente.numeroDocumento !== null
          ? selectedRowPaciente.numeroDocumento
          : ""),

      120,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Fecha: " +
        (selectedRow !== undefined &&
        selectedRow !== null &&
        selectedRow.fecha !== undefined &&
        selectedRow.fecha !== null
          ? formatDate2(selectedRow.fecha)
          : ""),
      150,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Fecha Nacimiento: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.fechaNacimiento !== undefined &&
        selectedRowPaciente.fechaNacimiento !== null
          ? formatDate2(selectedRowPaciente.fechaNacimiento)
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Edad: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.fechaNacimiento !== undefined &&
        selectedRowPaciente.fechaNacimiento !== null
          ? getEdad(selectedRowPaciente.fechaNacimiento)
          : ""),
      80,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Fecha de Ingreso a la Institución: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        ingresosEgresos &&
        selectedRowPaciente.fechaNacimiento !== undefined &&
        selectedRowPaciente.fechaNacimiento !== null
          ? formatDate2(ingresosEgresos[0].fecha)
          : ""),
      110,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Obra Social Médica: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.obraSocialId !== undefined
          ? obrasSocialesMedicas[
              obrasSocialesMedicas.findIndex(
                (item) => item.id === selectedRowPaciente.obraSocialId
              )
            ].label
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Obra Social Contrato: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.obraSocialContratoId !== undefined
          ? obrasSociales[
              obrasSociales.findIndex(
                (item) => item.id === selectedRowPaciente.obraSocialContratoId
              )
            ].label
          : ""),
      110,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text("DIAGNOSTICOS DE INGRESO: ", 10, row, "left", "middle");

    row = row + 5;

    doc.setFontSize(8);

    let fil = 10;
    let j = 0;

    for (var i = 0; i < diagnosticosPacientes.length; i++) {
      j = j + 1;
      if (j === 1) {
        fil = 10;
      }
      if (j === 2) {
        fil = 80;
      }
      if (j === 3) {
        fil = 150;
      }
      doc.text(
        "-" + diagnosticosPacientes[i].label,
        fil,
        row,
        "left",
        "middle"
      );

      if (j === 3) {
        j = 0;
        row = row + 5;
      }

      /*
      let w = doc.getTextDimensions(diagnosticosPacientes[i].label);
      if (w.w > 180) {
        row = row + 10;
      } else {
        row = row + 5;
      }
      */
    }

    doc.setFontSize(10);

    row = row + 10;

    doc.text("MEDICACION: ", 10, row, "left", "middle");

    row = row + 5;

    doc.setFontSize(8);

    fil = 10;
    j = 0;

    for (var i = 0; i < medicacionesPacientes.length; i++) {
      j = j + 1;
      if (j === 1) {
        fil = 10;
      }
      if (j === 2) {
        fil = 80;
      }
      if (j === 3) {
        fil = 150;
      }
      doc.text(
        "-" + medicacionesPacientes[i].label,
        fil,
        row,
        "left",
        "middle"
      );

      if (j === 3) {
        j = 0;
        row = row + 5;
      }
    }
    doc.setFontSize(10);

    row = row + 10;
    doc.text("ANTECEDENTES PERSONALES ", 10, row, "left", "middle");
    row = row + 5;
    doc.setFontSize(9);
    doc.text("ENFERMEDADES PADECIDAS ", 10, row, "left", "middle");
    row = row + 5;
    doc.setFontSize(10);

    var columns = ["", "", "", "", "", ""];
    var data = [
      [
        "Artrosis",
        antecedente.pregunta1 ? "*" : "",
        "Osteoporosis.",
        antecedente.pregunta2 ? "*" : "",
        "Diabetes tipo I / II.",
        antecedente.pregunta3 ? "*" : "",
      ],
      [
        "Gota.",
        antecedente.pregunta4 ? "*" : "",
        "Arterioesclerosis",
        antecedente.pregunta5 ? "*" : "",
        "HTA.",
        antecedente.pregunta6 ? "*" : "",
      ],
      [
        "Insuficiencia cardiaca.",
        antecedente.pregunta7 ? "*" : "",
        "Fiebre reumática.",
        antecedente.pregunta8 ? "*" : "",
        "EPOC.",
        antecedente.pregunta9 ? "*" : "",
      ],
      [
        "Infecciones respiratorias.",
        antecedente.pregunta10 ? "*" : "",
        "Cardiopatía.",
        antecedente.pregunta11 ? "*" : "",
        "Neoplasias.",
        antecedente.pregunta12 ? "*" : "",
      ],
      [
        "Anemia.",
        antecedente.pregunta13 ? "*" : "",
        "Hiperlipidemias.",
        antecedente.pregunta14 ? "*" : "",
        "Insuficiencia renal.",
        antecedente.pregunta15 ? "*" : "",
      ],
      [
        "Alteraciones próstata.",
        antecedente.pregunta16 ? "*" : "",
        "Ulcera péptica.",
        antecedente.pregunta17 ? "*" : "",
        "ACV.",
        antecedente.pregunta18 ? "*" : "",
      ],
      [
        "Parkinson.",
        antecedente.pregunta19 ? "*" : "",
        "Depresión.",
        antecedente.pregunta20 ? "*" : "",
        "Epilepsia.",
        antecedente.pregunta21 ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 10 },
      styles: {
        fontSize: 9,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;
    doc.text(
      antecedente.otrosEnfermedades !== undefined &&
        antecedente.otrosEnfermedades !== null
        ? "Otras: " + antecedente.otrosEnfermedades
        : "Otras: ___________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 20;
    doc.setFontSize(9);
    doc.text(
      "ANTECEDENTES GINECOLOGICOS Y OBSTETRICOS ",
      10,
      row,
      "left",
      "middle"
    );
    row = row + 5;
    doc.setFontSize(10);

    var columns = ["", "", "", ""];
    var data = [
      [
        "Menarca",
        antecedente.pregunta24 ? "*" : "",
        "Menopausia.",
        antecedente.pregunta25 ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 9,
      },
      showHead: "never",
    });

    //doc.text("fin ", 10, row, "left", "middle");

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 15;

    doc.setFontSize(9);
    doc.text("OTROS ANTECEDENTES", 10, row, "left", "middle");
    row = row + 5;
    doc.setFontSize(10);

    var columns = ["", "", "", "", "", ""];
    var data = [
      [
        "Hospitalizaciones previas.",
        antecedente.pregunta26 ? "*" : "",
        "Intervenciones quirúrgicas.",
        antecedente.pregunta27 ? "*" : "",
        "Traumatismos.",
        antecedente.pregunta28 ? "*" : "",
      ],
      [
        "Caídas previas.",
        antecedente.pregunta29 ? "*" : "",
        "Transfusiones.",
        antecedente.pregunta30 ? "*" : "",
        "Hábitos tóxicos.",
        antecedente.pregunta31 ? "*" : "",
      ],
      [
        "Alergias medicamentosas.",
        antecedente.pregunta32 ? "*" : "",
        "Vacunaciones.",
        antecedente.pregunta33 ? "*" : "",
        "Covid.",
        antecedente.pregunta34 ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 9,
      },
      showHead: "never",
    });

    row = row + 35;
    doc.text(
      antecedente.otrosAntecedentes !== undefined &&
        antecedente.otrosAntecedentes !== null
        ? "Otros: " + antecedente.otrosAntecedentes
        : "Otros: ___________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("3", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${formatDate2(selectedRow.fecha)}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 20;

    doc.setFontSize(10);
    doc.text("ESTADO ACTUAL", 10, row, "left", "middle");
    doc.setFontSize(9);
    row = row + 5;
    doc.text("GENERALES", 10, row, "left", "middle");
    doc.setFontSize(10);

    row = row + 2;
    var columns = ["", "", "", "", "", "", "", ""];
    var data = [
      [
        "Aspecto general deteriorado.",
        actual.pregunta1 ? "*" : "",
        "Anorexia.",
        actual.pregunta2 ? "*" : "",
        "Fatiga.",
        actual.pregunta3 ? "*" : "",
        "Deshidratación.",
        actual.pregunta4 ? "*" : "",
      ],
      [
        "Pérdida de peso.",
        actual.pregunta5 ? "*" : "",
        "Prurito.",
        actual.pregunta6 ? "*" : "",
        "Ictericia.",
        actual.pregunta7 ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 9,
      },
      showHead: "never",
    });

    row = row + 25;
    doc.text(
      actual.otrosGenerales !== undefined && actual.otrosGenerales !== null
        ? "Otros: " + actual.otrosGenerales
        : "Otros: ___________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 10;
    doc.text("GASTROINTESTINALES", 10, row, "left", "middle");
    doc.setFontSize(10);

    row = row + 2;
    var columns = ["", "", "", "", "", ""];
    var data = [
      [
        "Nauseas Vómitos.",
        actual.pregunta8 ? "*" : "",
        "Disfagia.",
        actual.pregunta9 ? "*" : "",
        "Pirosis.",
        actual.pregunta10 ? "*" : "",
      ],
      [
        "Polifagia.",
        actual.pregunta11 ? "*" : "",
        "Dolor abdominal.",
        actual.pregunta12 ? "*" : "",
        "Diarrea.",
        actual.pregunta13 ? "*" : "",
      ],
      [
        "Estreñimiento.",
        actual.pregunta14 ? "*" : "",
        "Hemorragias digestivas.",
        actual.pregunta15 ? "*" : "",
        "Polidipsia.",
        actual.pregunta16 ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 9,
      },
      showHead: "never",
    });

    row = row + 32;
    doc.text(
      actual.otrosGastrointestinales !== undefined &&
        actual.otrosGastrointestinales !== null
        ? "Otros: " + actual.otrosGastrointestinales
        : "Otros: ___________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 10;
    doc.text("GENITOURINARIOS", 10, row, "left", "middle");
    doc.setFontSize(10);

    row = row + 2;
    var columns = ["", "", "", "", "", "", "", ""];
    var data = [
      [
        "Disuria.",
        actual.pregunta17 ? "*" : "",
        "Nicturia.",
        actual.pregunta18 ? "*" : "",
        "Poliuria.",
        actual.pregunta19 ? "*" : "",
        "Polaquiurea.",
        actual.pregunta20 ? "*" : "",
      ],
      [
        "Incontinencia.",
        actual.pregunta21 ? "*" : "",
        "Prurito genital.",
        actual.pregunta22 ? "*" : "",
        "Dolor genital.",
        actual.pregunta23 ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 9,
      },
      showHead: "never",
    });

    row = row + 25;
    doc.text(
      actual.otrosGenitourinarios !== undefined &&
        actual.otrosGenitourinarios !== null
        ? "Otros: " + actual.otrosGenitourinarios
        : "Otros: ___________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 10;
    doc.text("CARDIORESPIRATORIOS", 10, row, "left", "middle");
    doc.setFontSize(10);

    row = row + 2;
    var columns = ["", "", "", "", "", ""];
    var data = [
      [
        "Dolor torácico.",
        actual.pregunta24 ? "*" : "",
        "Tos.",
        actual.pregunta25 ? "*" : "",
        "Expectoración.",
        actual.pregunta26 ? "*" : "",
      ],
      [
        "Edemas.",
        actual.pregunta27 ? "*" : "",
        "Disnea.",
        actual.pregunta28 ? "*" : "",
        "Hemoptisis.",
        actual.pregunta29 ? "*" : "",
      ],
      [
        "Hipo.",
        actual.pregunta30 ? "*" : "",
        "TBC.",
        actual.pregunta31 ? "*" : "",
        "Chagas.",
        actual.pregunta32 ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 9,
      },
      showHead: "never",
    });

    row = row + 32;
    doc.text(
      actual.otrosCardiorespiratorios !== undefined &&
        actual.otrosCardiorespiratorios !== null
        ? "Otros: " + actual.otrosCardiorespiratorios
        : "Otros: ___________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 10;
    doc.text("NEUROLOGICOS", 10, row, "left", "middle");
    doc.setFontSize(10);

    row = row + 2;
    var columns = ["", "", "", "", "", ""];
    var data = [
      [
        "Ansiedad.",
        actual.pregunta33 ? "*" : "",
        "Tristeza.",
        actual.pregunta34 ? "*" : "",
        "Alteraciones del lenguaje..",
        actual.pregunta35 ? "*" : "",
      ],
      [
        "Inmovilidad.",
        actual.pregunta36 ? "*" : "",
        "Parálisis.",
        actual.pregunta37 ? "*" : "",
        "Pérdida de memoria.",
        actual.pregunta45 ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 9,
      },
      showHead: "never",
    });

    row = row + 25;
    doc.text(
      actual.otrosNeurologicos !== undefined &&
        actual.otrosNeurologicos !== null
        ? "Otros: " + actual.otrosNeurologicos
        : "Otros: ___________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 10;
    doc.text("LOCOMOTORES", 10, row, "left", "middle");
    doc.setFontSize(10);

    row = row + 2;
    var columns = ["", "", "", "", "", ""];
    var data = [
      [
        "Dolor articulaciones.",
        actual.pregunta38 ? "*" : "",
        "Dolor espalda.",
        actual.pregunta39 ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 9,
      },
      showHead: "never",
    });

    row = row + 17;
    doc.text(
      actual.otrosLocomotores !== undefined && actual.otrosLocomotores !== null
        ? "Otros: " + actual.otrosLocomotores
        : "Otros: ___________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 10;
    doc.text("CEREBRALES", 10, row, "left", "middle");
    doc.setFontSize(10);

    row = row + 2;
    var columns = ["", "", "", "", "", ""];
    var data = [
      [
        "Convulsiones.",
        actual.pregunta40 ? "*" : "",
        "Cefaleas.",
        actual.pregunta41 ? "*" : "",
        "Caídas.",
        actual.pregunta42 ? "*" : "",
      ],
      [
        "Insomnio.",
        actual.pregunta43 ? "*" : "",
        "Demencia.",
        actual.pregunta44 ? "*" : "",
        "A.C.V..",
        actual.pregunta46 ? "*" : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 9,
      },
      showHead: "never",
    });

    row = row + 25;
    doc.text(
      actual.otrosCerebrales !== undefined && actual.otrosCerebrales !== null
        ? "Otros: " + actual.otrosCerebrales
        : "Otros: ___________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("4", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${formatDate2(selectedRow.fecha)}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 20;

    doc.setFontSize(10);
    doc.text("EXAMEN FISICO", 10, row, "left", "middle");
    row = row + 5;
    doc.setFontSize(9);

    doc.text(
      `TA ${examenI.ta1 ? examenI.ta1 : "______"} / ${
        examenI.ta2 ? examenI.ta2 : "______"
      } mmHg (decúbito) ${examenI.ta3 ? examenI.ta3 : "______"} / ${
        examenI.ta4 ? examenI.ta4 : "______"
      } mmHg (bipedestación) hipotensión ortostatica`,
      10,
      row,
      {
        maxWidth: 180,
        align: "justify",
      }
    );

    row = row + 5;

    doc.text(
      `FC ${examenI.fc1 ? examenI.fc1 : "______"} (Decúbito) ${
        examenI.fc2 ? examenI.fc2 : "______"
      } (Bipedestación) Peso: ${
        examenI.peso ? examenI.peso : "______"
      } Kg  Talla: ${examenI.talla ? examenI.talla : "______"} Mts  Tº: ${
        examenI.t ? examenI.t : "______"
      }  FR: ${examenI.fr ? examenI.fr : "______"} `,
      10,
      row,
      {
        maxWidth: 180,
        align: "justify",
      }
    );

    doc.setFontSize(9);
    row = row + 7;
    doc.text("ESTADO DE CONCIENCIA", 10, row, "left", "middle");

    row = row + 2;
    var columns = ["", "", "", "", "", ""];
    var data = [
      [
        "Desorientado temporo-espacialmente.",
        examenI.pregunta1 === undefined || !examenI.pregunta1
          ? "SI  - NO"
          : examenI.pregunta1
          ? "SI* - NO"
          : "SI  - NO*",
        "Revisado normal.",
        examenI.pregunta2 === undefined || !examenI.pregunta2
          ? "SI  - NO"
          : examenI.pregunta2
          ? "SI* - NO"
          : "SI  - NO*",
        "Obnubilación.",
        examenI.pregunta3 === undefined || !examenI.pregunta3
          ? "SI  - NO"
          : examenI.pregunta3
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Estupor.",
        examenI.pregunta4 === undefined || !examenI.pregunta4
          ? "SI  - NO"
          : examenI.pregunta4
          ? "SI* - NO"
          : "SI  - NO*",
        "Coma.",
        examenI.pregunta5 === undefined || !examenI.pregunta5
          ? "SI  - NO"
          : examenI.pregunta5
          ? "SI* - NO"
          : "SI  - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
        cellPadding: 1,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;
    doc.text("PIEL", 10, row, "left", "middle");

    row = row + 2;
    var columns = ["", "", "", "", "", ""];
    var data = [
      [
        "Aspecto normal.",
        examenI.pregunta6 === undefined || !examenI.pregunta6
          ? "SI  - NO"
          : examenI.pregunta6
          ? "SI* - NO"
          : "SI  - NO*",
        "Deshidratacion.",
        examenI.pregunta7 === undefined || !examenI.pregunta7
          ? "SI  - NO"
          : examenI.pregunta7
          ? "SI* - NO"
          : "SI  - NO*",
        "Coloración: normal.",
        examenI.pregunta8 === undefined || !examenI.pregunta8
          ? "SI  - NO"
          : examenI.pregunta8
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Elasticidad: normal.",
        examenI.pregunta9 === undefined || !examenI.pregunta9
          ? "SI  - NO"
          : examenI.pregunta9
          ? "SI* - NO"
          : "SI  - NO*",
        "Humedad: normal.",
        examenI.pregunta10 === undefined || !examenI.pregunta10
          ? "SI  - NO"
          : examenI.PREGUNTA1
          ? "SI* - NO"
          : "SI  - NO*",
        "Turgencia normal.",
        examenI.pregunta11 === undefined || !examenI.pregunta11
          ? "SI  - NO"
          : examenI.pregunta11
          ? "SI* - NO"
          : "SI  - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
        cellPadding: 1,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;
    doc.text(
      examenI.otrosPiel !== undefined && examenI.otrosPiel !== null
        ? "Otros: " + examenI.otrosPiel
        : "Otros: ________________________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 7;
    doc.text("CABEZA", 10, row, "left", "middle");

    row = row + 2;
    var columns = ["", "", "", "", "", ""];
    var data = [
      [
        "Asimetrías.",
        examenI.pregunta12 === undefined || !examenI.pregunta12
          ? "SI  - NO"
          : examenI.pregunta12
          ? "SI* - NO"
          : "SI  - NO*",
        "Dolor senos para nasales.",
        examenI.pregunta13 === undefined || !examenI.pregunta13
          ? "SI  - NO"
          : examenI.pregunta13
          ? "SI* - NO"
          : "SI  - NO*",
        "Latidos.",
        examenI.pregunta14 === undefined || !examenI.pregunta14
          ? "SI  - NO"
          : examenI.pregunta14
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Cicatrices.",
        examenI.pregunta15 === undefined || !examenI.pregunta15
          ? "SI  - NO"
          : examenI.pregunta15
          ? "SI* - NO"
          : "SI  - NO*",
        "Tumoraciones",
        examenI.pregunta16 === undefined || !examenI.pregunta16
          ? "SI  - NO"
          : examenI.pregunta16
          ? "SI* - NO"
          : "SI  - NO*",
        "Revisado normal.",
        examenI.pregunta71 === undefined || !examenI.pregunta71
          ? "SI  - NO"
          : examenI.pregunta71
          ? "SI* - NO"
          : "SI  - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
        cellPadding: 1,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;
    doc.text(
      examenI.otrosCabeza !== undefined && examenI.otrosCabeza !== null
        ? "Otros: " + examenI.otrosCabeza
        : "Otros: ________________________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 7;
    doc.text("OJOS", 10, row, "left", "middle");

    row = row + 2;
    var columns = ["", "", "", "", "", "", "", ""];
    var data = [
      [
        "Entropión.",
        examenI.pregunta17 === undefined || !examenI.pregunta17
          ? "SI  - NO"
          : examenI.pregunta17
          ? "SI* - NO"
          : "SI  - NO*",
        "Ectropión.",
        examenI.pregunta18 === undefined || !examenI.pregunta18
          ? "SI  - NO"
          : examenI.pregunta18
          ? "SI* - NO"
          : "SI  - NO*",
        "Exoftalmos.",
        examenI.pregunta19 === undefined || !examenI.pregunta19
          ? "SI  - NO"
          : examenI.pregunta19
          ? "SI* - NO"
          : "SI  - NO*",
        "Miosis.",
        examenI.pregunta20 === undefined || !examenI.pregunta20
          ? "SI  - NO"
          : examenI.pregunta20
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Ptosis palpebral.",
        examenI.pregunta21 === undefined || !examenI.pregunta21
          ? "SI  - NO"
          : examenI.pregunta21
          ? "SI* - NO"
          : "SI  - NO*",
        "Sequedad ocular.",
        examenI.pregunta22 === undefined || !examenI.pregunta22
          ? "SI  - NO"
          : examenI.pregunta22
          ? "SI* - NO"
          : "SI  - NO*",
        "Conjuntivitis.",
        examenI.pregunta23 === undefined || !examenI.pregunta23
          ? "SI  - NO"
          : examenI.pregunta23
          ? "SI* - NO"
          : "SI  - NO*",
        "Blefaritis.",
        examenI.pregunta24 === undefined || !examenI.pregunta24
          ? "SI  - NO"
          : examenI.pregunta24
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Movimientos oculares.",
        examenI.pregunta25 === undefined || !examenI.pregunta25
          ? "SI  - NO"
          : examenI.pregunta25
          ? "SI* - NO"
          : "SI  - NO*",
        "Nistagmos.",
        examenI.pregunta26 === undefined || !examenI.pregunta26
          ? "SI  - NO"
          : examenI.pregunta26
          ? "SI* - NO"
          : "SI  - NO*",
        "Revisado normal.",
        examenI.pregunta72 === undefined || !examenI.pregunta72
          ? "SI  - NO"
          : examenI.pregunta72
          ? "SI* - NO"
          : "SI  - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
        cellPadding: 1,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;
    doc.text(
      examenI.otrosOjos !== undefined && examenI.otrosOjos !== null
        ? "Otros: " + examenI.otrosOjos
        : "Otros: ________________________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 7;
    doc.text("OIDOS", 10, row, "left", "middle");

    row = row + 2;
    var columns = ["", "", "", "", "", "", "", ""];
    var data = [
      [
        "Tapón cerumen.",
        examenI.pregunta27 === undefined || !examenI.pregunta27
          ? "SI  - NO"
          : examenI.pregunta27
          ? "SI* - NO"
          : "SI  - NO*",
        "Eczema.",
        examenI.pregunta28 === undefined || !examenI.pregunta28
          ? "SI  - NO"
          : examenI.pregunta28
          ? "SI* - NO"
          : "SI  - NO*",
        "Perforación timpánica.",
        examenI.pregunta29 === undefined || !examenI.pregunta29
          ? "SI  - NO"
          : examenI.pregunta29
          ? "SI* - NO"
          : "SI  - NO*",
        "Forúnculo.",
        examenI.pregunta30 === undefined || !examenI.pregunta30
          ? "SI  - NO"
          : examenI.pregunta30
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Otomicosis.",
        examenI.pregunta31 === undefined || !examenI.pregunta31
          ? "SI  - NO"
          : examenI.pregunta31
          ? "SI* - NO"
          : "SI  - NO*",
        "Otitis media.",
        examenI.pregunta32 === undefined || !examenI.pregunta32
          ? "SI  - NO"
          : examenI.pregunta32
          ? "SI* - NO"
          : "SI  - NO*",
        "Revisado normal.",
        examenI.pregunta73 === undefined || !examenI.pregunta73
          ? "SI  - NO"
          : examenI.pregunta73
          ? "SI* - NO"
          : "SI  - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
        cellPadding: 1,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;
    doc.text(
      examenI.otrosOidos !== undefined && examenI.otrosOidos !== null
        ? "Otros: " + examenI.otrosOidos
        : "Otros: ________________________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 7;
    doc.text("BOCA", 10, row, "left", "middle");

    row = row + 2;
    var columns = ["", "", "", "", "", ""];
    var data = [
      [
        "Alt. Dentición.",
        examenI.pregunta33 === undefined || !examenI.pregunta33
          ? "SI  - NO"
          : examenI.pregunta33
          ? "SI* - NO"
          : "SI  - NO*",
        "Prótesis.",
        examenI.pregunta34 === undefined || !examenI.pregunta34
          ? "SI  - NO"
          : examenI.pregunta24
          ? "SI* - NO"
          : "SI  - NO*",
        "Periodontitis.",
        examenI.pregunta35 === undefined || !examenI.pregunta35
          ? "SI  - NO"
          : examenI.pregunta35
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Ulceras.",
        examenI.pregunta36 === undefined || !examenI.pregunta36
          ? "SI  - NO"
          : examenI.pregunta36
          ? "SI* - NO"
          : "SI  - NO*",
        "Candidiasis oral.",
        examenI.pregunta37 === undefined || !examenI.pregunta37
          ? "SI  - NO"
          : examenI.pregunta37
          ? "SI* - NO"
          : "SI  - NO*",
        "Estomatitis.",
        examenI.pregunta38 === undefined || !examenI.pregunta38
          ? "SI  - NO"
          : examenI.pregunta38
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Xerostomía.",
        examenI.pregunta39 === undefined || !examenI.pregunta39
          ? "SI  - NO"
          : examenI.pregunta39
          ? "SI* - NO"
          : "SI  - NO*",
        "Región sublingual normal.",
        examenI.pregunta40 === undefined || !examenI.pregunta40
          ? "SI  - NO"
          : examenI.pregunta40
          ? "SI* - NO"
          : "SI  - NO*",
        "Fauces normales.",
        examenI.pregunta41 === undefined || !examenI.pregunta41
          ? "SI  - NO"
          : examenI.pregunta41
          ? "SI* - NO"
          : "SI  - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
        cellPadding: 1,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;
    doc.text(
      examenI.otrosBoca !== undefined && examenI.otrosBoca !== null
        ? "Otros: " + examenI.otrosBoca
        : "Otros: ________________________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 7;
    doc.text("CUELLO", 10, row, "left", "middle");

    row = row + 2;
    var columns = ["", "", "", "", "", "", "", ""];
    var data = [
      [
        "Forma: simétrico.",
        examenI.pregunta42 === undefined || !examenI.pregunta42
          ? "SI  - NO"
          : examenI.pregunta42
          ? "SI* - NO"
          : "SI  - NO*",
        "cilíndrico.",
        examenI.pregunta43 === undefined || !examenI.pregunta43
          ? "SI  - NO"
          : examenI.pregunta43
          ? "SI* - NO"
          : "SI  - NO*",
        "Latidos anormales.",
        examenI.pregunta44 === undefined || !examenI.pregunta44
          ? "SI  - NO"
          : examenI.pregunta44
          ? "SI* - NO"
          : "SI  - NO*",
        "Ganglios.",
        examenI.pregunta45 === undefined || !examenI.pregunta45
          ? "SI  - NO"
          : examenI.pregunta45
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "PVY.",
        examenI.pregunta46 === undefined || !examenI.pregunta46
          ? "SI  - NO"
          : examenI.pregunta46
          ? "SI* - NO"
          : "SI  - NO*",
        "Tiroides palpable.",
        examenI.pregunta47 === undefined || !examenI.pregunta47
          ? "SI  - NO"
          : examenI.pregunta47
          ? "SI* - NO"
          : "SI  - NO*",
        "Soplos.",
        examenI.pregunta48 === undefined || !examenI.pregunta48
          ? "SI  - NO"
          : examenI.pregunta48
          ? "SI* - NO"
          : "SI  - NO*",
        "Movimientos normales.",
        examenI.pregunta49 === undefined || !examenI.pregunta49
          ? "SI  - NO"
          : examenI.pregunta49
          ? "SI* - NO"
          : "SI  - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
        cellPadding: 1,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;
    doc.text(
      examenI.otrosCuello !== undefined && examenI.otrosCuello !== null
        ? "Otros: " + examenI.otrosCuello
        : "Otros: ________________________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 7;
    doc.text("TORAX", 10, row, "left", "middle");

    row = row + 2;
    var columns = ["", "", "", "", "", ""];
    var data = [
      [
        "Soplos.",
        examenI.pregunta50 === undefined || !examenI.pregunta50
          ? "SI  - NO"
          : examenI.pregunta50
          ? "SI* - NO"
          : "SI  - NO*",
        "Ruidos cardiacos normales.",
        examenI.pregunta51 === undefined || !examenI.pregunta51
          ? "SI  - NO"
          : examenI.pregunta51
          ? "SI* - NO"
          : "SI  - NO*",
        "Frémitos y otros.",
        examenI.pregunta52 === undefined || !examenI.pregunta52
          ? "SI  - NO"
          : examenI.pregunta52
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Excursión respiratoria normal.",
        examenI.pregunta53 === undefined || !examenI.pregunta53
          ? "SI  - NO"
          : examenI.pregunta53
          ? "SI* - NO"
          : "SI  - NO*",
        "Percusión normal.",
        examenI.pregunta54 === undefined || !examenI.pregunta54
          ? "SI  - NO"
          : examenI.pregunta54
          ? "SI* - NO"
          : "SI  - NO*",
        "Entrada de aire normal.",
        examenI.pregunta55 === undefined || !examenI.pregunta55
          ? "SI  - NO"
          : examenI.pregunta55
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Soplos, sibilancias.",
        examenI.pregunta56 === undefined || !examenI.pregunta56
          ? "SI  - NO"
          : examenI.pregunta56
          ? "SI* - NO"
          : "SI  - NO*",
        "Disnea.",
        examenI.pregunta76 === undefined || !examenI.pregunta76
          ? "SI  - NO"
          : examenI.pregunta76
          ? "SI* - NO"
          : "SI  - NO*",
        "Esputo.",
        examenI.pregunta57 === undefined || !examenI.pregunta57
          ? "SI  - NO"
          : examenI.pregunta57
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Deformidades columna.",
        examenI.pregunta58 === undefined || !examenI.pregunta58
          ? "SI  - NO"
          : examenI.pregunta58
          ? "SI* - NO"
          : "SI  - NO*",
        "Mamas normales.",
        examenI.pregunta60 === undefined || !examenI.pregunta60
          ? "SI  - NO"
          : examenI.pregunta60
          ? "SI* - NO"
          : "SI  - NO*",
        "Surco submamario normal.",
        examenI.pregunta61 === undefined || !examenI.pregunta61
          ? "SI  - NO"
          : examenI.pregunta61
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Revisado normal.",
        examenI.pregunta74 === undefined || !examenI.pregunta74
          ? "SI  - NO"
          : examenI.pregunta74
          ? "SI* - NO"
          : "SI  - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
        cellPadding: 1,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;
    doc.text(
      examenI.otrosTorax !== undefined && examenI.otrosTorax !== null
        ? "Otros: " + examenI.otrosTorax
        : "Otros: ________________________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 7;
    doc.text("ABDOMEN", 10, row, "left", "middle");

    row = row + 2;
    var columns = ["", "", "", "", "", "", "", ""];
    var data = [
      [
        "Cicatrices.",
        examenI.pregunta62 === undefined || !examenI.pregunta62
          ? "SI  - NO"
          : examenI.pregunta62
          ? "SI* - NO"
          : "SI  - NO*",
        "Dolor a palp.",
        examenI.pregunta63 === undefined || !examenI.pregunta63
          ? "SI  - NO"
          : examenI.pregunta63
          ? "SI* - NO"
          : "SI  - NO*",
        "Distensión abdominal.",
        examenI.pregunta64 === undefined || !examenI.pregunta64
          ? "SI  - NO"
          : examenI.pregunta64
          ? "SI* - NO"
          : "SI  - NO*",
        "Peritonismo.",
        examenI.pregunta65 === undefined || !examenI.pregunta65
          ? "SI  - NO"
          : examenI.pregunta65
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Timpanismo.",
        examenI.pregunta66 === undefined || !examenI.pregunta66
          ? "SI  - NO"
          : examenI.pregunta66
          ? "SI* - NO"
          : "SI  - NO*",
        "Ruidos hidroaereos alt.",
        examenI.pregunta67 === undefined || !examenI.pregunta67
          ? "SI  - NO"
          : examenI.pregunta67
          ? "SI* - NO"
          : "SI  - NO*",
        "Masas.",
        examenI.pregunta68 === undefined || !examenI.pregunta68
          ? "SI  - NO"
          : examenI.pregunta68
          ? "SI* - NO"
          : "SI  - NO*",
        "Globo vesical.",
        examenI.pregunta69 === undefined || !examenI.pregunta69
          ? "SI  - NO"
          : examenI.pregunta69
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Ascitis.",
        examenI.pregunta70 === undefined || !examenI.pregunta70
          ? "SI  - NO"
          : examenI.pregunta70
          ? "SI* - NO"
          : "SI  - NO*",
        "Revisado normal.",
        examenI.pregunta75 === undefined || !examenI.pregunta75
          ? "SI  - NO"
          : examenI.pregunta75
          ? "SI* - NO"
          : "SI  - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
        cellPadding: 1,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;
    doc.text(
      examenI.otrosAbdomen !== undefined && examenI.otrosAbdomen !== null
        ? "Otros: " + examenI.otrosAbdomen
        : "Otros: ________________________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("5", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${formatDate2(selectedRow.fecha)}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 25;

    doc.setFontSize(10);
    doc.text("GENITOURINARIO", 10, row, "left", "middle");
    row = row + 5;
    doc.setFontSize(9);

    row = row + 2;
    var columns = ["", "", "", ""];
    var data = [
      [
        "Inflamación.",
        examenII.pregunta1 === undefined || !examenII.pregunta1
          ? "SI  - NO"
          : examenII.pregunta1
          ? "SI* - NO"
          : "SI  - NO*",
        "Revisado normal.",
        examenII.pregunta11 === undefined || !examenII.pregunta11
          ? "SI  - NO"
          : examenII.pregunta11
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Micosis.",
        examenII.pregunta2 === undefined || !examenII.pregunta2
          ? "SI  - NO"
          : examenII.pregunta2
          ? "SI* - NO"
          : "SI  - NO*",
        "impactación fecal.",
        examenII.pregunta3 === undefined || !examenII.pregunta3
          ? "SI  - NO"
          : examenII.pregunta3
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Flujo vaginal.",
        examenII.pregunta4 === undefined || !examenII.pregunta4
          ? "SI  - NO"
          : examenII.pregunta4
          ? "SI* - NO"
          : "SI  - NO*",
        "Crecimiento prostático.",
        examenII.pregunta5 === undefined || !examenII.pregunta5
          ? "SI  - NO"
          : examenII.pregunta5
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Ulceras genitales.",
        examenII.pregunta6 === undefined || !examenII.pregunta6
          ? "SI  - NO"
          : examenII.pregunta6
          ? "SI* - NO"
          : "SI  - NO*",
        "Hemorroides: Ext.",
        examenII.pregunta7 === undefined || !examenII.pregunta7
          ? "SI  - NO"
          : examenII.pregunta7
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Prolapso uterino.",
        examenII.pregunta8 === undefined || !examenII.pregunta8
          ? "SI  - NO"
          : examenII.pregunta8
          ? "SI* - NO"
          : "SI  - NO*",
        "Hemorroides Int.",
        examenII.pregunta9 === undefined || !examenII.pregunta9
          ? "SI  - NO"
          : examenII.pregunta9
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Prolapso rectal.",
        examenII.pregunta10 === undefined || !examenII.pregunta10
          ? "SI  - NO"
          : examenII.pregunta10
          ? "SI* - NO"
          : "SI  - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;
    doc.text(
      examenII.otrosGenitourinarios !== undefined &&
        examenII.otrosGenitourinarios !== null
        ? "Otros: " + examenII.otrosGenitourinarios
        : "Otros: ________________________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 15;
    doc.text("LOCOMOTOR", 10, row, "left", "middle");
    doc.setFontSize(9);

    row = row + 2;
    var columns = ["", "", "", ""];
    var data = [
      [
        "Amplitud mov. articulares normal.",
        examenII.pregunta12 === undefined || !examenII.pregunta12
          ? "SI  - NO"
          : examenII.pregunta12
          ? "SI* - NO"
          : "SI  - NO*",
        "Insuf. vascular periférica.",
        examenII.pregunta13 === undefined || !examenII.pregunta13
          ? "SI  - NO"
          : examenII.pregunta13
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Contracturas.",
        examenII.pregunta14 === undefined || !examenII.pregunta14
          ? "SI  - NO"
          : examenII.pregunta14
          ? "SI* - NO"
          : "SI  - NO*",
        "Ulceras EE.II.",
        examenII.pregunta15 === undefined || !examenII.pregunta15
          ? "SI  - NO"
          : examenII.pregunta15
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Atrofias musculares.",
        examenII.pregunta16 === undefined || !examenII.pregunta16
          ? "SI  - NO"
          : examenII.pregunta16
          ? "SI* - NO"
          : "SI - NO*",
        "Revisado normal.",
        examenII.pregunta44 === undefined || !examenII.pregunta44
          ? "SI  - NO"
          : examenII.pregunta44
          ? "SI* - NO"
          : "SI - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 10;

    doc.text("Pulsos art. MM.II", 10, row, "left", "middle");
    doc.setFontSize(9);

    row = row + 2;
    var columns = ["", "", "", ""];
    var data = [
      [
        "Femoral.",
        examenII.pregunta17 === undefined || !examenII.pregunta17
          ? "SI  - NO"
          : examenII.pregunta17
          ? "SI* - NO"
          : "SI  - NO*",
        "Tibial.",
        examenII.pregunta18 === undefined || !examenII.pregunta18
          ? "SI  - NO"
          : examenII.pregunta18
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Poplíteo.",
        examenII.pregunta19 === undefined || !examenII.pregunta19
          ? "SI  - NO"
          : examenII.pregunta19
          ? "SI* - NO"
          : "SI  - NO*",
        "Pedio.",
        examenII.pregunta20 === undefined || !examenII.pregunta20
          ? "SI  - NO"
          : examenII.pregunta20
          ? "SI* - NO"
          : "SI  - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 10;

    doc.text("PIES", 10, row, "left", "middle");
    doc.setFontSize(9);

    row = row + 2;
    var columns = ["", "", "", ""];
    var data = [
      [
        "Dolor.",
        examenII.pregunta21 === undefined || !examenII.pregunta21
          ? "SI  - NO"
          : examenII.pregunta21
          ? "SI* - NO"
          : "SI  - NO*",
        "Maceración.",
        examenII.pregunta22 === undefined || !examenII.pregunta22
          ? "SI  - NO"
          : examenII.pregunta22
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Deformación.",
        examenII.pregunta23 === undefined || !examenII.pregunta23
          ? "SI  - NO"
          : examenII.pregunta23
          ? "SI* - NO"
          : "SI  - NO*",
        "Hiperqueratosis.",
        examenII.pregunta24 === undefined || !examenII.pregunta24
          ? "SI  - NO"
          : examenII.pregunta24
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Ulceras.",
        examenII.pregunta25 === undefined || !examenII.pregunta25
          ? "SI  - NO"
          : examenII.pregunta25
          ? "SI* - NO"
          : "SI - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 10;

    doc.text("PIEL y FANERAS", 10, row, "left", "middle");
    doc.setFontSize(9);

    row = row + 2;
    var columns = ["", "", "", ""];
    var data = [
      [
        "Heridas.",
        examenII.pregunta26 === undefined || !examenII.pregunta26
          ? "SI  - NO"
          : examenII.pregunta26
          ? "SI* - NO"
          : "SI  - NO*",
        "Edemas.",
        examenII.pregunta27 === undefined || !examenII.pregunta27
          ? "SI  - NO"
          : examenII.pregunta27
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Elasticidad normal.",
        examenII.pregunta28 === undefined || !examenII.pregunta28
          ? "SI  - NO"
          : examenII.pregunta28
          ? "SI* - NO"
          : "SI  - NO*",
        "Ulceras: Presión (grado I-IV).",
        examenII.pregunta29 === undefined || !examenII.pregunta29
          ? "SI  - NO"
          : examenII.pregunta29
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Turgencia normal.",
        examenII.pregunta30 === undefined || !examenII.pregunta30
          ? "SI  - NO"
          : examenII.pregunta30
          ? "SI* - NO"
          : "SI  - NO*",
        "Actínicas.",
        examenII.pregunta31 === undefined || !examenII.pregunta31
          ? "SI  - NO"
          : examenII.pregunta31
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Humedad normal.",
        examenII.pregunta32 === undefined || !examenII.pregunta32
          ? "SI  - NO"
          : examenII.pregunta32
          ? "SI* - NO"
          : "SI  - NO*",
        "Seborreica.",
        examenII.pregunta33 === undefined || !examenII.pregunta33
          ? "SI  - NO"
          : examenII.pregunta33
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Revisado normal.",
        examenII.pregunta45 === undefined || !examenII.pregunta45
          ? "SI  - NO"
          : examenII.pregunta45
          ? "SI* - NO"
          : "SI - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;
    doc.text(
      examenII.otrosPielFaneras !== undefined &&
        examenII.otrosPielFaneras !== null
        ? "Otros: " + examenII.otrosPielFaneras
        : "Otros: ________________________________________________________________________________________________",
      12,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    row = row + 15;
    doc.text("SISTEMA NERVIOSO", 10, row, "left", "middle");
    doc.setFontSize(9);

    row = row + 2;
    var columns = ["", "", "", ""];
    var data = [
      [
        "Pares craneales y reflejos pupilares: presentes.",
        examenII.pregunta34 === undefined || !examenII.pregunta34
          ? "SI  - NO"
          : examenII.pregunta34
          ? "SI* - NO"
          : "SI  - NO*",
        "Reflejos osteotendinosos normales.",
        examenII.pregunta35 === undefined || !examenII.pregunta35
          ? "SI  - NO"
          : examenII.pregunta35
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Sensibilidad normal.",
        examenII.pregunta36 === undefined || !examenII.pregunta36
          ? "SI  - NO"
          : examenII.pregunta36
          ? "SI* - NO"
          : "SI  - NO*",
        "Marcha y equilibrio: normal.",
        examenII.pregunta37 === undefined || !examenII.pregunta37
          ? "SI  - NO"
          : examenII.pregunta37
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Movimientos anormales.",
        examenII.pregunta38 === undefined || !examenII.pregunta38
          ? "SI  - NO"
          : examenII.pregunta38
          ? "SI* - NO"
          : "SI  - NO*",
        "Afasia.",
        examenII.pregunta39 === undefined || !examenII.pregunta39
          ? "SI  - NO"
          : examenII.pregunta39
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Fuerza muscular normal.",
        examenII.pregunta40 === undefined || !examenII.pregunta40
          ? "SI  - NO"
          : examenII.pregunta40
          ? "SI* - NO"
          : "SI  - NO*",
        "Flexores plantares normal.",
        examenII.pregunta41 === undefined || !examenII.pregunta41
          ? "SI  - NO"
          : examenII.pregunta41
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Abductores de la cadera normal.",
        examenII.pregunta42 === undefined || !examenII.pregunta42
          ? "SI  - NO"
          : examenII.pregunta42
          ? "SI* - NO"
          : "SI  - NO*",
        "Extensores de la cadera normal.",
        examenII.pregunta43 === undefined || !examenII.pregunta43
          ? "SI  - NO"
          : examenII.pregunta43
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Revisado normal.",
        examenII.pregunta46 === undefined || !examenII.pregunta46
          ? "SI  - NO"
          : examenII.pregunta46
          ? "SI* - NO"
          : "SI - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("6", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${formatDate2(selectedRow.fecha)}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 25;

    doc.setFontSize(10);
    doc.text("FUNCION VISUAL", 10, row, "left", "middle");
    row = row + 5;
    doc.setFontSize(9);

    row = row + 2;
    var columns = ["", ""];
    var data = [
      ["20/400.", examenIII.pregunta1 ? "*" : ""],
      ["20/200.", examenIII.pregunta2 ? "*" : ""],
      ["20/100.", examenIII.pregunta3 ? "*" : ""],
      ["20/70.", examenIII.pregunta4 ? "*" : ""],
      ["20/50.", examenIII.pregunta5 ? "*" : ""],
      ["20/40.", examenIII.pregunta6 ? "*" : ""],
      ["20/30.", examenIII.pregunta7 ? "*" : ""],
      ["20/20.", examenIII.pregunta8 ? "*" : ""],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 10,
        cellPadding: 3,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 10;

    var columns = ["", ""];
    var data = [
      [
        "Usa anteojos.",
        examenIII.pregunta9 === undefined
          ? "SI  - NO"
          : examenIII.pregunta9
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Graduación.",
        examenIII.pregunta10 === undefined
          ? "SI  - NO"
          : examenIII.pregunta10
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Glaucoma.",
        examenIII.pregunta11 === undefined
          ? "SI  - NO"
          : examenIII.pregunta11
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Maculopatía degenerativa.",
        examenIII.pregunta12 === undefined
          ? "SI  - NO"
          : examenIII.pregunta12
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Arco senil.",
        examenIII.pregunta13 === undefined
          ? "SI  - NO"
          : examenIII.pregunta13
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Agudeza visual.",
        examenIII.pregunta14 === undefined
          ? "SI  - NO"
          : examenIII.pregunta14
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "Cataratas.",
        examenIII.pregunta15 === undefined
          ? "SI  - NO"
          : examenIII.pregunta15
          ? "SI* - NO"
          : "SI  - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 10,
        cellPadding: 3,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 15;

    doc.setFontSize(10);

    doc.text(
      `Se mantiene la tarjeta a unos 36 cm. de los ojos, con buena iluminación. Se evalúa la visión para cada ojo de forma separada, con y sin gafas. Los pacientes con presbicia usaran sus gafas bifocales. Los miopes se evaluaran solamente con gafas.`,
      10,
      row,
      {
        maxWidth: 180,
        align: "justify",
      }
    );

    row = row + 20;
    doc.text(
      "- CEGUERA: AV < 20/200 en el mejor ojo.",
      10,
      row,
      "left",
      "middle"
    );
    row = row + 10;
    doc.text(
      "- C. PARCIAL: AV < 20/70 en el mejor ojo.",
      10,
      row,
      "left",
      "middle"
    );
    row = row + 10;
    doc.text(
      "- DETERIORO VISUAL FUNCIONAL: AV < 20/50 en el mejor ojo, con alteración de la vida diaria.",
      10,
      row,
      "left",
      "middle"
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("7", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${formatDate2(selectedRow.fecha)}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 25;

    doc.setFontSize(10);
    doc.text("FUNCION AUDITIVA", 10, row, "left", "middle");
    row = row + 5;
    doc.setFontSize(9);

    row = row + 2;
    var columns = ["", ""];
    var data = [
      [
        "Usa Audífono:",
        examenIII.pregunta18 === undefined
          ? "SI  - NO"
          : examenIII.pregunta18
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "1) Le ha ocasionado un problema de audición el sentirse avergonzado cuando ha conocído a una persona ?",
        examenIII.pregunta19 === undefined
          ? "SI  - NO"
          : examenIII.pregunta19
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "2) Le ha ocasionado un problema de audición el sentirse frustrado cuando habla con miembros de su familia ?",
        examenIII.pregunta20 === undefined
          ? "SI  - NO"
          : examenIII.pregunta20
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "3) Tiene usted dificultades para escuchar cuando alguien le habla susurrando ?",
        examenIII.pregunta21 === undefined
          ? "SI  - NO"
          : examenIII.pregunta21
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "4) Se siente usted incapacitado por un problema de audición ?",
        examenIII.pregunta22 === undefined
          ? "SI  - NO"
          : examenIII.pregunta22
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "5) Le ha ocasionado un problema de audición dificultades cuando visita amigos, familiares o vecinos ?",
        examenIII.pregunta23 === undefined
          ? "SI  - NO"
          : examenIII.pregunta23
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "6) Le ha ocasionado un problema de audición que acuda a los servicios religiosos menos frecuentemente de lo que quería ?",
        examenIII.pregunta24 === undefined
          ? "SI  - NO"
          : examenIII.pregunta24
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "7) Le ha ocasionado un problema de audición el tener discusiones con los miembros de su familia ?",
        examenIII.pregunta25 === undefined
          ? "SI  - NO"
          : examenIII.pregunta25
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "8) Le ha ocasionado un problema de audición ificultades para escuchar la televisión o la radio ?",
        examenIII.pregunta26 === undefined
          ? "SI  - NO"
          : examenIII.pregunta26
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "9) Siente usted que algún problema de audición le limita o estorba su vida personal o social ?",
        examenIII.pregunta27 === undefined
          ? "SI  - NO"
          : examenIII.pregunta27
          ? "SI* - NO"
          : "SI  - NO*",
      ],
      [
        "10) Le ha ocasionado un problema de audición dificultades cuando está en un restaurante con familiares o amigos ?",
        examenIII.pregunta28 === undefined
          ? "SI  - NO"
          : examenIII.pregunta28
          ? "SI* - NO"
          : "SI  - NO*",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 10,
        cellPadding: 3,
      },
      columnStyles: {
        0: { cellWidth: 160 },
        1: { cellWidth: 20 },
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 10;

    doc.setFontSize(10);

    doc.text(
      `Total: ${String(
        Number(
          examenIII.pregunta19 === "true" || examenIII.pregunta19 === true
            ? 1
            : 0
        ) +
          Number(
            examenIII.pregunta20 === "true" || examenIII.pregunta20 === true
              ? 1
              : 0
          ) +
          Number(
            examenIII.pregunta21 === "true" || examenIII.pregunta21 === true
              ? 1
              : 0
          ) +
          Number(
            examenIII.pregunta22 === "true" || examenIII.pregunta22 === true
              ? 1
              : 0
          ) +
          Number(
            examenIII.pregunta23 === "true" || examenIII.pregunta23 === true
              ? 1
              : 0
          ) +
          Number(
            examenIII.pregunta24 === "true" || examenIII.pregunta24 === true
              ? 1
              : 0
          ) +
          Number(
            examenIII.pregunta25 === "true" || examenIII.pregunta25 === true
              ? 1
              : 0
          ) +
          Number(
            examenIII.pregunta26 === "true" || examenIII.pregunta26 === true
              ? 1
              : 0
          ) +
          Number(
            examenIII.pregunta27 === "true" || examenIII.pregunta27 === true
              ? 1
              : 0
          ) +
          Number(
            examenIII.pregunta28 === "true" || examenIII.pregunta28 === true
              ? 1
              : 0
          )
      )}`,
      10,
      row,
      "left",
      "middle"
    );

    row = row + 10;
    doc.text("Total: > 4 anormal.", 10, row, "left", "middle");
    row = row + 10;
    doc.text(
      examenIII.pregunta29 === undefined
        ? "Recibe tratamiento: SI  - NO"
        : examenIII.pregunta29
        ? "Recibe tratamiento: SI* - NO"
        : "Recibe tratamiento: SI  - NO*",
      10,
      row,
      "left",
      "middle"
    );
    row = row + 10;
    doc.text(
      examenIII.cualAuditiva !== undefined && examenIII.cualAuditiva !== null
        ? "Cual: " + examenIII.cualAuditiva
        : "Cual: ______________________________________________________________________________________",
      10,
      row,
      {
        maxWidth: 180,
        align: "left",
      }
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("8", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${formatDate2(selectedRow.fecha)}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 25;

    doc.setFontSize(10);

    doc.text("DIAGNOSTICOS DE INGRESO: ", 10, row, "left", "middle");

    row = row + 5;

    doc.setFontSize(9);

    for (var i = 0; i < diagnosticosPacientes.length; i++) {
      doc.text("-" + diagnosticosPacientes[i].label, 10, row, {
        maxWidth: 180,
        align: "left",
      });
      let w = doc.getTextDimensions(diagnosticosPacientes[i].label);
      if (w.w > 180) {
        row = row + 10;
      } else {
        row = row + 5;
      }
    }

    doc.setFontSize(10);

    row = row + 5;

    doc.text("VALORACION GERIATRICA INTEGRAL: ", 10, row, "left", "middle");

    doc.setFontSize(9);

    row = row + 5;

    doc.text("FUNCIONAL", 10, row, "left", "middle");

    row = row + 10;

    doc.text(
      "BARTHEL: " +
        (barthel
          ? Number(barthel.comer ? barthel.comer : 0) +
            Number(barthel.lavarse ? barthel.lavarse : 0) +
            Number(barthel.vestirse ? barthel.vestirse : 0) +
            Number(barthel.arreglarse ? barthel.arreglarse : 0) +
            Number(barthel.deposiciones ? barthel.deposiciones : 0) +
            Number(barthel.miccion ? barthel.miccion : 0) +
            Number(barthel.retrete ? barthel.retrete : 0) +
            Number(barthel.trasladarse ? barthel.trasladarse : 0) +
            Number(barthel.deambular ? barthel.deambular : 0) +
            Number(barthel.escalones ? barthel.escalones : 0)
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "LAWTON Y BRODY: " +
        (lawtonBrody
          ? Number(
              lawtonBrody.telefonoResultado ? lawtonBrody.telefonoResultado : 0
            ) +
            Number(
              lawtonBrody.comprasResultado ? lawtonBrody.comprasResultado : 0
            ) +
            Number(
              lawtonBrody.comidaResultado ? lawtonBrody.comidaResultado : 0
            ) +
            Number(lawtonBrody.casaResultado ? lawtonBrody.casaResultado : 0) +
            Number(lawtonBrody.ropaResultado ? lawtonBrody.ropaResultado : 0) +
            Number(
              lawtonBrody.transporteResultado
                ? lawtonBrody.transporteResultado
                : 0
            ) +
            Number(
              lawtonBrody.medicacionResultado
                ? lawtonBrody.medicacionResultado
                : 0
            ) +
            Number(
              lawtonBrody.economicoResultado
                ? lawtonBrody.economicoResultado
                : 0
            )
          : ""),
      70,
      row,
      "left",
      "middle"
    );

    doc.text(
      "TINETTI: " +
        (tinetti
          ? Number(tinetti.equilibrioSentado ? tinetti.equilibrioSentado : 0) +
            Number(tinetti.levantarse ? tinetti.levantarse : 0) +
            Number(tinetti.intentoLevantarse ? tinetti.intentoLevantarse : 0) +
            Number(
              tinetti.equilibrioSegundos ? tinetti.equilibrioSegundos : 0
            ) +
            Number(
              tinetti.equilibrioProlongado ? tinetti.equilibrioProlongado : 0
            ) +
            Number(
              tinetti.rombergSensibilizado ? tinetti.rombergSensibilizado : 0
            ) +
            Number(tinetti.romberg ? tinetti.romberg : 0) +
            Number(tinetti.girar ? tinetti.girar : 0) +
            Number(tinetti.girar2 ? tinetti.girar2 : 0) +
            Number(tinetti.sentarse ? tinetti.sentarse : 0) / 16
          : ""),
      130,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      "MINIMENTAL: " +
        (escalaValoracionMental
          ? Number(
              escalaValoracionMental.pregunta1
                ? escalaValoracionMental.pregunta1Value
                : 0
            ) +
            Number(
              escalaValoracionMental.pregunta2
                ? escalaValoracionMental.pregunta2Value
                : 0
            ) +
            Number(
              escalaValoracionMental.pregunta3
                ? escalaValoracionMental.pregunta3Value
                : 0
            ) +
            Number(
              escalaValoracionMental.pregunta4
                ? escalaValoracionMental.pregunta4Value
                : 0
            ) +
            Number(
              escalaValoracionMental.pregunta5
                ? escalaValoracionMental.pregunta5
                : 0
            ) +
            Number(
              escalaValoracionMental.pregunta6
                ? escalaValoracionMental.pregunta6Value
                : 0
            ) +
            Number(
              escalaValoracionMental.pregunta7
                ? escalaValoracionMental.pregunta7Value
                : 0
            ) +
            Number(
              escalaValoracionMental.pregunta8
                ? escalaValoracionMental.pregunta8Value
                : 0
            ) +
            Number(
              escalaValoracionMental.pregunta9
                ? escalaValoracionMental.pregunta9Value
                : 0
            ) +
            Number(
              escalaValoracionMental.pregunta10
                ? escalaValoracionMental.pregunta10Value
                : 0
            ) +
            Number(
              escalaValoracionMental.pregunta11
                ? escalaValoracionMental.pregunta11Value
                : 0
            ) +
            Number(
              escalaValoracionMental.pregunta12
                ? escalaValoracionMental.pregunta12Value
                : 0
            ) +
            Number(
              escalaValoracionMental.pregunta13
                ? escalaValoracionMental.pregunta13Value
                : 0
            ) +
            Number(
              escalaValoracionMental.pregunta14
                ? escalaValoracionMental.pregunta14Value
                : 0
            ) +
            Number(
              escalaValoracionMental.pregunta15
                ? escalaValoracionMental.pregunta15Value
                : 0
            )
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "DEPRESION: " +
        (yesavage
          ? Number(yesavage.pregunta1 ? yesavage.pregunta1 : 0) +
            Number(yesavage.pregunta2 ? yesavage.pregunta2 : 0) +
            Number(yesavage.pregunta3 ? yesavage.pregunta3 : 0) +
            Number(yesavage.pregunta4 ? yesavage.pregunta4 : 0) +
            Number(yesavage.pregunta5 ? yesavage.pregunta5 : 0) +
            Number(yesavage.pregunta6 ? yesavage.pregunta6 : 0) +
            Number(yesavage.pregunta7 ? yesavage.pregunta7 : 0) +
            Number(yesavage.pregunta8 ? yesavage.pregunta8 : 0) +
            Number(yesavage.pregunta9 ? yesavage.pregunta9 : 0) +
            Number(yesavage.pregunta10 ? yesavage.pregunta10 : 0) +
            Number(yesavage.pregunta11 ? yesavage.pregunta11 : 0) +
            Number(yesavage.pregunta12 ? yesavage.pregunta12 : 0) +
            Number(yesavage.pregunta13 ? yesavage.pregunta13 : 0) +
            Number(yesavage.pregunta14 ? yesavage.pregunta14 : 0) +
            Number(yesavage.pregunta15 ? yesavage.pregunta15 : 0) +
            Number(yesavage.pregunta16 ? yesavage.pregunta16 : 0) +
            Number(yesavage.pregunta17 ? yesavage.pregunta17 : 0) +
            Number(yesavage.pregunta18 ? yesavage.pregunta18 : 0) +
            Number(yesavage.pregunta19 ? yesavage.pregunta19 : 0) +
            Number(yesavage.pregunta20 ? yesavage.pregunta20 : 0) +
            Number(yesavage.pregunta21 ? yesavage.pregunta21 : 0) +
            Number(yesavage.pregunta22 ? yesavage.pregunta22 : 0) +
            Number(yesavage.pregunta23 ? yesavage.pregunta23 : 0) +
            Number(yesavage.pregunta24 ? yesavage.pregunta24 : 0) +
            Number(yesavage.pregunta25 ? yesavage.pregunta25 : 0) +
            Number(yesavage.pregunta26 ? yesavage.pregunta26 : 0) +
            Number(yesavage.pregunta27 ? yesavage.pregunta27 : 0) +
            Number(yesavage.pregunta28 ? yesavage.pregunta28 : 0) +
            Number(yesavage.pregunta29 ? yesavage.pregunta29 : 0) +
            Number(yesavage.pregunta30 ? yesavage.pregunta30 : 0)
          : ""),
      70,
      row,
      "left",
      "middle"
    );

    doc.text(
      "NORTON: " +
        (norton
          ? Number(norton.estadoFisico ? norton.estadoFisico : 0) +
            Number(norton.estadoMental ? norton.estadoMental : 0) +
            Number(norton.actividad ? norton.actividad : 0) +
            Number(norton.movilidad ? norton.movilidad : 0) +
            Number(norton.incontinencia ? norton.incontinencia : 0)
          : ""),
      130,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      "FRAGILIDAD: " +
        (fragilidad
          ? Number(fragilidad.pregunta1 ? fragilidad.pregunta1Value : 0) +
            Number(fragilidad.pregunta2 ? fragilidad.pregunta2Value : 0) +
            Number(fragilidad.pregunta3 ? fragilidad.pregunta3Value : 0) +
            Number(fragilidad.pregunta4 ? fragilidad.pregunta4Value : 0) +
            Number(fragilidad.pregunta5 ? fragilidad.pregunta5Value : 0) +
            Number(fragilidad.pregunta6 ? fragilidad.pregunta6Value : 0) +
            Number(fragilidad.pregunta7 ? fragilidad.pregunta7Value : 0) +
            Number(fragilidad.pregunta8 ? fragilidad.pregunta8Value : 0) +
            Number(fragilidad.pregunta9 ? fragilidad.pregunta9Value : 0) +
            Number(fragilidad.pregunta10 ? fragilidad.pregunta10Value : 0) +
            Number(fragilidad.pregunta11 ? fragilidad.pregunta11Value : 0) +
            Number(fragilidad.pregunta12 ? fragilidad.pregunta12Value : 0) +
            Number(fragilidad.pregunta13 ? fragilidad.pregunta13Value : 0) +
            Number(fragilidad.pregunta14 ? fragilidad.pregunta14Value : 0) +
            Number(fragilidad.pregunta15 ? fragilidad.pregunta15Value : 0) +
            Number(fragilidad.pregunta16 ? fragilidad.pregunta16Value : 0) +
            Number(fragilidad.pregunta17 ? fragilidad.pregunta17Value : 0) +
            Number(fragilidad.pregunta18 ? fragilidad.pregunta18Value : 0) +
            Number(fragilidad.pregunta19 ? fragilidad.pregunta19Value : 0) +
            Number(fragilidad.pregunta20 ? fragilidad.pregunta20Value : 0) +
            Number(fragilidad.pregunta21 ? fragilidad.pregunta21Value : 0) +
            Number(fragilidad.pregunta22 ? fragilidad.pregunta22Value : 0) +
            Number(fragilidad.pregunta23 ? fragilidad.pregunta23Value : 0) +
            Number(fragilidad.pregunta24 ? fragilidad.pregunta24Value : 0) +
            Number(fragilidad.pregunta25 ? fragilidad.pregunta25Value : 0) +
            Number(fragilidad.pregunta26 ? fragilidad.pregunta26Value : 0) +
            Number(fragilidad.pregunta27 ? fragilidad.pregunta27Value : 0) +
            " - " +
            fragilidad.observaciones
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      "AUDITIVO: " +
        (examenIII
          ? Number(examenIII.pregunta19 ? examenIII.pregunta19 : 0) +
            Number(examenIII.pregunta20 ? examenIII.pregunta20 : 0) +
            Number(examenIII.pregunta21 ? examenIII.pregunta21 : 0) +
            Number(examenIII.pregunta22 ? examenIII.pregunta22 : 0) +
            Number(examenIII.pregunta23 ? examenIII.pregunta23 : 0) +
            Number(examenIII.pregunta24 ? examenIII.pregunta24 : 0) +
            Number(examenIII.pregunta25 ? examenIII.pregunta25 : 0) +
            Number(examenIII.pregunta26 ? examenIII.pregunta26 : 0) +
            Number(examenIII.pregunta27 ? examenIII.pregunta27 : 0) +
            Number(examenIII.pregunta28 ? examenIII.pregunta28 : 0)
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "VISUAL: " +
        (examenIII
          ? examenIII.pregunta1 || examenIII.pregunta2
            ? "CEGUERA"
            : examenIII.pregunta3 || examenIII.pregunta4
            ? "CEGUERA PARCIAL"
            : "DETERIORO VISUAL FUNCIONAL "
          : ""),
      70,
      row,
      "left",
      "middle"
    );

    doc.text(
      "TEST DEL RELOJ: " +
        (reloj
          ? Number(reloj.esfera ? reloj.esfera : 0) +
            Number(
              reloj.numero && reloj.numero / 10 === 3.5
                ? reloj.numero / 10
                : reloj.numero && reloj.numero / 10 !== 3.5
                ? Math.trunc(reloj.numero / 10)
                : 0
            ) +
            Number(
              reloj.aguja && reloj.aguja / 10 === 3.5
                ? reloj.aguja / 10
                : reloj.aguja && reloj.aguja / 10 !== 3.5
                ? Math.trunc(reloj.aguja / 10)
                : 0
            )
          : null),
      130,
      row,
      "left",
      "middle"
    );

    row = row + 15;

    doc.setFontSize(10);
    doc.text("CONCLUSION ", 10, row, "left", "middle");
    row = row + 5;
    doc.setFontSize(9);
    doc.text("MEDICA", 10, row, "left", "middle");

    row = row + 5;

    if (examenIII.vgimedica)
      doc.text(examenIII.vgimedica ? examenIII.vgimedica : "", 10, row, {
        maxWidth: 180,
        align: "left",
      });

    var lines = 0;
    var lMargin = 20; //left margin in mm
    var rMargin = 10; //right margin in mm
    var pdfInMM = 210; // width of A4 in mm
    lines = doc.splitTextToSize(
      examenIII.vgimedica,
      pdfInMM - lMargin - rMargin
    );

    row = row + lines.length * 5;

    doc.text("SOCIAL", 10, row, "left", "middle");
    row = row + 5;

    if (examenIII.vgisocial)
      doc.text(examenIII.vgisocial ? examenIII.vgisocial : "", 10, row, {
        maxWidth: 180,
        align: "left",
      });

    var lines = 0;
    var lMargin = 20; //left margin in mm
    var rMargin = 10; //right margin in mm
    var pdfInMM = 210; // width of A4 in mm
    lines = doc.splitTextToSize(
      examenIII.vgisocial,
      pdfInMM - lMargin - rMargin
    );

    row = row + lines.length * 5;

    doc.text("PSICOLOGICA", 10, row, "left", "middle");
    row = row + 5;

    if (examenIII.vgipsicologica)
      doc.text(
        examenIII.vgipsicologica ? examenIII.vgipsicologica : "",
        10,
        row,
        {
          maxWidth: 180,
          align: "left",
        }
      );

    var lines = 0;
    var lMargin = 20; //left margin in mm
    var rMargin = 10; //right margin in mm
    var pdfInMM = 210; // width of A4 in mm
    lines = doc.splitTextToSize(
      examenIII.vgipsicologica,
      pdfInMM - lMargin - rMargin
    );

    row = row + lines.length * 5;

    doc.text("NUTRICIONAL", 10, row, "left", "middle");
    row = row + 5;

    if (examenIII.vginutricional)
      doc.text(
        examenIII.vginutricional ? examenIII.vginutricional : "",
        10,
        row,
        {
          maxWidth: 180,
          align: "left",
        }
      );

    var lines = 0;
    var lMargin = 20; //left margin in mm
    var rMargin = 10; //right margin in mm
    var pdfInMM = 210; // width of A4 in mm
    lines = doc.splitTextToSize(
      examenIII.vginutricional,
      pdfInMM - lMargin - rMargin
    );

    row = row + lines.length * 5;

    doc.text("ESTUDIOS SOLICITADOS: ", 10, row, "left", "middle");

    row = row + 5;

    doc.setFontSize(9);

    for (var i = 0; i < estudiosPacientes.length; i++) {
      doc.text("-" + estudiosPacientes[i].label, 10, row, {
        maxWidth: 180,
        align: "left",
      });
      let w = doc.getTextDimensions(estudiosPacientes[i].label);
      if (w.w > 180) {
        row = row + 10;
      } else {
        row = row + 5;
      }
    }

    doc.setFontSize(10);

    row = row + 10;

    doc.text("PLAN TERAPEUTICO/REHABILITACION: ", 10, row, "left", "middle");

    row = row + 5;

    doc.setFontSize(9);

    for (var i = 0; i < medicacionesPacientes.length; i++) {
      doc.text("-" + medicacionesPacientes[i].label, 10, row, "left", "middle");
      row = row + 5;
    }
    doc.setFontSize(10);

    row = 290;

    doc.text("MEDICO", 90, row, "left", "middle");

    doc.addPage();

    row = 10;
    doc.text("9", 200, row);
    doc.text(
      `Apellido y Nombre del Residente: ${
        paciente.nombre
      }   Fecha: ${formatDate2(selectedRow.fecha)}`,
      30,
      row,
      "left",
      "middle"
    );
    row = 20;

    doc.setFontSize(10);

    doc.text("VALORACION GLOBAL SUBJETIVA: ", 10, row, "left", "middle");
    row = row + 5;
    doc.setFontSize(9);
    doc.text("EVALUACION NUTRICIONAL: ", 10, row, "left", "middle");

    row = row + 2;
    var columns = [
      "EDAD",
      "SEXO",
      "PESO",
      "DEPENDENCIA",
      "POSTRADO",
      "PROBLEMAS",
      "RIESGO P/ALIMENTAR",
      "ORALES",
      "NUTRICIONAL",
    ];
    var data = [
      [
        evaluacionNutricional.edad === undefined ||
        evaluacionNutricional.edad === null
          ? ""
          : evaluacionNutricional.edad,
        evaluacionNutricional.sexo === undefined ||
        evaluacionNutricional.sexo === null
          ? ""
          : evaluacionNutricional.sexo,
        evaluacionNutricional.peso === undefined ||
        evaluacionNutricional.peso === null
          ? ""
          : evaluacionNutricional.peso,
        evaluacionNutricional.dependencia === undefined ||
        evaluacionNutricional.dependencia === null
          ? ""
          : evaluacionNutricional.dependencia
          ? "SI"
          : "NO",
        evaluacionNutricional.postrado === undefined ||
        evaluacionNutricional.postrado === null
          ? ""
          : evaluacionNutricional.postrado
          ? "SI"
          : "NO",
        evaluacionNutricional.problemas === undefined ||
        evaluacionNutricional.problemas === null
          ? ""
          : evaluacionNutricional.problemas
          ? "SI"
          : "NO",
        evaluacionNutricional.riesgoAlimentar === undefined ||
        evaluacionNutricional.riesgoAlimentar === null
          ? ""
          : evaluacionNutricional.riesgoAlimentar
          ? "SI"
          : "NO",
        evaluacionNutricional.orales === undefined ||
        evaluacionNutricional.orales === null
          ? ""
          : evaluacionNutricional.orales
          ? "SI"
          : "NO",
        evaluacionNutricional.nutricional === undefined ||
        evaluacionNutricional.nutricional === null
          ? ""
          : evaluacionNutricional.nutricional
          ? "SI"
          : "NO",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
        cellPadding: 1,
      },
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;

    doc.text("DIAGNOSTICO", 10, row, "left", "middle");

    row = row + 2;
    var columns = ["", "", "", "", "", "", "", "", "", "", "", "", ""];
    var data = [
      [
        "Cambio de peso 6 meses:",
        "Ninguno",
        evaluacionNutricional.ningunoPeso6Meses === undefined
          ? ""
          : evaluacionNutricional.ningunoPeso6Meses
          ? "*"
          : "",
        "Perdida de peso",
        evaluacionNutricional.perdidaPeso6Meses === undefined
          ? ""
          : evaluacionNutricional.perdidaPeso6Meses
          ? "*"
          : "",
        "Aumento de peso",
        evaluacionNutricional.aumentoPeso6Meses === undefined
          ? ""
          : evaluacionNutricional.aumentoPeso6Meses
          ? "*"
          : "",
      ],
      [
        "Cambio de peso 2 semanas:",
        "Ninguno",
        evaluacionNutricional.ningunoPeso2Semanas === undefined
          ? ""
          : evaluacionNutricional.ningunoPeso2Semanas
          ? "*"
          : "",
        "Perdida de peso",
        evaluacionNutricional.perdidaPeso2Semanas === undefined
          ? ""
          : evaluacionNutricional.perdidaPeso2Semanas
          ? "*"
          : "",
        "Aumento de peso",
        evaluacionNutricional.aumentoPeso2Semanas === undefined
          ? ""
          : evaluacionNutricional.aumentoPeso2Semanas
          ? "*"
          : "",
      ],
      [
        "Síntomas gastrointestinales:",
        "Ninguno",
        evaluacionNutricional.ninguno === undefined
          ? ""
          : evaluacionNutricional.ninguno
          ? "*"
          : "",
        "Nauseas",
        evaluacionNutricional.nauseas === undefined
          ? ""
          : evaluacionNutricional.nauseas
          ? "*"
          : "",
        "Vómitos",
        evaluacionNutricional.vomitos === undefined
          ? ""
          : evaluacionNutricional.vomitos
          ? "*"
          : "",
        "Diarrea",
        evaluacionNutricional.diarrea === undefined
          ? ""
          : evaluacionNutricional.diarrea
          ? "*"
          : "",
        "Anorexia",
        evaluacionNutricional.anorexia === undefined
          ? ""
          : evaluacionNutricional.anorexia
          ? "*"
          : "",
        "Dolor",
        evaluacionNutricional.dolor === undefined
          ? ""
          : evaluacionNutricional.dolor
          ? "*"
          : "",
      ],
      [
        "Cambios en la ingesta:",
        "Ninguno",
        evaluacionNutricional.sinCambios === undefined
          ? ""
          : evaluacionNutricional.sinCambios
          ? "*"
          : "",
        "Ayuno",
        evaluacionNutricional.ayuno === undefined
          ? ""
          : evaluacionNutricional.ayuno
          ? "*"
          : "",
        "Hipocalóricos",
        evaluacionNutricional.hipocaloricos === undefined
          ? ""
          : evaluacionNutricional.hipocaloricos
          ? "*"
          : "",
        "Dieta liquida",
        evaluacionNutricional.dietaLiquida === undefined
          ? ""
          : evaluacionNutricional.dietaLiquida
          ? "*"
          : "",
        "Dieta Insuficiente",
        evaluacionNutricional.dietaInsuficiente === undefined
          ? ""
          : evaluacionNutricional.dietaInsuficiente
          ? "*"
          : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;

    columns = [
      "MES",
      "ACTITUD FRENTE A LA COMIDA",
      "DEPENDE P/ALIMENTAR",
      "COMPLEMENTO ALIMENTARIO",
      "PESO",
      "TALLA",
      "IMC",
      "RIESGO NUTRICIONAL",
    ];
    data = [
      [
        "ENERO",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[0].actitud !== null
          ? tipoDieta[0].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[0].depende !== null
          ? tipoDieta[0].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[0].complemento !== null
          ? tipoDieta[0].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[0].pesoMes !== null
          ? tipoDieta[0].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[0].talla !== null
          ? tipoDieta[0].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[0].imc !== null
          ? tipoDieta[0].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[0].riesgoNutricional !== null
          ? tipoDieta[0].riesgoNutricional
          : "",
      ],
      [
        "FEBRERO",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[1].actitud !== null
          ? tipoDieta[1].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[1].depende !== null
          ? tipoDieta[1].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[1].complemento !== null
          ? tipoDieta[1].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[1].pesoMes !== null
          ? tipoDieta[1].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[1].talla !== null
          ? tipoDieta[1].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[1].imc !== null
          ? tipoDieta[1].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[1].riesgoNutricional !== null
          ? tipoDieta[1].riesgoNutricional
          : "",
      ],
      [
        "MARZO",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[2].actitud !== null
          ? tipoDieta[2].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[2].depende !== null
          ? tipoDieta[2].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[2].complemento !== null
          ? tipoDieta[2].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[2].pesoMes !== null
          ? tipoDieta[2].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[2].talla !== null
          ? tipoDieta[2].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[2].imc !== null
          ? tipoDieta[2].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[2].riesgoNutricional !== null
          ? tipoDieta[2].riesgoNutricional
          : "",
      ],
      [
        "ABRIL",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[3].actitud !== null
          ? tipoDieta[3].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[3].depende !== null
          ? tipoDieta[3].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[3].complemento !== null
          ? tipoDieta[3].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[3].pesoMes !== null
          ? tipoDieta[3].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[3].talla !== null
          ? tipoDieta[3].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[3].imc !== null
          ? tipoDieta[3].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[3].riesgoNutricional !== null
          ? tipoDieta[3].riesgoNutricional
          : "",
      ],
      [
        "MAYO",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[4].actitud !== null
          ? tipoDieta[4].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[4].depende !== null
          ? tipoDieta[4].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[4].complemento !== null
          ? tipoDieta[4].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[4].pesoMes !== null
          ? tipoDieta[4].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[4].talla !== null
          ? tipoDieta[4].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[4].imc !== null
          ? tipoDieta[4].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[4].riesgoNutricional !== null
          ? tipoDieta[4].riesgoNutricional
          : "",
      ],
      [
        "JUNIO",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[5].actitud !== null
          ? tipoDieta[5].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[5].depende !== null
          ? tipoDieta[5].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[5].complemento !== null
          ? tipoDieta[5].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[5].pesoMes !== null
          ? tipoDieta[5].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[5].talla !== null
          ? tipoDieta[5].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[5].imc !== null
          ? tipoDieta[5].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[5].riesgoNutricional !== null
          ? tipoDieta[5].riesgoNutricional
          : "",
      ],
      [
        "JULIO",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[6].actitud !== null
          ? tipoDieta[6].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[6].depende !== null
          ? tipoDieta[6].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[6].complemento !== null
          ? tipoDieta[6].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[6].pesoMes !== null
          ? tipoDieta[6].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[6].talla !== null
          ? tipoDieta[6].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[6].imc !== null
          ? tipoDieta[6].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[6].riesgoNutricional !== null
          ? tipoDieta[6].riesgoNutricional
          : "",
      ],
      [
        "AGOSTO",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[7].actitud !== null
          ? tipoDieta[7].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[7].depende !== null
          ? tipoDieta[7].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[7].complemento !== null
          ? tipoDieta[7].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[7].pesoMes !== null
          ? tipoDieta[7].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[7].talla !== null
          ? tipoDieta[7].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[7].imc !== null
          ? tipoDieta[7].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[7].riesgoNutricional !== null
          ? tipoDieta[7].riesgoNutricional
          : "",
      ],
      [
        "SEPTIEMBRE",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[8].actitud !== null
          ? tipoDieta[8].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[8].depende !== null
          ? tipoDieta[8].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[8].complemento !== null
          ? tipoDieta[8].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[8].pesoMes !== null
          ? tipoDieta[8].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[8].talla !== null
          ? tipoDieta[8].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[8].imc !== null
          ? tipoDieta[8].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[8].riesgoNutricional !== null
          ? tipoDieta[8].riesgoNutricional
          : "",
      ],
      [
        "OCTUBRE",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[9].actitud !== null
          ? tipoDieta[9].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[9].depende !== null
          ? tipoDieta[9].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[9].complemento !== null
          ? tipoDieta[9].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[9].pesoMes !== null
          ? tipoDieta[9].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[9].talla !== null
          ? tipoDieta[9].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[9].imc !== null
          ? tipoDieta[9].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[9].riesgoNutricional !== null
          ? tipoDieta[9].riesgoNutricional
          : "",
      ],
      [
        "NOVIEMBRE",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[10].actitud !== null
          ? tipoDieta[10].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[10].depende !== null
          ? tipoDieta[10].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[10].complemento !== null
          ? tipoDieta[10].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[10].pesoMes !== null
          ? tipoDieta[10].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[10].talla !== null
          ? tipoDieta[10].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[10].imc !== null
          ? tipoDieta[10].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[10].riesgoNutricional !== null
          ? tipoDieta[10].riesgoNutricional
          : "",
      ],
      [
        "DICIEMBRE",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[11].actitud !== null
          ? tipoDieta[11].actitud
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[11].depende !== null
          ? tipoDieta[11].depende
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[11].complemento !== null
          ? tipoDieta[11].complemento
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[11].pesoMes !== null
          ? tipoDieta[11].pesoMes
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[11].talla !== null
          ? tipoDieta[11].talla
          : "",
        tipoDieta && tipoDieta.length > 0 && tipoDieta[11].imc !== null
          ? tipoDieta[11].imc
          : "",
        tipoDieta &&
        tipoDieta.length > 0 &&
        tipoDieta[11].riesgoNutricional !== null
          ? tipoDieta[11].riesgoNutricional
          : "",
      ],
    ];

    doc.autoTable(columns, data, {
      startY: row,
      margin: { left: 10 },
      styles: {
        fontSize: 7,
      },
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 10;

    let textDiag = "";
    for (var i = 0; i < diagnosticosPacientes.length; i++) {
      textDiag = textDiag + "•" + diagnosticosPacientes[i].label + "  ";
    }

    doc.text("Diagnóstico primario: " + textDiag, 10, row, {
      maxWidth: 180,
      align: "justify",
    });

    let w = doc.getTextDimensions(textDiag);
    if (w.w > 180) {
      row = row + 12;
    } else {
      row = row + 5;
    }

    doc.setFontSize(10);

    row = row + 5;

    doc.text("Requerimientos metabólicos (estrés)", 10, row, "left", "middle");

    row = row + 2;
    var columns = ["", "", "", "", "", "", "", ""];
    var data = [
      [
        "Sin estrés:",
        evaluacionNutricional.sinEstres !== undefined &&
        evaluacionNutricional.sinEstres !== null
          ? "*"
          : "",
        "Estrés bajo",
        evaluacionNutricional.estresBajo !== undefined &&
        evaluacionNutricional.estresBajo !== null
          ? "*"
          : "",
        "Estrés moderado",
        evaluacionNutricional.estresModerado !== undefined &&
        evaluacionNutricional.estresModerado !== null
          ? "*"
          : "",
        "Estrés alto",
        evaluacionNutricional.estresAlto !== undefined &&
        evaluacionNutricional.estresAlto !== null
          ? "*"
          : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;

    doc.text("EXAMEN FISICO", 10, row, "left", "middle");

    row = row + 2;
    var columns = [
      "",
      "Normal",
      "Déficit ligero",
      "Déficit moderado",
      "Déficit severo",
    ];
    var data = [
      [
        "Grasa subcutánea:",
        evaluacionNutricional.perdidaGrasaNormal !== undefined &&
        evaluacionNutricional.perdidaGrasaNormal !== null
          ? evaluacionNutricional.perdidaGrasaNormal
            ? "*"
            : ""
          : "",
        evaluacionNutricional.perdidaGrasaDeficitLigero !== undefined &&
        evaluacionNutricional.perdidaGrasaDeficitLigero !== null
          ? evaluacionNutricional.perdidaGrasaDeficitLigero
            ? "*"
            : ""
          : "",
        evaluacionNutricional.perdidaGrasaDeficitModerado !== undefined &&
        evaluacionNutricional.perdidaGrasaDeficitModerado !== null
          ? evaluacionNutricional.perdidaGrasaDeficitModerado
            ? "*"
            : ""
          : "",
        evaluacionNutricional.perdidaGrasaDeficitSevero !== undefined &&
        evaluacionNutricional.perdidaGrasaDeficitSevero !== null
          ? evaluacionNutricional.perdidaGrasaDeficitSevero
            ? "*"
            : ""
          : "",
      ],
      [
        "Masa muscular:",
        evaluacionNutricional.perdidaMasaNormal !== undefined &&
        evaluacionNutricional.perdidaMasaNormal !== null
          ? evaluacionNutricional.perdidaMasaNormal
            ? "*"
            : ""
          : "",
        evaluacionNutricional.perdidaMasaDeficitLigero !== undefined &&
        evaluacionNutricional.perdidaMasaDeficitLigero !== null
          ? evaluacionNutricional.perdidaMasaDeficitLigero
            ? "*"
            : ""
          : "",
        evaluacionNutricional.perdidaMasaDeficitModerado !== undefined &&
        evaluacionNutricional.perdidaMasaDeficitModerado !== null
          ? evaluacionNutricional.perdidaMasaDeficitModerado
            ? "*"
            : ""
          : "",
        evaluacionNutricional.perdidaMasaDeficitSevero !== undefined &&
        evaluacionNutricional.perdidaMasaDeficitSevero !== null
          ? evaluacionNutricional.perdidaMasaDeficitSevero
            ? "*"
            : ""
          : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
      },
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;

    var columns = ["", "", "", "", "", "", "", "", "", "", "", "", ""];
    var data = [
      [
        "Edema maleolar:",
        evaluacionNutricional.edemaMaleolar === undefined ||
        evaluacionNutricional.edemaMaleolar === null
          ? ""
          : evaluacionNutricional.edemaMaleolar
          ? "*"
          : "",
        "Grado",
        evaluacionNutricional.gradoEdemaMaleolar === undefined ||
        evaluacionNutricional.gradoEdemaMaleolar === null
          ? ""
          : evaluacionNutricional.gradoEdemaMaleolar,
        "Ulceras PP",
        evaluacionNutricional.ulcerasPP === undefined ||
        evaluacionNutricional.ulcerasPP === null
          ? ""
          : evaluacionNutricional.ulcerasPP
          ? "*"
          : "",
        "Grado",
        evaluacionNutricional.gradoUlcerasPP === undefined ||
        evaluacionNutricional.gradoUlcerasPP === null
          ? ""
          : evaluacionNutricional.gradoUlcerasPP,
      ],
      [
        "Edema sacro:",
        evaluacionNutricional.edemaSacro === undefined ||
        evaluacionNutricional.edemaSacro === null
          ? ""
          : evaluacionNutricional.edemaSacro
          ? "*"
          : "",
        "Grado",
        evaluacionNutricional.gradoEdemaSacro === undefined ||
        evaluacionNutricional.gradoEdemaSacro === null
          ? ""
          : evaluacionNutricional.gradoEdemaSacro,
        "Fiebre",
        evaluacionNutricional.fiebre === undefined ||
        evaluacionNutricional.fiebre === null
          ? ""
          : evaluacionNutricional.fiebre
          ? "*"
          : "",
      ],
      [
        "Ascitis:",
        evaluacionNutricional.ascitis === undefined ||
        evaluacionNutricional.ascitis === null
          ? ""
          : evaluacionNutricional.ascitis
          ? "*"
          : "",
        "Grado",
        evaluacionNutricional.gradoAscitis === undefined ||
        evaluacionNutricional.gradoAscitis === null
          ? ""
          : evaluacionNutricional.gradoAscitis,
        "Fiebre",
        evaluacionNutricional.sintomas === undefined ||
        evaluacionNutricional.sintomas === null
          ? ""
          : evaluacionNutricional.sintomas
          ? "*"
          : "",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      startY: row,
      styles: {
        fontSize: 8,
      },
      showHead: "never",
    });

    finalY = doc.previousAutoTable.finalY;

    row = finalY + 5;

    doc.text("DIAGNOSTICO NUTRICIONAL", 10, row, "left", "middle");
    row = row + 3;
    doc.setFontSize(7);
    doc.text(
      `No se establece un método numérico para la evaluación de este test. Se valora en A, Bo C seún la predominancia de síntomas, con especial atención a las siguientes variables: pérdida de peso, cambios en la injesta habitual, pérdida de tejido subcutáneo y pérdida de masa muscular.`,
      10,
      row,
      {
        maxWidth: 180,
        align: "justify",
      }
    );

    doc.setFontSize(9);
    row = row + 10;
    doc.text("A= Bien nutrído", 10, row, "left", "middle");
    doc.text(
      evaluacionNutricional.diagnosticoNutricional !== undefined &&
        evaluacionNutricional.diagnosticoNutricional !== null
        ? evaluacionNutricional.diagnosticoNutricional === "A"
          ? "*"
          : ""
        : "",
      50,
      row,
      "left",
      "middle"
    );

    row = row + 5;
    doc.text(
      "B= Sospecha de malnutrición o malnutrición moderada",
      10,
      row,
      "left",
      "middle"
    );
    doc.text(
      evaluacionNutricional.diagnosticoNutricional !== undefined &&
        evaluacionNutricional.diagnosticoNutricional !== null
        ? evaluacionNutricional.diagnosticoNutricional === "B"
          ? "*"
          : ""
        : "",
      100,
      row,
      "left",
      "middle"
    );

    row = row + 3;
    doc.setFontSize(7);
    doc.text(
      `5-10% de pérdida de peso en las últimas semanas antes del ingreso. Disminución de la injesta en las últimas semanas. Pérdida de tejido subcutáneo.`,
      10,
      row,
      {
        maxWidth: 180,
        align: "justify",
      }
    );

    doc.setFontSize(9);
    row = row + 5;
    doc.text("C= Malnutrición severa", 10, row, "left", "middle");
    doc.text(
      evaluacionNutricional.diagnosticoNutricional !== undefined &&
        evaluacionNutricional.diagnosticoNutricional !== null
        ? evaluacionNutricional.diagnosticoNutricional === "C"
          ? "*"
          : ""
        : "",
      50,
      row,
      "left",
      "middle"
    );

    row = row + 3;
    doc.setFontSize(7);
    doc.text(
      `Pérdida > 10% del peso. Severa pérdida de masa muscular y tejido subcutáneo. Edema.`,
      10,
      row,
      {
        maxWidth: 180,
        align: "justify",
      }
    );

    row = 290;

    doc.text("FIRMA Y SELLO", 90, row, "left", "middle");


    doc.addPage();


    doc.setFontSize(10);
    row = row + 10;

    doc.addImage(vgi, 'PNG', 180, 120, 260, 160, null, null, 90);



    window.open(doc.output("bloburl"));
  };

  /// FIN

  //console.log(evaluacionNutricional);
  //console.log(frases);
  //console.log(historiasClinicas);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {historiasClinicas ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  align: "center",
                  margin: "1em",
                  marginTop:
                    isMobile && !isPortrait
                      ? "22vw"
                      : isMobile && isPortrait
                      ? "55vw"
                      : "0vw",
                }}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-sync"
                  tooltip="Actualizar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "1em" }}
                  onClick={actClick}
                />
                <Button
                  icon="pi pi-print"
                  tooltip="Imprimir"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "1em" }}
                  onClick={imprimirClick}
                />
                <Button
                  id="imprimirClick"
                  icon="pi pi-print"
                  tooltip="Imprimir"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "1em", visibility: "hidden" }}
                  onClick={acceptImprimir}
                />

                {/*
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-reddit"
                  tooltip="Psicología"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClickPsicologia}
                />
                <Button
                  icon="pi pi-apple"
                  tooltip="Nutrición"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClickNutricion}
                />
                */}
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={historiasClinicas}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      responsiveLayout="scroll"

                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha"
                        body={fechaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Nombre"
                        body={nombreTemplate}
                        style={{ width: "40%" }}
                        sortable
                      ></Column>
                      <Column
                        field="numeroDocumento"
                        header="Documento"
                        body={documentoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={true}
                maximizable
                modal
                //footer={renderFooter()}
                onHide={onHide}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "100vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        style={{ marginLeft: "1vw" }}
                      >
                        Anterior
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleNext}>
                        {activeStep === steps.length - 1
                          ? "Guardar"
                          : "Siguiente"}
                      </Button>
                    </Box>

                    <Fragment>
                      {activeStep === 0 && selectedRowPaciente ? (
                        <DatosPersonales paciente={selectedRowPaciente} />
                      ) : null}

                      {activeStep === 1 && selectedRowPaciente ? (
                        <Fragilidad />
                      ) : null}

                      {activeStep === 2 && selectedRowPaciente ? (
                        <Antecedentes />
                      ) : null}

                      {activeStep === 3 && selectedRowPaciente ? (
                        <Actual />
                      ) : null}

                      {activeStep === 4 && selectedRowPaciente ? (
                        <ExamenI />
                      ) : null}
                      {activeStep === 5 && selectedRowPaciente ? (
                        <ExamenII />
                      ) : null}
                      {activeStep === 6 && selectedRowPaciente ? (
                        <ExamenIII />
                      ) : null}
                      {activeStep === 7 && selectedRowPaciente ? (
                        <Evoluciones />
                      ) : null}

                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                          style={{ marginLeft: "1vw" }}
                        >
                          Anterior
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleNext}>
                          {activeStep === steps.length - 1
                            ? "Guardar"
                            : "Siguiente"}
                        </Button>
                      </Box>
                    </Fragment>
                  </Box>
                </Grid>
              </Dialog>

              <Dialog
                showHeader={false}
                visible={dialogPacientes}
                closable={false}
                footer={renderFooterPacientes()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <Tooltip
                          target=".export-buttons>button"
                          position="bottom"
                        />
                        <DataTable
                          value={pacientes}
                          selection={selectedRowPaciente}
                          onSelectionChange={(e) =>
                            setSelectedRowPaciente(e.value)
                          }
                          selectionMode="single"
                          dataKey="id"
                          header={headerPacientes}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "small", marginTop: "1vw" }}
                          globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          paginator
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          rows={10}
                          rowsPerPageOptions={[10, 20, 50]}
                          responsiveLayout="scroll"

                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                        >
                          <Column
                            field="id"
                            header="ID"
                            body={idTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="nombre"
                            header="Apellido y Nombre"
                            body={nombreTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="domicilio"
                            header="Domicilio"
                            body={domicilioTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="numeroDocumento"
                            header="Documento"
                            body={documentoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="fechaIngresoEgreso"
                            header="Ingreso/Egreso"
                            body={ingresoEgresoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="motivoIngresoEgreso"
                            header="Motivo"
                            body={motivoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="activo"
                            header="Activo"
                            body={activoTemplate}
                            sortable
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
      {exit ? <Navigate to="/login" /> : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
