import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

export default function ExamenI(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    examenI,
    statusAcceso3,
    localidades,
    guardarExamenI,
    obtenerAcceso3,
    obtenerAcceso4,
  } = serviciosContext;

  useEffect(() => {
    obtenerAcceso4({
      usuarioId: state.usuario.id,
      layout: "Psicologia",
    });
    obtenerAcceso3({
      usuarioId: state.usuario.id,
      layout: "EditarPIHC",
    });
  }, []);

  const handleChangePregunta1 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta1: event.target.value,
    });
  };

  const handleChangePregunta2 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta2: event.target.value,
    });
  };

  const handleChangePregunta3 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta3: event.target.value,
    });
  };

  const handleChangePregunta4 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta4: event.target.value,
    });
  };

  const handleChangePregunta5 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta5: event.target.value,
    });
  };

  const handleChangePregunta6 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta6: event.target.value,
    });
  };

  const handleChangePregunta7 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta7: event.target.value,
    });
  };

  const handleChangePregunta8 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta8: event.target.value,
    });
  };

  const handleChangePregunta9 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta9: event.target.value,
    });
  };

  const handleChangePregunta10 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta10: event.target.value,
    });
  };

  const handleChangePregunta11 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta11: event.target.value,
    });
  };

  const handleChangePregunta12 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta12: event.target.value,
    });
  };

  const handleChangePregunta13 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta13: event.target.value,
    });
  };

  const handleChangePregunta14 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta14: event.target.value,
    });
  };

  const handleChangePregunta15 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta15: event.target.value,
    });
  };

  const handleChangePregunta16 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta16: event.target.value,
    });
  };

  const handleChangePregunta17 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta17: event.target.value,
    });
  };

  const handleChangePregunta18 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta18: event.target.value,
    });
  };

  const handleChangePregunta19 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta19: event.target.value,
    });
  };

  const handleChangePregunta20 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta20: event.target.value,
    });
  };

  const handleChangePregunta21 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta21: event.target.value,
    });
  };

  const handleChangePregunta22 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta22: event.target.value,
    });
  };

  const handleChangePregunta23 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta23: event.target.value,
    });
  };

  const handleChangePregunta24 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta24: event.target.value,
    });
  };

  const handleChangePregunta25 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta25: event.target.value,
    });
  };

  const handleChangePregunta26 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta26: event.target.value,
    });
  };

  const handleChangePregunta27 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta27: event.target.value,
    });
  };

  const handleChangePregunta28 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta28: event.target.value,
    });
  };

  const handleChangePregunta29 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta29: event.target.value,
    });
  };

  const handleChangePregunta30 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta30: event.target.value,
    });
  };

  const handleChangePregunta31 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta31: event.target.value,
    });
  };

  const handleChangePregunta32 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta32: event.target.value,
    });
  };

  const handleChangePregunta33 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta33: event.target.value,
    });
  };

  const handleChangePregunta34 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta34: event.target.value,
    });
  };

  const handleChangePregunta35 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta35: event.target.value,
    });
  };

  const handleChangePregunta36 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta36: event.target.value,
    });
  };

  const handleChangePregunta37 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta37: event.target.value,
    });
  };

  const handleChangePregunta38 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta38: event.target.value,
    });
  };

  const handleChangePregunta39 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta39: event.target.value,
    });
  };

  const handleChangePregunta40 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta40: event.target.value,
    });
  };

  const handleChangePregunta41 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta41: event.target.value,
    });
  };

  const handleChangePregunta42 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta42: event.target.value,
    });
  };

  const handleChangePregunta43 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta43: event.target.value,
    });
  };

  const handleChangePregunta44 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta44: event.target.value,
    });
  };

  const handleChangePregunta45 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta45: event.target.value,
    });
  };

  const handleChangePregunta46 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta46: event.target.value,
    });
  };

  const handleChangePregunta47 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta47: event.target.value,
    });
  };

  const handleChangePregunta48 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta48: event.target.value,
    });
  };

  const handleChangePregunta49 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta49: event.target.value,
    });
  };

  const handleChangePregunta50 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta50: event.target.value,
    });
  };

  const handleChangePregunta51 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta51: event.target.value,
    });
  };

  const handleChangePregunta52 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta52: event.target.value,
    });
  };

  const handleChangePregunta53 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta53: event.target.value,
    });
  };

  const handleChangePregunta54 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta54: event.target.value,
    });
  };

  const handleChangePregunta55 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta55: event.target.value,
    });
  };

  const handleChangePregunta56 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta56: event.target.value,
    });
  };

  const handleChangePregunta57 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta57: event.target.value,
    });
  };

  const handleChangePregunta58 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta58: event.target.value,
    });
  };

  const handleChangePregunta59 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta59: event.target.value,
    });
  };

  const handleChangePregunta60 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta60: event.target.value,
    });
  };

  const handleChangePregunta61 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta61: event.target.value,
    });
  };

  const handleChangePregunta62 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta62: event.target.value,
    });
  };

  const handleChangePregunta63 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta63: event.target.value,
    });
  };

  const handleChangePregunta64 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta64: event.target.value,
    });
  };

  const handleChangePregunta65 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta65: event.target.value,
    });
  };

  const handleChangePregunta66 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta66: event.target.value,
    });
  };

  const handleChangePregunta67 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta67: event.target.value,
    });
  };

  const handleChangePregunta68 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta68: event.target.value,
    });
  };

  const handleChangePregunta69 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta69: event.target.value,
    });
  };

  const handleChangePregunta70 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta70: event.target.value,
    });
  };

  const handleChangePregunta71 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta71: event.target.value,
    });
  };

  const handleChangePregunta72 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta72: event.target.value,
    });
  };

  const handleChangePregunta73 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta73: event.target.value,
    });
  };

  const handleChangePregunta74 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta74: event.target.value,
    });
  };

  const handleChangePregunta75 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta75: event.target.value,
    });
  };

  const handleChangePregunta76 = (event) => {
    guardarExamenI({
      ...examenI,
      pregunta76: event.target.value,
    });
  };

  //console.log(paciente);
  //console.log(examenI);

  return (
    <Fragment>
      {examenI ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "0vw", marginLeft: "0vw" }}
          >
            <Grid item xs={1} md={1} style={{ marginTop: "0vw" }}>
              <label for="ta1" style={{ marginTop: "3vw", marginLeft: "1vw" }}>
                TA
              </label>
            </Grid>
            <Grid item xs={3} md={1} style={{ marginTop: "-0vw" }}>
              <TextField
                value={examenI ? examenI.ta1 : null}
                id="ta1"
                label=""
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    ta1: e.target.value,
                  })
                }
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>
            <Grid item xs={3} md={1} style={{ marginTop: "0vw" }}>
              <TextField
                value={examenI ? examenI.ta2 : null}
                id="ta2"
                label=""
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    ta2: e.target.value,
                  })
                }
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>
            <Grid item xs={3} md={2} style={{ marginTop: "0vw" }}>
              <label style={{ marginTop: "3vw", marginLeft: "0vw" }}>
                mmHg (decúbito)
              </label>
            </Grid>

            <Grid item xs={3} md={1} style={{ marginTop: "0vw" }}>
              <TextField
                value={examenI ? examenI.ta3 : null}
                id="ta3"
                label=""
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    ta3: e.target.value,
                  })
                }
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>
            <Grid item xs={3} md={1} style={{ marginTop: "0vw" }}>
              <TextField
                value={examenI ? examenI.ta4 : null}
                id="ta4"
                label=""
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    ta4: e.target.value,
                  })
                }
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>
            <Grid item xs={5} md={5} style={{ marginTop: "-1vw" }}>
              <p style={{ paddingLeft: "1vw" }}>
                mmHg (bipedestación) hipotensión ortostatica
              </p>
            </Grid>

            <Grid item xs={1} md={1} style={{ marginTop: "-1vw" }}>
              <p style={{ paddingLeft: "1vw" }}>FC</p>
            </Grid>

            <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={examenI ? examenI.fc1 : null}
                id="fc1"
                label=""
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    fc1: e.target.value,
                  })
                }
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>
            <Grid item xs={5} md={1} style={{ marginTop: "-1vw" }}>
              <p style={{ paddingLeft: "1vw" }}>(Decúbito)</p>
            </Grid>
            <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={examenI ? examenI.fc2 : null}
                id="fc2"
                label=""
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    fc2: e.target.value,
                  })
                }
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>
            <Grid item xs={5} md={2} style={{ marginTop: "-1vw" }}>
              <p style={{ paddingLeft: "1vw" }}>(Bipedestación)</p>
            </Grid>

            <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={examenI ? examenI.peso : null}
                id="peso"
                label="Peso"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    peso: e.target.value,
                  })
                }
                InputLabelProps={{
                  shrink: true,
              }}
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={examenI ? examenI.talla : null}
                id="talla"
                label="Talla"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    talla: e.target.value,
                  })
                }
                InputLabelProps={{
                  shrink: true,
              }}
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={examenI ? examenI.t : null}
                id="t"
                label="Tº"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    t: e.target.value,
                  })
                }
                InputLabelProps={{
                  shrink: true,
              }}
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={examenI ? examenI.fr : null}
                id="fr"
                label="FR"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    fr: e.target.value,
                  })
                }
                InputLabelProps={{
                  shrink: true,
              }}
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                ESTADO DE CONCIENCIA:
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta1 : null}
                  onChange={handleChangePregunta1}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Desorientado temporo-espacialmente
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta2 : null}
                  onChange={handleChangePregunta2}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Revisado Normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta3 : null}
                  onChange={handleChangePregunta3}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Obnubilación
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta4 : null}
                  onChange={handleChangePregunta4}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Estupor
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta5 : null}
                  onChange={handleChangePregunta5}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Coma
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                PIEL:
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta6 : null}
                  onChange={handleChangePregunta6}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Aspecto normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta7 : null}
                  onChange={handleChangePregunta7}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Deshidratacion
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta8 : null}
                  onChange={handleChangePregunta8}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Coloración: normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta9 : null}
                  onChange={handleChangePregunta9}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Elasticidad: normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta10 : null}
                  onChange={handleChangePregunta10}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Humedad: normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta11 : null}
                  onChange={handleChangePregunta11}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Turgencia normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <TextField
                value={examenI ? examenI.otrosPiel : null}
                id="otrosPiel"
                label="Otros"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    otrosPiel: e.target.value,
                  })
                }
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                CABEZA:
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta12 : null}
                  onChange={handleChangePregunta12}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Asimetrías
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta13 : null}
                  onChange={handleChangePregunta13}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Dolor senos para nasales
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta14 : null}
                  onChange={handleChangePregunta14}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Latidos
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta15 : null}
                  onChange={handleChangePregunta15}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Cicatrices
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta16 : null}
                  onChange={handleChangePregunta16}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Tumoraciones
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta71 : null}
                  onChange={handleChangePregunta71}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Revisado normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <TextField
                value={examenI ? examenI.otrosCabeza : null}
                id="otrosCabeza"
                label="Otros"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    otrosCabeza: e.target.value,
                  })
                }
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                OJOS:
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta17 : null}
                  onChange={handleChangePregunta17}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Entropión
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta18 : null}
                  onChange={handleChangePregunta18}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Ectropión
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta19 : null}
                  onChange={handleChangePregunta19}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Exoftalmos
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta20 : null}
                  onChange={handleChangePregunta20}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Miosis
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta21 : null}
                  onChange={handleChangePregunta21}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Ptosis palpebral
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta22 : null}
                  onChange={handleChangePregunta22}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Sequedad ocular
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta23 : null}
                  onChange={handleChangePregunta23}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Conjuntivitis
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta24 : null}
                  onChange={handleChangePregunta24}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Blefaritis
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta25 : null}
                  onChange={handleChangePregunta25}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Movimientos oculares
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta26 : null}
                  onChange={handleChangePregunta26}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Nistagmos
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta72 : null}
                  onChange={handleChangePregunta72}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Revisado normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <TextField
                value={examenI ? examenI.otrosOjos : null}
                id="otrosOjos"
                label="Otros"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    otrosOjos: e.target.value,
                  })
                }
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                OIDOS:
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta27 : null}
                  onChange={handleChangePregunta27}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Tapón cerumen
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta28 : null}
                  onChange={handleChangePregunta28}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Eczema
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta29 : null}
                  onChange={handleChangePregunta29}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Perforación timpánica
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta30 : null}
                  onChange={handleChangePregunta30}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Forúnculo
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta31 : null}
                  onChange={handleChangePregunta31}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Otomicosis
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta32 : null}
                  onChange={handleChangePregunta32}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Otitis media
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta73 : null}
                  onChange={handleChangePregunta73}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Revisado normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <TextField
                value={examenI ? examenI.otrosOidos : null}
                id="otrosOidos"
                label="Otros"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    otrosOidos: e.target.value,
                  })
                }
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                BOCA:
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta33 : null}
                  onChange={handleChangePregunta33}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Alt. Dentición
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta34 : null}
                  onChange={handleChangePregunta34}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Prótesis
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta35 : null}
                  onChange={handleChangePregunta35}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Periodontitis
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta36 : null}
                  onChange={handleChangePregunta36}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Ulceras
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta37 : null}
                  onChange={handleChangePregunta37}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Candidiasis oral
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta38 : null}
                  onChange={handleChangePregunta38}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Estomatitis
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta39 : null}
                  onChange={handleChangePregunta39}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Xerostomía
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta40 : null}
                  onChange={handleChangePregunta40}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Región sublingual normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta41 : null}
                  onChange={handleChangePregunta41}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Fauces normales
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <TextField
                value={examenI ? examenI.otrosBoca : null}
                id="otrosBoca"
                label="Otros"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    otrosBoca: e.target.value,
                  })
                }
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                CUELLO:
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta42 : null}
                  onChange={handleChangePregunta42}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Forma: simétrico
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta43 : null}
                  onChange={handleChangePregunta43}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    cilíndrico
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta44 : null}
                  onChange={handleChangePregunta44}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Latidos anormales
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta45 : null}
                  onChange={handleChangePregunta45}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Ganglios
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta46 : null}
                  onChange={handleChangePregunta46}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    PVY
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta47 : null}
                  onChange={handleChangePregunta47}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Tiroides palpable
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta48 : null}
                  onChange={handleChangePregunta48}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Soplos
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta49 : null}
                  onChange={handleChangePregunta49}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Movimientos normales
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <TextField
                value={examenI ? examenI.otrosCuello : null}
                id="otrosCuello"
                label="Otros"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    otrosCuello: e.target.value,
                  })
                }
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                TORAX:
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta50 : null}
                  onChange={handleChangePregunta50}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Soplos
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta51 : null}
                  onChange={handleChangePregunta51}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Ruidos cardiacos normales
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta52 : null}
                  onChange={handleChangePregunta52}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Frémitos y otros
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta53 : null}
                  onChange={handleChangePregunta53}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Excursión respiratoria normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta54 : null}
                  onChange={handleChangePregunta54}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Percusión normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta55 : null}
                  onChange={handleChangePregunta55}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Entrada de aire normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta56 : null}
                  onChange={handleChangePregunta56}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Soplos, sibilancias
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>


            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta76 : null}
                  onChange={handleChangePregunta76}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Disnea
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>


            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta57 : null}
                  onChange={handleChangePregunta57}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Esputo
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta58 : null}
                  onChange={handleChangePregunta58}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Deformidades columna
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

{/*

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta59 : null}
                  onChange={handleChangePregunta59}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Percusión apof. Espinosas
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

                  */}

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta60 : null}
                  onChange={handleChangePregunta60}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Mamas normales
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta61 : null}
                  onChange={handleChangePregunta61}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Surco submamario normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta74 : null}
                  onChange={handleChangePregunta74}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Revisado normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <TextField
                value={examenI ? examenI.otrosTorax : null}
                id="otrosTorax"
                label="Otros"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    otrosTorax: e.target.value,
                  })
                }
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                ABDOMEN:
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta62 : null}
                  onChange={handleChangePregunta62}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Cicatrices
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta63 : null}
                  onChange={handleChangePregunta63}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Dolor a palp
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta64 : null}
                  onChange={handleChangePregunta64}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Distensión abdominal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta65 : null}
                  onChange={handleChangePregunta65}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Peritonismo
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta66 : null}
                  onChange={handleChangePregunta66}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Timpanismo
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta67 : null}
                  onChange={handleChangePregunta67}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Ruidos hidroaereos alt.
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta68 : null}
                  onChange={handleChangePregunta68}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Masas
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta69 : null}
                  onChange={handleChangePregunta69}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Globo vesical
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta70 : null}
                  onChange={handleChangePregunta70}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Ascitis
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenI ? examenI.pregunta75 : null}
                  onChange={handleChangePregunta75}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Revisado normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenI && examenI.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <TextField
                value={examenI ? examenI.otrosAbdomen : null}
                id="otrosAbdomen"
                label="Otros"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenI({
                    ...examenI,
                    otrosAbdomen: e.target.value,
                  })
                }
                disabled={examenI && examenI.status && statusAcceso3 !== 200}
              />
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
