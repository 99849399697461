import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";


export default function Fragilidad(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    fragilidad,
    statusAcceso3,
    localidades,
    guardarFragilidad,
    obtenerAcceso,
  } = serviciosContext;

  const handleChangePregunta1 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta1: event.target.checked,
      pregunta1Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta2 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta2: event.target.checked,
      pregunta2Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta3 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta3: event.target.checked,
      pregunta3Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta4 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta4: event.target.checked,
      pregunta4Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta5 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta5: event.target.checked,
      pregunta5Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta6 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta6: event.target.checked,
      pregunta6Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta7 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta7: event.target.checked,
      pregunta7Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta8 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta8: event.target.checked,
      pregunta8Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta9 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta9: event.target.checked,
      pregunta9Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta10 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta10: event.target.checked,
      pregunta10Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta11 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta11: event.target.checked,
      pregunta11Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta12 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta12: event.target.checked,
      pregunta12Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta13 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta13: event.target.checked,
      pregunta13Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta14 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta14: event.target.checked,
      pregunta14Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta15 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta15: event.target.checked,
      pregunta15Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta16 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta16: event.target.checked,
      pregunta16Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta17 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta17: event.target.checked,
      pregunta17Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta18 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta18: event.target.checked,
      pregunta18Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta19 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta19: event.target.checked,
      pregunta19Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta20 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta20: event.target.checked,
      pregunta20Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta21 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta21: event.target.checked,
      pregunta21Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta22 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta22: event.target.checked,
      pregunta22Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta23 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta23: event.target.checked,
      pregunta23Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta24 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta24: event.target.checked,
      pregunta24Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta25 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta25: event.target.checked,
      pregunta25Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta26 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta26: event.target.checked,
      pregunta26Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta27 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta27: event.target.checked,
      pregunta27Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta28 = (event) => {
    guardarFragilidad({
      ...fragilidad,
      pregunta28: event.target.checked,
      pregunta28Value: event.target.checked ? event.target.value : 0,
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  //console.log(fragilidad);

  return (
    <Fragment>
      {fragilidad ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "1vw", marginLeft: "0vw" }}
          >
            <Grid item xs={12} md={12} style={{ marginTop: "-1.5vw" }}>
              <TextField
                id="lugar"
                label="LUGAR DE DERIVACION casa/clínica/geriátrico/otros/"
                variant="standard"
                required
                fullWidth
                value={fragilidad ? fragilidad.lugar : null}
                onChange={(e) => {
                  guardarFragilidad({
                    ...fragilidad,
                    lugar: e.target.value,
                  });
                }}
                disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta1 : null}
                      onChange={handleChangePregunta1}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Mayor de 80 años."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta2 : null}
                      onChange={handleChangePregunta2}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Hospitalización reciente."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta3 : null}
                      onChange={handleChangePregunta3}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Estado de abandono."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta4 : null}
                      onChange={handleChangePregunta4}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Incontinencia."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta5 : null}
                      onChange={handleChangePregunta5}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Inmovilización."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta6 : null}
                      onChange={handleChangePregunta6}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Dependencia total."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta7 : null}
                      onChange={handleChangePregunta7}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Caídas recientes."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta8 : null}
                      onChange={handleChangePregunta8}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Cirugías recientes."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta9 : null}
                      onChange={handleChangePregunta9}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Ulceras  decúbito leves."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta10 : null}
                      onChange={handleChangePregunta10}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Ulceras  decúbito graves."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta11 : null}
                      onChange={handleChangePregunta11}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Demencia."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta12 : null}
                      onChange={handleChangePregunta12}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Depresión."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta13 : null}
                      onChange={handleChangePregunta13}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Anorexia."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta14 : null}
                      onChange={handleChangePregunta14}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Enfermedad Terminal."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta15 : null}
                      onChange={handleChangePregunta15}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Deshidratación."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta16 : null}
                      onChange={handleChangePregunta16}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Desnutrición."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta17 : null}
                      onChange={handleChangePregunta17}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Mal estado general."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta18 : null}
                      onChange={handleChangePregunta18}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Intento de suicidio."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta19 : null}
                      onChange={handleChangePregunta19}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Diabetes tipo I."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta20 : null}
                      onChange={handleChangePregunta20}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Hta .grave."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta21 : null}
                      onChange={handleChangePregunta21}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="ACV."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta22 : null}
                      onChange={handleChangePregunta22}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Epilepsia."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta23 : null}
                      onChange={handleChangePregunta23}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="EPOC."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta24 : null}
                      onChange={handleChangePregunta24}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Estado de coma."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta25 : null}
                      onChange={handleChangePregunta25}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Medicación psiquiatrica."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta26 : null}
                      onChange={handleChangePregunta26}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Sondas."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta27 : null}
                      onChange={handleChangePregunta27}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Ostomias."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fragilidad ? fragilidad.pregunta28 : null}
                      onChange={handleChangePregunta28}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Peligroso para si o terceros."
                  labelPlacement="end"
                  disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
                  />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h5" gutterBottom component="div">
                Total de puntos
              </Typography>
              <Typography variant="h4" gutterBottom component="div">
                {Number(fragilidad.pregunta1 ? fragilidad.pregunta1Value : 0) +
                  Number(fragilidad.pregunta2 ? fragilidad.pregunta2Value : 0) +
                  Number(fragilidad.pregunta3 ? fragilidad.pregunta3Value : 0) +
                  Number(fragilidad.pregunta4 ? fragilidad.pregunta4Value : 0) +
                  Number(fragilidad.pregunta5 ? fragilidad.pregunta5Value : 0) +
                  Number(fragilidad.pregunta6 ? fragilidad.pregunta6Value : 0) +
                  Number(fragilidad.pregunta7 ? fragilidad.pregunta7Value : 0) +
                  Number(fragilidad.pregunta8 ? fragilidad.pregunta8Value : 0) +
                  Number(fragilidad.pregunta9 ? fragilidad.pregunta9Value : 0) +
                  Number(
                    fragilidad.pregunta10 ? fragilidad.pregunta10Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta11 ? fragilidad.pregunta11Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta12 ? fragilidad.pregunta12Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta13 ? fragilidad.pregunta13Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta14 ? fragilidad.pregunta14Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta15 ? fragilidad.pregunta15Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta16 ? fragilidad.pregunta16Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta17 ? fragilidad.pregunta17Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta18 ? fragilidad.pregunta18Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta19 ? fragilidad.pregunta19Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta20 ? fragilidad.pregunta20Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta21 ? fragilidad.pregunta21Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta22 ? fragilidad.pregunta22Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta23 ? fragilidad.pregunta23Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta24 ? fragilidad.pregunta24Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta25 ? fragilidad.pregunta25Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta26 ? fragilidad.pregunta26Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta27 ? fragilidad.pregunta27Value : 0
                  ) +
                  Number(
                    fragilidad.pregunta28 ? fragilidad.pregunta28Value : 0
                  )}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
              Cuantificar gravedad de los positivos
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextareaAutosize
                value={
                  fragilidad
                    ? fragilidad.observaciones
                    : null
                }
                id="observaciones"
                aria-label="minimum height"
                minRows={2}
                maxRows={6}
                fullWidth
                placeholder="Cuantificar gravedad de los positivos"
                style={{ width: "100%" }}
                onChange={(e) =>
                  guardarFragilidad({
                    ...fragilidad,
                    observaciones: e.target.value,
                  })
                }
                disabled={fragilidad && fragilidad.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "0vw" }} />




          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
