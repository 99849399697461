import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Stack from "@mui/material/Stack";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FormGroup from "@mui/material/FormGroup";

export default function EvaluacionNutricional(props) {
  let navigate = useNavigate();

  const anios = [
    {
      id: 1,
      label: "2022",
      actual: false,
    },
    {
      id: 2,
      label: "2023",
      actual: false,
    },
    {
      id: 3,
      label: "2024",
      actual: true,
    },
    {
      id: 4,
      label: "2025",
      actual: false,
    },
    {
      id: 5,
      label: "2026",
      actual: false,
    },
  ];

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    selectedRowPaciente,
    evaluacionNutricional,
    tipoDieta,
    statusAcceso3,
    statusAcceso5,
    localidades,
    diagnosticosPacientes,
    diagnosticos,
    guardarEvaluacionNutricional,
    guardarTipoDieta,
    obtenerAcceso3,
    obtenerAcceso5,
    obtenerTiposDietas,
  } = serviciosContext;

  useEffect(() => {
    obtenerAcceso5({
      usuarioId: state.usuario.id,
      layout: "Nutricion",
    });
    obtenerAcceso3({
      usuarioId: state.usuario.id,
      layout: "EditarPIHC",
    });
  }, []);

  useEffect(() => {
    obtenerTiposDietas({
      anio: anios.find((item) => item.actual === true).label,
      pacienteId: selectedRowPaciente.id,
    });
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      anio: anios.find((item) => item.actual === true).label,
      edad: getEdad(selectedRowPaciente.fechaNacimiento)
    });
  }, []);

  const handleChangeDependencia = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      dependencia: event.target.value,
    });
  };

  const handleChangePostrado = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      postrado: event.target.value,
    });
  };

  const handleChangeProblemas = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      problemas: event.target.value,
    });
  };

  const handleChangeRiesgo = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      riesgoAlimentar: event.target.value,
    });
  };

  const handleChangeOrales = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      orales: event.target.value,
    });
  };

  const handleChangeNutricional = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      nutricional: event.target.value,
    });
  };

  const handleChangeNingunoPeso2Semanas = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      ningunoPeso2Semanas: event.target.checked,
    });
  };

  const handleChangePerdidaPeso2Semanas = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      perdidaPeso2Semanas: event.target.checked,
    });
  };

  const handleChangeAumentoPeso2Semanas = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      aumentoPeso2Semanas: event.target.checked,
    });
  };

  const handleChangeNingunoPeso6Meses = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      ningunoPeso6Meses: event.target.checked,
    });
  };

  const handleChangePerdidaPeso6Meses = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      perdidaPeso6Meses: event.target.checked,
    });
  };

  const handleChangeAumentoPeso6Meses = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      aumentoPeso6Meses: event.target.checked,
    });
  };

  const handleChangeNinguno = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      ninguno: event.target.checked,
    });
  };

  const handleChangeNauseas = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      nauseas: event.target.checked,
    });
  };

  const handleChangeVomitos = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      vomitos: event.target.checked,
    });
  };

  const handleChangeDiarrea = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      diarrea: event.target.checked,
    });
  };

  const handleChangeAnorexia = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      anorexia: event.target.checked,
    });
  };

  const handleChangeDolor = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      dolor: event.target.checked,
    });
  };

  const handleChangeSinCambios = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      sinCambios: event.target.checked,
    });
  };

  const handleChangeAyuno = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      ayuno: event.target.checked,
    });
  };

  const handleChangeHipocaloricos = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      hipocaloricos: event.target.checked,
    });
  };

  const handleChangeDietaLiquida = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      dietaLiquida: event.target.checked,
    });
  };

  const handleChangeDietaInsuficiente = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      dietaInsuficiente: event.target.checked,
    });
  };

  const handleChangeSinEstres = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      sinEstres: event.target.checked,
    });
  };

  const handleChangeEstresBajo = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      estresBajo: event.target.checked,
    });
  };

  const handleChangeEstresModerado = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      estresModerado: event.target.checked,
    });
  };

  const handleChangeEstresAlto = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      estresAlto: event.target.checked,
    });
  };

  const handleChangePerdidaGrasaNormal = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      perdidaGrasaNormal: event.target.checked,
    });
  };

  const handleChangePerdidaGrasaDeficitLigero = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      perdidaGrasaDeficitLigero: event.target.checked,
    });
  };

  const handleChangePerdidaGrasaDeficitModerado = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      perdidaGrasaDeficitModerado: event.target.checked,
    });
  };

  const handleChangePerdidaGrasaDeficitSevero = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      perdidaGrasaDeficitSevero: event.target.checked,
    });
  };

  const handleChangePerdidaMasaNormal = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      perdidaMasaNormal: event.target.checked,
    });
  };

  const handleChangePerdidaMasaDeficitLigero = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      perdidaMasaDeficitLigero: event.target.checked,
    });
  };

  const handleChangePerdidaMasaDeficitModerado = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      perdidaMasaDeficitModerado: event.target.checked,
    });
  };

  const handleChangePerdidaMasaDeficitSevero = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      perdidaMasaDeficitSevero: event.target.checked,
    });
  };



  const handleChangeEdemaMaleolar = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      edemaMaleolar: event.target.value,
    });
  };

  const handleChangeUlcerasPP = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      ulcerasPP: event.target.value,
    });
  };


  const handleChangeEdemaSacro = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      edemaSacro: event.target.value,
    });
  };

  const handleChangeFiebre = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      fiebre: event.target.value,
    });
  };

  const handleChangeAscitis = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      ascitis: event.target.value,
    });
  };

  const handleChangeSintomas = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      sintomas: event.target.value,
    });
  };


  const handleChangeEdemaMaleolarNormal = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      edemaMaleolarNormal: event.target.checked,
    });
  };

  const handleChangeEdemaMaleolarDeficitLigero = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      edemaMaleolarDeficitLigero: event.target.checked,
    });
  };

  const handleChangeEdemaMaleolarDeficitModerado = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      edemaMaleolarDeficitModerado: event.target.checked,
    });
  };

  const handleChangeEdemaMaleolarDeficitSevero = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      edemaMaleolarDeficitSevero: event.target.checked,
    });
  };

  const handleChangeEdemaSacroNormal = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      edemaSacroNormal: event.target.checked,
    });
  };

  const handleChangeEdemaSacroDeficitLigero = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      edemaSacroDeficitLigero: event.target.checked,
    });
  };

  const handleChangeEdemaSacroDeficitModerado = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      edemaSacroDeficitModerado: event.target.checked,
    });
  };

  const handleChangeEdemaSacroDeficitSevero = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      edemaSacroDeficitSevero: event.target.checked,
    });
  };

  const handleChangeAscitisNormal = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      ascitisNormal: event.target.checked,
    });
  };

  const handleChangeAscitisDeficitLigero = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      ascitisDeficitLigero: event.target.checked,
    });
  };

  const handleChangeAscitisDeficitModerado = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      ascitisDeficitModerado: event.target.checked,
    });
  };

  const handleChangeAscitisDeficitSevero = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      ascitisDeficitSevero: event.target.checked,
    });
  };

  const handleChangeDiagnosticoNutricional = (event) => {
    guardarEvaluacionNutricional({
      ...evaluacionNutricional,
      diagnosticoNutricional: event.target.value,
    });
  };

  const handleChangeActitud = (e, i) => {
    const myNewArray = Object.assign([...tipoDieta], {
      [i]: {
        ...tipoDieta[i],
        actitud: e.target.value,
        mes: i + 1,
        anio: evaluacionNutricional.anio,
      },
    });
    guardarTipoDieta(myNewArray);
  };

  const handleChangeDepende = (e, i) => {
    const myNewArray = Object.assign([...tipoDieta], {
      [i]: {
        ...tipoDieta[i],
        depende: e.target.value,
        mes: i + 1,
        anio: evaluacionNutricional.anio,
      },
    });
    guardarTipoDieta(myNewArray);
  };

  const handleChangeComplemento = (e, i) => {
    const myNewArray = Object.assign([...tipoDieta], {
      [i]: {
        ...tipoDieta[i],
        complemento: e.target.value,
        mes: i + 1,
        anio: evaluacionNutricional.anio,
      },
    });
    guardarTipoDieta(myNewArray);
  };

  const handleChangePesoMes = (e, i) => {
    const myNewArray = Object.assign([...tipoDieta], {
      [i]: {
        ...tipoDieta[i],
        pesoMes: e.target.value,
        imc: parseFloat(
          Number(e.target.value) /
            Number(tipoDieta[i].talla * tipoDieta[i].talla)
        ).toFixed(2),
        mes: i + 1,
        anio: evaluacionNutricional.anio,
      },
    });
    guardarTipoDieta(myNewArray);
  };

  const handleChangeTalla = (e, i) => {
    const myNewArray = Object.assign([...tipoDieta], {
      [i]: {
        ...tipoDieta[i],
        talla: e.target.value,
        imc: parseFloat(
          Number(tipoDieta[i].pesoMes) / Number(e.target.value * e.target.value)
        ).toFixed(2),
        mes: i + 1,
        anio: evaluacionNutricional.anio,
      },
    });
    guardarTipoDieta(myNewArray);
  };

  const handleChangeImc = (e, i) => {
    const myNewArray = Object.assign([...tipoDieta], {
      [i]: {
        ...tipoDieta[i],
        imc: e.target.value,
        mes: i + 1,
        anio: evaluacionNutricional.anio,
      },
    });
    guardarTipoDieta(myNewArray);
  };

  const handleChangeRiesgoNutricional = (e, i) => {
    const myNewArray = Object.assign([...tipoDieta], {
      [i]: {
        ...tipoDieta[i],
        riesgoNutricional: e.target.value,
        mes: i + 1,
        anio: evaluacionNutricional.anio,
      },
    });
    guardarTipoDieta(myNewArray);
  };


  function getEdad(dateString) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  }

  //console.log(selectedRowPaciente)
  //console.log(new Date(tipoDieta.mes + "/01" + "/" + tipoDieta.anio))
  //console.log(evaluacionNutricional);

  return (
    <Fragment>
      {evaluacionNutricional ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "0vw", marginLeft: "0vw" }}
          >
            <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  evaluacionNutricional ? evaluacionNutricional.edad : null
                }
                id="edad"
                label="Edad"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarEvaluacionNutricional({
                    ...evaluacionNutricional,
                    edad: e.target.value,
                  })
                }
                disabled={
                  (evaluacionNutricional &&
                    evaluacionNutricional.status &&
                    statusAcceso3 !== 200) ||
                  (evaluacionNutricional &&
                    !evaluacionNutricional.status &&
                    statusAcceso5 !== 200)
                }
              />
            </Grid>

            <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  evaluacionNutricional ? evaluacionNutricional.sexo : null
                }
                id="sexo"
                error={
                  evaluacionNutricional.sexo === undefined ||
                  evaluacionNutricional.sexo === null ||
                  evaluacionNutricional.sexo === "" ||
                  (evaluacionNutricional.sexo !== "F" &&
                    evaluacionNutricional.sexo !== "M")
                }
                label="Sexo"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarEvaluacionNutricional({
                    ...evaluacionNutricional,
                    sexo: e.target.value,
                  })
                }
                helperText={
                  evaluacionNutricional.sexo === undefined ||
                  evaluacionNutricional.sexo === null ||
                  evaluacionNutricional.sexo === "" ||
                  (evaluacionNutricional.sexo !== "F" &&
                    evaluacionNutricional.sexo !== "M")
                    ? "Ingresar M o F"
                    : null
                }
                disabled={
                  (evaluacionNutricional &&
                    evaluacionNutricional.status &&
                    statusAcceso3 !== 200) ||
                  (evaluacionNutricional &&
                    !evaluacionNutricional.status &&
                    statusAcceso5 !== 200)
                }
              />
            </Grid>

            <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  evaluacionNutricional ? evaluacionNutricional.peso : null
                }
                id="peso"
                label="Peso"
                fullWidth
                type="number"
                variant="standard"
                onChange={(e) =>
                  guardarEvaluacionNutricional({
                    ...evaluacionNutricional,
                    peso: e.target.value,
                  })
                }
                disabled={
                  (evaluacionNutricional &&
                    evaluacionNutricional.status &&
                    statusAcceso3 !== 200) ||
                  (evaluacionNutricional &&
                    !evaluacionNutricional.status &&
                    statusAcceso5 !== 200)
                }
              />
            </Grid>

            <Grid item xs={6} md={3} style={{ marginTop: "0vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    evaluacionNutricional
                      ? evaluacionNutricional.dependencia
                      : null
                  }
                  onChange={handleChangeDependencia}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Dependencia
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={3} style={{ marginTop: "0vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    evaluacionNutricional
                      ? evaluacionNutricional.postrado
                      : null
                  }
                  onChange={handleChangePostrado}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Postrado
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={3} style={{ marginTop: "0vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    evaluacionNutricional
                      ? evaluacionNutricional.problemas
                      : null
                  }
                  onChange={handleChangeProblemas}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Problemas
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={4} style={{ marginTop: "0vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    evaluacionNutricional
                      ? evaluacionNutricional.riesgoAlimentar
                      : null
                  }
                  onChange={handleChangeRiesgo}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Riesgo P/Alimentar
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={4} style={{ marginTop: "0vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    evaluacionNutricional ? evaluacionNutricional.orales : null
                  }
                  onChange={handleChangeOrales}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Orales
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={4} style={{ marginTop: "0vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    evaluacionNutricional
                      ? evaluacionNutricional.nutricional
                      : null
                  }
                  onChange={handleChangeNutricional}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Nutricional
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Diagnóstico
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <label
                style={{
                  fontWeight: "bold",
                  marginLeft: "1.3vw",
                  marginTop: "1vw",
                }}
              >
                Cambios de peso ultimas 2 semanas
              </label>
            </Grid>

            <Grid item xs={12} md={8}>
              <FormControl>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.ningunoPeso2Semanas
                            : null
                        }
                        onChange={handleChangeNingunoPeso2Semanas}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Ninguno"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.perdidaPeso2Semanas
                            : null
                        }
                        onChange={handleChangePerdidaPeso2Semanas}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Pérdida de Peso"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.aumentoPeso2Semanas
                            : null
                        }
                        onChange={handleChangeAumentoPeso2Semanas}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Aumento de Peso"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <label
                style={{
                  fontWeight: "bold",
                  marginLeft: "1.3vw",
                  marginTop: "1vw",
                }}
              >
                Cambios de peso ultimos 6 meses
              </label>
            </Grid>

            <Grid item xs={12} md={8}>
              <FormControl>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.ningunoPeso6Meses
                            : null
                        }
                        onChange={handleChangeNingunoPeso6Meses}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Ninguno"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.perdidaPeso6Meses
                            : null
                        }
                        onChange={handleChangePerdidaPeso6Meses}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Pérdida de Peso"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.aumentoPeso6Meses
                            : null
                        }
                        onChange={handleChangeAumentoPeso6Meses}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Aumento de Peso"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <label
                style={{
                  fontWeight: "bold",
                  marginLeft: "1.3vw",
                  marginTop: "1vw",
                }}
              >
                {" "}
                Sistemas gastrointestinales
              </label>
            </Grid>

            <Grid item xs={12} md={8}>
              <FormControl>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.ninguno
                            : null
                        }
                        onChange={handleChangeNinguno}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Ninguno"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.nauseas
                            : null
                        }
                        onChange={handleChangeNauseas}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Nauseas"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.vomitos
                            : null
                        }
                        onChange={handleChangeVomitos}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Vómitos"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.diarrea
                            : null
                        }
                        onChange={handleChangeDiarrea}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Diarrea"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.anorexia
                            : null
                        }
                        onChange={handleChangeAnorexia}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Anorexia"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.dolor
                            : null
                        }
                        onChange={handleChangeDolor}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Dolor"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <label
                style={{
                  fontWeight: "bold",
                  marginLeft: "1.3vw",
                  marginTop: "1vw",
                }}
              >
                Cambios en la injesta
              </label>
            </Grid>

            <Grid item xs={12} md={8}>
              <FormControl>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.sinCambios
                            : null
                        }
                        onChange={handleChangeSinCambios}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Ninguno"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.ayuno
                            : null
                        }
                        onChange={handleChangeAyuno}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Ayuno"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.hipocaloricos
                            : null
                        }
                        onChange={handleChangeHipocaloricos}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Hipocaloricos"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.dietaLiquida
                            : null
                        }
                        onChange={handleChangeDietaLiquida}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Dieta Líquida"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.dietaInsuficiente
                            : null
                        }
                        onChange={handleChangeDietaInsuficiente}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Dieta Insuficiente"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            {/*      
            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  evaluacionNutricional
                    ? evaluacionNutricional.datosIngreso
                    : null
                }
                id="ingreso"
                label="Datos de Ingreso"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarEvaluacionNutricional({
                    ...evaluacionNutricional,
                    datosIngreso: e.target.value,
                  })
                }
                disabled={
                  (evaluacionNutricional &&
                    evaluacionNutricional.status &&
                    statusAcceso3 !== 200) ||
                  (evaluacionNutricional &&
                    !evaluacionNutricional.status &&
                    statusAcceso5 !== 200)
                }
              />
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  evaluacionNutricional
                    ? evaluacionNutricional.diagnosticoMedico
                    : null
                }
                id="diagnostico"
                label="Diagnóstico Médico"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarEvaluacionNutricional({
                    ...evaluacionNutricional,
                    diagnosticoMedico: e.target.value,
                  })
                }
                disabled={
                  (evaluacionNutricional &&
                    evaluacionNutricional.status &&
                    statusAcceso3 !== 200) ||
                  (evaluacionNutricional &&
                    !evaluacionNutricional.status &&
                    statusAcceso5 !== 200)
                }
              />
            </Grid>

            {/*    
            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  evaluacionNutricional ? evaluacionNutricional.dieta : null
                }
                id="dieta"
                label="Tipo de Dieta"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarEvaluacionNutricional({
                    ...evaluacionNutricional,
                    dieta: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  evaluacionNutricional ? evaluacionNutricional.tipoDieta : null
                }
                id="ingreso"
                label="Tipo de Dieta"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarEvaluacionNutricional({
                    ...evaluacionNutricional,
                    tipoDieta: e.target.value,
                  })
                }
                disabled={
                  (evaluacionNutricional &&
                    evaluacionNutricional.status &&
                    statusAcceso3 !== 200) ||
                  (evaluacionNutricional &&
                    !evaluacionNutricional.status &&
                    statusAcceso5 !== 200)
                }
              />
            </Grid>

            */}

            <Grid item xs={12} md={12} style={{ marginTop: "1vw" }}>
              <Typography variant="button" display="block" gutterBottom>
                TABLA :
              </Typography>
            </Grid>

            {/*    
            <Grid item xs={12} md={1} style={{ marginTop: "1vw" }}>
              <Typography variant="button" display="block" gutterBottom>
                Período :
              </Typography>
            </Grid>
              */}

            <Grid item xs={12} md={1.5}>
              <Autocomplete
                id="anio"
                fullWidth
                options={anios}
                disablePortal
                required
                //defaultValue={colores[idColorDefault]}

                defaultValue={anios[anios.findIndex((item) => item.actual === true)]}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    style={{ marginTop: "-1vw" }}
                    label="Período"
                  />
                )}
                //onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarEvaluacionNutricional({
                      ...evaluacionNutricional,
                      anio: value.label,
                    });
                    obtenerTiposDietas({
                      ...tipoDieta,
                      anio: value.label,
                      pacienteId: selectedRowPaciente.id,
                    });
                  } else {
                    guardarEvaluacionNutricional({
                      ...evaluacionNutricional,
                      anio: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "0vw" }} />

            <Grid item xs={12} md={1} style={{ marginTop: "1vw" }}>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                style={{ textDecoration: "underline" }}
              >
                Mes
              </Typography>
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "1vw" }}>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                style={{ textDecoration: "underline" }}
              >
                Actitud Frente a la Comida
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} style={{ marginTop: "1vw" }}>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                style={{ textDecoration: "underline" }}
              >
                Depende P/Alimentar
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} style={{ marginTop: "1vw" }}>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                style={{ textDecoration: "underline" }}
              >
                Complemento Alimentario
              </Typography>
            </Grid>
            <Grid item xs={12} md={1} style={{ marginTop: "1vw" }}>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                style={{ textDecoration: "underline" }}
              >
                Peso
              </Typography>
            </Grid>
            <Grid item xs={12} md={1} style={{ marginTop: "1vw" }}>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                style={{ textDecoration: "underline" }}
              >
                Talla en m
              </Typography>
            </Grid>
            <Grid item xs={12} md={1} style={{ marginTop: "1vw" }}>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                style={{ textDecoration: "underline" }}
              >
                IMC
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} style={{ marginTop: "1vw" }}>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                style={{ textDecoration: "underline" }}
              >
                Riesgo Nutricional
              </Typography>
            </Grid>

            {/*ENERO*/}

            {tipoDieta.map((tipo) => (
              <Fragment>
                <Grid item xs={12} md={1} style={{ marginTop: "1vw" }}>
                  <Typography
                    variant="button"
                    display="block"
                    gutterBottom
                    style={{ textDecoration: "underline" }}
                  >
                    {tipo.id === 1
                      ? "ENERO"
                      : tipo.id === 2
                      ? "FEBRERO"
                      : tipo.id === 3
                      ? "MARZO"
                      : tipo.id === 4
                      ? "ABRIL"
                      : tipo.id === 5
                      ? "MAYO"
                      : tipo.id === 6
                      ? "JUNIO"
                      : tipo.id === 7
                      ? "JULIO"
                      : tipo.id === 8
                      ? "AGOSTO"
                      : tipo.id === 9
                      ? "SEPTIEMBRE"
                      : tipo.id === 10
                      ? "OCTUBRE"
                      : tipo.id === 11
                      ? "NOVIEMBRE"
                      : tipo.id === 12
                      ? "DICIEMBRE"
                      : null}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={2} style={{ marginTop: "0vw" }}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={
                        tipoDieta && tipoDieta[tipo.id - 1]
                          ? tipoDieta[tipo.id - 1].actitud
                          : null
                      }
                      onChange={(e) => handleChangeActitud(e, tipo.id - 1)}
                    >
                      <FormLabel
                        id="escalones"
                        style={{
                          fontWeight: "bold",
                          fontSize: "large",
                          marginRight: "1vw",
                          marginTop: ".5vw",
                        }}
                      ></FormLabel>

                      <FormControlLabel
                        value="B"
                        control={<Radio />}
                        label="B"
                        disabled={
                          (evaluacionNutricional &&
                            evaluacionNutricional.status &&
                            statusAcceso3 !== 200) ||
                          (evaluacionNutricional &&
                            !evaluacionNutricional.status &&
                            statusAcceso5 !== 200)
                        }
                      />
                      <FormControlLabel
                        value="R"
                        control={<Radio />}
                        label="R"
                        disabled={
                          (evaluacionNutricional &&
                            evaluacionNutricional.status &&
                            statusAcceso3 !== 200) ||
                          (evaluacionNutricional &&
                            !evaluacionNutricional.status &&
                            statusAcceso5 !== 200)
                        }
                      />
                      <FormControlLabel
                        value="M"
                        control={<Radio />}
                        label="M"
                        disabled={
                          (evaluacionNutricional &&
                            evaluacionNutricional.status &&
                            statusAcceso3 !== 200) ||
                          (evaluacionNutricional &&
                            !evaluacionNutricional.status &&
                            statusAcceso5 !== 200)
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={2} style={{ marginTop: "0vw" }}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={
                        tipoDieta && tipoDieta[tipo.id - 1]
                          ? tipoDieta[tipo.id - 1].depende
                          : null
                      }
                      onChange={(e) => handleChangeDepende(e, tipo.id - 1)}
                    >
                      <FormLabel
                        id="escalones"
                        style={{
                          fontWeight: "bold",
                          fontSize: "large",
                          marginRight: "1vw",
                          marginTop: ".5vw",
                        }}
                      ></FormLabel>

                      <FormControlLabel
                        value="SI"
                        control={<Radio />}
                        label="SI"
                        disabled={
                          (evaluacionNutricional &&
                            evaluacionNutricional.status &&
                            statusAcceso3 !== 200) ||
                          (evaluacionNutricional &&
                            !evaluacionNutricional.status &&
                            statusAcceso5 !== 200)
                        }
                      />
                      <FormControlLabel
                        value="NO"
                        control={<Radio />}
                        label="NO"
                        disabled={
                          (evaluacionNutricional &&
                            evaluacionNutricional.status &&
                            statusAcceso3 !== 200) ||
                          (evaluacionNutricional &&
                            !evaluacionNutricional.status &&
                            statusAcceso5 !== 200)
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={2} style={{ marginTop: "0vw" }}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={
                        tipoDieta && tipoDieta[tipo.id - 1]
                          ? tipoDieta[tipo.id - 1].complemento
                          : null
                      }
                      onChange={(e) => handleChangeComplemento(e, tipo.id - 1)}
                    >
                      <FormLabel
                        id="escalones"
                        style={{
                          fontWeight: "bold",
                          fontSize: "large",
                          marginRight: "1vw",
                          marginTop: ".5vw",
                        }}
                      ></FormLabel>

                      <FormControlLabel
                        value="SI"
                        control={<Radio />}
                        label="SI"
                        disabled={
                          (evaluacionNutricional &&
                            evaluacionNutricional.status &&
                            statusAcceso3 !== 200) ||
                          (evaluacionNutricional &&
                            !evaluacionNutricional.status &&
                            statusAcceso5 !== 200)
                        }
                      />
                      <FormControlLabel
                        value="NO"
                        control={<Radio />}
                        label="NO"
                        disabled={
                          (evaluacionNutricional &&
                            evaluacionNutricional.status &&
                            statusAcceso3 !== 200) ||
                          (evaluacionNutricional &&
                            !evaluacionNutricional.status &&
                            statusAcceso5 !== 200)
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
                  <TextField
                    value={
                      tipoDieta[tipo.id - 1].pesoMes
                        ? tipoDieta[tipo.id - 1].pesoMes
                        : ""
                    }
                    id="pesoMes"
                    //label="Peso"
                    fullWidth
                    variant="standard"
                    onChange={(e) => handleChangePesoMes(e, tipo.id - 1)}
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                </Grid>

                <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
                  <TextField
                    value={
                      tipoDieta[tipo.id - 1].talla
                        ? tipoDieta[tipo.id - 1].talla
                        : ""
                    }
                    id="talla"
                    //label="Peso"
                    fullWidth
                    variant="standard"
                    onChange={(e) => handleChangeTalla(e, tipo.id - 1)}
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                </Grid>

                <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
                  <TextField
                    value={
                      tipoDieta[tipo.id - 1].imc ? tipoDieta[tipo.id - 1].imc : ""
                    }
                    id="imc"
                    //label="Peso"
                    fullWidth
                    variant="standard"
                    onChange={(e) => handleChangeImc(e, tipo.id - 1)}
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                </Grid>

                <Grid item xs={12} md={2} style={{ marginTop: "0vw" }}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={
                        tipoDieta[tipo.id - 1]
                          ? tipoDieta[tipo.id - 1].riesgoNutricional
                          : null
                      }
                      onChange={(e) =>
                        handleChangeRiesgoNutricional(e, tipo.id - 1)
                      }
                    >
                      <FormLabel
                        id="escalones"
                        style={{
                          fontWeight: "bold",
                          fontSize: "large",
                          marginRight: "1vw",
                          marginTop: ".5vw",
                        }}
                      ></FormLabel>

                      <FormControlLabel
                        value="SI"
                        control={<Radio />}
                        label="SI"
                        disabled={
                          (evaluacionNutricional &&
                            evaluacionNutricional.status &&
                            statusAcceso3 !== 200) ||
                          (evaluacionNutricional &&
                            !evaluacionNutricional.status &&
                            statusAcceso5 !== 200)
                        }
                      />
                      <FormControlLabel
                        value="NO"
                        control={<Radio />}
                        label="NO"
                        disabled={
                          (evaluacionNutricional &&
                            evaluacionNutricional.status &&
                            statusAcceso3 !== 200) ||
                          (evaluacionNutricional &&
                            !evaluacionNutricional.status &&
                            statusAcceso5 !== 200)
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Fragment>
            ))}

            {/* FIN MES*/}

            <Grid item xs={12} md={12}>
              <Autocomplete
                id="diagnostico"
                fullWidth
                multiple
                options={diagnosticos}
                disablePortal
                defaultValue={
                  selectedRowPaciente &&
                  diagnosticosPacientes &&
                  diagnosticosPacientes !== null &&
                  diagnosticosPacientes.length > 0
                    ? diagnosticosPacientes
                    : []
                }
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    style={{ marginTop: "-1vw" }}
                    label="Diagnóstico Primario"
                  />
                )}
                disabled
                //onKeyDown={handleEnter}
                /*
                      onChange={(event, values) => {
                        if (values) {
                          guardarDiagnosticosPacientes(values);
                        } else {
                          guardarDiagnosticosPacientes(null);
                        }
                      }}
                      */
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl>
                <FormGroup aria-label="position" row>
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Requerimientos Metabólicos (estrés)
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.sinEstres
                            : null
                        }
                        onChange={handleChangeSinEstres}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Sin Estrés"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.estresBajo
                            : null
                        }
                        onChange={handleChangeEstresBajo}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Estrés Bajo"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.estresModerado
                            : null
                        }
                        onChange={handleChangeEstresModerado}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Estrés Moderado"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.estresAlto
                            : null
                        }
                        onChange={handleChangeEstresAlto}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Estrés Alto"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Exámen Físico
              </Typography>
            </Grid>

            <Grid item xs={12} md={2}>
              <label
                style={{
                  fontWeight: "bold",
                  marginLeft: "1.3vw",
                  marginTop: "1vw",
                }}
              >
                Grasa subcutánea
              </label>
            </Grid>

            <Grid item xs={12} md={10}>
              <FormControl>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.perdidaGrasaNormal
                            : null
                        }
                        onChange={handleChangePerdidaGrasaNormal}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Normal"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.perdidaGrasaDeficitLigero
                            : null
                        }
                        onChange={handleChangePerdidaGrasaDeficitLigero}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Déficit Ligero"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.perdidaGrasaDeficitModerado
                            : null
                        }
                        onChange={handleChangePerdidaGrasaDeficitModerado}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Déficit Moderado"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.perdidaGrasaDeficitSevero
                            : null
                        }
                        onChange={handleChangePerdidaGrasaDeficitSevero}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Déficit Severo"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <label
                style={{
                  fontWeight: "bold",
                  marginLeft: "1.3vw",
                  marginTop: "1vw",
                }}
              >
                Masa muscular
              </label>
            </Grid>

            <Grid item xs={12} md={10}>
              <FormControl>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.perdidaMasaNormal
                            : null
                        }
                        onChange={handleChangePerdidaMasaNormal}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Normal"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.perdidaMasaDeficitLigero
                            : null
                        }
                        onChange={handleChangePerdidaMasaDeficitLigero}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Déficit Ligero"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.perdidaMasaDeficitModerado
                            : null
                        }
                        onChange={handleChangePerdidaMasaDeficitModerado}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Déficit Moderado"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.perdidaMasaDeficitSevero
                            : null
                        }
                        onChange={handleChangePerdidaMasaDeficitSevero}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Déficit Severo"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>


            <Grid item xs={12} md={12}/>
            <Grid item xs={6} md={2.5} style={{ marginTop: "0vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    evaluacionNutricional
                      ? evaluacionNutricional.edemaMaleolar
                      : null
                  }
                  onChange={handleChangeEdemaMaleolar}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Edema Maleolar
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>


            <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  evaluacionNutricional ? evaluacionNutricional.gradoEdemaMaleolar : null
                }
                id="gradoEdemaMaleolar"
                label="Grado"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarEvaluacionNutricional({
                    ...evaluacionNutricional,
                    gradoEdemaMaleolar: e.target.value,
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}

                disabled={
                  (evaluacionNutricional &&
                    evaluacionNutricional.status &&
                    statusAcceso3 !== 200) ||
                  (evaluacionNutricional &&
                    !evaluacionNutricional.status &&
                    statusAcceso5 !== 200)
                }
              />
            </Grid>


            <Grid item xs={6} md={2.5} style={{ marginTop: "0vw", marginLeft: "4vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    evaluacionNutricional
                      ? evaluacionNutricional.ulcerasPP
                      : null
                  }
                  onChange={handleChangeUlcerasPP}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Ulceras PP
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>


            <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  evaluacionNutricional ? evaluacionNutricional.gradoUlcerasPP : null
                }
                id="gradoUlcerasPP"
                label="Grado"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarEvaluacionNutricional({
                    ...evaluacionNutricional,
                    gradoUlcerasPP: e.target.value,
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}

                disabled={
                  (evaluacionNutricional &&
                    evaluacionNutricional.status &&
                    statusAcceso3 !== 200) ||
                  (evaluacionNutricional &&
                    !evaluacionNutricional.status &&
                    statusAcceso5 !== 200)
                }
              />
            </Grid>





            <Grid item xs={12} md={4}/>
            <Grid item xs={6} md={2.5} style={{ marginTop: "0vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    evaluacionNutricional
                      ? evaluacionNutricional.edemaSacro
                      : null
                  }
                  onChange={handleChangeEdemaSacro}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Edema Sacro
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>


            <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  evaluacionNutricional ? evaluacionNutricional.gradoEdemaSacro : null
                }
                id="gradoEdemaSacro"
                label="Grado"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarEvaluacionNutricional({
                    ...evaluacionNutricional,
                    gradoEdemaSacro: e.target.value,
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}

                disabled={
                  (evaluacionNutricional &&
                    evaluacionNutricional.status &&
                    statusAcceso3 !== 200) ||
                  (evaluacionNutricional &&
                    !evaluacionNutricional.status &&
                    statusAcceso5 !== 200)
                }
              />
            </Grid>


            <Grid item xs={6} md={2.5} style={{ marginTop: "0vw", marginLeft: "4vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    evaluacionNutricional
                      ? evaluacionNutricional.fiebre
                      : null
                  }
                  onChange={handleChangeFiebre}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Fiebre
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>






            <Grid item xs={12} md={5}/>
            <Grid item xs={6} md={2.5} style={{ marginTop: "0vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    evaluacionNutricional
                      ? evaluacionNutricional.ascitis
                      : null
                  }
                  onChange={handleChangeAscitis}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Ascitis
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>


            <Grid item xs={4} md={1} style={{ marginTop: "-1vw" }}>
              <TextField
                value={
                  evaluacionNutricional ? evaluacionNutricional.gradoAscitis : null
                }
                id="gradoAscitis"
                label="Grado"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarEvaluacionNutricional({
                    ...evaluacionNutricional,
                    gradoAscitis: e.target.value,
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}

                disabled={
                  (evaluacionNutricional &&
                    evaluacionNutricional.status &&
                    statusAcceso3 !== 200) ||
                  (evaluacionNutricional &&
                    !evaluacionNutricional.status &&
                    statusAcceso5 !== 200)
                }
              />
            </Grid>


            <Grid item xs={6} md={2.5} style={{ marginTop: "0vw", marginLeft: "4vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    evaluacionNutricional
                      ? evaluacionNutricional.sintomas
                      : null
                  }
                  onChange={handleChangeSintomas}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Síntomas
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      (evaluacionNutricional &&
                        evaluacionNutricional.status &&
                        statusAcceso3 !== 200) ||
                      (evaluacionNutricional &&
                        !evaluacionNutricional.status &&
                        statusAcceso5 !== 200)
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>





{/*


            <Grid item xs={12} md={2}>
              <label
                style={{
                  fontWeight: "bold",
                  marginLeft: "1.3vw",
                  marginTop: "1vw",
                }}
              >
                Edema maleolar
              </label>
            </Grid>

            <Grid item xs={12} md={10}>
              <FormControl>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.edemaMaleolarNormal
                            : null
                        }
                        onChange={handleChangeEdemaMaleolarNormal}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Normal"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.edemaMaleolarDeficitLigero
                            : null
                        }
                        onChange={handleChangeEdemaMaleolarDeficitLigero}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Déficit Ligero"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.edemaMaleolarDeficitModerado
                            : null
                        }
                        onChange={handleChangeEdemaMaleolarDeficitModerado}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Déficit Moderado"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.edemaMaleolarDeficitSevero
                            : null
                        }
                        onChange={handleChangeEdemaMaleolarDeficitSevero}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Déficit Severo"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <label
                style={{
                  fontWeight: "bold",
                  marginLeft: "1.3vw",
                  marginTop: "1vw",
                }}
              >
                Edema sacro
              </label>
            </Grid>

            <Grid item xs={12} md={10}>
              <FormControl>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.edemaSacroNormal
                            : null
                        }
                        onChange={handleChangeEdemaSacroNormal}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Normal"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.edemaSacroDeficitLigero
                            : null
                        }
                        onChange={handleChangeEdemaSacroDeficitLigero}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Déficit Ligero"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.edemaSacroDeficitModerado
                            : null
                        }
                        onChange={handleChangeEdemaSacroDeficitModerado}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Déficit Moderado"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.edemaSacroDeficitSevero
                            : null
                        }
                        onChange={handleChangeEdemaSacroDeficitSevero}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Déficit Severo"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <label
                style={{
                  fontWeight: "bold",
                  marginLeft: "1.3vw",
                  marginTop: "1vw",
                }}
              >
                Ascitis
              </label>
            </Grid>

            <Grid item xs={12} md={10}>
              <FormControl>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.ascitisNormal
                            : null
                        }
                        onChange={handleChangeAscitisNormal}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Normal"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.ascitisDeficitLigero
                            : null
                        }
                        onChange={handleChangeAscitisDeficitLigero}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Déficit Ligero"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.ascitisDeficitModerado
                            : null
                        }
                        onChange={handleChangeAscitisDeficitModerado}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Déficit Moderado"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          evaluacionNutricional
                            ? evaluacionNutricional.ascitisDeficitSevero
                            : null
                        }
                        onChange={handleChangeAscitisDeficitSevero}
                        inputProps={{ "aria-label": "controlled" }}
                        value={true}
                        style={{
                          marginLeft: "3vw",
                          marginRight: "1vw",
                          marginTop: "0.5vw",
                        }}
                      />
                    }
                    label="Déficit Severo"
                    labelPlacement="end"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>

                  */}


            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Diagnóstico nutricional
              </Typography>
              <p>
                No se establece un método numérico para la evaluación de este
                test. Se valora en A, Bo C seún la predominancia de síntomas,
                con especial atención a las siguientes variables: pérdida de
                peso, cambios en la injesta habitual, pérdida de tejido
                subcutáneo y pérdida de masa muscular.
              </p>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "0vw" }}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    evaluacionNutricional
                      ? evaluacionNutricional.diagnosticoNutricional
                      : null
                  }
                  onChange={handleChangeDiagnosticoNutricional}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  ></FormLabel>

                  <FormControlLabel
                    value="A"
                    control={<Radio />}
                    label="A - Bien nutrido"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="B"
                    control={<Radio />}
                    label="B - Sospecha de malnutrición o malnutrición moderada"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <label
                    style={{
                      fontSize: "small",
                      marginLeft: "1.7vw",
                      marginTop: "-0.5vw",
                    }}
                  >
                    5-10% de pérdida de peso en las últimas semanas antes del
                    ingreso. Disminución de la injesta en las últimas semanas.
                    Pérdida de tejido subcutáneo.
                  </label>

                  <FormControlLabel
                    value="C"
                    control={<Radio />}
                    label="C - Malnutrición severea"
                    disabled={
                      evaluacionNutricional &&
                      evaluacionNutricional.status &&
                      statusAcceso3 !== 200
                    }
                  />
                  <label
                    style={{
                      fontSize: "small",
                      marginLeft: "1.7vw",
                      marginTop: "-0.5vw",
                    }}
                  >
                    Pérdida &gt; 10% del peso. Severa pérdida de masa muscular y
                    tejido subcutáneo. Edema.
                  </label>
                </RadioGroup>
              </FormControl>
            </Grid>

            {/*      
            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Observaciones
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextareaAutosize
                value={
                  evaluacionNutricional
                    ? evaluacionNutricional.observaciones
                    : null
                }
                id="observaciones"
                aria-label="minimum height"
                minRows={2}
                maxRows={6}
                fullWidth
                placeholder="Observaciones"
                style={{ width: "100%" }}
                onChange={(e) =>
                  guardarEvaluacionNutricional({
                    ...evaluacionNutricional,
                    observaciones: e.target.value,
                  })
                }
                disabled={
                  (evaluacionNutricional &&
                    evaluacionNutricional.status &&
                    statusAcceso3 !== 200) ||
                  (evaluacionNutricional &&
                    !evaluacionNutricional.status &&
                    statusAcceso5 !== 200)
                }
              />
            </Grid>
              */}

            <Grid item xs={12} md={2} style={{ marginTop: "0vw" }} />
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
