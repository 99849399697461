import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { InputTextarea } from "primereact/inputtextarea";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import "./util/NumeroaLetras";
import { NumeroLetras } from "./util/NumeroaLetras";
import { TabView, TabPanel } from "primereact/tabview";

export default function Recetas(props) {
  let navigate = useNavigate();
  const toast = useRef(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [globalFilter, setGlobalFilter] = useState(null);

  const [visible, setVisible] = useState(false);
  const [visibleHorario, setVisibleHorario] = useState(false);
  const [visibleReceta, setVisibleReceta] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowHorario, setSelectedRowHorario] = useState(null);
  const [selectedRowReceta, setSelectedRowReceta] = useState(null);
  const [selectedRowGuardada, setSelectedRowGuardada] = useState(null);
  const [programada, guardarProgramada] = useState(null);
  const [dialogPacientes, setDialogPacientes] = useState(false);
  const [dialogProgramada, setDialogProgramada] = useState(false);
  const [dialogGuardadas, setDialogGuardadas] = useState(false);
  const [dialogReceta, setDialogReceta] = useState(false);
  const [horario, guardarHorario] = useState(null);
  const [time, setTime] = useState(null);
  const [receta, guardarReceta] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const acPresentacion = useRef();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    barthel,
    fragilidad,
    lawtonBrody,
    yesavage,
    tinetti,
    norton,
    diagnosticos,
    programadas,
    horarios,
    medicaciones,
    geriatricoElegido,
    medicacionesPacientes,
    paciente,
    frasesVgi,
    frases,
    frasesVGI,
    evolucion,
    planes,
    vgi,
    obrasSociales,
    obrasSocialesMedicas,
    ingresosEgresos,
    selectedRowPaciente,
    pacientes,
    unidades,
    vias,
    diagnosticosPacientes,
    statusAcceso3,
    localidades,
    guardarProgramadaPeriodica,
    obtenerAcceso,
    formatos,
    guardadas,
    agregarFrasesVGI,
    agregarFrases,
    obtenerFrases,
    obtenerFrasesVGI,
    obtenerDiagnosticosPacientes,
    obtenerMedicacionesPacientes,
    obtenerMedicacionesPacientesIndividual,
    obtenerDiagnosticosPacientesIndividual,
    setSelectedRowPaciente,
    obtenerPacientes,
    setGeriatricoElegido,
    obtenerProgramadas,
    obtenerIngresosEgresos,
    obtenerObrasSocialesPacientes,
    obtenerObrasSocialesMedicasPacientes,
    obtenerInformesPreIngreso,
    guardarEvolucion,
    guardarVgi,
    agregarProgramada,
    actualizarProgramada,
    obtenerHorarios,
    actualizarHorario,
    agregarHorario,
    eliminarHorario,
    guardarHorarios,
    obtenerUnidades,
    obtenerPlanes,
    obtenerVias,
    obtenerFormatos,
    agregarReceta,
    obtenerRecetasPaciente,
    borrarReceta,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Recetas",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    obtenerPacientes({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    });
    //obtenerDiagnosticosPacientes({ todos: false });
    obtenerMedicacionesPacientes({ todos: false });
    obtenerUnidades();
    obtenerVias();
    //obtenerFrases();
    //obtenerFrasesVGI();
    //obtenerObrasSocialesPacientes({ todos: false });
    obtenerObrasSocialesMedicasPacientes({ todos: false });
    obtenerPlanes();
  }, []);

  const eliminarReceta = (rowData) => {
    borrarReceta({ id: rowData.id });
    setTimeout(() => {
      obtenerRecetasPaciente({ pacienteId: selectedRowPaciente.id });
    }, 1000);
  };

  const imprimirReceta = (rowData) => {
    let arrayReceta = [];

    if (rowData.medicamento1) {
      arrayReceta.push({
        label: rowData.medicamento1,
        detalle: rowData.detalle1,
        marca: rowData.marca1,
        cantidadReceta: rowData.cantidad1,
        observacion: rowData.observacion1,
      });
    }
    if (rowData.medicamento2) {
      arrayReceta.push({
        label: rowData.medicamento2,
        detalle: rowData.detalle2,
        marca: rowData.marca2,
        cantidadReceta: rowData.cantidad2,
        observacion: rowData.observacion2,
      });
    }
    if (rowData.medicamento3) {
      arrayReceta.push({
        label: rowData.medicamento3,
        detalle: rowData.detalle3,
        marca: rowData.marca3,
        cantidadReceta: rowData.cantidad3,
        observacion: rowData.observacion3,
      });
    }

    setSelectedRow(arrayReceta)


    setTimeout(() => {
      let elem = document.getElementById("imprimir");
      elem.click();
    }, 1000);

  };

  const addClick = (e) => {
    setDialogPacientes(true);
    setSelectedRowPaciente(null);
  };

  const addClickNueva = (e) => {
    setDialogProgramada(true);
    setDialogReceta(true);
  };

  const addClickGuardada = (e) => {
    obtenerRecetasPaciente({ pacienteId: selectedRowPaciente.id });
    setTimeout(() => {
      setDialogGuardadas(true);
    }, 1000);
  };

  const accept = () => {
    eliminarHorario({ id: selectedRowHorario.id });
    setTimeout(() => {
      obtenerHorarios({ id: selectedRow.id });
    }, 2000);
  };

  const exportColumns = [
    { title: "Medicamento", dataKey: "nombre" },
    { title: "Inicio", dataKey: "inicio" },
    { title: "Final", dataKey: "final" },
    { title: "Estado", dataKey: "estado" },
    { title: "Horario", dataKey: "hora" },
    { title: "Dósis", dataKey: "dosis" },
    { title: "Concentración", dataKey: "concentracion" },
    { title: "Unidad", dataKey: "unidad" },
    { title: "Vía", dataKey: "via" },
    { title: "Instrucciones", dataKey: "instrucciones" },
  ];

  const acceptGuardar = () => {
    for (var i = 0; i < selectedRow.length; i++) {
      if (
        !selectedRow[i] ||
        selectedRow[i].medicacionId === undefined ||
        selectedRow[i].medicacionId === null ||
        selectedRow[i].medicacionId === 0 ||
        selectedRow[i].medicacionId === ""
      ) {
        if (
          !selectedRow[i] ||
          selectedRow[i].presentacionManual === undefined ||
          selectedRow[i].presentacionManual === null ||
          selectedRow[i].presentacionManual === 0 ||
          selectedRow[i].presentacionManual === ""
        ) {
          mensajeAlerta(
            "Recetas",
            "La presentación de " + selectedRow[i].label + " es necesaria !.",
            "error"
          );
          return;
        }
      }

      if (
        !selectedRow[i] ||
        selectedRow[i].cantidadReceta === undefined ||
        selectedRow[i].cantidadReceta === null ||
        selectedRow[i].cantidadReceta === "" ||
        selectedRow[i].cantidadReceta === 0
      ) {
        mensajeAlerta(
          "Recetas",
          "La cantidad de " + selectedRow[i].label + " es necesaria !.",
          "error"
        );
        return;
      }
    }

    agregarReceta({ receta: selectedRow, pacienteId: selectedRowPaciente.id });
  };

  const acceptImprimir = () => {

    const doc = new jsPDF();

    let hoy = new Date();

    let fechaDia = hoy.getDate();
    let fechaMes = hoy.getMonth() + 1;
    let fechaAnio = hoy.getFullYear();
    let nombreMes = "";

    if (fechaMes === 1) {
      nombreMes = "Enero";
    }
    if (fechaMes === 2) {
      nombreMes = "Febrero";
    }
    if (fechaMes === 3) {
      nombreMes = "Marzo";
    }
    if (fechaMes === 4) {
      nombreMes = "Abril";
    }
    if (fechaMes === 5) {
      nombreMes = "Mayo";
    }
    if (fechaMes === 6) {
      nombreMes = "Junio";
    }
    if (fechaMes === 7) {
      nombreMes = "Julio";
    }
    if (fechaMes === 8) {
      nombreMes = "Agosto";
    }
    if (fechaMes === 9) {
      nombreMes = "Septiembre";
    }
    if (fechaMes === 10) {
      nombreMes = "Octubre";
    }
    if (fechaMes === 11) {
      nombreMes = "Noviembre";
    }
    if (fechaMes === 12) {
      nombreMes = "Diciembre";
    }

    doc.setFontSize(12);

    doc.setFont("Time-BoldItalic", "bold");
    doc.text(geriatricoElegido.nombre, 15, 8);

    doc.rect(10, 12, 190, 25);

    doc.setFontSize(14);

    doc.setFont("Time-BoldItalic", "bold");
    doc.text("RECETA", 85, 20);

    doc.setFont("Times-Roman", "normal");

    doc.setFontSize(10);

    let row = 25;

    doc.text(
      "Paciente: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.nombre !== undefined &&
        selectedRowPaciente.nombre !== null
          ? selectedRowPaciente.nombre
          : ""),
      12,
      row,
      "left",
      "middle"
    );

    doc.text(
      "D.N.I.: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.numeroDocumento !== undefined &&
        selectedRowPaciente.numeroDocumento !== null
          ? selectedRowPaciente.numeroDocumento
          : ""),

      130,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Obra Social Médica: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.obraSocialId !== undefined
          ? obrasSocialesMedicas[
              obrasSocialesMedicas.findIndex(
                (item) => item.id === selectedRowPaciente.obraSocialId
              )
            ].label
          : ""),
      12,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Nº Obra Social: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.numeroObraSocial !== undefined &&
        selectedRowPaciente.numeroObraSocial !== null
          ? selectedRowPaciente.numeroObraSocial
          : ""),

      130,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    //console.log(planes.findIndex((item) => item.id === selectedRowPaciente.planId))

    doc.text(
      "Plan: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.planId !== undefined &&
        selectedRowPaciente.planId !== null
          ? planes[
              planes.findIndex((item) => item.id === selectedRowPaciente.planId)
            ].nombre
          : ""),
      12,
      row,
      "left",
      "middle"
    );

    doc.text("Fecha: " + formatDate(hoy), 130, row, "left", "middle");

    let band = false;

    for (var i = 0; i < selectedRow.length; i++) {
      if (
        !selectedRow[i] ||
        selectedRow[i].detalle === undefined ||
        selectedRow[i].detalle === null ||
        selectedRow[i].detalle === 0 ||
        selectedRow[i].detalle === ""
      ) {
        if (
          !selectedRow[i] ||
          selectedRow[i].presentacionManual === undefined ||
          selectedRow[i].presentacionManual === null ||
          selectedRow[i].presentacionManual === 0 ||
          selectedRow[i].presentacionManual === ""
        ) {
          mensajeAlerta("Recetas", "La presentación es necesaria !.", "error");
        }
      }

      if (
        !selectedRow[i] ||
        selectedRow[i].cantidadReceta === undefined ||
        selectedRow[i].cantidadReceta === null ||
        selectedRow[i].cantidadReceta === "" ||
        selectedRow[i].cantidadReceta === 0
      ) {
        mensajeAlerta("Recetas", "La cantidad es necesaria !.", "error");
        return;
      }

      band = true;

      if (band) {
        row = row + 15;

        doc.text(
          selectedRow[i].detalle
            ? selectedRow[i].label + " " + selectedRow[i].detalle
            : selectedRow[i].label + " " + selectedRow[i].presentacionManual,
          12,
          row,
          "left",
          "middle"
        );

        if (selectedRow[i].marca && selectedRow[i].marca !== "") {
          row = row + 7;

          doc.text(
            selectedRow[i].detalle
              ? selectedRow[i].marca + " " + selectedRow[i].detalle
              : selectedRow[i].marca + " " + selectedRow[i].presentacionManual,
            12,
            row,
            "left",
            "middle"
          );
        }

        row = row + 7;

        doc.text(
          String(selectedRow[i].cantidadReceta) +
            " = " +
            NumeroLetras(selectedRow[i].cantidadReceta, false),
          15,
          row,
          "left",
          "middle"
        );

        doc.text(
          selectedRow[i].observacion !== undefined &&
            selectedRow[i].observacion &&
            selectedRow[i].observacion !== ""
            ? selectedRow[i].observacion
            : "",
          40,
          row,
          "left",
          "middle"
        );

        row = row + 7;
      }
    }

    row = 270;

    doc.text(
      "_______________________________________",
      70,
      row,
      "left",
      "middle"
    );

    window.open(doc.output("bloburl"));
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHideGuardadas = (e) => {
    setDialogGuardadas(false);
  };

  const onHideHorario = (e) => {
    setVisibleHorario(false);
  };

  const onHidePacientes = (e) => {
    setSelectedRowPaciente(null);
    setDialogPacientes(false);
  };

  const aceptarProgramada = (e) => {
    e.preventDefault();

    if (
      !selectedRowReceta ||
      selectedRowReceta.medicacionId === undefined ||
      selectedRowReceta.medicacionId === null ||
      selectedRowReceta.medicacionId === 0 ||
      selectedRowReceta.medicacionId === ""
    ) {
      if (
        !selectedRowReceta ||
        selectedRowReceta.presentacionManual === undefined ||
        selectedRowReceta.presentacionManual === null ||
        selectedRowReceta.presentacionManual === 0 ||
        selectedRowReceta.presentacionManual === ""
      ) {
        mensajeAlerta("Recetas", "La presentación es necesaria !.", "error");
        return;
      }
    }

    if (
      !selectedRowReceta ||
      selectedRowReceta.cantidadReceta === undefined ||
      selectedRowReceta.cantidadReceta === null ||
      selectedRowReceta.cantidadReceta === "" ||
      selectedRowReceta.cantidadReceta === 0
    ) {
      mensajeAlerta("Recetas", "La cantidad es necesaria !.", "error");
      return;
    }

    let index = selectedRow.findIndex(
      (elem) => elem.label === selectedRowReceta.label
    );

    selectedRow[index].medicacionId = selectedRowReceta.medicacionId;
    selectedRow[index].detalle = selectedRowReceta.detalle;
    selectedRow[index].presentacionManual =
      selectedRowReceta.presentacionManual;
    selectedRow[index].marca = selectedRowReceta.marca;
    selectedRow[index].cantidadReceta = selectedRowReceta.cantidadReceta;
    selectedRow[index].observacion = selectedRowReceta.observacion;

    onHide();
  };

  const aceptarClickPacientes = (e) => {
    if (selectedRowPaciente) {
      //verificarHistoriaClinica({ selectedRowPaciente });
      //obtenerInformeFragilidad({ pacienteId: selectedRowPaciente.id });
      //obtenerInformesHistoriaClinica({ pacienteId: selectedRowPaciente.id });
      /*
      obtenerDiagnosticosPacientesIndividual({
        pacienteId: selectedRowPaciente.id,
      });
      obtenerProgramadas({
        pacienteId: selectedRowPaciente.id,
      });
      */
      obtenerMedicacionesPacientesIndividual({
        pacienteId: selectedRowPaciente.id,
        tipo: "M",
      });

      /*
      obtenerIngresosEgresos({ pacienteId: selectedRowPaciente.id });
      obtenerInformesPreIngreso({ pacienteId: selectedRowPaciente.id });
      */
      setDialogPacientes(false);
      //setDialogProgramada(true);
      //setDialogReceta(true);
      setSelectedRow(null);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  const onHideProgramadas = (e) => {
    setDialogProgramada(false);
    //setSelectedRowPaciente(null)
    //setDialogCaidas(false);
  };

  const editClickProgramada = (e) => {
    if (selectedRowReceta) {
      setTimeout(() => {
        setVisible(true);
      }, 1000);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un medicamento.",
        life: 3000,
      });
    }
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  function getEdad(dateString) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("-");
  }

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Tag value="Control de Medicación"></Tag>
      </div>
    </Fragment>
  );

  const renderFooterPacientes = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickPacientes}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHidePacientes}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeaderGuardadas = () => {
    return (
      <Fragment>
        <div>
          {selectedRowPaciente ? (
            <Tag value={"Recetas de " + selectedRowPaciente.nombre}></Tag>
          ) : null}
        </div>
      </Fragment>
    );
  };

  const renderHeaderProgramadas = () => {
    return (
      <Fragment>
        <div>
          {selectedRowPaciente ? (
            <Tag value={"Medicación de " + selectedRowPaciente.nombre}></Tag>
          ) : null}
        </div>
      </Fragment>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarProgramada}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const headerProgramadas = (
    <Fragment>
      <Button
        icon="pi pi-pencil"
        tooltip="Editar"
        className="p-button-rounded p-button-success"
        style={{ marginLeft: "1em" }}
        onClick={editClickProgramada}
      />
      <Button
        icon="pi pi-print"
        tooltip="Imprimir"
        className="p-button-rounded p-button-help"
        style={{ marginLeft: "1em" }}
        onClick={acceptImprimir}
      />
      <Button
        icon="pi pi-save"
        tooltip="Guardar"
        className="p-button-rounded p-button-danger"
        style={{ marginLeft: "1em" }}
        onClick={acceptGuardar}
      />
    </Fragment>
  );

  const headerRecetas = (
    <div>
      <Tag value="Receta"></Tag>
    </div>
  );

  const headerPacientes = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            fullWidth
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Residentes"></Tag>
      </div>
    </Fragment>
  );

  /* INICIO */

  /*FIN*/

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          icon="pi pi-print"
          className="p-button-success p-button-text"
          onClick={(e) => {
            imprimirReceta(rowData);
          }}
          tooltip="Imprimir"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />

        <Button
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          icon="pi pi-trash"
          className="p-button-danger p-button-text"
          onClick={(e) => {
            eliminarReceta(rowData);
          }}
          tooltip="Borrar"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
      </Fragment>
    );
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const presentacionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Presentación</span>
        {rowData.medicacionId ? rowData.detalle : rowData.presentacionManual}
      </Fragment>
    );
  };

  const cantidadTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Cantidad</span>
        {Number(rowData.cantidadReceta > 0) ? rowData.cantidadReceta : ""}
      </Fragment>
    );
  };

  const inicioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDate2(rowData.inicio)}
      </Fragment>
    );
  };

  const finalTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {!rowData.final ? "" : formatDate2(rowData.final)}
      </Fragment>
    );
  };

  const fecha2Template = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDate(rowData.fecha)}
      </Fragment>
    );
  };

  const fechaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDate2(rowData.fecha)}
      </Fragment>
    );
  };

  const medicamento1Template = (rowData) => {
    let m1 = rowData.medicamento1 ? rowData.medicamento1 : "";
    let m2 = rowData.detalle1 ? "-" + rowData.detalle1 : "";
    let m3 = rowData.marca1 ? "-" + rowData.marca1 : "";
    let m4 = rowData.cantidad1 ? "-" + rowData.cantidad1 : "";
    let m5 = rowData.observacion1 ? "-" + rowData.observacion1 : "";

    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {m1 + m2 + m3 + m4 + m5}
      </Fragment>
    );
  };

  const medicamento2Template = (rowData) => {
    let m1 = rowData.medicamento2 ? rowData.medicamento2 : "";
    let m2 = rowData.detalle2 ? "-" + rowData.detalle2 : "";
    let m3 = rowData.marca2 ? "-" + rowData.marca2 : "";
    let m4 = rowData.cantidad2 ? "-" + rowData.cantidad2 : "";
    let m5 = rowData.observacion2 ? "-" + rowData.observacion2 : "";

    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {m1 + m2 + m3 + m4 + m5}
      </Fragment>
    );
  };

  const medicamento3Template = (rowData) => {
    let m1 = rowData.medicamento3 ? rowData.medicamento3 : "";
    let m2 = rowData.detalle3 ? "-" + rowData.detalle3 : "";
    let m3 = rowData.marca3 ? "-" + rowData.marca3 : "";
    let m4 = rowData.cantidad3 ? "-" + rowData.cantidad3 : "";
    let m5 = rowData.observacion3 ? "-" + rowData.observacion3 : "";

    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {m1 + m2 + m3 + m4 + m5}
      </Fragment>
    );
  };

  function fechaReves(fecha) {
    var initial = fecha.split(/\//);
    return [initial[2], initial[1], initial[0]].join("-"); //=> 'mm/dd/yyyy'
  }

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const documentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const ingresoEgresoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Ingreso/Egreso</span>
        {formatDate2(rowData.fechaIngresoEgreso)}
      </Fragment>
    );
  };

  const motivoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Motivo</span>
        {rowData.motivoIngresoEgreso}
      </Fragment>
    );
  };

  const activoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Activo</span>
        <Checkbox checked={rowData.activo}></Checkbox>
      </Fragment>
    );
  };

  function formatDate2(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  //console.log(recetas);
  //console.log(selectedRowPaciente);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {medicacionesPacientes ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  align: "center",
                  margin: "1em",
                  marginTop:
                    isMobile && !isPortrait
                      ? "22vw"
                      : isMobile && isPortrait
                      ? "55vw"
                      : "3vw",
                }}
              >
                <Button
                  icon="pi pi-search"
                  tooltip="Pacientes"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                {selectedRowPaciente ? (
                  <Button
                    icon="pi pi-plus"
                    tooltip="Nueva Receta"
                    className="p-button-rounded p-button-secondary"
                    style={{ marginLeft: "1em" }}
                    onClick={addClickNueva}
                  />
                ) : null}
                {selectedRowPaciente ? (
                  <Button
                    icon="pi pi-save"
                    tooltip="Recetas Guardadas"
                    className="p-button-rounded p-button-danger"
                    style={{ marginLeft: "1em" }}
                    onClick={addClickGuardada}
                  />
                ) : null}
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
              </Grid>
              {selectedRowPaciente ? (
                <Fragment>
                  <Dialog
                    header={renderHeaderProgramadas()}
                    visible={dialogProgramada}
                    maximized={true}
                    //maximizable={true}
                    closable={true}
                    onHide={onHideProgramadas}
                    //footer={renderFooterEvoluciones()}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "95vw", paddingBottom: "0" }}
                    position="top"
                  >
                    <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                      <Grid item xs={12} md={12}>
                        {medicacionesPacientes ? (
                          <div className="datatable-responsive-demo">
                            <div className="card">
                              <Tooltip
                                target=".export-buttons>button"
                                position="bottom"
                              />
                              <DataTable
                                value={medicacionesPacientes}
                                selection={selectedRow}
                                onSelectionChange={(e) => {
                                  if (e.value && e.value.length >= 4) {
                                    mensajeAlerta(
                                      "Recetas",
                                      "Puede seleccionar hasta 3 medicamentos por receta !.",
                                      "error"
                                    );
                                    setSelectedRow(null);
                                    return;
                                  }
                                  setSelectedRow(e.value);
                                  setSelectedRowReceta(null);
                                }}
                                selectionMode={rowClick ? null : "checkbox"}
                                //selectionMode="single"
                                dataKey="id"
                                //header={headerProgramadas}
                                className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                                style={{
                                  fontSize: "small",
                                  marginTop: "0vw",
                                }}
                                //globalFilter={globalFilter}
                                emptyMessage="No hay datos."
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                responsiveLayout="scroll"

                                //paginatorLeft={paginatorLeft}
                                //paginatorRight={paginatorRight}
                              >
                                <Column
                                  selectionMode="multiple"
                                  headerStyle={{ width: "3rem" }}
                                ></Column>

                                <Column
                                  field="id"
                                  header="ID"
                                  body={idTemplate}
                                  style={{ display: "none" }}
                                ></Column>
                                <Column
                                  field="label"
                                  header="Medicamento"
                                  //body={documentoTemplate}
                                ></Column>
                                <Column
                                  field="dosis"
                                  header="Dosis Mg."
                                  //body={documentoTemplate}
                                ></Column>
                                <Column
                                  field="cantidad"
                                  header="Cantidad"
                                  //body={documentoTemplate}
                                ></Column>
                                <Column
                                  field="inicio"
                                  header="Inicio"
                                  //body={inicioTemplate}
                                ></Column>
                                <Column
                                  field="final"
                                  header="Final"
                                  //body={finalTemplate}
                                ></Column>
                                <Column
                                  field="activo"
                                  header="Activo"
                                  body={activoTemplate}
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        ) : null}
                      </Grid>

                      <Grid item xs={12} md={12}>
                        {selectedRow ? (
                          <div className="datatable-responsive-demo">
                            <div className="card">
                              <Tooltip
                                target=".export-buttons>button"
                                position="bottom"
                              />
                              <DataTable
                                value={selectedRow}
                                selection={selectedRowReceta}
                                onSelectionChange={(e) => {
                                  setSelectedRowReceta(e.value);
                                  obtenerFormatos({ id: e.value.id });
                                  //guardarReceta(null)
                                }}
                                selectionMode="single"
                                dataKey="id"
                                header={headerProgramadas}
                                className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                                style={{
                                  fontSize: "small",
                                  marginTop: "0vw",
                                }}
                                //globalFilter={globalFilter}
                                emptyMessage="No hay datos."
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                responsiveLayout="scroll"

                                //paginatorLeft={paginatorLeft}
                                //paginatorRight={paginatorRight}
                              >
                                <Column
                                  field="id"
                                  header="ID"
                                  body={idTemplate}
                                  style={{ display: "none" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="label"
                                  header="Medicamento"
                                  //body={documentoTemplate}
                                ></Column>
                                <Column
                                  field="presentacion"
                                  header="Presentación"
                                  body={presentacionTemplate}
                                ></Column>
                                <Column
                                  field="marca"
                                  header="Marca"
                                  //body={presentacionTemplate}
                                ></Column>
                                <Column
                                  field="cantidad"
                                  header="Cantidad"
                                  body={cantidadTemplate}
                                ></Column>
                                <Column
                                  field="observacion"
                                  header="Observación"
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Dialog>
                </Fragment>
              ) : null}
              <Dialog
                showHeader={false}
                visible={dialogPacientes}
                closable={false}
                footer={renderFooterPacientes()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <Tooltip
                          target=".export-buttons>button"
                          position="bottom"
                        />
                        <DataTable
                          value={pacientes}
                          selection={selectedRowPaciente}
                          onSelectionChange={(e) =>
                            setSelectedRowPaciente(e.value)
                          }
                          selectionMode="single"
                          dataKey="id"
                          header={headerPacientes}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "small", marginTop: "1vw" }}
                          globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          paginator
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          rows={10}
                          rowsPerPageOptions={[10, 20, 50]}
                          responsiveLayout="scroll"

                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                        >
                          <Column
                            field="id"
                            header="ID"
                            body={idTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="nombre"
                            header="Apellido y Nombre"
                            body={nombreTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="domicilio"
                            header="Domicilio"
                            body={domicilioTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="numeroDocumento"
                            header="Documento"
                            body={documentoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="fechaIngresoEgreso"
                            header="Ingreso/Egreso"
                            body={ingresoEgresoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="motivoIngresoEgreso"
                            header="Motivo"
                            body={motivoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="activo"
                            header="Activo"
                            body={activoTemplate}
                            sortable
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderProgramadas()}
                visible={visible}
                closable={true}
                //maximizable
                onHide={onHide}
                footer={renderFooter()}
                breakpoints={{ "960px": "100vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0vw", marginLeft: "0vw" }}
                >
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={selectedRowReceta ? selectedRowReceta.label : null}
                      required
                      id="medicamento"
                      label="Medicamento"
                      fullWidth
                      disabled
                      variant="standard"
                    />
                  </Grid>

                  <Grid item xs={12} md={6} />
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="formato"
                      ref={acPresentacion}
                      fullWidth
                      options={formatos}
                      defaultValue={
                        formatos &&
                        selectedRowReceta &&
                        selectedRowReceta.medicacionId
                          ? formatos[
                              formatos.findIndex(
                                (elem) =>
                                  elem.id === selectedRowReceta.medicacionId
                              )
                            ]
                          : null
                      }
                      disablePortal
                      getOptionLabel={(option) => option.detalle}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Presentación *"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, values) => {
                        if (values) {
                          setSelectedRowReceta({
                            ...selectedRowReceta,
                            medicacionId: values.id,
                            detalle: values.detalle,
                            presentacionManual: "",
                          });
                        } else {
                          setSelectedRowReceta({
                            ...selectedRowReceta,
                            medicacionId: null,
                            detalle: null,
                          });

                          setTimeout(() => {
                            const ele =
                              acPresentacion.current.getElementsByClassName(
                                "MuiAutocomplete-clearIndicator"
                              )[0];
                            if (ele) ele.click();
                          }, 1000);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={
                        selectedRowReceta
                          ? selectedRowReceta.presentacionManual
                          : null
                      }
                      required
                      id="presentacionManual"
                      label="Presentación"
                      fullWidth
                      disabled={
                        selectedRowReceta && selectedRowReceta.medicacionId
                          ? true
                          : false
                      }
                      variant="standard"
                      onChange={(e) =>
                        setSelectedRowReceta({
                          ...selectedRowReceta,
                          presentacionManual: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      value={receta ? receta.marca : null}
                      id="marca"
                      label="Marca Comercial"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        setSelectedRowReceta({
                          ...selectedRowReceta,
                          marca: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      value={
                        selectedRowReceta
                          ? selectedRowReceta.cantidadReceta
                          : null
                      }
                      id="cantidad"
                      label="Cantidad"
                      fullWidth
                      type="number"
                      variant="standard"
                      onChange={(e) =>
                        setSelectedRowReceta({
                          ...selectedRowReceta,
                          cantidadReceta: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      value={
                        selectedRowReceta ? selectedRowReceta.observacion : null
                      }
                      id="observacion"
                      label="Observacion"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        setSelectedRowReceta({
                          ...selectedRowReceta,
                          observacion: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderGuardadas()}
                visible={dialogGuardadas}
                closable={true}
                //maximizable
                onHide={onHideGuardadas}
                //footer={renderFooter()}
                breakpoints={{ "960px": "100vw" }}
                style={{ width: "95vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0vw", marginLeft: "0vw" }}
                >
                  <Grid item xs={12} md={12}>
                    {guardadas ? (
                      <div className="datatable-responsive-demo">
                        <div className="card">
                          <Tooltip
                            target=".export-buttons>button"
                            position="bottom"
                          />
                          <DataTable
                            value={guardadas}
                            selection={selectedRowGuardada}
                            onSelectionChange={(e) => {
                              setSelectedRowGuardada(e.value);
                            }}
                            selectionMode="single"
                            dataKey="id"
                            //header={headerProgramadas}
                            className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                            style={{
                              fontSize: "small",
                              marginTop: "0vw",
                            }}
                            //globalFilter={globalFilter}
                            emptyMessage="No hay datos."
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            responsiveLayout="scroll"

                            //paginatorLeft={paginatorLeft}
                            //paginatorRight={paginatorRight}
                          >
                            <Column
                              field="id"
                              header="ID"
                              body={idTemplate}
                              style={{ display: "none" }}
                              sortable
                            ></Column>
                            <Column
                              field="fecha"
                              header="Fecha"
                              body={fechaTemplate}
                            ></Column>
                            <Column
                              field="medicamento1"
                              header="Medicamento"
                              body={medicamento1Template}
                            ></Column>
                            <Column
                              field="medicamento1"
                              header="Medicamento"
                              body={medicamento2Template}
                            ></Column>
                            <Column
                              field="medicamento1"
                              header="Medicamento"
                              body={medicamento3Template}
                            ></Column>
                            <Column
                              className="colBotones"
                              body={actionBodyTemplate}
                              style={{ width: "10%" }}
                            ></Column>
                          </DataTable>
                        </div>
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </Dialog>
              <button
                id="imprimir"
                type="button"
                onClick={(e) => {
                  acceptImprimir();
                }}
                style={{ display: "none" }}
              >
                Imprimir Receta
              </button>
            </Fragment>
          ) : (
            <Spinner />
          )}
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}
