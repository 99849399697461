import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";

export default function Antecedentes(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    antecedente,
    statusAcceso3,
    localidades,
    guardarAntecedente,
    obtenerAcceso,
  } = serviciosContext;

  const handleChangePregunta1 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta1: event.target.checked,
    });
  };

  const handleChangePregunta2 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta2: event.target.checked,
    });
  };

  const handleChangePregunta3 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta3: event.target.checked,
    });
  };

  const handleChangePregunta4 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta4: event.target.checked,
    });
  };

  const handleChangePregunta5 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta5: event.target.checked,
    });
  };

  const handleChangePregunta6 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta6: event.target.checked,
    });
  };

  const handleChangePregunta7 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta7: event.target.checked,
    });
  };

  const handleChangePregunta8 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta8: event.target.checked,
    });
  };

  const handleChangePregunta9 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta9: event.target.checked,
    });
  };

  const handleChangePregunta10 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta10: event.target.checked,
    });
  };

  const handleChangePregunta11 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta11: event.target.checked,
    });
  };

  const handleChangePregunta12 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta12: event.target.checked,
    });
  };

  const handleChangePregunta13 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta13: event.target.checked,
    });
  };

  const handleChangePregunta14 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta14: event.target.checked,
    });
  };

  const handleChangePregunta15 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta15: event.target.checked,
    });
  };

  const handleChangePregunta16 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta16: event.target.checked,
    });
  };

  const handleChangePregunta17 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta17: event.target.checked,
    });
  };

  const handleChangePregunta18 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta18: event.target.checked,
    });
  };

  const handleChangePregunta19 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta19: event.target.checked,
    });
  };

  const handleChangePregunta20 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta20: event.target.checked,
    });
  };

  const handleChangePregunta21 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta21: event.target.checked,
    });
  };

  const handleChangePregunta22 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta22: event.target.checked,
    });
  };

  const handleChangePregunta23 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta23: event.target.checked,
    });
  };

  const handleChangePregunta24 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta24: event.target.checked,
    });
  };

  const handleChangePregunta25 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta25: event.target.checked,
    });
  };

  const handleChangePregunta26 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta26: event.target.checked,
    });
  };

  const handleChangePregunta27 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta27: event.target.checked,
    });
  };

  const handleChangePregunta28 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta28: event.target.checked,
    });
  };

  const handleChangePregunta29 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta29: event.target.checked,
    });
  };

  const handleChangePregunta30 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta30: event.target.checked,
    });
  };

  const handleChangePregunta31 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta31: event.target.checked,
    });
  };

  const handleChangePregunta32 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta32: event.target.checked,
    });
  };

  const handleChangePregunta33 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta33: event.target.checked,
    });
  };

  const handleChangePregunta34 = (event) => {
    guardarAntecedente({
      ...antecedente,
      pregunta34: event.target.checked,
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  //console.log(antecedente);

  return (
    <Fragment>
      {antecedente ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "1vw", marginLeft: "0vw" }}
          >
            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Enfermedades padecidas:
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta1 : null}
                      onChange={handleChangePregunta1}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Artrosis."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta2 : null}
                      onChange={handleChangePregunta2}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Osteoporosis."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta3 : null}
                      onChange={handleChangePregunta3}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Diabetes tipo I / II."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta4 : null}
                      onChange={handleChangePregunta4}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Gota."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta5 : null}
                      onChange={handleChangePregunta5}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Arterioesclerosis."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta6 : null}
                      onChange={handleChangePregunta6}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="HTA."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta7 : null}
                      onChange={handleChangePregunta7}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Insuficiencia cardiaca."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta8 : null}
                      onChange={handleChangePregunta8}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Fiebre reumática."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta9 : null}
                      onChange={handleChangePregunta9}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="EPOC."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta10 : null}
                      onChange={handleChangePregunta10}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Infecciones respiratorias."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta11 : null}
                      onChange={handleChangePregunta11}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Cardiopatía."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta12 : null}
                      onChange={handleChangePregunta12}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Neoplasias."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta13 : null}
                      onChange={handleChangePregunta13}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Anemia."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta14 : null}
                      onChange={handleChangePregunta14}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Hiperlipidemias."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta15 : null}
                      onChange={handleChangePregunta15}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Insuficiencia renal."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta16 : null}
                      onChange={handleChangePregunta16}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Alteraciones próstata."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta17 : null}
                      onChange={handleChangePregunta17}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Ulcera péptica."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta18 : null}
                      onChange={handleChangePregunta18}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="ACV."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta19 : null}
                      onChange={handleChangePregunta19}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Parkinson."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta20 : null}
                      onChange={handleChangePregunta20}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Depresión."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta21 : null}
                      onChange={handleChangePregunta21}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Epilepsia."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            {/*}     
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta22 : null}
                      onChange={handleChangePregunta22}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Cardiopatía."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>
                */}

            <Grid item xs={12} md={12} style={{ marginTop: "-1.5vw" }}>
              <TextField
                id="otrosEnfermedades"
                label="Otras"
                variant="standard"
                fullWidth
                value={antecedente ? antecedente.otrosEnfermedades : null}
                onChange={(e) => {
                  guardarAntecedente({
                    ...antecedente,
                    otrosEnfermedades: e.target.value,
                  });
                }}
                disabled={
                  antecedente && antecedente.status && statusAcceso3 !== 200
                }
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Antecedentes ginecológicos y obstétricos:
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta24 : null}
                      onChange={handleChangePregunta24}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Menarca."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta25 : null}
                      onChange={handleChangePregunta25}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Menopausia."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Otros antecedente:
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta26 : null}
                      onChange={handleChangePregunta26}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Hospitalizaciones previas."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta27 : null}
                      onChange={handleChangePregunta27}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Intervenciones quirúrgicas."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta28 : null}
                      onChange={handleChangePregunta28}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Traumatismos."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta29 : null}
                      onChange={handleChangePregunta29}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Caídas previas."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta30 : null}
                      onChange={handleChangePregunta30}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Transfusiones."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta31 : null}
                      onChange={handleChangePregunta31}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Hábitos tóxicos."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta32 : null}
                      onChange={handleChangePregunta32}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Alergias medicamentosas."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta33 : null}
                      onChange={handleChangePregunta33}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Vacunaciones."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antecedente ? antecedente.pregunta34 : null}
                      onChange={handleChangePregunta34}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Covid."
                  labelPlacement="end"
                  disabled={
                    antecedente && antecedente.status && statusAcceso3 !== 200
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1.5vw" }}>
              <TextField
                id="otrosAntecedentes"
                label="Otros"
                variant="standard"
                fullWidth
                value={antecedente ? antecedente.otrosAntecedentes : null}
                onChange={(e) => {
                  guardarAntecedente({
                    ...antecedente,
                    otrosAntecedentes: e.target.value,
                  });
                }}
                disabled={
                  antecedente && antecedente.status && statusAcceso3 !== 200
                }
              />
            </Grid>

            {/*   
            <Grid item xs={12} md={12} style={{ marginTop: "-1.5vw" }}>
              <TextField
                id="motivoIngreso"
                label="Motivo de Ingreso"
                variant="standard"
                fullWidth
                value={antecedente ? antecedente.otros : null}
                onChange={(e) => {
                  guardarAntecedente({
                    ...antecedente,
                    motivoIngreso: e.target.value,
                  });
                }}
                disabled={
                  antecedente && antecedente.status && statusAcceso3 !== 200
                }
              />
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-1.5vw" }}>
              <TextField
                id="anamnesis"
                label="Anamnesis"
                variant="standard"
                fullWidth
                value={antecedente ? antecedente.otros : null}
                onChange={(e) => {
                  guardarAntecedente({
                    ...antecedente,
                    anamnesis: e.target.value,
                  });
                }}
                disabled={
                  antecedente && antecedente.status && statusAcceso3 !== 200
                }
              />
            </Grid>
              */}

            <Grid item xs={12} md={2} style={{ marginTop: "0vw" }} />
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
