import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { InputTextarea } from "primereact/inputtextarea";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
//import firma from "https://seniorfy.com.ar/firma/firma.png";
import firma from "../../assets/images/firma.png";

export default function EvaluacionPeriodica(props) {
  let navigate = useNavigate();
  const toast = useRef(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [globalFilter, setGlobalFilter] = useState(null);

  const [visible, setVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [band, setBand] = useState(true);
  const [dialogPacientes, setDialogPacientes] = useState(false);
  const [email, setEmail] = useState(null);
  const [email2, setEmail2] = useState(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    geriatricoElegido,
    selectedRowPaciente,
    pacientes,
    diagnosticosPacientes,
    statusAcceso3,
    localidades,
    guardarEvaluacionPeriodica,
    obtenerAcceso,
    agregarFrasesVGI,
    agregarFrases,
    obtenerFrases,
    obtenerFrasesVGI,
    obtenerDiagnosticosPacientes,
    obtenerMedicacionesPacientes,
    obtenerMedicacionesPacientesIndividual,
    obtenerDiagnosticosPacientesIndividual,
    setSelectedRowPaciente,
    obtenerPacientes,
    setGeriatricoElegido,
    obtenerEvaluaciones,
    obtenerIngresosEgresos,
    obtenerObrasSocialesPacientes,
    obtenerObrasSocialesMedicasPacientes,
    obtenerInformesPreIngreso,
    guardarEvolucion,
    guardarVgi,
    agregarEvaluacion,
    actualizarEvaluacion,
    obtenerEvaluacionAnterior,
    enviarCertificadoEmail,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "FirmaCertificado",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
      domicilio: localStorage.getItem("geriatricoElegidoDomicilio"),
      telefono: localStorage.getItem("geriatricoElegidoTelefono"),
    });
    obtenerPacientes({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    });
    obtenerDiagnosticosPacientes({ todos: false });
    //obtenerMedicacionesPacientes({ todos: false });
    //obtenerFrases();
    //obtenerFrasesVGI();
    //obtenerObrasSocialesPacientes({ todos: false });
    //obtenerObrasSocialesMedicasPacientes({ todos: false });
  }, []);

  const addClick = (e) => {
    setDialogPacientes(true);
    setSelectedRowPaciente(null);
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHidePacientes = (e) => {
    setSelectedRowPaciente(null);
    setDialogPacientes(false);
  };

  const aceptarClickEmail = (e) => {
    if (selectedRowPaciente) {
      /*
      obtenerDiagnosticosPacientesIndividual({
        pacienteId: selectedRowPaciente.id,
      });
      */
      setEmail(selectedRowPaciente.email);
      setEmail2(null)
      setVisible(true);
      //acceptImprimir();
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  const aceptarEnviarEmail = (e) => {
    if (!email || email === undefined || email === null) {
      mensajeAlerta("Correo", "El correo no puede estar vacío !.", "error");
      return;
    }

    enviarCertificadoEmail({
      email,
      email2,
      selectedRowPaciente,
      diagnosticosPacientes,
      geriatricoElegido,
    });

    setTimeout(() => {
      setVisible(false);
      onHide();
    }, 2000);
  };

  const aceptarClickPdf = (e) => {
    if (selectedRowPaciente) {
      //verificarHistoriaClinica({ selectedRowPaciente });
      //obtenerInformeFragilidad({ pacienteId: selectedRowPaciente.id });
      //obtenerInformesHistoriaClinica({ pacienteId: selectedRowPaciente.id });
      /*
      obtenerMedicacionesPacientesIndividual({
        pacienteId: selectedRowPaciente.id,
      });
      obtenerDiagnosticosPacientesIndividual({
        pacienteId: selectedRowPaciente.id,
      });
      /*
      obtenerEvaluaciones({
        pacienteId: selectedRowPaciente.id,
      });
      obtenerIngresosEgresos({ pacienteId: selectedRowPaciente.id });
      obtenerInformesPreIngreso({ pacienteId: selectedRowPaciente.id });

      setDialogPacientes(false);
      setDialogEvaluacion(true);
      */

      acceptImprimir();
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  const acceptImprimir = () => {
    const doc = new jsPDF({ lineHeight: 1.5 });
    let hoy = new Date();

    let fechaDia = hoy.getDate();
    let fechaMes = hoy.getMonth() + 1;
    let fechaAnio = hoy.getFullYear();
    let nombreMes = "";

    if (fechaMes === 1) {
      nombreMes = "Enero";
    }
    if (fechaMes === 2) {
      nombreMes = "Febrero";
    }
    if (fechaMes === 3) {
      nombreMes = "Marzo";
    }
    if (fechaMes === 4) {
      nombreMes = "Abril";
    }
    if (fechaMes === 5) {
      nombreMes = "Mayo";
    }
    if (fechaMes === 6) {
      nombreMes = "Junio";
    }
    if (fechaMes === 7) {
      nombreMes = "Julio";
    }
    if (fechaMes === 8) {
      nombreMes = "Agosto";
    }
    if (fechaMes === 9) {
      nombreMes = "Septiembre";
    }
    if (fechaMes === 10) {
      nombreMes = "Octubre";
    }
    if (fechaMes === 11) {
      nombreMes = "Noviembre";
    }
    if (fechaMes === 12) {
      nombreMes = "Diciembre";
    }

    doc.setFontSize(12);

    doc.setFont("Time-BoldItalic", "bold");
    //doc.text(geriatricoElegido.nombre, 15, 8);

    //doc.rect(10, 12, 190, 30);

    doc.setFontSize(12);

    doc.setFont("Time-BoldItalic", "bold");
    doc.text("RESIDENCIA GERIATRICA", 70, 40);
    doc.text(geriatricoElegido.nombre, 70, 45);
    doc.text(
      geriatricoElegido.domicilio ? geriatricoElegido.domicilio : "",
      70,
      50
    );
    doc.text(
      geriatricoElegido.telefono ? geriatricoElegido.telefono : "",
      70,
      55
    );

    doc.setFont("Times-Roman", "bold");

    doc.setFontSize(10);

    let row = 70;

    let titulo = "CERTIFICADO DE RESIDENCIA/SUPERVIVENCIA";
    doc.text(titulo, 60, row);
    let textWidth = doc.getTextWidth(titulo);
    doc.line(60, row + 2, 60 + textWidth + 1, row + 2);

    doc.setFont("Times-Roman", "normal");
    doc.lineHeightProportion = 2;
    row = 80;
    doc.text(
      `En mi carácter de Director institucional de la Residencia Geriátrica "${
        geriatricoElegido.nombre
      }", certifico que el Sr/Sra ${selectedRowPaciente.nombre}. D.N.I. ${
        selectedRowPaciente.numeroDocumento
      }, se encuentra internada en este establecimiento geriátrico, sito en la calle ${
        geriatricoElegido.domicilio
      }, desde el día ${formatDate2(
        selectedRowPaciente.fechaIngresoEgreso
      )}, hasta el día de la fecha. Con diagnóstico:`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    let textDiag = "";
    for (var i = 0; i < diagnosticosPacientes.length; i++) {
      textDiag = textDiag + "•" + diagnosticosPacientes[i].label + "  ";
    }

    row = 106;

    doc.text(`${textDiag}`, 30, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = 130;
    doc.text(
      `Se realiza el presente Cerificado de Residencia a pedido del interesado, a los efectos de ser presentado a quien corresponda.`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = 150;
    doc.text(
      `Buenos Aires, ${fechaDia} de ${nombreMes} de ${fechaAnio}.`,
      30,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = 160;

    doc.addImage(firma, "png", 100, row, 40, 40);

    row = 200;
    doc.text("Firma de la Autoridad Responsable", 100, row);

    //setSelectedRow(null);

    window.open(doc.output("bloburl"));
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  function getEdad(dateString) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Tag value="Evaluaciónes"></Tag>
      </div>
    </Fragment>
  );

  const renderHeaderFrasesVGI = () => {
    return (
      <Fragment>
        <div>
          <Tag value={"Opciones VGI"}></Tag>
        </div>
      </Fragment>
    );
  };

  const renderHeaderFrasesEvolucion = () => {
    return (
      <Fragment>
        <div>
          <Tag value={"Opciones Evolución"}></Tag>
        </div>
      </Fragment>
    );
  };

  const renderFooterPacientes = () => {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-envelope"
          onClick={aceptarClickEmail}
          className="p-button-success p-mr-2"
          style={{ padding: 10 }}
        >
          {" "}
          Email
        </Button>
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={aceptarClickPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ padding: 10 }}
        >
          {" "}
          PDF
        </Button>
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={onHidePacientes}
          className="p-button-danger p-mr-2"
          data-pr-tooltip="PDF"
          style={{ padding: 10 }}
        >
          {" "}
          Cancelar
        </Button>
      </div>
    );
  };

  const headerPacientes = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            fullWidth
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Residentes"></Tag>
      </div>
    </Fragment>
  );

  /* INICIO */

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const fechaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDate2(rowData.fecha)}
      </Fragment>
    );
  };

  const fecha2Template = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDate(rowData.fecha)}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const documentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const ingresoEgresoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Ingreso/Egreso</span>
        {formatDate2(rowData.fechaIngresoEgreso)}
      </Fragment>
    );
  };

  const motivoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Motivo</span>
        {rowData.motivoIngresoEgreso}
      </Fragment>
    );
  };

  const activoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Activo</span>
        <Checkbox checked={rowData.activo}></Checkbox>
      </Fragment>
    );
  };

  function formatDate2(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarEnviarEmail}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(selectedRowPaciente);
  //console.log(vgi);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {pacientes ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  align: "center",
                  margin: "1em",
                  marginTop:
                    isMobile && !isPortrait
                      ? "22vw"
                      : isMobile && isPortrait
                      ? "55vw"
                      : "3vw",
                }}
              >
                <Button
                  icon="pi pi-search"
                  tooltip="Pacientes"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
              </Grid>

              <Dialog
                showHeader={false}
                visible={dialogPacientes}
                closable={false}
                footer={renderFooterPacientes()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <Tooltip
                          target=".export-buttons>button"
                          position="bottom"
                        />
                        <DataTable
                          value={pacientes}
                          selection={selectedRowPaciente}
                          onSelectionChange={(e) => {
                            setSelectedRowPaciente(e.value);
                            obtenerDiagnosticosPacientesIndividual({
                              pacienteId: e.value.id,
                            });
                          }}
                          selectionMode="single"
                          dataKey="id"
                          header={headerPacientes}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "small", marginTop: "1vw" }}
                          globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          paginator
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          rows={10}
                          rowsPerPageOptions={[10, 20, 50]}
                          responsiveLayout="scroll"

                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                        >
                          <Column
                            field="id"
                            header="ID"
                            body={idTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="nombre"
                            header="Apellido y Nombre"
                            body={nombreTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="domicilio"
                            header="Domicilio"
                            body={domicilioTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="numeroDocumento"
                            header="Documento"
                            body={documentoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="fechaIngresoEgreso"
                            header="Ingreso/Egreso"
                            body={ingresoEgresoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="motivoIngresoEgreso"
                            header="Motivo"
                            body={motivoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="activo"
                            header="Activo"
                            body={activoTemplate}
                            sortable
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                showHeader={false}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12} style={{ marginTop: "0vw" }}>
                    <TextField
                      id="email"
                      label="Correo"
                      fullWidth
                      variant="standard"
                      value={email ? email : null}
                      //disabled={selectedRow ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} style={{ marginTop: "0vw" }}>
                    <TextField
                      id="email2"
                      label="Correo"
                      fullWidth
                      variant="standard"
                      value={email2 ? email2 : null}
                      //disabled={selectedRow ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setEmail2(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}
