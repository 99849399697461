import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { InputTextarea } from "primereact/inputtextarea";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

export default function EvaluacionPeriodica(props) {
  let navigate = useNavigate();
  const toast = useRef(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [globalFilter, setGlobalFilter] = useState(null);

  const [visible, setVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [evaluacion, guardarEvaluacion] = useState(null);
  const [dialogFrasesVGI, setDialogFrasesVGI] = useState(false);
  const [dialogFrasesEvolucion, setDialogFrasesEvolucion] = useState(false);
  const [frase1, setFrase1] = useState(null);
  const [frase2, setFrase2] = useState(null);
  const [frase3, setFrase3] = useState(null);
  const [frase4, setFrase4] = useState(null);
  const [frase5, setFrase5] = useState(null);
  const [frase6, setFrase6] = useState(null);
  const [frase7, setFrase7] = useState(null);
  const [frase8, setFrase8] = useState(null);
  const [fraseVGI1, setFraseVGI1] = useState(null);
  const [fraseVGI2, setFraseVGI2] = useState(null);
  const [fraseVGI3, setFraseVGI3] = useState(null);
  const [fraseVGI4, setFraseVGI4] = useState(null);
  const [fraseVGI5, setFraseVGI5] = useState(null);
  const [fraseVGI6, setFraseVGI6] = useState(null);
  const [fraseVGI7, setFraseVGI7] = useState(null);
  const [fraseVGI8, setFraseVGI8] = useState(null);
  const [band, setBand] = useState(true);
  const [dialogPacientes, setDialogPacientes] = useState(false);
  const [dialogEvaluacion, setDialogEvaluacion] = useState(false);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    barthel,
    fragilidad,
    lawtonBrody,
    yesavage,
    tinetti,
    norton,
    diagnosticos,
    evaluaciones,
    medicaciones,
    medicacionesPacientes,
    paciente,
    frasesVgi,
    frases,
    frasesVGI,
    evolucion,
    vgi,
    geriatricoElegido,
    obrasSociales,
    obrasSocialesMedicas,
    ingresosEgresos,
    selectedRowPaciente,
    pacientes,
    diagnosticosPacientes,
    statusAcceso3,
    localidades,
    guardarEvaluacionPeriodica,
    obtenerAcceso,
    agregarFrasesVGI,
    agregarFrases,
    obtenerFrases,
    obtenerFrasesVGI,
    obtenerDiagnosticosPacientes,
    obtenerMedicacionesPacientes,
    obtenerMedicacionesPacientesIndividual,
    obtenerDiagnosticosPacientesIndividual,
    setSelectedRowPaciente,
    obtenerPacientes,
    setGeriatricoElegido,
    obtenerEvaluaciones,
    obtenerIngresosEgresos,
    obtenerObrasSocialesPacientes,
    obtenerObrasSocialesMedicasPacientes,
    obtenerInformesPreIngreso,
    guardarEvolucion,
    guardarVgi,
    agregarEvaluacion,
    actualizarEvaluacion,
    obtenerEvaluacionAnterior,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Evaluaciones",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    obtenerPacientes({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    });
    obtenerDiagnosticosPacientes({ todos: false });
    obtenerMedicacionesPacientes({ todos: false });
    obtenerFrases();
    obtenerFrasesVGI();
    obtenerObrasSocialesPacientes({ todos: false });
    obtenerObrasSocialesMedicasPacientes({ todos: false });
  }, []);

  const addClick = (e) => {
    setDialogPacientes(true);
    setSelectedRowPaciente(null);
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const frasesVgiClick = (e) => {
    setDialogFrasesVGI(true);
  };

  const frasesEvolucionClick = (e) => {
    setDialogFrasesEvolucion(true);
  };

  const onHideFrasesVGI = (e) => {
    setDialogFrasesVGI(false);
    //setDialogCaidas(false);
  };

  const onHideFrasesEvolucion = (e) => {
    setDialogFrasesEvolucion(false);
    //setDialogCaidas(false);
  };

  const aceptarFrasesVGI = (e) => {
    agregarFrasesVGI({
      fraseVGI1,
      fraseVGI2,
      fraseVGI3,
      fraseVGI4,
      fraseVGI5,
      fraseVGI6,
      fraseVGI7,
      fraseVGI8,
    });
    onHideFrasesVGI();
    obtenerFrasesVGI();
  };

  const aceptarFrasesEvolucion = (e) => {
    agregarFrases({
      frase1,
      frase2,
      frase3,
      frase4,
      frase5,
      frase6,
      frase7,
      frase8,
    });
    onHideFrasesEvolucion();
    obtenerFrases();
  };

  const onHidePacientes = (e) => {
    setSelectedRowPaciente(null);
    setDialogPacientes(false);
  };

  const aceptarEvaluacion = (e) => {
    if (selectedRow) {
      actualizarEvaluacion({
        id: selectedRow.id,
        vgi,
        evolucion,
        usuario: state.usuario,
      });
    } else {
      agregarEvaluacion({
        paciente: selectedRowPaciente,
        vgi,
        evolucion,
        usuario: state.usuario,
      });
    }
    onHide();
    setTimeout(() => {
      obtenerEvaluaciones({
        pacienteId: selectedRowPaciente.id,
      });
    }, 1000);
  };

  const aceptarClickPacientes = (e) => {
    if (selectedRowPaciente) {
      //verificarHistoriaClinica({ selectedRowPaciente });
      //obtenerInformeFragilidad({ pacienteId: selectedRowPaciente.id });
      //obtenerInformesHistoriaClinica({ pacienteId: selectedRowPaciente.id });
      obtenerMedicacionesPacientesIndividual({
        pacienteId: selectedRowPaciente.id,
      });
      obtenerDiagnosticosPacientesIndividual({
        pacienteId: selectedRowPaciente.id,
      });
      obtenerEvaluaciones({
        pacienteId: selectedRowPaciente.id,
      });
      obtenerIngresosEgresos({ pacienteId: selectedRowPaciente.id });
      obtenerInformesPreIngreso({ pacienteId: selectedRowPaciente.id });

      setDialogPacientes(false);
      setDialogEvaluacion(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  const onHideEvaluaciones = (e) => {
    setDialogEvaluacion(false);
    //setDialogCaidas(false);
  };

  const addClickEvaluacion = (e) => {
    guardarEvaluacion([]);
    obtenerEvaluacionAnterior({ pacienteId: selectedRowPaciente.id });
    guardarEvolucion(null);
    setVisible(true);
    setSelectedRow(null);
  };

  const verClickEvaluacion = (e) => {
    if (selectedRow) {
      guardarEvaluacion(selectedRow);
      guardarVgi(selectedRow.vgi);
      guardarEvolucion(selectedRow.evolucion);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una evaluación.",
        life: 3000,
      });
    }
  };

  const imprimirClick = (e) => {
    if (selectedRow) {
      guardarEvaluacion(selectedRow);
      guardarVgi(selectedRow.vgi);
      guardarEvolucion(selectedRow.evolucion);
      toast.current.show({
        severity: "info",
        summary: "Imprimir",
        detail: "Obteniendo datos para imprimir.",
        life: 3000,
      });
      setTimeout(() => {
        var elem;
        elem = document.getElementById("imprimirClick");
        elem.click();
      }, 3000);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Imprimir",
        detail: "Debe seleccionar una evaluación.",
        life: 3000,
      });
    }
  };

  const acceptImprimir = () => {
    const doc = new jsPDF();
    let hoy = new Date();

    doc.setFontSize(12);

    doc.setFont("Time-BoldItalic", "bold");
    doc.text(geriatricoElegido.nombre, 15, 8);

    doc.rect(10, 12, 190, 30);

    doc.setFontSize(14);

    doc.setFont("Time-BoldItalic", "bold");
    doc.text("EVALUACION PERIODICA", 70, 20);

    doc.setFont("Times-Roman", "normal");

    doc.setFontSize(10);

    let row = 25;

    doc.text(
      "Paciente: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.nombre !== undefined &&
        selectedRowPaciente.nombre !== null
          ? selectedRowPaciente.nombre
          : ""),
      12,
      row,
      "left",
      "middle"
    );

    doc.text(
      "D.N.I.: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.numeroDocumento !== undefined &&
        selectedRowPaciente.numeroDocumento !== null
          ? selectedRowPaciente.numeroDocumento
          : ""),

      130,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Fecha Nacimiento: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.fechaNacimiento !== undefined &&
        selectedRowPaciente.fechaNacimiento !== null
          ? formatDate2(selectedRowPaciente.fechaNacimiento)
          : ""),
      12,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Edad: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.fechaNacimiento !== undefined &&
        selectedRowPaciente.fechaNacimiento !== null
          ? getEdad(selectedRowPaciente.fechaNacimiento)
          : ""),
      130,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Fecha de Ingreso a la Institución: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        ingresosEgresos &&
        selectedRowPaciente.fechaNacimiento !== undefined &&
        selectedRowPaciente.fechaNacimiento !== null
          ? formatDate2(ingresosEgresos[0].fecha)
          : ""),
      12,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Obra Social Contrato: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.obraSocialContratoId !== undefined
          ? obrasSociales[
              obrasSociales.findIndex(
                (item) => item.id === selectedRowPaciente.obraSocialContratoId
              )
            ].label
          : ""),
      130,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Obra Social Médica: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.obraSocialId !== undefined
          ? obrasSocialesMedicas[
              obrasSocialesMedicas.findIndex(
                (item) => item.id === selectedRowPaciente.obraSocialId
              )
            ].label
          : ""),
      12,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Nº Obra Social: " +
        (selectedRowPaciente !== undefined &&
        selectedRowPaciente !== null &&
        selectedRowPaciente.numeroObraSocial !== undefined &&
        selectedRowPaciente.numeroObraSocial !== null
          ? selectedRowPaciente.numeroObraSocial
          : ""),

      130,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      "Fecha: " +
        (selectedRow !== undefined &&
        selectedRow !== null &&
        selectedRow.fecha !== undefined &&
        selectedRow.fecha !== null
          ? formatDate2(selectedRow.fecha)
          : ""),
      12,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text("DIAGNOSTICOS DE INGRESO: ", 10, row, "left", "middle");

    row = row + 5;

    doc.setFontSize(9);

    for (var i = 0; i < diagnosticosPacientes.length; i++) {
      doc.text("-" + diagnosticosPacientes[i].label, 10, row, {
        maxWidth: 180,
        align: "left",
      });
      let w = doc.getTextDimensions(diagnosticosPacientes[i].label);
      if (w.w > 180) {
        row = row + 10;
      } else {
        row = row + 5;
      }
    }

    doc.setFontSize(10);

    row = row + 5;

    doc.text("EVALUACION DE INGRESO: ", 10, row, "left", "middle");

    row = row + 10;

    doc.text(
      "BARTHEL: " +
        (barthel
          ? Number(barthel.comer ? barthel.comer : 0) +
            Number(barthel.lavarse ? barthel.lavarse : 0) +
            Number(barthel.vestirse ? barthel.vestirse : 0) +
            Number(barthel.arreglarse ? barthel.arreglarse : 0) +
            Number(barthel.deposiciones ? barthel.deposiciones : 0) +
            Number(barthel.miccion ? barthel.miccion : 0) +
            Number(barthel.retrete ? barthel.retrete : 0) +
            Number(barthel.trasladarse ? barthel.trasladarse : 0) +
            Number(barthel.deambular ? barthel.deambular : 0) +
            Number(barthel.escalones ? barthel.escalones : 0)
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "LAWTON Y BRODY: " +
        (lawtonBrody
          ? Number(
              lawtonBrody.telefonoResultado ? lawtonBrody.telefonoResultado : 0
            ) +
            Number(
              lawtonBrody.comprasResultado ? lawtonBrody.comprasResultado : 0
            ) +
            Number(
              lawtonBrody.comidaResultado ? lawtonBrody.comidaResultado : 0
            ) +
            Number(lawtonBrody.casaResultado ? lawtonBrody.casaResultado : 0) +
            Number(lawtonBrody.ropaResultado ? lawtonBrody.ropaResultado : 0) +
            Number(
              lawtonBrody.transporteResultado
                ? lawtonBrody.transporteResultado
                : 0
            ) +
            Number(
              lawtonBrody.medicacionResultado
                ? lawtonBrody.medicacionResultado
                : 0
            ) +
            Number(
              lawtonBrody.economicoResultado
                ? lawtonBrody.economicoResultado
                : 0
            )
          : ""),
      60,
      row,
      "left",
      "middle"
    );

    doc.text(
      "TINETTI: " +
        (tinetti
          ? Number(tinetti.equilibrioSentado ? tinetti.equilibrioSentado : 0) +
            Number(tinetti.levantarse ? tinetti.levantarse : 0) +
            Number(tinetti.intentoLevantarse ? tinetti.intentoLevantarse : 0) +
            Number(
              tinetti.equilibrioSegundos ? tinetti.equilibrioSegundos : 0
            ) +
            Number(
              tinetti.equilibrioProlongado ? tinetti.equilibrioProlongado : 0
            ) +
            Number(
              tinetti.rombergSensibilizado ? tinetti.rombergSensibilizado : 0
            ) +
            Number(tinetti.romberg ? tinetti.romberg : 0) +
            Number(tinetti.girar ? tinetti.girar : 0) +
            Number(tinetti.girar2 ? tinetti.girar2 : 0) +
            Number(tinetti.sentarse ? tinetti.sentarse : 0) / 16
          : ""),
      110,
      row,
      "left",
      "middle"
    );

    doc.text(
      "NORTON: " +
        (norton
          ? Number(norton.estadoFisico ? norton.estadoFisico : 0) +
            Number(norton.estadoMental ? norton.estadoMental : 0) +
            Number(norton.actividad ? norton.actividad : 0) +
            Number(norton.movilidad ? norton.movilidad : 0) +
            Number(norton.incontinencia ? norton.incontinencia : 0)
          : ""),
      150,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "MARCADORES DE FRAGILIDAD: " +
        (fragilidad
          ? Number(fragilidad.pregunta1 ? fragilidad.pregunta1Value : 0) +
            Number(fragilidad.pregunta2 ? fragilidad.pregunta2Value : 0) +
            Number(fragilidad.pregunta3 ? fragilidad.pregunta3Value : 0) +
            Number(fragilidad.pregunta4 ? fragilidad.pregunta4Value : 0) +
            Number(fragilidad.pregunta5 ? fragilidad.pregunta5Value : 0) +
            Number(fragilidad.pregunta6 ? fragilidad.pregunta6Value : 0) +
            Number(fragilidad.pregunta7 ? fragilidad.pregunta7Value : 0) +
            Number(fragilidad.pregunta8 ? fragilidad.pregunta8Value : 0) +
            Number(fragilidad.pregunta9 ? fragilidad.pregunta9Value : 0) +
            Number(fragilidad.pregunta10 ? fragilidad.pregunta10Value : 0) +
            Number(fragilidad.pregunta11 ? fragilidad.pregunta11Value : 0) +
            Number(fragilidad.pregunta12 ? fragilidad.pregunta12Value : 0) +
            Number(fragilidad.pregunta13 ? fragilidad.pregunta13Value : 0) +
            Number(fragilidad.pregunta14 ? fragilidad.pregunta14Value : 0) +
            Number(fragilidad.pregunta15 ? fragilidad.pregunta15Value : 0) +
            Number(fragilidad.pregunta16 ? fragilidad.pregunta16Value : 0) +
            Number(fragilidad.pregunta17 ? fragilidad.pregunta17Value : 0) +
            Number(fragilidad.pregunta18 ? fragilidad.pregunta18Value : 0) +
            Number(fragilidad.pregunta19 ? fragilidad.pregunta19Value : 0) +
            Number(fragilidad.pregunta20 ? fragilidad.pregunta20Value : 0) +
            Number(fragilidad.pregunta21 ? fragilidad.pregunta21Value : 0) +
            Number(fragilidad.pregunta22 ? fragilidad.pregunta22Value : 0) +
            Number(fragilidad.pregunta23 ? fragilidad.pregunta23Value : 0) +
            Number(fragilidad.pregunta24 ? fragilidad.pregunta24Value : 0) +
            Number(fragilidad.pregunta25 ? fragilidad.pregunta25Value : 0) +
            Number(fragilidad.pregunta26 ? fragilidad.pregunta26Value : 0) +
            Number(fragilidad.pregunta27 ? fragilidad.pregunta27Value : 0) +
            " - " +
            fragilidad.observaciones
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text("V.G.I.: ", 10, row, "left", "middle");
    row = row + 5;

    if (vgi)
      doc.text(vgi ? vgi : "", 10, row, {
        maxWidth: 180,
        align: "left",
      });

    var lines = 0;
    var lMargin = 20; //left margin in mm
    var rMargin = 10; //right margin in mm
    var pdfInMM = 210; // width of A4 in mm
    lines = doc.splitTextToSize(vgi, pdfInMM - lMargin - rMargin);

    row = row + lines.length * 5;
    doc.text(
      "_____________________________________________________________________________________________",
      10,
      row,
      "left",
      "middle"
    );
    row = row + 10;

    doc.text("MEDICACION: ", 10, row, "left", "middle");

    row = row + 5;

    doc.setFontSize(9);

    for (var i = 0; i < medicacionesPacientes.length; i++) {
      doc.text("-" + medicacionesPacientes[i].label, 10, row, "left", "middle");
      row = row + 5;
    }

    doc.setFontSize(10);

    row = row + 5;

    doc.text("EVOLUCION: ", 10, row, "left", "middle");
    row = row + 5;

    if (evolucion)
      doc.text(evolucion ? evolucion : "", 10, row, {
        maxWidth: 180,
        align: "left",
      });
    var lines = 0;
    var lMargin = 20; //left margin in mm
    var rMargin = 10; //right margin in mm
    var pdfInMM = 210; // width of A4 in mm
    lines = doc.splitTextToSize(evolucion, pdfInMM - lMargin - rMargin);

    row = row + lines.length * 5;

    doc.text(
      "_____________________________________________________________________________________________",
      10,
      row,
      "left",
      "middle"
    );
    row = row + 10;

    row = 290;

    doc.text("FIRMA Y SELLO", 90, row, "left", "middle");

    setSelectedRow(null);

    window.open(doc.output("bloburl"));
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  function getEdad(dateString) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Tag value="Evaluaciónes"></Tag>
      </div>
    </Fragment>
  );

  const renderHeaderFrasesVGI = () => {
    return (
      <Fragment>
        <div>
          <Tag value={"Opciones VGI"}></Tag>
        </div>
      </Fragment>
    );
  };

  const renderHeaderFrasesEvolucion = () => {
    return (
      <Fragment>
        <div>
          <Tag value={"Opciones Evolución"}></Tag>
        </div>
      </Fragment>
    );
  };

  const renderFooterFrasesVGI = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarFrasesVGI}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideFrasesVGI}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderFooterFrasesEvolucion = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarFrasesEvolucion}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideFrasesEvolucion}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderFooterPacientes = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickPacientes}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHidePacientes}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeaderEvaluaciones = () => {
    return (
      <Fragment>
        <div>
          {selectedRowPaciente ? (
            <Tag
              value={"Evaluaciones Periódicas de " + selectedRowPaciente.nombre}
            ></Tag>
          ) : null}
        </div>
      </Fragment>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarEvaluacion}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const headerEvaluaciones = (
    <Fragment>
      <Button
        icon="pi pi-plus"
        tooltip="Agregar"
        className="p-button-rounded p-button-help"
        style={{ marginLeft: "0em" }}
        onClick={addClickEvaluacion}
      />
      <Button
        icon="pi pi-pencil"
        tooltip="Ver"
        className="p-button-rounded p-button-success"
        style={{ marginLeft: "1em" }}
        onClick={verClickEvaluacion}
      />
      <Button
        icon="pi pi-print"
        tooltip="Imprimir"
        className="p-button-rounded p-button-help"
        style={{ marginLeft: "1em" }}
        onClick={imprimirClick}
      />
      <Button
        id="imprimirClick"
        icon="pi pi-print"
        tooltip="Imprimir"
        className="p-button-rounded p-button-help"
        style={{ marginLeft: "1em", visibility: "hidden" }}
        onClick={acceptImprimir}
      />
    </Fragment>
  );

  const headerPacientes = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            fullWidth
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Residentes"></Tag>
      </div>
    </Fragment>
  );

  /* INICIO */

  if (
    band &&
    frases &&
    frases.length > 0 &&
    frasesVGI &&
    frasesVGI.length > 0
  ) {
    setBand(false);
    if (frases[0].frase !== null && frases[0].frase !== undefined) {
      setFrase1(frases[0].frase);
    }
    if (frases[1].frase !== null && frases[1].frase !== undefined) {
      setFrase2(frases[1].frase);
    }
    if (frases[2].frase !== null && frases[2].frase !== undefined) {
      setFrase3(frases[2].frase);
    }
    if (frases[3].frase !== null && frases[3].frase !== undefined) {
      setFrase4(frases[3].frase);
    }
    if (frases[4].frase !== null && frases[4].frase !== undefined) {
      setFrase5(frases[4].frase);
    }
    if (frases[5].frase !== null && frases[5].frase !== undefined) {
      setFrase6(frases[5].frase);
    }
    if (frases[6].frase !== null && frases[6].frase !== undefined) {
      setFrase7(frases[6].frase);
    }
    if (frases[7].frase !== null && frases[7].frase !== undefined) {
      setFrase8(frases[7].frase);
    }

    //VGI

    if (frasesVGI[0].frase !== null && frasesVGI[0].frase !== undefined) {
      setFraseVGI1(frasesVGI[0].frase);
    }
    if (frasesVGI[1].frase !== null && frasesVGI[1].frase !== undefined) {
      setFraseVGI2(frasesVGI[1].frase);
    }
    if (frasesVGI[2].frase !== null && frasesVGI[2].frase !== undefined) {
      setFraseVGI3(frasesVGI[2].frase);
    }
    if (frasesVGI[3].frase !== null && frasesVGI[3].frase !== undefined) {
      setFraseVGI4(frasesVGI[3].frase);
    }
    if (frasesVGI[4].frase !== null && frasesVGI[4].frase !== undefined) {
      setFraseVGI5(frasesVGI[4].frase);
    }
    if (frasesVGI[5].frase !== null && frasesVGI[5].frase !== undefined) {
      setFraseVGI6(frasesVGI[5].frase);
    }
    if (frasesVGI[6].frase !== null && frasesVGI[6].frase !== undefined) {
      setFraseVGI7(frasesVGI[6].frase);
    }
    if (frasesVGI[7].frase !== null && frasesVGI[7].frase !== undefined) {
      setFraseVGI8(frasesVGI[7].frase);
    }
  }
  /* FIN */

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const fechaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDate2(rowData.fecha)}
      </Fragment>
    );
  };

  const fecha2Template = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDate(rowData.fecha)}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const documentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const ingresoEgresoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Ingreso/Egreso</span>
        {formatDate2(rowData.fechaIngresoEgreso)}
      </Fragment>
    );
  };

  const motivoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Motivo</span>
        {rowData.motivoIngresoEgreso}
      </Fragment>
    );
  };

  const activoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Activo</span>
        <Checkbox checked={rowData.activo}></Checkbox>
      </Fragment>
    );
  };

  function formatDate2(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  //console.log(ingresosEgresos);
  //console.log(vgi);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {evaluaciones ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  align: "center",
                  margin: "1em",
                  marginTop:
                    isMobile && !isPortrait
                      ? "22vw"
                      : isMobile && isPortrait
                      ? "55vw"
                      : "3vw",
                }}
              >
                <Button
                  icon="pi pi-search"
                  tooltip="Pacientes"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-align-justify"
                  tooltip="Opciones VGI"
                  className="p-button-rounded p-button-success"
                  style={{ marginLeft: "1em" }}
                  onClick={frasesVgiClick}
                />
                <Button
                  icon="pi pi-align-justify"
                  tooltip="Opciones Evolución"
                  className="p-button-rounded p-button-warning"
                  style={{ marginLeft: "1em" }}
                  onClick={frasesEvolucionClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
              </Grid>

              <Dialog
                header={renderHeaderEvaluaciones()}
                visible={dialogEvaluacion}
                maximized={true}
                //maximizable={true}
                closable={true}
                onHide={onHideEvaluaciones}
                //footer={renderFooterEvoluciones()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "95vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    {evaluaciones ? (
                      <div className="datatable-responsive-demo">
                        <div className="card">
                          <Tooltip
                            target=".export-buttons>button"
                            position="bottom"
                          />
                          <DataTable
                            value={evaluaciones}
                            selection={selectedRow}
                            onSelectionChange={(e) => setSelectedRow(e.value)}
                            selectionMode="single"
                            dataKey="id"
                            header={headerEvaluaciones}
                            className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                            style={{ fontSize: "small", marginTop: "0vw" }}
                            //globalFilter={globalFilter}
                            emptyMessage="No hay datos."
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            responsiveLayout="scroll"

                            //paginatorLeft={paginatorLeft}
                            //paginatorRight={paginatorRight}
                          >
                            <Column
                              field="id"
                              header="ID"
                              body={idTemplate}
                              style={{ display: "none" }}
                              sortable
                            ></Column>
                            <Column
                              field="fecha"
                              header="Fecha"
                              body={fechaTemplate}
                              sortable
                              style={{ width: "10%" }}
                            ></Column>
                            <Column
                              field="vgi"
                              header="V.G.I."
                              //body={documentoTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="evolucion"
                              header="Evolucion"
                              //body={documentoTemplate}
                              sortable
                            ></Column>
                          </DataTable>
                        </div>
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                showHeader={false}
                visible={dialogPacientes}
                closable={false}
                footer={renderFooterPacientes()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <Tooltip
                          target=".export-buttons>button"
                          position="bottom"
                        />
                        <DataTable
                          value={pacientes}
                          selection={selectedRowPaciente}
                          onSelectionChange={(e) =>
                            setSelectedRowPaciente(e.value)
                          }
                          selectionMode="single"
                          dataKey="id"
                          header={headerPacientes}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "small", marginTop: "1vw" }}
                          globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          paginator
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          rows={10}
                          rowsPerPageOptions={[10, 20, 50]}
                          responsiveLayout="scroll"

                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                        >
                          <Column
                            field="id"
                            header="ID"
                            body={idTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="nombre"
                            header="Apellido y Nombre"
                            body={nombreTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="domicilio"
                            header="Domicilio"
                            body={domicilioTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="numeroDocumento"
                            header="Documento"
                            body={documentoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="fechaIngresoEgreso"
                            header="Ingreso/Egreso"
                            body={ingresoEgresoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="motivoIngresoEgreso"
                            header="Motivo"
                            body={motivoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="activo"
                            header="Activo"
                            body={activoTemplate}
                            sortable
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                //header={renderHeader()}
                visible={visible}
                closable={true}
                maximizable
                onHide={onHide}
                footer={renderFooter()}
                breakpoints={{ "960px": "95vw" }}
                style={{ width: "100vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "1vw", marginLeft: "0vw" }}
                >
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline" }}
                    >
                      DATOS PERSONALES
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      value={
                        evaluaciones && evaluaciones.fecha !== undefined
                          ? evaluaciones.fecha
                          : formatDate(new Date())
                      }
                      InputLabelProps={{ shrink: true }}
                      id="fecha"
                      label="Fecha Actual"
                      fullWidth
                      type="date"
                      variant="standard"
                      disabled
                      size="small"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      value={
                        selectedRowPaciente ? selectedRowPaciente.nombre : null
                      }
                      InputLabelProps={{ shrink: true }}
                      id="nombre"
                      label="Apellido y Nombre"
                      fullWidth
                      variant="standard"
                      disabled
                      size="small"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      value={
                        selectedRowPaciente
                          ? selectedRowPaciente.numeroDocumento
                          : null
                      }
                      InputLabelProps={{ shrink: true }}
                      id="numeroDocumento"
                      label="Nº de Documento"
                      fullWidth
                      variant="standard"
                      disabled
                      size="small"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <TextField
                      id="fechaNacimiento"
                      label="Fecha de Nacimiento"
                      type="date"
                      variant="standard"
                      fullWidth
                      value={
                        selectedRowPaciente
                          ? selectedRowPaciente.fechaNacimiento
                          : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                      size="small"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <TextField
                      value={
                        selectedRowPaciente
                          ? getEdad(selectedRowPaciente.fechaNacimiento)
                          : null
                      }
                      InputLabelProps={{ shrink: true }}
                      id="edad"
                      label="Edad"
                      fullWidth
                      variant="standard"
                      disabled
                      size="small"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} md={2}>
                    <TextField
                      id="fechaIngreso"
                      label="Fecha de Ingreso a la Institución"
                      type="date"
                      fullWidth
                      variant="standard"
                      value={
                        selectedRowPaciente && ingresosEgresos
                          ? ingresosEgresos[0].fecha
                          : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                      size="small"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="obraSocial"
                      fullWidth
                      options={obrasSocialesMedicas}
                      disablePortal
                      defaultValue={
                        selectedRowPaciente &&
                        selectedRowPaciente.obraSocialId !== undefined
                          ? obrasSocialesMedicas[
                              obrasSocialesMedicas.findIndex(
                                (item) =>
                                  item.id === selectedRowPaciente.obraSocialId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Obra Social Médica"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      disabled
                      size="small"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="obraSocialContrato"
                      fullWidth
                      options={obrasSociales}
                      disablePortal
                      defaultValue={
                        selectedRowPaciente &&
                        selectedRowPaciente.obraSocialContratoId !== undefined
                          ? obrasSociales[
                              obrasSociales.findIndex(
                                (item) =>
                                  item.id ===
                                  selectedRowPaciente.obraSocialContratoId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Obra Social Contrato"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      disabled
                      size="small"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="diagnostico"
                      fullWidth
                      multiple
                      options={diagnosticos}
                      disablePortal
                      defaultValue={
                        selectedRowPaciente &&
                        diagnosticosPacientes &&
                        diagnosticosPacientes !== null &&
                        diagnosticosPacientes.length > 0
                          ? diagnosticosPacientes
                          : []
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Diagnósticos"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      /*
                      onChange={(event, values) => {
                        if (values) {
                          guardarDiagnosticosPacientes(values);
                        } else {
                          guardarDiagnosticosPacientes(null);
                        }
                      }}
                      */
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline" }}
                    >
                      EVALUACION DE INGRESO:
                    </Typography>
                  </Grid>

                  {/*        
            <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
              <TextareaAutosize
                value={
                  evaluacionPeriodica
                    ? evaluacionPeriodica.diagnosticoIngreso
                    : null
                }
                id="DiagnósticoIngreso"
                aria-label="minimum height"
                minRows={2}
                maxRows={6}
                fullWidth
                placeholder="Diagnóstico de Ingreso"
                style={{ width: "100%" }}
                onChange={(e) =>
                  guardarEvaluacionPeriodica({
                    ...evaluacionPeriodica,
                    diagnosticoIngreso: e.target.value,
                  })
                }
                disabled={
                  evaluacionPeriodica &&
                  evaluacionPeriodica.status &&
                  statusAcceso3 !== 200
                }
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                VALORACION GERIATRICA INTEGRAL:
              </Typography>
            </Grid>
            */}

                  <Grid item xs={12} md={2}>
                    <TextField
                      value={
                        barthel
                          ? Number(barthel.comer ? barthel.comer : 0) +
                            Number(barthel.lavarse ? barthel.lavarse : 0) +
                            Number(barthel.vestirse ? barthel.vestirse : 0) +
                            Number(
                              barthel.arreglarse ? barthel.arreglarse : 0
                            ) +
                            Number(
                              barthel.deposiciones ? barthel.deposiciones : 0
                            ) +
                            Number(barthel.miccion ? barthel.miccion : 0) +
                            Number(barthel.retrete ? barthel.retrete : 0) +
                            Number(
                              barthel.trasladarse ? barthel.trasladarse : 0
                            ) +
                            Number(barthel.deambular ? barthel.deambular : 0) +
                            Number(barthel.escalones ? barthel.escalones : 0)
                          : null
                      }
                      id="barthel"
                      label="BARTHEL"
                      fullWidth
                      disabled
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      value={
                        lawtonBrody
                          ? Number(
                              lawtonBrody.telefonoResultado
                                ? lawtonBrody.telefonoResultado
                                : 0
                            ) +
                            Number(
                              lawtonBrody.comprasResultado
                                ? lawtonBrody.comprasResultado
                                : 0
                            ) +
                            Number(
                              lawtonBrody.comidaResultado
                                ? lawtonBrody.comidaResultado
                                : 0
                            ) +
                            Number(
                              lawtonBrody.casaResultado
                                ? lawtonBrody.casaResultado
                                : 0
                            ) +
                            Number(
                              lawtonBrody.ropaResultado
                                ? lawtonBrody.ropaResultado
                                : 0
                            ) +
                            Number(
                              lawtonBrody.transporteResultado
                                ? lawtonBrody.transporteResultado
                                : 0
                            ) +
                            Number(
                              lawtonBrody.medicacionResultado
                                ? lawtonBrody.medicacionResultado
                                : 0
                            ) +
                            Number(
                              lawtonBrody.economicoResultado
                                ? lawtonBrody.economicoResultado
                                : 0
                            )
                          : null
                      }
                      id="lawton"
                      label="LAWTON Y BRODY"
                      fullWidth
                      disabled
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      value={
                        tinetti
                          ? Number(
                              tinetti.equilibrioSentado
                                ? tinetti.equilibrioSentado
                                : 0
                            ) +
                            Number(
                              tinetti.levantarse ? tinetti.levantarse : 0
                            ) +
                            Number(
                              tinetti.intentoLevantarse
                                ? tinetti.intentoLevantarse
                                : 0
                            ) +
                            Number(
                              tinetti.equilibrioSegundos
                                ? tinetti.equilibrioSegundos
                                : 0
                            ) +
                            Number(
                              tinetti.equilibrioProlongado
                                ? tinetti.equilibrioProlongado
                                : 0
                            ) +
                            Number(
                              tinetti.rombergSensibilizado
                                ? tinetti.rombergSensibilizado
                                : 0
                            ) +
                            Number(tinetti.romberg ? tinetti.romberg : 0) +
                            Number(tinetti.girar ? tinetti.girar : 0) +
                            Number(tinetti.girar2 ? tinetti.girar2 : 0) +
                            Number(tinetti.sentarse ? tinetti.sentarse : 0) / 16
                          : null
                      }
                      id="tinetti"
                      label="TINETTI"
                      fullWidth
                      disabled
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      value={
                        norton
                          ? Number(
                              norton.estadoFisico ? norton.estadoFisico : 0
                            ) +
                            Number(
                              norton.estadoMental ? norton.estadoMental : 0
                            ) +
                            Number(norton.actividad ? norton.actividad : 0) +
                            Number(norton.movilidad ? norton.movilidad : 0) +
                            Number(
                              norton.incontinencia ? norton.incontinencia : 0
                            )
                          : null
                      }
                      id="norton"
                      label="NORTON"
                      fullWidth
                      disabled
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      value={
                        fragilidad
                          ? Number(
                              fragilidad.pregunta1
                                ? fragilidad.pregunta1Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta2
                                ? fragilidad.pregunta2Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta3
                                ? fragilidad.pregunta3Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta4
                                ? fragilidad.pregunta4Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta5
                                ? fragilidad.pregunta5Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta6
                                ? fragilidad.pregunta6Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta7
                                ? fragilidad.pregunta7Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta8
                                ? fragilidad.pregunta8Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta9
                                ? fragilidad.pregunta9Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta10
                                ? fragilidad.pregunta10Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta11
                                ? fragilidad.pregunta11Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta12
                                ? fragilidad.pregunta12Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta13
                                ? fragilidad.pregunta13Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta14
                                ? fragilidad.pregunta14Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta15
                                ? fragilidad.pregunta15Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta16
                                ? fragilidad.pregunta16Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta17
                                ? fragilidad.pregunta17Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta18
                                ? fragilidad.pregunta18Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta19
                                ? fragilidad.pregunta19Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta20
                                ? fragilidad.pregunta20Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta21
                                ? fragilidad.pregunta21Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta22
                                ? fragilidad.pregunta22Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta23
                                ? fragilidad.pregunta23Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta24
                                ? fragilidad.pregunta24Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta25
                                ? fragilidad.pregunta25Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta26
                                ? fragilidad.pregunta26Value
                                : 0
                            ) +
                            Number(
                              fragilidad.pregunta27
                                ? fragilidad.pregunta27Value
                                : 0
                            ) +
                            " - " +
                            fragilidad.observaciones
                          : null
                      }
                      id="fragilidad"
                      label="MARCADORES DE FRAGILIDAD"
                      fullWidth
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline" }}
                    >
                      V.G.I.:
                    </Typography>
                  </Grid>

                  {frasesVGI && frasesVGI.length > 0 ? (
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        id="frasesVgi"
                        fullWidth
                        options={frasesVGI}
                        disablePortal
                        getOptionLabel={(option) => option.frase}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Opciones V.G.I."
                            size="small"
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "black",
                              },
                            }}
                          />
                        )}
                        onChange={(event, value) => {
                          if (value && value.frase && value.frase !== null) {
                            if (vgi) {
                              guardarVgi((vgi) => vgi + value.frase + "\n");
                            } else {
                              guardarVgi(value.frase + "\n");
                            }
                            setTimeout(() => {
                              const ele = document.getElementsByClassName(
                                "MuiAutocomplete-clearIndicator"
                              )[1];
                              if (ele) ele.click();
                            }, 1000);
                          }
                        }}

                        //onKeyDown={handleEnter}
                      />
                    </Grid>
                  ) : null}

                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ margin: "1vw", width: "auto" }}
                  >
                    <Grid item xs={12} md={12}>
                      {/*}
                  <textarea
                    rows="10"
                    //cols="100"
                    name="text"
                    placeholder="Ingrese V.G.I."
                    value={vgi}
                    onChange={(e) => guardarVgi(e.target.value)}
                    style={{ width: "100%" }}
                  ></textarea>
                  */}
                      <InputTextarea
                        value={vgi ? vgi : null}
                        onChange={(e) => guardarVgi(e.target.value)}
                        rows={10}
                        cols={
                          isMobile && !isPortrait
                            ? 90
                            : isMobile && isPortrait
                            ? 30
                            : 120
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="medicacion"
                      fullWidth
                      multiple
                      options={medicaciones}
                      disablePortal
                      defaultValue={
                        selectedRowPaciente &&
                        medicacionesPacientes &&
                        medicacionesPacientes !== null &&
                        medicacionesPacientes.length > 0
                          ? medicacionesPacientes
                          : []
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Medicación"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      /*
                      onChange={(event, values) => {
                        if (values) {
                          guardarDiagnosticosPacientes(values);
                        } else {
                          guardarDiagnosticosPacientes(null);
                        }
                      }}
                      */
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline" }}
                    >
                      EVOLUCION:
                    </Typography>
                  </Grid>

                  {frases && frases.length > 0 ? (
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        id="frases"
                        fullWidth
                        options={frases}
                        disablePortal
                        getOptionLabel={(option) => option.frase}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Opciones"
                            size="small"
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "black",
                              },
                            }}
                          />
                        )}
                        onChange={(event, value) => {
                          if (value && value.frase && value.frase !== null) {
                            if (evolucion) {
                              guardarEvolucion(
                                (evolucion) => evolucion + value.frase + "\n"
                              );
                            } else {
                              guardarEvolucion(value.frase + "\n");
                            }
                            setTimeout(() => {
                              const ele = document.getElementsByClassName(
                                "MuiAutocomplete-clearIndicator"
                              )[2];
                              if (ele) ele.click();
                            }, 1000);
                          }
                        }}

                        //onKeyDown={handleEnter}
                      />
                    </Grid>
                  ) : null}

                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ margin: "1vw", width: "auto" }}
                  >
                    <Grid item xs={12} md={12}>
                      {/*
                  <textarea
                    rows="10"
                    //cols="100"
                    name="text"
                    placeholder="Ingrese Evolución"
                    disabled={selectedRow !== null}
                    value={evolucion}
                    onChange={(e) => guardarEvolucion(e.target.value)}
                    style={{ width: "100%" }}
                  ></textarea>
                  */}
                      <InputTextarea
                        value={evolucion ? evolucion : null}
                        onChange={(e) => guardarEvolucion(e.target.value)}
                        rows={10}
                        cols={
                          isMobile && !isPortrait
                            ? 90
                            : isMobile && isPortrait
                            ? 30
                            : 120
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Dialog>
              <Dialog
                header={renderHeaderFrasesVGI()}
                visible={dialogFrasesVGI}
                //maximized={true}
                //maximizable={true}
                closable={true}
                onHide={onHideFrasesVGI}
                footer={renderFooterFrasesVGI()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase1"
                      label="Opción 1"
                      fullWidth
                      variant="standard"
                      value={fraseVGI1 ? fraseVGI1 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFraseVGI1(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase2"
                      label="Opción 2"
                      fullWidth
                      variant="standard"
                      value={fraseVGI2 ? fraseVGI2 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFraseVGI2(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase3"
                      label="Opción 3"
                      fullWidth
                      variant="standard"
                      value={fraseVGI3 ? fraseVGI3 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFraseVGI3(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase4"
                      label="Opción 4"
                      fullWidth
                      variant="standard"
                      value={fraseVGI4 ? fraseVGI4 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFraseVGI4(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase5"
                      label="Opción 5"
                      fullWidth
                      variant="standard"
                      value={fraseVGI5 ? fraseVGI5 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFraseVGI5(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase6"
                      label="Opción 6"
                      fullWidth
                      variant="standard"
                      value={fraseVGI6 ? fraseVGI6 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFraseVGI6(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase7"
                      label="Opción 7"
                      fullWidth
                      variant="standard"
                      value={fraseVGI7 ? fraseVGI7 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFraseVGI7(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase8"
                      label="Opción 8"
                      fullWidth
                      variant="standard"
                      value={fraseVGI8 ? fraseVGI8 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFraseVGI8(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderFrasesEvolucion()}
                visible={dialogFrasesEvolucion}
                //maximized={true}
                //maximizable={true}
                closable={true}
                onHide={onHideFrasesEvolucion}
                footer={renderFooterFrasesEvolucion()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase1"
                      label="Opción 1"
                      fullWidth
                      variant="standard"
                      value={frase1 ? frase1 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase1(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase2"
                      label="Opción 2"
                      fullWidth
                      variant="standard"
                      value={frase2 ? frase2 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase2(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase3"
                      label="Opción 3"
                      fullWidth
                      variant="standard"
                      value={frase3 ? frase3 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase3(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase4"
                      label="Opción 4"
                      fullWidth
                      variant="standard"
                      value={frase4 ? frase4 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase4(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase5"
                      label="Opción 5"
                      fullWidth
                      variant="standard"
                      value={frase5 ? frase5 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase5(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase6"
                      label="Opción 6"
                      fullWidth
                      variant="standard"
                      value={frase6 ? frase6 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase6(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase7"
                      label="Opción 7"
                      fullWidth
                      variant="standard"
                      value={frase7 ? frase7 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase7(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="frase8"
                      label="Opción 8"
                      fullWidth
                      variant="standard"
                      value={frase8 ? frase8 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase8(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}
