//imports front
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { AuthContext } from "../../context/AuthContext";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import * as EmailValidator from "email-validator";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Spinner from "../layout/Spinner";
import { Navigate, useNavigate } from "react-router-dom";

const theme = createTheme();

export default function Account(props) {
  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado, registrarUsuario } = authContext;

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const serviciosContext = useContext(ServiciosContext);
  const { statusAcceso, obtenerAcceso } = serviciosContext;

  const [usuario, guardarUsuario] = useState({
    nombre: "",
    apellido: "",
    email: "",
    password: "",
    password2: "",
  });

  const [redirect, setRedirect] = useState(false);

  let navigate = useNavigate();

  //const configuracionContext = useContext(ConfiguracionContext);
  //const { statusAcceso, obtenerAcceso } = configuracionContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Usuarios",
      });
    }
  }, [state]);

  // extraer de usuario
  const { nombre, apellido, email, password, password2 } = usuario;

  const crearCuenta = (e) => {
    e.preventDefault();
    //Validar

    if (nombre.trim() === "") {
      mensajeAlerta("Login", "El email es necesario !.", "error");
      return;
    }

    if (apellido.trim() === "") {
      mensajeAlerta("Login", "El apellido es necesario !.", "error");
      return;
    }

    if (email.trim() === "") {
      mensajeAlerta("Login", "El email es necesario !.", "error");
      return;
    }
    if (!EmailValidator.validate(email)) {
      mensajeAlerta("Login", "El email es incorrecto !.", "error");
      return;
    }

    if (password.trim() === "") {
      mensajeAlerta("Login", "El password es incorrecto !.", "error");
      return;
    }

    if (password.length < 6) {
      mensajeAlerta(
        "Login",
        "El password debe tener al menos 6 caracteres !.",
        "error"
      );
      return;
    }

    if (password2 != password) {
      mensajeAlerta("Login", "Los password son diferentes !.", "error");
      return;
    }

    // Llamar al context
    registrarUsuario({ nombre, apellido, email, password });
    setRedirect(true);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Crear Cuenta",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const exitClick = (e) => {
    navigate("/");
  };

  //console.log(statusAcceso)

  return (
    <Fragment>
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Crear Cuenta
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        autoComplete="nombre"
                        name="nombre"
                        variant="outlined"
                        required
                        fullWidth
                        id="nombre"
                        label="Nombre"
                        autoFocus
                        value={nombre}
                        onChange={(e) =>
                          guardarUsuario({
                            ...usuario,
                            nombre: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="apellido"
                        label="Apellido"
                        name="apellido"
                        autoComplete="apellido"
                        value={apellido}
                        onChange={(e) =>
                          guardarUsuario({
                            ...usuario,
                            apellido: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Dirección de Email"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) =>
                          guardarUsuario({
                            ...usuario,
                            email: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) =>
                          guardarUsuario({
                            ...usuario,
                            password: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password2"
                        label="Repetir password"
                        type="password"
                        id="password2"
                        autoComplete="current-password"
                        value={password2}
                        onChange={(e) =>
                          guardarUsuario({
                            ...usuario,
                            password2: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} />
                  </Grid>
                  <Button
                    type="button"
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={crearCuenta}
                  >
                    Crear Cuenta
                  </Button>
                </Box>
              </Box>
              {redirect ? <Navigate to="/" /> : null}
            </Container>
          </ThemeProvider>
        </Fragment>
      ) : null}
    </Fragment>
  );
}
