import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import ChequeoBanos from "./ChequeoBanos";
import ChequeoHabitaciones from "./ChequeoHabitaciones";
import ChequeoEspacios from "./ChequeoEspacios";
import ChequeoCocinas from "./ChequeoCocinas";
import ChequeoEnfermerias from "./ChequeoEnfermerias";
import ChequeoOficinas from "./ChequeoOficinas";
import ChequeoLavaderos from "./ChequeoLavaderos";
import ChequeoElementos from "./ChequeoElementos";
import ChequeoCumplimientos from "./ChequeoCumplimientos";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

export default function Chequeos(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [chequeo, guardarChequeo] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const [exit, setExit] = useState(false);
  let navigate = useNavigate();
  const [visibleDatos, setVisibleDatos] = useState(false);

  const steps = [
    "Baños",
    "Habitaciones",
    "Espacios en Común",
    "Cocina",
    "Enfermería",
    "Oficina",
    "Lavadero",
    "Control de Elementos",
    "Cumplimientos de seguridad e higiene",
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    chequeos,
    geriatricoElegido,
    chequeoBanos,
    chequeoHabitaciones,
    chequeoEspacios,
    chequeoCocinas,
    chequeoEnfermerias,
    chequeoOficinas,
    chequeoLavaderos,
    chequeoElementos,
    chequeoCumplimientos,
    obtenerChequeos,
    obtenerAcceso,
    agregarChequeo,
    actualizarChequeo,
    obtenerChequeo,
    setGeriatricoElegido,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Chequeos",
      });
    }
  }, [state]);

  useEffect(() => {
    obtenerChequeos({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    });
  }, []);

  const editClick = (e) => {
    if (selectedRow) {
      obtenerChequeo({ id: selectedRow.id });
      guardarChequeo(selectedRow);
      setVisibleDatos(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una chequeo.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //props.history.push(`/chequeo/0`);
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = yyyy + "-" + mm + "-" + dd;

    setVisible(true);
    setSelectedRow(null);
    guardarChequeo({
      fecha: formattedToday,
      geriatricoId: Number(geriatricoElegido.id),
    });
  };

  const imprimirClick = (e) => {
    if (selectedRow) {
      obtenerChequeo({ id: selectedRow.id });
      toast.current.show({
        severity: "info",
        summary: "Imprimir",
        detail: "Obteniendo datos para imprimir.",
        life: 3000,
      });
      setTimeout(() => {
        var elem;
        elem = document.getElementById("imprimirClick");
        elem.click();
      }, 5000);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Imprimir",
        detail: "Debe seleccionar un chequeo.",
        life: 3000,
      });
    }
  };

  const acceptImprimir = () => {
    const doc = new jsPDF();

    let hoy = new Date();

    let fechaDia = hoy.getDate();
    let fechaMes = hoy.getMonth() + 1;
    let fechaAnio = hoy.getFullYear();
    let nombreMes = "";

    if (fechaMes === 1) {
      nombreMes = "Enero";
    }
    if (fechaMes === 2) {
      nombreMes = "Febrero";
    }
    if (fechaMes === 3) {
      nombreMes = "Marzo";
    }
    if (fechaMes === 4) {
      nombreMes = "Abril";
    }
    if (fechaMes === 5) {
      nombreMes = "Mayo";
    }
    if (fechaMes === 6) {
      nombreMes = "Junio";
    }
    if (fechaMes === 7) {
      nombreMes = "Julio";
    }
    if (fechaMes === 8) {
      nombreMes = "Agosto";
    }
    if (fechaMes === 9) {
      nombreMes = "Septiembre";
    }
    if (fechaMes === 10) {
      nombreMes = "Octubre";
    }
    if (fechaMes === 11) {
      nombreMes = "Noviembre";
    }
    if (fechaMes === 12) {
      nombreMes = "Diciembre";
    }

    let row = 10;
    doc.setFontSize(10);
    doc.text("1", 200, row);
    row = 10;
    doc.setFontSize(11);
    let titulo = "CHEQUEO PERIODICO OBLIGATORIO.";
    doc.text(titulo, 70, row);
    let textWidth = doc.getTextWidth(titulo);
    doc.line(70, row + 2, 70 + textWidth + 1, row + 2);
    doc.setFontSize(10);
    row = 20;

    doc.text(`Diario, semanal, mensual, semestral.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });


    doc.text(`Fecha: ${formatDate2(selectedRow.fecha)}`, 170, row, {
      maxWidth: 160,
      align: "justify",
    });


    row = row + 10;

    doc.text(`OBJETIVO HOGAR PARA ADULTOS MAYORES.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });
    row = row + 10;

    doc.text(
      `La adecuada operación del establecimiento de salud y la prestación de los servicios en condiciones de calidad, seguridad, higiene y confort a los pacientes, visitantes y personal requiere que los responsables de la gestión del establecimiento y el personal prioricen la implementación de estrategias y actividades, para el mantenimiento de la infraestructura física, instalaciones, equipo y mobiliario del establecimiento. Con la finalidad de facilitar la organización y ejecución del mantenimiento en el establecimiento, contribuimos a generar una cultura de mantenimiento, como parte de la gestión moderna y eficiente de los servicios, orientada a mejorar la calidad e incrementar la seguridad en el funcionamiento del mismo. RECORDANDO QUE LA CAPACITACIÓN (NO ES UNO DE LOS CAMINOS) ES EL ÚNICO CAMINO.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );
    row = row + 40;

    doc.setFontSize(11);
    titulo = "MANTENIMIENTO EDILICIO";
    doc.text(titulo, 80, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(80, row + 2, 80 + textWidth + 1, row + 2);
    doc.setFontSize(10);
    row = row + 10;
    doc.setFont(undefined, "normal");
    doc.text("BAÑOS", 20, row, "left", "middle");
    row = row + 10;

    doc.text(
      `Puerta en buen estado, que cierre correctamente, completa con 3 bisagras y picaporte de los 2 lados estilo sanatorial.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 15;
    doc.text(`Cartel indicador sanitario.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Cartel indicador de aptos discapacitados.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(
      `Funcionamiento de los dispenser de jabón en buen estado y cargado.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(
      `Dispenser de toallas deberán estar en buen estado, fijas a la pared y cargadas con papel.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(
      `Dispenser de papel deberán estar en buen estado, fijas a la pared y cargadas con papel.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(
      `Espejo pintado, en buen estado, colocado a la altura correspondiente.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(`Tiene inclinación para discapacitados.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(
      `Iluminación automática funcionando, completa con lámpara y plafón.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(`Tachos de basura en buen estado con tapa.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Ventilación natural con rejilla limpia, mosquitero.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Ventilación forzada. funcionando.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Teclas de luz con módulo completo y funcionando.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(
      `Rejilla de piso en buenas condiciones bien amuradas. A nivel del piso.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(`Perchero.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Llamador funcionando.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Agarraderas.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Barandas con tranquera firme y pintada.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(
      `Lavatorio: firme, adherido a la pared a la altura correspondiente.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );


    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("2", 200, row);
    row = 20;
    doc.setFont(undefined, "normal");

    doc.text(`Sin columna de apoyo.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Flexibles sin pérdida.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Desagüe sin pérdidas ni roturas.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(
      `Botón de descargas sin pérdida, funcionando correctamente.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(
      `Mochilas con descargas sin pérdida, o válvula completa funcionando correctamente.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(
      `Inodoro: blanco, firme adherido al piso y estéticamente aceptable.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(`Tapa inodoro completa, firme, limpia.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Ducha con volante completo.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Duchador completo.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Canilla funcionando con agua caliente y fría.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Con volante completa.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Silla para bañar en buen estado limpia.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Suministro de agua continuo.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Aparición de humedades.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Pintura en techos, paredes y barandas.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Limpieza y orden.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(
      `Planilla indicadora de turnos de limpieza firmados por horarios.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(`Cartel indicador de no arrojar papeles al inodoro.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Cartel indicador de agua no potable.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Cartel indicador de conserve el orden y la limpieza.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Cartel indicador de lávese las manos.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(
      `No hay elementos sobrantes que no pertenezcan al área. Secador de piso. Balde. Trapos.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(
      `No hay cables a la vista o cajas de electricidad abiertas.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(
      `Paredes sucias. Artefactos. Agarraderas. techos o pisos.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("3", 200, row);
    doc.setFont(undefined, "normal");
    row = row + 10;

    var columns = ["Baño Nº", "Condición", "Comentario/Desvío"];
    var data = [
      [
        chequeoBanos.banoNum1 !== null ? chequeoBanos.banoNum1 : " ",
        chequeoBanos.banoCondicion1 !== null
          ? chequeoBanos.banoCondicion1
          : " ",
        chequeoBanos.banoComentario1 !== null
          ? chequeoBanos.banoComentario1
          : " ",
      ],
      [
        chequeoBanos.banoNum2 !== null ? chequeoBanos.banoNum2 : " ",
        chequeoBanos.banoCondicion2 !== null
          ? chequeoBanos.banoCondicion2
          : " ",
        chequeoBanos.banoComentario2 !== null
          ? chequeoBanos.banoComentario2
          : " ",
      ],
      [
        chequeoBanos.banoNum3 !== null ? chequeoBanos.banoNum3 : " ",
        chequeoBanos.banoCondicion3 !== null
          ? chequeoBanos.banoCondicion3
          : " ",
        chequeoBanos.banoComentario3 !== null
          ? chequeoBanos.banoComentario3
          : " ",
      ],
      [
        chequeoBanos.banoNum4 !== null ? chequeoBanos.banoNum4 : " ",
        chequeoBanos.banoCondicion4 !== null
          ? chequeoBanos.banoCondicion4
          : " ",
        chequeoBanos.banoComentario4 !== null
          ? chequeoBanos.banoComentario4
          : " ",
      ],
      [
        chequeoBanos.banoNum5 !== null ? chequeoBanos.banoNum5 : " ",
        chequeoBanos.banoCondicion5 !== null
          ? chequeoBanos.banoCondicion5
          : " ",
        chequeoBanos.banoComentario5 !== null
          ? chequeoBanos.banoComentario5
          : " ",
      ],
      [
        chequeoBanos.banoNum6 !== null ? chequeoBanos.banoNum6 : " ",
        chequeoBanos.banoCondicion6 !== null
          ? chequeoBanos.banoCondicion6
          : " ",
        chequeoBanos.banoComentario6 !== null
          ? chequeoBanos.banoComentario6
          : " ",
      ],
      [
        chequeoBanos.banoNum7 !== null ? chequeoBanos.banoNum7 : " ",
        chequeoBanos.banoCondicion7 !== null
          ? chequeoBanos.banoCondicion7
          : " ",
        chequeoBanos.banoComentario7 !== null
          ? chequeoBanos.banoComentario7
          : " ",
      ],
      [
        chequeoBanos.banoNum8 !== null ? chequeoBanos.banoNum8 : " ",
        chequeoBanos.banoCondicion8 !== null
          ? chequeoBanos.banoCondicion8
          : " ",
        chequeoBanos.banoComentario8 !== null
          ? chequeoBanos.banoComentario8
          : " ",
      ],
      [
        chequeoBanos.banoNum9 !== null ? chequeoBanos.banoNum9 : " ",
        chequeoBanos.banoCondicion9 !== null
          ? chequeoBanos.banoCondicion9
          : " ",
        chequeoBanos.banoComentario9 !== null
          ? chequeoBanos.banoComentario9
          : " ",
      ],
      [
        chequeoBanos.banoNum10 !== null ? chequeoBanos.banoNum10 : " ",
        chequeoBanos.banoCondicion10 !== null
          ? chequeoBanos.banoCondicion10
          : " ",
        chequeoBanos.banoComentario10 !== null
          ? chequeoBanos.banoComentario10
          : " ",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 20 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 50 },
        2: { cellWidth: 100 },
      },
    });

    let finalY = doc.previousAutoTable.finalY;

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("4", 200, row);
    row = 20;
    doc.setFont(undefined, "normal");

    doc.text("HABITACIONES", 20, row, "left", "middle");

    row = row + 10;

    doc.text(
      `Presenta indicación de número correlativo y factor ocupacional.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(
      `Puerta en buen estado, que cierre correctamente, completa con 3 bisagras y picaporte de los 2 lados estilo sanatorial.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 15;
    doc.text(
      `Mobiliario en buen estado, camas firmes, limpias, acordes al tamaño de la habitación.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(`Teclas de luz con módulo completo y funcionando.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(
      `Iluminación funcionando, completa con lámpara y plafón.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(`Llamadores.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Luz cabecera.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Luz de emergencia.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Ventiladores funcionando. Limpios.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Pisos enteros lavables sin fisuras ni grietas.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(
      `Mesa de luz completa limpia con tiradores y en condiciones de uso.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(
      `Armarios completos en condiciones de uso. placares enteros. Tienen las puertas, con cajones y manijas.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 15;
    doc.text(`Sillas de apoyo. Mesa.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Controlar aparición de humedades.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Pintura en techos y paredes.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(
      `Ventanas: con vidrio sano, protección al vacío, mosquiteros.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(`Cortinas. Limpias, enteras, con buen funcionamiento.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Decoración, ambientación, cuadros, espejos.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Temperatura acorde a la estación.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Limpieza y orden.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Hay cables a la vista, sueltos.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Enchufes y teclas de luz funcionando.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Sensor de humo.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Las camas están armadas para la hora del día.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Tienen sábanas, colcha. En buen estado.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("5", 200, row);
    row = 20;
    doc.setFont(undefined, "normal");

    doc.text(`Almohada funda.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Zalea.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Colchón en buen estado, alta densidad, con forro.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(`Colchón anti escaras funcionando, con motor.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });

    row = row + 10;
    doc.text(
      `Hay sillas de ruedas sin uso. Apoya pies sin uso. Rotos.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;
    doc.text(
      `Placares en condiciones ordenados. Tienen la ropa colgada, ordenada, tienen cajones.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );

    row = row + 10;

    var columns = ["Habitación Nº", "Ocupación", "Comentario/Desvío"];
    var data = [
      [
        chequeoHabitaciones.habitacionNum1 !== null
          ? chequeoHabitaciones.habitacionNum1
          : " ",
        chequeoHabitaciones.habitacionOcupacion1 !== null
          ? chequeoHabitaciones.habitacionOcupacion1
          : " ",
        chequeoHabitaciones.habitacionComentario1 !== null
          ? chequeoHabitaciones.habitacionComentario1
          : " ",
      ],
      [
        chequeoHabitaciones.habitacionNum2 !== null
          ? chequeoHabitaciones.habitacionNum2
          : " ",
        chequeoHabitaciones.habitacionOcupacion2 !== null
          ? chequeoHabitaciones.habitacionOcupacion2
          : " ",
        chequeoHabitaciones.habitacionComentario2 !== null
          ? chequeoHabitaciones.habitacionComentario2
          : " ",
      ],
      [
        chequeoHabitaciones.habitacionNum3 !== null
          ? chequeoHabitaciones.habitacionNum3
          : " ",
        chequeoHabitaciones.habitacionOcupacion3 !== null
          ? chequeoHabitaciones.habitacionOcupacion3
          : " ",
        chequeoHabitaciones.habitacionComentario3 !== null
          ? chequeoHabitaciones.habitacionComentario3
          : " ",
      ],
      [
        chequeoHabitaciones.habitacionNum4 !== null
          ? chequeoHabitaciones.habitacionNum4
          : " ",
        chequeoHabitaciones.habitacionOcupacion4 !== null
          ? chequeoHabitaciones.habitacionOcupacion4
          : " ",
        chequeoHabitaciones.habitacionComentario4 !== null
          ? chequeoHabitaciones.habitacionComentario4
          : " ",
      ],
      [
        chequeoHabitaciones.habitacionNum5 !== null
          ? chequeoHabitaciones.habitacionNum5
          : " ",
        chequeoHabitaciones.habitacionOcupacion5 !== null
          ? chequeoHabitaciones.habitacionOcupacion5
          : " ",
        chequeoHabitaciones.habitacionComentario5 !== null
          ? chequeoHabitaciones.habitacionComentario5
          : " ",
      ],
      [
        chequeoHabitaciones.habitacionNum6 !== null
          ? chequeoHabitaciones.habitacionNum6
          : " ",
        chequeoHabitaciones.habitacionOcupacion6 !== null
          ? chequeoHabitaciones.habitacionOcupacion6
          : " ",
        chequeoHabitaciones.habitacionComentario6 !== null
          ? chequeoHabitaciones.habitacionComentario6
          : " ",
      ],
      [
        chequeoHabitaciones.habitacionNum7 !== null
          ? chequeoHabitaciones.habitacionNum7
          : " ",
        chequeoHabitaciones.habitacionOcupacion7 !== null
          ? chequeoHabitaciones.habitacionOcupacion7
          : " ",
        chequeoHabitaciones.habitacionComentario7 !== null
          ? chequeoHabitaciones.habitacionComentario7
          : " ",
      ],
      [
        chequeoHabitaciones.habitacionNum8 !== null
          ? chequeoHabitaciones.habitacionNum8
          : " ",
        chequeoHabitaciones.habitacionOcupacion8 !== null
          ? chequeoHabitaciones.habitacionOcupacion8
          : " ",
        chequeoHabitaciones.habitacionComentario8 !== null
          ? chequeoHabitaciones.habitacionComentario8
          : " ",
      ],
      [
        chequeoHabitaciones.habitacionNum9 !== null
          ? chequeoHabitaciones.habitacionNum9
          : " ",
        chequeoHabitaciones.habitacionOcupacion9 !== null
          ? chequeoHabitaciones.habitacionOcupacion9
          : " ",
        chequeoHabitaciones.habitacionComentario9 !== null
          ? chequeoHabitaciones.habitacionComentario9
          : " ",
      ],
      [
        chequeoHabitaciones.habitacionNum10 !== null
          ? chequeoHabitaciones.habitacionNum10
          : " ",
        chequeoHabitaciones.habitacionOcupacion10 !== null
          ? chequeoHabitaciones.habitacionOcupacion10
          : " ",
        chequeoHabitaciones.habitacionComentario10 !== null
          ? chequeoHabitaciones.habitacionComentario10
          : " ",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 20 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 50 },
        2: { cellWidth: 100 },
      },
    });

    //finalY = doc.previousAutoTable.finalY;

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("6", 200, row);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.text("ESPACIOS EN COMUN", 20, row, "left", "middle");
    row = row + 10;

    var columns = ["", "", "Comentario/Desvío"];
    var data = [
      [
        "Entrada. Puerta. Limpia. Cerradura. Timbre.",
        chequeoEspacios.pregunta1 ? "SI" : "NO",
        chequeoEspacios.comentario1 !== null
          ? chequeoEspacios.comentario1
          : " ",
      ],
      [
        "Tiene placa registral.",
        chequeoEspacios.pregunta2 ? "SI" : "NO",
        chequeoEspacios.comentario2 !== null
          ? chequeoEspacios.comentario2
          : " ",
      ],
      [
        "Iluminación funcionando, completa con lámpara y plafón.",
        chequeoEspacios.pregunta3 ? "SI" : "NO",
        chequeoEspacios.comentario3 !== null
          ? chequeoEspacios.comentario3
          : " ",
      ],
      [
        "Pintura en techos y paredes.",
        chequeoEspacios.pregunta4 ? "SI" : "NO",
        chequeoEspacios.comentario4 !== null
          ? chequeoEspacios.comentario4
          : " ",
      ],
      [
        "Pasamanos firmes a la pared y tranqueras con traba.",
        chequeoEspacios.pregunta5 ? "SI" : "NO",
        chequeoEspacios.comentario5 !== null
          ? chequeoEspacios.comentario5
          : " ",
      ],
      [
        "Luz de emergencia.",
        chequeoEspacios.pregunta6 ? "SI" : "NO",
        chequeoEspacios.comentario6 !== null
          ? chequeoEspacios.comentario6
          : " ",
      ],
      [
        "Rampas de acceso con barandas a ambos lados.",
        chequeoEspacios.pregunta7 ? "SI" : "NO",
        chequeoEspacios.comentario7 !== null
          ? chequeoEspacios.comentario7
          : " ",
      ],
      [
        "Antideslizante. Señalización principio y fin.",
        chequeoEspacios.pregunta8 ? "SI" : "NO",
        chequeoEspacios.comentario8 !== null
          ? chequeoEspacios.comentario8
          : " ",
      ],
      [
        "Señalización de salida de emergencia.",
        chequeoEspacios.pregunta9 ? "SI" : "NO",
        chequeoEspacios.comentario9 !== null
          ? chequeoEspacios.comentario9
          : " ",
      ],
      [
        "Temperatura acorde a estación.",
        chequeoEspacios.pregunta10 ? "SI" : "NO",
        chequeoEspacios.comentario10 !== null
          ? chequeoEspacios.comentario10
          : " ",
      ],
      [
        "Teclas de luz con módulo completo y funcionando.",
        chequeoEspacios.pregunta11 ? "SI" : "NO",
        chequeoEspacios.comentario11 !== null
          ? chequeoEspacios.comentario11
          : " ",
      ],
      [
        "Mobiliario en buen estado mesas con regatones, firmes con la tapa atornillada.",
        chequeoEspacios.pregunta12 ? "SI" : "NO",
        chequeoEspacios.comentario12 !== null
          ? chequeoEspacios.comentario12
          : " ",
      ],
      [
        "Sillas con cuerina sana, con regatones.",
        chequeoEspacios.pregunta13 ? "SI" : "NO",
        chequeoEspacios.comentario13 !== null
          ? chequeoEspacios.comentario13
          : " ",
      ],
      [
        "Reloj control horario.",
        chequeoEspacios.pregunta14 ? "SI" : "NO",
        chequeoEspacios.comentario14 !== null
          ? chequeoEspacios.comentario14
          : " ",
      ],
      [
        "Libro de registro de visitas.",
        chequeoEspacios.pregunta15 ? "SI" : "NO",
        chequeoEspacios.comentario15 !== null
          ? chequeoEspacios.comentario15
          : " ",
      ],
      [
        "Libro de registro de personal.",
        chequeoEspacios.pregunta16 ? "SI" : "NO",
        chequeoEspacios.comentario16 !== null
          ? chequeoEspacios.comentario16
          : " ",
      ],
      [
        "Dispenser de alcohol.",
        chequeoEspacios.pregunta17 ? "SI" : "NO",
        chequeoEspacios.comentario17 !== null
          ? chequeoEspacios.comentario17
          : " ",
      ],
      [
        "Controlar aparición de humedades.",
        chequeoEspacios.pregunta18 ? "SI" : "NO",
        chequeoEspacios.comentario18 !== null
          ? chequeoEspacios.comentario18
          : " ",
      ],
      [
        "Carros de transporte con ruedas y en buen estado.",
        chequeoEspacios.pregunta19 ? "SI" : "NO",
        chequeoEspacios.comentario19 !== null
          ? chequeoEspacios.comentario19
          : " ",
      ],
      [
        "Controlar en buen funcionamiento tv, soportes y equipos de música.",
        chequeoEspacios.pregunta20 ? "SI" : "NO",
        chequeoEspacios.comentario20 !== null
          ? chequeoEspacios.comentario20
          : " ",
      ],
      [
        "Libro de recepción de mercaderias.",
        chequeoEspacios.pregunta21 ? "SI" : "NO",
        chequeoEspacios.comentario21 !== null
          ? chequeoEspacios.comentario21
          : " ",
      ],
      [
        "Control de temp. al acceso.",
        chequeoEspacios.pregunta22 ? "SI" : "NO",
        chequeoEspacios.comentario22 !== null
          ? chequeoEspacios.comentario22
          : " ",
      ],
      [
        "Cámaras de video.",
        chequeoEspacios.pregunta23 ? "SI" : "NO",
        chequeoEspacios.comentario23 !== null
          ? chequeoEspacios.comentario23
          : " ",
      ],
      [
        "Escalera. Escalones Antideslizante.",
        chequeoEspacios.pregunta24 ? "SI" : "NO",
        chequeoEspacios.comentario24 !== null
          ? chequeoEspacios.comentario24
          : " ",
      ],
      [
        "Barandas a ambos lados.",
        chequeoEspacios.pregunta25 ? "SI" : "NO",
        chequeoEspacios.comentario25 !== null
          ? chequeoEspacios.comentario25
          : " ",
      ],
      [
        "Señalizadas el primer y último escalón.",
        chequeoEspacios.pregunta26 ? "SI" : "NO",
        chequeoEspacios.comentario26 !== null
          ? chequeoEspacios.comentar6io26
          : " ",
      ],
      [
        "Cuadros.",
        chequeoEspacios.pregunta27 ? "SI" : "NO",
        chequeoEspacios.comentario27 !== null
          ? chequeoEspacios.comentario27
          : " ",
      ],
      [
        "Ambientación acorde.",
        chequeoEspacios.pregunta28 ? "SI" : "NO",
        chequeoEspacios.comentario28 !== null
          ? chequeoEspacios.comentario28
          : " ",
      ],
      [
        "Reloj.",
        chequeoEspacios.pregunta29 ? "SI" : "NO",
        chequeoEspacios.comentario29 !== null
          ? chequeoEspacios.comentario29
          : " ",
      ],
      [
        "Espejos.",
        chequeoEspacios.pregunta30 ? "SI" : "NO",
        chequeoEspacios.comentario30 !== null
          ? chequeoEspacios.comentario30
          : " ",
      ],
      [
        "Almanaque.",
        chequeoEspacios.pregunta31 ? "SI" : "NO",
        chequeoEspacios.comentario31 !== null
          ? chequeoEspacios.comentario31
          : " ",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 20 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 10 },
        2: { cellWidth: 70 },
      },
    });

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("7", 200, row);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.text("ESPACIOS EN COMUN", 20, row, "left", "middle");
    row = row + 10;

    var columns = ["", "", "Comentario/Desvío"];
    var data = [
      [
        "Radio.",
        chequeoEspacios.pregunta32 ? "SI" : "NO",
        chequeoEspacios.comentario32 !== null
          ? chequeoEspacios.comentario32
          : " ",
      ],
      [
        "TV con dispositivo para internet.",
        chequeoEspacios.pregunta33 ? "SI" : "NO",
        chequeoEspacios.comentario33 !== null
          ? chequeoEspacios.comentario33
          : " ",
      ],
      [
        "Dispenser para agua caliente y fría.",
        chequeoEspacios.pregunta34 ? "SI" : "NO",
        chequeoEspacios.comentario34 !== null
          ? chequeoEspacios.comentario34
          : " ",
      ],
      [
        "Ventanas con vidrios sanos funcionando con mosquiteros.",
        chequeoEspacios.pregunta35 ? "SI" : "NO",
        chequeoEspacios.comentario35 !== null
          ? chequeoEspacios.comentario35
          : " ",
      ],
      [
        "Ventanas con cortinas en condiciones.",
        chequeoEspacios.pregunta36 ? "SI" : "NO",
        chequeoEspacios.comentario36 !== null
          ? chequeoEspacios.comentario36
          : " ",
      ],
      [
        "Puertas con picaportes.",
        chequeoEspacios.pregunta37 ? "SI" : "NO",
        chequeoEspacios.comentario37 !== null
          ? chequeoEspacios.comentario37
          : " ",
      ],
      [
        "Ventilador.",
        chequeoEspacios.pregunta38 ? "SI" : "NO",
        chequeoEspacios.comentario38 !== null
          ? chequeoEspacios.comentario38
          : " ",
      ],
      [
        "Luz de emergencia.",
        chequeoEspacios.pregunta39 ? "SI" : "NO",
        chequeoEspacios.comentario39 !== null
          ? chequeoEspacios.comentario39
          : " ",
      ],
      [
        "Patios limpios.",
        chequeoEspacios.pregunta40 ? "SI" : "NO",
        chequeoEspacios.comentario40 !== null
          ? chequeoEspacios.comentario40
          : " ",
      ],
      [
        "Plantas. Mantenimiento, órden.",
        chequeoEspacios.pregunta41 ? "SI" : "NO",
        chequeoEspacios.comentario41 !== null
          ? chequeoEspacios.comentario41
          : " ",
      ],
      [
        "Salidas de emergencia. Demarcadas.",
        chequeoEspacios.pregunta42 ? "SI" : "NO",
        chequeoEspacios.comentario42 !== null
          ? chequeoEspacios.comentario42
          : " ",
      ],
      [
        "Barral antipánico.",
        chequeoEspacios.pregunta43 ? "SI" : "NO",
        chequeoEspacios.comentario43 !== null
          ? chequeoEspacios.comentario43
          : " ",
      ],
      [
        "Lugar para visitas.",
        chequeoEspacios.pregunta44 ? "SI" : "NO",
        chequeoEspacios.comentario44 !== null
          ? chequeoEspacios.comentario44
          : " ",
      ],
      [
        "Lugar para reuniones.",
        chequeoEspacios.pregunta45 ? "SI" : "NO",
        chequeoEspacios.comentario45 !== null
          ? chequeoEspacios.comentario45
          : " ",
      ],
      [
        "Depósito de limpieza limpio y ordenado.",
        chequeoEspacios.pregunta46 ? "SI" : "NO",
        chequeoEspacios.comentario46 !== null
          ? chequeoEspacios.comentario46
          : " ",
      ],
      [
        "Hay objetos que no corresponden al sitio.",
        chequeoEspacios.pregunta47 ? "SI" : "NO",
        chequeoEspacios.comentario47 !== null
          ? chequeoEspacios.comentario47
          : " ",
      ],
      [
        "Calefacción.",
        chequeoEspacios.pregunta48 ? "SI" : "NO",
        chequeoEspacios.comentario48 !== null
          ? chequeoEspacios.comentario48
          : " ",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 20 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 10 },
        2: { cellWidth: 70 },
      },
    });

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("8", 200, row);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.text("COCINA", 20, row, "left", "middle");
    row = row + 10;

    var columns = ["", "", "Comentario/Desvío"];
    var data = [
      [
        "Controlar funcionamiento de las heladeras.",
        chequeoCocinas.pregunta1 ? "SI" : "NO",
        chequeoCocinas.comentario1 !== null ? chequeoCocinas.comentario1 : " ",
      ],
      [
        "Heladera para cocidos.",
        chequeoCocinas.pregunta2 ? "SI" : "NO",
        chequeoCocinas.comentario2 !== null ? chequeoCocinas.comentario2 : " ",
      ],
      [
        "Heladera para alimentos de la calle.",
        chequeoCocinas.pregunta3 ? "SI" : "NO",
        chequeoCocinas.comentario3 !== null ? chequeoCocinas.comentario3 : " ",
      ],
      [
        "Heladera para postres.",
        chequeoCocinas.pregunta4 ? "SI" : "NO",
        chequeoCocinas.comentario4 !== null ? chequeoCocinas.comentario4 : " ",
      ],
      [
        "Heladera para crudos.",
        chequeoCocinas.pregunta5 ? "SI" : "NO",
        chequeoCocinas.comentario5 !== null ? chequeoCocinas.comentario5 : " ",
      ],
      [
        "Cocina, hornallas.",
        chequeoCocinas.pregunta6 ? "SI" : "NO",
        chequeoCocinas.comentario6 !== null ? chequeoCocinas.comentario6 : " ",
      ],
      [
        "Horno tiene válvula de seguridad.",
        chequeoCocinas.pregunta7 ? "SI" : "NO",
        chequeoCocinas.comentario7 !== null ? chequeoCocinas.comentario7 : " ",
      ],
      [
        "Extractor de aire.",
        chequeoCocinas.pregunta8 ? "SI" : "NO",
        chequeoCocinas.comentario8 !== null ? chequeoCocinas.comentario8 : " ",
      ],
      [
        "Campana de humo y olores con extractor forzado funcionando y limpio.",
        chequeoCocinas.pregunta9 ? "SI" : "NO",
        chequeoCocinas.comentario9 !== null ? chequeoCocinas.comentario9 : " ",
      ],
      [
        "Detector de gas.",
        chequeoCocinas.pregunta10 ? "SI" : "NO",
        chequeoCocinas.comentario10 !== null
          ? chequeoCocinas.comentario10
          : " ",
      ],
      [
        "Detector de humo.",
        chequeoCocinas.pregunta11 ? "SI" : "NO",
        chequeoCocinas.comentario11 !== null
          ? chequeoCocinas.comentario11
          : " ",
      ],
      [
        "Corte Gral. de gas, automático manual.",
        chequeoCocinas.pregunta12 ? "SI" : "NO",
        chequeoCocinas.comentario12 !== null
          ? chequeoCocinas.comentario12
          : " ",
      ],
      [
        "Teclas de luz con módulo completo y funcionando.",
        chequeoCocinas.pregunta13 ? "SI" : "NO",
        chequeoCocinas.comentario13 !== null
          ? chequeoCocinas.comentario13
          : " ",
      ],
      [
        "Iluminación funcionando, completa con lámpara y plafón.",
        chequeoCocinas.pregunta14 ? "SI" : "NO",
        chequeoCocinas.comentario14 !== null
          ? chequeoCocinas.comentario14
          : " ",
      ],
      [
        "Atrapa moscas limpias y funcionando.",
        chequeoCocinas.pregunta15 ? "SI" : "NO",
        chequeoCocinas.comentario15 !== null
          ? chequeoCocinas.comentario15
          : " ",
      ],
      [
        "Control y desinfección de insectos. Última fecha.",
        chequeoCocinas.pregunta16 ? "SI" : "NO",
        chequeoCocinas.comentario16 !== null
          ? chequeoCocinas.comentario16
          : " ",
      ],
      [
        "Utiliza protocolo de limpieza.",
        chequeoCocinas.pregunta17 ? "SI" : "NO",
        chequeoCocinas.comentario17 !== null
          ? chequeoCocinas.comentario17
          : " ",
      ],
      [
        "Mesadas de trabajo.",
        chequeoCocinas.pregunta18 ? "SI" : "NO",
        chequeoCocinas.comentario18 !== null
          ? chequeoCocinas.comentario18
          : " ",
      ],
      [
        "Bacha profunda firmes con canilla funcionando.",
        chequeoCocinas.pregunta19 ? "SI" : "NO",
        chequeoCocinas.comentario19 !== null
          ? chequeoCocinas.comentario19
          : " ",
      ],
      [
        "Agua caliente y fría de suministro continuo.",
        chequeoCocinas.pregunta20 ? "SI" : "NO",
        chequeoCocinas.comentario20 !== null
          ? chequeoCocinas.comentario20
          : " ",
      ],
      [
        "Controlar la aparición de humedades.",
        chequeoCocinas.pregunta21 ? "SI" : "NO",
        chequeoCocinas.comentario21 !== null
          ? chequeoCocinas.comentario21
          : " ",
      ],
      [
        "Procesadora de alimentos.",
        chequeoCocinas.pregunta22 ? "SI" : "NO",
        chequeoCocinas.comentario22 !== null
          ? chequeoCocinas.comentario22
          : " ",
      ],
      [
        "Licuadora.",
        chequeoCocinas.pregunta23 ? "SI" : "NO",
        chequeoCocinas.comentario23 !== null
          ? chequeoCocinas.comentario23
          : " ",
      ],
      [
        "Batidora.",
        chequeoCocinas.pregunta24 ? "SI" : "NO",
        chequeoCocinas.comentario24 !== null
          ? chequeoCocinas.comentario24
          : " ",
      ],
      [
        "Balanza.",
        chequeoCocinas.pregunta25 ? "SI" : "NO",
        chequeoCocinas.comentario25 !== null
          ? chequeoCocinas.comentario25
          : " ",
      ],
      [
        "Cortador de fiambre.",
        chequeoCocinas.pregunta26 ? "SI" : "NO",
        chequeoCocinas.comentario26 !== null
          ? chequeoCocinas.comentar6io26
          : " ",
      ],
      [
        "Picadora de carne.",
        chequeoCocinas.pregunta61 ? "SI" : "NO",
        chequeoCocinas.comentario61 !== null
          ? chequeoCocinas.comentario61
          : " ",
      ],
      [
        "Freezer en cantidad limpios funcionando.",
        chequeoCocinas.pregunta27 ? "SI" : "NO",
        chequeoCocinas.comentario27 !== null
          ? chequeoCocinas.comentario27
          : " ",
      ],
      [
        "Congelados fechados.",
        chequeoCocinas.pregunta28 ? "SI" : "NO",
        chequeoCocinas.comentario28 !== null
          ? chequeoCocinas.comentario28
          : " ",
      ],
      [
        "Congelados menú alternativo una población.",
        chequeoCocinas.pregunta29 ? "SI" : "NO",
        chequeoCocinas.comentario29 !== null
          ? chequeoCocinas.comentario29
          : " ",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 20 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 10 },
        2: { cellWidth: 70 },
      },
    });

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("9", 200, row);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.text("COCINA", 20, row, "left", "middle");
    row = row + 10;

    var columns = ["", "", "Comentario/Desvío"];
    var data = [
      [
        "Stock carne 35 kg.",
        chequeoCocinas.pregunta30 ? "SI" : "NO",
        chequeoCocinas.comentario30 !== null
          ? chequeoCocinas.comentario30
          : " ",
      ],
      [
        "Stock pollo 35 kg.",
        chequeoCocinas.pregunta31 ? "SI" : "NO",
        chequeoCocinas.comentario31 !== null
          ? chequeoCocinas.comentario31
          : " ",
      ],
      [
        "Stock pescado 10 kg.",
        chequeoCocinas.pregunta32 ? "SI" : "NO",
        chequeoCocinas.comentario32 !== null
          ? chequeoCocinas.comentario32
          : " ",
      ],
      [
        "Stock congelados 35 kg.",
        chequeoCocinas.pregunta33 ? "SI" : "NO",
        chequeoCocinas.comentario33 !== null
          ? chequeoCocinas.comentario33
          : " ",
      ],
      [
        "Depósito alimentos secos completos, ordenados con estanterías. Sin alimentos apoyados en el piso.",
        chequeoCocinas.pregunta34 ? "SI" : "NO",
        chequeoCocinas.comentario34 !== null
          ? chequeoCocinas.comentario34
          : " ",
      ],
      [
        "Usa protocolo de ingreso de mercadería.",
        chequeoCocinas.pregunta35 ? "SI" : "NO",
        chequeoCocinas.comentario35 !== null
          ? chequeoCocinas.comentario35
          : " ",
      ],
      [
        "Depósito de verduras limpio ordenado con piso y pared lavable con puerta cerrada y ventilación con mosquitero.",
        chequeoCocinas.pregunta36 ? "SI" : "NO",
        chequeoCocinas.comentario36 !== null
          ? chequeoCocinas.comentario36
          : " ",
      ],
      [
        "Menú de dietas.",
        chequeoCocinas.pregunta37 ? "SI" : "NO",
        chequeoCocinas.comentario37 !== null
          ? chequeoCocinas.comentario37
          : " ",
      ],
      [
        "Menú del día en cartelera.",
        chequeoCocinas.pregunta38 ? "SI" : "NO",
        chequeoCocinas.comentario38 !== null
          ? chequeoCocinas.comentario38
          : " ",
      ],
      [
        "Carpeta de cocina.",
        chequeoCocinas.pregunta39 ? "SI" : "NO",
        chequeoCocinas.comentario39 !== null
          ? chequeoCocinas.comentario39
          : " ",
      ],
      [
        "Listado de residentes al día.",
        chequeoCocinas.pregunta40 ? "SI" : "NO",
        chequeoCocinas.comentario40 !== null
          ? chequeoCocinas.comentario40
          : " ",
      ],
      [
        "Menús de la semana.",
        chequeoCocinas.pregunta41 ? "SI" : "NO",
        chequeoCocinas.comentario41 !== null
          ? chequeoCocinas.comentario41
          : " ",
      ],
      [
        "Colaciones.",
        chequeoCocinas.pregunta42 ? "SI" : "NO",
        chequeoCocinas.comentario42 !== null
          ? chequeoCocinas.comentario42
          : " ",
      ],
      [
        "Vajilla, platos hondos, platos planos, platos de postre, tazas de 250 ml, vasos de 300 ml, cuchara, tenedor, cuchillo, cuchara de postre, paneras platos de café, jarras, bandejas.",
        chequeoCocinas.pregunta43 ? "SI" : "NO",
        chequeoCocinas.comentario43 !== null
          ? chequeoCocinas.comentario43
          : " ",
      ],
      [
        "Elementos de cocina, ollas con tapa, pizzeras, bandejas para horno, espumadera, cucharon, colador, cuchillas.",
        chequeoCocinas.pregunta44 ? "SI" : "NO",
        chequeoCocinas.comentario44 !== null
          ? chequeoCocinas.comentario44
          : " ",
      ],
      [
        "Cuchilla grande, pelapapas, pisa papas.",
        chequeoCocinas.pregunta45 ? "SI" : "NO",
        chequeoCocinas.comentario45 !== null
          ? chequeoCocinas.comentario45
          : " ",
      ],
      [
        "Pisos lavables Paredes lavables.",
        chequeoCocinas.pregunta46 ? "SI" : "NO",
        chequeoCocinas.comentario46 !== null
          ? chequeoCocinas.comentario46
          : " ",
      ],
      [
        "Mosquiteros en ventanas y puertas.",
        chequeoCocinas.pregunta47 ? "SI" : "NO",
        chequeoCocinas.comentario47 !== null
          ? chequeoCocinas.comentario47
          : " ",
      ],
      [
        "El personal controla el ingreso de mercadería, pesa y cuenta las unidades. Utiliza protocolo de ingreso mercadería.",
        chequeoCocinas.pregunta48 ? "SI" : "NO",
        chequeoCocinas.comentario48 !== null
          ? chequeoCocinas.comentario48
          : " ",
      ],
      [
        "Ventanas: con vidrio sano, con cierre en buen estado funcionando y mosquitero.",
        chequeoCocinas.pregunta49 ? "SI" : "NO",
        chequeoCocinas.comentario49 !== null
          ? chequeoCocinas.comentario49
          : " ",
      ],
      [
        "Cocina en Gral. Ventilada, iluminada, Limpia y ordenado.",
        chequeoCocinas.pregunta50 ? "SI" : "NO",
        chequeoCocinas.comentario50 !== null
          ? chequeoCocinas.comentario50
          : " ",
      ],
      [
        "El personal usa vestimenta adecuada, ambo, guantes, cofia, birrete, protectores. Empleados.",
        chequeoCocinas.pregunta51 ? "SI" : "NO",
        chequeoCocinas.comentario51 !== null
          ? chequeoCocinas.comentario51
          : " ",
      ],
      [
        "Bacha sin pérdida, desagüe sin pérdidas ni roturas.",
        chequeoCocinas.pregunta52 ? "SI" : "NO",
        chequeoCocinas.comentario52 !== null
          ? chequeoCocinas.comentario52
          : " ",
      ],
      [
        "Tablas para corte de varios colores.",
        chequeoCocinas.pregunta53 ? "SI" : "NO",
        chequeoCocinas.comentario53 !== null
          ? chequeoCocinas.comentario53
          : " ",
      ],
      [
        "Tiene porta planillas para pesos de mercadería.",
        chequeoCocinas.pregunta54 ? "SI" : "NO",
        chequeoCocinas.comentario54 !== null
          ? chequeoCocinas.comentario54
          : " ",
      ],
      [
        "Tachos de basura húmedos con tapa limpio.",
        chequeoCocinas.pregunta55 ? "SI" : "NO",
        chequeoCocinas.comentario55 !== null
          ? chequeoCocinas.comentario55
          : " ",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 20 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 10 },
        2: { cellWidth: 70 },
      },
    });

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("10", 200, row);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.text("COCINA", 20, row, "left", "middle");
    row = row + 10;

    var columns = ["", "", "Comentario/Desvío"];
    var data = [
      [
        "Tacho de basura secos con tapa en condiciones.",
        chequeoCocinas.pregunta56 ? "SI" : "NO",
        chequeoCocinas.comentario56 !== null
          ? chequeoCocinas.comentario56
          : " ",
      ],
      [
        "Utiliza recipientes para pan.",
        chequeoCocinas.pregunta57 ? "SI" : "NO",
        chequeoCocinas.comentario57 !== null
          ? chequeoCocinas.comentario57
          : " ",
      ],
      [
        "Utiliza recipientes para huevos.",
        chequeoCocinas.pregunta58 ? "SI" : "NO",
        chequeoCocinas.comentario58 !== null
          ? chequeoCocinas.comentario58
          : " ",
      ],
      [
        "Dispenser de jabón.",
        chequeoCocinas.pregunta59 ? "SI" : "NO",
        chequeoCocinas.comentario59 !== null
          ? chequeoCocinas.comentario59
          : " ",
      ],
      [
        "Dispenser de papel seca manos.",
        chequeoCocinas.pregunta60 ? "SI" : "NO",
        chequeoCocinas.comentario60 !== null
          ? chequeoCocinas.comentario60
          : " ",
      ],
      [
        "Carros de transporte de alimentos limpios.",
        chequeoCocinas.pregunta62 ? "SI" : "NO",
        chequeoCocinas.comentario62 !== null
          ? chequeoCocinas.comentario62
          : " ",
      ],
      [
        "Carro de transporte en condiciones.",
        chequeoCocinas.pregunta63 ? "SI" : "NO",
        chequeoCocinas.comentario63 !== null
          ? chequeoCocinas.comentario63
          : " ",
      ],
      [
        "Trasladar la comida en bandeja con tapa.",
        chequeoCocinas.pregunta64 ? "SI" : "NO",
        chequeoCocinas.comentario64 !== null
          ? chequeoCocinas.comentario64
          : " ",
      ],
      [
        "Existen elementos en desuso o inservibles.",
        chequeoCocinas.pregunta65 ? "SI" : "NO",
        chequeoCocinas.comentario65 !== null
          ? chequeoCocinas.comentario65
          : " ",
      ],
      [
        "Tiene estanterías.",
        chequeoCocinas.pregunta66 ? "SI" : "NO",
        chequeoCocinas.comentario66 !== null
          ? chequeoCocinas.comentario66
          : " ",
      ],
      [
        "Alimentos depositados en el piso.",
        chequeoCocinas.pregunta67 ? "SI" : "NO",
        chequeoCocinas.comentario67 !== null
          ? chequeoCocinas.comentario67
          : " ",
      ],
      [
        "La empresa capacita al personal.",
        chequeoCocinas.pregunta68 ? "SI" : "NO",
        chequeoCocinas.comentario68 !== null
          ? chequeoCocinas.comentario68
          : " ",
      ],
      [
        "Ayudante de cocina.",
        chequeoCocinas.pregunta69 ? "SI" : "NO",
        chequeoCocinas.comentario69 !== null
          ? chequeoCocinas.comentario69
          : " ",
      ],
      [
        "Hay listado de menú alternativo.",
        chequeoCocinas.pregunta70 ? "SI" : "NO",
        chequeoCocinas.comentario70 !== null
          ? chequeoCocinas.comentario70
          : " ",
      ],
      [
        "Hay manual del menú con fotos.",
        chequeoCocinas.pregunta71 ? "SI" : "NO",
        chequeoCocinas.comentario71 !== null
          ? chequeoCocinas.comentario71
          : " ",
      ],
      [
        "Hay stock para tres dias.",
        chequeoCocinas.pregunta72 ? "SI" : "NO",
        chequeoCocinas.comentario72 !== null
          ? chequeoCocinas.comentario72
          : " ",
      ],
      [
        "Existe mercadería en mal estado.",
        chequeoCocinas.pregunta73 ? "SI" : "NO",
        chequeoCocinas.comentario73 !== null
          ? chequeoCocinas.comentario73
          : " ",
      ],
      [
        "Tiene tuperware suficientes ?.",
        chequeoCocinas.pregunta74 ? "SI" : "NO",
        chequeoCocinas.comentario74 !== null
          ? chequeoCocinas.comentario74
          : " ",
      ],
      [
        "Paredes limpias, humedad, azulejada.",
        chequeoCocinas.pregunta75 ? "SI" : "NO",
        chequeoCocinas.comentario75 !== null
          ? chequeoCocinas.comentario75
          : " ",
      ],
      [
        "Hay mercadería apoyada en el piso.",
        chequeoCocinas.pregunta76 ? "SI" : "NO",
        chequeoCocinas.comentario76 !== null
          ? chequeoCocinas.comentario76
          : " ",
      ],
      [
        "Los estantes se encuentran limpios.",
        chequeoCocinas.pregunta77 ? "SI" : "NO",
        chequeoCocinas.comentario77 !== null
          ? chequeoCocinas.comentario77
          : " ",
      ],
      [
        "Realiza encuesta de pacientes.",
        chequeoCocinas.pregunta78 ? "SI" : "NO",
        chequeoCocinas.comentario78 !== null
          ? chequeoCocinas.comentario78
          : " ",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 20 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 10 },
        2: { cellWidth: 70 },
      },
    });

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("11", 200, row);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.text("ENFERMERIA", 20, row, "left", "middle");
    row = row + 10;

    var columns = ["", "", "Comentario/Desvío"];
    var data = [
      [
        "Mobiliario en buen estado.",
        chequeoEnfermerias.pregunta1 ? "SI" : "NO",
        chequeoEnfermerias.comentario1 !== null
          ? chequeoEnfermerias.comentario1
          : " ",
      ],
      [
        "Mesadas lavables firmes con canilla funcionando con agua caliente y fría con volante completa con flexibles sin pérdida, desagüe sin pérdidas ni roturas.",
        chequeoEnfermerias.pregunta2 ? "SI" : "NO",
        chequeoEnfermerias.comentario2 !== null
          ? chequeoEnfermerias.comentario2
          : " ",
      ],
      [
        "Suministro de agua continuo.",
        chequeoEnfermerias.pregunta3 ? "SI" : "NO",
        chequeoEnfermerias.comentario3 !== null
          ? chequeoEnfermerias.comentario3
          : " ",
      ],
      [
        "Buen funcionamiento de sillas de ruedas completas con gomas, freno, apoya pie.",
        chequeoEnfermerias.pregunta4 ? "SI" : "NO",
        chequeoEnfermerias.comentario4 !== null
          ? chequeoEnfermerias.comentario4
          : " ",
      ],
      [
        "La grúa para pacientes se completó con tela y funcionando.",
        chequeoEnfermerias.pregunta5 ? "SI" : "NO",
        chequeoEnfermerias.comentario5 !== null
          ? chequeoEnfermerias.comentario5
          : " ",
      ],
      [
        "Tubo de oxígeno con soporte firme, lleno con vías de administración y máscara.",
        chequeoEnfermerias.pregunta6 ? "SI" : "NO",
        chequeoEnfermerias.comentario6 !== null
          ? chequeoEnfermerias.comentario6
          : " ",
      ],
      [
        "Oxímetro.",
        chequeoEnfermerias.pregunta7 ? "SI" : "NO",
        chequeoEnfermerias.comentario7 !== null
          ? chequeoEnfermerias.comentario7
          : " ",
      ],
      [
        "Termómetro.",
        chequeoEnfermerias.pregunta8 ? "SI" : "NO",
        chequeoEnfermerias.comentario8 !== null
          ? chequeoEnfermerias.comentario8
          : " ",
      ],
      [
        "Tensiómetro bajalenguas.",
        chequeoEnfermerias.pregunta9 ? "SI" : "NO",
        chequeoEnfermerias.comentario9 !== null
          ? chequeoEnfermerias.comentario9
          : " ",
      ],
      [
        "Camilla.",
        chequeoEnfermerias.pregunta10 ? "SI" : "NO",
        chequeoEnfermerias.comentario10 !== null
          ? chequeoEnfermerias.comentario10
          : " ",
      ],
      [
        "Carro de curaciones.",
        chequeoEnfermerias.pregunta11 ? "SI" : "NO",
        chequeoEnfermerias.comentario11 !== null
          ? chequeoEnfermerias.comentario11
          : " ",
      ],
      [
        "Aspirador.",
        chequeoEnfermerias.pregunta12 ? "SI" : "NO",
        chequeoEnfermerias.comentario12 !== null
          ? chequeoEnfermerias.comentario12
          : " ",
      ],
      [
        "Nebulizador.",
        chequeoEnfermerias.pregunta13 ? "SI" : "NO",
        chequeoEnfermerias.comentario13 !== null
          ? chequeoEnfermerias.comentario13
          : " ",
      ],
      [
        "Bomba de infusión.",
        chequeoEnfermerias.pregunta14 ? "SI" : "NO",
        chequeoEnfermerias.comentario14 !== null
          ? chequeoEnfermerias.comentario14
          : " ",
      ],
      [
        "Lámpara.",
        chequeoEnfermerias.pregunta15 ? "SI" : "NO",
        chequeoEnfermerias.comentario15 !== null
          ? chequeoEnfermerias.comentario15
          : " ",
      ],
      [
        "Luz de emergencia.",
        chequeoEnfermerias.pregunta16 ? "SI" : "NO",
        chequeoEnfermerias.comentario16 !== null
          ? chequeoEnfermerias.comentario16
          : " ",
      ],
      [
        "Reloj.",
        chequeoEnfermerias.pregunta17 ? "SI" : "NO",
        chequeoEnfermerias.comentario17 !== null
          ? chequeoEnfermerias.comentario17
          : " ",
      ],
      [
        "Linterna.",
        chequeoEnfermerias.pregunta18 ? "SI" : "NO",
        chequeoEnfermerias.comentario18 !== null
          ? chequeoEnfermerias.comentario18
          : " ",
      ],
      [
        "Panel de visor funcionando.",
        chequeoEnfermerias.pregunta19 ? "SI" : "NO",
        chequeoEnfermerias.comentario19 !== null
          ? chequeoEnfermerias.comentario19
          : " ",
      ],
      [
        "Alarma de incendio.",
        chequeoEnfermerias.pregunta20 ? "SI" : "NO",
        chequeoEnfermerias.comentario20 !== null
          ? chequeoEnfermerias.comentario20
          : " ",
      ],
      [
        "Cesto de basura con tapa.",
        chequeoEnfermerias.pregunta21 ? "SI" : "NO",
        chequeoEnfermerias.comentario21 !== null
          ? chequeoEnfermerias.comentario21
          : " ",
      ],
      [
        "Heladera.",
        chequeoEnfermerias.pregunta22 ? "SI" : "NO",
        chequeoEnfermerias.comentario22 !== null
          ? chequeoEnfermerias.comentario22
          : " ",
      ],
      [
        "Balanza.",
        chequeoEnfermerias.pregunta23 ? "SI" : "NO",
        chequeoEnfermerias.comentario23 !== null
          ? chequeoEnfermerias.comentario23
          : " ",
      ],
      [
        "Iluminación funcionando, completa con lámpara y plafón.",
        chequeoEnfermerias.pregunta24 ? "SI" : "NO",
        chequeoEnfermerias.comentario24 !== null
          ? chequeoEnfermerias.comentario24
          : " ",
      ],
      [
        "Atrapamoscas.",
        chequeoEnfermerias.pregunta25 ? "SI" : "NO",
        chequeoEnfermerias.comentario25 !== null
          ? chequeoEnfermerias.comentario25
          : " ",
      ],
      [
        "Controlar la aparición de humedades.",
        chequeoEnfermerias.pregunta26 ? "SI" : "NO",
        chequeoEnfermerias.comentario26 !== null
          ? chequeoEnfermerias.comentar6io26
          : " ",
      ],
      [
        "Pintura de techos y paredes.",
        chequeoEnfermerias.pregunta27 ? "SI" : "NO",
        chequeoEnfermerias.comentario27 !== null
          ? chequeoEnfermerias.comentario27
          : " ",
      ],
      [
        "Orden y limpieza.",
        chequeoEnfermerias.pregunta28 ? "SI" : "NO",
        chequeoEnfermerias.comentario28 !== null
          ? chequeoEnfermerias.comentario28
          : " ",
      ],
      [
        "HC Completa (VIG).",
        chequeoEnfermerias.pregunta29 ? "SI" : "NO",
        chequeoEnfermerias.comentario29 !== null
          ? chequeoEnfermerias.comentario29
          : " ",
      ],
      [
        "Evaluación medica quincenal.",
        chequeoEnfermerias.pregunta30 ? "SI" : "NO",
        chequeoEnfermerias.comentario30 !== null
          ? chequeoEnfermerias.comentario30
          : " ",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 20 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 10 },
        2: { cellWidth: 70 },
      },
    });

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("12", 200, row);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.text("ENFERMERIA", 20, row, "left", "middle");
    row = row + 10;

    var columns = ["", "", "Comentario/Desvío"];
    var data = [
      [
        "Indicaciones médicas mensual.",
        chequeoEnfermerias.pregunta31 ? "SI" : "NO",
        chequeoEnfermerias.comentario31 !== null
          ? chequeoEnfermerias.comentario31
          : " ",
      ],
      [
        "Resumen médico semestrales.",
        chequeoEnfermerias.pregunta32 ? "SI" : "NO",
        chequeoEnfermerias.comentario32 !== null
          ? chequeoEnfermerias.comentario32
          : " ",
      ],
      [
        "Exámenes médicos anuales.",
        chequeoEnfermerias.pregunta33 ? "SI" : "NO",
        chequeoEnfermerias.comentario33 !== null
          ? chequeoEnfermerias.comentario33
          : " ",
      ],
      [
        "Exámenes periódicos, diabéticos, anticoagulados, renales, etc.",
        chequeoEnfermerias.pregunta34 ? "SI" : "NO",
        chequeoEnfermerias.comentario34 !== null
          ? chequeoEnfermerias.comentario34
          : " ",
      ],
      [
        "Interconsulta psiquiatría y evolución.",
        chequeoEnfermerias.pregunta35 ? "SI" : "NO",
        chequeoEnfermerias.comentario35 !== null
          ? chequeoEnfermerias.comentario35
          : " ",
      ],
      [
        "Interconsulta nutrición y evolución.",
        chequeoEnfermerias.pregunta36 ? "SI" : "NO",
        chequeoEnfermerias.comentario36 !== null
          ? chequeoEnfermerias.comentario36
          : " ",
      ],
      [
        "Evaluación de enfermería.",
        chequeoEnfermerias.pregunta37 ? "SI" : "NO",
        chequeoEnfermerias.comentario37 !== null
          ? chequeoEnfermerias.comentario37
          : " ",
      ],
      [
        "Indicaciones de enfermería.",
        chequeoEnfermerias.pregunta38 ? "SI" : "NO",
        chequeoEnfermerias.comentario38 !== null
          ? chequeoEnfermerias.comentario38
          : " ",
      ],
      [
        "Controles diarios de enfermería.",
        chequeoEnfermerias.pregunta39 ? "SI" : "NO",
        chequeoEnfermerias.comentario39 !== null
          ? chequeoEnfermerias.comentario39
          : " ",
      ],
      [
        "Controles de intercurrencias, caídas, upp, lesiones, escabiosis, etc.",
        chequeoEnfermerias.pregunta40 ? "SI" : "NO",
        chequeoEnfermerias.comentario40 !== null
          ? chequeoEnfermerias.comentario40
          : " ",
      ],
      [
        "Control de baños.",
        chequeoEnfermerias.pregunta41 ? "SI" : "NO",
        chequeoEnfermerias.comentario41 !== null
          ? chequeoEnfermerias.comentario41
          : " ",
      ],
      [
        "Control de entrega de pañales.",
        chequeoEnfermerias.pregunta42 ? "SI" : "NO",
        chequeoEnfermerias.comentario42 !== null
          ? chequeoEnfermerias.comentario42
          : " ",
      ],
      [
        "Pizarra actualizada.",
        chequeoEnfermerias.pregunta43 ? "SI" : "NO",
        chequeoEnfermerias.comentario43 !== null
          ? chequeoEnfermerias.comentario43
          : " ",
      ],
      [
        "Cuaderno de novedades.",
        chequeoEnfermerias.pregunta44 ? "SI" : "NO",
        chequeoEnfermerias.comentario44 !== null
          ? chequeoEnfermerias.comentario44
          : " ",
      ],
      [
        "Control y respuesta de tel. familiares.",
        chequeoEnfermerias.pregunta45 ? "SI" : "NO",
        chequeoEnfermerias.comentario45 !== null
          ? chequeoEnfermerias.comentario45
          : " ",
      ],
      [
        "Cartelería en buen estado.",
        chequeoEnfermerias.pregunta46 ? "SI" : "NO",
        chequeoEnfermerias.comentario46 !== null
          ? chequeoEnfermerias.comentario46
          : " ",
      ],
      [
        "Carpeta de capacitación.",
        chequeoEnfermerias.pregunta47 ? "SI" : "NO",
        chequeoEnfermerias.comentario47 !== null
          ? chequeoEnfermerias.comentario47
          : " ",
      ],
      [
        "Carpeta de nutricionista.",
        chequeoEnfermerias.pregunta48 ? "SI" : "NO",
        chequeoEnfermerias.comentario48 !== null
          ? chequeoEnfermerias.comentario48
          : " ",
      ],
      [
        "Registro de podología.",
        chequeoEnfermerias.pregunta49 ? "SI" : "NO",
        chequeoEnfermerias.comentario49 !== null
          ? chequeoEnfermerias.comentario49
          : " ",
      ],
      [
        "Registro de peluquería.",
        chequeoEnfermerias.pregunta50 ? "SI" : "NO",
        chequeoEnfermerias.comentario50 !== null
          ? chequeoEnfermerias.comentario50
          : " ",
      ],
      [
        "El personal usa vestimenta adecuada, ambo, guantes, cofia, birrete, protectores. Empleados.",
        chequeoEnfermerias.pregunta51 ? "SI" : "NO",
        chequeoEnfermerias.comentario51 !== null
          ? chequeoEnfermerias.comentario51
          : " ",
      ],
      [
        "Libro de internación a nivel seguimiento.",
        chequeoEnfermerias.pregunta52 ? "SI" : "NO",
        chequeoEnfermerias.comentario52 !== null
          ? chequeoEnfermerias.comentario52
          : " ",
      ],
      [
        "Vitrina. Con stock. Sin elementos vencidos.",
        chequeoEnfermerias.pregunta53 ? "SI" : "NO",
        chequeoEnfermerias.comentario53 !== null
          ? chequeoEnfermerias.comentario53
          : " ",
      ],
      [
        "Caja de paro. Con stock. Sin elementos vencidos.",
        chequeoEnfermerias.pregunta54 ? "SI" : "NO",
        chequeoEnfermerias.comentario54 !== null
          ? chequeoEnfermerias.comentario54
          : " ",
      ],
      [
        "Caja curaciones completa.",
        chequeoEnfermerias.pregunta55 ? "SI" : "NO",
        chequeoEnfermerias.comentario55 !== null
          ? chequeoEnfermerias.comentario55
          : " ",
      ],
      [
        "Descartador de agujas en condiciones.",
        chequeoEnfermerias.pregunta56 ? "SI" : "NO",
        chequeoEnfermerias.comentario56 !== null
          ? chequeoEnfermerias.comentario56
          : " ",
      ],
      [
        "Teclas de luz con módulo completo y funcionando.",
        chequeoEnfermerias.pregunta57 ? "SI" : "NO",
        chequeoEnfermerias.comentario57 !== null
          ? chequeoEnfermerias.comentario57
          : " ",
      ],
      [
        "Alacenas con llave.",
        chequeoEnfermerias.pregunta58 ? "SI" : "NO",
        chequeoEnfermerias.comentario58 !== null
          ? chequeoEnfermerias.comentario58
          : " ",
      ],
      [
        "Controlar funcionamiento de heladera y balanza.",
        chequeoEnfermerias.pregunta59 ? "SI" : "NO",
        chequeoEnfermerias.comentario59 !== null
          ? chequeoEnfermerias.comentario59
          : " ",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 20 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 10 },
        2: { cellWidth: 70 },
      },
    });

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("13", 200, row);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.text("OFICINA", 20, row, "left", "middle");
    row = row + 10;

    var columns = ["", "", "Comentario/Desvío"];
    var data = [
      [
        "Mobiliario de uso diario, escritorio, computadoras, teléfonos funcionando.",
        chequeoOficinas.pregunta1 ? "SI" : "NO",
        chequeoOficinas.comentario1 !== null
          ? chequeoOficinas.comentario1
          : " ",
      ],
      [
        "Cámaras y TV. Imágenes por teléfono, Wifi. Señal de internet.",
        chequeoOficinas.pregunta2 ? "SI" : "NO",
        chequeoOficinas.comentario2 !== null
          ? chequeoOficinas.comentario2
          : " ",
      ],
      [
        "Habilitación municipal.",
        chequeoOficinas.pregunta3 ? "SI" : "NO",
        chequeoOficinas.comentario3 !== null
          ? chequeoOficinas.comentario3
          : " ",
      ],
      [
        "Habilitación ministerio.",
        chequeoOficinas.pregunta4 ? "SI" : "NO",
        chequeoOficinas.comentario4 !== null
          ? chequeoOficinas.comentario4
          : " ",
      ],
      [
        "Libro de registro de inspecciones.",
        chequeoOficinas.pregunta5 ? "SI" : "NO",
        chequeoOficinas.comentario5 !== null
          ? chequeoOficinas.comentario5
          : " ",
      ],
      [
        "Plano de habilitación.",
        chequeoOficinas.pregunta6 ? "SI" : "NO",
        chequeoOficinas.comentario6 !== null
          ? chequeoOficinas.comentario6
          : " ",
      ],
      [
        "Certificado de limpieza de tanques de agua. Vencimiento.",
        chequeoOficinas.pregunta7 ? "SI" : "NO",
        chequeoOficinas.comentario7 !== null
          ? chequeoOficinas.comentario7
          : " ",
      ],
      [
        "Certificado bomberos. Vencimiento.",
        chequeoOficinas.pregunta8 ? "SI" : "NO",
        chequeoOficinas.comentario8 !== null
          ? chequeoOficinas.comentario8
          : " ",
      ],
      [
        "Planos de evacuación y simulacro de incendio.",
        chequeoOficinas.pregunta9 ? "SI" : "NO",
        chequeoOficinas.comentario9 !== null
          ? chequeoOficinas.comentario9
          : " ",
      ],
      [
        "Exhibe derechos de los residentes en lugar visible.",
        chequeoOficinas.pregunta10 ? "SI" : "NO",
        chequeoOficinas.comentario10 !== null
          ? chequeoOficinas.comentario10
          : " ",
      ],
      [
        "Inscripción ugcor.",
        chequeoOficinas.pregunta11 ? "SI" : "NO",
        chequeoOficinas.comentario11 !== null
          ? chequeoOficinas.comentario11
          : " ",
      ],
      [
        "Libretas sanitarias. Vencimiento.",
        chequeoOficinas.pregunta12 ? "SI" : "NO",
        chequeoOficinas.comentario12 !== null
          ? chequeoOficinas.comentario12
          : " ",
      ],
      [
        "Tarjetas de matafuegos. Vencimiento.",
        chequeoOficinas.pregunta13 ? "SI" : "NO",
        chequeoOficinas.comentario13 !== null
          ? chequeoOficinas.comentario13
          : " ",
      ],
      [
        "Libro de residentes actualizado última fecha.",
        chequeoOficinas.pregunta14 ? "SI" : "NO",
        chequeoOficinas.comentario14 !== null
          ? chequeoOficinas.comentario14
          : " ",
      ],
      [
        "Libro de visitas. Ultima fecha.",
        chequeoOficinas.pregunta15 ? "SI" : "NO",
        chequeoOficinas.comentario15 !== null
          ? chequeoOficinas.comentario15
          : " ",
      ],
      [
        "Certificado de desratización.",
        chequeoOficinas.pregunta16 ? "SI" : "NO",
        chequeoOficinas.comentario16 !== null
          ? chequeoOficinas.comentario16
          : " ",
      ],
      [
        "Chapa mural de frente. 30x40.",
        chequeoOficinas.pregunta17 ? "SI" : "NO",
        chequeoOficinas.comentario17 !== null
          ? chequeoOficinas.comentario17
          : " ",
      ],
      [
        "Inscripción en residuos patológicos.",
        chequeoOficinas.pregunta18 ? "SI" : "NO",
        chequeoOficinas.comentario18 !== null
          ? chequeoOficinas.comentario18
          : " ",
      ],
      [
        "Manifiesto de retiro residuos patológicos.",
        chequeoOficinas.pregunta19 ? "SI" : "NO",
        chequeoOficinas.comentario19 !== null
          ? chequeoOficinas.comentario19
          : " ",
      ],
      [
        "Certificado de incineración.",
        chequeoOficinas.pregunta20 ? "SI" : "NO",
        chequeoOficinas.comentario20 !== null
          ? chequeoOficinas.comentario20
          : " ",
      ],
      [
        "Teléfono de calle.",
        chequeoOficinas.pregunta21 ? "SI" : "NO",
        chequeoOficinas.comentario21 !== null
          ? chequeoOficinas.comentario21
          : " ",
      ],
      [
        "Teléfono celular.",
        chequeoOficinas.pregunta22 ? "SI" : "NO",
        chequeoOficinas.comentario22 !== null
          ? chequeoOficinas.comentario22
          : " ",
      ],
      [
        "Radios.",
        chequeoOficinas.pregunta23 ? "SI" : "NO",
        chequeoOficinas.comentario23 !== null
          ? chequeoOficinas.comentario23
          : " ",
      ],
      [
        "Impresora funcionando.",
        chequeoOficinas.pregunta24 ? "SI" : "NO",
        chequeoOficinas.comentario24 !== null
          ? chequeoOficinas.comentario24
          : " ",
      ],
      [
        "Cartuchos de repuesto.",
        chequeoOficinas.pregunta25 ? "SI" : "NO",
        chequeoOficinas.comentario25 !== null
          ? chequeoOficinas.comentario25
          : " ",
      ],
      [
        "Hojas de impresión.",
        chequeoOficinas.pregunta26 ? "SI" : "NO",
        chequeoOficinas.comentario26 !== null
          ? chequeoOficinas.comentar6io26
          : " ",
      ],
      [
        "Computadora, teclado, mouse, monitor funcionando con internet.",
        chequeoOficinas.pregunta27 ? "SI" : "NO",
        chequeoOficinas.comentario27 !== null
          ? chequeoOficinas.comentario27
          : " ",
      ],
      [
        "Mail de la institución de lectura diaria.",
        chequeoOficinas.pregunta28 ? "SI" : "NO",
        chequeoOficinas.comentario28 !== null
          ? chequeoOficinas.comentario28
          : " ",
      ],
      [
        "Seguros de responsabilidad civil.",
        chequeoOficinas.pregunta29 ? "SI" : "NO",
        chequeoOficinas.comentario29 !== null
          ? chequeoOficinas.comentario29
          : " ",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 20 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 10 },
        2: { cellWidth: 70 },
      },
    });

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("14", 200, row);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.text("OFICINA", 20, row, "left", "middle");
    row = row + 10;

    var columns = ["", "", "Comentario/Desvío"];
    var data = [
      [
        "Incendio.",
        chequeoOficinas.pregunta30 ? "SI" : "NO",
        chequeoOficinas.comentario30 !== null
          ? chequeoOficinas.comentario30
          : " ",
      ],
      [
        "Ascensores.",
        chequeoOficinas.pregunta31 ? "SI" : "NO",
        chequeoOficinas.comentario31 !== null
          ? chequeoOficinas.comentario31
          : " ",
      ],
      [
        "Mala praxis.",
        chequeoOficinas.pregunta32 ? "SI" : "NO",
        chequeoOficinas.comentario32 !== null
          ? chequeoOficinas.comentario32
          : " ",
      ],
      [
        "Listado completo y actualizado de residentes con contactos telefónicos, mail , lugares de cápita y médicos de cabecera.",
        chequeoOficinas.pregunta33 ? "SI" : "NO",
        chequeoOficinas.comentario33 !== null
          ? chequeoOficinas.comentario33
          : " ",
      ],
      [
        "Libro de ascensores actualizado.",
        chequeoOficinas.pregunta34 ? "SI" : "NO",
        chequeoOficinas.comentario34 !== null
          ? chequeoOficinas.comentario34
          : " ",
      ],
      [
        "Calderas control vencimiento.",
        chequeoOficinas.pregunta35 ? "SI" : "NO",
        chequeoOficinas.comentario35 !== null
          ? chequeoOficinas.comentario35
          : " ",
      ],
      [
        "Contrato de alquiler vencimiento.",
        chequeoOficinas.pregunta36 ? "SI" : "NO",
        chequeoOficinas.comentario36 !== null
          ? chequeoOficinas.comentario36
          : " ",
      ],
      [
        "Teclas de luz con módulo completo y funcionan.",
        chequeoOficinas.pregunta37 ? "SI" : "NO",
        chequeoOficinas.comentario37 !== null
          ? chequeoOficinas.comentario37
          : " ",
      ],
      [
        "Iluminación funcionando, completa con lámpara y plafón.",
        chequeoOficinas.pregunta38 ? "SI" : "NO",
        chequeoOficinas.comentario38 !== null
          ? chequeoOficinas.comentario38
          : " ",
      ],
      [
        "Controlar la aparición de humedades.",
        chequeoOficinas.pregunta39 ? "SI" : "NO",
        chequeoOficinas.comentario39 !== null
          ? chequeoOficinas.comentario39
          : " ",
      ],
      [
        "Pintura en techos y paredes.",
        chequeoOficinas.pregunta40 ? "SI" : "NO",
        chequeoOficinas.comentario40 !== null
          ? chequeoOficinas.comentario40
          : " ",
      ],
      [
        "Limpieza y orden.",
        chequeoOficinas.pregunta41 ? "SI" : "NO",
        chequeoOficinas.comentario41 !== null
          ? chequeoOficinas.comentario41
          : " ",
      ],
      [
        "perchero.",
        chequeoOficinas.pregunta42 ? "SI" : "NO",
        chequeoOficinas.comentario42 !== null
          ? chequeoOficinas.comentario42
          : " ",
      ],
      [
        "reloj.",
        chequeoOficinas.pregunta43 ? "SI" : "NO",
        chequeoOficinas.comentario43 !== null
          ? chequeoOficinas.comentario43
          : " ",
      ],
      [
        "Pizarra actualizada con novedades.",
        chequeoOficinas.pregunta44 ? "SI" : "NO",
        chequeoOficinas.comentario44 !== null
          ? chequeoOficinas.comentario44
          : " ",
      ],
      [
        "Control de presentismo.",
        chequeoOficinas.pregunta45 ? "SI" : "NO",
        chequeoOficinas.comentario45 !== null
          ? chequeoOficinas.comentario45
          : " ",
      ],
      [
        "Legajos de residentes al dia. Contrato.",
        chequeoOficinas.pregunta46 ? "SI" : "NO",
        chequeoOficinas.comentario46 !== null
          ? chequeoOficinas.comentario46
          : " ",
      ],
      [
        "Consentimiento médico firmado.",
        chequeoOficinas.pregunta47 ? "SI" : "NO",
        chequeoOficinas.comentario47 !== null
          ? chequeoOficinas.comentario47
          : " ",
      ],
      [
        "Legajos de personal completo.",
        chequeoOficinas.pregunta48 ? "SI" : "NO",
        chequeoOficinas.comentario48 !== null
          ? chequeoOficinas.comentario48
          : " ",
      ],
      [
        "Dirección, tel, mail actualizado mensualmente.",
        chequeoOficinas.pregunta49 ? "SI" : "NO",
        chequeoOficinas.comentario49 !== null
          ? chequeoOficinas.comentario49
          : " ",
      ],
      [
        "Planillas de control de stock.",
        chequeoOficinas.pregunta50 ? "SI" : "NO",
        chequeoOficinas.comentario50 !== null
          ? chequeoOficinas.comentario50
          : " ",
      ],
      [
        "Puerta con picaporte.",
        chequeoOficinas.pregunta51 ? "SI" : "NO",
        chequeoOficinas.comentario51 !== null
          ? chequeoOficinas.comentario51
          : " ",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 20 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 10 },
        2: { cellWidth: 70 },
      },
    });

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("15", 200, row);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.text("LAVADERO", 20, row, "left", "middle");
    row = row + 10;

    var columns = ["", "", "Comentario/Desvío"];
    var data = [
      [
        "Funcionamiento de los lavarropas.",
        chequeoLavaderos.pregunta1 ? "SI" : "NO",
        chequeoLavaderos.comentario1 !== null
          ? chequeoLavaderos.comentario1
          : " ",
      ],
      [
        "Secarropas.",
        chequeoLavaderos.pregunta2 ? "SI" : "NO",
        chequeoLavaderos.comentario2 !== null
          ? chequeoLavaderos.comentario2
          : " ",
      ],
      [
        "Tanque blanqueador.",
        chequeoLavaderos.pregunta3 ? "SI" : "NO",
        chequeoLavaderos.comentario3 !== null
          ? chequeoLavaderos.comentario3
          : " ",
      ],
      [
        "Funcionamiento de termotanque y abastecimiento de agua.",
        chequeoLavaderos.pregunta4 ? "SI" : "NO",
        chequeoLavaderos.comentario4 !== null
          ? chequeoLavaderos.comentario4
          : " ",
      ],
      [
        "Estanterías y cordeles en buen estado.",
        chequeoLavaderos.pregunta5 ? "SI" : "NO",
        chequeoLavaderos.comentario5 !== null
          ? chequeoLavaderos.comentario5
          : " ",
      ],
      [
        "Teclas de luz con módulo completo y funcionando.",
        chequeoLavaderos.pregunta6 ? "SI" : "NO",
        chequeoLavaderos.comentario6 !== null
          ? chequeoLavaderos.comentario6
          : " ",
      ],
      [
        "Iluminación funcionando, completa con lámpara y plafón.",
        chequeoLavaderos.pregunta7 ? "SI" : "NO",
        chequeoLavaderos.comentario7 !== null
          ? chequeoLavaderos.comentario7
          : " ",
      ],
      [
        "Mesadas piletas firmes con flexibles sin pérdida, desagüe sin pérdidas ni roturas con canilla funcionando con agua caliente y fría con volante completo.",
        chequeoLavaderos.pregunta8 ? "SI" : "NO",
        chequeoLavaderos.comentario8 !== null
          ? chequeoLavaderos.comentario8
          : " ",
      ],
      [
        "Suministro de agua continuo.",
        chequeoLavaderos.pregunta9 ? "SI" : "NO",
        chequeoLavaderos.comentario9 !== null
          ? chequeoLavaderos.comentario9
          : " ",
      ],
      [
        "Seguridad, puesta a tierra, enchufes según normativa.",
        chequeoLavaderos.pregunta10 ? "SI" : "NO",
        chequeoLavaderos.comentario10 !== null
          ? chequeoLavaderos.comentario10
          : " ",
      ],
      [
        "Ventanas: con vidrio sano, con cierre en buen estado funcionando y mosquitero.",
        chequeoLavaderos.pregunta11 ? "SI" : "NO",
        chequeoLavaderos.comentario11 !== null
          ? chequeoLavaderos.comentario11
          : " ",
      ],
      [
        "Controlar la aparición de humedades.",
        chequeoLavaderos.pregunta12 ? "SI" : "NO",
        chequeoLavaderos.comentario12 !== null
          ? chequeoLavaderos.comentario12
          : " ",
      ],
      [
        "Pintura en techos y paredes.",
        chequeoLavaderos.pregunta13 ? "SI" : "NO",
        chequeoLavaderos.comentario13 !== null
          ? chequeoLavaderos.comentario13
          : " ",
      ],
      [
        "Cartelería actualizada en condiciones.",
        chequeoLavaderos.pregunta14 ? "SI" : "NO",
        chequeoLavaderos.comentario14 !== null
          ? chequeoLavaderos.comentario14
          : " ",
      ],
      [
        "Sector de planchado ordenado.",
        chequeoLavaderos.pregunta15 ? "SI" : "NO",
        chequeoLavaderos.comentario15 !== null
          ? chequeoLavaderos.comentario15
          : " ",
      ],
      [
        "Depósito de ropa con estantes firmes.",
        chequeoLavaderos.pregunta16 ? "SI" : "NO",
        chequeoLavaderos.comentario16 !== null
          ? chequeoLavaderos.comentario16
          : " ",
      ],
      [
        "Canastos de residentes con nombre.",
        chequeoLavaderos.pregunta17 ? "SI" : "NO",
        chequeoLavaderos.comentario17 !== null
          ? chequeoLavaderos.comentario17
          : " ",
      ],
      [
        "Cuarto de ropa contaminada.",
        chequeoLavaderos.pregunta18 ? "SI" : "NO",
        chequeoLavaderos.comentario18 !== null
          ? chequeoLavaderos.comentario18
          : " ",
      ],
      [
        "Bolsas rojas con ropa y rotuladas.",
        chequeoLavaderos.pregunta19 ? "SI" : "NO",
        chequeoLavaderos.comentario19 !== null
          ? chequeoLavaderos.comentario19
          : " ",
      ],
      [
        "Libre de otros objetos.",
        chequeoLavaderos.pregunta20 ? "SI" : "NO",
        chequeoLavaderos.comentario20 !== null
          ? chequeoLavaderos.comentario20
          : " ",
      ],
      [
        "Pileta con desagües y canillas en condiciones.",
        chequeoLavaderos.pregunta21 ? "SI" : "NO",
        chequeoLavaderos.comentario21 !== null
          ? chequeoLavaderos.comentario21
          : " ",
      ],
      [
        "Cartel indicador en la puerta.",
        chequeoLavaderos.pregunta22 ? "SI" : "NO",
        chequeoLavaderos.comentario22 !== null
          ? chequeoLavaderos.comentario22
          : " ",
      ],
      [
        "Puerta con picaporte.",
        chequeoLavaderos.pregunta23 ? "SI" : "NO",
        chequeoLavaderos.comentario23 !== null
          ? chequeoLavaderos.comentario23
          : " ",
      ],
      [
        "Tiene ropa identificada ?.",
        chequeoLavaderos.pregunta24 ? "SI" : "NO",
        chequeoLavaderos.comentario24 !== null
          ? chequeoLavaderos.comentario24
          : " ",
      ],
      [
        "Esta ordenado.",
        chequeoLavaderos.pregunta25 ? "SI" : "NO",
        chequeoLavaderos.comentario25 !== null
          ? chequeoLavaderos.comentario25
          : " ",
      ],
      [
        "Hay ropa ajena a la residencia.",
        chequeoLavaderos.pregunta26 ? "SI" : "NO",
        chequeoLavaderos.comentario26 !== null
          ? chequeoLavaderos.comentario26
          : " ",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 20 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 10 },
        2: { cellWidth: 70 },
      },
    });



    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("16", 200, row);
    row = 20;

    doc.text(`PROTOCOLO LAVANDERIA ROPA DE CAMA.`, 20, row, {
      maxWidth: 160,
      align: "justify",
    });
    row = row + 10;

    doc.text(
      `ESTE PROTOCOLO FUE CREADO PARA QUE LA TAREA DEL LAVADERO DE RECEPCIÓN Y ENTREGA DE ROPA DE CAMA SEA REALIZADA DE MANERA EFICIENTE Y PRACTICA:`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );
    row = row + 10;

    doc.text(
      `-EL ENFERMERO O ENCARGADO DEL TURNO DEBERÁ ENTREGAR AL LAVADERO LA CANTIDAD DE SABANAS SUCIAS CONTADA Y EN BOLSAS.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );
    row = row + 10;


    doc.text(
      `EL PERSONAL DEL LAVADERO DEBERÁ ENTREGAR SABANAS LIMPIAS CORRESPONDIENTES A LA MISMA CANTIDAD DE SABANAS SUCIAS RECIBIDAS. AUTORIZANDO EL CAMBIO. EN UNA PLANILLA A TAL FIN.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );
    row = row + 15;

    doc.text(
      `LO MISMO SE HARÁ CON LAS FUNDAS.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );
    row = row + 5;

    doc.text(
      `SALEAS.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );
    row = row + 5;

    doc.text(
      `FRAZADAS.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );
    row = row + 5;

    doc.text(
      `ACOLCHADOS.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );
    row = row + 5;

    doc.text(
      `EL PERSONAL DEL LAVADERO NO ESTA AUTORIZADO A ENTREGAR SÁBANAS LIMPIAS, SINO RECIBE LAS SABANAS SUCIAS, COMO ASI TAMBIÉN NINGUNA DE LAS PRENDAS NOMBRADAS ANTERIORMENTE.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );
    row = row + 15;

    doc.text(
      `-CUANDO EL PERSONAL DEL LAVADERO NO SE ENCUENTRE POR ALGUN MOTIVO, DEBERÁ DEJAR LA CANTIDAD DE SABANAS CORRESPONDIENTES A CADA TURNO ROTULADO Y EL BOLSAS LIMPIAS Y ESTE DEBERÁ RESPONDER CON LA MISMA CANTIDAD DE SABANAS SUCIAS HACIÉNDOSE RESPONSABLE TANTO EL PERSONAL DEL LAVADERO COMO EL RESPONSABLE DEL TURNO PARA QUE ESTE PROCESO SE EFECTÚE CORRECTAMENTE.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );
    row = row + 25;

    doc.text(
      `Se pasa por guardia hasta que llegue personal del lavadero y autorice. La reposición.`,
      20,
      row,
      {
        maxWidth: 160,
        align: "justify",
      }
    );


    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("17", 200, row);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.text(
      "CONTROL DE ELEMENTOS CON PERIODICIDAD",
      20,
      row,
      "left",
      "middle"
    );
    row = row + 10;

    var columns = ["", "", "Comentario/Desvío"];
    var data = [
      [
        "Funcionamiento del grupo electrógeno con nafta e instrucciones de encendido y puesta en marcha con periodicidad semanal.",
        chequeoElementos.pregunta1 ? "SI" : "NO",
        chequeoElementos.comentario1 !== null
          ? chequeoElementos.comentario1
          : " ",
      ],
      [
        "Comprobar luminaria.",
        chequeoElementos.pregunta2 ? "SI" : "NO",
        chequeoElementos.comentario2 !== null
          ? chequeoElementos.comentario2
          : " ",
      ],
      [
        "Correcto funcionamiento de los termo y calefones para proveer adecuadamente agua caliente a todo el establecimiento. (Semanal).",
        chequeoElementos.pregunta3 ? "SI" : "NO",
        chequeoElementos.comentario3 !== null
          ? chequeoElementos.comentario3
          : " ",
      ],
      [
        "Control de funcionamiento de la bomba de agua, cisterna y tablero de comando. (semestral).",
        chequeoElementos.pregunta4 ? "SI" : "NO",
        chequeoElementos.comentario4 !== null
          ? chequeoElementos.comentario4
          : " ",
      ],
      [
        "Plan de capacitación anual. Roles del personal.",
        chequeoElementos.pregunta5 ? "SI" : "NO",
        chequeoElementos.comentario5 !== null
          ? chequeoElementos.comentario5
          : " ",
      ],
      [
        "Funcionamiento de los equipos de calefacción y calderas comprobar correcto encendido en el mes de febrero, de lo contrario llamar al service, controlar la temperatura de los radiadores y purgarlos.",
        chequeoElementos.pregunta6 ? "SI" : "NO",
        chequeoElementos.comentario6 !== null
          ? chequeoElementos.comentario6
          : " ",
      ],
      [
        "Probar funcionamiento de luces de emergencia, batería.",
        chequeoElementos.pregunta7 ? "SI" : "NO",
        chequeoElementos.comentario7 !== null
          ? chequeoElementos.comentario7
          : " ",
      ],
      [
        "Central de incendio funcionando y en condiciones.",
        chequeoElementos.pregunta8 ? "SI" : "NO",
        chequeoElementos.comentario8 !== null
          ? chequeoElementos.comentario8
          : " ",
      ],
      [
        "Detectores de humo y de gases controlan su funcionamiento.",
        chequeoElementos.pregunta9 ? "SI" : "NO",
        chequeoElementos.comentario9 !== null
          ? chequeoElementos.comentario9
          : " ",
      ],
      [
        "Cartelería plástica y luminosa completa.",
        chequeoElementos.pregunta10 ? "SI" : "NO",
        chequeoElementos.comentario10 !== null
          ? chequeoElementos.comentario10
          : " ",
      ],
      [
        "Croquis de evacuación y salidas de emergencia libres para la circulación.",
        chequeoElementos.pregunta11 ? "SI" : "NO",
        chequeoElementos.comentario11 !== null
          ? chequeoElementos.comentario11
          : " ",
      ],
      [
        "Barrales antipánico, señalización y cintas antideslizantes.",
        chequeoElementos.pregunta12 ? "SI" : "NO",
        chequeoElementos.comentario12 !== null
          ? chequeoElementos.comentario12
          : " ",
      ],
      [
        "Canaletas: 1 vez por semana o la proximidad de lluvias.",
        chequeoElementos.pregunta13 ? "SI" : "NO",
        chequeoElementos.comentario13 !== null
          ? chequeoElementos.comentario13
          : " ",
      ],
      [
        "Techos y terrazas libres de cualquier objeto.",
        chequeoElementos.pregunta14 ? "SI" : "NO",
        chequeoElementos.comentario14 !== null
          ? chequeoElementos.comentario14
          : " ",
      ],
      [
        "Patios: limpieza y corte de pasto quincenal (verano) mensual (invierno).",
        chequeoElementos.pregunta15 ? "SI" : "NO",
        chequeoElementos.comentario15 !== null
          ? chequeoElementos.comentario15
          : " ",
      ],
      [
        "Control de plagas, desratización, certificados según requerimiento.",
        chequeoElementos.pregunta16 ? "SI" : "NO",
        chequeoElementos.comentario16 !== null
          ? chequeoElementos.comentario16
          : " ",
      ],
      [
        "Destape de cloacas y desagües mensualmente.",
        chequeoElementos.pregunta17 ? "SI" : "NO",
        chequeoElementos.comentario17 !== null
          ? chequeoElementos.comentario17
          : " ",
      ],
      [
        "Módulos sanitarios funcionamiento y recarga.",
        chequeoElementos.pregunta18 ? "SI" : "NO",
        chequeoElementos.comentario18 !== null
          ? chequeoElementos.comentario18
          : " ",
      ],
      [
        "Recintos de residuos comunes y patológicos libres y en correctas condiciones de higiene, paredes y pisos con cerámicos, con puerta y picaporte que cierre correctamente.",
        chequeoElementos.pregunta19 ? "SI" : "NO",
        chequeoElementos.comentario19 !== null
          ? chequeoElementos.comentario19
          : " ",
      ],
      [
        "Tableros general en condiciones con tapa con indicación de peligro electricidad. Sectorizado con térmicas y disyuntores.",
        chequeoElementos.pregunta20 ? "SI" : "NO",
        chequeoElementos.comentario20 !== null
          ? chequeoElementos.comentario20
          : " ",
      ],
      [
        "Tableros de sectores con tapa e indicación de peligro electricidad. Térmicas y disyuntores por zona. Bien identificados.",
        chequeoElementos.pregunta21 ? "SI" : "NO",
        chequeoElementos.comentario21 !== null
          ? chequeoElementos.comentario21
          : " ",
      ],
      [
        "Tomas, enchufes íntegros, timbres, luminaria, puesta a tierra.",
        chequeoElementos.pregunta22 ? "SI" : "NO",
        chequeoElementos.comentario22 !== null
          ? chequeoElementos.comentario22
          : " ",
      ],
      [
        "Lámparas de luminarias completas funcionando.",
        chequeoElementos.pregunta23 ? "SI" : "NO",
        chequeoElementos.comentario23 !== null
          ? chequeoElementos.comentario23
          : " ",
      ],
      [
        "Ascensor, si posee, al detectar un mal funcionamiento llamar al servicio.",
        chequeoElementos.pregunta24 ? "SI" : "NO",
        chequeoElementos.comentario24 !== null
          ? chequeoElementos.comentario24
          : " ",
      ],
      [
        "Oblea de control mensual.",
        chequeoElementos.pregunta25 ? "SI" : "NO",
        chequeoElementos.comentario25 !== null
          ? chequeoElementos.comentario25
          : " ",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 20 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 10 },
        2: { cellWidth: 70 },
      },
    });

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("18", 200, row);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.text(
      "CONTROL DE ELEMENTOS CON PERIODICIDAD",
      20,
      row,
      "left",
      "middle"
    );
    row = row + 10;

    var columns = ["", "", "Comentario/Desvío"];
    var data = [
      [
        "Libro de mantenimiento actualizado.",
        chequeoElementos.pregunta26 ? "SI" : "NO",
        chequeoElementos.comentario26 !== null
          ? chequeoElementos.comentario26
          : " ",
      ],
      [
        "Sala de maquina: con luz y matafuegos, con cierre por seguridad. Sin elementos ajenos prohibidos a la sala.",
        chequeoElementos.pregunta27 ? "SI" : "NO",
        chequeoElementos.comentario27 !== null
          ? chequeoElementos.comentario27
          : " ",
      ],
      [
        "Grupos electrógenos mensual.",
        chequeoElementos.pregunta28 ? "SI" : "NO",
        chequeoElementos.comentario28 !== null
          ? chequeoElementos.comentario28
          : " ",
      ],
      [
        "Sensores de humo.",
        chequeoElementos.pregunta29 ? "SI" : "NO",
        chequeoElementos.comentario29 !== null
          ? chequeoElementos.comentario29
          : " ",
      ],
      [
        "Central de incendio mensual.",
        chequeoElementos.pregunta30 ? "SI" : "NO",
        chequeoElementos.comentario30 !== null
          ? chequeoElementos.comentario30
          : " ",
      ],
      [
        "Luces de emergencia mensual.",
        chequeoElementos.pregunta31 ? "SI" : "NO",
        chequeoElementos.comentario31 !== null
          ? chequeoElementos.comentario31
          : " ",
      ],
      [
        "Termotanques.",
        chequeoElementos.pregunta32 ? "SI" : "NO",
        chequeoElementos.comentario32 !== null
          ? chequeoElementos.comentario32
          : " ",
      ],
      [
        "Calefones.",
        chequeoElementos.pregunta33 ? "SI" : "NO",
        chequeoElementos.comentario33 !== null
          ? chequeoElementos.comentario33
          : " ",
      ],
      [
        "Puesta a tierra.",
        chequeoElementos.pregunta34 ? "SI" : "NO",
        chequeoElementos.comentario34 !== null
          ? chequeoElementos.comentario34
          : " ",
      ],
      [
        "Control de módulos, teclas, tomas y enchufes.",
        chequeoElementos.pregunta35 ? "SI" : "NO",
        chequeoElementos.comentario35 !== null
          ? chequeoElementos.comentario35
          : " ",
      ],
      [
        "Calefactores.",
        chequeoElementos.pregunta36 ? "SI" : "NO",
        chequeoElementos.comentario36 !== null
          ? chequeoElementos.comentario36
          : " ",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 20 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 10 },
        2: { cellWidth: 70 },
      },
    });

    doc.addPage();

    row = 10;
    doc.setFontSize(10);
    doc.text("19", 200, row);
    row = 20;

    doc.setFont(undefined, "normal");
    doc.text("CUMPLIMIENTOS DE SEGURIDAD E HIGIENE", 20, row, "left", "middle");
    row = row + 10;

    var columns = ["", "", "Comentario/Desvío"];
    var data = [
      [
        "Medición de intensidad lumínica.",
        chequeoCumplimientos.pregunta1 ? "SI" : "NO",
        chequeoCumplimientos.comentario1 !== null
          ? chequeoCumplimientos.comentario1
          : " ",
      ],
      [
        "Simulacros de evacuación.",
        chequeoCumplimientos.pregunta2 ? "SI" : "NO",
        chequeoCumplimientos.comentario2 !== null
          ? chequeoCumplimientos.comentario2
          : " ",
      ],
      [
        "Práctica de extinción de incendios.",
        chequeoCumplimientos.pregunta3 ? "SI" : "NO",
        chequeoCumplimientos.comentario3 !== null
          ? chequeoCumplimientos.comentario3
          : " ",
      ],
      [
        "Presentación de RAR (Relevamientos de Agentes de Riesgo)",
        chequeoCumplimientos.pregunta4 ? "SI" : "NO",
        chequeoCumplimientos.comentario4 !== null
          ? chequeoCumplimientos.comentario4
          : " ",
      ],
      [
        "Plan de capacitación anual. Roles del personal.",
        chequeoCumplimientos.pregunta5 ? "SI" : "NO",
        chequeoCumplimientos.comentario5 !== null
          ? chequeoCumplimientos.comentario5
          : " ",
      ],
      [
        "Capacitación: Tipos de fuego y métodos de extinción.",
        chequeoCumplimientos.pregunta6 ? "SI" : "NO",
        chequeoCumplimientos.comentario6 !== null
          ? chequeoCumplimientos.comentario6
          : " ",
      ],
      [
        "Análisis de agua. Bactereológico y físico químico.",
        chequeoCumplimientos.pregunta7 ? "SI" : "NO",
        chequeoCumplimientos.comentario7 !== null
          ? chequeoCumplimientos.comentario7
          : " ",
      ],
      [
        "Limpieza de tanques.",
        chequeoCumplimientos.pregunta8 ? "SI" : "NO",
        chequeoCumplimientos.comentario8 !== null
          ? chequeoCumplimientos.comentario8
          : " ",
      ],
      [
        "Desratización.",
        chequeoCumplimientos.pregunta9 ? "SI" : "NO",
        chequeoCumplimientos.comentario9 !== null
          ? chequeoCumplimientos.comentario9
          : " ",
      ],
      [
        "Estudio ergonómico en caso de ser requerido.",
        chequeoCumplimientos.pregunta10 ? "SI" : "NO",
        chequeoCumplimientos.comentario10 !== null
          ? chequeoCumplimientos.comentario10
          : " ",
      ],
      [
        "Espacio de trabajo.",
        chequeoCumplimientos.pregunta11 ? "SI" : "NO",
        chequeoCumplimientos.comentario11 !== null
          ? chequeoCumplimientos.comentario11
          : " ",
      ],
      [
        "Contar con las herramientas necesarias y control de stock de materiales.",
        chequeoCumplimientos.pregunta12 ? "SI" : "NO",
        chequeoCumplimientos.comentario12 !== null
          ? chequeoCumplimientos.comentario12
          : " ",
      ],
      [
        "Limpio y ordenado.",
        chequeoCumplimientos.pregunta13 ? "SI" : "NO",
        chequeoCumplimientos.comentario13 !== null
          ? chequeoCumplimientos.comentario13
          : " ",
      ],
      [
        "Iluminado y ventilado.",
        chequeoCumplimientos.pregunta14 ? "SI" : "NO",
        chequeoCumplimientos.comentario14 !== null
          ? chequeoCumplimientos.comentario14
          : " ",
      ],
      [
        "Medición de puesta a tierra.",
        chequeoCumplimientos.pregunta15 ? "SI" : "NO",
        chequeoCumplimientos.comentario15 !== null
          ? chequeoCumplimientos.comentario15
          : " ",
      ],
    ];

    doc.autoTable(columns, data, {
      margin: { top: row, left: 20 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 10 },
        2: { cellWidth: 70 },
      },
    });

    window.open(doc.output("bloburl"));
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
    setVisibleDatos(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !chequeo ||
      chequeo.fecha === undefined ||
      chequeo.fecha === null ||
      chequeo.fecha === ""
    ) {
      mensajeAlerta("Chequeos", "La fecha es necesaria !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      //actualizarChequeo({ chequeo, chequeoBanos, chequeoHabitaciones });
    } else {
      agregarChequeo({ chequeo });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerChequeos({ geriatricoId: Number(geriatricoElegido.id) });
    }, 2000);
    //exitClick();
  };

  // Steps

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === 8) {
      showConfirm();
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const showConfirm = () => {
    confirmDialog({
      message: "Desea guardar los datos ?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Si",
      accept,
      reject,
    });
  };

  const accept = () => {
    actualizarChequeo({
      chequeo,
      chequeoBanos,
      chequeoHabitaciones,
      chequeoEspacios,
      chequeoCocinas,
      chequeoEnfermerias,
      chequeoOficinas,
      chequeoLavaderos,
      chequeoElementos,
      chequeoCumplimientos,
    });
    onHide();
    setTimeout(() => {
      obtenerChequeos({ geriatricoId: Number(geriatricoElegido.id) });
    }, 2000);
  };

  const reject = () => {
    toast.current.show({
      severity: "info",
      summary: "Chequeo",
      detail: "Los datos no se han guardado",
      life: 3000,
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const fechaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDate2(rowData.fecha)}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const telefonoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Télefono</span>
        {rowData.telefono}
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Habilitado</span>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  function formatDate2(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, chequeos);
        doc.save("chequeos.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(chequeos);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "chequeos");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Chequeos"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Chequeo"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(chequeoBanos);
  //console.log(statusAcceso);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {chequeos ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  align: "center",
                  margin: "1em",
                  marginTop:
                    isMobile && !isPortrait
                      ? "22vw"
                      : isMobile && isPortrait
                      ? "55vw"
                      : "0vw",
                }}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar Fecha Chequeo"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar Chequeo"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-print"
                  tooltip="Imprimir"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "1em" }}
                  onClick={imprimirClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <Button
                  id="imprimirClick"
                  icon="pi pi-print"
                  tooltip="Imprimir"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "1em", visibility: "hidden" }}
                  onClick={acceptImprimir}
                />

                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={chequeos}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      responsiveLayout="scroll"

                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha"
                        body={fechaTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  {selectedRow ? (
                    <Grid item xs={12} md={2}>
                      <TextField
                        value={chequeo ? chequeo.id : null}
                        required
                        id="id"
                        label="ID"
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={4}>
                    <TextField
                      value={
                        chequeo && chequeo.fecha !== undefined
                          ? chequeo.fecha
                          : formatDate(new Date())
                      }
                      InputLabelProps={{ shrink: true }}
                      id="fecha"
                      label="Fecha"
                      fullWidth
                      type="date"
                      variant="standard"
                      onChange={(e) => guardarChequeo(e.target.value)}
                      size="small"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeader()}
                visible={visibleDatos}
                closable={true}
                maximizable
                //footer={renderFooter()}
                onHide={onHide}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "100vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        style={{ marginLeft: "1vw" }}
                      >
                        Anterior
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleNext}>
                        {activeStep === steps.length - 1
                          ? "Guardar"
                          : "Siguiente"}
                      </Button>
                    </Box>

                    <Fragment>
                      {activeStep === 0 && selectedRow ? (
                        <ChequeoBanos />
                      ) : null}

                      {activeStep === 1 && selectedRow ? (
                        <ChequeoHabitaciones />
                      ) : null}

                      {activeStep === 2 && selectedRow ? (
                        <ChequeoEspacios />
                      ) : null}

                      {activeStep === 3 && selectedRow ? (
                        <ChequeoCocinas />
                      ) : null}

                      {activeStep === 4 && selectedRow ? (
                        <ChequeoEnfermerias />
                      ) : null}

                      {activeStep === 5 && selectedRow ? (
                        <ChequeoOficinas />
                      ) : null}

                      {activeStep === 6 && selectedRow ? (
                        <ChequeoLavaderos />
                      ) : null}

                      {activeStep === 7 && selectedRow ? (
                        <ChequeoElementos />
                      ) : null}

                      {activeStep === 8 && selectedRow ? (
                        <ChequeoCumplimientos />
                      ) : null}

                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                          style={{ marginLeft: "1vw" }}
                        >
                          Anterior
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleNext}>
                          {activeStep === steps.length - 1
                            ? "Guardar"
                            : "Siguiente"}
                        </Button>
                      </Box>
                    </Fragment>
                  </Box>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
      {exit ? <Navigate to="/login" /> : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
