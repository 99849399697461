import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import "../../assets/css/fixedheader.css";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function Planillas(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowPagos, setSelectedRowPagos] = useState(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;

  const exportColumns = [
    { title: "id", dataKey: "numero" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Obra Social", dataKey: "obraSocial" },
    { title: "Mensual", dataKey: "mensual" },
    { title: "I.V.A.", dataKey: "iva" },
    { title: "Total", dataKey: "total" },
    { title: "Coseguro", dataKey: "coseguro" },
    { title: "Pañales", dataKey: "panales" },
    { title: "Estudios", dataKey: "estudios" },
    { title: "Efectivo", dataKey: "efectivo" },
    { title: "Obra Social", dataKey: "importeObraSocial" },
    { title: "Pendiente", dataKey: "pendiente" },
    { title: "Ingreso", dataKey: "ingreso" },
  ];

  const [periodo, setPeriodo] = useState(null);
  const [paciente, guardarPaciente] = useState(null);
  const [visible, setVisible] = useState(false);
  const [cosegurosVisible, setCosegurosVisible] = useState(false);
  const [pagosVisible, setPagosVisible] = useState(false);
  const [modalIngresosEgresos, setModalIngresosEgresos] = useState(false);
  const [datPla, setDatPla] = useState([]);
  const toast = useRef(null);
  const [exit, setExit] = useState(false);
  const [ajuste, setAjuste] = useState(null);
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    statusAcceso2,
    statusAcceso3,
    pacientes,
    localidades,
    diagnosticos,
    datosPlanilla,
    datosPlanillaExport,
    datosPagos,
    ingresosEgresos,
    obrasSociales,
    coseguro,
    totalesPlanilla,
    geriatricoElegido,
    obtenerPacientes,
    obtenerAcceso,
    obtenerAcceso2,
    obtenerAcceso3,
    agregarPaciente,
    actualizarPaciente,
    setGeriatricoElegido,
    obtenerLocalidadesPacientes,
    obtenerObrasSocialesPacientes,
    obtenerDiagnosticosPacientes,
    agregarCoseguros,
    obtenerIngresosEgresos,
    obtenerDatosPlanilla,
    facturacionMensual,
    guardarDatosPlanilla,
    guardarCoseguro,
    obtenerPendientesPlanilla,
    generarPagoPendiente,
    cerrarPlanilla,
    borrarPlanilla,
    ajustesPlanilla,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Planilla",
      });
      obtenerAcceso2({
        usuarioId: state.usuario.id,
        layout: "AjustesPlanilla",
      });
      obtenerAcceso3({
        usuarioId: state.usuario.id,
        layout: "BorrarPlanilla",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    obtenerPeriodoActual().then((today) =>
      obtenerDatosPlanilla({
        periodo: today,
        geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
      })
    );
    //obtenerLocalidadesPacientes({ todos: false });
    //obtenerObrasSocialesPacientes({ todos: false });
    //obtenerDiagnosticosPacientes({ todos: false });
  }, []);

  const editClick = (e) => {
    if (statusAcceso2 !== 200) {
      toast.current.show({
        severity: "error",
        summary: "Ajustar Pendientes",
        detail: "No tiene permiso para acceder a esta pantalla !.",
        life: 3000,
      });
      return;
    }
    if (selectedRow) {
      setAjuste(selectedRow.ajuste);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  const borrarClick = (e) => {
    if (statusAcceso3 !== 200) {
      toast.current.show({
        severity: "error",
        summary: "Borrar Registros",
        detail: "No tiene permiso para acceder a esta pantalla !.",
        life: 3000,
      });
      return;
    }
    if (selectedRow) {
      toast.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div className="p-flex p-flex-column" style={{ flex: "1" }}>
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>Confirma borrar registro der {selectedRow.nombre}</h4>
            </div>
            <div className="p-grid p-fluid">
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) => {
                    opcionSiBorrar();
                  }}
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-secondary"
                  onClick={hide}
                />
              </div>
            </div>
          </div>
        ),
      });
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  const cosegurosClick = (e) => {
    if (selectedRow) {
      guardarPaciente(selectedRow);
      setCosegurosVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  const pagosClick = (e) => {
    if (selectedRow) {
      guardarPaciente(selectedRow);
      obtenerPendientesPlanilla({
        periodo,
        geriatricoId: geriatricoElegido.id,
        pacienteId: selectedRow.id,
      });
      setSelectedRowPagos(null);
      setPagosVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  const cierreClick = (e) => {
    if (
      periodo.substring(0, 2) >= 1 &&
      periodo.substring(0, 2) <= 12 &&
      periodo.substring(3, 7) >= 2022
    ) {
      toast.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div className="p-flex p-flex-column" style={{ flex: "1" }}>
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>Confirma cierre mensual del período {periodo}</h4>
            </div>
            <div className="p-grid p-fluid">
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) => {
                    opcionSiCierre();
                  }}
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-secondary"
                  onClick={hide}
                />
              </div>
            </div>
          </div>
        ),
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Período",
        detail: "El período es incorrecto.",
        life: 3000,
      });
    }
  };

  const showConfirm = () => {
    if (
      periodo.substring(0, 2) >= 1 &&
      periodo.substring(0, 2) <= 12 &&
      periodo.substring(3, 7) >= 2022
    ) {
      toast.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div className="p-flex p-flex-column" style={{ flex: "1" }}>
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>Confirma proceso de facturación mensual período {periodo}</h4>
            </div>
            <div className="p-grid p-fluid">
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) => {
                    opcionSi();
                  }}
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-secondary"
                  onClick={hide}
                />
              </div>
            </div>
          </div>
        ),
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Período",
        detail: "El período es incorrecto.",
        life: 3000,
      });
    }
  };

  const showConfirmPagos = () => {
    if (selectedRowPagos) {
      toast.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div className="p-flex p-flex-column" style={{ flex: "1" }}>
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>
                Confirma pago de {selectedRowPagos.descripcion} por $
                {selectedRowPagos.pendiente}
              </h4>
            </div>
            <div className="p-grid p-fluid">
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) => {
                    opcionSiPagos();
                  }}
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-secondary"
                  onClick={hide}
                />
              </div>
            </div>
          </div>
        ),
      });
    } else {
      toast.current.show({
        severity: "info",
        summary: "Pagos",
        detail: "Debe seleccionar un item pendiente de pago.",
        life: 3000,
      });
    }
  };

  const opcionSi = async () => {
    await obtenerPacientes({
      geriatricoId: geriatricoElegido.id,
      planilla: true,
      periodo,
    });
    var elem;
    elem = document.getElementById("facturacionMensual");
    elem.click();
    hide();
  };

  const opcionSiPagos = async () => {
    generarPagoPendiente({
      selectedRowPagos,
      geriatricoId: geriatricoElegido.id,
      periodo,
    });
    setTimeout(() => {
      obtenerPendientesPlanilla({
        periodo,
        geriatricoId: geriatricoElegido.id,
        pacienteId: selectedRow.id,
      });
      obtenerDatosPlanilla({
        periodo,
        geriatricoId: geriatricoElegido.id,
      });
      setSelectedRow(null);
    }, 2000);
    //setPagosVisible(true);
    hide();
  };

  const opcionSiCierre = async () => {
    cerrarPlanilla({
      periodo,
      geriatricoId: geriatricoElegido.id,
      datosPlanilla,
    });
    setTimeout(() => {
      obtenerDatosPlanilla({
        periodo,
        geriatricoId: geriatricoElegido.id,
      });
    }, 2000);
    //setPagosVisible(true);
    hide();
  };

  const opcionSiBorrar = async () => {
    borrarPlanilla({
      planillaId: selectedRow.planillaId,
      pacienteId: selectedRow.id,
      periodo,
      geriatricoId: geriatricoElegido.id,
    });
    setTimeout(() => {
      obtenerDatosPlanilla({
        periodo,
        geriatricoId: geriatricoElegido.id,
      });
      setSelectedRowPagos(null);
    }, 2000);
    //setPagosVisible(true);
    hide();
  };

  const facturacion = () => {
    facturacionMensual({
      periodo,
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
      pacientes,
    });
    /*
    setTimeout(() => {
      obtenerDatosPlanilla({
        periodo,
        geriatricoId: Number(geriatricoElegido.id),
      });
    }, 2000);
    window.location.reload()
    */
  };

  const showConfirmBorrar = (data) => {
    toast.current.show({
      severity: "warn",
      sticky: true,
      content: (
        <div className="p-flex p-flex-column" style={{ flex: "1" }}>
          <div className="p-text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <h4>Confirma borrar registro del paciente {data.nombre}</h4>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-6">
              <Button
                type="button"
                label="Si"
                className="p-button-success"
                onClick={(e) => {
                  opcionSiBorrar(data.planillaId);
                }}
              />
            </div>
            <div className="p-col-6">
              <Button
                type="button"
                label="No"
                className="p-button-secondary"
                onClick={hide}
              />
            </div>
          </div>
        </div>
      ),
    });
  };

  const hide = () => {
    toast.current.clear();
    setSelectedRowPagos(null);
  };
  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHideCoseguros = (e) => {
    setCosegurosVisible(false);
  };

  const onHidePagos = (e) => {
    setPagosVisible(false);
  };

  const aceptarClickCoseguros = (e) => {
    e.preventDefault();

    //Validar
    if (
      !coseguro ||
      coseguro.tipo === undefined ||
      coseguro.tipo === null ||
      coseguro.tipo.trim() === ""
    ) {
      mensajeAlerta(
        "Coseguros",
        "El tipo de coseguro es necesario !.",
        "error"
      );
      return;
    }

    if (
      !coseguro ||
      coseguro.importe === undefined ||
      coseguro.importe === null ||
      coseguro.importe <= 0
    ) {
      mensajeAlerta(
        "Coseguros",
        "El importe del coseguro es necesario !.",
        "error"
      );
      return;
    }

    agregarCoseguros({
      coseguro,
      pacienteId: selectedRow.id,
      pacienteNombre: selectedRow.nombre,
      periodo,
      geriatricoId: geriatricoElegido.id,
    });
    onHideCoseguros();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerDatosPlanilla({
        periodo,
        geriatricoId: geriatricoElegido.id,
      });
      guardarCoseguro(null);
    }, 2000);
    //exitClick();
  };

  const aceptarClickAjuste = (e) => {
    e.preventDefault();

    ajustesPlanilla({
      ajuste,
      pacienteId: selectedRow.id,
      planillaId: selectedRow.planillaId,
    });
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerDatosPlanilla({
        periodo,
        geriatricoId: geriatricoElegido.id,
      });
      setAjuste(null);
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const obtenerPeriodoActual = async () => {
    let today = convertUTCDateToLocalDate(new Date());
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    today = mm + "/" + yyyy;
    setPeriodo(today);
    return today;
  };

  function convertUTCDateToLocalDate(date) {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  }

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.numero}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const obraSocialTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Obra Social</span>
        {rowData.obraSocial}
      </Fragment>
    );
  };

  const mensualTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Mensual</span>
        {rowData.mensual}
      </Fragment>
    );
  };

  const coseguroTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Coseguro</span>
        {rowData.coseguro}
      </Fragment>
    );
  };

  const panalesTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Pañales</span>
        {rowData.panales}
      </Fragment>
    );
  };
  const ivaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">I.V.A.</span>
        {rowData.iva}
      </Fragment>
    );
  };
  const estudiosTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Estudios</span>
        {rowData.estudios}
      </Fragment>
    );
  };

  const totalTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Total</span>
        {rowData.total}
      </Fragment>
    );
  };

  const ajusteTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Ajuste</span>
        {rowData.ajuste}
      </Fragment>
    );
  };

  const pendienteTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Pendiente Efectivo</span>
        {rowData.pendiente}
      </Fragment>
    );
  };

  const efectivoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Ingreso Efectivo</span>
        {rowData.efectivo}
      </Fragment>
    );
  };

  const importeObraSocialTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Obra Social</span>
        {rowData.importeObraSocial}
      </Fragment>
    );
  };

  const ingresoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Ingreso</span>
        {formatDate(rowData.ingreso)}
      </Fragment>
    );
  };

  const descripcionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Descripción</span>
        {rowData.descripcion}
      </Fragment>
    );
  };

  const saldoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Pendiente</span>
        {rowData.pendiente}
      </Fragment>
    );
  };

  const totalesTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title"></span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const emptyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title"></span>
        {""}
      </Fragment>
    );
  };

  const totalMensualTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Mensual</span>
        {rowData.mensual}
      </Fragment>
    );
  };

  const totalCoseguroTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Coseguro</span>
        {rowData.coseguro}
      </Fragment>
    );
  };

  const totalPanalesTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Pañales</span>
        {rowData.panales}
      </Fragment>
    );
  };

  const totalIvaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">I.V.A.</span>
        {rowData.iva}
      </Fragment>
    );
  };

  const totalEstudiosTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Estudios</span>
        {rowData.estudios}
      </Fragment>
    );
  };

  const totalCuotaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Total</span>
        {rowData.total}
      </Fragment>
    );
  };

  const totalEfectivoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Efectivo</span>
        {rowData.efectivo}
      </Fragment>
    );
  };

  const totalObraSocialTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Obra Social</span>
        {rowData.obraSocial}
      </Fragment>
    );
  };

  const totalAjusteTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Ajuste</span>
        {rowData.ajuste}
      </Fragment>
    );
  };

  const totalPendienteTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Pendiente</span>
        {rowData.pendiente}
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.setFontSize(12);
        doc.text("Listado Mensual Período " + periodo, 60, 8);
        doc.autoTable(exportColumns, datosPlanillaExport, {
          styles: {
            fontSize: 6,
          },
        });
        //doc.save("planilla_" + periodo + ".pdf");
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(datosPlanillaExport);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Planilla");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName +
            "_" +
            periodo +
            "_" +
            new Date().getTime() +
            EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Planilla Mensual"></Tag>
      </div>
    </Fragment>
  );

  const renderHeaderCoseguro = () => {
    return (
      <div>
        {selectedRow ? (
          <Tag value={"Coseguros de " + selectedRow.nombre}></Tag>
        ) : null}
      </div>
    );
  };

  const renderFooterCoseguro = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e) => {
            aceptarClickCoseguros(e);
          }}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideCoseguros}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div>
        {selectedRow ? (
          <Tag value={"Modificaión de " + selectedRow.nombre}></Tag>
        ) : null}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e) => {
            aceptarClickAjuste(e);
          }}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeaderPagos = () => {
    return (
      <div>
        {selectedRow ? (
          <Tag value={"Pendientes de " + selectedRow.nombre}></Tag>
        ) : null}
      </div>
    );
  };

  const renderFooterPagos = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e) => {
            showConfirmPagos();
          }}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHidePagos}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const handleChange = (event) => {
    guardarCoseguro({
      ...coseguro,
      tipo: event.target.value,
    });
  };

  function formatDate(dateString) {
    var allDate = dateString.split("-");
    var newDate = [allDate[2], allDate[1], allDate[0]].join("/");
    return newDate;
  }

  const footer = () => {
    return (
      <div className="datatable-responsive-demo">
        <div className="card">
          <Tooltip target=".export-buttons>button" position="bottom" />
          <DataTable
            value={totalesPlanilla}
            //selection={selectedRow}
            //onSelectionChange={(e) => setSelectedRow(e.value)}
            //selectionMode="single"
            //dataKey="id"
            //header={header}
            //footer={footer}
            className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
            style={{ fontSize: "small", marginTop: "1vw" }}
            //globalFilter={globalFilter}
            emptyMessage="No hay datos."
            //paginator
            //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            //currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
            //rows={10}
            //rowsPerPageOptions={[10, 20, 50, 100, 200]}
            //responsiveLayout="scroll"

            //paginatorLeft={paginatorLeft}
            //paginatorRight={paginatorRight}
          >
            <Column
              field="nombre"
              header="TOTALES"
              body={totalesTemplate}
              style={{ width: "24%" }}
            ></Column>
            <Column
              field="mensual"
              header="Mensual"
              body={totalMensualTemplate}
              style={{ width: "7%" }}
              align="right"
            ></Column>
            <Column
              field="iva"
              header="I.V.A."
              body={totalIvaTemplate}
              style={{ width: "8%" }}
              align="right"
            ></Column>
            <Column
              field="total"
              header="Total"
              body={totalCuotaTemplate}
              style={{ width: "7.0%" }}
              align="right"
            ></Column>
            <Column
              field="coseguro"
              header="Coseguro"
              body={totalCoseguroTemplate}
              style={{ width: "7.5%" }}
              align="right"
            ></Column>
            <Column
              field="panales"
              header="Pañales"
              body={totalPanalesTemplate}
              style={{ width: "7%" }}
              align="right"
            ></Column>
            <Column
              field="estudios"
              header="Estudios"
              body={totalEstudiosTemplate}
              style={{ width: "7.5%" }}
              align="right"
            ></Column>
            <Column
              field="efectivo"
              header="Ingreso Efectivo"
              body={totalEfectivoTemplate}
              style={{ width: "7.0%" }}
              align="right"
            ></Column>
            <Column
              field="obraSocial"
              header="Obra Social"
              body={totalObraSocialTemplate}
              style={{ width: "6.5%" }}
              align="right"
            ></Column>
            <Column
              field="ajuste"
              header="Ajustes"
              body={totalAjusteTemplate}
              style={{ width: "6.0%" }}
              align="right"
            ></Column>
            <Column
              field="pendiente"
              header="Pendiente Efectivo"
              body={totalPendienteTemplate}
              style={{ width: "7.0%" }}
              align="right"
            ></Column>
            <Column
              field="id"
              header=""
              body={emptyTemplate}
              style={{ width: "25%" }}
            ></Column>
          </DataTable>
        </div>
      </div>
    );
  };

  //console.log(datosPlanilla);
  //console.log(datosPlanillaExport);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {datosPlanilla && totalesPlanilla ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ align: "center", margin: "1em" }}
              >
                <div id="botones" classname="botonesPlanilla">
                  <label htmlFor="periodo">Período: </label>
                  <InputMask
                    id="periodo"
                    mask="99/9999"
                    value={periodo}
                    onChange={(e) => {
                      setPeriodo(e.value);
                      if (
                        e.value.substring(0, 2) >= 1 &&
                        e.value.substring(0, 2) <= 12 &&
                        e.value.substring(3, 7) >= 2022
                      ) {
                        guardarDatosPlanilla([]);
                        obtenerDatosPlanilla({
                          periodo: e.value,
                          geriatricoId: geriatricoElegido.id,
                        });
                      }
                    }}
                    style={{
                      align: "center",
                      marginLeft: "0.5em",
                      marginTop:
                        isMobile && !isPortrait
                          ? "22vw"
                          : isMobile && isPortrait
                          ? "55vw"
                          : "0vw",
                    }}
                  ></InputMask>
                  <Button
                    icon="pi pi-dollar"
                    tooltip="Facturación Mensual"
                    className="p-button-rounded p-button-help"
                    style={{ marginLeft: "1em" }}
                    onClick={(e) => {
                      showConfirm();
                    }}
                  />
                  <Button
                    icon="pi pi-plus"
                    tooltip="Coseguros"
                    className="p-button-rounded p-button-primary"
                    style={{ marginLeft: "1em" }}
                    onClick={(e) => {
                      cosegurosClick();
                    }}
                  />
                  <Button
                    icon="pi pi-check"
                    tooltip="Pagos"
                    className="p-button-rounded p-button-primary"
                    style={{ marginLeft: "1em" }}
                    onClick={(e) => {
                      pagosClick();
                    }}
                  />
                  <Button
                    icon="pi pi-pencil"
                    tooltip="Ajustes Pendiente"
                    className="p-button-rounded p-button-danger"
                    style={{ marginLeft: "1em" }}
                    onClick={editClick}
                  />
                  <Button
                    icon="pi pi-trash"
                    tooltip="Borrar Registro"
                    className="p-button-rounded p-button-danger"
                    style={{ marginLeft: "1em" }}
                    onClick={borrarClick}
                  />
                  <Button
                    icon="pi pi-lock"
                    tooltip="Cierre Mensual"
                    className="p-button-rounded p-button-primary"
                    style={{ marginLeft: "1em" }}
                    onClick={(e) => {
                      if (datosPlanilla.length > 0) {
                        cierreClick();
                      }
                    }}
                  />
                  <Button
                    icon="pi pi-times"
                    tooltip="Cerrar"
                    className="p-button-rounded p-button-primary"
                    style={{ marginLeft: "1em" }}
                    onClick={exitClick}
                  />

                  <button
                    id="facturacionMensual"
                    type="button"
                    onClick={(e) => {
                      facturacion();
                    }}
                    style={{ visibility: "hidden" }}
                  >
                    Facturacion Mensual
                  </button>
                </div>
                <div className="datatable-responsive-demo tablafija">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={datosPlanilla}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      footer={footer}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{
                        fontSize: "small",
                        marginTop:
                          isMobile && !isPortrait
                            ? "30vw"
                            : isMobile && isPortrait
                            ? "94vw"
                            : "1vw",
                      }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={100}
                      rowsPerPageOptions={[10, 20, 50, 100, 200]}
                      responsiveLayout="scroll"

                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="numero"
                        header="ID"
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Apellido y Nombre"
                        body={nombreTemplate}
                        style={{ width: "20%" }}
                        sortable
                      ></Column>
                      <Column
                        field="obraSocial"
                        header="Obra Social"
                        body={obraSocialTemplate}
                        style={{ width: "15%" }}
                        sortable
                      ></Column>
                      <Column
                        field="mensual"
                        header="Mensual"
                        body={mensualTemplate}
                        style={{ width: "8%" }}
                        sortable
                        align="right"
                      ></Column>
                      <Column
                        field="iva"
                        header="I.V.A."
                        body={ivaTemplate}
                        style={{ width: "8%" }}
                        sortable
                        align="right"
                      ></Column>
                      <Column
                        field="total"
                        header="Total"
                        body={totalTemplate}
                        style={{ width: "8%" }}
                        sortable
                        align="right"
                      ></Column>
                      <Column
                        field="coseguro"
                        header="Coseguro"
                        body={coseguroTemplate}
                        style={{ width: "8%" }}
                        sortable
                        align="right"
                      ></Column>
                      <Column
                        field="panales"
                        header="Pañales"
                        body={panalesTemplate}
                        style={{ width: "8%" }}
                        align="right"
                        sortable
                      ></Column>
                      <Column
                        field="estudios"
                        header="Estudios"
                        body={estudiosTemplate}
                        style={{ width: "8%" }}
                        sortable
                        align="right"
                      ></Column>
                      <Column
                        field="efectivo"
                        header="Ingreso Efectivo"
                        body={efectivoTemplate}
                        style={{ width: "8%" }}
                        sortable
                        align="right"
                      ></Column>
                      <Column
                        field="importeObraSocial"
                        header="Obra Social"
                        body={importeObraSocialTemplate}
                        style={{ width: "8%" }}
                        sortable
                        align="right"
                      ></Column>
                      <Column
                        field="ajuste"
                        header="Ajustes"
                        body={ajusteTemplate}
                        style={{ width: "7.5%" }}
                        sortable
                        align="right"
                      ></Column>

                      <Column
                        field="pendiente"
                        header="Pendiente Efectivo"
                        body={pendienteTemplate}
                        style={{ width: "8%" }}
                        sortable
                        align="right"
                      ></Column>
                      <Column
                        field="ingreso"
                        header="Ingreso"
                        body={ingresoTemplate}
                        style={{ width: "15%" }}
                        sortable
                        align="right"
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeaderPagos()}
                visible={pagosVisible}
                closable={false}
                footer={renderFooterPagos()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "45vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <div className="datatable-responsive-demo">
                    <div className="card">
                      <Tooltip
                        target=".export-buttons>button"
                        position="bottom"
                      />
                      <DataTable
                        value={datosPagos}
                        selection={selectedRowPagos}
                        onSelectionChange={(e) => setSelectedRowPagos(e.value)}
                        selectionMode="single"
                        dataKey="key"
                        //header={header}
                        className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                        style={{ fontSize: "small", marginTop: "1vw" }}
                        //globalFilter={globalFilter}
                        emptyMessage="No hay datos."
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50, 100, 200]}
                        responsiveLayout="scroll"

                        //paginatorLeft={paginatorLeft}
                        //paginatorRight={paginatorRight}
                      >
                        <Column
                          field="key"
                          header="Key"
                          style={{ display: "none" }}
                        ></Column>
                        <Column
                          field="id"
                          header="Id"
                          style={{ display: "none" }}
                        ></Column>
                        <Column
                          field="descripcion"
                          header="Descripción"
                          body={descripcionTemplate}
                          sortable
                        ></Column>
                        <Column
                          field="pendiente"
                          header="Pendiente"
                          body={saldoTemplate}
                          sortable
                          align="right"
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderCoseguro()}
                visible={cosegurosVisible}
                closable={false}
                footer={renderFooterCoseguro()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "45vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={1} style={{ marginTop: "1vw" }}>
                  <Grid item xs={6} md={6} style={{ marginTop: "-1vw" }}>
                    <TextField
                      id="periodo"
                      label="Período"
                      fullWidth
                      variant="standard"
                      disabled
                      value={periodo}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={6} style={{ marginTop: "-1vw" }}>
                    <TextField
                      id="fecha"
                      label="Fecha"
                      fullWidth
                      variant="standard"
                      disabled
                      value={new Date().toLocaleDateString()}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Tipo
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="panales"
                          control={<Radio />}
                          label="Pañales"
                        />
                        {/*
                        <FormControlLabel
                          value="iva"
                          control={<Radio />}
                          label="I.V.A."
                        />
                        */}
                        <FormControlLabel
                          value="estudios"
                          control={<Radio />}
                          label="Estudios"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={coseguro ? coseguro.importe : null}
                      id="importe"
                      label="Importe"
                      type="number"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarCoseguro({
                          ...coseguro,
                          importe: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={10} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={coseguro ? coseguro.descripcion : null}
                      id="descripcion"
                      label="Descripción"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarCoseguro({
                          ...coseguro,
                          descripcion: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "45vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={1} style={{ marginTop: "1vw" }}>
                  <Grid item xs={12} md={10} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={ajuste ? ajuste : null}
                      id="ajuste"
                      label="Ajuste de Pendientes (Negativo resta y Positivo suma)"
                      type="number"
                      fullWidth
                      variant="standard"
                      onChange={(e) => setAjuste(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
      {exit ? <Navigate to="/login" /> : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
