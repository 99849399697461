import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";

export default function ChequeoEspacios(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    chequeoEspacios,
    statusAcceso,
    statusAcceso3,
    localidades,
    guardarChequeoEspacios,
    obtenerAcceso,
  } = serviciosContext;

  const handleChangePregunta1 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta1: event.target.checked,
    });
  };

  const handleChangePregunta2 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta2: event.target.checked,
    });
  };

  const handleChangePregunta3 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta3: event.target.checked,
    });
  };

  const handleChangePregunta4 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta4: event.target.checked,
    });
  };

  const handleChangePregunta5 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta5: event.target.checked,
    });
  };

  const handleChangePregunta6 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta6: event.target.checked,
    });
  };

  const handleChangePregunta7 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta7: event.target.checked,
    });
  };

  const handleChangePregunta8 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta8: event.target.checked,
    });
  };

  const handleChangePregunta9 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta9: event.target.checked,
    });
  };

  const handleChangePregunta10 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta10: event.target.checked,
    });
  };

  const handleChangePregunta11 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta11: event.target.checked,
    });
  };

  const handleChangePregunta12 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta12: event.target.checked,
    });
  };

  const handleChangePregunta13 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta13: event.target.checked,
    });
  };

  const handleChangePregunta14 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta14: event.target.checked,
    });
  };

  const handleChangePregunta15 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta15: event.target.checked,
    });
  };

  const handleChangePregunta16 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta16: event.target.checked,
    });
  };

  const handleChangePregunta17 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta17: event.target.checked,
    });
  };

  const handleChangePregunta18 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta18: event.target.checked,
    });
  };

  const handleChangePregunta19 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta19: event.target.checked,
    });
  };

  const handleChangePregunta20 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta20: event.target.checked,
    });
  };

  const handleChangePregunta21 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta21: event.target.checked,
    });
  };

  const handleChangePregunta22 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta22: event.target.checked,
    });
  };

  const handleChangePregunta23 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta23: event.target.checked,
    });
  };

  const handleChangePregunta24 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta24: event.target.checked,
    });
  };

  const handleChangePregunta25 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta25: event.target.checked,
    });
  };

  const handleChangePregunta26 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta26: event.target.checked,
    });
  };

  const handleChangePregunta27 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta27: event.target.checked,
    });
  };

  const handleChangePregunta28 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta28: event.target.checked,
    });
  };

  const handleChangePregunta29 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta29: event.target.checked,
    });
  };
  const handleChangePregunta30 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta30: event.target.checked,
    });
  };
  const handleChangePregunta31 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta31: event.target.checked,
    });
  };
  const handleChangePregunta32 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta32: event.target.checked,
    });
  };
  const handleChangePregunta33 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta33: event.target.checked,
    });
  };
  const handleChangePregunta34 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta34: event.target.checked,
    });
  };
  const handleChangePregunta35 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta35: event.target.checked,
    });
  };
  const handleChangePregunta36 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta36: event.target.checked,
    });
  };
  const handleChangePregunta37 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta37: event.target.checked,
    });
  };
  const handleChangePregunta38 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta38: event.target.checked,
    });
  };
  const handleChangePregunta39 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta39: event.target.checked,
    });
  };
  const handleChangePregunta40 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta40: event.target.checked,
    });
  };
  const handleChangePregunta41 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta41: event.target.checked,
    });
  };
  const handleChangePregunta42 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta42: event.target.checked,
    });
  };
  const handleChangePregunta43 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta43: event.target.checked,
    });
  };

  const handleChangePregunta44 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta44: event.target.checked,
    });
  };

  const handleChangePregunta45 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta45: event.target.checked,
    });
  };

  const handleChangePregunta46 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta46: event.target.checked,
    });
  };

  const handleChangePregunta47 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta47: event.target.checked,
    });
  };

  const handleChangePregunta48 = (event) => {
    guardarChequeoEspacios({
      ...chequeoEspacios,
      pregunta48: event.target.checked,
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  //console.log(chequeoEspacios);

  return (
    <Fragment>
      <Fragment>
        <Grid
          container
          spacing={3}
          style={{ marginTop: "0vw", marginLeft: "0vw" }}
        >
          <Grid item xs={12} md={7} style={{ marginTop: "1vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEspacios ? chequeoEspacios.pregunta1 : null}
                    onChange={handleChangePregunta1}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Entrada. Puerta. Limpia. Cerradura. Timbre."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "0vw" }}>
            <TextField
              id="comentario1"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario1 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario1: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEspacios ? chequeoEspacios.pregunta2 : null}
                    onChange={handleChangePregunta2}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Tiene placa registral."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario2"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario2 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario2: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEspacios ? chequeoEspacios.pregunta3 : null}
                    onChange={handleChangePregunta3}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Iluminación funcionando, completa con lámpara y plafón."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario3"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario3 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario3: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEspacios ? chequeoEspacios.pregunta4 : null}
                    onChange={handleChangePregunta4}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Pintura en techos y paredes."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario4"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario4 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario4: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEspacios ? chequeoEspacios.pregunta5 : null}
                    onChange={handleChangePregunta5}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Pasamanos firmes a la pared y tranqueras con traba."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario5"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario5 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario5: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEspacios ? chequeoEspacios.pregunta6 : null}
                    onChange={handleChangePregunta6}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Luz de emergencia."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario6"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario6 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario6: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEspacios ? chequeoEspacios.pregunta7 : null}
                    onChange={handleChangePregunta7}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Rampas de acceso con barandas a ambos lados."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario7"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario7 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario7: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEspacios ? chequeoEspacios.pregunta8 : null}
                    onChange={handleChangePregunta8}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Antideslizante. Señalización principio y fin."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario8"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario8 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario8: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoEspacios ? chequeoEspacios.pregunta9 : null}
                    onChange={handleChangePregunta9}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Señalización de salida de emergencia."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario9"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario9 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario9: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta10 : null
                    }
                    onChange={handleChangePregunta10}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Temperatura acorde a estación."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario10"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario10 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario10: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta11 : null
                    }
                    onChange={handleChangePregunta11}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Teclas de luz con módulo completo y funcionando."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario11"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario11 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario11: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta12 : null
                    }
                    onChange={handleChangePregunta12}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Mobiliario en buen estado mesas con regatones, firmes con la tapa atornillada."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario12"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario12 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario12: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta13 : null
                    }
                    onChange={handleChangePregunta13}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Sillas con cuerina sana, con regatones."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario13"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario13 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario13: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta14 : null
                    }
                    onChange={handleChangePregunta14}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Reloj control horario."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario14"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario14 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario14: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta15 : null
                    }
                    onChange={handleChangePregunta15}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Libro de registro de visitas."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario15"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario15 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario15: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta16 : null
                    }
                    onChange={handleChangePregunta16}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Libro de registro de personal."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario16"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario16 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario16: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta17 : null
                    }
                    onChange={handleChangePregunta17}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Dispenser de alcohol."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario17"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario17 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario17: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta18 : null
                    }
                    onChange={handleChangePregunta18}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Controlar aparición de humedades."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario18"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario18 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario18: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta19 : null
                    }
                    onChange={handleChangePregunta19}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Carros de transporte con ruedas y en buen estado."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario19"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario19 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario19: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta20 : null
                    }
                    onChange={handleChangePregunta20}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Controlar en buen funcionamiento tv, soportes y equipos de música."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario20"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario20 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario20: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta21 : null
                    }
                    onChange={handleChangePregunta21}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Libro de recepción de mercaderias."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario21"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario21 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario21: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta22 : null
                    }
                    onChange={handleChangePregunta22}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Control de temp. al acceso."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario22"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario22 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario22: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta23 : null
                    }
                    onChange={handleChangePregunta23}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cámaras de video."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario23"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario23 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario23: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta24 : null
                    }
                    onChange={handleChangePregunta24}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Escalera. Escalones Antideslizante."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario24"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario24 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario24: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta25 : null
                    }
                    onChange={handleChangePregunta25}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Barandas a ambos lados."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario25"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario25 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario25: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta26 : null
                    }
                    onChange={handleChangePregunta26}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Señalizadas el primer y último escalón."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario26"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario26 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario26: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta27 : null
                    }
                    onChange={handleChangePregunta27}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cuadros."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario27"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario27 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario27: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta28 : null
                    }
                    onChange={handleChangePregunta28}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Ambientación acorde."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario28"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario28 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario28: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta29 : null
                    }
                    onChange={handleChangePregunta29}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Reloj."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario29"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario29 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario29: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta30 : null
                    }
                    onChange={handleChangePregunta30}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Espejos."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario30"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario30 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario30: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta31 : null
                    }
                    onChange={handleChangePregunta31}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Almanaque."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario31"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario31 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario31: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta32 : null
                    }
                    onChange={handleChangePregunta32}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Radio."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario32"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario32 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario32: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta33 : null
                    }
                    onChange={handleChangePregunta33}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="TV con dispositivo para internet."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario33"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario33 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario33: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta34 : null
                    }
                    onChange={handleChangePregunta34}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Dispenser para agua caliente y fría."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario34"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario34 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario34: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta35 : null
                    }
                    onChange={handleChangePregunta35}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Ventanas con vidrios sanos funcionando con mosquiteros."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario35"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario35 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario35: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta36 : null
                    }
                    onChange={handleChangePregunta36}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Ventanas con cortinas en condiciones."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario36"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario36 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario36: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta37 : null
                    }
                    onChange={handleChangePregunta37}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Puertas con picaportes."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario37"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario37 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario37: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta38 : null
                    }
                    onChange={handleChangePregunta38}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Ventilador."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario38"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario38 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario38: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta39 : null
                    }
                    onChange={handleChangePregunta39}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Luz de emergencia."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario39"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario39 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario39: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta40 : null
                    }
                    onChange={handleChangePregunta40}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Patios limpios."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario40"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario40 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario40: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta41 : null
                    }
                    onChange={handleChangePregunta41}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Plantas. Mantenimiento, órden."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario41"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario41 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario41: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta42 : null
                    }
                    onChange={handleChangePregunta42}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Salidas de emergencia. Demarcadas."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario42"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario42 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario42: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta43 : null
                    }
                    onChange={handleChangePregunta43}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Barral antipánico."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario43"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario43 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario43: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta44 : null
                    }
                    onChange={handleChangePregunta44}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Lugar para visitas."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario44"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario44 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario44: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta45 : null
                    }
                    onChange={handleChangePregunta45}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Lugar para reuniones."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario45"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario45 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario45: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta46 : null
                    }
                    onChange={handleChangePregunta46}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Depósito de limpieza limpio y ordenado."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario46"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario46 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario46: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta47 : null
                    }
                    onChange={handleChangePregunta47}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Hay objetos que no corresponden al sitio."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario47"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario47 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario47: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={7} style={{ marginTop: "0vw" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      chequeoEspacios ? chequeoEspacios.pregunta48 : null
                    }
                    onChange={handleChangePregunta48}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Calefacción."
                labelPlacement="end"
                //disabled={chequeoEspacios && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
            <TextField
              id="comentario48"
              label="COMENTARIO / DESVÍO"
              variant="standard"
              fullWidth
              value={chequeoEspacios ? chequeoEspacios.comentario48 : null}
              onChange={(e) => {
                guardarChequeoEspacios({
                  ...chequeoEspacios,
                  comentario48: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} />
        </Grid>
      </Fragment>
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
