import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import test from "../../assets/images/test.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

export default function EscalaValoracionMental(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    escalaValoracionMental,
    statusAcceso3,
    statusAcceso4,
    localidades,
    guardarEscalaValoracionMental,
    obtenerAcceso3,
    obtenerAcceso4,
  } = serviciosContext;

  useEffect(() => {
    obtenerAcceso4({
      usuarioId: state.usuario.id,
      layout: "Psicologia",
    });
    obtenerAcceso3({
      usuarioId: state.usuario.id,
      layout: "EditarPIHC",
    });
  }, []);

  const handleChangePregunta1 = (event) => {
    guardarEscalaValoracionMental({
      ...escalaValoracionMental,
      pregunta1: event.target.checked,
      pregunta1Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta2 = (event) => {
    guardarEscalaValoracionMental({
      ...escalaValoracionMental,
      pregunta2: event.target.checked,
      pregunta2Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta3 = (event) => {
    guardarEscalaValoracionMental({
      ...escalaValoracionMental,
      pregunta3: event.target.checked,
      pregunta3Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta4 = (event) => {
    guardarEscalaValoracionMental({
      ...escalaValoracionMental,
      pregunta4: event.target.checked,
      pregunta4Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta5 = (event) => {
    guardarEscalaValoracionMental({
      ...escalaValoracionMental,
      pregunta5: event.target.value,
    });
  };

  const handleChangePregunta6 = (event) => {
    guardarEscalaValoracionMental({
      ...escalaValoracionMental,
      pregunta6: event.target.checked,
      pregunta6Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta7 = (event) => {
    guardarEscalaValoracionMental({
      ...escalaValoracionMental,
      pregunta7: event.target.checked,
      pregunta7Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta8 = (event) => {
    guardarEscalaValoracionMental({
      ...escalaValoracionMental,
      pregunta8: event.target.checked,
      pregunta8Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta9 = (event) => {
    guardarEscalaValoracionMental({
      ...escalaValoracionMental,
      pregunta9: event.target.checked,
      pregunta9Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta10 = (event) => {
    guardarEscalaValoracionMental({
      ...escalaValoracionMental,
      pregunta10: event.target.checked,
      pregunta10Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta11 = (event) => {
    guardarEscalaValoracionMental({
      ...escalaValoracionMental,
      pregunta11: event.target.checked,
      pregunta11Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta12 = (event) => {
    guardarEscalaValoracionMental({
      ...escalaValoracionMental,
      pregunta12: event.target.checked,
      pregunta12Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta13 = (event) => {
    guardarEscalaValoracionMental({
      ...escalaValoracionMental,
      pregunta13: event.target.checked,
      pregunta13Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta14 = (event) => {
    guardarEscalaValoracionMental({
      ...escalaValoracionMental,
      pregunta14: event.target.checked,
      pregunta14Value: event.target.checked ? event.target.value : 0,
    });
  };

  const handleChangePregunta15 = (event) => {
    guardarEscalaValoracionMental({
      ...escalaValoracionMental,
      pregunta15: event.target.checked,
      pregunta15Value: event.target.checked ? event.target.value : 0,
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  //console.log(statusAcceso3);
  //console.log(statusAcceso4);

  return (
    <Fragment>
      {escalaValoracionMental ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "0vw", marginLeft: "0vw" }}
          >
            <Grid item xs={12} md={4}>
              MINIMENTAL
            </Grid>
            <Grid item xs={12} md={4} style={{ marginTop: "0vw" }}>
              <TextField
                id="fecha"
                label="Fecha"
                type="date"
                variant="standard"
                required
                disabled={
                  (escalaValoracionMental &&
                    escalaValoracionMental.status &&
                    statusAcceso3 !== 200) ||
                  (escalaValoracionMental &&
                    !escalaValoracionMental.status &&
                    statusAcceso4 !== 200)
                }
                fullWidth
                value={
                  escalaValoracionMental ? escalaValoracionMental.fecha : null
                }
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  guardarEscalaValoracionMental({
                    ...escalaValoracionMental,
                    fecha: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              Punto de corte 23-24
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="escalones"
                  style={{
                    fontWeight: "bold",
                    fontSize: "medium",
                    marginRight: "1vw",
                    marginTop: ".5vw",
                  }}
                >
                  Orientación
                </FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        escalaValoracionMental
                          ? escalaValoracionMental.pregunta1
                          : null
                      }
                      onChange={handleChangePregunta1}
                      inputProps={{ "aria-label": "controlled" }}
                      value="5"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Dígame el día - Fecha - Mes - Estación - Año"
                  labelPlacement="end"
                  disabled={
                    (escalaValoracionMental &&
                      escalaValoracionMental.status &&
                      statusAcceso3 !== 200) ||
                    (escalaValoracionMental &&
                      !escalaValoracionMental.status &&
                      statusAcceso4 !== 200)
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        escalaValoracionMental
                          ? escalaValoracionMental.pregunta2
                          : null
                      }
                      onChange={handleChangePregunta2}
                      inputProps={{ "aria-label": "controlled" }}
                      value="5"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Hospital (o lugar) - ciudad - Provincia - País"
                  labelPlacement="end"
                  disabled={
                    (escalaValoracionMental &&
                      escalaValoracionMental.status &&
                      statusAcceso3 !== 200) ||
                    (escalaValoracionMental &&
                      !escalaValoracionMental.status &&
                      statusAcceso4 !== 200)
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="escalones"
                  style={{
                    fontWeight: "bold",
                    fontSize: "medium",
                    marginRight: "1vw",
                    marginTop: ".5vw",
                  }}
                >
                  Fijación
                </FormLabel>
                <FormLabel
                  id="escalones"
                  style={{
                    fontWeight: "bold",
                    fontSize: "medium",
                    marginRight: "1vw",
                    marginTop: ".5vw",
                  }}
                >
                  (Repita estas tres palabras: Peso - Caballo – Manzana)
                </FormLabel>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        escalaValoracionMental
                          ? escalaValoracionMental.pregunta3
                          : null
                      }
                      onChange={handleChangePregunta3}
                      inputProps={{ "aria-label": "controlled" }}
                      value="3"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Repetirlas hasta que las aprenda"
                  labelPlacement="end"
                  disabled={
                    (escalaValoracionMental &&
                      escalaValoracionMental.status &&
                      statusAcceso3 !== 200) ||
                    (escalaValoracionMental &&
                      !escalaValoracionMental.status &&
                      statusAcceso4 !== 200)
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="escalones"
                  style={{
                    fontWeight: "bold",
                    fontSize: "medium",
                    marginRight: "1vw",
                    marginTop: ".5vw",
                  }}
                >
                  Concentración y Cálculo
                </FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        escalaValoracionMental
                          ? escalaValoracionMental.pregunta4
                          : null
                      }
                      onChange={handleChangePregunta4}
                      inputProps={{ "aria-label": "controlled" }}
                      value="5"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Si tiene 30 pesos. y me va dando de 3 en 3, cuantas le van quedando?"
                  labelPlacement="end"
                  disabled={
                    (escalaValoracionMental &&
                      escalaValoracionMental.status &&
                      statusAcceso3 !== 200) ||
                    (escalaValoracionMental &&
                      !escalaValoracionMental.status &&
                      statusAcceso4 !== 200)
                  }
                />
                <FormLabel
                  id="escalones"
                  style={{
                    fontWeight: "bold",
                    fontSize: "medium",
                    marginRight: "1vw",
                    marginTop: ".5vw",
                  }}
                >
                  (Asignar 1 pto. por cada respuesta correcta)
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    escalaValoracionMental
                      ? escalaValoracionMental.pregunta5
                      : null
                  }
                  onChange={handleChangePregunta5}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontSize: "medium",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Repita estos números hacia atrás: 5-9-2
                  </FormLabel>
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="1"
                    disabled={
                      (escalaValoracionMental &&
                        escalaValoracionMental.status &&
                        statusAcceso3 !== 200) ||
                      (escalaValoracionMental &&
                        !escalaValoracionMental.status &&
                        statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="2"
                    disabled={
                      (escalaValoracionMental &&
                        escalaValoracionMental.status &&
                        statusAcceso3 !== 200) ||
                      (escalaValoracionMental &&
                        !escalaValoracionMental.status &&
                        statusAcceso4 !== 200)
                    }
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="3"
                    disabled={
                      (escalaValoracionMental &&
                        escalaValoracionMental.status &&
                        statusAcceso3 !== 200) ||
                      (escalaValoracionMental &&
                        !escalaValoracionMental.status &&
                        statusAcceso4 !== 200)
                    }
                  />
                </RadioGroup>{" "}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="escalones"
                  style={{
                    fontWeight: "bold",
                    fontSize: "medium",
                    marginRight: "1vw",
                    marginTop: ".5vw",
                  }}
                >
                  Memoria
                </FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        escalaValoracionMental
                          ? escalaValoracionMental.pregunta6
                          : null
                      }
                      onChange={handleChangePregunta6}
                      inputProps={{ "aria-label": "controlled" }}
                      value="3"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Recuerda las tres palabras que le he dicho antes?"
                  labelPlacement="end"
                  disabled={
                    (escalaValoracionMental &&
                      escalaValoracionMental.status &&
                      statusAcceso3 !== 200) ||
                    (escalaValoracionMental &&
                      !escalaValoracionMental.status &&
                      statusAcceso4 !== 200)
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="escalones"
                  style={{
                    fontWeight: "bold",
                    fontSize: "medium",
                    marginRight: "1vw",
                    marginTop: ".5vw",
                  }}
                >
                  Lenguaje y Construcción
                </FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        escalaValoracionMental
                          ? escalaValoracionMental.pregunta7
                          : null
                      }
                      onChange={handleChangePregunta7}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Mostrar un bolígrafo: que es esto?"
                  labelPlacement="end"
                  disabled={
                    (escalaValoracionMental &&
                      escalaValoracionMental.status &&
                      statusAcceso3 !== 200) ||
                    (escalaValoracionMental &&
                      !escalaValoracionMental.status &&
                      statusAcceso4 !== 200)
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        escalaValoracionMental
                          ? escalaValoracionMental.pregunta8
                          : null
                      }
                      onChange={handleChangePregunta8}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Repetirlo con un reloj de pulsera"
                  labelPlacement="end"
                  disabled={
                    (escalaValoracionMental &&
                      escalaValoracionMental.status &&
                      statusAcceso3 !== 200) ||
                    (escalaValoracionMental &&
                      !escalaValoracionMental.status &&
                      statusAcceso4 !== 200)
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        escalaValoracionMental
                          ? escalaValoracionMental.pregunta9
                          : null
                      }
                      onChange={handleChangePregunta9}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Repetir esta frase: 'En un trigal había cinco perros'"
                  labelPlacement="end"
                  disabled={
                    (escalaValoracionMental &&
                      escalaValoracionMental.status &&
                      statusAcceso3 !== 200) ||
                    (escalaValoracionMental &&
                      !escalaValoracionMental.status &&
                      statusAcceso4 !== 200)
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        escalaValoracionMental
                          ? escalaValoracionMental.pregunta10
                          : null
                      }
                      onChange={handleChangePregunta10}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Si una manzana y una pera son frutas, que son el rojo y el verde?"
                  labelPlacement="end"
                  disabled={
                    (escalaValoracionMental &&
                      escalaValoracionMental.status &&
                      statusAcceso3 !== 200) ||
                    (escalaValoracionMental &&
                      !escalaValoracionMental.status &&
                      statusAcceso4 !== 200)
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        escalaValoracionMental
                          ? escalaValoracionMental.pregunta11
                          : null
                      }
                      onChange={handleChangePregunta11}
                      inputProps={{ "aria-label": "controlled" }}
                      value="2"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="un perro y un gato?"
                  labelPlacement="end"
                  disabled={
                    (escalaValoracionMental &&
                      escalaValoracionMental.status &&
                      statusAcceso3 !== 200) ||
                    (escalaValoracionMental &&
                      !escalaValoracionMental.status &&
                      statusAcceso4 !== 200)
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        escalaValoracionMental
                          ? escalaValoracionMental.pregunta12
                          : null
                      }
                      onChange={handleChangePregunta12}
                      inputProps={{ "aria-label": "controlled" }}
                      value="3"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Tome este papel con la mano derecha, dóblelo y póngalo encima de la mesa."
                  labelPlacement="end"
                  disabled={
                    (escalaValoracionMental &&
                      escalaValoracionMental.status &&
                      statusAcceso3 !== 200) ||
                    (escalaValoracionMental &&
                      !escalaValoracionMental.status &&
                      statusAcceso4 !== 200)
                  }
                />

                <FormLabel
                  id="escalones"
                  style={{
                    fontWeight: "bold",
                    fontSize: "medium",
                    marginRight: "1vw",
                    marginTop: ".5vw",
                  }}
                >
                  (Lea esto y haga lo que dice)
                </FormLabel>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        escalaValoracionMental
                          ? escalaValoracionMental.pregunta13
                          : null
                      }
                      onChange={handleChangePregunta13}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="CIERRE LOS OJOS"
                  labelPlacement="end"
                  disabled={
                    (escalaValoracionMental &&
                      escalaValoracionMental.status &&
                      statusAcceso3 !== 200) ||
                    (escalaValoracionMental &&
                      !escalaValoracionMental.status &&
                      statusAcceso4 !== 200)
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        escalaValoracionMental
                          ? escalaValoracionMental.pregunta14
                          : null
                      }
                      onChange={handleChangePregunta14}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Escriba una frase: .........."
                  labelPlacement="end"
                  disabled={
                    (escalaValoracionMental &&
                      escalaValoracionMental.status &&
                      statusAcceso3 !== 200) ||
                    (escalaValoracionMental &&
                      !escalaValoracionMental.status &&
                      statusAcceso4 !== 200)
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        escalaValoracionMental
                          ? escalaValoracionMental.pregunta15
                          : null
                      }
                      onChange={handleChangePregunta15}
                      inputProps={{ "aria-label": "controlled" }}
                      value="1"
                      style={{ marginRight: "1vw" }}
                    />
                  }
                  label="Copie este dibujo"
                  labelPlacement="end"
                  disabled={
                    (escalaValoracionMental &&
                      escalaValoracionMental.status &&
                      statusAcceso3 !== 200) ||
                    (escalaValoracionMental &&
                      !escalaValoracionMental.status &&
                      statusAcceso4 !== 200)
                  }
                />
              </FormControl>
              <img
                src={test}
                alt="Test"
                height="50"
                style={{ marginLeft: "2em", marginTop: "-0.3em" }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h5" gutterBottom component="div">
                Resultado
              </Typography>
              <Typography variant="h4" gutterBottom component="div">
                {Number(
                  escalaValoracionMental.pregunta1
                    ? escalaValoracionMental.pregunta1Value
                    : 0
                ) +
                  Number(
                    escalaValoracionMental.pregunta2
                      ? escalaValoracionMental.pregunta2Value
                      : 0
                  ) +
                  Number(
                    escalaValoracionMental.pregunta3
                      ? escalaValoracionMental.pregunta3Value
                      : 0
                  ) +
                  Number(
                    escalaValoracionMental.pregunta4
                      ? escalaValoracionMental.pregunta4Value
                      : 0
                  ) +
                  Number(
                    escalaValoracionMental.pregunta5
                      ? escalaValoracionMental.pregunta5
                      : 0
                  ) +
                  Number(
                    escalaValoracionMental.pregunta6
                      ? escalaValoracionMental.pregunta6Value
                      : 0
                  ) +
                  Number(
                    escalaValoracionMental.pregunta7
                      ? escalaValoracionMental.pregunta7Value
                      : 0
                  ) +
                  Number(
                    escalaValoracionMental.pregunta8
                      ? escalaValoracionMental.pregunta8Value
                      : 0
                  ) +
                  Number(
                    escalaValoracionMental.pregunta9
                      ? escalaValoracionMental.pregunta9Value
                      : 0
                  ) +
                  Number(
                    escalaValoracionMental.pregunta10
                      ? escalaValoracionMental.pregunta10Value
                      : 0
                  ) +
                  Number(
                    escalaValoracionMental.pregunta11
                      ? escalaValoracionMental.pregunta11Value
                      : 0
                  ) +
                  Number(
                    escalaValoracionMental.pregunta12
                      ? escalaValoracionMental.pregunta12Value
                      : 0
                  ) +
                  Number(
                    escalaValoracionMental.pregunta13
                      ? escalaValoracionMental.pregunta13Value
                      : 0
                  ) +
                  Number(
                    escalaValoracionMental.pregunta14
                      ? escalaValoracionMental.pregunta14Value
                      : 0
                  ) +
                  Number(
                    escalaValoracionMental.pregunta15
                      ? escalaValoracionMental.pregunta15Value
                      : 0
                  )}
              </Typography>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
