import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import EmailIcon from "@mui/icons-material/Email";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import { Editor } from "primereact/editor";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";


export default function Notas(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  
  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Asunto", dataKey: "asunto" },
    { title: "Texto", dataKey: "texto" },
  ];

  const [nota, guardarNota] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visiblePacientes, setVisiblePacientes] = useState(false);
  const toast = useRef(null);
  const [exit, setExit] = useState(false);
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    notas,
    pacientes,
    geriatricoElegido,
    rows,
    obtenerNotas,
    obtenerAcceso,
    agregarNota,
    actualizarNota,
    obtenerPacientes,
    setGeriatricoElegido,
    setRows,
    enviarCorreo,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Notas",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    obtenerPacientes({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    });

    obtenerNotas();
  }, []);

  const editClick = (e) => {
    if (selectedRow) {
      guardarNota(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una nota.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //props.history.push(`/nota/0`);
    setVisible(true);
    setSelectedRow(null);
    guardarNota(null);
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHidePacientes = (e) => {
    setVisiblePacientes(false);
  };

  const onVisiblePacientes = (e) => {
    setVisiblePacientes(true);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !nota ||
      nota.asunto === undefined ||
      nota.asunto === null ||
      nota.asunto.trim() === ""
    ) {
      mensajeAlerta("Notas", "El asunto es necesario !.", "error");
      return;
    }

    if (
      !nota ||
      nota.texto === undefined ||
      nota.texto === null ||
      nota.texto.trim() === ""
    ) {
      mensajeAlerta("Notas", "El texto es necesario !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarNota({ nota });
    } else {
      agregarNota({ nota });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerNotas();
    }, 2000);
    //exitClick();
  };

  const aceptarClickPacientes = () => {
    const cantidad = rows.length;

    if (cantidad > 0) {
      confirmDialog({
        message: `Desea enviar el email a ${cantidad} residentes ?`,
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si",
        accept,
        reject,
      });
    } else {
      toast.current.show({
        severity: "info",
        summary: "Notas",
        detail: "Debe seleccionar algún residente para enviar el email.",
        life: 3000,
      });
    }
  };

  const accept = () => {
    enviarCorreo({ nota: nota.texto, pacientes: rows, geriatrico: geriatricoElegido.nombre });
    actualizarNota({ nota });
    onHidePacientes();
    onHide();
  };

  const reject = () => {
    onHidePacientes();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const asuntoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Asunto</span>
        {rowData.asunto}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const telefonoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Télefono</span>
        {rowData.telefono}
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Habilitado</span>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, notas);
        doc.save("notas.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(notas);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "notas");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Notas"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Nota"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
        {selectedRow ?
        <ButtonMui
          onClick={onVisiblePacientes}
          color="error"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<EmailIcon />}
          autoFocus
        >
          Email
        </ButtonMui>
        : null }
      </div>
    );
  };

  const renderHeaderPacientes = () => {
    return (
      <div>
        <Tag value="Residentes"></Tag>
      </div>
    );
  };

  const renderFooterPacientes = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickPacientes}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHidePacientes}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(notas);
  //console.log(statusAcceso);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {notas ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
               style={{
                  align: "center",
                  margin: "1em",
                  marginTop:
                    isMobile && !isPortrait
                      ? "22vw"
                      : isMobile && isPortrait
                      ? "55vw"
                      : "0vw",
                }}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={notas}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      responsiveLayout="scroll"
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="asunto"
                        header="Asunto"
                        body={asuntoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  {selectedRow ? (
                    <Grid item xs={12} md={2}>
                      <TextField
                        value={nota ? nota.id : null}
                        required
                        id="id"
                        label="ID"
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={10}>
                    <TextField
                      value={nota ? nota.asunto : null}
                      required
                      id="asunto"
                      label="Asunto"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarNota({
                          ...nota,
                          asunto: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="card">
                      <Editor
                        style={{ height: "320px" }}
                        value={nota ? nota.texto : ""}
                        onTextChange={(e) =>
                          guardarNota({
                            ...nota,
                            texto: e.htmlValue,
                          })
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderPacientes()}
                visible={visiblePacientes}
                closable={false}
                //maximized={true}
                footer={renderFooterPacientes()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3}>
                  {pacientes ? (
                    <Fragment>
                      <Grid item xs={12} md={12}>
                        <div className="datatable-responsive-demo">
                          <div className="card">
                            <DataTable
                              value={pacientes}
                              selection={rows}
                              onSelectionChange={(e) => setRows(e.value)}
                              dataKey="id"
                              //header={header}
                              className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                              style={{ fontSize: "small" }}
                              //globalFilter={globalFilter}
                              emptyMessage="No hay datos."
                              paginator
                              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                              currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                              rows={10}
                              rowsPerPageOptions={[10, 20, 50]}
                              //paginatorLeft={paginatorLeft}
                              //paginatorRight={paginatorRight}
                            >
                              <Column
                                field="numeroObraSocial"
                                header="Nº de Beneficiario"
                                sortable
                              ></Column>
                              <Column
                                field="nombre"
                                header="Apellido y Nombre"
                                sortable
                              ></Column>
                              <Column
                                field="numeroDocumento"
                                header="Nº de Documento"
                                sortable
                              ></Column>
                              <Column
                                selectionMode="multiple"
                                headerStyle={{ width: "3em" }}
                              ></Column>
                            </DataTable>
                          </div>
                        </div>
                      </Grid>
                    </Fragment>
                  ) : null}
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
      {exit ? <Navigate to="/login" /> : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
