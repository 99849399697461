import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

export default function Norton(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const { norton, statusAcceso3, localidades, guardarNorton } =
    serviciosContext;

  const handleChangeEstadoFisico = (event) => {
    guardarNorton({
      ...norton,
      estadoFisico: event.target.value,
    });
  };

  const handleChangeEstadoMental = (event) => {
    guardarNorton({
      ...norton,
      estadoMental: event.target.value,
    });
  };

  const handleChangeActividad = (event) => {
    guardarNorton({
      ...norton,
      actividad: event.target.value,
    });
  };

  const handleChangeMovilidad = (event) => {
    guardarNorton({
      ...norton,
      movilidad: event.target.value,
    });
  };

  const handleChangeIncontinencia = (event) => {
    guardarNorton({
      ...norton,
      incontinencia: event.target.value,
    });
  };


  const handleChangeRombergSensibilizado = (event) => {
    guardarNorton({
      ...norton,
      rombergSensibilizado: event.target.value,
    });
  };

  const handleChangeRomberg = (event) => {
    guardarNorton({
      ...norton,
      romberg: event.target.value,
    });
  };


  const handleChangeGirar = (event) => {
    guardarNorton({
      ...norton,
      girar: event.target.value,
    });
  };

  const handleChangeGirar2 = (event) => {
    guardarNorton({
      ...norton,
      girar2: event.target.value,
    });
  };

  const handleChangeSentarse = (event) => {
    guardarNorton({
      ...norton,
      sentarse: event.target.value,
    });
  };


  //console.log(statusAcceso3);
  //console.log(norton);

  return (
    <Fragment>
      {norton ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "0vw", marginLeft: "0vw" }}
          >
            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="estadoFisico"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  Estado Físico General
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="estadoFisico"
                  value={norton ? norton.estadoFisico : null}
                  onChange={handleChangeEstadoFisico}
                >
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Bueno"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Mediano"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Regular"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Muy Malo"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="estadoMental"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  Estado Mental
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="estadoMental"
                  value={norton ? norton.estadoMental : null}
                  onChange={handleChangeEstadoMental}
                >
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Alerta"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Apático"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Confuso"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Estuporoso o comatoso"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="actividad"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  Actividad
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="actividad"
                  value={norton ? norton.actividad : null}
                  onChange={handleChangeActividad}
                >
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Ambulante"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Disminuida"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Muy limitada"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Inmovil"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="movilidad"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  Movilidad
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="movilidad"
                  value={norton ? norton.movilidad : null}
                  onChange={handleChangeMovilidad}
                >
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Total"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Camina con ayuda"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Sentado"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Encamado"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="incontinencia"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  Incontinencia
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="incontinencia"
                  value={norton ? norton.incontinencia : null}
                  onChange={handleChangeIncontinencia}
                >
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Ninguna"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Ocasional"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Urinaria o fecal"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Urinaria y fecal"
                    disabled={
                      norton && norton.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="caption" display="block" gutterBottom>
                Escala de Norton (Valoración del riesgo de formación de úlceras por presión)
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                La clasificación de riesgo sería:
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                - Puntuación de 5 a 11: Paciente de alto riesgo.
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
              - Puntuación de 12 a 14: Paciente de riesgo medio o evidente.
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
              - Puntuación de más de 14: Paciente de riesgo bajo o no riesgo.
              </Typography>
            </Grid>


            <Grid item xs={12} md={4}>
              <Typography variant="h5" gutterBottom component="div">
              PUNTAJE
              </Typography>
              <Typography variant="h4" gutterBottom component="div">
                {Number(norton.estadoFisico ? norton.estadoFisico : 0) +
                  Number(norton.estadoMental ? norton.estadoMental : 0) +
                  Number(norton.actividad ? norton.actividad : 0) +
                  Number(norton.movilidad ? norton.movilidad : 0) +
                  Number(norton.incontinencia ? norton.incontinencia : 0)}
              </Typography>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
