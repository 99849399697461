import React, { useState, Fragment, useContext, useRef } from "react";
import { createContext } from "react";
import clienteAxios from "../config/axios";
import { AlertContext } from "./AlertContext";
import { v4 as uuidv4 } from "uuid";

const ConfiguracionContext = createContext();
const { Provider, Consumer } = ConfiguracionContext;

function ConfiguracionProvider({ children }) {
  const [numeroTelefono, guardarNumeroTelefono] = useState(null);
  const [valBusqueda, guardarValBusqueda] = useState(null);
  const [rows, setRows] = useState(null);
  const [afiliado, guardarAfiliado] = useState(null);
  const [titular, guardarTitular] = useState(null);
  const [antecedentes, guardarAntecedentes] = useState(null);
  const [antecedente, guardarAntecedente] = useState(null);
  const [tratamientos, guardarTratamientos] = useState(null);
  const [tratamiento, guardarTratamiento] = useState(null);
  const [tiposServicios, guardarTiposServicios] = useState(null);
  const [tipoServicio, guardarTipoServicio] = useState(null);
  const [motivosConsultas, guardarMotivosConsultas] = useState(null);
  const [motivoConsulta, guardarMotivoConsulta] = useState(null);
  const [usuarios, guardarUsuarios] = useState(null);
  const [pantallas, guardarPantallas] = useState(null);
  const [colores, guardarColores] = useState([]);
  const [coloresM, guardarColoresM] = useState([]);
  const [color, guardarColor] = useState(null);
  const [configuracion, guardarConfiguracion] = useState(null);
  const [statusAcceso, setStatusAcceso] = useState(0);
  const [statusAcceso2, setStatusAcceso2] = useState(0);
  const [medicos, guardarMedicos] = useState(null);
  const [medico, guardarMedico] = useState(null);
  const [choferes, guardarChoferes] = useState(null);
  const [chofer, guardarChofer] = useState(null);
  const [enfermeros, guardarEnfermeros] = useState(null);
  const [enfermero, guardarEnfermero] = useState(null);
  const [tiposMoviles, guardarTiposMoviles] = useState(null);
  const [tipoMovil, guardarTipoMovil] = useState(null);
  const [moviles, guardarMoviles] = useState(null);
  const [movil, guardarMovil] = useState(null);
  const [telefonos, guardarTelefonos] = useState(null);
  const [telefono, guardarTelefono] = useState(null);
  const [diagnosticos, guardarDiagnosticos] = useState(null);
  const [diagnostico, guardarDiagnostico] = useState(null);
  const [almacenesMoviles, guardarAlmacenesMoviles] = useState(null);
  const [almacenMovil, guardarAlmacenMovil] = useState(null);
  const [almacenes, guardarAlmacenes] = useState(null);
  const [almacen, guardarAlmacen] = useState(null);
  const [bases, guardarBases] = useState(null);
  const [base, guardarBase] = useState(null);
  const [medicamentos, guardarMedicamentos] = useState(null);
  const [formatos, guardarFormatos] = useState([]);
  const [medicamento, guardarMedicamento] = useState(null);
  const [alertas, guardarAlertas] = useState(null);
  const [movimientoMedicamento, guardarMovimientoMedicamento] = useState(null);
  const [movimientosMedicamentos, guardarMovimientosMedicamentos] =
    useState(null);
  const [stocksMedicamentos, guardarStocksMedicamentos] = useState(null);
  const [ajustesStocksMedicamentos, guardarAjustesStocksMedicamentos] =
    useState(null);
  const [grupos, guardarGrupos] = useState(null);
  const [planes, guardarPlanes] = useState(null);
  const [coseguros, guardarCoseguros] = useState([]);
  const [coseguro, guardarCoseguro] = useState(null);
  const [coseguroServicio, guardarCoseguroServicio] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [parametros, setParametros] = useState({
    edadPediatrico: 12,
    diurnoDesde: "07:00",
    diurnoHasta: "17:00",
    nocturnoDesde: "17:00",
    nocturnoHasta: "07:00",
    medicamentos: false,
  });
  const [coseguroAbonar, guardarCoseguroAbonar] = useState(0);
  const [zonasMovilesDerivados, guardarZonasMovilesDerivados] = useState(null);

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;



  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }



  //Antecedentes

  const obtenerAntecedentes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerantecedentes",
        datos
      );

      let data = respuesta.data;

      guardarAntecedentes(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAntecedente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerantecedente",
        datos
      );

      let data = respuesta.data;

      guardarAntecedente(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarAntecedente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarantecedente",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarAntecedente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarantecedente",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Tratamientos

  const obtenerTratamientos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertratamientos",
        datos
      );

      let data = respuesta.data;

      guardarTratamientos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerTratamiento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertratamiento",
        datos
      );

      let data = respuesta.data;

      guardarTratamiento(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarTratamiento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizartratamiento",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarTratamiento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregartratamiento",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Tipos de servicios

  const obtenerTiposServicios = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertiposservicios",
        datos
      );

      let data = respuesta.data;

      guardarTiposServicios(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerTipoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertiposervicio",
        datos
      );

      let data = respuesta.data;

      guardarTipoServicio(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarTipoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizartiposervicio",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarTipoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregartiposervicio",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Motivos de consultas

  const obtenerMotivosConsultas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermotivosconsultas",
        datos
      );

      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          nombre: data[i].nombre,
          color: data[i].colores.nombre,
          colorId: data[i].id_color,
          servicio: data[i].tiposServicios.nombre,
          servicioId: data[i].id_servicio,
          cuestionario: data[i].cuestionario,
          habilitado: data[i].habilitado,
          codColor: data[i].colores.codigo,
        });
      }

      guardarMotivosConsultas(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerMotivoConsulta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermotivoconsulta",
        datos
      );

      let data = respuesta.data;

      guardarMotivoConsulta(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarMotivoConsulta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarmotivoconsulta",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarMotivoConsulta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmotivoconsulta",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Usuarios

  const obtenerUsuarios = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/configuracion/obtenerusuarios"
      );
      let data = respuesta.data;
      guardarUsuarios(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Pantallas

  const obtenerAccesos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneraccesos",
        datos
      );
      let data = respuesta.data;

      const local = [];
      const rows = [];

      if (data.response2.length === 0) {
        for (var i = 0; i < data.response.length; i++) {
          local.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: false },
          });
        }
        guardarPantallas(local);
        return;
      }

      for (var i = 0; i < data.response.length; i++) {
        let band = false;
        for (var j = 0; j < data.response2.length; j++) {
          if (data.response[i].nombre === data.response2[j].layout) {
            band = true;
          }
        }
        if (band) {
          local.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: true },
          });
          rows.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: true },
          });
          setRows(rows);
        } else {
          local.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: false },
          });
        }
      }

      guardarPantallas(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarAccesos = async (datos) => {
    const { rows, usuario } = datos;

    const local = [];

    for (var i = 0; i < rows.length; i++) {
      local.push({
        id: 0,
        id_usuario: usuario.usuarioId,
        layout: rows[i].nombre,
      });
    }

    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregaraccesos",
        { local, usuario }
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAcceso = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneracceso",
        datos
      );
      //let data = respuesta.data;
      setStatusAcceso(200);
    } catch (error) {
      setStatusAcceso(400);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };


  const obtenerAcceso2 = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneracceso",
        datos
      );
      //let data = respuesta.data;
      setStatusAcceso2(200);
    } catch (error) {
      setStatusAcceso2(400);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  //Colores

  const obtenerColores = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/configuracion/obtenercolores"
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          nombre: data[i].nombre,
          demoraLlegada: data[i].demoraLlegada,
          alertaLlegada: data[i].alertaLlegada,
          demoraSalida: data[i].demoraSalida,
          alertaSalida: data[i].alertaSalida,
          medico: data[i].medico,
          chofer: data[i].chofer,
          enfermero: data[i].enfermero,
          simultaneo: data[i].simultaneo,
          alertaPendiente: data[i].alertaPendiente,
        });
      }
      guardarColores(local);
      guardarColoresM(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerColor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercolor",
        datos
      );

      let data = respuesta.data;

      guardarColor(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarColor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarcolor",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Configuracion

  const obtenerConfiguracion = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/configuracion/obtenerconfiguracion"
      );
      let data = respuesta.data;

      guardarConfiguracion(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const actualizarConfiguracion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarconfiguracion",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Medicos

  const obtenerMedicosLiberados = async (datos) => {
    const { tripulaciones } = datos;
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermedicos",
        datos
      );

      let data = respuesta.data;
      const local = [];

      let bandMedico;
      for (var i = 0; i < data.length; i++) {
        bandMedico = false;
        for (var j = 0; j < tripulaciones.length; j++) {
          if (data[i].id === tripulaciones[j].medicoId) {
            bandMedico = true;
            break;
          }
        }

        if (!bandMedico) {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
          });
        }
      }

      guardarMedicos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerMedicos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermedicos",
        datos
      );

      let data = respuesta.data;

      guardarMedicos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerMedico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermedico",
        datos
      );

      let data = respuesta.data;

      guardarMedico(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarMedico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarmedico",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarMedico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmedico",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };


  const firmaMedico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/firmamedico",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };



  //Choferes

  const obtenerChoferesLiberados = async (datos) => {
    const { tripulaciones } = datos;
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerchoferes",
        datos
      );

      let data = respuesta.data;
      const local = [];

      let bandChofer;
      for (var i = 0; i < data.length; i++) {
        bandChofer = false;
        for (var j = 0; j < tripulaciones.length; j++) {
          if (data[i].id === tripulaciones[j].choferId) {
            bandChofer = true;
            break;
          }
        }

        if (!bandChofer) {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
          });
        }
      }

      guardarChoferes(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerChoferes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerchoferes",
        datos
      );

      let data = respuesta.data;

      guardarChoferes(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerChofer = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerchofer",
        datos
      );

      let data = respuesta.data;

      guardarChofer(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarChofer = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarchofer",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarChofer = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarchofer",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Enfermeros

  const obtenerEnfermerosLiberados = async (datos) => {
    const { tripulaciones } = datos;
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerenfermeros",
        datos
      );

      let data = respuesta.data;
      const local = [];

      let bandEnfermero;
      for (var i = 0; i < data.length; i++) {
        bandEnfermero = false;
        for (var j = 0; j < tripulaciones.length; j++) {
          if (data[i].id === tripulaciones[j].enfermeroId) {
            bandEnfermero = true;
            break;
          }
        }

        if (!bandEnfermero) {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
          });
        }
      }

      guardarEnfermeros(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerEnfermeros = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerenfermeros",
        datos
      );

      let data = respuesta.data;

      guardarEnfermeros(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerEnfermero = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerenfermero",
        datos
      );

      let data = respuesta.data;

      guardarEnfermero(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarEnfermero = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarenfermero",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarEnfermero = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarenfermero",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Tipos de móviles

  const obtenerTiposMoviles = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertiposmoviles",
        datos
      );

      let data = respuesta.data;

      guardarTiposMoviles(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerTipoMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertipomovil",
        datos
      );

      let data = respuesta.data;

      guardarTipoMovil(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarTipoMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizartipomovil",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarTipoMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregartipomovil",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Moviles

  const obtenerMoviles = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermoviles",
        datos
      );

      let data = respuesta.data;

      const local = [];

      const { propios } = datos;

      for (var i = 0; i < data.length; i++) {
        if (propios) {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
            propio: data[i].propio,
            detalle: data[i].detalle,
            matricula: data[i].matricula,
            marca: data[i].marca,
            modelo: data[i].modelo,
            seguro: data[i].seguro,
            poliza: data[i].poliza,
            tipoMovil: data[i].tiposMoviles.nombre,
            telefono: data[i].telefono,
            contacto: data[i].contacto,
            nocturnoDesde: data[i].nocturnoDesde,
            nocturnoHasta: data[i].nocturnoHasta,
            pediatrico: data[i].pediatrico,
            habilitado: data[i].habilitado,
            tipoId: data[i].tipoId,
          });
        } else {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
            propio: data[i].propio,
            detalle: data[i].detalle,
            matricula: null,
            marca: null,
            modelo: null,
            seguro: null,
            poliza: null,
            tipoMovil: null,
            telefono: data[i].telefono,
            contacto: data[i].contacto,
            nocturnoDesde: data[i].nocturnoDesde,
            nocturnoHasta: data[i].nocturnoHasta,
            pediatrico: data[i].pediatrico,
            habilitado: data[i].habilitado,
          });
        }
      }

      guardarMoviles(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerMovilesPropiosLiberados = async (datos) => {
    const { tripulaciones } = datos;

    try {
      const respuesta = await clienteAxios.get(
        "/api/configuracion/obtenermovilespropiosliberados"
      );

      let data = respuesta.data;
      const local = [];

      let bandMovil;
      for (var i = 0; i < data.length; i++) {
        bandMovil = false;
        for (var j = 0; j < tripulaciones.length; j++) {
          if (data[i].id === tripulaciones[j].movil) {
            bandMovil = true;
            break;
          }
        }

        if (!bandMovil) {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
          });
        }
      }

      guardarMoviles(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermovil",
        datos
      );

      let data = respuesta.data;

      guardarMovil(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarmovil",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmovil",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerNuevoNumeroMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenernuevonumeromovil",
        datos
      );

      let data = respuesta.data;
      const { propio } = datos;
      let proximo = Number(data) + 1;
      let nombre = "";
      if (propio) {
        nombre = "MOVIL " + proximo;
      }

      guardarMovil({
        ...movil,
        id: proximo,
        nombre,
        propio,
        detalle: "",
        matricula: "",
        marca: "",
        modelo: "",
        seguro: "",
        poliza: "",
        id_tipo: null,
        telefono: "",
        contacto: "",
        nocturnoDesde: "",
        nocturnoHasta: "",
        pediatrico: "",
        habilitado: true,
      });
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerZonasMovilesDerivados = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/configuracion/obtenerzonasmovilesderivados",
      );

      let data = respuesta.data;
      guardarZonasMovilesDerivados(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };



  //Telefonos

  const obtenerTelefonos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertelefonos",
        datos
      );

      let data = respuesta.data;

      guardarTelefonos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerTelefonosLiberados = async (datos) => {
    const { tripulaciones } = datos;
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertelefonos",
        datos
      );

      let data = respuesta.data;
      const local = [];

      let bandTelefono;
      for (var i = 0; i < data.length; i++) {
        bandTelefono = false;
        for (var j = 0; j < tripulaciones.length; j++) {
          if (data[i].id === tripulaciones[j].telefonoId) {
            bandTelefono = true;
            break;
          }
        }
        if (!bandTelefono && data[i].registrado) {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
          });
        }
      }

      guardarTelefonos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };


  const obtenerTelefono = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertelefono",
        datos
      );

      let data = respuesta.data;

      guardarTelefono(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarTelefono = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizartelefono",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarTelefono = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregartelefono",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Diagnosticos

  const obtenerDiagnosticos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerdiagnosticos",
        datos
      );

      let data = respuesta.data;

      guardarDiagnosticos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerDiagnostico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerdiagnostico",
        datos
      );

      let data = respuesta.data;

      guardarDiagnostico(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarDiagnostico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizardiagnostico",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarDiagnostico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregardiagnostico",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Grupos

  const obtenerGrupos = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/configuracion/obtenergrupos"
      );

      let data = respuesta.data;

      guardarGrupos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Bases

  const obtenerBases = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerbases",
        datos
      );

      let data = respuesta.data;

      guardarBases(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerBase = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerbase",
        datos
      );

      let data = respuesta.data;

      guardarBase(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarBase = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarbase",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarBase = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarbase",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Alertas

  const obtenerAlertas = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/configuracion/obteneralertas"
      );

      let data = respuesta.data;

      guardarAlertas(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarAlertas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizaralertas",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerCoseguros = async (datos) => {
    const { grupo } = datos;

    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercoseguros",
        datos
      );

      let dato = [];
      let band;
      let uid;
      let indexH;

      if (grupo === 1) {
        let planes = respuesta.data["response"];
        let tiposServicios = respuesta.data["response2"];
        let coseguros = respuesta.data["response3"];

        for (var i = 0; i < planes.length; i++) {
          for (var j = 0; j < tiposServicios.length; j++) {
            band = false;
            for (var h = 0; h < coseguros.length; h++) {
              if (
                planes[i].id_codigo === coseguros[h].planId &&
                tiposServicios[j].id === coseguros[h].servicioId
              ) {
                band = true;
                indexH = h;
              }
            }
            if (!band) {
              uid = uuidv4();
              dato.push({
                id: uid,
                grupoId: 1,
                AfiliadoId: null,
                planId: planes[i].id_codigo,
                planNombre: planes[i].nombre,
                servicioId: tiposServicios[j].id,
                servicioNombre: tiposServicios[j].nombre,
                valorDiurno: 0,
                valorNocturno: 0,
              });
            } else {
              dato.push({
                id: coseguros[indexH].id,
                grupoId: 1,
                AfiliadoId: null,
                planId: planes[i].id_codigo,
                planNombre: planes[i].nombre,
                servicioId: tiposServicios[j].id,
                servicioNombre: tiposServicios[j].nombre,
                valorDiurno: coseguros[indexH].valorDiurno,
                valorNocturno: coseguros[indexH].valorNocturno,
              });
            }
          }
        }
        guardarCoseguros(dato);
      }
      if (grupo > 1) {
        let socios = respuesta.data["response"];
        let tiposServicios = respuesta.data["response2"];
        let coseguros = respuesta.data["response3"];
        for (var i = 0; i < socios.length; i++) {
          for (var j = 0; j < tiposServicios.length; j++) {
            band = false;
            for (var h = 0; h < coseguros.length; h++) {
              if (
                socios[i].id === coseguros[h].afiliadoId &&
                tiposServicios[j].id === coseguros[h].servicioId
              ) {
                band = true;
                indexH = h;
              }
            }
            if (!band) {
              uid = uuidv4();
              dato.push({
                id: uid,
                grupoId: grupo,
                afiliadoId: socios[i].id,
                planId: socios[i].id_plan_facturacion,
                afiliadoNombre: socios[i].nombre_razonsocial,
                servicioId: tiposServicios[j].id,
                servicioNombre: tiposServicios[j].nombre,
                valorDiurno: 0,
                valorNocturno: 0,
              });
            } else {
              dato.push({
                id: coseguros[indexH].id,
                grupoId: 2,
                afiliadoId: socios[i].id,
                planId: socios[i].id_plan_facturacion,
                afiliadoNombre: socios[i].nombre_razonsocial,
                servicioId: tiposServicios[j].id,
                servicioNombre: tiposServicios[j].nombre,
                valorDiurno: coseguros[indexH].valorDiurno,
                valorNocturno: coseguros[indexH].valorNocturno,
              });
            }
          }
        }
        guardarCoseguros(dato);
      }
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerCoseguroServicio = async (datos) => {
    const { afiliado, titular, nombre } = datos;

    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercoseguroservicio",
        datos
      );

      let d2 = new Date();
      let h2 = d2.toLocaleString("es-AR", {
        hour: "numeric",
        minute: "numeric",
      });
      let data = respuesta.data;

      if (data !== null) {
        if (h2 > parametros.diurnoDesde && h2 <= parametros.diurnoHasta) {
          guardarCoseguroServicio({
            valor: Number(parseFloat(data.valorDiurno).toFixed(2)),
            cartel: parseFloat(data.valorDiurno).toFixed(2),
            afiliado: titular.id_titular,
            plan:
              titular.planes.nombre.charAt(0).toUpperCase() +
              titular.planes.nombre.slice(1).toLowerCase(),
            servicio:
              nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase(),
            mensaje:
              data.afiliadoId === null
                ? titular.planes.nombre.charAt(0).toUpperCase() +
                  titular.planes.nombre.slice(1).toLowerCase()
                : titular.nombre_razonsocial,
          });
          guardarCoseguroAbonar(
            Number(parseFloat(data.valorDiurno).toFixed(2))
          );
        } else {
          guardarCoseguroServicio({
            valor: Number(parseFloat(data.valorNocturno).toFixed(2)),
            cartel: parseFloat(data.valorNocturno).toFixed(2),
            afiliado: titular.id_titular,
            plan:
              titular.planes.nombre.charAt(0).toUpperCase() +
              titular.planes.nombre.slice(1).toLowerCase(),
            servicio:
              nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase(),
            mensaje:
              data.afiliadoId === null
                ? titular.planes.nombre.charAt(0).toUpperCase() +
                  titular.planes.nombre.slice(1).toLowerCase()
                : titular.nombre_razonsocial,
          });
          guardarCoseguroAbonar(
            Number(parseFloat(data.valorNocturno).toFixed(2))
          );
        }
      } else {
        guardarCoseguroServicio({
          valor: 0,
          cartel: "No está valorizado",
          afiliado: titular.id_titular,
          plan:
            titular.planes.nombre.charAt(0).toUpperCase() +
            titular.planes.nombre.slice(1).toLowerCase(),
          servicio:
            nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase(),
          mensaje:
            titular.id_grupo === 1
              ? titular.planes.nombre.charAt(0).toUpperCase() +
                titular.planes.nombre.slice(1).toLowerCase()
              : titular.nombre_razonsocial,
        });
        guardarCoseguroAbonar(Number(0));
      }
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerCoseguro = async (datos) => {
    const { idCoseguro } = datos;
    let index = coseguros.findIndex((item) => item.id === idCoseguro);
    guardarCoseguro(coseguros[index]);
  };

  const actualizarCoseguro = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarcoseguro",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const eliminarCoseguro = async (id) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/eliminarcoseguro",
        { id }
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Medicamentos

 
  const obtenerMedicamentos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermedicamentos",
        datos
      );

      let data = respuesta.data;

      guardarMedicamentos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerMedicamentosSku = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermedicamentos",
        datos
      );

      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          sku: data[i].sku,
          descripcion: data[i].sku + " - " + data[i].nombre,
        });
      }

      guardarMedicamentos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerMedicamento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermedicamento",
        datos
      );

      let data = respuesta.data;

      guardarMedicamento(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarMedicamento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarmedicamento",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarMedicamento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmedicamento",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Almacenes

  const obtenerAlmacenesMoviles = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/configuracion/obteneralmacenesmoviles"
      );

      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          almacen: data[i].almacenes.nombre,
          almacenId: data[i].almacenId,
          movil: data[i].moviles.nombre,
          movilId: data[i].movilId,
        });
      }

      guardarAlmacenesMoviles(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAlmacenMovil = async (datos) => {

    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneralmacenmovil",
        datos
      );


      let data = respuesta.data;
      guardarAlmacenMovil(data);

    } catch (error) {
      console.log(error.response);
      guardarAlmacenMovil(null);

      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAlmacenes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneralmacenes",
        datos
      );

      let data = respuesta.data;

      guardarAlmacenes(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAlmacen = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneralmacen",
        datos
      );

      let data = respuesta.data;

      console.log(data);

      guardarAlmacen(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarAlmacen = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizaralmacen",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarAlmacen = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregaralmacen",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarAlmacenMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregaralmacenmovil",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const eliminarAlmacenMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/eliminaralmacenmovil",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Movimientos medicamentos

  const obtenerMovimientosMedicamentos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermovimientosmedicamentos",
        datos
      );

      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          sku: data[i].sku,
          fecha: formatDateTime(data[i].fecha),
          nombre: data[i].medicamentos.nombre,
          cantidad: data[i].cantidad,
          almacen: data[i].almacenes.nombre,
          tipo: data[i].tipo === "E" ? "ENTRADA" : "SALIDA",
          descripcion: data[i].descripcion,
        });
      }

      guardarMovimientosMedicamentos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerStocksMedicamentos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerstocksmedicamentos",
        datos
      );

      let data = respuesta.data;
      
      //console.log(data)
      
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          sku: data[i].sku,
          nombre: data[i].medicamentos.nombre,
          stock: data[i].stock,
          almacen: data[i].almacenes.nombre,
          almacenId: data[i].almacenId,
        });
      }

      guardarStocksMedicamentos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAjustesStocksMedicamentos = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/configuracion/obtenerajustesstocksmedicamentos"
      );

      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          sku: data[i].sku,
          nombre: data[i].medicamentos.nombre,
          stockAnterior: data[i].stockAnterior,
          stockPosterior: data[i].stockPosterior,
          almacen: data[i].almacenes.nombre,
          almacenId: data[i].almacenId,
          usuario: data[i].usuario,
        });
      }

      guardarAjustesStocksMedicamentos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const ajustarStockMedicamento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/ajustarstockmedicamento",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerMovimientoMedicamento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermovimientomedicamento",
        datos
      );

      let data = respuesta.data;

      guardarMovimientoMedicamento(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarMovimientoMedicamento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarmovimientomedicamento",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarMovimientoMedicamento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmovimientomedicamento",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  // Recetas

  const agregarReceta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarreceta",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };




  return (
    <Fragment>
      <Provider
        value={{
          antecedentes,
          antecedente,
          tratamientos,
          tratamiento,
          tiposServicios,
          tipoServicio,
          motivosConsultas,
          motivoConsulta,
          usuarios,
          pantallas,
          configuracion,
          rows,
          statusAcceso,
          statusAcceso2,
          colores,
          color,
          medicos,
          medico,
          choferes,
          chofer,
          enfermeros,
          enfermero,
          tiposMoviles,
          tipoMovil,
          moviles,
          movil,
          telefonos,
          telefono,
          diagnosticos,
          diagnostico,
          almacenes,
          almacenesMoviles,
          almacenMovil,
          bases,
          base,
          almacen,
          alertas,
          medicamentos,
          formatos,
          medicamento,
          movimientosMedicamentos,
          movimientoMedicamento,
          planes,
          coseguro,
          coseguros,
          activeIndex,
          grupos,
          coseguroServicio,
          parametros,
          coseguroAbonar,
          stocksMedicamentos,
          ajustesStocksMedicamentos,
          zonasMovilesDerivados,
          coloresM,
          obtenerAntecedentes,
          obtenerAntecedente,
          agregarAntecedente,
          actualizarAntecedente,
          guardarAntecedente,
          obtenerTratamientos,
          obtenerTratamiento,
          agregarTratamiento,
          actualizarTratamiento,
          guardarTratamiento,
          obtenerTiposServicios,
          obtenerTipoServicio,
          agregarTipoServicio,
          actualizarTipoServicio,
          guardarTipoServicio,
          obtenerMotivosConsultas,
          obtenerMotivoConsulta,
          agregarMotivoConsulta,
          actualizarMotivoConsulta,
          guardarMotivoConsulta,
          obtenerUsuarios,
          agregarAccesos,
          obtenerAccesos,
          obtenerConfiguracion,
          setRows,
          obtenerAcceso,
          obtenerAcceso2,
          obtenerColores,
          obtenerColor,
          actualizarColor,
          guardarColor,
          obtenerMedicos,
          obtenerMedicosLiberados,
          obtenerMedico,
          agregarMedico,
          actualizarMedico,
          guardarMedico,
          obtenerChoferes,
          obtenerChoferesLiberados,
          obtenerChofer,
          agregarChofer,
          actualizarChofer,
          guardarChofer,
          obtenerEnfermeros,
          obtenerEnfermerosLiberados,
          obtenerEnfermero,
          agregarEnfermero,
          actualizarEnfermero,
          guardarEnfermero,
          obtenerTiposMoviles,
          obtenerTipoMovil,
          agregarTipoMovil,
          actualizarTipoMovil,
          guardarTipoMovil,
          obtenerMoviles,
          obtenerMovil,
          agregarMovil,
          actualizarMovil,
          guardarMovil,
          obtenerMovilesPropiosLiberados,
          obtenerNuevoNumeroMovil,
          obtenerTelefonos,
          obtenerTelefono,
          agregarTelefono,
          actualizarTelefono,
          guardarTelefono,
          obtenerDiagnosticos,
          obtenerDiagnostico,
          agregarDiagnostico,
          actualizarDiagnostico,
          guardarDiagnostico,
          obtenerMedicamentos,
          obtenerMedicamentosSku,
          obtenerMedicamento,
          agregarMedicamento,
          actualizarMedicamento,
          guardarMedicamento,
          obtenerAlmacenesMoviles,
          guardarAlmacenesMoviles,
          agregarAlmacenMovil,
          eliminarAlmacenMovil,
          obtenerAlmacenes,
          obtenerAlmacen,
          agregarAlmacen,
          actualizarAlmacen,
          guardarAlmacen,
          obtenerMovimientosMedicamentos,
          obtenerMovimientoMedicamento,
          agregarMovimientoMedicamento,
          actualizarMovimientoMedicamento,
          guardarMovimientoMedicamento,
          guardarMovimientosMedicamentos,
          obtenerAlertas,
          guardarAlertas,
          actualizarAlertas,
          actualizarConfiguracion,
          guardarConfiguracion,
          obtenerBases,
          obtenerBase,
          agregarBase,
          actualizarBase,
          guardarBase,
          guardarMoviles,
          obtenerTelefonosLiberados,
          obtenerCoseguros,
          obtenerCoseguro,
          guardarCoseguro,
          actualizarCoseguro,
          guardarCoseguros,
          setActiveIndex,
          obtenerGrupos,
          obtenerCoseguroServicio,
          setParametros,
          guardarCoseguroServicio,
          eliminarCoseguro,
          guardarCoseguroAbonar,
          guardarStocksMedicamentos,
          obtenerStocksMedicamentos,
          ajustarStockMedicamento,
          guardarAjustesStocksMedicamentos,
          obtenerAjustesStocksMedicamentos,
          guardarAlmacenMovil,
          obtenerAlmacenMovil,
          obtenerZonasMovilesDerivados,
          guardarZonasMovilesDerivados,
          guardarTelefonos,
          guardarMedicos,
          guardarChoferes,
          guardarEnfermeros,
          firmaMedico,
          agregarReceta,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export {
  ConfiguracionProvider,
  Consumer as ConfiguracionConsumer,
  ConfiguracionContext,
};
