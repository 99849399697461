//imports front
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { AuthContext } from "../../context/AuthContext";
import { AlertContext } from "../../context/AlertContext";
import * as EmailValidator from "email-validator";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Dialog } from "primereact/dialog";
import Box from "@mui/material/Box";
import ButtonMui from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Tag } from "primereact/tag";
import { Card } from "primereact/card";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Toast } from "primereact/toast";
import Spinner from "./Spinner";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import TextField from "@mui/material/TextField";
import { SelectButton } from "primereact/selectbutton";
import { TabView, TabPanel } from "primereact/tabview";
import { Document, Page, pdfjs } from "react-pdf";
import pdfCaidas from "./caidas.pdf";
import pdfUlceras from "./ulcerasporpresion.pdf";
import pdfEnfermeria from "./enfermeria.pdf";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { maxWidth } from "@mui/system";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Eventos() {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;

  const [dialogEvoluciones, setDialogEvoluciones] = useState(false);
  const [dialogFichaCaida, setDialogFichaCaida] = useState(false);
  const [dialogControlCaida, setDialogControlCaida] = useState(false);
  const [dialogDiabetes, setDialogDiabetes] = useState(false);
  const [dialogAnticoagulados, setDialogAnticoagulados] = useState(false);
  const [dialogUlcera, setDialogUlcera] = useState(false);
  const [dialogEnfermeria, setDialogEnfermeria] = useState(false);
  const [band, setBand] = useState(true);
  const [card, setCard] = useState(null);
  const toast = useRef(null);
  let navigate = useNavigate();
  const [dialogPacientes, setDialogPacientes] = useState(false);
  const [dialogEvolucion, setDialogEvolucion] = useState(false);
  const [exit, setExit] = useState(false);
  const [tipo, setTipo] = useState("evolucion");
  const veces = ["Si", "No"];
  const [primera, setPrimera] = useState("Si");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [activeIndex, setActiveIndex] = useState(0);
  const [caida, guardarCaida] = useState(null);
  const [diabete, guardarDiabete] = useState(null);
  const [ulcera, guardarUlcera] = useState(null);
  const [enfermeria, guardarEnfermeria] = useState(null);
  const [anticoagulado, guardarAnticoagulado] = useState(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    evolucion,
    caidas,
    historiasClinicas,
    paciente,
    pacientes,
    historiaClinica,
    geriatricoElegido,
    visible,
    fragilidad,
    antecedente,
    actual,
    examenI,
    examenII,
    examenIII,
    evoluciones,
    diabetes,
    anticoagulados,
    ulceras,
    enfermerias,
    selectedRowPaciente,
    validarHistoriaClinica,
    obtenerHistoriasClinicas,
    obtenerAcceso,
    obtenerAcceso2,
    obtenerAcceso3,
    obtenerAcceso4,
    obtenerAcceso5,
    agregarHistoriaClinica,
    actualizarHistoriaClinica,
    setGeriatricoElegido,
    obtenerPaciente,
    obtenerPacientes,
    setSelectedRowPaciente,
    obtenerEvoluciones,
    guardarEvolucion,
    guardarCaidas,
    agregarEvolucion,
    obtenerFichasCaidas,
    agregarFichasCaidas,
    actualizarFichasCaidas,
    obtenerControlCaidas,
    agregarControlCaidas,
    actualizarControlCaidas,
    obtenerDiabetes,
    guardarDiabetes,
    agregarDiabetes,
    actualizarDiabetes,
    obtenerUlceras,
    guardarUlceras,
    agregarUlceras,
    actualizarUlceras,
    guardarEnfermerias,
    obtenerEnfermerias,
    agregarEnfermerias,
    actualizarEnfermerias,
    obtenerAnticoagulados,
    guardarAnticoagulados,
    agregarAnticoagulados,
    actualizarAnticoagulados,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Evoluciones",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    obtenerPacientes({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    });
  }, []);

  const exitClick = (e) => {
    navigate("/");
  };

  const imprimir = (e) => {
    if (tipo === "evolucion") {
      var line = 25; // Line height to start text at
      var lineHeight = 5;
      var leftMargin = 20;
      var wrapWidth = 180;
      var longString = evolucion;

      const doc = new jsPDF();

      doc.setFontSize(14);
      doc.text("EVOLUCION RESIDENTE " + selectedRowPaciente.nombre, 50, 10);

      doc.setFontSize(12);

      doc.setFontSize(12);
      var splitText = doc.splitTextToSize(longString, wrapWidth);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }

      let lineDate = line + 10;
      doc.text(formatDate(selectedRow.fecha), 10, lineDate);

      window.open(doc.output("bloburl"));
      /*
      doc.save(
        "evolucion_" +
          selectedRowPaciente.nombre +
          "_" +
          new Date().toLocaleDateString() +
          ".pdf"
      );
      */
    }

    if (tipo === "caidas") {
      if (activeIndex === 0) {
        //window.open(pdfCaidas, "PRINT", "height=400,width=600");
        window.open(pdfCaidas, "_blank");
      }

      /*
      if (activeIndex === 1) {
        const doc = new jsPDF();

        doc.setFontSize(14);
        doc.text("FICHA DE EVALUACIÓN DE CAIDAS", 50, 10);

        doc.setFontSize(12);

        doc.text(
          "Apellido y Nombre: " + selectedRowPaciente.nombre,
          10,
          20,
          "left",
          "middle"
        );

        doc.text(
          "Fecha: " +
            (caida !== undefined &&
            caida !== null &&
            caida.fecha !== undefined &&
            caida.fecha !== null
              ? formatDate(caida.fecha)
              : ""),
          10,
          25,
          "left",
          "middle"
        );

        doc.text(
          "Hora: " +
            (caida !== undefined &&
            caida !== null &&
            caida.hora !== undefined &&
            caida.hora !== null
              ? caida.hora
              : ""),
          80,
          25,
          "left",
          "middle"
        );

        doc.text(
          "Testigo: " +
            (caida !== undefined &&
            caida !== null &&
            caida.testigo !== undefined &&
            caida.testigo !== null
              ? caida.testigo
              : ""),
          10,
          35,
          "left",
          "middle"
        );

        doc.text(
          "Lugar del acontecimiento (institución) (calle) casa) (otro): " +
            (caida !== undefined &&
            caida !== null &&
            caida.lugar !== undefined &&
            caida.lugar !== null
              ? caida.lugar
              : ""),
          10,
          45,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text(
          "Lugar de la institución:(habitación) (comedor) (baño) (pasillo) (otro): " +
            (caida !== undefined &&
            caida !== null &&
            caida.lugar2 !== undefined &&
            caida.lugar2 !== null
              ? caida.lugar2
              : ""),
          10,
          60,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text(
          "Circunstancia: " +
            (caida !== undefined &&
            caida !== null &&
            caida.circunstancia !== undefined &&
            caida.circunstancia !== null
              ? caida.circunstancia
              : ""),
          10,
          70,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text(
          "Diagnóstico del Paciente: " +
            (caida !== undefined &&
            caida !== null &&
            caida.diagnostico !== undefined &&
            caida.diagnostico !== null
              ? caida.diagnostico
              : ""),
          10,
          80,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text(
          "Intercurrencias médicas que puedan influir: " +
            (caida !== undefined &&
            caida !== null &&
            caida.intercurrencias !== undefined &&
            caida.intercurrencias !== null
              ? caida.intercurrencias
              : ""),
          10,
          90,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text(
          "Medicamentos que ingiere: " +
            (caida !== undefined &&
            caida !== null &&
            caida.medicamentos !== undefined &&
            caida.medicamentos !== null
              ? caida.medicamentos
              : ""),
          10,
          100,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text("Condiciones del lugar: ", 10, 110, {
          maxWidth: 200,
          align: "left",
        });

        doc.text(
          "Piso: " +
            (caida !== undefined &&
            caida !== null &&
            caida.piso !== undefined &&
            caida.piso !== null
              ? caida.piso
              : ""),
          10,
          120,
          {
            maxWidth: 50,
            align: "left",
          }
        );

        doc.text(
          "Iluminación: " +
            (caida !== undefined &&
            caida !== null &&
            caida.iluminacion !== undefined &&
            caida.iluminacion !== null
              ? caida.iluminacion
              : ""),
          70,
          120,
          {
            maxWidth: 50,
            align: "left",
          }
        );

        doc.text(
          "Obstáculos: " +
            (caida !== undefined &&
            caida !== null &&
            caida.obstaculos !== undefined &&
            caida.obstaculos !== null
              ? caida.obstaculos
              : ""),
          130,
          120,
          {
            maxWidth: 50,
            align: "left",
          }
        );

        doc.text(
          "Barandas: " +
            (caida !== undefined &&
            caida !== null &&
            caida.barandas !== undefined &&
            caida.barandas !== null
              ? caida.barandas
              : ""),
          10,
          140,
          {
            maxWidth: 50,
            align: "left",
          }
        );

        doc.text(
          "Cama: " +
            (caida !== undefined &&
            caida !== null &&
            caida.cama !== undefined &&
            caida.cama !== null
              ? caida.cama
              : ""),
          70,
          140,
          {
            maxWidth: 50,
            align: "left",
          }
        );

        doc.text(
          "Escalera: " +
            (caida !== undefined &&
            caida !== null &&
            caida.escaleras !== undefined &&
            caida.escaleras !== null
              ? caida.escaleras
              : ""),
          130,
          140,
          {
            maxWidth: 50,
            align: "left",
          }
        );

        doc.text(
          "Calzado: " +
            (caida !== undefined &&
            caida !== null &&
            caida.calzado !== undefined &&
            caida.calzado !== null
              ? caida.calzado
              : ""),
          10,
          160,
          {
            maxWidth: 50,
            align: "left",
          }
        );

        doc.text(
          "Silla: " +
            (caida !== undefined &&
            caida !== null &&
            caida.silla !== undefined &&
            caida.silla !== null
              ? caida.silla
              : ""),
          70,
          160,
          {
            maxWidth: 50,
            align: "left",
          }
        );

        doc.text(
          "Acompañante: " +
            (caida !== undefined &&
            caida !== null &&
            caida.acompanante !== undefined &&
            caida.acompanante !== null
              ? caida.acompanante
              : ""),
          130,
          160,
          {
            maxWidth: 50,
            align: "left",
          }
        );

        doc.text(
          "Primera Vez: " +
            (primera !== undefined && primera !== null ? primera : ""),
          10,
          180
        );

        doc.text(
          "Escala de Tinetti: " +
            (caida !== undefined &&
            caida !== null &&
            caida.tinetti !== undefined &&
            caida.tinetti !== null
              ? caida.tinetti
              : ""),
          10,
          200,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text(
          "Consecuencias: " +
            (caida !== undefined &&
            caida !== null &&
            caida.consecuencias !== undefined &&
            caida.consecuencias !== null
              ? caida.consecuencias
              : ""),
          10,
          220,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text("Firma: ____________________________________ ", 40, 260);

        doc.text("Aclaración: ____________________________________ ", 40, 280);

        window.open(doc.output('bloburl'))
      }
      */

      if (activeIndex === 1) {
        const doc = new jsPDF();

        doc.setFontSize(14);
        doc.text("FICHA DE CONTROL DE CAIDAS", 50, 10);

        doc.setFontSize(12);

        doc.text(
          "Apellido y Nombre: " + selectedRowPaciente.nombre,
          10,
          20,
          "left",
          "middle"
        );

        doc.text(
          "Fecha: " +
            (caida !== undefined &&
            caida !== null &&
            caida.fecha !== undefined &&
            caida.fecha !== null
              ? formatDate(caida.fecha)
              : ""),
          10,
          25,
          "left",
          "middle"
        );

        doc.text(
          "Lugar del acontecimiento (institución) (calle) casa) (otro): " +
            (caida !== undefined &&
            caida !== null &&
            caida.lugar !== undefined &&
            caida.lugar !== null
              ? caida.lugar
              : ""),
          10,
          35,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text(
          "Consecuencias: " +
            (caida !== undefined &&
            caida !== null &&
            caida.consecuencias !== undefined &&
            caida.consecuencias !== null
              ? caida.consecuencias
              : ""),
          10,
          55,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text(
          "Tratamiento: " +
            (caida !== undefined &&
            caida !== null &&
            caida.tratamiento !== undefined &&
            caida.tratamiento !== null
              ? caida.tratamiento
              : ""),
          10,
          75,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        window.open(doc.output("bloburl"));
        /*
        doc.save(
          "ControlCaidas_" +
            selectedRowPaciente.nombre +
            "export_" +
            new Date().toLocaleDateString() +
            ".pdf"
        );
        */
      }
    }

    if (tipo === "diabetes") {
      const doc = new jsPDF();

      doc.setFontSize(14);
      doc.text("FICHA DE CONTROL DE DIABETES", 50, 10);

      doc.setFontSize(12);

      doc.text(
        "Apellido y Nombre: " + selectedRowPaciente.nombre,
        10,
        20,
        "left",
        "middle"
      );

      doc.text(
        "Fecha: " +
          (diabete !== undefined &&
          diabete !== null &&
          diabete.fecha !== undefined &&
          diabete.fecha !== null
            ? formatDate(diabete.fecha)
            : ""),
        10,
        25,
        "left",
        "middle"
      );

      doc.text(
        "Tipo: " +
          (diabete !== undefined &&
          diabete !== null &&
          diabete.tipo !== undefined &&
          diabete.tipo !== null
            ? diabete.tipo
            : ""),
        10,
        35,
        {
          maxWidth: 200,
          align: "left",
        }
      );

      doc.text(
        "Medicación: " +
          (diabete !== undefined &&
          diabete !== null &&
          diabete.medicacion !== undefined &&
          diabete.medicacion !== null
            ? diabete.medicacion
            : ""),
        10,
        45,
        {
          maxWidth: 200,
          align: "left",
        }
      );

      doc.text(
        "Valor: " +
          (diabete !== undefined &&
          diabete !== null &&
          diabete.valor !== undefined &&
          diabete.valor !== null
            ? diabete.valor
            : ""),
        10,
        55,
        {
          maxWidth: 200,
          align: "left",
        }
      );

      doc.text(
        "Control: " +
          (diabete !== undefined &&
          diabete !== null &&
          diabete.control !== undefined &&
          diabete.control !== null
            ? diabete.control
            : ""),
        10,
        65,
        {
          maxWidth: 200,
          align: "left",
        }
      );

      window.open(doc.output("bloburl"));
      /*
      doc.save(
        "Diabetes_" +
          selectedRowPaciente.nombre +
          "export_" +
          new Date().toLocaleDateString() +
          ".pdf"
      );
      */
    }

    if (tipo === "ulceras") {
      if (activeIndex === 0) {
        //window.open(pdfCaidas, "PRINT", "height=400,width=600");
        window.open(pdfUlceras, "_blank");
      }

      if (activeIndex === 1) {
        const doc = new jsPDF();

        doc.setFontSize(14);
        doc.text("FICHA DE CONTROL DE ULCERAS POR PRESION", 50, 10);

        doc.setFontSize(12);

        doc.text(
          "Apellido y Nombre: " + selectedRowPaciente.nombre,
          10,
          20,
          "left",
          "middle"
        );

        doc.text(
          "Fecha: " +
            (ulcera !== undefined &&
            ulcera !== null &&
            ulcera.fecha !== undefined &&
            ulcera.fecha !== null
              ? formatDate(ulcera.fecha)
              : ""),
          10,
          25,
          "left",
          "middle"
        );

        doc.text(
          "Valoración: " +
            (ulcera !== undefined &&
            ulcera !== null &&
            ulcera.valoracion !== undefined &&
            ulcera.valoracion !== null
              ? ulcera.valoracion
              : ""),
          10,
          35,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text(
          "Localización: " +
            (ulcera !== undefined &&
            ulcera !== null &&
            ulcera.localizacion !== undefined &&
            ulcera.localizacion !== null
              ? ulcera.localizacion
              : ""),
          10,
          45,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text(
          "Evolución de la Ulcera: " +
            (ulcera !== undefined &&
            ulcera !== null &&
            ulcera.mejora !== undefined &&
            ulcera.mejora !== null &&
            ulcera.mejora
              ? "Mejora"
              : ulcera.estable !== undefined &&
                ulcera.estable !== null &&
                ulcera.estable
              ? "Estable"
              : ulcera.empeora !== undefined &&
                ulcera.empeora !== null &&
                ulcera.empeora
              ? "Esmpeora"
              : ulcera.curada !== undefined &&
                ulcera.curada !== null &&
                ulcera.curada
              ? "Curada"
              : null),

          10,
          55
        );

        doc.text(
          "Cambio de TTO: " +
            (ulcera !== undefined &&
            ulcera !== null &&
            ulcera.tto !== undefined &&
            ulcera.tto !== null
              ? ulcera.tto
              : ""),
          10,
          65,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text("Firma: ____________________________________ ", 40, 85);

        doc.text("Aclaración: ____________________________________ ", 40, 105);

        window.open(doc.output("bloburl"));
        /*
        doc.save(
          "ControlUlceras" +
            selectedRowPaciente.nombre +
            "export_" +
            new Date().toLocaleDateString() +
            ".pdf"
        );
        */
      }
    }

    if (tipo === "enfermeria") {
      if (activeIndex === 0) {
        //window.open(pdfCaidas, "PRINT", "height=400,width=600");
        window.open(pdfEnfermeria, "_blank");
      }

      if (activeIndex === 1) {
        const doc = new jsPDF();

        doc.setFontSize(14);
        doc.text("FICHA DE CONTROL DE ENFERMERIA", 50, 10);

        doc.setFontSize(12);

        doc.text(
          "Apellido y Nombre: " + selectedRowPaciente.nombre,
          10,
          20,
          "left",
          "middle"
        );

        doc.text(
          "Fecha: " +
            (enfermeria !== undefined &&
            enfermeria !== null &&
            enfermeria.fecha !== undefined &&
            enfermeria.fecha !== null
              ? formatDate(enfermeria.fecha)
              : ""),
          10,
          25,
          "left",
          "middle"
        );

        doc.text(
          "Medicación: " +
            (enfermeria !== undefined &&
            enfermeria !== null &&
            enfermeria.medicacion !== undefined &&
            enfermeria.medicacion !== null
              ? enfermeria.medicacion
              : ""),
          10,
          35,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text(
          "Temperatura: " +
            (enfermeria !== undefined &&
            enfermeria !== null &&
            enfermeria.temperatura !== undefined &&
            enfermeria.temperatura !== null
              ? enfermeria.temperatura
              : ""),
          10,
          45,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text(
          "T.A.: " +
            (enfermeria !== undefined &&
            enfermeria !== null &&
            enfermeria.tension !== undefined &&
            enfermeria.tension !== null
              ? enfermeria.tension
              : ""),
          10,
          50,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text(
          "F.C.: " +
            (enfermeria !== undefined &&
            enfermeria !== null &&
            enfermeria.frecuencia !== undefined &&
            enfermeria.frecuencia !== null
              ? enfermeria.frecuencia
              : ""),
          10,
          55,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        doc.text(
          "Observaciones: " +
            (enfermeria !== undefined &&
            enfermeria !== null &&
            enfermeria.observaciones !== undefined &&
            enfermeria.observaciones !== null
              ? enfermeria.observaciones
              : ""),
          10,
          60,
          {
            maxWidth: 200,
            align: "left",
          }
        );

        window.open(doc.output("bloburl"));
        /*
        doc.save(
          "ControlEnfermeria" +
            selectedRowPaciente.nombre +
            "export_" +
            new Date().toLocaleDateString() +
            ".pdf"
        );
        */
      }
    }
  };

  const aceptarEvolucion = (e) => {
    if (selectedRow === null) {
      agregarEvolucion({
        paciente: selectedRowPaciente,
        evolucion: evolucion,
        usuario: state.usuario.usuario,
      });
      onHide();
      setTimeout(() => {
        obtenerEvoluciones({ pacienteId: selectedRowPaciente.id });
      }, 2000);
    }
  };

  const aceptarDiabetes = (e) => {
    if (
      diabete === undefined ||
      diabete === null ||
      diabete.fecha === undefined ||
      diabete.fecha === null ||
      diabete.fecha === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Diabetes",
        detail: "Debe indicar la fecha.",
        life: 3000,
      });
      return;
    }

    if (
      diabete === undefined ||
      diabete === null ||
      diabete.tipo === undefined ||
      diabete.tipo === null ||
      diabete.tipo === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Diabetes",
        detail: "Debe indicar el tipo.",
        life: 3000,
      });
      return;
    }

    if (
      diabete === undefined ||
      diabete === null ||
      diabete.medicacion === undefined ||
      diabete.medicacion === null ||
      diabete.medicacion === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Diabetes",
        detail: "Debe indicar la medicación.",
        life: 3000,
      });
      return;
    }

    if (
      diabete === undefined ||
      diabete === null ||
      diabete.valor === undefined ||
      diabete.valor === null ||
      diabete.valor === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Diabetes",
        detail: "Debe indicar el valor.",
        life: 3000,
      });
      return;
    }

    if (
      diabete === undefined ||
      diabete === null ||
      diabete.control === undefined ||
      diabete.control === null ||
      diabete.control === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Diabetes",
        detail: "Debe indicar el control.",
        life: 3000,
      });
      return;
    }

    if (selectedRow === null) {
      agregarDiabetes({
        paciente: selectedRowPaciente,
        diabete,
        usuario: state.usuario.usuario,
      });
    } else {
      actualizarDiabetes({
        paciente: selectedRowPaciente,
        diabete,
        usuario: state.usuario.usuario,
      });
    }
    setSelectedRow(null);
    onHideDiabetes();
    setTimeout(() => {
      obtenerDiabetes({ pacienteId: selectedRowPaciente.id });
    }, 2000);
  };


  const aceptarAnticoagulados = (e) => {
    if (
      anticoagulado === undefined ||
      anticoagulado === null ||
      anticoagulado.fecha === undefined ||
      anticoagulado.fecha === null ||
      anticoagulado.fecha === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Anticoagulados",
        detail: "Debe indicar la fecha.",
        life: 3000,
      });
      return;
    }

    if (
      anticoagulado === undefined ||
      anticoagulado === null ||
      anticoagulado.tipo === undefined ||
      anticoagulado.tipo === null ||
      anticoagulado.tipo === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Anticoagulados",
        detail: "Debe indicar el tipo.",
        life: 3000,
      });
      return;
    }

    if (
      anticoagulado === undefined ||
      anticoagulado === null ||
      anticoagulado.medicacion === undefined ||
      anticoagulado.medicacion === null ||
      anticoagulado.medicacion === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Anticoagulados",
        detail: "Debe indicar la medicación.",
        life: 3000,
      });
      return;
    }

    if (
      anticoagulado === undefined ||
      anticoagulado === null ||
      anticoagulado.valor === undefined ||
      anticoagulado.valor === null ||
      anticoagulado.valor === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Anticoagulados",
        detail: "Debe indicar el valor.",
        life: 3000,
      });
      return;
    }

    if (
      anticoagulado === undefined ||
      anticoagulado === null ||
      anticoagulado.control === undefined ||
      anticoagulado.control === null ||
      anticoagulado.control === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Anticoagulados",
        detail: "Debe indicar el control.",
        life: 3000,
      });
      return;
    }

    if (selectedRow === null) {
      agregarAnticoagulados({
        paciente: selectedRowPaciente,
        anticoagulado,
        usuario: state.usuario.usuario,
      });
    } else {
      actualizarAnticoagulados({
        paciente: selectedRowPaciente,
        anticoagulado,
        usuario: state.usuario.usuario,
      });
    }
    setSelectedRow(null);
    onHideAnticoagulados();
    setTimeout(() => {
      obtenerAnticoagulados({ pacienteId: selectedRowPaciente.id });
    }, 2000);
  };



  const aceptarFichasCaidas = (e) => {
    if (
      caida === undefined ||
      caida === null ||
      caida.fecha === undefined ||
      caida.fecha === null ||
      caida.fecha === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Fichas",
        detail: "Debe indicar la fecha.",
        life: 3000,
      });
      return;
    }

    if (
      caida === undefined ||
      caida === null ||
      caida.hora === undefined ||
      caida.hora === null ||
      caida.hora === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Fichas",
        detail: "Debe indicar la hora.",
        life: 3000,
      });
      return;
    }

    if (
      caida === undefined ||
      caida === null ||
      caida.lugar === undefined ||
      caida.lugar === null ||
      caida.lugar.trim() === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Fichas",
        detail: "Debe indicar el lugar.",
        life: 3000,
      });
      return;
    }

    if (selectedRow === null) {
      agregarFichasCaidas({
        paciente: selectedRowPaciente,
        caida,
        primera,
        usuario: state.usuario.usuario,
      });
    } else {
      actualizarFichasCaidas({
        paciente: selectedRowPaciente,
        caida,
        primera,
        usuario: state.usuario.usuario,
      });
    }
    setSelectedRow(null);
    onHideFichasCaidas();
    setTimeout(() => {
      obtenerFichasCaidas({ pacienteId: selectedRowPaciente.id });
    }, 2000);
  };

  const aceptarUlceras = (e) => {
    if (
      ulcera === undefined ||
      ulcera === null ||
      ulcera.fecha === undefined ||
      ulcera.fecha === null ||
      ulcera.fecha === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Fichas",
        detail: "Debe indicar la fecha.",
        life: 3000,
      });
      return;
    }

    if (
      ulcera === undefined ||
      ulcera === null ||
      ulcera.valoracion === undefined ||
      ulcera.valoracion === null ||
      ulcera.valoracion === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Fichas",
        detail: "Debe indicar la valoración.",
        life: 3000,
      });
      return;
    }

    if (
      ulcera === undefined ||
      ulcera === null ||
      ulcera.localizacion === undefined ||
      ulcera.localizacion === null ||
      ulcera.localizacion.trim() === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Fichas",
        detail: "Debe indicar la localización.",
        life: 3000,
      });
      return;
    }

    if (
      ulcera === undefined ||
      ulcera === null ||
      ulcera.evolucion === undefined ||
      ulcera.evolucion === null ||
      ulcera.evolucion === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Fichas",
        detail: "Debe indicar la evolución.",
        life: 3000,
      });
      return;
    }

    if (
      ulcera === undefined ||
      ulcera === null ||
      ulcera.tto === undefined ||
      ulcera.tto === null ||
      ulcera.tto === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Fichas",
        detail: "Debe indicar el cambio de TTO.",
        life: 3000,
      });
      return;
    }

    if (selectedRow === null) {
      agregarUlceras({
        paciente: selectedRowPaciente,
        ulcera,
        usuario: state.usuario.usuario,
      });
    } else {
      actualizarUlceras({
        paciente: selectedRowPaciente,
        ulcera,
        usuario: state.usuario.usuario,
      });
    }
    setSelectedRow(null);
    onHideUlceras();
    setTimeout(() => {
      obtenerUlceras({ pacienteId: selectedRowPaciente.id });
    }, 2000);
  };

  const aceptarEnfermeria = (e) => {
    if (
      enfermeria === undefined ||
      enfermeria === null ||
      enfermeria.fecha === undefined ||
      enfermeria.fecha === null ||
      enfermeria.fecha === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Enfermería",
        detail: "Debe indicar la fecha.",
        life: 3000,
      });
      return;
    }

    if (
      enfermeria === undefined ||
      enfermeria === null ||
      enfermeria.medicacion === undefined ||
      enfermeria.medicacion === null ||
      enfermeria.medicacion === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Enfermería",
        detail: "Debe indicar la medicación.",
        life: 3000,
      });
      return;
    }

    if (
      enfermeria === undefined ||
      enfermeria === null ||
      enfermeria.temperatura === undefined ||
      enfermeria.temperatura === null ||
      enfermeria.temperatura.trim() === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Enfermería",
        detail: "Debe indicar la temperatura.",
        life: 3000,
      });
      return;
    }

    if (
      enfermeria === undefined ||
      enfermeria === null ||
      enfermeria.tension === undefined ||
      enfermeria.tension === null ||
      enfermeria.tension === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Enfermería",
        detail: "Debe indicar la T.A.",
        life: 3000,
      });
      return;
    }

    if (
      enfermeria === undefined ||
      enfermeria === null ||
      enfermeria.frecuencia === undefined ||
      enfermeria.frecuencia === null ||
      enfermeria.frecuencia === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Enfermería",
        detail: "Debe indicar la F.C.",
        life: 3000,
      });
      return;
    }

    if (selectedRow === null) {
      agregarEnfermerias({
        paciente: selectedRowPaciente,
        enfermeria,
        usuario: state.usuario.usuario,
      });
    } else {
      actualizarEnfermerias({
        paciente: selectedRowPaciente,
        enfermeria,
        usuario: state.usuario.usuario,
      });
    }
    setSelectedRow(null);
    onHideEnfermeria();
    setTimeout(() => {
      obtenerEnfermerias({ pacienteId: selectedRowPaciente.id });
    }, 2000);
  };

  const aceptarControlCaidas = (e) => {
    if (
      caida === undefined ||
      caida === null ||
      caida.fecha === undefined ||
      caida.fecha === null ||
      caida.fecha === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Control",
        detail: "Debe indicar la fecha.",
        life: 3000,
      });
      return;
    }

    if (
      caida === undefined ||
      caida === null ||
      caida.lugar === undefined ||
      caida.lugar === null ||
      caida.lugar.trim() === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Control",
        detail: "Debe indicar el lugar.",
        life: 3000,
      });
      return;
    }

    if (
      caida === undefined ||
      caida === null ||
      caida.consecuencias === undefined ||
      caida.consecuencias === null ||
      caida.consecuencias.trim() === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Control",
        detail: "Debe indicar las consecuencias.",
        life: 3000,
      });
      return;
    }

    if (
      caida === undefined ||
      caida === null ||
      caida.tratamiento === undefined ||
      caida.tratamiento === null ||
      caida.tratamiento.trim() === ""
    ) {
      toast.current.show({
        severity: "error",
        summary: "Control",
        detail: "Debe indicar el tratamiento.",
        life: 3000,
      });
      return;
    }

    if (selectedRow === null) {
      agregarControlCaidas({
        paciente: selectedRowPaciente,
        caida,
        usuario: state.usuario.usuario,
      });
    } else {
      actualizarControlCaidas({
        paciente: selectedRowPaciente,
        caida,
        usuario: state.usuario.usuario,
      });
    }
    setSelectedRow(null);
    onHideControlCaidas();
    setTimeout(() => {
      obtenerControlCaidas({ pacienteId: selectedRowPaciente.id });
    }, 2000);
  };

  const onHide = (e) => {
    setDialogEvolucion(false);
    //setDialogCaidas(false);
  };

  const onHideEvoluciones = (e) => {
    setDialogEvoluciones(false);
    //setDialogCaidas(false);
  };

  const onHideFichasCaidas = (e) => {
    setDialogFichaCaida(false);
    //setDialogCaidas(false);
  };

  const onHideControlCaidas = (e) => {
    setDialogControlCaida(false);
    //setDialogCaidas(false);
  };

  const onHideDiabetes = (e) => {
    setDialogDiabetes(false);
    //setDialogCaidas(false);
  };

  const onHideAnticoagulados = (e) => {
    setDialogAnticoagulados(false);
    //setDialogCaidas(false);
  };

  const onHideUlceras = (e) => {
    setDialogUlcera(false);
    //setDialogCaidas(false);
  };

  const onHideEnfermeria = (e) => {
    setDialogEnfermeria(false);
    //setDialogCaidas(false);
  };

  const addClick = (e) => {
    setDialogPacientes(true);
    setSelectedRowPaciente(null);
  };

  const addClickEvolucion = (e) => {
    guardarEvolucion([]);
    setDialogEvolucion(true);
    setSelectedRow(null);
  };

  const addClickDiabetes = (e) => {
    guardarDiabetes([]);
    setDialogDiabetes(true);
    setSelectedRow(null);
  };

  const addClickAnticoagulados = (e) => {
    guardarAnticoagulados([]);
    setDialogAnticoagulados(true);
    setSelectedRow(null);
  };

  const verClickEvolucion = (e) => {
    if (selectedRow) {
      guardarEvolucion(selectedRow.evolucion);
      setDialogEvolucion(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una evolución.",
        life: 3000,
      });
    }
  };

  const verClickDiabetes = (e) => {
    if (selectedRow) {
      guardarDiabete(selectedRow);
      setDialogDiabetes(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un control.",
        life: 3000,
      });
    }
  };

  const verClickAnticoagulados = (e) => {
    if (selectedRow) {
      guardarAnticoagulado(selectedRow);
      setDialogAnticoagulados(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un control.",
        life: 3000,
      });
    }
  };

  const addClickFichasCaidas = (e) => {
    guardarCaidas([]);
    setSelectedRow(null);
    /*
    if (activeIndex === 1) {
      setDialogFichaCaida(true);
    }
    */
    if (activeIndex === 1) {
      setDialogControlCaida(true);
    }
  };

  const verClickFichasCaidas = (e) => {
    if (selectedRow) {
      guardarCaida(selectedRow);
      /*
      if (activeIndex === 1) {
        setDialogFichaCaida(true);
      }
      */
      if (activeIndex === 1) {
        setDialogControlCaida(true);
      }
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un control.",
        /*
        detail:
          activeIndex === 1
            ? "Debe seleccionar una ficha."
            : "Debe seleccionar un control.",
        */
        life: 3000,
      });
    }
  };

  const addClickUlceras = (e) => {
    guardarUlceras([]);
    setSelectedRow(null);
    setDialogUlcera(true);
  };

  const addClickEnfermeria = (e) => {
    guardarEnfermerias([]);
    setSelectedRow(null);
    setDialogEnfermeria(true);
  };

  const verClickUlceras = (e) => {
    if (selectedRow) {
      guardarUlcera(selectedRow);
      setDialogUlcera(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un control.",
        life: 3000,
      });
    }
  };

  const verClickEnfermeria = (e) => {
    if (selectedRow) {
      guardarEnfermeria(selectedRow);
      setDialogEnfermeria(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un control.",
        life: 3000,
      });
    }
  };

  const renderHeader = () => {
    return (
      <Fragment>
        <div>
          {selectedRowPaciente ? (
            <Tag value={"Residente " + selectedRowPaciente.nombre}></Tag>
          ) : null}
        </div>
        <div style={{ textAlign: "center" }}>
          {selectedRowPaciente ? (
            <Tag
              value={
                tipo === "evolucion"
                  ? "EVOLUCIONES"
                  : tipo === "caidas"
                  ? "CAIDAS"
                  : tipo === "diabetes"
                  ? "DIABETES"
                  : tipo === "ulceras"
                  ? "ULCERAS POR PRESION"
                  : tipo === "enfermeria"
                  ? "ENFERMERIA"
                  : tipo === "anticoagulados"
                  ? "ANTICOAGULADOS"
                  : null
              }
            ></Tag>
          ) : null}
        </div>
      </Fragment>
    );
  };

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const headerEvoluciones = (
    <Fragment>
      <Button
        icon="pi pi-plus"
        tooltip="Agregar"
        className="p-button-rounded p-button-help"
        style={{ marginLeft: "0em" }}
        onClick={addClickEvolucion}
      />
      <Button
        icon="pi pi-pencil"
        tooltip="Ver"
        className="p-button-rounded p-button-success"
        style={{ marginLeft: "1em" }}
        onClick={verClickEvolucion}
      />
    </Fragment>
  );

  const headerDiabetes = (
    <Fragment>
      <Button
        icon="pi pi-plus"
        tooltip="Agregar"
        className="p-button-rounded p-button-help"
        style={{ marginLeft: "0em" }}
        onClick={addClickDiabetes}
      />
      <Button
        icon="pi pi-pencil"
        tooltip="Ver"
        className="p-button-rounded p-button-success"
        style={{ marginLeft: "1em" }}
        onClick={verClickDiabetes}
      />
    </Fragment>
  );

  const headerAnticoagulados = (
    <Fragment>
      <Button
        icon="pi pi-plus"
        tooltip="Agregar"
        className="p-button-rounded p-button-help"
        style={{ marginLeft: "0em" }}
        onClick={addClickAnticoagulados}
      />
      <Button
        icon="pi pi-pencil"
        tooltip="Ver"
        className="p-button-rounded p-button-success"
        style={{ marginLeft: "1em" }}
        onClick={verClickAnticoagulados}
      />
    </Fragment>
  );


  const headerFichaCaidas = (
    <Fragment>
      <Button
        icon="pi pi-plus"
        tooltip="Agregar"
        className="p-button-rounded p-button-help"
        style={{ marginLeft: "0em" }}
        onClick={addClickFichasCaidas}
      />
      <Button
        icon="pi pi-pencil"
        tooltip="Ver"
        className="p-button-rounded p-button-success"
        style={{ marginLeft: "1em" }}
        onClick={verClickFichasCaidas}
      />
    </Fragment>
  );

  const headerUlceras = (
    <Fragment>
      <Button
        icon="pi pi-plus"
        tooltip="Agregar"
        className="p-button-rounded p-button-help"
        style={{ marginLeft: "0em" }}
        onClick={addClickUlceras}
      />
      <Button
        icon="pi pi-pencil"
        tooltip="Ver"
        className="p-button-rounded p-button-success"
        style={{ marginLeft: "1em" }}
        onClick={verClickUlceras}
      />
    </Fragment>
  );

  const headerEnfermeria = (
    <Fragment>
      <Button
        icon="pi pi-plus"
        tooltip="Agregar"
        className="p-button-rounded p-button-help"
        style={{ marginLeft: "0em" }}
        onClick={addClickEnfermeria}
      />
      <Button
        icon="pi pi-pencil"
        tooltip="Ver"
        className="p-button-rounded p-button-success"
        style={{ marginLeft: "1em" }}
        onClick={verClickEnfermeria}
      />
    </Fragment>
  );

  const renderHeaderEvoluciones = () => {
    return (
      <Fragment>
        <div>
          {selectedRowPaciente ? (
            <Tag value={"Residentes " + selectedRowPaciente.nombre}></Tag>
          ) : null}
        </div>

        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={tipo}
            onChange={(e) => setTipo(e.target.value)}
          >
            <FormControlLabel
              value="caidas"
              control={<Radio />}
              label="Caídas"
            />
            <FormControlLabel
              value="diabetes"
              control={<Radio />}
              label="Diabetes"
            />
            <FormControlLabel
              value="ulceras"
              control={<Radio />}
              label="Ulceras por Presión"
            />
            <FormControlLabel
              value="enfermeria"
              control={<Radio />}
              label="Enfermería"
            />
            <FormControlLabel
              value="anticoagulados"
              control={<Radio />}
              label="Anticoagulados"
            />
          </RadioGroup>
        </FormControl>
      </Fragment>
    );
  };

  const headerPacientes = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            fullWidth
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Residentes"></Tag>
      </div>
    </Fragment>
  );

  const renderFooterPacientes = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickPacientes}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHidePacientes}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderFooterEvolucion = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarEvolucion}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
        {selectedRow !== null ? (
          <ButtonMui
            onClick={imprimir}
            color="error"
            type="button"
            fullWidth
            variant="contained"
            startIcon={<CloseIcon />}
            autoFocus
          >
            Imprimir
          </ButtonMui>
        ) : null}
      </div>
    );
  };

  const renderFooterCaidas = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarFichasCaidas}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideFichasCaidas}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
        {selectedRow !== null ? (
          <ButtonMui
            onClick={imprimir}
            color="error"
            type="button"
            fullWidth
            variant="contained"
            startIcon={<CloseIcon />}
            autoFocus
          >
            Imprimir
          </ButtonMui>
        ) : null}
      </div>
    );
  };

  const renderFooterControlCaidas = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarControlCaidas}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideControlCaidas}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
        {selectedRow !== null ? (
          <ButtonMui
            onClick={imprimir}
            color="error"
            type="button"
            fullWidth
            variant="contained"
            startIcon={<CloseIcon />}
            autoFocus
          >
            Imprimir
          </ButtonMui>
        ) : null}
      </div>
    );
  };

  const renderFooterUlceras = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarUlceras}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideUlceras}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
        {selectedRow !== null ? (
          <ButtonMui
            onClick={imprimir}
            color="error"
            type="button"
            fullWidth
            variant="contained"
            startIcon={<CloseIcon />}
            autoFocus
          >
            Imprimir
          </ButtonMui>
        ) : null}
      </div>
    );
  };

  const renderFooterEnfermeria = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarEnfermeria}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideEnfermeria}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
        {selectedRow !== null ? (
          <ButtonMui
            onClick={imprimir}
            color="error"
            type="button"
            fullWidth
            variant="contained"
            startIcon={<CloseIcon />}
            autoFocus
          >
            Imprimir
          </ButtonMui>
        ) : null}
      </div>
    );
  };

  const renderFooterDiabetes = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarDiabetes}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideDiabetes}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
        {selectedRow !== null ? (
          <ButtonMui
            onClick={imprimir}
            color="error"
            type="button"
            fullWidth
            variant="contained"
            startIcon={<CloseIcon />}
            autoFocus
          >
            Imprimir
          </ButtonMui>
        ) : null}
      </div>
    );
  };

  const renderFooterAnticoagulados = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarAnticoagulados}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideAnticoagulados}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
        {selectedRow !== null ? (
          <ButtonMui
            onClick={imprimir}
            color="error"
            type="button"
            fullWidth
            variant="contained"
            startIcon={<CloseIcon />}
            autoFocus
          >
            Imprimir
          </ButtonMui>
        ) : null}
      </div>
    );
  };


  const onHidePacientes = (e) => {
    setSelectedRowPaciente(null);
    setDialogPacientes(false);
  };

  const aceptarClickPacientes = (e) => {
    if (selectedRowPaciente) {
      //verificarHistoriaClinica({ selectedRowPaciente });
      //obtenerInformeFragilidad({ pacienteId: selectedRowPaciente.id });
      //obtenerInformesHistoriaClinica({ pacienteId: selectedRowPaciente.id });
      obtenerEvoluciones({ pacienteId: selectedRowPaciente.id });
      obtenerDiabetes({ pacienteId: selectedRowPaciente.id });
      obtenerAnticoagulados({ pacienteId: selectedRowPaciente.id });
      setDialogPacientes(false);
      setTipo("caidas");
      setDialogEvoluciones(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const fechaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDateTime(rowData.fecha)}
      </Fragment>
    );
  };

  const fecha2Template = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDate(rowData.fecha)}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const documentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const ingresoEgresoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Ingreso/Egreso</span>
        {formatDate(rowData.fechaIngresoEgreso)}
      </Fragment>
    );
  };

  const motivoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Motivo</span>
        {rowData.motivoIngresoEgreso}
      </Fragment>
    );
  };

  const activoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Activo</span>
        <Checkbox checked={rowData.activo}></Checkbox>
      </Fragment>
    );
  };

  const horaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Hora</span>
        {rowData.hora}
      </Fragment>
    );
  };

  const testigoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Testigo</span>
        {rowData.testigo}
      </Fragment>
    );
  };

  const lugarTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Lugar</span>
        {rowData.lugar}
      </Fragment>
    );
  };

  const consecuenciasTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Consecuencias</span>
        {rowData.consecuencias}
      </Fragment>
    );
  };

  const tratamientoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Tratamiento</span>
        {rowData.tratamiento}
      </Fragment>
    );
  };

  const valoracionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Valoración</span>
        {rowData.valoracion}
      </Fragment>
    );
  };

  const localizacionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Localización</span>
        {rowData.localizacion}
      </Fragment>
    );
  };

  const mejoraTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Mejora</span>
        <Checkbox checked={rowData.mejora}></Checkbox>
      </Fragment>
    );
  };

  const estableTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Estable</span>
        <Checkbox checked={rowData.estable}></Checkbox>
      </Fragment>
    );
  };

  const empeoraTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Empeora</span>
        <Checkbox checked={rowData.empeora}></Checkbox>
      </Fragment>
    );
  };

  const curadaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Curada</span>
        <Checkbox checked={rowData.curada}></Checkbox>
      </Fragment>
    );
  };

  const ttoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Cambio TTO</span>
        {rowData.tto}
      </Fragment>
    );
  };

  const medicacionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Medicación</span>
        {rowData.medicacion}
      </Fragment>
    );
  };

  const temperaturaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Temperatura</span>
        {rowData.temperatura}
      </Fragment>
    );
  };

  const tensionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">T.A.</span>
        {rowData.tension}
      </Fragment>
    );
  };

  const frecuenciaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">F.C.</span>
        {rowData.frecuencia}
      </Fragment>
    );
  };

  const observacionesTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Observaciones</span>
        {rowData.observaciones}
      </Fragment>
    );
  };

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  //console.log(ulcera);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {evoluciones ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  align: "center",
                  margin: "1em",
                  marginTop:
                    isMobile && !isPortrait
                      ? "22vw"
                      : isMobile && isPortrait
                      ? "55vw"
                      : "0vw",
                }}
              >
                <Button
                  icon="pi pi-search"
                  tooltip="Pacientes"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
              </Grid>

              <Dialog
                showHeader={false}
                visible={dialogPacientes}
                closable={false}
                footer={renderFooterPacientes()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <Tooltip
                          target=".export-buttons>button"
                          position="bottom"
                        />
                        <DataTable
                          value={pacientes}
                          selection={selectedRowPaciente}
                          onSelectionChange={(e) =>
                            setSelectedRowPaciente(e.value)
                          }
                          selectionMode="single"
                          dataKey="id"
                          header={headerPacientes}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "small", marginTop: "1vw" }}
                          globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          paginator
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          rows={10}
                          rowsPerPageOptions={[10, 20, 50]}
                          responsiveLayout="scroll"

                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                        >
                          <Column
                            field="id"
                            header="ID"
                            body={idTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="nombre"
                            header="Apellido y Nombre"
                            body={nombreTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="domicilio"
                            header="Domicilio"
                            body={domicilioTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="numeroDocumento"
                            header="Documento"
                            body={documentoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="fechaIngresoEgreso"
                            header="Ingreso/Egreso"
                            body={ingresoEgresoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="motivoIngresoEgreso"
                            header="Motivo"
                            body={motivoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="activo"
                            header="Activo"
                            body={activoTemplate}
                            sortable
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderEvoluciones()}
                visible={dialogEvoluciones}
                maximized={true}
                //maximizable={true}
                closable={true}
                onHide={onHideEvoluciones}
                //footer={renderFooterEvoluciones()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "95vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    {tipo === "evolucion" && evoluciones ? (
                      <div className="datatable-responsive-demo">
                        <div className="card">
                          <Tooltip
                            target=".export-buttons>button"
                            position="bottom"
                          />
                          <DataTable
                            value={evoluciones}
                            selection={selectedRow}
                            onSelectionChange={(e) => setSelectedRow(e.value)}
                            selectionMode="single"
                            dataKey="id"
                            header={headerEvoluciones}
                            className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                            style={{ fontSize: "small", marginTop: "0vw" }}
                            //globalFilter={globalFilter}
                            emptyMessage="No hay datos."
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            responsiveLayout="scroll"

                            //paginatorLeft={paginatorLeft}
                            //paginatorRight={paginatorRight}
                          >
                            <Column
                              field="id"
                              header="ID"
                              body={idTemplate}
                              style={{ display: "none" }}
                              sortable
                            ></Column>
                            <Column
                              field="fecha"
                              header="Fecha"
                              body={fechaTemplate}
                              sortable
                              style={{ width: "10%" }}
                            ></Column>
                            <Column
                              field="evolucion"
                              header="Evolucion"
                              //body={documentoTemplate}
                              sortable
                            ></Column>
                          </DataTable>
                        </div>
                      </div>
                    ) : null}

                    {tipo === "caidas" && caidas ? (
                      <Grid item xs={12} md={12}>
                        <div className="card">
                          <TabView
                            activeIndex={activeIndex}
                            onTabChange={(e) => {
                              /*
                              if (e.index === 1) {
                                obtenerFichasCaidas({
                                  pacienteId: selectedRowPaciente.id,
                                });
                              }
                              */
                              if (e.index === 1) {
                                obtenerControlCaidas({
                                  pacienteId: selectedRowPaciente.id,
                                });
                              }
                              setActiveIndex(e.index);
                            }}
                          >
                            <TabPanel header="Protocolo">
                              <div>
                                <Document
                                  file={pdfCaidas}
                                  onLoadSuccess={onDocumentLoadSuccess}
                                >
                                  <Page pageNumber={pageNumber} />
                                </Document>
                                <p>
                                  Page {pageNumber} of {numPages}
                                </p>
                              </div>
                              <ButtonMui
                                onClick={imprimir}
                                color="error"
                                type="button"
                                fullWidth
                                variant="contained"
                                startIcon={<CloseIcon />}
                                autoFocus
                              >
                                Imprimir
                              </ButtonMui>
                            </TabPanel>
                            {/*
                            <TabPanel header="Ficha">
                              <div className="datatable-responsive-demo">
                                <div className="card">
                                  <Tooltip
                                    target=".export-buttons>button"
                                    position="bottom"
                                  />
                                  <DataTable
                                    value={caidas}
                                    selection={selectedRow}
                                    onSelectionChange={(e) =>
                                      setSelectedRow(e.value)
                                    }
                                    selectionMode="single"
                                    dataKey="id"
                                    header={headerFichaCaidas}
                                    className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                                    style={{
                                      fontSize: "small",
                                      marginTop: "0vw",
                                    }}
                                    //globalFilter={globalFilter}
                                    emptyMessage="No hay datos."
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    responsiveLayout="scroll"

                                    //paginatorLeft={paginatorLeft}
                                    //paginatorRight={paginatorRight}
                                  >
                                    <Column
                                      field="id"
                                      header="ID"
                                      body={idTemplate}
                                      style={{ display: "none" }}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="fecha"
                                      header="Fecha"
                                      body={fecha2Template}
                                      sortable
                                      style={{ width: "10%" }}
                                    ></Column>
                                    <Column
                                      field="hora"
                                      header="Hora"
                                      sortable
                                      body={horaTemplate}
                                      style={{ width: "10%" }}
                                    ></Column>
                                    <Column
                                      field="testigo"
                                      header="Testigo"
                                      body={testigoTemplate}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="lugar"
                                      header="Lugar"
                                      body={lugarTemplate}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="consecuencias"
                                      header="Consecuencias"
                                      body={consecuenciasTemplate}
                                      sortable
                                    ></Column>
                                  </DataTable>
                                </div>
                              </div>
                            </TabPanel>
                          */}

                            <TabPanel header="Controles">
                              <div className="datatable-responsive-demo">
                                <div className="card">
                                  <Tooltip
                                    target=".export-buttons>button"
                                    position="bottom"
                                  />
                                  <DataTable
                                    value={caidas}
                                    selection={selectedRow}
                                    onSelectionChange={(e) =>
                                      setSelectedRow(e.value)
                                    }
                                    selectionMode="single"
                                    dataKey="id"
                                    header={headerFichaCaidas}
                                    className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                                    style={{
                                      fontSize: "small",
                                      marginTop: "0vw",
                                    }}
                                    //globalFilter={globalFilter}
                                    emptyMessage="No hay datos."
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    responsiveLayout="scroll"

                                    //paginatorLeft={paginatorLeft}
                                    //paginatorRight={paginatorRight}
                                  >
                                    <Column
                                      field="id"
                                      header="ID"
                                      body={idTemplate}
                                      style={{ display: "none" }}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="fecha"
                                      header="Fecha"
                                      body={fecha2Template}
                                      sortable
                                      style={{ width: "10%" }}
                                    ></Column>
                                    <Column
                                      field="lugar"
                                      header="Lugar"
                                      body={lugarTemplate}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="consecuencias"
                                      header="Consecuencias"
                                      body={consecuenciasTemplate}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="tratamiento"
                                      header="Tratamiento"
                                      body={tratamientoTemplate}
                                      sortable
                                    ></Column>
                                  </DataTable>
                                </div>
                              </div>
                            </TabPanel>
                          </TabView>
                        </div>
                      </Grid>
                    ) : null}

                    {tipo === "diabetes" && diabetes ? (
                      <div className="datatable-responsive-demo">
                        <div className="card">
                          <Tooltip
                            target=".export-buttons>button"
                            position="bottom"
                          />
                          <DataTable
                            value={diabetes}
                            selection={selectedRow}
                            onSelectionChange={(e) => setSelectedRow(e.value)}
                            selectionMode="single"
                            dataKey="id"
                            header={headerDiabetes}
                            className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                            style={{ fontSize: "small", marginTop: "0vw" }}
                            //globalFilter={globalFilter}
                            emptyMessage="No hay datos."
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            responsiveLayout="scroll"

                            //paginatorLeft={paginatorLeft}
                            //paginatorRight={paginatorRight}
                          >
                            <Column
                              field="id"
                              header="ID"
                              //body={idTemplate}
                              style={{ display: "none" }}
                              sortable
                            ></Column>
                            <Column
                              field="fecha"
                              header="Fecha"
                              //body={fechaTemplate}
                              sortable
                              style={{ width: "10%" }}
                            ></Column>
                            <Column
                              field="tipo"
                              header="Tipo"
                              //body={documentoTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="medicacion"
                              header="Medicación"
                              //body={documentoTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="valor"
                              header="Valor"
                              //body={documentoTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="control"
                              header="Control"
                              //body={documentoTemplate}
                              sortable
                            ></Column>
                          </DataTable>
                        </div>
                      </div>
                    ) : null}

                    {tipo === "anticoagulados" && anticoagulados ? (
                      <div className="datatable-responsive-demo">
                        <div className="card">
                          <Tooltip
                            target=".export-buttons>button"
                            position="bottom"
                          />
                          <DataTable
                            value={anticoagulados}
                            selection={selectedRow}
                            onSelectionChange={(e) => setSelectedRow(e.value)}
                            selectionMode="single"
                            dataKey="id"
                            header={headerAnticoagulados}
                            className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                            style={{ fontSize: "small", marginTop: "0vw" }}
                            //globalFilter={globalFilter}
                            emptyMessage="No hay datos."
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            responsiveLayout="scroll"

                            //paginatorLeft={paginatorLeft}
                            //paginatorRight={paginatorRight}
                          >
                            <Column
                              field="id"
                              header="ID"
                              //body={idTemplate}
                              style={{ display: "none" }}
                              sortable
                            ></Column>
                            <Column
                              field="fecha"
                              header="Fecha"
                              //body={fechaTemplate}
                              sortable
                              style={{ width: "10%" }}
                            ></Column>
                            <Column
                              field="tipo"
                              header="Tipo"
                              //body={documentoTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="medicacion"
                              header="Medicación"
                              //body={documentoTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="valor"
                              header="Valor"
                              //body={documentoTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="control"
                              header="Control"
                              //body={documentoTemplate}
                              sortable
                            ></Column>
                          </DataTable>
                        </div>
                      </div>
                    ) : null}

                    {tipo === "ulceras" && ulceras ? (
                      <Grid item xs={12} md={12}>
                        <div className="card">
                          <TabView
                            activeIndex={activeIndex}
                            onTabChange={(e) => {
                              if (e.index === 1) {
                                obtenerUlceras({
                                  pacienteId: selectedRowPaciente.id,
                                });
                              }
                              setActiveIndex(e.index);
                            }}
                          >
                            <TabPanel header="Protocolo">
                              <div>
                                <Document
                                  file={pdfUlceras}
                                  onLoadSuccess={onDocumentLoadSuccess}
                                >
                                  <Page pageNumber={pageNumber} />
                                </Document>
                                <p>
                                  Page {pageNumber} of {numPages}
                                </p>
                                <button
                                  onClick={() => {
                                    if (pageNumber !== 1) {
                                      setPageNumber(pageNumber - 1);
                                    }
                                  }}
                                >
                                  Página anterior
                                </button>
                                <button
                                  onClick={() => {
                                    if (pageNumber !== 4) {
                                      setPageNumber(pageNumber + 1);
                                    }
                                  }}
                                >
                                  Página siguiente
                                </button>
                              </div>
                              <ButtonMui
                                onClick={imprimir}
                                color="error"
                                type="button"
                                fullWidth
                                variant="contained"
                                startIcon={<CloseIcon />}
                                autoFocus
                              >
                                Imprimir
                              </ButtonMui>
                            </TabPanel>
                            <TabPanel header="Controles">
                              <div className="datatable-responsive-demo">
                                <div className="card">
                                  <Tooltip
                                    target=".export-buttons>button"
                                    position="bottom"
                                  />
                                  <DataTable
                                    value={ulceras}
                                    selection={selectedRow}
                                    onSelectionChange={(e) =>
                                      setSelectedRow(e.value)
                                    }
                                    selectionMode="single"
                                    dataKey="id"
                                    header={headerUlceras}
                                    className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                                    style={{
                                      fontSize: "small",
                                      marginTop: "0vw",
                                    }}
                                    //globalFilter={globalFilter}
                                    emptyMessage="No hay datos."
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    responsiveLayout="scroll"

                                    //paginatorLeft={paginatorLeft}
                                    //paginatorRight={paginatorRight}
                                  >
                                    <Column
                                      field="id"
                                      header="ID"
                                      body={idTemplate}
                                      style={{ display: "none" }}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="fecha"
                                      header="Fecha"
                                      body={fecha2Template}
                                      sortable
                                      style={{ width: "10%" }}
                                    ></Column>
                                    <Column
                                      field="valoracion"
                                      header="Valoración"
                                      body={valoracionTemplate}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="localizacion"
                                      header="Localizacion"
                                      body={localizacionTemplate}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="mejora"
                                      header="Mejora"
                                      body={mejoraTemplate}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="estable"
                                      header="Estable"
                                      body={estableTemplate}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="empeora"
                                      header="Empeora"
                                      body={empeoraTemplate}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="curada"
                                      header="Curada"
                                      body={curadaTemplate}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="tto"
                                      header="Cambio TTO"
                                      body={ttoTemplate}
                                      sortable
                                    ></Column>
                                  </DataTable>
                                </div>
                              </div>
                            </TabPanel>
                          </TabView>
                        </div>
                      </Grid>
                    ) : null}

                    {tipo === "enfermeria" && enfermerias ? (
                      <Grid item xs={12} md={12}>
                        <div className="card">
                          <TabView
                            activeIndex={activeIndex}
                            onTabChange={(e) => {
                              if (e.index === 1) {
                                obtenerEnfermerias({
                                  pacienteId: selectedRowPaciente.id,
                                });
                              }
                              setActiveIndex(e.index);
                            }}
                          >
                            {/*
                            <TabPanel header="Protocolo">
                              <div>
                                <Document
                                  file={pdfEnfermeria}
                                  onLoadSuccess={onDocumentLoadSuccess}
                                >
                                  <Page pageNumber={pageNumber} />
                                </Document>
                                <p>
                                  Page {pageNumber} of {numPages}
                                </p>
                                <button
                                  onClick={() => {
                                    if (pageNumber !== 1) {
                                      setPageNumber(pageNumber - 1);
                                    }
                                  }}
                                >
                                  Página anterior
                                </button>
                                <button
                                  onClick={() => {
                                    if (pageNumber !== 2) {
                                      setPageNumber(pageNumber + 1);
                                    }
                                  }}
                                >
                                  Página siguiente
                                </button>
                              </div>
                              <ButtonMui
                                onClick={imprimir}
                                color="error"
                                type="button"
                                fullWidth
                                variant="contained"
                                startIcon={<CloseIcon />}
                                autoFocus
                              >
                                Imprimir
                              </ButtonMui>
                            </TabPanel>
                                */}
                            <TabPanel header="Controles">
                              <div className="datatable-responsive-demo">
                                <div className="card">
                                  <Tooltip
                                    target=".export-buttons>button"
                                    position="bottom"
                                  />
                                  <DataTable
                                    value={enfermerias}
                                    selection={selectedRow}
                                    onSelectionChange={(e) =>
                                      setSelectedRow(e.value)
                                    }
                                    selectionMode="single"
                                    dataKey="id"
                                    header={headerEnfermeria}
                                    className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                                    style={{
                                      fontSize: "small",
                                      marginTop: "0vw",
                                    }}
                                    //globalFilter={globalFilter}
                                    emptyMessage="No hay datos."
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    responsiveLayout="scroll"

                                    //paginatorLeft={paginatorLeft}
                                    //paginatorRight={paginatorRight}
                                  >
                                    <Column
                                      field="id"
                                      header="ID"
                                      body={idTemplate}
                                      style={{ display: "none" }}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="fecha"
                                      header="Fecha"
                                      body={fecha2Template}
                                      sortable
                                      style={{ width: "10%" }}
                                    ></Column>
                                    <Column
                                      field="medicacion"
                                      header="Medicación"
                                      body={medicacionTemplate}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="temperatura"
                                      header="Temperatura"
                                      body={temperaturaTemplate}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="tension"
                                      header="T.A."
                                      body={tensionTemplate}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="frecuencia"
                                      header="F.C."
                                      body={frecuenciaTemplate}
                                      sortable
                                    ></Column>
                                    <Column
                                      field="observaciones"
                                      header="Observaciones"
                                      body={observacionesTemplate}
                                      sortable
                                    ></Column>
                                  </DataTable>
                                </div>
                              </div>
                            </TabPanel>
                          </TabView>
                        </div>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeader()}
                visible={dialogEvolucion}
                closable={true}
                footer={renderFooterEvolucion()}
                maximizable={true}
                onHide={onHide}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "90vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid
                  container
                  spacing={3}
                  style={{ margin: "2vw", width: "auto" }}
                >
                  <Grid item xs={12} md={12}>
                    <textarea
                      rows="20"
                      //cols="100"
                      name="text"
                      placeholder="Ingrese evolución"
                      disabled={selectedRow !== null}
                      value={evolucion}
                      onChange={(e) => guardarEvolucion(e.target.value)}
                      style={{ width: "100%" }}
                    ></textarea>
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeader()}
                visible={dialogFichaCaida}
                closable={true}
                footer={renderFooterCaidas()}
                maximizable={true}
                onHide={onHideFichasCaidas}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "90vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid
                  container
                  spacing={3}
                  style={{ margin: "2vw", width: "auto" }}
                >
                  <Grid item xs={6} md={2}>
                    <TextField
                      id="fecha"
                      label="Fecha"
                      type="date"
                      fullWidth
                      variant="standard"
                      value={caida && caida.fecha ? caida.fecha : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          fecha: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={6} md={2}>
                    <TextField
                      id="hora"
                      label="Hora"
                      type="time"
                      fullWidth
                      variant="standard"
                      value={caida && caida.hora ? caida.hora : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          hora: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <TextField
                      id="testigo"
                      label="Testigo"
                      fullWidth
                      variant="standard"
                      value={caida && caida.testigo ? caida.testigo : null}
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          testigo: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="lugar"
                      label="Lugar del acontecimiento: (institución) (calle) casa) (otro)"
                      fullWidth
                      variant="standard"
                      value={caida && caida.lugar ? caida.lugar : null}
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          lugar: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="lugar2"
                      label="Lugar de la institución:(habitación) (comedor) (baño) (pasillo) (otro)"
                      fullWidth
                      variant="standard"
                      value={caida && caida.lugar2 ? caida.lugar2 : null}
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          lugar2: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="circunstancia"
                      label="Circunstancia"
                      fullWidth
                      variant="standard"
                      value={
                        caida && caida.circunstancia
                          ? caida.circunstancia
                          : null
                      }
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          circunstancia: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="diagnostico"
                      label="Diagnóstico"
                      fullWidth
                      variant="standard"
                      value={
                        caida && caida.diagnostico ? caida.diagnostico : null
                      }
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          diagnostico: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="intercurrencias"
                      label="Intercurrencias médicas que puedan influir"
                      fullWidth
                      variant="standard"
                      value={
                        caida && caida.intercurrencias
                          ? caida.intercurrencias
                          : null
                      }
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          intercurrencias: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="medicamentos"
                      label="Medicamentos que ingiere"
                      fullWidth
                      variant="standard"
                      value={
                        caida && caida.medicamentos ? caida.medicamentos : null
                      }
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          medicamentos: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="piso"
                      label="Piso"
                      fullWidth
                      variant="standard"
                      value={caida && caida.piso ? caida.piso : null}
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          piso: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="iluminacion"
                      label="Iluminación"
                      fullWidth
                      variant="standard"
                      value={
                        caida && caida.iluminacion ? caida.iluminacion : null
                      }
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          iluminacion: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="obstaculos"
                      label="Obstaculos"
                      fullWidth
                      variant="standard"
                      value={
                        caida && caida.obstaculos ? caida.obstaculos : null
                      }
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          obstaculos: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="barandas"
                      label="Barandas"
                      fullWidth
                      variant="standard"
                      value={caida && caida.barandas ? caida.barandas : null}
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          barandas: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="cama"
                      label="Cama"
                      fullWidth
                      variant="standard"
                      value={caida && caida.cama ? caida.cama : null}
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          cama: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="escaleras"
                      label="Escaleras"
                      fullWidth
                      variant="standard"
                      value={caida && caida.escaleras ? caida.escaleras : null}
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          escaleras: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="calzado"
                      label="Calzado"
                      fullWidth
                      variant="standard"
                      value={caida && caida.calzado ? caida.calzado : null}
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          calzado: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="silla"
                      label="Silla"
                      fullWidth
                      variant="standard"
                      value={caida && caida.silla ? caida.silla : null}
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          silla: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="acompanante"
                      label="Acompañante"
                      fullWidth
                      variant="standard"
                      value={
                        caida && caida.acompanante ? caida.acompanante : null
                      }
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          acompanante: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3} style={{ marginTop: "1.5vw" }}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Primera Vez
                      <SelectButton
                        value={primera}
                        options={veces}
                        onChange={(e) => setPrimera(e.value)}
                        style={{
                          float: "right",
                          marginTop: "-0.5vw",
                        }}
                      />
                    </FormLabel>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="tinetti"
                      label="Escala de Tinetti"
                      fullWidth
                      variant="standard"
                      value={caida && caida.tinetti ? caida.tinetti : null}
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          tinetti: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="consecuencias"
                      label="Consecuencias"
                      fullWidth
                      variant="standard"
                      value={
                        caida && caida.consecuencias
                          ? caida.consecuencias
                          : null
                      }
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          consecuencias: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeader()}
                visible={dialogControlCaida}
                closable={true}
                footer={renderFooterControlCaidas()}
                maximizable={true}
                onHide={onHideControlCaidas}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "90vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid
                  container
                  spacing={3}
                  style={{ margin: "2vw", width: "auto" }}
                >
                  <Grid item xs={6} md={2}>
                    <TextField
                      id="fecha"
                      label="Fecha"
                      type="date"
                      fullWidth
                      variant="standard"
                      value={caida && caida.fecha ? caida.fecha : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          fecha: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="lugar"
                      label="Lugar del acontecimiento: (institución) (calle) casa) (otro)"
                      fullWidth
                      variant="standard"
                      value={caida && caida.lugar ? caida.lugar : null}
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          lugar: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="consecuencias"
                      label="Consecuencias"
                      fullWidth
                      variant="standard"
                      value={
                        caida && caida.consecuencias
                          ? caida.consecuencias
                          : null
                      }
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          consecuencias: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="tratamiento"
                      label="Tratamiento"
                      fullWidth
                      variant="standard"
                      value={
                        caida && caida.tratamiento ? caida.tratamiento : null
                      }
                      onChange={(e) =>
                        guardarCaida({
                          ...caida,
                          tratamiento: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeader()}
                visible={dialogDiabetes}
                closable={true}
                footer={renderFooterDiabetes()}
                maximizable={true}
                onHide={onHideDiabetes}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid
                  container
                  spacing={3}
                  style={{ margin: "2vw", width: "auto" }}
                >
                  <Grid item xs={6} md={2}>
                    <TextField
                      id="fecha"
                      label="Fecha"
                      type="date"
                      fullWidth
                      variant="standard"
                      value={diabete && diabete.fecha ? diabete.fecha : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarDiabete({
                          ...diabete,
                          fecha: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="tipo"
                      label="Tipo"
                      fullWidth
                      variant="standard"
                      value={diabete && diabete.tipo ? diabete.tipo : null}
                      onChange={(e) =>
                        guardarDiabete({
                          ...diabete,
                          tipo: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="medicacion"
                      label="Medicación"
                      fullWidth
                      variant="standard"
                      value={
                        diabete && diabete.medicacion
                          ? diabete.medicacion
                          : null
                      }
                      onChange={(e) =>
                        guardarDiabete({
                          ...diabete,
                          medicacion: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="valor"
                      label="Valor"
                      fullWidth
                      variant="standard"
                      value={diabete && diabete.valor ? diabete.valor : null}
                      onChange={(e) =>
                        guardarDiabete({
                          ...diabete,
                          valor: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="control"
                      label="Control"
                      fullWidth
                      variant="standard"
                      value={
                        diabete && diabete.control ? diabete.control : null
                      }
                      onChange={(e) =>
                        guardarDiabete({
                          ...diabete,
                          control: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeader()}
                visible={dialogAnticoagulados}
                closable={true}
                footer={renderFooterAnticoagulados()}
                maximizable={true}
                onHide={onHideAnticoagulados}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid
                  container
                  spacing={3}
                  style={{ margin: "2vw", width: "auto" }}
                >
                  <Grid item xs={6} md={2}>
                    <TextField
                      id="fecha"
                      label="Fecha"
                      type="date"
                      fullWidth
                      variant="standard"
                      value={anticoagulado && anticoagulado.fecha ? anticoagulado.fecha : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarAnticoagulado({
                          ...anticoagulado,
                          fecha: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="tipo"
                      label="Tipo"
                      fullWidth
                      variant="standard"
                      value={anticoagulado && anticoagulado.tipo ? anticoagulado.tipo : null}
                      onChange={(e) =>
                        guardarAnticoagulado({
                          ...anticoagulado,
                          tipo: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="medicacion"
                      label="Medicación"
                      fullWidth
                      variant="standard"
                      value={
                        anticoagulado && anticoagulado.medicacion
                          ? anticoagulado.medicacion
                          : null
                      }
                      onChange={(e) =>
                        guardarAnticoagulado({
                          ...anticoagulado,
                          medicacion: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="valor"
                      label="Valor"
                      fullWidth
                      variant="standard"
                      value={anticoagulado && anticoagulado.valor ? anticoagulado.valor : null}
                      onChange={(e) =>
                        guardarAnticoagulado({
                          ...anticoagulado,
                          valor: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="control"
                      label="Control"
                      fullWidth
                      variant="standard"
                      value={
                        anticoagulado && anticoagulado.control ? anticoagulado.control : null
                      }
                      onChange={(e) =>
                        guardarAnticoagulado({
                          ...anticoagulado,
                          control: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeader()}
                visible={dialogUlcera}
                closable={true}
                footer={renderFooterUlceras()}
                maximizable={true}
                onHide={onHideUlceras}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid
                  container
                  spacing={3}
                  style={{ margin: "2vw", width: "auto" }}
                >
                  <Grid item xs={6} md={2}>
                    <TextField
                      id="fecha"
                      label="Fecha"
                      type="date"
                      fullWidth
                      variant="standard"
                      value={ulcera && ulcera.fecha ? ulcera.fecha : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarUlcera({
                          ...ulcera,
                          fecha: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="valoracion"
                      label="Valoración del riesgo"
                      fullWidth
                      variant="standard"
                      value={
                        ulcera && ulcera.valoracion ? ulcera.valoracion : null
                      }
                      onChange={(e) =>
                        guardarUlcera({
                          ...ulcera,
                          valoracion: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="localizacion"
                      label="Localización"
                      fullWidth
                      variant="standard"
                      value={
                        ulcera && ulcera.localizacion
                          ? ulcera.localizacion
                          : null
                      }
                      onChange={(e) =>
                        guardarUlcera({
                          ...ulcera,
                          localizacion: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel
                        id="evolucion"
                        style={{ fontWeight: "bold", fontSize: "large" }}
                      >
                        Evolución de la ulcera
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="evolucion"
                        value={
                          ulcera && ulcera.evolucion ? ulcera.evolucion : null
                        }
                        onChange={(e) =>
                          guardarUlcera({
                            ...ulcera,
                            evolucion: e.target.value,
                          })
                        }
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Mejora."
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Estable."
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="Empeora."
                        />
                        <FormControlLabel
                          value="4"
                          control={<Radio />}
                          label="Curada."
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="tto"
                      label="Cambio de TTO"
                      fullWidth
                      variant="standard"
                      value={ulcera && ulcera.tto ? ulcera.tto : null}
                      onChange={(e) =>
                        guardarUlcera({
                          ...ulcera,
                          tto: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeader()}
                visible={dialogEnfermeria}
                closable={true}
                footer={renderFooterEnfermeria()}
                maximizable={true}
                onHide={onHideEnfermeria}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid
                  container
                  spacing={3}
                  style={{ margin: "2vw", width: "auto" }}
                >
                  <Grid item xs={6} md={2}>
                    <TextField
                      id="fecha"
                      label="Fecha"
                      type="date"
                      fullWidth
                      variant="standard"
                      value={
                        enfermeria && enfermeria.fecha ? enfermeria.fecha : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarEnfermeria({
                          ...enfermeria,
                          fecha: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={10}>
                    <TextField
                      id="medicacion"
                      label="Medicación"
                      fullWidth
                      variant="standard"
                      value={
                        enfermeria && enfermeria.medicacion
                          ? enfermeria.medicacion
                          : null
                      }
                      onChange={(e) =>
                        guardarEnfermeria({
                          ...enfermeria,
                          medicacion: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="temperatura"
                      label="Temperatura"
                      fullWidth
                      variant="standard"
                      value={
                        enfermeria && enfermeria.temperatura
                          ? enfermeria.temperatura
                          : null
                      }
                      onChange={(e) =>
                        guardarEnfermeria({
                          ...enfermeria,
                          temperatura: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="tension"
                      label="T.A."
                      fullWidth
                      variant="standard"
                      value={
                        enfermeria && enfermeria.tension
                          ? enfermeria.tension
                          : null
                      }
                      onChange={(e) =>
                        guardarEnfermeria({
                          ...enfermeria,
                          tension: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="frecuencia"
                      label="F.C."
                      fullWidth
                      variant="standard"
                      value={
                        enfermeria && enfermeria.frecuencia
                          ? enfermeria.frecuencia
                          : null
                      }
                      onChange={(e) =>
                        guardarEnfermeria({
                          ...enfermeria,
                          frecuencia: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="observaciones"
                      label="Observaciones"
                      fullWidth
                      variant="standard"
                      value={
                        enfermeria && enfermeria.observaciones
                          ? enfermeria.observaciones
                          : null
                      }
                      onChange={(e) =>
                        guardarEnfermeria({
                          ...enfermeria,
                          observaciones: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
      {exit ? <Navigate to="/login" /> : null}
    </Fragment>
  );
}
