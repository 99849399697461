//imports front
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { AuthContext } from "../../context/AuthContext";
import { AlertContext } from "../../context/AlertContext";
import * as EmailValidator from "email-validator";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Dialog } from "primereact/dialog";
import Box from "@mui/material/Box";
import ButtonMui from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Tag } from "primereact/tag";
import { Card } from "primereact/card";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Toast } from "primereact/toast";
import Spinner from "./Spinner";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import TextField from "@mui/material/TextField";
import { SelectButton } from "primereact/selectbutton";
import { TabView, TabPanel } from "primereact/tabview";
import { Document, Page, pdfjs } from "react-pdf";
import pdfCaidas from "./caidas.pdf";
import pdfUlceras from "./ulcerasporpresion.pdf";
import pdfEnfermeria from "./enfermeria.pdf";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { maxWidth } from "@mui/system";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Autocomplete from "@mui/material/Autocomplete";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Evoluciones() {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;

  const [dialogEvoluciones, setDialogEvoluciones] = useState(false);
  const [dialogFrases, setDialogFrases] = useState(false);
  const [dialogControlCaida, setDialogControlCaida] = useState(false);
  const [dialogDiabetes, setDialogDiabetes] = useState(false);
  const [dialogUlcera, setDialogUlcera] = useState(false);
  const [dialogEnfermeria, setDialogEnfermeria] = useState(false);
  const [band, setBand] = useState(true);
  const [card, setCard] = useState(null);
  const [frase1, setFrase1] = useState(null);
  const [frase2, setFrase2] = useState(null);
  const [frase3, setFrase3] = useState(null);
  const [frase4, setFrase4] = useState(null);
  const [frase5, setFrase5] = useState(null);
  const [frase6, setFrase6] = useState(null);
  const [frase7, setFrase7] = useState(null);
  const [frase8, setFrase8] = useState(null);
  const toast = useRef(null);
  let navigate = useNavigate();
  const [dialogPacientes, setDialogPacientes] = useState(false);
  const [dialogEvolucion, setDialogEvolucion] = useState(false);
  const [exit, setExit] = useState(false);
  const [tipo, setTipo] = useState("evolucion");
  const veces = ["Si", "No"];
  const [primera, setPrimera] = useState("Si");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [activeIndex, setActiveIndex] = useState(0);
  const [caida, guardarCaida] = useState(null);
  const [diabete, guardarDiabete] = useState(null);
  const [ulcera, guardarUlcera] = useState(null);
  const [enfermeria, guardarEnfermeria] = useState(null);
  const autoC = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    evolucion,
    caidas,
    historiasClinicas,
    paciente,
    pacientes,
    historiaClinica,
    geriatricoElegido,
    visible,
    fragilidad,
    antecedente,
    actual,
    examenI,
    examenII,
    examenIII,
    evoluciones,
    diabetes,
    ulceras,
    enfermerias,
    frases,
    selectedRowPaciente,
    validarHistoriaClinica,
    obtenerHistoriasClinicas,
    obtenerAcceso,
    obtenerAcceso2,
    obtenerAcceso3,
    obtenerAcceso4,
    obtenerAcceso5,
    agregarHistoriaClinica,
    actualizarHistoriaClinica,
    setGeriatricoElegido,
    obtenerPaciente,
    obtenerPacientes,
    setSelectedRowPaciente,
    obtenerEvoluciones,
    guardarEvolucion,
    guardarCaidas,
    agregarEvolucion,
    agregarFrases,
    obtenerFrases,
    obtenerFichasCaidas,
    agregarFichasCaidas,
    actualizarFichasCaidas,
    obtenerControlCaidas,
    agregarControlCaidas,
    actualizarControlCaidas,
    obtenerDiabetes,
    guardarDiabetes,
    agregarDiabetes,
    actualizarDiabetes,
    obtenerUlceras,
    guardarUlceras,
    agregarUlceras,
    actualizarUlceras,
    guardarEnfermerias,
    obtenerEnfermerias,
    agregarEnfermerias,
    actualizarEnfermerias,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Evoluciones",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    obtenerPacientes({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    });
    obtenerFrases();
  }, []);

  const exitClick = (e) => {
    navigate("/");
  };

  const imprimir = (e) => {
    var line = 25; // Line height to start text at
    var lineHeight = 5;
    var leftMargin = 20;
    var wrapWidth = 180;
    var longString = evolucion;

    const doc = new jsPDF();

    doc.setFontSize(14);
    doc.text("EVOLUCION RESIDENTE " + selectedRowPaciente.nombre, 50, 10);

    doc.setFontSize(12);

    doc.setFontSize(12);
    var splitText = doc.splitTextToSize(longString, wrapWidth);
    for (var i = 0, length = splitText.length; i < length; i++) {
      // loop thru each line and increase
      doc.text(splitText[i], leftMargin, line);
      line = lineHeight + line;
    }

    let lineDate = line + 10;
    doc.text(formatDate(selectedRow.fecha), 10, lineDate);
    lineDate = line + 20;
    doc.text(selectedRow.usuario, 10, lineDate);

    window.open(doc.output("bloburl"));
  };

  const aceptarEvolucion = (e) => {
    if (selectedRow === null) {
      agregarEvolucion({
        paciente: selectedRowPaciente,
        evolucion: evolucion,
        usuario: state.usuario.usuario,
      });
      onHide();
      setTimeout(() => {
        obtenerEvoluciones({ pacienteId: selectedRowPaciente.id });
      }, 2000);
    }
  };

  const aceptarFrases = (e) => {
    agregarFrases({
      frase1,
      frase2,
      frase3,
      frase4,
      frase5,
      frase6,
    });
    onHideFrases();
  };

  const onHide = (e) => {
    setDialogEvolucion(false);
    //setDialogCaidas(false);
  };

  const onHideEvoluciones = (e) => {
    setDialogEvoluciones(false);
    //setDialogCaidas(false);
  };

  const onHideFrases = (e) => {
    setDialogFrases(false);
    //setDialogCaidas(false);
  };

  const addClick = (e) => {
    setDialogPacientes(true);
    setSelectedRowPaciente(null);
  };

  const frasesClick = (e) => {
    setDialogFrases(true);
    setSelectedRowPaciente(null);
  };

  const addClickEvolucion = (e) => {
    guardarEvolucion([]);
    setDialogEvolucion(true);
    setSelectedRow(null);
  };

  const verClickEvolucion = (e) => {
    if (selectedRow) {
      guardarEvolucion(selectedRow.evolucion);
      setDialogEvolucion(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una evolución.",
        life: 3000,
      });
    }
  };

  /* INICIO */

  if (band && frases && frases.length > 0) {
    setBand(false);
    if (frases[0].frase !== null && frases[0].frase !== undefined) {
      setFrase1(frases[0].frase);
    }
    if (frases[1].frase !== null && frases[1].frase !== undefined) {
      setFrase2(frases[1].frase);
    }
    if (frases[2].frase !== null && frases[2].frase !== undefined) {
      setFrase3(frases[2].frase);
    }
    if (frases[3].frase !== null && frases[3].frase !== undefined) {
      setFrase4(frases[3].frase);
    }
    if (frases[4].frase !== null && frases[4].frase !== undefined) {
      setFrase5(frases[4].frase);
    }
    if (frases[5].frase !== null && frases[5].frase !== undefined) {
      setFrase6(frases[5].frase);
    }
    if (frases[6].frase !== null && frases[6].frase !== undefined) {
      setFrase7(frases[6].frase);
    }
    if (frases[7].frase !== null && frases[7].frase !== undefined) {
      setFrase8(frases[7].frase);
    }
  }

  /* FIN */

  const renderHeader = () => {
    return (
      <Fragment>
        <div>
          {selectedRowPaciente ? (
            <Tag value={"Residente " + selectedRowPaciente.nombre}></Tag>
          ) : null}
        </div>
        {/*
        <div style={{ textAlign: "center" }}>
          {selectedRowPaciente ? (
            <Tag
              value={"EVOLUCIONES"}
            ></Tag>
          ) : null}
        </div>
          */}
        <div style={{ marginTop: "1vw" }} />

        {frases && frases.length > 0 ? (
          <Grid item xs={12} md={12}>
            <Autocomplete
              id="frases"
              fullWidth
              options={frases}
              disablePortal
              getOptionLabel={(option) => option.frase}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Opciones"
                  size="small"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
              )}
              onChange={(event, value) => {
                if (value && value.frase && value.frase !== null) {
                  if (evolucion) {
                    guardarEvolucion((evolucion) => evolucion +  value.frase);
                  } else {
                    guardarEvolucion(value.frase);
                  }
                }
                setTimeout(() => {
                  const ele = document.getElementsByClassName(
                    "MuiAutocomplete-clearIndicator"
                  )[0];
                  if (ele) ele.click();
                }, 500);
              }}

              //onKeyDown={handleEnter}
            />
          </Grid>
        ) : null}
      </Fragment>
    );
  };

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const headerEvoluciones = (
    <Fragment>
      <Button
        icon="pi pi-plus"
        tooltip="Agregar"
        className="p-button-rounded p-button-help"
        style={{ marginLeft: "0em" }}
        onClick={addClickEvolucion}
      />
      <Button
        icon="pi pi-pencil"
        tooltip="Ver"
        className="p-button-rounded p-button-success"
        style={{ marginLeft: "1em" }}
        onClick={verClickEvolucion}
      />
    </Fragment>
  );

  const renderHeaderFrases = () => {
    return (
      <Fragment>
        <div>
          <Tag value={"Frases Evoluciones"}></Tag>
        </div>
      </Fragment>
    );
  };

  const renderHeaderEvoluciones = () => {
    return (
      <Fragment>
        <div>
          {selectedRowPaciente ? (
            <Tag
              value={"Evoluciones Residente " + selectedRowPaciente.nombre}
            ></Tag>
          ) : null}
        </div>
      </Fragment>
    );
  };

  const headerPacientes = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            fullWidth
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Residentes"></Tag>
      </div>
    </Fragment>
  );

  const renderFooterPacientes = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickPacientes}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHidePacientes}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderFooterEvolucion = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarEvolucion}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
        {selectedRow !== null ? (
          <ButtonMui
            onClick={imprimir}
            color="error"
            type="button"
            fullWidth
            variant="contained"
            startIcon={<CloseIcon />}
            autoFocus
          >
            Imprimir
          </ButtonMui>
        ) : null}
      </div>
    );
  };

  const renderFooterFrases = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarFrases}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideFrases}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const onHidePacientes = (e) => {
    setSelectedRowPaciente(null);
    setDialogPacientes(false);
  };

  const aceptarClickPacientes = (e) => {
    if (selectedRowPaciente) {
      //verificarHistoriaClinica({ selectedRowPaciente });
      //obtenerInformeFragilidad({ pacienteId: selectedRowPaciente.id });
      //obtenerInformesHistoriaClinica({ pacienteId: selectedRowPaciente.id });
      obtenerEvoluciones({ pacienteId: selectedRowPaciente.id });
      obtenerDiabetes({ pacienteId: selectedRowPaciente.id });
      setDialogPacientes(false);
      setTipo("evolucion");
      setDialogEvoluciones(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const fechaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDateTime(rowData.fecha)}
      </Fragment>
    );
  };

  const fecha2Template = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDate(rowData.fecha)}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const documentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const ingresoEgresoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Ingreso/Egreso</span>
        {formatDate(rowData.fechaIngresoEgreso)}
      </Fragment>
    );
  };

  const motivoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Motivo</span>
        {rowData.motivoIngresoEgreso}
      </Fragment>
    );
  };

  const activoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Activo</span>
        <Checkbox checked={rowData.activo}></Checkbox>
      </Fragment>
    );
  };

  const horaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Hora</span>
        {rowData.hora}
      </Fragment>
    );
  };

  const testigoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Testigo</span>
        {rowData.testigo}
      </Fragment>
    );
  };

  const lugarTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Lugar</span>
        {rowData.lugar}
      </Fragment>
    );
  };

  const consecuenciasTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Consecuencias</span>
        {rowData.consecuencias}
      </Fragment>
    );
  };

  const tratamientoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Tratamiento</span>
        {rowData.tratamiento}
      </Fragment>
    );
  };

  const valoracionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Valoración</span>
        {rowData.valoracion}
      </Fragment>
    );
  };

  const localizacionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Localización</span>
        {rowData.localizacion}
      </Fragment>
    );
  };

  const mejoraTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Mejora</span>
        <Checkbox checked={rowData.mejora}></Checkbox>
      </Fragment>
    );
  };

  const estableTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Estable</span>
        <Checkbox checked={rowData.estable}></Checkbox>
      </Fragment>
    );
  };

  const empeoraTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Empeora</span>
        <Checkbox checked={rowData.empeora}></Checkbox>
      </Fragment>
    );
  };

  const curadaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Curada</span>
        <Checkbox checked={rowData.curada}></Checkbox>
      </Fragment>
    );
  };

  const ttoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Cambio TTO</span>
        {rowData.tto}
      </Fragment>
    );
  };

  const medicacionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Medicación</span>
        {rowData.medicacion}
      </Fragment>
    );
  };

  const temperaturaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Temperatura</span>
        {rowData.temperatura}
      </Fragment>
    );
  };

  const tensionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">T.A.</span>
        {rowData.tension}
      </Fragment>
    );
  };

  const frecuenciaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">F.C.</span>
        {rowData.frecuencia}
      </Fragment>
    );
  };

  const observacionesTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Observaciones</span>
        {rowData.observaciones}
      </Fragment>
    );
  };

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  //console.log(selectedRow)

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {evoluciones ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  align: "center",
                  margin: "1em",
                  marginTop:
                    isMobile && !isPortrait
                      ? "22vw"
                      : isMobile && isPortrait
                      ? "55vw"
                      : "0vw",
                }}
              >
                <Button
                  icon="pi pi-search"
                  tooltip="Pacientes"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-align-justify"
                  tooltip="Frases"
                  className="p-button-rounded p-button-success"
                  style={{ marginLeft: "1em" }}
                  onClick={frasesClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
              </Grid>

              <Dialog
                showHeader={false}
                visible={dialogPacientes}
                closable={false}
                footer={renderFooterPacientes()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <Tooltip
                          target=".export-buttons>button"
                          position="bottom"
                        />
                        <DataTable
                          value={pacientes}
                          selection={selectedRowPaciente}
                          onSelectionChange={(e) =>
                            setSelectedRowPaciente(e.value)
                          }
                          selectionMode="single"
                          dataKey="id"
                          header={headerPacientes}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "small", marginTop: "1vw" }}
                          globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          paginator
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          rows={10}
                          rowsPerPageOptions={[10, 20, 50]}
                          responsiveLayout="scroll"

                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                        >
                          <Column
                            field="id"
                            header="ID"
                            body={idTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="nombre"
                            header="Apellido y Nombre"
                            body={nombreTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="domicilio"
                            header="Domicilio"
                            body={domicilioTemplate}
                            style={{ width: "30%" }}
                            sortable
                          ></Column>
                          <Column
                            field="numeroDocumento"
                            header="Documento"
                            body={documentoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="fechaIngresoEgreso"
                            header="Ingreso/Egreso"
                            body={ingresoEgresoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="motivoIngresoEgreso"
                            header="Motivo"
                            body={motivoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="activo"
                            header="Activo"
                            body={activoTemplate}
                            sortable
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderEvoluciones()}
                visible={dialogEvoluciones}
                maximized={true}
                //maximizable={true}
                closable={true}
                onHide={onHideEvoluciones}
                //footer={renderFooterEvoluciones()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "95vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    {evoluciones ? (
                      <div className="datatable-responsive-demo">
                        <div className="card">
                          <Tooltip
                            target=".export-buttons>button"
                            position="bottom"
                          />
                          <DataTable
                            value={evoluciones}
                            selection={selectedRow}
                            onSelectionChange={(e) => setSelectedRow(e.value)}
                            selectionMode="single"
                            dataKey="id"
                            header={headerEvoluciones}
                            className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                            style={{ fontSize: "small", marginTop: "0vw" }}
                            //globalFilter={globalFilter}
                            emptyMessage="No hay datos."
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            responsiveLayout="scroll"

                            //paginatorLeft={paginatorLeft}
                            //paginatorRight={paginatorRight}
                          >
                            <Column
                              field="id"
                              header="ID"
                              body={idTemplate}
                              style={{ display: "none" }}
                              sortable
                            ></Column>
                            <Column
                              field="fecha"
                              header="Fecha"
                              body={fechaTemplate}
                              sortable
                              style={{ width: "10%" }}
                            ></Column>
                            <Column
                              field="evolucion"
                              header="Evolucion"
                              //body={documentoTemplate}
                              sortable
                            ></Column>
                          </DataTable>
                        </div>
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeader()}
                visible={dialogEvolucion}
                closable={true}
                footer={renderFooterEvolucion()}
                maximizable={true}
                onHide={onHide}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "90vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid
                  container
                  spacing={3}
                  style={{ margin: "2vw", width: "auto" }}
                >
                  <Grid item xs={12} md={12}>
                    <textarea
                      rows="20"
                      //cols="100"
                      name="text"
                      placeholder="Ingrese evolución"
                      disabled={selectedRow !== null}
                      value={evolucion}
                      onChange={(e) => guardarEvolucion(e.target.value)}
                      style={{ width: "100%" }}
                    ></textarea>
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderFrases()}
                visible={dialogFrases}
                //maximized={true}
                //maximizable={true}
                closable={true}
                onHide={onHideFrases}
                footer={renderFooterFrases()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="frase1"
                      label="Opción 1"
                      fullWidth
                      variant="standard"
                      value={frase1 ? frase1 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase1(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="frase2"
                      label="Opción 2"
                      fullWidth
                      variant="standard"
                      value={frase2 ? frase2 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase2(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="frase3"
                      label="Opción 3"
                      fullWidth
                      variant="standard"
                      value={frase3 ? frase3 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase3(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="frase4"
                      label="Opción 4"
                      fullWidth
                      variant="standard"
                      value={frase4 ? frase4 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase4(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="frase5"
                      label="Opción 5"
                      fullWidth
                      variant="standard"
                      value={frase5 ? frase5 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase5(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="frase6"
                      label="Opción 6"
                      fullWidth
                      variant="standard"
                      value={frase6 ? frase6 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase6(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="frase7"
                      label="Opción 7"
                      fullWidth
                      variant="standard"
                      value={frase7 ? frase7 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase7(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="frase8"
                      label="Opción 8"
                      fullWidth
                      variant="standard"
                      value={frase8 ? frase8 : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setFrase8(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
      {exit ? <Navigate to="/login" /> : null}
    </Fragment>
  );
}
