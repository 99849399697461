import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";

export default function ChequeoBanos(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    chequeoBanos,
    statusAcceso,
    statusAcceso3,
    localidades,
    guardarChequeoBanos,
    obtenerAcceso,
  } = serviciosContext;

  const handleChangePregunta1 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta1: event.target.checked,
    });
  };

  const handleChangePregunta2 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta2: event.target.checked,
    });
  };

  const handleChangePregunta3 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta3: event.target.checked,
    });
  };

  const handleChangePregunta4 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta4: event.target.checked,
    });
  };

  const handleChangePregunta5 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta5: event.target.checked,
    });
  };

  const handleChangePregunta6 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta6: event.target.checked,
    });
  };

  const handleChangePregunta7 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta7: event.target.checked,
    });
  };

  const handleChangePregunta8 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta8: event.target.checked,
    });
  };

  const handleChangePregunta9 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta9: event.target.checked,
    });
  };

  const handleChangePregunta10 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta10: event.target.checked,
    });
  };

  const handleChangePregunta11 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta11: event.target.checked,
    });
  };

  const handleChangePregunta12 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta12: event.target.checked,
    });
  };

  const handleChangePregunta13 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta13: event.target.checked,
    });
  };

  const handleChangePregunta14 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta14: event.target.checked,
    });
  };

  const handleChangePregunta15 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta15: event.target.checked,
    });
  };

  const handleChangePregunta16 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta16: event.target.checked,
    });
  };

  const handleChangePregunta17 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta17: event.target.checked,
    });
  };

  const handleChangePregunta18 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta18: event.target.checked,
    });
  };

  const handleChangePregunta19 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta19: event.target.checked,
    });
  };

  const handleChangePregunta20 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta20: event.target.checked,
    });
  };

  const handleChangePregunta21 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta21: event.target.checked,
    });
  };

  const handleChangePregunta22 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta22: event.target.checked,
    });
  };

  const handleChangePregunta23 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta23: event.target.checked,
    });
  };

  const handleChangePregunta24 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta24: event.target.checked,
    });
  };

  const handleChangePregunta25 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta25: event.target.checked,
    });
  };

  const handleChangePregunta26 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta26: event.target.checked,
    });
  };

  const handleChangePregunta27 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta27: event.target.checked,
    });
  };

  const handleChangePregunta28 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta28: event.target.checked,
    });
  };

  const handleChangePregunta29 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta29: event.target.checked,
    });
  };
  const handleChangePregunta30 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta30: event.target.checked,
    });
  };
  const handleChangePregunta31 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta31: event.target.checked,
    });
  };
  const handleChangePregunta32 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta32: event.target.checked,
    });
  };
  const handleChangePregunta33 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta33: event.target.checked,
    });
  };
  const handleChangePregunta34 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta34: event.target.checked,
    });
  };
  const handleChangePregunta35 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta35: event.target.checked,
    });
  };
  const handleChangePregunta36 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta36: event.target.checked,
    });
  };
  const handleChangePregunta37 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta37: event.target.checked,
    });
  };
  const handleChangePregunta38 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta38: event.target.checked,
    });
  };
  const handleChangePregunta39 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta39: event.target.checked,
    });
  };
  const handleChangePregunta40 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta40: event.target.checked,
    });
  };
  const handleChangePregunta41 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta41: event.target.checked,
    });
  };
  const handleChangePregunta42 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta42: event.target.checked,
    });
  };
  const handleChangePregunta43 = (event) => {
    guardarChequeoBanos({
      ...chequeoBanos,
      pregunta43: event.target.checked,
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    return;
  };

  //console.log(chequeoBanos);

  return (
    <Fragment>
      <Fragment>
        <Grid
          container
          spacing={3}
          style={{ marginTop: "1vw", marginLeft: "0vw" }}
        >
          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta1 : null}
                    onChange={handleChangePregunta1}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Puerta en buen estado, que cierre correctamente, completa con 3 bisagras y picaporte de los 2 lados estilo
                  sanatorial."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta2 : null}
                    onChange={handleChangePregunta2}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cartel indicador sanitario."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta3 : null}
                    onChange={handleChangePregunta3}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cartel indicador de aptos discapacitados."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta4 : null}
                    onChange={handleChangePregunta4}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Funcionamiento de los dispenser de jabón en buen estado y cargado."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta5 : null}
                    onChange={handleChangePregunta5}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Dispenser de toallas deberán estar en buen estado, fijas a la pared y cargadas con papel."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta6 : null}
                    onChange={handleChangePregunta6}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Dispenser de papel deberán estar en buen estado, fijas a la pared y cargadas con papel."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta7 : null}
                    onChange={handleChangePregunta7}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Espejo pintado, en buen estado, colocado a la altura correspondiente."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta8 : null}
                    onChange={handleChangePregunta8}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Tiene inclinación para discapacitados."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta9 : null}
                    onChange={handleChangePregunta9}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Iluminación automática funcionando, completa con lámpara y plafón."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta10 : null}
                    onChange={handleChangePregunta10}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Tachos de basura en buen estado con tapa."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta11 : null}
                    onChange={handleChangePregunta11}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Ventilación natural con rejilla limpia, mosquitero."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta12 : null}
                    onChange={handleChangePregunta12}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Ventilación forzada. funcionando."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta13 : null}
                    onChange={handleChangePregunta13}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Teclas de luz con módulo completo y funcionando."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta14 : null}
                    onChange={handleChangePregunta14}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Rejilla de piso en buenas condiciones bien amuradas. A nivel del piso."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta15 : null}
                    onChange={handleChangePregunta15}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Perchero."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta16 : null}
                    onChange={handleChangePregunta16}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Llamador funcionando."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta17 : null}
                    onChange={handleChangePregunta17}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Agarraderas."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta18 : null}
                    onChange={handleChangePregunta18}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Barandas con tranquera firme y pintada."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta19 : null}
                    onChange={handleChangePregunta19}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Lavatorio: firme, adherido a la pared a la altura correspondiente."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta20 : null}
                    onChange={handleChangePregunta20}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Sin columna de apoyo."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta21 : null}
                    onChange={handleChangePregunta21}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Flexibles sin pérdida."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta22 : null}
                    onChange={handleChangePregunta22}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Desagüe sin pérdidas ni roturas."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta23 : null}
                    onChange={handleChangePregunta23}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Botón de descargas sin pérdida, funcionando correctamente."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta24 : null}
                    onChange={handleChangePregunta24}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Mochilas con descargas sin pérdida, o válvula completa funcionando correctamente."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta25 : null}
                    onChange={handleChangePregunta25}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Inodoro: blanco, firme adherido al piso y estéticamente aceptable."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta26 : null}
                    onChange={handleChangePregunta26}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Tapa inodoro completa, firme, limpia."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta27 : null}
                    onChange={handleChangePregunta27}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Ducha con volante completo."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta28 : null}
                    onChange={handleChangePregunta28}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Duchador completo."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta29 : null}
                    onChange={handleChangePregunta29}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Canilla funcionando con agua caliente y fría."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta30 : null}
                    onChange={handleChangePregunta30}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Con volante completa."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta31 : null}
                    onChange={handleChangePregunta31}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Silla para bañar en buen estado limpia."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta32 : null}
                    onChange={handleChangePregunta32}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Suministro de agua continuo."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta33 : null}
                    onChange={handleChangePregunta33}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Aparición de humedades."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta34 : null}
                    onChange={handleChangePregunta34}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Pintura en techos, paredes y barandas."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta35 : null}
                    onChange={handleChangePregunta35}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Limpieza y orden."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta36 : null}
                    onChange={handleChangePregunta36}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Planilla indicadora de turnos de limpieza firmados por horarios."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta37 : null}
                    onChange={handleChangePregunta37}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cartel indicador de no arrojar papeles al inodoro."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta38 : null}
                    onChange={handleChangePregunta38}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cartel indicador de agua no potable."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta39 : null}
                    onChange={handleChangePregunta39}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cartel indicador de conserve el orden y la limpieza."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta40 : null}
                    onChange={handleChangePregunta40}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Cartel indicador de lávese las manos."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta41 : null}
                    onChange={handleChangePregunta41}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="No hay elementos sobrantes que no pertenezcan al área. Secador de piso. Balde. Trapos."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta42 : null}
                    onChange={handleChangePregunta42}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="No hay cables a la vista o cajas de electricidad abiertas."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chequeoBanos ? chequeoBanos.pregunta43 : null}
                    onChange={handleChangePregunta43}
                    inputProps={{ "aria-label": "controlled" }}
                    value="1"
                    style={{ marginRight: "1vw" }}
                  />
                }
                label="Paredes sucias. Artefactos. Agarraderas. techos o pisos."
                labelPlacement="end"
                //disabled={chequeoBanos && statusAcceso !== 200}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12} />

          <Grid item xs={12} md={2}>
            <TextField
              id="banoNum1"
              label="Baño Nº"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoNum1 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoNum1: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="banoCondicion1"
              label="Condición"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoCondicion1 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoCondicion1: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="banoComentario1"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoComentario1 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoComentario1: e.target.value,
                });
              }}
            />
          </Grid>


          <Grid item xs={12} md={2}>
            <TextField
              id="banoNum2"
              label="Baño Nº"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoNum2 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoNum2: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="banoCondicion2"
              label="Condición"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoCondicion2 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoCondicion2: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="banoComentario2"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoComentario2 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoComentario2: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="banoNum3"
              label="Baño Nº"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoNum3 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoNum3: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="banoCondicion3"
              label="Condición"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoCondicion3 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoCondicion3: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="banoComentario3"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoComentario3 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoComentario3: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="banoNum4"
              label="Baño Nº"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoNum4 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoNum4: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="banoCondicion4"
              label="Condición"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoCondicion4 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoCondicion4: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="banoComentario4"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoComentario4 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoComentario4: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="banoNum5"
              label="Baño Nº"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoNum5 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoNum5: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="banoCondicion5"
              label="Condición"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoCondicion5 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoCondicion5: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="banoComentario5"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoComentario5 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoComentario5: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="banoNum6"
              label="Baño Nº"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoNum6 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoNum6: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="banoCondicion6"
              label="Condición"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoCondicion6 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoCondicion6: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="banoComentario6"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoComentario6 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoComentario6: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="banoNum7"
              label="Baño Nº"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoNum7 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoNum7: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="banoCondicion7"
              label="Condición"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoCondicion7 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoCondicion7: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="banoComentario7"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoComentario7 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoComentario7: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="banoNum8"
              label="Baño Nº"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoNum8 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoNum8: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="banoCondicion8"
              label="Condición"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoCondicion8 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoCondicion8: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="banoComentario8"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoComentario8 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoComentario8: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="banoNum9"
              label="Baño Nº"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoNum9 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoNum9: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="banoCondicion9"
              label="Condición"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoCondicion9 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoCondicion9: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="banoComentario9"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoComentario9 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoComentario9: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="banoNum10"
              label="Baño Nº"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoNum10 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoNum10: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="banoCondicion10"
              label="Condición"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoCondicion10 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoCondicion10: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="banoComentario10"
              label="Comentario / Desvío"
              variant="standard"
              fullWidth
              value={chequeoBanos ? chequeoBanos.banoComentario10 : null}
              onChange={(e) => {
                guardarChequeoBanos({
                  ...chequeoBanos,
                  banoComentario10: e.target.value,
                });
              }}
            />
          </Grid>


        </Grid>
      </Fragment>
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
