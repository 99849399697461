import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

export default function ExamenII(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    examenII,
    statusAcceso3,
    localidades,
    guardarExamenII,
    obtenerAcceso3,
    obtenerAcceso4,
  } = serviciosContext;

  useEffect(() => {
    obtenerAcceso4({
      usuarioId: state.usuario.id,
      layout: "Psicologia",
    });
    obtenerAcceso3({
      usuarioId: state.usuario.id,
      layout: "EditarPIHC",
    });
  }, []);

  const handleChangePregunta1 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta1: event.target.value,
    });
  };

  const handleChangePregunta2 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta2: event.target.value,
    });
  };

  const handleChangePregunta3 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta3: event.target.value,
    });
  };

  const handleChangePregunta4 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta4: event.target.value,
    });
  };

  const handleChangePregunta5 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta5: event.target.value,
    });
  };

  const handleChangePregunta6 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta6: event.target.value,
    });
  };

  const handleChangePregunta7 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta7: event.target.value,
    });
  };

  const handleChangePregunta8 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta8: event.target.value,
    });
  };

  const handleChangePregunta9 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta9: event.target.value,
    });
  };

  const handleChangePregunta10 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta10: event.target.value,
    });
  };

  const handleChangePregunta11 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta11: event.target.value,
    });
  };

  const handleChangePregunta12 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta12: event.target.value,
    });
  };

  const handleChangePregunta13 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta13: event.target.value,
    });
  };

  const handleChangePregunta14 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta14: event.target.value,
    });
  };

  const handleChangePregunta15 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta15: event.target.value,
    });
  };

  const handleChangePregunta16 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta16: event.target.value,
    });
  };

  const handleChangePregunta17 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta17: event.target.value,
    });
  };

  const handleChangePregunta18 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta18: event.target.value,
    });
  };

  const handleChangePregunta19 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta19: event.target.value,
    });
  };

  const handleChangePregunta20 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta20: event.target.value,
    });
  };

  const handleChangePregunta21 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta21: event.target.value,
    });
  };

  const handleChangePregunta22 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta22: event.target.value,
    });
  };

  const handleChangePregunta23 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta23: event.target.value,
    });
  };

  const handleChangePregunta24 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta24: event.target.value,
    });
  };

  const handleChangePregunta25 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta25: event.target.value,
    });
  };

  const handleChangePregunta26 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta26: event.target.value,
    });
  };

  const handleChangePregunta27 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta27: event.target.value,
    });
  };

  const handleChangePregunta28 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta28: event.target.value,
    });
  };

  const handleChangePregunta29 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta29: event.target.value,
    });
  };

  const handleChangePregunta30 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta30: event.target.value,
    });
  };

  const handleChangePregunta31 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta31: event.target.value,
    });
  };

  const handleChangePregunta32 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta32: event.target.value,
    });
  };

  const handleChangePregunta33 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta33: event.target.value,
    });
  };

  const handleChangePregunta34 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta34: event.target.value,
    });
  };

  const handleChangePregunta35 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta35: event.target.value,
    });
  };

  const handleChangePregunta36 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta36: event.target.value,
    });
  };

  const handleChangePregunta37 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta37: event.target.value,
    });
  };

  const handleChangePregunta38 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta38: event.target.value,
    });
  };

  const handleChangePregunta39 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta39: event.target.value,
    });
  };

  const handleChangePregunta40 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta40: event.target.value,
    });
  };

  const handleChangePregunta41 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta41: event.target.value,
    });
  };

  const handleChangePregunta42 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta42: event.target.value,
    });
  };

  const handleChangePregunta43 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta43: event.target.value,
    });
  };

  const handleChangePregunta44 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta44: event.target.value,
    });
  };

  const handleChangePregunta45 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta45: event.target.value,
    });
  };

  const handleChangePregunta46 = (event) => {
    guardarExamenII({
      ...examenII,
      pregunta46: event.target.value,
    });
  };

  //console.log(paciente);
  //console.log(examenII);

  return (
    <Fragment>
      {examenII ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "0vw", marginLeft: "0vw" }}
          >
            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                GENITOURINARIO:
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta1 : null}
                  onChange={handleChangePregunta1}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Inflamación
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/*
            <Grid item xs={12} md={6} style={{ marginTop: "-3vw" }}>
              <TextField
                value={examenII ? examenII.tactoRectal : null}
                id="tactoRectal"
                label="Tacto Rectal"
                //fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenII({
                    ...examenII,
                    tactoRectal: e.target.value,
                  })
                }
                disabled={examenII && examenII.status && statusAcceso3 !== 200}
              />
            </Grid>
              */}

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta2 : null}
                  onChange={handleChangePregunta2}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Micosis
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta3 : null}
                  onChange={handleChangePregunta3}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    impactación fecal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta4 : null}
                  onChange={handleChangePregunta4}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Flujo vaginal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta5 : null}
                  onChange={handleChangePregunta5}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Crecimiento prostático
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta6 : null}
                  onChange={handleChangePregunta6}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Ulceras genitales
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta7 : null}
                  onChange={handleChangePregunta7}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Hemorroides: Ext.
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta8 : null}
                  onChange={handleChangePregunta8}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Prolapso uterino
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta9 : null}
                  onChange={handleChangePregunta9}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Hemorroides Int.
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta10 : null}
                  onChange={handleChangePregunta10}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Prolapso rectal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta11 : null}
                  onChange={handleChangePregunta11}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Revisado normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <TextField
                value={examenII ? examenII.otrosGenitourinarios : null}
                id="otrosGenitourinarios"
                label="Otros"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenII({
                    ...examenII,
                    otrosGenitourinarios: e.target.value,
                  })
                }
                disabled={examenII && examenII.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                LOCOMOTOR :
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta12 : null}
                  onChange={handleChangePregunta12}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Amplitud mov. articulares normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta13 : null}
                  onChange={handleChangePregunta13}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Insuf. vascular periférica
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta14 : null}
                  onChange={handleChangePregunta14}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Contracturas
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta15 : null}
                  onChange={handleChangePregunta15}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Ulceras EE.II
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta16 : null}
                  onChange={handleChangePregunta16}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Atrofias musculares
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta44 : null}
                  onChange={handleChangePregunta44}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Revisado normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                Pulsos art. MM.II :
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta17 : null}
                  onChange={handleChangePregunta17}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Femoral
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta18 : null}
                  onChange={handleChangePregunta18}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Tibial
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta19 : null}
                  onChange={handleChangePregunta19}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Poplíteo
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta20 : null}
                  onChange={handleChangePregunta20}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Pedio
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                PIES: :
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta21 : null}
                  onChange={handleChangePregunta21}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Dolor
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta22 : null}
                  onChange={handleChangePregunta22}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Maceración
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta23 : null}
                  onChange={handleChangePregunta23}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Deformación
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta24 : null}
                  onChange={handleChangePregunta24}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Hiperqueratosis
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta25 : null}
                  onChange={handleChangePregunta25}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Ulceras
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                PIEL y FANERAS:
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta26 : null}
                  onChange={handleChangePregunta26}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Heridas
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta27 : null}
                  onChange={handleChangePregunta27}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Edemas
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta28 : null}
                  onChange={handleChangePregunta28}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Elasticidad normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta29 : null}
                  onChange={handleChangePregunta29}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Ulceras: Presión (grado I-IV):
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta30 : null}
                  onChange={handleChangePregunta30}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Turgencia normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta31 : null}
                  onChange={handleChangePregunta31}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Actínicas
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta32 : null}
                  onChange={handleChangePregunta32}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Humedad normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta33 : null}
                  onChange={handleChangePregunta33}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Seborreica
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta45 : null}
                  onChange={handleChangePregunta45}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Revisado normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "-2vw" }}>
              <TextField
                value={examenII ? examenII.otrosPielFaneras : null}
                id="otrosPielFaneras"
                label="Otros"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  guardarExamenII({
                    ...examenII,
                    otrosPielFaneras: e.target.value,
                  })
                }
                disabled={examenII && examenII.status && statusAcceso3 !== 200}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" style={{ textDecoration: "underline" }}>
                SISTEMA NERVIOSO:
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta34 : null}
                  onChange={handleChangePregunta34}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Pares craneales y reflejos pupilares: presentes
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta35 : null}
                  onChange={handleChangePregunta35}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Reflejos osteotendinosos normales:
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta36 : null}
                  onChange={handleChangePregunta36}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Sensibilidad normal:
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta37 : null}
                  onChange={handleChangePregunta37}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Marcha y equilibrio: normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta38 : null}
                  onChange={handleChangePregunta38}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Movimientos anormales:
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta39 : null}
                  onChange={handleChangePregunta39}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Afasia:
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta40 : null}
                  onChange={handleChangePregunta40}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Fuerza muscular normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta41 : null}
                  onChange={handleChangePregunta41}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Flexores plantares normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta42 : null}
                  onChange={handleChangePregunta42}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Abductores de la cadera normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta43 : null}
                  onChange={handleChangePregunta43}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Extensores de la cadera normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginTop: "-2vw" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={examenII ? examenII.pregunta46 : null}
                  onChange={handleChangePregunta46}
                >
                  <FormLabel
                    id="escalones"
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginRight: "1vw",
                      marginTop: ".5vw",
                    }}
                  >
                    Revisado normal
                  </FormLabel>

                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="SI"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="NO"
                    disabled={
                      examenII && examenII.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
