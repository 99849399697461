import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function Articulos(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  let navigate = useNavigate();

  const exportColumns = [
    { title: "Id", dataKey: "id" },
    { title: "Descripción", dataKey: "descripcion" },
    { title: "Punto de Pedido", dataKey: "puntoPedido" },
    { title: "Stock", dataKey: "stock" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [articulo, guardarArticulo] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleAjustar, setVisibleAjustar] = useState(false);
  const [movimiento, guardarMovimiento] = useState(null);
  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    statusAcceso2,
    articulos,
    obtenerArticulos,
    obtenerAcceso,
    obtenerAcceso2,
    agregarArticulo,
    actualizarArticulo,
    agregarMovimientoAjuste,
  } = serviciosContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Articulos" });
    }
  }, [state]);

  useEffect(() => {
    obtenerArticulos({ todos: true });
  }, []);

  const editClick = (e) => {
    if (selectedRow) {
      guardarArticulo(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un artículo.",
        life: 3000,
      });
    }
  };

  const ajustarClick = (e) => {
    if (selectedRow) {
      guardarArticulo(selectedRow);
      setVisibleAjustar(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Ajustar",
        detail: "Debe seleccionar un artículo.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //props.history.push(`/articulo/0`);
    setVisible(true);
    setSelectedRow(null);
    guardarArticulo({
      habilitado: true,
    });
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHideAjustar = (e) => {
    setVisibleAjustar(false);
  };

  const aceptarAjustarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !articulo ||
      articulo.nuevoStock === undefined ||
      articulo.nuevoStock === null ||
      articulo.nuevoStock === "" ||
      articulo.nuevoStock <= 0
    ) {
      mensajeAlerta(
        "Articulos",
        "Nuevo Stock es necesario y no puede ser cero o negativo !.",
        "error"
      );
      return;
    }

    if (
      !articulo ||
      articulo.nuevoStock === undefined ||
      articulo.nuevoStock === null ||
      articulo.nuevoStock === "" ||
      articulo.nuevoStock === articulo.stock
    ) {
      mensajeAlerta(
        "Artículos",
        "Nuevo Stock no puede ser igual al stock actual !.",
        "error"
      );
      return;
    }

    // Llamar al context
    if (selectedRow) {
      agregarMovimientoAjuste({ movimiento, articulo });
    } else {
      //agregarArticulo({ articulo });
    }
    onHideAjustar();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerArticulos({ todos: true });
    }, 2000);
    //exitClick();
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !articulo ||
      articulo.descripcion === undefined ||
      articulo.descripcion === null ||
      articulo.descripcion.trim() === ""
    ) {
      mensajeAlerta("Artículos", "La descripción es necesaria !.", "error");
      return;
    }

    if (
      !articulo ||
      articulo.puntoPedido === undefined ||
      articulo.puntoPedido === null ||
      articulo.puntoPedido === "" ||
      articulo.puntoPedido === 0
    ) {
      mensajeAlerta("Artículos", "El punto de pedido es necesario !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarArticulo({ articulo });
    } else {
      agregarArticulo({ articulo });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerArticulos({ todos: true });
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Artículos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const descripcionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Descripción</span>
        {rowData.descripcion}
      </Fragment>
    );
  };

  const stockTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Stock</span>
        {rowData.stock}
      </Fragment>
    );
  };

  const unidadTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Unid. de medida</span>
        {rowData.unidad}
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, articulos);
        doc.save("articulos.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(articulos);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "articulos");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Artículos"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Artículo"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderFooterAjustar = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarAjustarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideAjustar}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(articulosNaturales);
  //console.log(state);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {articulos ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
               style={{
                  align: "center",
                  margin: "1em",
                  marginTop:
                    isMobile && !isPortrait
                      ? "22vw"
                      : isMobile && isPortrait
                      ? "55vw"
                      : "0vw",
                }}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-arrows-v"
                  tooltip="Ajustar"
                  className="p-button-rounded p-button-secondary"
                  style={{ marginLeft: "1em" }}
                  onClick={ajustarClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={articulos}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="descripcion"
                        header="Descripción"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="stock"
                        header="Stock"
                        //body={stockTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="puntoPedido"
                        header="Punto de Pedido"
                        //body={unidadTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="habilitado"
                        header="Habilitado"
                        body={habilitadoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  {selectedRow ? (
                    <Grid item xs={12} md={2}>
                      <TextField
                        value={articulo ? articulo.id : null}
                        required
                        variant="standard"
                        id="id"
                        label="ID"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={10}>
                    <TextField
                      value={articulo ? articulo.descripcion : null}
                      required
                      variant="standard"
                      id="descripcion"
                      label="Descripción"
                      fullWidth
                      onChange={(e) =>
                        guardarArticulo({
                          ...articulo,
                          descripcion: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={articulo ? articulo.puntoPedido : null}
                      required
                      variant="standard"
                      id="puntoPedido"
                      label="Punto de Pedido"
                      type="number"
                      fullWidth
                      onChange={(e) =>
                        guardarArticulo({
                          ...articulo,
                          puntoPedido: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={articulo ? articulo.habilitado : null}
                          style={{ marginLeft: "1vw" }}
                          onChange={(e) =>
                            guardarArticulo({
                              ...articulo,
                              habilitado: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Habilitado"
                      labelPlacement="start"
                      style={{ paddingLeft: "2vw", paddingTop: "1.6vw" }}
                    />
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeader()}
                visible={visibleAjustar}
                closable={false}
                footer={renderFooterAjustar()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  <Grid item xs={12} md={10}>
                    <TextField
                      value={articulo ? articulo.descripcion : null}
                      required
                      variant="standard"
                      id="descripcion"
                      label="Descripción"
                      fullWidth
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <TextField
                      value={articulo ? articulo.stock : null}
                      required
                      variant="standard"
                      type="number"
                      id="cantidad"
                      label={
                        articulo && articulo.unidad
                          ? "Stock actual en " + articulo.unidad
                          : "Stock actual"
                      }
                      fullWidth
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <TextField
                      value={articulo ? articulo.nuevoStock : null}
                      required
                      variant="standard"
                      type="number"
                      id="cantidad"
                      label={
                        articulo && articulo.unidad
                          ? "Nuevo stock en " + articulo.unidad
                          : "Nuevo stock"
                      }
                      fullWidth
                      onChange={(e) => {
                        if (Number(e.target.value) > Number(articulo.stock)) {
                          guardarMovimiento({
                            articuloId: articulo.id,
                            tipoMovimiento: "E",
                            detalle: "AJUSTE DE STOCK",
                            cantidad:
                              Number(e.target.value) - Number(articulo.stock),
                          });
                        }
                        if (Number(e.target.value) < Number(articulo.stock)) {
                          guardarMovimiento({
                            articuloId: articulo.id,
                            tipoMovimiento: "S",
                            detalle: "AJUSTE DE STOCK",
                            cantidad:
                              Number(articulo.stock) - Number(e.target.value),
                          });
                        }
                        guardarArticulo({
                          ...articulo,
                          nuevoStock: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
