import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { jsPDF } from "jspdf";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function Pacientes(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Domicilio", dataKey: "domicilio" },
    { title: "Teléfono", dataKey: "telefono" },
    { title: "Estado", dataKey: "estado" },
  ];

  const motivosIngresos = [{ label: "ALTA MEDICA/REINGRESA", id: 1 }];
  const motivosEgresos = [
    { label: "INTERNACION", id: 1 },
    { label: "ALTA VOLUNTARIA", id: 2 },
    { label: "ALTA TRANSITORIA", id: 3 },
    { label: "OBITO", id: 4 },
  ];

  const fechasPagos = [
    { label: "01 a 15", id: 1 },
    { label: "16 a 31", id: 2 },
  ];

  const [paciente, guardarPaciente] = useState(null);
  const [visible, setVisible] = useState(false);
  const [modalIngresosEgresos, setModalIngresosEgresos] = useState(false);
  const toast = useRef(null);
  const [exit, setExit] = useState(false);
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    statusAcceso,
    statusAcceso2,
    pacientes,
    localidades,
    diagnosticos,
    medicaciones,
    planes,
    medicacionesPacientes,
    diagnosticosPacientes,
    ingresosEgresos,
    obrasSociales,
    obrasSocialesMedicas,
    clinicas,
    patologias,
    vinculos,
    coseguros,
    panales,
    descartables,
    establecimientos,
    geriatricoElegido,
    obtenerPacientes,
    obtenerAcceso,
    obtenerAcceso2,
    agregarPaciente,
    actualizarPaciente,
    setGeriatricoElegido,
    obtenerLocalidadesPacientes,
    obtenerObrasSocialesPacientes,
    obtenerObrasSocialesMedicasPacientes,
    obtenerDiagnosticosPacientes,
    obtenerMedicacionesPacientes,
    obtenerMedicacionesPacientesIndividual,
    obtenerDiagnosticosPacientesIndividual,
    obtenerClinicasPacientes,
    obtenerPatologiasPacientes,
    obtenerVinculosPacientes,
    obtenerCosegurosPacientes,
    obtenerPanalesPacientes,
    obtenerDescartablesPacientes,
    actualizarPacienteIngresoEgreso,
    obtenerIngresosEgresos,
    obtenerPlanes,
    guardarMedicacionesPacientes,
    guardarDiagnosticosPacientes,
    obtenerEstablecimientos,
  } = serviciosContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Pacientes",
      });
    }
  }, [state]);

  useEffect(() => {
    setGeriatricoElegido({
      id: Number(localStorage.getItem("geriatricoElegidoId")),
      nombre: localStorage.getItem("geriatricoElegidoNombre"),
    });
    obtenerPacientes({
      geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
    });
    obtenerLocalidadesPacientes({ todos: false });
    obtenerObrasSocialesPacientes({ todos: false });
    obtenerObrasSocialesMedicasPacientes({ todos: false });
    obtenerClinicasPacientes({ todos: false });
    obtenerDiagnosticosPacientes({ todos: false });
    obtenerMedicacionesPacientes({ todos: false });
    obtenerVinculosPacientes({ todos: false });
    obtenerCosegurosPacientes({ todos: false });
    obtenerPanalesPacientes({ todos: false });
    obtenerDescartablesPacientes({ todos: false });
    obtenerEstablecimientos({ todos: false });
    obtenerPlanes();

    //obtenerDiagnosticosPacientes({ todos: false });
  }, []);

  const editClick = (e) => {
    if (selectedRow) {
      //obtenerIngresosEgresos({ pacienteId: selectedRow.id });
      //obtenerMedicacionesPacientesIndividual({ pacienteId: selectedRow.id });
      //obtenerDiagnosticosPacientesIndividual({ pacienteId: selectedRow.id });
      guardarPaciente(selectedRow);
      setTimeout(() => {
        setVisible(true);
      }, 500);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  const imprimir = (e) => {
    const doc = new jsPDF();

    let row = 10;
    doc.setFontSize(11);
    doc.text("FICHA RESIDENTE " + paciente.nombre, 50, row);
    doc.setFontSize(10);
    row = 20;
    let titulo = "Datos Personales";
    doc.text(titulo, 10, row);
    let textWidth = doc.getTextWidth(titulo);
    doc.line(10, row + 2, 10 + textWidth + 1, row + 2);
    doc.setFontSize(9);
    row = 30;
    doc.text(
      "Domicilio: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.domicilio !== undefined &&
        paciente.domicilio !== null
          ? paciente.domicilio
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Localidad: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.localidadId !== undefined &&
        paciente.localidadId !== null
          ? localidades.find((item) => item.id === paciente.localidadId).label
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    doc.text(
      "C.P.: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.cp !== undefined &&
        paciente.cp !== null
          ? paciente.cp
          : ""),
      150,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Documento: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.numeroDocumento !== undefined &&
        paciente.numeroDocumento !== null
          ? paciente.numeroDocumento
          : ""),

      170,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Nacimiento: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.fechaNacimiento !== undefined &&
        paciente.fechaNacimiento !== null
          ? formatDate(paciente.fechaNacimiento)
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Lugar de Nac.: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.lugarNacimiento !== undefined &&
        paciente.lugarNacimiento !== null
          ? paciente.lugarNacimiento
          : ""),

      50,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Estado Civil: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.estadoCivil !== undefined &&
        paciente.estadoCivil !== null
          ? paciente.estadoCivil
          : ""),

      120,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefono !== undefined &&
        paciente.telefono !== null
          ? paciente.telefono
          : ""),

      160,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "O. Social: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.obraSocialId !== undefined &&
        paciente.obraSocialId !== null
          ? obrasSocialesMedicas.find(
              (item) => item.id === paciente.obraSocialId
            ).label
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Nº. O. Social: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.numeroObraSocial !== undefined &&
        paciente.numeroObraSocial !== null
          ? paciente.numeroObraSocial
          : ""),

      50,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Clínica Internación: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.clinicaId !== undefined &&
        paciente.clinicaId !== null &&
        paciente.clinicaId !== 0
          ? clinicas.find((item) => item.id === paciente.clinicaId).label
          : ""),
      110,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Médico Cabecera: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.medicoCabecera !== undefined &&
        paciente.medicoCabecera !== null &&
        paciente.medicoCabecera !== 0
          ? paciente.medicoCabecera
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono Urgencias Médicas: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoUrgencias !== undefined &&
        paciente.telefonoUrgencias !== null
          ? paciente.telefonoUrgencias
          : ""),
      110,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    /*
    for (var i = 0; i < diagnosticosPacientes.length; i++) {
      doc.text("•" + diagnosticosPacientes[i].label, 10, row, {
        maxWidth: 180,
        align: "left",
      });
      let w = doc.getTextDimensions(diagnosticosPacientes[i].label);
      if (w.w > 180) {
        row = row + 10;
      } else {
        row = row + 5;
      }
    }
    */

    let textDiag = "";
    for (var i = 0; i < diagnosticosPacientes.length; i++) {
      textDiag = textDiag + "•" + diagnosticosPacientes[i].label + "  ";
    }

    doc.text("Diagnóstico de Base: " + textDiag, 10, row, {
      maxWidth: 180,
      align: "justify",
    });

    let w = doc.getTextDimensions(textDiag);
    if (w.w > 180) {
      row = row + 12;
    } else {
      row = row + 5;
    }

    doc.text(
      "Fecha Ingreso: " + formatDate(ingresosEgresos[0].fecha),
      10,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    if (paciente && paciente.motivoIngresoEgreso !== "PRIMER INGRESO") {
      doc.text(
        "Fecha de Egreso/Reingreso: " + formatDate(paciente.fechaIngresoEgreso),
        10,
        row,
        "left",
        "middle"
      );
    }

    if (paciente.activo) {
      doc.text(
        "Motivo de Ingreso: " +
          (paciente !== undefined &&
          paciente !== null &&
          paciente.motivoIngresoEgreso !== undefined &&
          paciente.motivoIngresoEgreso !== null
            ? paciente.motivoIngresoEgreso
            : ""),
        90,
        row,
        "left",
        "middle"
      );
    } else {
      doc.text(
        "Motivo de Egreso: " +
          (paciente !== undefined &&
          paciente !== null &&
          paciente.motivoIngresoEgreso !== undefined &&
          paciente.motivoIngresoEgreso !== null
            ? paciente.motivoIngresoEgreso
            : ""),
        90,
        row,
        "left",
        "middle"
      );
    }

    doc.text(
      "Activo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.activo !== undefined &&
        paciente.activo !== null
          ? paciente.activo
            ? "Si"
            : "No"
          : ""),
      180,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Medicación que recibe: " +
        (medicacionesPacientes
          ? medicacionesPacientes.map(
              (medicacion) => "•" + medicacion.label + "  "
            )
          : ""),

      10,
      row,
      {
        maxWidth: 180,
        align: "justify",
      }
    );

    doc.setFontSize(10);
    row = row + 10;
    titulo = "Datos del Responsable";
    doc.text(titulo, 10, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(10, row + 2, 10 + textWidth + 1, row + 2);
    doc.setFontSize(9);
    row = row + 10;

    doc.text(
      "Apellido y Nombre: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.nombreRepresentante !== undefined &&
        paciente.nombreRepresentante !== null
          ? paciente.nombreRepresentante
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Domicilio: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.domicilioRepresentante !== undefined &&
        paciente.domicilioRepresentante !== null
          ? paciente.domicilioRepresentante
          : ""),
      110,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Vínculo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.vinculoId !== undefined &&
        paciente.vinculoId !== null
          ? vinculos.find((item) => item.id === paciente.vinculoId).label
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Localidad: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.localidadRepresentanteId !== undefined &&
        paciente.localidadRepresentanteId !== null
          ? localidades.find(
              (item) => item.id === paciente.localidadRepresentanteId
            ).label
          : ""),
      70,
      row,
      "left",
      "middle"
    );

    doc.text(
      "C.P.: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.cpRepresentante !== undefined &&
        paciente.cpRepresentante !== null
          ? paciente.cpRepresentante
          : ""),
      130,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoRepresentante !== undefined &&
        paciente.telefonoRepresentante !== null
          ? paciente.telefonoRepresentante
          : ""),

      160,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    /*
    doc.text(
      "Nacimiento: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.fechaNacimientoRepresentante !== undefined &&
        paciente.fechaNacimientoRepresentante !== null &&
        paciente.fechaNacimientoRepresentante !== "0000-00-00"
          ? formatDate(paciente.fechaNacimientoRepresentante)
          : ""),
      10,
      row,
      "left",
      "middle"
    );
    */

    doc.text(
      "Documento: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.numeroDocumentoRepresentante !== undefined &&
        paciente.numeroDocumentoRepresentante !== null
          ? paciente.numeroDocumentoRepresentante
          : ""),

      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono Comunicaciones: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoComunicaciones !== undefined &&
        paciente.telefonoComunicaciones !== null
          ? paciente.telefonoComunicaciones
          : ""),
      70,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Email: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.email !== undefined &&
        paciente.email !== null
          ? paciente.email
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    /*
    doc.setFontSize(10);
    row = row + 10;
    titulo = "Nivel Socio - Económico";
    doc.text(titulo, 10, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(10, row + 2, 10 + textWidth + 1, row + 2);
    doc.setFontSize(9);
    row = row + 10;

    doc.text(
      "Escolaridad: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.escolaridad !== undefined &&
        paciente.escolaridad !== null
          ? paciente.escolaridad
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Completo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.completo !== undefined &&
        paciente.completo !== null
          ? paciente.completo
          : ""),
      50,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Situación Laboral: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.situacionLaboral !== undefined &&
        paciente.situacionLaboral !== null
          ? paciente.situacionLaboral
          : ""),
      80,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Desempleño Laboral: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.desempenoLaboral !== undefined &&
        paciente.desempenoLaboral !== null
          ? paciente.desempenoLaboral
          : ""),
      160,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Profesión, oficio o actividad: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.profesion !== undefined &&
        paciente.profesion !== null
          ? paciente.profesion
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.setFontSize(10);
    row = row + 10;
    titulo = "Dimensión Relacional y Convivencial";
    doc.text(titulo, 10, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(10, row + 2, 10 + textWidth + 1, row + 2);
    doc.setFontSize(9);
    row = row + 10;

    doc.text(
      "Red de contención social: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.contencion !== undefined &&
        paciente.contencion !== null
          ? paciente.contencion
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Cual: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.contencionCual !== undefined &&
        paciente.contencionCual !== null
          ? paciente.contencionCual
          : ""),
      70,
      row,
      "left",
      "middle"
    );

    */

    doc.setFontSize(10);
    row = row + 10;
    titulo = "Datos de Grupo Familiar";
    doc.text(titulo, 10, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(10, row + 2, 10 + textWidth + 1, row + 2);
    doc.setFontSize(9);
    row = row + 10;

    doc.text(
      "1- Apellido y Nombre: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.nombreGrupo1 !== undefined &&
        paciente.nombreGrupo1 !== null
          ? paciente.nombreGrupo1
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Domicilio: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.domicilioGrupo1 !== undefined &&
        paciente.domicilioGrupo1 !== null
          ? paciente.domicilioGrupo1
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "   Vínculo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.vinculoIdGrupo1 !== undefined &&
        paciente.vinculoIdGrupo1 !== null &&
        paciente.vinculoIdGrupo1 !== 0
          ? vinculos.find((item) => item.id === paciente.vinculoIdGrupo1).label
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Ocupación: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.ocupacionGrupo1 !== undefined &&
        paciente.ocupacionGrupo1 !== null
          ? paciente.ocupacionGrupo1
          : ""),
      80,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoGrupo1 !== undefined &&
        paciente.telefonoGrupo1 !== null
          ? paciente.telefonoGrupo1
          : ""),
      160,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "2- Apellido y Nombre: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.nombreGrupo2 !== undefined &&
        paciente.nombreGrupo2 !== null
          ? paciente.nombreGrupo2
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Domicilio: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.domicilioGrupo2 !== undefined &&
        paciente.domicilioGrupo2 !== null
          ? paciente.domicilioGrupo2
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "   Vínculo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.vinculoIdGrupo2 !== undefined &&
        paciente.vinculoIdGrupo2 !== null
          ? vinculos.find((item) => item.id === paciente.vinculoIdGrupo2).label
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Ocupación: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.ocupacionGrupo2 !== undefined &&
        paciente.ocupacionGrupo2 !== null
          ? paciente.ocupacionGrupo2
          : ""),
      80,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoGrupo2 !== undefined &&
        paciente.telefonoGrupo2 !== null
          ? paciente.telefonoGrupo2
          : ""),
      160,
      row,
      "left",
      "middle"
    );

    /*

    row = row + 5;

    doc.text(
      "3- Apellido y Nombre: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.nombreGrupo3 !== undefined &&
        paciente.nombreGrupo3 !== null
          ? paciente.nombreGrupo3
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Domicilio: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.domicilioGrupo3 !== undefined &&
        paciente.domicilioGrupo3 !== null
          ? paciente.domicilioGrupo3
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "   Vínculo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.vinculoIdGrupo3 !== undefined &&
        paciente.vinculoIdGrupo3 !== null
          ? vinculos.find((item) => item.id === paciente.vinculoIdGrupo3).label
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Ocupación: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.ocupacionGrupo3 !== undefined &&
        paciente.ocupacionGrupo3 !== null
          ? paciente.ocupacionGrupo3
          : ""),
      80,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoGrupo3 !== undefined &&
        paciente.telefonoGrupo3 !== null
          ? paciente.telefonoGrupo3
          : ""),
      160,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "4- Apellido y Nombre: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.nombreGrupo4 !== undefined &&
        paciente.nombreGrupo4 !== null
          ? paciente.nombreGrupo4
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Domicilio: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.domicilioGrupo4 !== undefined &&
        paciente.domicilioGrupo4 !== null
          ? paciente.domicilioGrupo4
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "   Vínculo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.vinculoIdGrupo4 !== undefined &&
        paciente.vinculoIdGrupo4 !== null
          ? vinculos.find((item) => item.id === paciente.vinculoIdGrupo4).label
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Ocupación: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.ocupacionGrupo4 !== undefined &&
        paciente.ocupacionGrupo4 !== null
          ? paciente.ocupacionGrupo4
          : ""),
      80,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoGrupo4 !== undefined &&
        paciente.telefonoGrupo4 !== null
          ? paciente.telefonoGrupo4
          : ""),
      160,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "5- Apellido y Nombre: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.nombreGrupo5 !== undefined &&
        paciente.nombreGrupo5 !== null
          ? paciente.nombreGrupo5
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Domicilio: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.domicilioGrupo5 !== undefined &&
        paciente.domicilioGrupo5 !== null
          ? paciente.domicilioGrupo5
          : ""),
      100,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "   Vínculo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.vinculoIdGrupo5 !== undefined &&
        paciente.vinculoIdGrupo5 !== null
          ? vinculos.find((item) => item.id === paciente.vinculoIdGrupo5).label
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Ocupación: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.ocupacionGrupo5 !== undefined &&
        paciente.ocupacionGrupo5 !== null
          ? paciente.ocupacionGrupo5
          : ""),
      80,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Teléfono: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.telefonoGrupo5 !== undefined &&
        paciente.telefonoGrupo5 !== null
          ? paciente.telefonoGrupo5
          : ""),
      160,
      row,
      "left",
      "middle"
    );

    */

    doc.setFontSize(10);
    row = row + 10;
    titulo = "Observaciones";
    doc.text(titulo, 10, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(10, row + 2, 10 + textWidth + 1, row + 2);
    doc.setFontSize(9);
    row = row + 10;

    doc.text(
      paciente !== undefined &&
        paciente !== null &&
        paciente.observaciones !== undefined &&
        paciente.observaciones !== null
        ? paciente.observaciones
        : "",
      10,
      row,
      {
        maxWidth: 200,
        align: "left",
      }
    );

    doc.setFontSize(10);
    row = row + 10;
    titulo = "Datos de Contacto";
    doc.text(titulo, 10, row);
    textWidth = doc.getTextWidth(titulo);
    doc.line(10, row + 2, 10 + textWidth + 1, row + 2);
    doc.setFontSize(9);
    row = row + 10;

    doc.text(
      "Email Alternativo: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.emailAlternativo !== undefined &&
        paciente.emailAlternativo !== null
          ? paciente.emailAlternativo
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "WhatsApp: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.whatsApp !== undefined &&
        paciente.whatsApp !== null
          ? paciente.whatsApp
          : ""),
      140,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "O. Social Contrato: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.obraSocialContratoId !== undefined &&
        paciente.obraSocialContratoId !== null
          ? obrasSociales.find(
              (item) => item.id === paciente.obraSocialContratoId
            ).label
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Arancel Mensual: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.arancel !== undefined &&
        paciente.arancel !== null
          ? paciente.arancel
          : ""),
      80,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Coseguro: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.coseguroId !== undefined &&
        paciente.coseguroId !== null
          ? coseguros.find((item) => item.id === paciente.coseguroId).label +
            " (" +
            coseguros.find((item) => item.id === paciente.coseguroId).valor +
            " )"
          : ""),
      140,
      row,
      "left",
      "middle"
    );

    row = row + 5;

    doc.text(
      "Pañales: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.panalId !== undefined &&
        paciente.panalId !== null
          ? panales.find((item) => item.id === paciente.panalId).label +
            " (" +
            panales.find((item) => item.id === paciente.panalId).valor +
            " )"
          : ""),
      10,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Descartables: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.descartableId !== undefined &&
        paciente.descartableId !== null
          ? descartables.find((item) => item.id === paciente.descartableId)
              .label +
            " (" +
            descartables.find((item) => item.id === paciente.descartableId)
              .valor +
            " )"
          : ""),
      80,
      row,
      "left",
      "middle"
    );

    doc.text(
      "Fecha Pago: " +
        (paciente !== undefined &&
        paciente !== null &&
        paciente.fechaPago !== undefined &&
        paciente.fechaPago !== null
          ? paciente.fechaPago === 1
            ? "01 a 15"
            : "16a 31"
          : ""),
      140,
      row,
      "left",
      "middle"
    );

    window.open(doc.output("bloburl"));
    /*
        doc.save(
          "FichaCaidas_" +
            selectedRowPaciente.nombre +
            "export_" +
            new Date().toLocaleDateString() +
            ".pdf"
        );
        */
  };

  const addClick = (e) => {
    //props.history.push(`/paciente/0`);
    setVisible(true);
    setSelectedRow(null);
    guardarPaciente({
      habilitado: true,
    });
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHideIngresosEgresos = (e) => {
    setModalIngresosEgresos(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();

    //Validar
    if (
      !paciente ||
      paciente.nombre === undefined ||
      paciente.nombre === null ||
      paciente.nombre.trim() === ""
    ) {
      mensajeAlerta("Residentes", "El nombre es necesario !.", "error");
      return;
    }

    if (
      !paciente ||
      paciente.domicilio === undefined ||
      paciente.domicilio === null ||
      paciente.domicilio.trim() === ""
    ) {
      mensajeAlerta("Residentes", "El domicilio es necesario !.", "error");
      return;
    }

    if (
      !paciente ||
      paciente.localidadId === undefined ||
      paciente.localidadId === null ||
      paciente.localidadId === 0
    ) {
      mensajeAlerta("Residentes", "La localidad es necesaria !.", "error");
      return;
    }

    if (
      !paciente ||
      paciente.numeroDocumento === undefined ||
      paciente.numeroDocumento === null ||
      paciente.numeroDocumento.trim() === ""
    ) {
      mensajeAlerta(
        "Residentes",
        "El Nº de documento es necesario !.",
        "error"
      );
      return;
    }

    if (
      !paciente ||
      paciente.fechaNacimiento === undefined ||
      paciente.fechaNacimiento === null ||
      paciente.fechaNacimiento.trim() === ""
    ) {
      mensajeAlerta(
        "Residentes",
        "La fecha de nacimiento es necesaria !.",
        "error"
      );
      return;
    }

    {
      /*
    if (
      !paciente ||
      paciente.telefono === undefined ||
      paciente.telefono === null ||
      paciente.telefono.trim() === ""
    ) {
      mensajeAlerta("Residentes", "El teléfono es necesario !.", "error");
      return;
    }
  */
    }

    if (
      !paciente ||
      paciente.obraSocialId === undefined ||
      paciente.obraSocialId === null ||
      paciente.obraSocialId === 0
    ) {
      mensajeAlerta(
        "Residentes",
        "Obra Social Médica es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !paciente ||
      paciente.fechaIngresoEgreso === undefined ||
      paciente.fechaIngresoEgreso === null ||
      paciente.fechaIngresoEgreso.trim() === ""
    ) {
      mensajeAlerta(
        "Residentes",
        "La fecha de ingreso es necesaria !.",
        "error"
      );
      return;
    }

    if (
      selectedRow &&
      paciente &&
      (paciente.geriatricoId === undefined ||
        paciente.geriatricoId === null ||
        paciente.geriatricoId === "")
    ) {
      mensajeAlerta("Residentes", "La residencia es necesaria !.", "error");
      return;
    }

    //Representante

    if (
      !paciente ||
      paciente.nombreRepresentante === undefined ||
      paciente.nombreRepresentante === null ||
      paciente.nombreRepresentante.trim() === ""
    ) {
      mensajeAlerta(
        "Residentes",
        "El nombre del representante es necesario !.",
        "error"
      );
      return;
    }

    if (
      !paciente ||
      paciente.domicilioRepresentante === undefined ||
      paciente.domicilioRepresentante === null ||
      paciente.domicilioRepresentante.trim() === ""
    ) {
      mensajeAlerta(
        "Residentes",
        "El domicilio del representante es necesario !.",
        "error"
      );
      return;
    }

    if (
      !paciente ||
      paciente.localidadRepresentanteId === undefined ||
      paciente.localidadRepresentanteId === null ||
      paciente.localidadRepresentanteId === 0
    ) {
      mensajeAlerta(
        "Residentes",
        "La localidad del representante es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !paciente ||
      paciente.vinculoId === undefined ||
      paciente.vinculoId === null ||
      paciente.vinculoId === 0
    ) {
      mensajeAlerta(
        "Residentes",
        "El vínculo del representante es necesario !.",
        "error"
      );
      return;
    }

    if (
      !paciente ||
      paciente.telefonoRepresentante === undefined ||
      paciente.telefonoRepresentante === null ||
      paciente.telefonoRepresentante.trim() === ""
    ) {
      mensajeAlerta(
        "Residentes",
        "El teléfono del representante es necesario !.",
        "error"
      );
      return;
    }

    if (
      !paciente ||
      paciente.numeroDocumentoRepresentante === undefined ||
      paciente.numeroDocumentoRepresentante === null ||
      paciente.numeroDocumentoRepresentante.trim() === ""
    ) {
      mensajeAlerta(
        "Residentes",
        "El Nº de documento del representante es necesario !.",
        "error"
      );
      return;
    }

    if (
      !paciente ||
      paciente.email === undefined ||
      paciente.email === null ||
      paciente.email.trim() === ""
    ) {
      mensajeAlerta("Residentes", "El email es necesario !.", "error");
      return;
    }

    if (
      !paciente ||
      paciente.telefonoComunicaciones === undefined ||
      paciente.telefonoComunicaciones === null ||
      paciente.telefonoComunicaciones.trim() === ""
    ) {
      mensajeAlerta(
        "Residentes",
        "El teléfono de comunicaciones es necesario !.",
        "error"
      );
      return;
    }

    if (
      !paciente ||
      paciente.obraSocialContratoId === undefined ||
      paciente.obraSocialContratoId === null ||
      paciente.obraSocialContratoId === 0
    ) {
      mensajeAlerta(
        "Residentes",
        "Obra Social Contrato es necesaria !.",
        "error"
      );
      return;
    }

    if (
      paciente &&
      paciente.obraSocialContratoId !== undefined &&
      paciente.obraSocialContratoId !== null &&
      paciente.obraSocialContratoId === 2
    ) {
      if (
        !paciente ||
        paciente.arancel === undefined ||
        paciente.arancel === null ||
        paciente.arancel === ""
      ) {
        mensajeAlerta("Residentes", "Arancel mensual es necesario !.", "error");
        return;
      }
    }

    if (
      !paciente ||
      paciente.fechaPago === undefined ||
      paciente.fechaPago === null ||
      paciente.fechaPago === 0
    ) {
      mensajeAlerta("Residentes", "Fecha de Pago es necesaria !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarPaciente({
        paciente,
        medicacionesPacientes,
        diagnosticosPacientes,
        ingresosEgresos,
      });
    } else {
      agregarPaciente({
        paciente,
        geriatricoId: geriatricoElegido.id,
        medicacionesPacientes,
        diagnosticosPacientes,
      });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerPacientes({
        geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
      });
    }, 2000);
    //exitClick();
  };

  const aceptarClickIngresosEgresos = (e) => {
    e.preventDefault();

    let ultimaFechaArray = ingresosEgresos.length - 1;
    let ultimaFecha = ingresosEgresos[ultimaFechaArray].fecha;

    let newDate = new Date(ultimaFecha + "T00:00:00");

    let ingresoEgresoFecha = new Date(paciente.fIngresoEgreso + "T00:00:00");

    //Validar
    if (
      !paciente ||
      paciente.fIngresoEgreso === undefined ||
      paciente.fIngresoEgreso === null ||
      paciente.fIngresoEgreso.trim() === ""
    ) {
      mensajeAlerta(
        "Residentes",
        "La fecha de ingreso/egreso es necesaria !.",
        "error"
      );
      return;
    }

    if (new Date(ingresoEgresoFecha) < newDate) {
      mensajeAlerta(
        "Residentes",
        "La fecha de ingreso/egreso es incorrecta !.",
        "error"
      );
      return;
    }

    if (
      !paciente ||
      paciente.mIngresoEgreso === undefined ||
      paciente.mIngresoEgreso === null ||
      paciente.mIngresoEgreso.trim() === ""
    ) {
      mensajeAlerta(
        "Residentes",
        "El motivo de ingreso/egreso es necesario !.",
        "error"
      );
      return;
    }

    let activo;

    if (selectedRow.activo) {
      activo = false;
    } else {
      activo = true;
    }

    let fechaActual = new Date(new Date());
    let diff = fechaActual - ingresoEgresoFecha;

    if (
      paciente.mIngresoEgreso === "INTERNACION" &&
      diff / (1000 * 60 * 60 * 24) < 20
    ) {
      activo = true;
    }

    if (paciente.mIngresoEgreso === "ALTA MEDICA/REINGRESA") {
      activo = true;
    }

    if (paciente.mIngresoEgreso === "OBITO") {
      activo = false;
    }

    actualizarPacienteIngresoEgreso({
      paciente,
      pacienteId: selectedRow.id,
      pacienteNombre: selectedRow.nombre,
      activo: activo,
    });
    onHideIngresosEgresos();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerPacientes({
        geriatricoId: Number(localStorage.getItem("geriatricoElegidoId")),
      });
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const ingresosYEgresos = () => {
    if (selectedRow) {
      obtenerIngresosEgresos({ pacienteId: selectedRow.id });
      guardarPaciente(selectedRow);
      setModalIngresosEgresos(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un residente.",
        life: 3000,
      });
    }
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const documentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const ingresoEgresoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Ingreso/Egreso</span>
        {formatDate(rowData.fechaIngresoEgreso)}
      </Fragment>
    );
  };

  const motivoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Motivo</span>
        {rowData.motivoIngresoEgreso}
      </Fragment>
    );
  };

  const fTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDate(rowData.fecha)}
      </Fragment>
    );
  };

  const mTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Motivo</span>
        {rowData.motivo}
      </Fragment>
    );
  };

  const activoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Activo</span>
        <Checkbox checked={rowData.activo}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, pacientes);
        doc.save("residentes.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(pacientes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "residentes");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Residentes"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Residente"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
        {selectedRow !== null ? (
          <ButtonMui
            onClick={imprimir}
            color="error"
            type="button"
            fullWidth
            variant="contained"
            startIcon={<CloseIcon />}
            autoFocus
          >
            Imprimir
          </ButtonMui>
        ) : null}
      </div>
    );
  };

  const renderHeaderIngresosEgresos = () => {
    return (
      <div>
        {selectedRow ? (
          <Tag value={"Ingresos y Egresos de " + selectedRow.nombre}></Tag>
        ) : null}
      </div>
    );
  };

  const renderFooterIngresosEgresos = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickIngresosEgresos}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideIngresosEgresos}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  function formatDate(dateString) {
    var allDate = dateString.split("-");
    var newDate = [allDate[2], allDate[1], allDate[0]].join("/");
    return newDate;
  }

  //console.log(isPortrait);
  //console.log(establecimientos);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {pacientes ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  align: "center",
                  margin: "1em",
                  marginTop:
                    isMobile && !isPortrait
                      ? "22vw"
                      : isMobile && isPortrait
                      ? "55vw"
                      : "0vw",
                }}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <Button
                  icon="pi pi-sort-alt"
                  tooltip="Ingresos/Egresos"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={ingresosYEgresos}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={pacientes}
                      selection={selectedRow}
                      onSelectionChange={(e) => {
                        setSelectedRow(e.value);
                        obtenerIngresosEgresos({ pacienteId: e.value.id });
                        obtenerMedicacionesPacientesIndividual({
                          pacienteId: e.value.id,
                        });
                        obtenerDiagnosticosPacientesIndividual({
                          pacienteId: e.value.id,
                        });
                      }}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50, 100, 500]}
                      responsiveLayout="scroll"

                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Apellido y Nombre"
                        body={nombreTemplate}
                        style={{ width: "30%" }}
                        sortable
                      ></Column>
                      <Column
                        field="domicilio"
                        header="Domicilio"
                        body={domicilioTemplate}
                        style={{ width: "30%" }}
                        sortable
                      ></Column>
                      <Column
                        field="numeroDocumento"
                        header="Documento"
                        body={documentoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="fechaIngresoEgreso"
                        header="Ingreso/Egreso"
                        body={ingresoEgresoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="motivoIngresoEgreso"
                        header="Motivo"
                        body={motivoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="activo"
                        header="Activo"
                        body={activoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "95vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline" }}
                    >
                      Datos Personales
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      value={paciente ? paciente.id : null}
                      required
                      disabled={!selectedRow}
                      id="id"
                      label="ID"
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      value={paciente ? paciente.nombre : null}
                      required
                      id="nombre"
                      label="Apellido y Nombre"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          nombre: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      value={paciente ? paciente.domicilio : null}
                      required
                      id="domicilio"
                      label="Domicilio"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          domicilio: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="localidad"
                      fullWidth
                      options={localidades}
                      disablePortal
                      required
                      //defaultValue={colores[idColorDefault]}

                      defaultValue={
                        paciente && paciente.localidadId !== undefined
                          ? localidades[
                              localidades.findIndex(
                                (item) => item.id === paciente.localidadId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Localidad"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            localidadId: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            localidadId: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={1} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.cp : null}
                      id="cp"
                      label="Cod. Postal"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          cp: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.numeroDocumento : null}
                      id="numeroDocumento"
                      label="Nº de Documento"
                      required
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          numeroDocumento: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={6} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      id="fechaNacimiento"
                      label="Fecha de Nacimiento"
                      type="date"
                      variant="standard"
                      requiered
                      fullWidth
                      value={paciente ? paciente.fechaNacimiento : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarPaciente({
                          ...paciente,
                          fechaNacimiento: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.lugarNacimiento : null}
                      id="lugarNacimiento"
                      label="Lugar de Nacimiento"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          lugarNacimiento: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.estadoCivil : null}
                      id="estadoCivil"
                      label="Estado Civil"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          estadoCivil: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.telefono : null}
                      id="telefono"
                      label="Teléfono"
                      fullWidth
                      required
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          telefono: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="obraSocial"
                      fullWidth
                      options={obrasSocialesMedicas}
                      disablePortal
                      defaultValue={
                        paciente && paciente.obraSocialId !== undefined
                          ? obrasSocialesMedicas[
                              obrasSocialesMedicas.findIndex(
                                (item) => item.id === paciente.obraSocialId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Obra Social Médica"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            obraSocialId: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            obraSocialId: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.numeroObraSocial : null}
                      id="numeroObraSocial"
                      label="Nº Obra Social"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          numeroObraSocial: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="plan"
                      fullWidth
                      options={
                        planes
                          ? planes.filter((element) => element.habilitado)
                          : null
                      }
                      disablePortal
                      getOptionLabel={(option) => option.nombre}
                      value={
                        paciente && paciente.planId
                          ? planes[
                              planes.findIndex(
                                (elem) => elem.id === paciente.planId
                              )
                            ]
                          : null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Plan *"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, values) => {
                        if (values) {
                          guardarPaciente({
                            ...paciente,
                            planId: values.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            planId: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="clinica"
                      fullWidth
                      options={clinicas}
                      disablePortal
                      defaultValue={
                        paciente && paciente.clinicaId !== undefined
                          ? clinicas[
                              clinicas.findIndex(
                                (item) => item.id === paciente.clinicaId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Clínica Internación"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            clinicaId: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            clinicaId: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.medicoCabecera : null}
                      id="medicoCabecera"
                      label="Médico de Cabecera"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          medicoCabecera: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.telefonoUrgencias : null}
                      id="telefonoUrgencias"
                      label="Teléfono Urgencias Médicas"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          telefonoUrgencias: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  {/*    
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="diagnostico"
                      fullWidth
                      options={diagnosticos}
                      disablePortal
                      defaultValue={
                        paciente && paciente.diagnosticoId !== undefined
                          ? diagnosticos[
                              diagnosticos.findIndex(
                                (item) => item.id === paciente.diagnosticoId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Diagnóstico de Base"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            diagnosticoId: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            diagnosticoId: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                    */}

                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="diagnostico"
                      fullWidth
                      multiple
                      options={diagnosticos}
                      disablePortal
                      defaultValue={
                        paciente &&
                        diagnosticosPacientes &&
                        diagnosticosPacientes !== null &&
                        diagnosticosPacientes.length > 0
                          ? diagnosticosPacientes
                          : []
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Diagnósticos"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, values) => {
                        if (values) {
                          guardarDiagnosticosPacientes(values);
                        } else {
                          guardarDiagnosticosPacientes(null);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="medicacion"
                      fullWidth
                      multiple
                      options={medicaciones}
                      disablePortal
                      defaultValue={
                        paciente &&
                        medicacionesPacientes &&
                        medicacionesPacientes !== null &&
                        medicacionesPacientes.length > 0
                          ? medicacionesPacientes
                          : []
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Medicación"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, values) => {
                        if (values) {
                          guardarMedicacionesPacientes(values);
                        } else {
                          guardarMedicacionesPacientes(null);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      id="fechaIngreso"
                      label="Fecha de Ingreso"
                      type="date"
                      fullWidth
                      variant="standard"
                      //disabled={selectedRow ? true : false}
                      defaultValue={
                        paciente && ingresosEgresos
                          ? ingresosEgresos[0].fecha
                          : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarPaciente({
                          ...paciente,
                          fechaIngresoEgreso: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  {paciente &&
                  paciente.motivoIngresoEgreso !== "PRIMER INGRESO" &&
                  selectedRow ? (
                    <Grid item xs={6} md={2} style={{ marginTop: "-1vw" }}>
                      <TextField
                        id="fechaIngresoEgreso"
                        label="Fecha de Egreso/Reingreso"
                        type="date"
                        fullWidth
                        variant="standard"
                        disabled={selectedRow ? true : false}
                        value={paciente ? paciente.fechaIngresoEgreso : null}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          guardarPaciente({
                            ...paciente,
                            fechaIngresoEgreso: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                  ) : null}

                  {selectedRow ? (
                    <Fragment>
                      <Grid item xs={6} md={2} style={{ marginTop: "-1vw" }}>
                        <TextField
                          id="motivoIngresoEgreso"
                          label={
                            selectedRow && selectedRow.activo
                              ? "Motivo de Ingreso/Reingreso"
                              : "Motivo de Egreso"
                          }
                          fullWidth
                          variant="standard"
                          disabled={selectedRow ? true : false}
                          value={paciente ? paciente.motivoIngresoEgreso : null}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarPaciente({
                              ...paciente,
                              motivoIngresoEgreso: e.target.value,
                            });
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        md={2}
                        style={{ paddingLeft: "1vw", marginTop: "0vw" }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              color="primary"
                              checked={paciente ? paciente.activo : null}
                              style={{ marginLeft: "1vw" }}
                              disabled={selectedRow ? true : false}
                              onChange={(e) =>
                                guardarPaciente({
                                  ...paciente,
                                  activo: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Activo"
                          labelPlacement="start"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          id="residencia"
                          fullWidth
                          options={establecimientos}
                          disablePortal
                          getOptionLabel={(option) => option.nombre}
                          defaultValue={
                            paciente &&
                            paciente.geriatricoId !== undefined &&
                            paciente.geriatricoId !== null
                              ? establecimientos[
                                  establecimientos.findIndex(
                                    (item) => item.id === paciente.geriatricoId
                                  )
                                ]
                              : null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              style={{ marginTop: "-1vw" }}
                              label="Residencia"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, values) => {
                            if (values) {
                              guardarPaciente({
                                ...paciente,
                                geriatricoId: values.id,
                                geriatrico: values.nombre,
                              });
                            } else {
                              guardarPaciente({
                                ...paciente,
                                geriatricoId: null,
                                geriatrico: null,
                              });
                            }
                          }}
                        />
                      </Grid>
                    </Fragment>
                  ) : null}

                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline" }}
                    >
                      Datos del Responsable
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.nombreRepresentante : null}
                      id="nombreRepresentante"
                      label="Apellido y Nombre"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          nombreRepresentante: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.domicilioRepresentante : null}
                      id="domicilioRepresentante"
                      label="Domicilio"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          domicilioRepresentante: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="localidadRepresentante"
                      fullWidth
                      options={localidades}
                      disablePortal
                      required
                      //defaultValue={colores[idColorDefault]}

                      defaultValue={
                        paciente &&
                        paciente.localidadRepresentanteId !== undefined
                          ? localidades[
                              localidades.findIndex(
                                (item) =>
                                  item.id === paciente.localidadRepresentanteId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Localidad"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            localidadRepresentanteId: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            localidadRepresentanteId: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={1} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.cpRepresentante : null}
                      id="cpRepresentante"
                      label="C.P."
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          cpRepresentante: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="vinculo"
                      fullWidth
                      options={vinculos}
                      disablePortal
                      defaultValue={
                        paciente && paciente.vinculoId !== undefined
                          ? vinculos[
                              vinculos.findIndex(
                                (item) => item.id === paciente.vinculoId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Vínculo"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            vinculoId: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            vinculoId: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.telefonoRepresentante : null}
                      id="telefonoRepresentante"
                      label="Teléfono"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          telefonoRepresentante: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={
                        paciente ? paciente.numeroDocumentoRepresentante : null
                      }
                      id="numeroDocumentoRepresentante"
                      label="Nº de Documento"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          numeroDocumentoRepresentante: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.email : null}
                      id="email"
                      label="Email"
                      fullWidth
                      required
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          email: e.target.value,
                        })
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.telefonoComunicaciones : null}
                      id="telefonoComunicaciones"
                      label="Teléfono Comunicaciones"
                      fullWidth
                      required
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          telefonoComunicaciones: e.target.value,
                        })
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  {/*   
                  <Grid item xs={6} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      id="fechaNacimientoRepresentante"
                      label="Fecha de Nacimiento"
                      type="date"
                      variant="standard"
                      required
                      fullWidth
                      value={
                        paciente ? paciente.fechaNacimientoRepresentante : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarPaciente({
                          ...paciente,
                          fechaNacimientoRepresentante: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                    */}

                  {/*
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline" }}
                    >
                      Nivel Socio-Económico
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6} style={{ marginTop: "0vw" }}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={paciente ? paciente.escolaridad : null}
                        onChange={(e) =>
                          guardarPaciente({
                            ...paciente,
                            escolaridad: e.target.value,
                          })
                        }
                      >
                        <FormLabel
                          id="escalones"
                          style={{
                            fontWeight: "bold",
                            fontSize: "large",
                            marginRight: "1vw",
                            marginTop: ".5vw",
                          }}
                        >
                          Escolaridad
                        </FormLabel>

                        <FormControlLabel
                          value="PRIM."
                          control={<Radio />}
                          label="PRIM."
                        />
                        <FormControlLabel
                          value="SECUND."
                          control={<Radio />}
                          label="SECUND."
                        />
                        <FormControlLabel
                          value="TERC."
                          control={<Radio />}
                          label="TERC."
                        />
                        <FormControlLabel
                          value="UNIV."
                          control={<Radio />}
                          label="UNIV."
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={3} style={{ marginTop: "0vw" }}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={paciente ? paciente.completo : null}
                        onChange={(e) =>
                          guardarPaciente({
                            ...paciente,
                            completo: e.target.value,
                          })
                        }
                      >
                        <FormLabel
                          id="escalones"
                          style={{
                            fontWeight: "bold",
                            fontSize: "large",
                            marginRight: "1vw",
                            marginTop: ".5vw",
                          }}
                        >
                          Completo
                        </FormLabel>

                        <FormControlLabel
                          value="SI"
                          control={<Radio />}
                          label="SI"
                        />
                        <FormControlLabel
                          value="NO"
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.situacionLaboral : null}
                      id="situacionLaboral"
                      label="Situación Laboral"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          situacionLaboral: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3} style={{ marginTop: "0vw" }}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={paciente ? paciente.desempenoLaboral : null}
                        onChange={(e) =>
                          guardarPaciente({
                            ...paciente,
                            desempenoLaboral: e.target.value,
                          })
                        }
                      >
                        <FormLabel
                          id="escalones"
                          style={{
                            fontWeight: "bold",
                            fontSize: "large",
                            marginRight: "1vw",
                            marginTop: ".5vw",
                          }}
                        >
                          Desempeño Laboral
                        </FormLabel>

                        <FormControlLabel
                          value="SI"
                          control={<Radio />}
                          label="SI"
                        />
                        <FormControlLabel
                          value="NO"
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={9} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.profesion : null}
                      id="profesion"
                      label="En que profesión. oficio o actividad ?"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          profesion: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  {/*   

                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline" }}
                    >
                      Aspecto Psicológico
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.lucidez : null}
                      id="lucidez"
                      label="Lucidez Mental"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          lucidez: e.target.value,
                        })
                      }
                    />
                  </Grid>


                  <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.lenguaje : null}
                      id="lenguaje"
                      label="Lenguaje"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          lenguaje: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.memoria : null}
                      id="memoria"
                      label="Nivel de concentración/atención/memoria"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          memoria: e.target.value,
                        })
                      }
                    />
                  </Grid>

   
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline" }}
                    >
                      Dimensión Relacional y Convivencial
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6} style={{ marginTop: "0vw" }}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={paciente ? paciente.contencion : null}
                        onChange={(e) =>
                          guardarPaciente({
                            ...paciente,
                            contencion: e.target.value,
                          })
                        }
                      >
                        <FormLabel
                          id="escalones"
                          style={{
                            fontWeight: "bold",
                            fontSize: "large",
                            marginRight: "1vw",
                            marginTop: ".5vw",
                          }}
                        >
                          Cuenta con una red de contención social: familiar,
                          vecinal, etc.?
                        </FormLabel>

                        <FormControlLabel
                          value="SI"
                          control={<Radio />}
                          label="SI"
                        />
                        <FormControlLabel
                          value="NO"
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.contencionCual : null}
                      id="contencionCual"
                      label="Cual?"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          contencionCual: e.target.value,
                        })
                      }
                    />
                  </Grid>
*/}
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline" }}
                    >
                      Datos de Grupo Familiar
                    </Typography>
                  </Grid>

                  {/*Grupo 1*/}
                  <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.nombreGrupo1 : null}
                      id="nombreGrupo1"
                      label="Apellido y Nombre"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          nombreGrupo1: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      id="vinculoGrupo1"
                      fullWidth
                      options={vinculos}
                      disablePortal
                      defaultValue={
                        paciente && paciente.vinculoIdGrupo1 !== undefined
                          ? vinculos[
                              vinculos.findIndex(
                                (item) => item.id === paciente.vinculoIdGrupo1
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Vínculo"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            vinculoIdGrupo1: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            vinculoIdGrupo1: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.ocupacionGrupo1 : null}
                      id="ocupacionGrupo1"
                      label="Ocupación"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          ocupacionGrupo1: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.domicilioGrupo1 : null}
                      id="domicilioGrupo1"
                      label="Domicilio"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          domicilioGrupo1: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.telefonoGrupo1 : null}
                      id="telefonoGrupo1"
                      label="Teléfono"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          telefonoGrupo1: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  {/*Grupo 2*/}
                  <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.nombreGrupo2 : null}
                      id="nombreGrupo2"
                      label="Apellido y Nombre"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          nombreGrupo2: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      id="vinculoGrupo2"
                      fullWidth
                      options={vinculos}
                      disablePortal
                      defaultValue={
                        paciente && paciente.vinculoIdGrupo2 !== undefined
                          ? vinculos[
                              vinculos.findIndex(
                                (item) => item.id === paciente.vinculoIdGrupo2
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Vínculo"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            vinculoIdGrupo2: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            vinculoIdGrupo2: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.ocupacionGrupo2 : null}
                      id="ocupacionGrupo2"
                      label="Ocupación"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          ocupacionGrupo2: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.domicilioGrupo2 : null}
                      id="domicilioGrupo2"
                      label="Domicilio"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          domicilioGrupo2: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.telefonoGrupo2 : null}
                      id="telefonoGrupo2"
                      label="Teléfono"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          telefonoGrupo2: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  {/*Grupo 3*/}
                  {/*}
                  <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.nombreGrupo3 : null}
                      id="nombreGrupo3"
                      label="Apellido y Nombre"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          nombreGrupo3: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      id="vinculoGrupo3"
                      fullWidth
                      options={vinculos}
                      disablePortal
                      defaultValue={
                        paciente && paciente.vinculoIdGrupo3 !== undefined
                          ? vinculos[
                              vinculos.findIndex(
                                (item) => item.id === paciente.vinculoIdGrupo3
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Vínculo"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            vinculoIdGrupo3: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            vinculoIdGrupo3: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.ocupacionGrupo3 : null}
                      id="ocupacionGrupo3"
                      label="Ocupación"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          ocupacionGrupo3: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.domicilioGrupo3 : null}
                      id="domicilioGrupo3"
                      label="Domicilio"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          domicilioGrupo3: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.telefonoGrupo3 : null}
                      id="telefonoGrupo3"
                      label="Teléfono"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          telefonoGrupo3: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  */}

                  {/*Grupo 4*/}
                  {/*
                  <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.nombreGrupo4 : null}
                      id="nombreGrupo4"
                      label="Apellido y Nombre"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          nombreGrupo4: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      id="vinculoGrupo4"
                      fullWidth
                      options={vinculos}
                      disablePortal
                      defaultValue={
                        paciente && paciente.vinculoIdGrupo4 !== undefined
                          ? vinculos[
                              vinculos.findIndex(
                                (item) => item.id === paciente.vinculoIdGrupo4
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Vínculo"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            vinculoIdGrupo4: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            vinculoIdGrupo4: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.ocupacionGrupo4 : null}
                      id="ocupacionGrupo4"
                      label="Ocupación"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          ocupacionGrupo4: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.domicilioGrupo4 : null}
                      id="domicilioGrupo4"
                      label="Domicilio"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          domicilioGrupo4: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.telefonoGrupo4 : null}
                      id="telefonoGrupo4"
                      label="Teléfono"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          telefonoGrupo4: e.target.value,
                        })
                      }
                    />
                  </Grid>
                    */}
                  {/*Grupo 5*/}
                  {/*
                  <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.nombreGrupo5 : null}
                      id="nombreGrupo5"
                      label="Apellido y Nombre"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          nombreGrupo5: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      id="vinculoGrupo5"
                      fullWidth
                      options={vinculos}
                      disablePortal
                      defaultValue={
                        paciente && paciente.vinculoIdGrupo5 !== undefined
                          ? vinculos[
                              vinculos.findIndex(
                                (item) => item.id === paciente.vinculoIdGrupo5
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Vínculo"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            vinculoIdGrupo5: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            vinculoIdGrupo5: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.ocupacionGrupo5 : null}
                      id="ocupacionGrupo5"
                      label="Ocupación"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          ocupacionGrupo5: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.domicilioGrupo5 : null}
                      id="domicilioGrupo5"
                      label="Domicilio"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          domicilioGrupo5: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.telefonoGrupo5 : null}
                      id="telefonoGrupo5"
                      label="Teléfono"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          telefonoGrupo5: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  */}

                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline" }}
                    >
                      Observaciones
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
                    <TextareaAutosize
                      value={paciente ? paciente.observaciones : null}
                      id="observaciones"
                      aria-label="minimum height"
                      minRows={2}
                      maxRows={6}
                      fullWidth
                      placeholder="Observaciones"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          observaciones: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  {/*    

                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline" }}
                    >
                      Diagnóstico Psico-Social
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
                    <TextareaAutosize
                      value={paciente ? paciente.diagnostico : null}
                      id="diagnostico"
                      aria-label="minimum height"
                      minRows={2}
                      maxRows={6}
                      fullWidth
                      placeholder="Diagnóstico Psico-Social"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          diagnostico: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline" }}
                    >
                      Plan de Tratamiento Psico-Social
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12} style={{ marginTop: "-1vw" }}>
                    <TextareaAutosize
                      value={paciente ? paciente.planTratamiento : null}
                      id="planTratamiento"
                      aria-label="minimum height"
                      minRows={2}
                      maxRows={8}
                      fullWidth
                      placeholder="Plan de Tratamiento Psico-Social"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          planTratamiento: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  */}

                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline" }}
                    >
                      Datos de Contacto
                    </Typography>
                  </Grid>
                  {/*
                  <Grid item xs={12} md={6} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.email : null}
                      id="email"
                      label="Email"
                      fullWidth
                      required
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          email: e.target.value,
                        })
                      }
                    />
                  </Grid>

                    */}
                  <Grid item xs={12} md={5} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.emailAlternativo : null}
                      id="emailAlternativo"
                      label="Email Alternativo"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          emailAlternativo: e.target.value,
                        })
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  {/*
                  <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.telefonoComunicaciones : null}
                      id="telefonoComunicaciones"
                      label="Teléfono Comunicaciones"
                      fullWidth
                      required
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          telefonoComunicaciones: e.target.value,
                        })
                      }
                    />
                  </Grid>
                    */}
                  <Grid item xs={12} md={3} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={paciente ? paciente.whatsApp : null}
                      id="whatsApp"
                      label="WhatsApp"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          whatsApp: e.target.value,
                        })
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="obraSocialContrato"
                      fullWidth
                      options={obrasSociales}
                      disablePortal
                      defaultValue={
                        paciente && paciente.obraSocialContratoId !== undefined
                          ? obrasSociales[
                              obrasSociales.findIndex(
                                (item) =>
                                  item.id === paciente.obraSocialContratoId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Obra Social Contrato"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            obraSocialContratoId: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            obraSocialContratoId: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={2} style={{ marginTop: "-1vw" }}>
                    <TextField
                      value={
                        paciente && paciente.obraSocialContratoId === 2
                          ? paciente.arancel
                          : null
                      }
                      id="arancel"
                      label="Arancel Mensual"
                      type="number"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarPaciente({
                          ...paciente,
                          arancel: e.target.value,
                        })
                      }
                      disabled={paciente && paciente.obraSocialContratoId !== 2}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="coseguro"
                      fullWidth
                      options={coseguros}
                      disablePortal
                      defaultValue={
                        paciente && paciente.coseguroId !== undefined
                          ? coseguros[
                              coseguros.findIndex(
                                (item) => item.id === paciente.coseguroId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) =>
                        option.label + " (" + option.valor + ")"
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Coseguro"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            coseguroId: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            coseguroId: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="panales"
                      fullWidth
                      options={panales}
                      disablePortal
                      defaultValue={
                        paciente && paciente.panalId !== undefined
                          ? panales[
                              panales.findIndex(
                                (item) => item.id === paciente.panalId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) =>
                        option.label + " (" + option.valor + ")"
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Pañales"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            panalId: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            panalId: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="descartables"
                      fullWidth
                      options={descartables}
                      disablePortal
                      defaultValue={
                        paciente && paciente.descartableId !== undefined
                          ? descartables[
                              descartables.findIndex(
                                (item) => item.id === paciente.descartableId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) =>
                        option.label + " (" + option.valor + ")"
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Descartables"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            descartableId: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            descartableId: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="fechaPago"
                      fullWidth
                      options={fechasPagos}
                      disablePortal
                      defaultValue={
                        paciente && paciente.fechaPago !== undefined
                          ? fechasPagos[
                              fechasPagos.findIndex(
                                (item) => item.id === paciente.fechaPago
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Fecha de Pago"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            fechaPago: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            fechaPago: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                          color="primary"
                          style={{ marginLeft: "1vw" }}
                          checked={paciente ? paciente.iva : null}
                            onChange={(e) => {
                              guardarPaciente({
                                ...paciente,
                                iva: e.target.checked,
                              });
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="I.V.A."
                        labelPlacement="start"
                      />
                    </FormControl>
                  </Grid>

                  {/*     
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="diagnostico"
                      fullWidth
                      options={diagnosticos}
                      disablePortal
                      required
                      //defaultValue={colores[idColorDefault]}

                      defaultValue={
                        paciente &&
                        paciente.diagnosticoId !== undefined
                          ? diagnosticos[
                              diagnosticos.findIndex(
                                (item) =>
                                  item.id === paciente.diagnosticoId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          style={{ marginTop: "-1vw" }}
                          label="Diagnóstico"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            diagnosticoId: value.id,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            diagnosticoId: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                  */}
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderIngresosEgresos()}
                visible={modalIngresosEgresos}
                closable={false}
                footer={renderFooterIngresosEgresos()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="fIngresoEgreso"
                      label={
                        selectedRow &&
                        selectedRow.activo &&
                        selectedRow.motivoIngresoEgreso !== "INTERNACION"
                          ? "Fecha de Egreso"
                          : "Fecha de Ingreso"
                      }
                      type="date"
                      disabled={
                        selectedRow &&
                        selectedRow.motivoIngresoEgreso === "OBITO"
                          ? true
                          : false
                      }
                      fullWidth
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarPaciente({
                          ...paciente,
                          fIngresoEgreso: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="mIngresoEgreso"
                      fullWidth
                      options={
                        selectedRow &&
                        selectedRow.activo &&
                        selectedRow.motivoIngresoEgreso !== "INTERNACION"
                          ? motivosEgresos
                          : motivosIngresos
                      }
                      disablePortal
                      disabled={
                        selectedRow &&
                        selectedRow.motivoIngresoEgreso === "OBITO"
                          ? true
                          : false
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={
                            selectedRow &&
                            selectedRow.activo &&
                            selectedRow.motivoIngresoEgreso !== "INTERNACION"
                              ? "Motivo de Egreso"
                              : "Motivo de Ingreso"
                          }
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarPaciente({
                            ...paciente,
                            mIngresoEgreso: value.label,
                          });
                        } else {
                          guardarPaciente({
                            ...paciente,
                            mIngresoEgreso: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <Tooltip
                          target=".export-buttons>button"
                          position="bottom"
                        />
                        <DataTable
                          value={ingresosEgresos}
                          size="small"
                          scrollable
                          scrollHeight="200px"
                          //selection={selectedRow}
                          //onSelectionChange={(e) => setSelectedRow(e.value)}
                          //selectionMode="single"
                          //dataKey="id"
                          //header={header}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "small", marginTop: "1vw" }}
                          //globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          //paginator
                          //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          //currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          //rows={10}
                          //rowsPerPageOptions={[10, 20, 50]}
                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                        >
                          <Column
                            field="fecha"
                            header="Fecha"
                            body={fTemplate}
                          ></Column>
                          <Column
                            field="motivo"
                            header="Motivo"
                            body={mTemplate}
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
      {exit ? <Navigate to="/login" /> : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
