import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

export default function Tinetti(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const { tinetti, statusAcceso3, localidades, guardarTinetti } =
    serviciosContext;

  const handleChangeEquilibrioSentado = (event) => {
    guardarTinetti({
      ...tinetti,
      equilibrioSentado: event.target.value,
    });
  };

  const handleChangeLevantarse = (event) => {
    guardarTinetti({
      ...tinetti,
      levantarse: event.target.value,
    });
  };

  const handleChangeIntentoLevantarse = (event) => {
    guardarTinetti({
      ...tinetti,
      intentoLevantarse: event.target.value,
    });
  };

  const handleChangeEquilibrioSegundos = (event) => {
    guardarTinetti({
      ...tinetti,
      equilibrioSegundos: event.target.value,
    });
  };

  const handleChangeEquilibrioProlongado = (event) => {
    guardarTinetti({
      ...tinetti,
      equilibrioProlongado: event.target.value,
    });
  };


  const handleChangeRombergSensibilizado = (event) => {
    guardarTinetti({
      ...tinetti,
      rombergSensibilizado: event.target.value,
    });
  };

  const handleChangeRomberg = (event) => {
    guardarTinetti({
      ...tinetti,
      romberg: event.target.value,
    });
  };


  const handleChangeGirar = (event) => {
    guardarTinetti({
      ...tinetti,
      girar: event.target.value,
    });
  };

  const handleChangeGirar2 = (event) => {
    guardarTinetti({
      ...tinetti,
      girar2: event.target.value,
    });
  };

  const handleChangeSentarse = (event) => {
    guardarTinetti({
      ...tinetti,
      sentarse: event.target.value,
    });
  };


  //console.log(statusAcceso3);
  //console.log(tinetti);

  return (
    <Fragment>
      {tinetti ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "0vw", marginLeft: "0vw" }}
          >
            <Grid item xs={12} md={12}>
              <Typography variant="caption" display="block" gutterBottom>
                A) EQUILIBRIO (el sujeto está sentado en una silla rígida, sin
                apoyo para brazos).
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="equilibrioSentado"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  ■ Equilibrio sentado
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="equilibrioSentado"
                  value={tinetti ? tinetti.equilibrioSentado : null}
                  onChange={handleChangeEquilibrioSentado}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Se inclina o se desliza de la silla."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Está estable, seguro."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="levantarse"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  ■ Levantarse de la silla
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="levantarse"
                  value={tinetti ? tinetti.levantarse : null}
                  onChange={handleChangeLevantarse}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Es incapaz sin ayuda."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Se debe ayudar con los brazos."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Se levanta sin usar los brazos."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="intentoLevantarse"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  ■ En el intento de levantarse
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="intentoLevantarse"
                  value={tinetti ? tinetti.intentoLevantarse : null}
                  onChange={handleChangeIntentoLevantarse}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Es incapaz sin ayuda."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Es capaz pero necesita más de un intento."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Es capaz al primer intento."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="equilibrioSegundos"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  ■ Equilibrio de pié (los primeros 5 segundos)
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="equilibrioSegundos"
                  value={tinetti ? tinetti.equilibrioSegundos : null}
                  onChange={handleChangeEquilibrioSegundos}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Inestable (vacila, mueve los piés, marcada oscilación del tronco)."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Estable gracias al bastón u otro auxilio para sujetarse."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Estable sin soportes o auxilios."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="equilibrioProlongado"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  ■ Equilibrio de pié prolongado
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="equilibrioProlongado"
                  value={tinetti ? tinetti.equilibrioProlongado : null}
                  onChange={handleChangeEquilibrioProlongado}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Inestable (vacila, mueve los piés, marcada oscilación del tronco)."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Estable pero con base de apoyo amplia (maleolos mediales>10cm) o usa auxilio."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Estable con base de apoyo estrecha, sin soportes o auxilios."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="rombergSensibilizado"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  ■ Romberg sensibilizado (con ojos abiertos, piés juntos,
                  empujar levemente con la palma de la mano sobre el esternón
                  del sujeto en 3 oportunidades)
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="rombergSensibilizado"
                  value={tinetti ? tinetti.rombergSensibilizado : null}
                  onChange={handleChangeRombergSensibilizado}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Comienza a caer."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Oscila, pero se endereza solo."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Estable."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>


            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="romberg"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  ■ Romberg (con ojos cerrados e igual que el anterior) 
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="romberg"
                  value={tinetti ? tinetti.romberg : null}
                  onChange={handleChangeRomberg}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Inestable."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Estable."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>


            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="girar"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  ■ Girar en 360°
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="girar"
                  value={tinetti ? tinetti.girar : null}
                  onChange={handleChangeGirar}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Con pasos discontinuos o movimiento no homogéneo."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Con pasos contínuos o movimiento homogéneo."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
              <hr/>      
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="girar2"
                  value={tinetti ? tinetti.girar2 : null}
                  onChange={handleChangeGirar2}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Inestable (se sujeta, oscila)."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Estable."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>


            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel
                  id="sentarse"
                  style={{ fontWeight: "bold", fontSize: "large" }}
                >
                  ■ Sentarse
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="sentarse"
                  value={tinetti ? tinetti.sentarse : null}
                  onChange={handleChangeSentarse}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Inseguro (equivoca distancia, cae sobre la silla)."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Usa los brazos o tiene un movimiento discontinuo."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Seguro, movimiento continuo."
                    disabled={
                      tinetti && tinetti.status && statusAcceso3 !== 200
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h5" gutterBottom component="div">
              PUNTAJE EQUILIBRIO
              </Typography>
              <Typography variant="h4" gutterBottom component="div">
                {Number(tinetti.equilibrioSentado ? tinetti.equilibrioSentado : 0) +
                  Number(tinetti.levantarse ? tinetti.levantarse : 0) +
                  Number(tinetti.intentoLevantarse ? tinetti.intentoLevantarse : 0) +
                  Number(tinetti.equilibrioSegundos ? tinetti.equilibrioSegundos : 0) +
                  Number(tinetti.equilibrioProlongado ? tinetti.equilibrioProlongado : 0) +
                  Number(tinetti.rombergSensibilizado ? tinetti.rombergSensibilizado : 0) +
                  Number(tinetti.romberg ? tinetti.romberg : 0) +
                  Number(tinetti.girar ? tinetti.girar : 0) +
                  Number(tinetti.girar2 ? tinetti.girar2 : 0) +
                  Number(tinetti.sentarse ? tinetti.sentarse : 0)} / 16
              </Typography>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
